import { useDispatch, useSelector } from 'react-redux';
import { GeralWrapper, Overlay, PopupWrapper, Subtitle, Title, TitleWrapper } from './styled';
import DataAccountContent from '@Pages/MyProfilePage/MyProfileBox/DataAccountContent';

const UpdateAccountPopup = () => {
  const { accountPersonalInfo } = useSelector(state => state.global);
  const dispatch = useDispatch();

  const close = () => {
    dispatch({ type: 'SET_ACCOUNT_PERSONAL_INFO', payload: { showPopup: false } });
  };

  return (
    accountPersonalInfo.showPopup && (
      <GeralWrapper>
        <Overlay onClick={close} />
        <PopupWrapper>
          <TitleWrapper>
            <Title>Atualizar dados da conta</Title>
            <Subtitle>Para continuar, precisamos que complete as informações de sua conta.</Subtitle>
          </TitleWrapper>
          <DataAccountContent showChangePasswordButton={false} forcedUpdate={true} />
        </PopupWrapper>
      </GeralWrapper>
    )
  );
};

export default UpdateAccountPopup;
