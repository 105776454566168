import React, { useEffect, useState } from 'react';
import Form from './Form';
import Wrapper from './Wrapper';
import Box from './Box';
import Container from './Container';
import Header from './Header';
import Overlay from './Overlay';
import Loading from '@Containers/Overlay';
import Close from './Close';
import styled from 'styled-components';
import ErrorMessage from './ErrorMessage';
import SuccessMessage from './SuccessMessage';
import { Button } from './Button';
import Input from './Input';
import { ConfirmButtonWrapper } from './Button/ConfirmButtonWrapper';
import { SuccessMessageWrapper } from './SuccessMessage/SuccessMessageWrapper';
import { Option, Select } from './styled';

export const PopupFormModal = ({ close, actionFunction, title, action, availableHosts }) => {
  const [name, handleName] = useState('');
  const [description, handleDescription] = useState('');
  const [host, handleHost] = useState();
  const [imageId, handleImageId] = useState('');
  const [errorEditImageMessage, handleErrorEditImageMessage] = useState('');
  const [successMessage, handleSuccessMessage] = useState('');
  const [loading, handleLoading] = useState(false);

  const isCreationPopup = action === 'create';
  const isConfirmPopup = action === 'delete';

  // ASYNC FUNCTIONS
  async function sendForm() {
    try {
      var data = {
        id: imageId,
        image_name: name,
        description: description,
        host: {
          host_id: host
        }
      };
      handleLoading(true);
      await actionFunction.actionFunction(data);

      handleLoading(false);
      if (!isCreationPopup) {
        handleSuccessMessage('Dados salvos com sucesso!');
      }
      resetErrorSuccessMessage();
    } catch (error) {
      handleErrorEditImageMessage(error.message);
      handleLoading(false);
      resetErrorSuccessMessage();
    }
  }

  async function onClickConfirmButton() {
    try {
      handleLoading(true);
      await actionFunction.actionFunction(actionFunction.data.id, actionFunction.data.host.host_id);
      handleLoading(false);

      const message = 'Imagem deletada com sucesso!';
      handleSuccessMessage(message);
      resetErrorSuccessMessage();
    } catch (error) {
      handleErrorEditImageMessage(error.message);
      handleLoading(false);
      resetErrorSuccessMessage();
    }
  }

  function onChangeDescription(event) {
    handleDescription(event.target.value);
  }

  function onChangeName(event) {
    handleName(event.target.value);
  }

  async function onSubmitForm(ev) {
    ev.preventDefault();
    if (isFormValid()) {
      sendForm();
    }
  }

  function isFormValid() {
    if (name.length === 0) {
      handleErrorEditImageMessage('O nome deve ser preenchido.');
      resetErrorSuccessMessage();
      return false;
    } else if (description.length === 0) {
      handleErrorEditImageMessage('Uma descrição deve ser preenchida.');
      resetErrorSuccessMessage();
      return false;
    }

    if (isCreationPopup) {
      if (!host) {
        handleErrorEditImageMessage('Um host deve ser selecionado.');
        resetErrorSuccessMessage();
        return false;
      }
    }
    return true;
  }

  function resetErrorSuccessMessage() {
    setTimeout(function () {
      handleErrorEditImageMessage('');
    }, 5000);
  }

  useEffect(() => {
    if (actionFunction.data) {
      handleName(actionFunction.data.image_name);
      handleDescription(actionFunction.data.description);
      handleHost(actionFunction.data.host.host_id);
      handleImageId(actionFunction.data.id);
    }
  }, [actionFunction]);

  return (
    <Wrapper>
      {loading ? <Loading /> : []}
      <Overlay onClick={() => { close(actionFunction.data) }} />
      <Container>
        <Box>
          <Div>
            <Close close={() => { close(actionFunction.data) }} />
          </Div>
          {successMessage ? (
            <SuccessMessageWrapper>
              <SuccessMessage>{successMessage}</SuccessMessage>
              <Button text="OK" onClick={() => { close(actionFunction.data) }}></Button>
            </SuccessMessageWrapper>
          ) : (
            <>
              <Header title={title} />
              {!isConfirmPopup ? (
                <>
                  <Form>
                    <label htmlFor="name">Nome: *</label>
                    <Input
                      type="text"
                      name="Name"
                      placeholder="Digite o nome"
                      required
                      value={name}
                      onChange={onChangeName}
                      inputProps={{ maxLength: 30, minLength: 8 }}
                      autoComplete="nope"
                      variant="outlined"
                    />
                    <label htmlFor="description">Descrição: *</label>
                    <Input
                      type="text"
                      name="Description"
                      placeholder="Digite uma descrição"
                      required
                      value={description}
                      onChange={onChangeDescription}
                      inputProps={{ maxLength: 255, minLength: 3 }}
                      autoComplete="nope"
                      variant="outlined"
                    />
                    <label htmlFor="host">Host: *</label>
                    <Select
                      value={host}
                      onChange={e => handleHost(e.target.value)}
                      required
                      disabled={!isCreationPopup || availableHosts.length === 0}
                    >
                      <Option value="">
                        {availableHosts.length === 0 ? "Sem hosts disponíveis" : "Selecione um host"}
                      </Option>
                      {availableHosts.map((host, index) => (
                        <Option key={index} value={host.host_id}>{host.nickname}</Option>
                      ))}
                    </Select>
                  </Form>
                  {errorEditImageMessage ? <ErrorMessage>{errorEditImageMessage}</ErrorMessage> : []}
                  <Button text={isCreationPopup ? 'CRIAR' : 'ATUALIZAR'} type="submit" onClick={onSubmitForm} />
                </>
              ) : (
                <>
                  {errorEditImageMessage ? <ErrorMessage>{errorEditImageMessage}</ErrorMessage> : []}
                  <ConfirmButtonWrapper>
                    <Button text="SIM" onClick={onClickConfirmButton}></Button>
                    <Button text="NÃO" onClick={close}></Button>
                  </ConfirmButtonWrapper>
                </>
              )}
            </>
          )}
        </Box>
      </Container>
    </Wrapper>
  );
};

export default PopupFormModal;

const Div = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  height: auto;
`;
