import { useCallback, useEffect, useState } from 'react';
import { getReferralVoucherInfoByOwner } from '../../../../services/noar-api';
import {
  ColoredCopyEm,
  ColoredCopyP,
  ColoredEm,
  ColoredStyledP,
  CopyContentWrapper,
  InfoWrapper,
  QrCodeContainer,
  ReferralVoucherContentWrapper,
  RegistersInfoWrapper,
  RegistersTitleWrapper,
  TitleInfoWrapper,
  VoucherInfoWrapper,
} from './styled';
import Subtitle from '../Subtitle';
import QRCode from 'qrcode.react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { VisibilityOutlined, VisibilityOffOutlined } from '@material-ui/icons';
import { NOAR_SITE } from '@Constants';

export const ReferralVoucherContent = () => {
  const [referralVoucherInfo, handleReferralVoucherInfo] = useState();
  const [initialDate, handleInitialDate] = useState(new Date(new Date().getFullYear(), 0, 1));
  const [finalDate, handleFinalDate] = useState(new Date());
  const [isCopied, handleIsCopied] = useState(false);
  const [isRegistersOpen, handleIsRegistersOpen] = useState(false);
  const text = referralVoucherInfo?.voucher?.toUpperCase();

  const onCopy = useCallback(
    function () {
      handleIsCopied(true);

      const timeoutId = setTimeout(function () {
        handleIsCopied(false);
      }, 1500);

      return function () {
        clearTimeout(timeoutId);
      };
    },
    [handleIsCopied],
  );

  function onChangeRegisterVisibility() {
    handleIsRegistersOpen(!isRegistersOpen);
  }

  useEffect(() => {
    async function getVoucherInfo() {
      const voucher = await getReferralVoucherInfoByOwner(initialDate, finalDate);
      if(voucher) handleReferralVoucherInfo(voucher);
    }
    getVoucherInfo();
  }, [initialDate, finalDate]);

  return referralVoucherInfo ? (
    <ReferralVoucherContentWrapper>
      <TitleInfoWrapper>
        <Subtitle>Vale indicação:</Subtitle>
        <CopyToClipboard {...{ onCopy, text }}>
          <CopyContentWrapper>
            <ColoredStyledP>
              <ColoredEm onClick={onCopy}>{referralVoucherInfo?.voucher?.toUpperCase()}</ColoredEm>
            </ColoredStyledP>
            <ColoredCopyP {...{ isCopied }}>
              <ColoredCopyEm data-text="Copiado!">Copiado!</ColoredCopyEm>
            </ColoredCopyP>
          </CopyContentWrapper>
        </CopyToClipboard>
      </TitleInfoWrapper>
      <RegistersInfoWrapper>
        <RegistersTitleWrapper>
          <p>Registros </p>
          {isRegistersOpen ? (
            <VisibilityOutlined onClick={onChangeRegisterVisibility} />
          ) : (
            <VisibilityOffOutlined onClick={onChangeRegisterVisibility} />
          )}
        </RegistersTitleWrapper>
        <InfoWrapper expand={isRegistersOpen}>
          <VoucherInfoWrapper expand={isRegistersOpen}>
            <p>Porcent. de desconto: {referralVoucherInfo?.discount_percentage}%</p>
            <p>Porcent. de lucro: {referralVoucherInfo?.profit_percentage}%</p>
            <p>Compras realizadas: {referralVoucherInfo?.first_purchase_amount}</p>
            <p>Quantidade de registros: {referralVoucherInfo?.register_amount}</p>
          </VoucherInfoWrapper>
          <QrCodeContainer>
            <QRCode
              value={`${NOAR_SITE}/register?referralVoucher=${referralVoucherInfo?.voucher?.toUpperCase()}`}
              size={200}
            />
          </QrCodeContainer>
        </InfoWrapper>
      </RegistersInfoWrapper>
    </ReferralVoucherContentWrapper>
  ) : (
    []
  );
};

export default ReferralVoucherContent;
