import styled from 'styled-components';

import { Link as RouterLink } from 'react-router-dom';

import { desktopMinSize, rem, mqmax, mqmin } from '@Styles/mixins';
import { mq } from '../../../../styles/mixins';

export const TextDesktop = styled.span`
  color: ${({color}) => color || '#fff'};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;

  ${mqmax(desktopMinSize - 1)} {
    display: none;
  }

  ${mq((desktopMinSize + 300), desktopMinSize)} {
    font-size: 11px;
  }
`;

export const Link = styled(RouterLink)`
  display: flex;
  align-items: center;
  text-decoration: none;
  position: relative;

  ${mqmax(desktopMinSize - 1)} {
    margin: ${rem(21)} 0 !important;
  }

  ${mqmin(desktopMinSize)} {
    justify-content: center;
  }
`;

export const MobileLink = styled(RouterLink)`
  display: flex;
  align-items: center;

  ${mqmin(desktopMinSize)} {
    display: none;
  }
`;

export const Icon = styled.img`
  display: flex;
  max-width: 100%;
  width: ${rem(16)};
  height: ${rem(18)};

  ${mqmax(desktopMinSize - 1)} {
    width: ${rem(12)};
    height: ${rem(14)};
  }
  & {
    background-color: transparent;
  }
`;

export const IconMobile = styled(Icon)`
  ${mqmin(desktopMinSize)} {
    display: none;
  }
`;

export const IconDesktop = styled(Icon)`
  ${mqmax(desktopMinSize - 1)} {
    display: none;
  }
`;

export const Div = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: ${rem(8)};
`;
