import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import MuiButton from '@material-ui/core/Button';
import {
    defaultFont
  } from '@Styles/mixins';

export const Footer = () => 
    <Button component={Link} to="/password/forgot">
        Esqueci minha senha
    </Button>

export default Footer;

const Button = styled(MuiButton)`
  &.MuiButton-root {
    width: 100%;
    font-family: ${defaultFont};
    text-transform: none;
    font-style: normal;
    text-align: center;
    transition: color calc(1s / 3) ease-in-out;
    font-weight: 200;
    color: #fff;
    font-size: calc(11 / 16 * 1rem);
    padding: 0;
  
  }
`;