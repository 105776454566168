import React from 'react';

import { useSelector } from 'react-redux';

import * as S from './styled';
import { useUserRole } from '../../../context/UserRoleContext';

export function HelloView({ name, nameAbbreviation }) {
  const { clientType } = useUserRole();

  return (
    <S.Hello>
      <S.P>
        Olá, <S.Span data-complete-name={name}>{nameAbbreviation}</S.Span>
        {clientType !== "B2B_COLLAB" 
          ? <strong>Selecione o serviço que deseja usar.</strong>
          : []
        }
      </S.P>
    </S.Hello>
  );
}

export function useHello() {
  const { name } = useSelector(state => state.global.session.client);

  const firstName = name?.split(' ')[0] || '';
  const nameAbbreviation = firstName.length > 18 ? `${firstName.substring(0, 15)}...` : firstName;

  return { name, nameAbbreviation };
}

export function Hello() {
  return <HelloView {...useHello()} />;
}

export default Hello;
