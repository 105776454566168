import React from 'react';
import { ShopPageThirdStepWrapper, ShopPageThirdStepContentWrapper, ShopPageThirdStepButtonWrapper } from './styled';
import { TutorialBackdrop } from '../styled';
import { useShoppingCart } from '../../../context/ShoppingCartContext';

export const ThirdInitialStep = ({ nextStep, skipTutorial }) => {
  const { cartItems } = useShoppingCart();
  const isItemOnCart = cartItems.length === 0;
  return (
    <>
      <TutorialBackdrop />
      <ShopPageThirdStepWrapper>
        <ShopPageThirdStepContentWrapper>
          <strong>
            Vamos <span>adicionar um plano </span>ao seu carrinho
          </strong>
          <ShopPageThirdStepButtonWrapper>
            <button disabled={isItemOnCart} onClick={nextStep}>
              Entendi
            </button>
            <button onClick={skipTutorial}>Fechar tutorial</button>
          </ShopPageThirdStepButtonWrapper>
        </ShopPageThirdStepContentWrapper>
      </ShopPageThirdStepWrapper>
    </>
  );
};
