export const desktopMinSize = 1080; // px
export const mobileMinSize = 360; // px
export const defaultFont = 'Inter';
export const headerMobileHeight = 53.19;
export const headerDesktopHeight = 73;
export const headerZIndex = 1000;
export const overlayZIndex = 5000;
export const overlayOpacity = 0.6;
export const privateNavigationMobileHeight = 64;

export const rem = px => `${px / 16}rem`;

export const visuallyHidden = `
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const placeholder = (color, opacity = 1) => `
  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: ${color};
    opacity: ${opacity};
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: ${color};
    opacity: ${opacity};
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: ${color};
    opacity: ${opacity};
  }
  &:-moz-placeholder { /* Firefox 18- */
    color: ${color};
    opacity: ${opacity};
  }
`;

export const mq = (max, min) => `
  @media only screen and (min-width: ${min}px) and (max-width: ${max}px)
`;

export const mqmin = min => `
  @media only screen and (min-width: ${min}px)
`;

export const mqmax = max => `
  @media only screen and (max-width: ${max}px)
`;

export const displayRow = () => `
  display: flex;
  flex-direction: row;
`;

export const displayColumn = () => `
  display: flex;
  flex-direction: column;
`;

export const displayRowReverse = () => `
  display: flex;
  flex-direction: row-reverse;
`;

export const displayColumnReverse = () => `
    display: flex;
    flex-direction: column-reverse;
`;

export const transform = property => `
  -webkit-transform: ${property};
  -ms-transform: ${property};
  -o-transform: ${property};
  transform: ${property};
`;

export const transition = property => `
  -webkit-transition: ${property};
  -moz-transition: ${property};
  -ms-transition: ${property};
  -o-transition: ${property};
  transition: ${property};
`;
