import React from 'react';

import Container from './Container';
import Title from './Title';

export const Header = () => (
  <Container>
    <Title />
  </Container>
);

export default Header;
