import styled from 'styled-components';

import { desktopMinSize, mqmax, mqmin } from '@Styles/mixins';

export const Container = styled.div`
  display: flex;
  margin: 0 0 calc(30 / 16 * 1rem) 0;
  width: 90%;
  ${mqmin(desktopMinSize)} {
    flex-direction: column;
    align-items: flex-start;
  }
  ${mqmax(desktopMinSize - 1)} {
    padding: 0 calc(50 / 1056 * 100%);
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export default Container;
