import { IconButton as MuiIconButton } from '@material-ui/core';
import { KeyboardArrowUp } from '@material-ui/icons';
import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

const IconButton = props => {
  const { href } = useLocation();

  const handleClick = useCallback(
    function scrollToHash(event) {
      event.preventDefault();
      document.body.scrollTo({ behavior: 'smooth', top: 0 });
    },
    [document],
  );

  return (
    <MuiIconButton aria-label="Voltar ao topo" component="a" onClick={handleClick} {...{ href }} {...props}>
      <KeyboardArrowUp />
    </MuiIconButton>
  );
};

export const Scroll = styled(IconButton)`
  &.MuiIconButton-root {
    animation: scroll 2s ease-in-out infinite;
    @keyframes scroll {
      0% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(0.5rem);
      }
      100% {
        transform: translateY(0);
      }
    }
    color: inherit;
  }
`;

export default Scroll;
