import React from 'react';
import Container from './Container';
import Icon from './Icon';

export const Back = ({goBack}) => {
    return (
        <Container onClick={goBack}>
            <Icon />
        </Container>
    )
};

export default Back;
