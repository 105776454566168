import Typography from '@material-ui/core/Typography';
import React from 'react';
import styled from 'styled-components';
import {
  defaultFont
} from '@Styles/mixins';

export const ErrorMessage = props => <P paragraph={true} {...props} />;

export default ErrorMessage;

const P = styled(Typography)`
  &.MuiTypography-paragraph {
    grid-column-start: 1;
    grid-column-end: 4;
    font-family: ${defaultFont};
    font-weight: 700;
    font-style: normal;
    font-size: calc(16 / 16 * 1rem);
    line-height: 22px;
    color: #ff0000;
    text-decoration: none;
    text-align: center;
    letter-spacing: 0;
    margin-bottom: 0;
  }
`;
