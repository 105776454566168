import styled from 'styled-components';

import { desktopMinSize, mqmax, rem, overlayZIndex } from '@Styles/mixins';

import { CreateTooltip } from '../Tooltip/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #fff;
  position: relative;
  gap: ${rem(15)};

  ${mqmax(desktopMinSize - 1)} {
    width: 90%;
    margin: ${rem(0)} auto;
    height: auto;
  }
`;

export const MaintenanceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${rem(100)};
  width: 100%;
  height: 100%;
  color: #fff;
  position: relative;
  padding: ${rem(20)} 0;
`;

export const WarningTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${rem(15)};
`;

export const WarningFooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${rem(15)};
`;

export const WarningRedirect = styled.a`
  font-size: ${rem(13)};
  font-weight: 200;
  text-align: center;
  line-height: ${rem(17)};
  color: #fff;
  text-decoration: none;
  cursor: pointer;
`;

export const WaningText = styled.p`
  font-size: ${rem(17)};
  font-weight: 300;
  text-align: center;
  line-height: ${rem(12)};

  ${mqmax(desktopMinSize - 1)} {
    line-height: ${rem(30)};
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${rem(18)};  
`;

export const TitlesHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${rem(8)};
`;

export const Title = styled.h1`
  color: #FFF;
  text-align: center;
  font-size: ${rem(26)};
  font-weight: 700;
`;

export const Subtitle = styled.h2`
  color: #FFF;
  text-align: center;
  font-weight: 200;
  font-size: ${rem(14)};
  line-height: ${rem(17)};
`;

export const PlansOption = styled.h1`
  font-size: calc(20rem / 16);
  font-weight: 400;
  opacity: 0.4;
  margin-left: ${({secondary}) => secondary ? '15px' : '0'};
  padding: 0.4rem 0;
  cursor: pointer;
  transition: 0.2s ease-in;
  border: solid ${({ active }) => (active ? '#2d80f2' : 'transparent')} calc(1rem / 16);
  padding: 0.5rem;
  border-radius: 1rem;
  text-align: center;
  
  ${mqmax(desktopMinSize - 1)} {
    font-size: calc(14rem / 16);
  }
  ${({ active }) => active ? `opacity: 1;`: ''}
  
`;

export const PriceCardWrapperStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 29px;
  position: relative;
  width: 100%;
  height: 100%;

  ${mqmax(desktopMinSize - 1)} {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
  }
`;

export const ShopPageCartMobileButton = styled.button`
  display: flex !important;
  position: absolute;
  right: ${({ isMobileCartOpen, step }) =>
    isMobileCartOpen || step === 4 || step === 5 || step === 6 || step === 7 ? '12px' : '0'};
  top: 15%;
  background-color: ${({ isMobileCartOpen, step }) =>
    isMobileCartOpen || step === 4 || step === 5 || step === 6 || step === 7 ? 'transparent' : '#2d80f2'};
  max-width: 51px;
  width: 100%;
  height: 44px;
  align-items: center;
  justify-content: center;
  border: 0;
  border-top-left-radius: 22px;
  border-bottom-left-radius: 22px;
  z-index: ${({ step }) => (step === 2 || step === 4 || step === 5 || step === 6 || step === 7 ? '103' : '2')};
  pointer-events: ${({ step }) =>
    step === 2 || step === 4 || step === 5 || step === 6 || step === 7 ? 'none' : 'unset'};

  svg {
    width: ${({ isMobileCartOpen, step }) =>
      isMobileCartOpen || step === 4 || step === 5 || step === 6 || step === 7 ? '23px' : '20px'};
    fill: #fff;
    stroke: ${({ isMobileCartOpen, step }) =>
      isMobileCartOpen || step === 4 || step === 5 || step === 6 || step === 7 ? '#fff ' : 'unset'};
    margin-left: ${({ isMobileCartOpen, step }) =>
      isMobileCartOpen || step === 4 || step === 5 || step === 6 || step === 7 ? '0' : '7px '};
  }

  ${mqmax(desktopMinSize - 1)} {
    top: ${({ isMobileCartOpen, step }) => isMobileCartOpen || step === 4 || step === 5 || step === 6 || step === 7 ? '11%' : '10%'};
  }
`;

export const ShopPageCartMobileBackDrop = styled.div`
  position: fixed;
  inset: 0;
  background: #17171a;
  opacity: ${({ isMobileCartOpen }) => (isMobileCartOpen ? '0.5' : '0')};
  z-index: ${({ isMobileCartOpen }) => (isMobileCartOpen ? '1' : '-1')};
  transition: opacity 300ms ease-in-out;
`;

export const Description = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: left;
`;

export const Tooltip = CreateTooltip(Description);

export const ContainerLoading = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${overlayZIndex + 10};
`;

export const ShopOptions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${rem(20)};
`;

export const PorwerUpButton = styled.button`
  margin-left: 20px;  
  display: flex;  
  padding: 6px 20px;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  color: white;
  background: #a00bf6;
  transition: 1s;
  box-shadow: 6px 6px 0 black;
  transform: skewX(-15deg);
  opacity: 0.6;
  :focus {
    outline: none; 
  }
  :hover, &.active {
    opacity: 1;
    transition: 0.5s;
    box-shadow: 8px 8px 0 #2d80f2;
  }
  span {
    transform: skewX(15deg) 
  }
  span {
    width: 5px;
    margin-left: 5px;
    position: relative;
    top: 12%;
    transition: 0.5s;
    margin-right: 0px;
  }

  path.one {
    transition: 0.4s;
    transform: translateX(-60%);
  }

  path.two {
      transition: 0.5s;
      transform: translateX(-30%);
  }

  @keyframes color_anim {
    0% {
        fill: white;
    }
    50% {
        fill: #2d80f2;
    }
    100% {
        fill: white;
    }
  }

  :hover path.three, &.active path.three{
    animation: color_anim 1s infinite 0.2s;
  }

  :hover path.two, &.active path.two{
    transform: translateX(0%);
    animation: color_anim 1s infinite 0.4s;
  }

  :hover path.one, &.active path.one {
    transform: translateX(0%);
    animation: color_anim 1s infinite 0.6s;
}

  :hover span, &.active span {
    transition: 0.5s;
    margin-right: 5px;
  }

  ${mqmax(desktopMinSize - 1)} {
    font-size: 10px;
    padding: 5px 15px;
    box-shadow: 3px 3px 0 black;
    margin-left: 15px;  
    svg {
      height: 10px;
    }

    :hover, &.active {
      box-shadow: 6px 6px 0 #2d80f2;
    }
  }


`;
