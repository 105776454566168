import styled from "styled-components";
import { desktopMinSize, mqmax } from "../../styles/mixins";

export const GoldImagesPanelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    color: #FFF;

    ${mqmax(desktopMinSize - 1)}{
        padding: 0 2rem 2rem;
    }
`;