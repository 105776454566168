import Typography from '@material-ui/core/Typography';
import React from 'react';
import styled from 'styled-components';
import {
  mqmax,
  desktopMinSize
} from '@Styles/mixins';

export const PaymentSubtitle = props => <H3 variant="h3" {...props} />;

export default PaymentSubtitle;

const H3 = styled(Typography)`
  &.MuiTypography-h3 {
    margin: 0;
    color: #fff;
    font-size: calc(19 / 16 * 1rem);
    font-weight: 300;
    line-height: calc(32 / 24);
    text-align: center;
    ${mqmax(desktopMinSize - 1)} {
      font-size: calc(12 / 16 * 1rem);
    }
  }
`;
