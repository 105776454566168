import React from 'react';
import { TimeExpiredWrapper } from './styled';

export const TimeExpiredAlert = ({modalRef, close, startQueue}) => {

  return (
    <TimeExpiredWrapper ref={modalRef}>
      <div>
        <h2>Tempo expirado :&#40;</h2>
        <strong>
          Encontramos um computador e você não aceitou a tempo, gostaria de <span>voltar para a fila?</span>
        </strong>
        <div>
          <button onClick={startQueue}>sim</button>
          <button onClick={close}>não</button>
        </div>
      </div>
    </TimeExpiredWrapper>
  );
};
