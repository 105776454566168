import React from 'react';
import { MdOutlineClose } from 'react-icons/md';
import styled from 'styled-components';
import { REDIRECT_TO_SHOP, ORIGIN_CLASSIC } from '@Constants';
import { mqmax, desktopMinSize } from '@Styles/mixins';
import { useHistory } from 'react-router-dom';
import imagem from '@Assets/popup_fila_free.png';
import imagemMobile from '@Assets/popup_fila_free_mobile.png';
import Image from './Image';

export const QueuePopup = ({closeModal, modalRef}) => {
  const history = useHistory();

  const redirect = () => {
    localStorage.setItem('redirectShopPage', ORIGIN_CLASSIC);
    history.push(REDIRECT_TO_SHOP);
  }

  return (<Post ref={modalRef}>
        <div>
          <Image onClick={redirect} src={window.innerWidth >= desktopMinSize ? imagem : imagemMobile} />
          <button onClick={closeModal}>
            <MdOutlineClose style={{color: '#fff'}} />
          </button>
        </div>
      </Post>)
};

export default QueuePopup;

const Post = styled.dialog`
  max-width: 715px;
  height: 800px;
  border: 0;
  cursor: pointer;
  box-shadow: 0 0 0 100vw rgba(0, 0, 0, 0.5);
  overflow: hidden;
  padding: 0;
  border-radius: 30px;
  background: transparent;

  &[open] {
    animation: slide-up 350ms forwards, fade-in 350ms forwards;
  }

  &[closing] {
    display: block;
    pointer-events: none;
    inset: 0;
    animation: slide-down 350ms forwards, fade-out 350ms forwards;
  }

  & > div {
    position: relative;
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-direction: column;

    button {
      background: transparent;
      position: absolute;
      top: 2%;
      right: 2%;

      svg {
        width: 24px;
        height: 24px;
      }

      ${mqmax(desktopMinSize - 1)} {
        margin-top: 10px;
        top: 0;
        right: 2%;
      }
    }
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes slide-up {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0%);
    }
  }

  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes slide-down {
    0% {
      transform: translateY(0%);
    }
    100% {
      transform: translateY(100%);
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    max-width: 337px;
    height: 599px;
  }
` 