import styled from 'styled-components';

import { desktopMinSize, mqmax } from '@Styles/mixins';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: calc(34 / 16 * 1rem);
    padding: 0 0 1rem 0;
    ${mqmax(desktopMinSize - 1)} {
        padding: 0 calc(50 / 1056 * 100%) 2rem calc(50 / 1056 * 100%);
    }
`;

export default Container;
