import { Swiper, SwiperSlide } from 'swiper/react';
import { Scrollbar } from 'swiper';
import { AMISwiperWrapper } from '../../../styled';
import { CarrouselCardWrapper } from '../../../../../components/AMICarrousel/styled';

const AmiSliderDesktopWrapper = ({
  AMIs,
  checked,
  searchedNameValue,
  state,
  handleAMISectionPage,
  setActiveIndexAMI,
}) => {
  return (
    <>
      {AMIs?.filter(ami => {
        if (checked.length <= 0) {
          return ami;
        }
        return checked.find(item => item === ami.category);
      }).map(ami => (
        <>
          {ami?.image_list.filter(ami => {
            if (searchedNameValue === '') {
              return ami;
            }
            return ami?.image_name.toLocaleLowerCase().match(searchedNameValue.toLocaleLowerCase());
          }).length > 0 && (
            <>
              <AMISwiperWrapper step={state[0].step} key={ami?.category}>
                <strong>{ami?.category}</strong>
                <Swiper
                  spaceBetween={0}
                  slidesPerView={'auto'}
                  freeMode={true}
                  scrollbar={{
                    enabled: true,
                    draggable: true,
                    hide: false,
                  }}
                  modules={[Scrollbar]}
                >
                  {ami?.image_list
                    .filter(ami => {
                      if (searchedNameValue === '') {
                        return ami;
                      }
                      return ami?.image_name.toLowerCase().match(searchedNameValue.toLowerCase());
                    })
                    .map(ami =>
                      <SwiperSlide key={ami?.id} style={{ maxWidth: '260px' }}>
                        <CarrouselCardWrapper step={state[0].step}>
                          <img src={ami?.image_photo} alt="" />
                          <strong>{ami?.image_name}</strong>

                          <button
                            type="button"
                            onClick={() => {
                              handleAMISectionPage();
                              setActiveIndexAMI(ami?.id);
                            }}
                          >
                            Acessar
                          </button>
                        </CarrouselCardWrapper>
                      </SwiperSlide>
                    )}
                </Swiper>
              </AMISwiperWrapper>
            </>
          )}
        </>
      ))}
    </>
  );
};

export default AmiSliderDesktopWrapper;
