import { PlanInfoWrapper, TopInfoWrapper } from "../../../styled";
import Hello from "../../../Hello";

const HelloDesktopWrapper = ({handleGoBack, clientType}) => {
    const retrievePlanName = localStorage.getItem('selected_plan');
    return (
        <>
            <TopInfoWrapper>
                <PlanInfoWrapper>
                    <span>Noar {retrievePlanName}</span>
                </PlanInfoWrapper>
            </TopInfoWrapper>
            <Hello />
        </>
    );
};

export default HelloDesktopWrapper;