import { useEffect, useState } from 'react';
import { FormBox, FormContainer, GeneralWrapper, Overlay } from './styled';
import { Result, StepFive, StepFour, StepOne, StepThree, StepTwo } from './steps';
import Buttons from './buttons';
import { getPlansFormResult } from '../../../../services/noar-api';
import { Loading } from '../../../../components/Loading';
import { AiOutlineClose } from 'react-icons/ai';

const PlanForm = ({ handleShowPlanForm }) => {
  const [step, setStep] = useState(1);
  const [useType, setUseType] = useState();
  const [result, setResult] = useState();
  const [loading, setLoading] = useState(false);

  //Games
  const [gameFrequency, setGameFrequency] = useState();
  const [saveProgressComputerGames, setSaveProgressComputerGames] = useState();
  const [gameSessionTime, setGameSessionTime] = useState();

  //Study
  const [studyMoments, setStudyMoments] = useState();
  const [differentStudySoftware, setDifferentStudySoftware] = useState();
  const [storeFilesStudy, setStoreFilesStudy] = useState();

  //Work
  const [useNoarProfessional, setUseNoarProfessional] = useState();
  const [professionalStorage, setProfessionalStorage] = useState();

  //Misc Activities
  const [usedNoarActivities, setUsedNoarActivities] = useState();
  const [softwareDifferentActivities, setSoftwareDifferentActivities] = useState();
  const [storeFilesActivities, setStoreFilesActivities] = useState();

  function handleNextStep() {
    setStep(step + 1);
  }

  function handlePreviousStep() {
    setStep(step - 1);
  }

  async function submitForm(e) {
    e.preventDefault();
    const form = {
      noar_use: useType,
      study_moments: studyMoments,
      different_study_software: differentStudySoftware,
      store_files_study: storeFilesStudy,
      used_noar_activities: usedNoarActivities,
      software_different_activities: softwareDifferentActivities,
      store_files_activities: storeFilesActivities,
      use_noar_professional: useNoarProfessional,
      professional_storage: professionalStorage,
      game_frequency: gameFrequency,
      save_progress_computer_game: saveProgressComputerGames,
      game_session_time: gameSessionTime,
    };
    setLoading(true);
    await getPlansFormResult(form)
      .then(res => {
        console.log(res);
        setResult(res?.plan_result);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
      });
  }

  useEffect(() => {
    if (result) {
      handleNextStep();
    }
  }, [result]);

  return (
    <GeneralWrapper>
      <Overlay />
      <FormContainer>
        <button className="close-button" onClick={handleShowPlanForm}>
          <AiOutlineClose style={{ width: '21px', height: '21px', opacity: 0.5, color: '#fff' }} fontWeight="bold" />
        </button>
        <FormBox>
          {loading ? (
            <Loading />
          ) : (
            <>
              {step === 1 ? <StepOne handleNextStep={handleNextStep} step={step} /> : []}
              {step === 2 ? <StepTwo useType={useType} onClickUseType={setUseType} /> : []}
              {step === 3 ? (
                <StepThree
                  useType={useType}
                  gameFrequency={gameFrequency}
                  setGameFrequency={setGameFrequency}
                  studyMoments={studyMoments}
                  setStudyMoments={setStudyMoments}
                  useNoarProfessional={useNoarProfessional}
                  setUseNoarProfessional={setUseNoarProfessional}
                  usedNoarActivities={usedNoarActivities}
                  setUsedNoarActivities={setUsedNoarActivities}
                />
              ) : (
                []
              )}
              {step === 4 ? (
                <StepFour
                  useType={useType}
                  saveProgressComputerGames={saveProgressComputerGames}
                  setSaveProgressComputerGames={setSaveProgressComputerGames}
                  differentStudySoftware={differentStudySoftware}
                  setDifferentStudySoftware={setDifferentStudySoftware}
                  professionalStorage={professionalStorage}
                  setProfessionalStorage={setProfessionalStorage}
                  softwareDifferentActivities={softwareDifferentActivities}
                  setSoftwareDifferentActivities={setSoftwareDifferentActivities}
                />
              ) : (
                []
              )}
              {step === 5 ? (
                <StepFive
                  result={result}
                  useType={useType}
                  gameSessionTime={gameSessionTime}
                  setGameSessionTime={setGameSessionTime}
                  storeFilesStudy={storeFilesStudy}
                  setStoreFilesStudy={setStoreFilesStudy}
                  storeFilesActivities={storeFilesActivities}
                  setStoreFilesActivities={setStoreFilesActivities}
                />
              ) : (
                []
              )}

              {step > 5 ? <Result result={result} /> : []}

              {step !== 1 ? (
                <Buttons
                  handlePreviousStep={handlePreviousStep}
                  handleNextStep={handleNextStep}
                  step={step}
                  useType={useType}
                  gameFrequency={gameFrequency}
                  saveProgressComputerGames={saveProgressComputerGames}
                  gameSessionTime={gameSessionTime}
                  studyMoments={studyMoments}
                  differentStudySoftware={differentStudySoftware}
                  storeFilesStudy={storeFilesStudy}
                  useNoarProfessional={useNoarProfessional}
                  professionalStorage={professionalStorage}
                  storeFilesActivities={storeFilesActivities}
                  usedNoarActivities={usedNoarActivities}
                  softwareDifferentActivities={softwareDifferentActivities}
                  submitForm={submitForm}
                  result={result}
                />
              ) : (
                []
              )}
            </>
          )}
        </FormBox>
      </FormContainer>
    </GeneralWrapper>
  );
};

export default PlanForm;
