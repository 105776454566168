import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Container from './Container';
import Image from './Image';
import image from './image.svg';
import { messages } from './messages';
import { initLogout, openConfirmationLightbox } from '@Store/ducks/global/actions';
import { REDIRECT_TO_LOGIN } from '@Constants';

export const Logout = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleClick = useCallback(
    function () {
      dispatch(initLogout());
      history.push(REDIRECT_TO_LOGIN);
    },
    [dispatch, history],
  );

  const onClickLogOut = useCallback(
    function () {
      dispatch(
        openConfirmationLightbox(handleClick, messages.message, messages.confirmBtnText, messages.refuseBtnText, null, null, false),
      );
    },
    [handleClick, dispatch],
  );

  return (
    <Container onClick={onClickLogOut}>
      <Image src={image} />
    </Container>
  );
};

export default Logout;
