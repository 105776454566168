import styled from 'styled-components';
import {
  mqmax,
  desktopMinSize
} from '@Styles/mixins';

export const Desktop = styled.div`
  ${mqmax(desktopMinSize - 1)} {
    display: none;
  }
`;

export default Desktop;
