import { Computer, Keyboard, MemoryOutlined, StorageOutlined } from "@material-ui/icons";

export const SESSION_COOKIE_NAME = 'noar-session';
export const REDIRECT_AFTER_LOGIN = '/my/home';
export const REDIRECT_TO_LOGIN = '/login';
export const REDIRECT_TO_CONFIRMATION_SUCCESS = '/register/confirmation/success';
export const REDIRECT_TO_SHOP = '/shop/personal';
export const REDIRECT_TO_CREATE_PASSWORD = '/password/create';
export const REDIRECT_TO_UPDATE_PASSWORD_MOBILE = '/update/password';
export const REDIRECT_AFTER_REGISTER = '/register/confirmation';
export const REDIRECT_TO_MAINTENANCE = '/maintenance';
export const COMPUTER_STATES = {
  STOPPED: 'STOPPED',
  PENDING: 'PENDING',
  RUNNING: 'RUNNING',
  STOPPING: 'STOPPING',
  QUEUE: 'QUEUE',
};
export const API_PAGARME_BASE = 'https://api.pagar.me/core/v5';
export const API_PAGARME_PUBLIC_TOKEN = process.env.REACT_APP_API_PAGARME_PUBLIC_TOKEN;
export const API_NOAR_BASE = process.env.REACT_APP_API_NOAR_BASE;
export const NOAR_SITE = process.env.REACT_APP_SITE;
export const SESSION_DEFAULT_SIZE = 5;
export const IMAGES_URL = 'https://images.noarcloud.com';
export const TERMS_PRIVACY_URL = '/terms/POLITICA_DE_PRIVACIDADE.pdf';
export const TERMS_USE_URL = '/terms/TERMOS_DE_USO.pdf';
export const IMAGES_URL_WEBSITE = 'https://siteimagesbucket.s3.us-east-1.amazonaws.com/website';
export const CLIENT_TYPES = {
  NOAR_LAN: 'NOAR_LAN',
  NOAR_PERSONAL: 'NOAR_PERSONAL',
};
export const NPS_FORM_RESPONSE_TYPES = {
  ANSWERED: 'ANSWERED',
  IGNORED: 'IGNORED',
};

export const MP_PUBLIC_KEY = process.env.REACT_APP_MP_PUBLIC_KEY;

export const messageDisconnect = {
  message: 'Sua sessão expirou! Você será direcionado para a tela de Login.',
  confirmBtnText: 'Ok',
  refuseBtnText: undefined
}
export const ORIGIN_CLASSIC = 'Classic';
export const ORIGIN_UNIT = 'UNIT';
export const ORIGIN_CORUJAO = 'EVENT';
export const ORIGIN_FREE = 'FREE';
export const ORIGIN_PERSONAL = 'PERSONAL';
export const ENTERPRISE = 'ENTERPRISE';


export const PRIORITY_QUEUE_TYPE = 'UNIT Premium ☜(ˆ▿ˆc)';
export const DEPRIORITIZED_QUEUE_TYPE = 'UNIT ☜(ˆ▿ˆc)';
export const FREE_QUEUE_TYPE = 'gratuita ☜(ˆ▿ˆc)';


export const PAID = 'PAID';
export const DENIED = 'DECLINED';
export const PENDING = 'IN_ANALYSIS';
export const ERROR = 'ERROR';
export const EXPIRED = 'EXPIRED';
export const UNENROLLED = 'UNENROLLED';
export const OK = 'OK';

export const QUEUE = 'QUEUE';
export const WAITING_CONFIRMATION = 'WAITING_CONFIRMATION';
export const LOST_PLAYBOY = 'LOST_PLAYBOY';
export const SERVING = 'SERVING';

export const SELECTED_PLAN_KEY = 'selected_plan';

export const PUBLICKEYPAGSEGURO = 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA2/FCRhKB+2cxdxng9Z/4Q0RUVG6S7CTOaieWzZ3BIz+v9kKROuN8PtmeXNIOC4ICt2fjKJKeq2Om0miVhTUtMfp2PUIBrzbmy7QSPfgtFG6MrLxw0+YAx3wuvs3MzkkZ8LSRxsQQ4I15VkSuwuBM5t9oJ026Tfb2LnHK40v2E29th9KSCtigtAnpOcunvbMfOUDLDHiop8IhDwh2dBX9CLZ9c7oF/6NVnfKFXx5u7TM4Pk83+lmQGBBY7qtrpRWFVqwGnoq6bUt55fv5xOJkDgObMlOuFDD2kFw/2Hnp1KA74FoS9eYUT9dvmO4lsJ3/RYnuyAplKMuDs320bZnKPwIDAQAB'

export const ENV = process.env.REACT_APP_ENV;

export const COMPUTER_INFOS = [
  {
    icon: <StorageOutlined />,
    title: 'SSD',
    description: 'Premium Disk',
    price: 'Incluso no plano'
  },
  {
    icon: <Keyboard />,
    title: 'Memória',
    description: '32 GB',
    price: 'Incluso no plano'
  },
  {
    icon: <Computer />,
    title: 'Placa de vídeo',
    description: 'NVIDIA Tesla T4',
    price: 'Incluso no plano'
  },
  {
    icon: <MemoryOutlined />,
    title: 'vCPU',
    description: '8vCore Xeon',
    price: 'Incluso no plano'
  },
]