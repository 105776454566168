import React from 'react';
import Container from './Container';
import Header from './Header';
import UpdatePasswordContent from './UpdatePasswordContent';

function UpdatePassword() {
  return (
    <Container>
      <Header />
      <UpdatePasswordContent />
    </Container>
  );
}

export default UpdatePassword;
