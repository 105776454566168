import styled from 'styled-components';
import { rem, desktopMinSize, mqmax } from '@Styles/mixins';

export const CarrouselCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 14rem;
  max-width: 241px;
  min-height: 219px;
  border-radius: 28px;
  position: relative;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: -129px;
    background: transparent linear-gradient(180deg, #00000000 0%, #000000 100%) 0% 0% no-repeat padding-box;
    z-index: 1;
    border-bottom-left-radius: 28px;
    border-bottom-right-radius: 28px;
  }

  strong {
    font-weight: 600;
    color: #fff;
    display: block;
    z-index: 3;
  } 

  img {
    width: 100%;
    height: 100%;
    max-width: 241px;
    min-height: 219px;
    z-index: 2;
    position: absolute;
    inset: 0;
    object-fit: cover;
    object-position: center;
  }

  button {
    max-width: 120px;
    width: 100%;
    height: 30px;
    border-radius: 28px;
    background-color: transparent;
    outline: 3px solid #2d80f2;
    outline-offset: -3px;
    border: 0;
    font-size: ${rem(14)};
    font-weight: 600;
    color: #2d80f2;
    display: block;
    margin-bottom: 25px;
    text-transform: uppercase;
    transition: all 150ms;
    opacity: 0;
    max-height: 0;
    z-index: -1;

    &:hover {
      background-color: #2d80f2;
      color: #fff;
    }
  }
  &:hover {
    button {
      margin-top: 23px !important;
      opacity: 1 !important;
      max-height: 200px !important;
      z-index: 3 !important;
      position: relative;
    }

    &:after {
      bottom: -10px;
      z-index: 2;
      opacity: 0.3;
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    display: none;
  }
`;

export const FilterInput = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  position: relative;
  justify-content: center;

  & > input {
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    max-width: 547px;
    width: 100%;
    height: 37px;
    border: 1px solid #fff;
    border-radius: 100vmax;
    background: transparent;
    padding-left: 18px;
    position: relative;
    transition: background-color 250ms;
    color: #fff;

    &::placeholder {
      font-size: 0.938rem;
      opacity: 0.23;

      ${mqmax(desktopMinSize - 1)} {
        font-size: 0.813rem;
      }
    }

    &:focus {
      outline: 0;
      background-color: #fff;
      color: #17171a;

      &::placeholder {
        opacity: 1;
        color: #17171a;
        font-weight: 400;
      }

      & + label > svg {
        stroke: #17171a;
      }
    }

    ${mqmax(desktopMinSize - 1)} {
      max-width: 264px;
      height: 33px;
    }
  }

  & > label {
    position: relative;
    right: 32px;
    top: 2px;
    cursor: pointer;

    svg {
      width: 14px;
      height: 14px;
    }
  }

  & > div > button {
    background-color: transparent;
    width: 34px;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #fff;
    border-radius: 7px;

    svg {
      width: 18px;
      height: 18px;
      fill: #fff;
    }
  }

  & > div:has(button):nth-of-type(1) {
    position: relative;
  }

  & > div:has(button):nth-of-type(2) {
    position: relative;
  }

  & > div > button:nth-of-type(1) {
    margin-right: 10px;
    svg {
      width: 25px;
      height: 25px;
      fill: #fff;
    }

    ${mqmax(desktopMinSize - 1)} {
      margin-right: 0;
    }
  }

  & > div:nth-of-type(2) > button {
    ${mqmax(desktopMinSize - 1)} {
      display: none;
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    margin-bottom: 23px;
  }
`;
