import styled from "styled-components";
import { desktopMinSize, mqmax } from "../../../styles/mixins";

export const PlansBusinessContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 0.5rem;  
`;

export const PlansBusinessItem = styled.p`
    font-style: normal;
    font-weight: 200;
    font-size: 18px;

    ${mqmax(desktopMinSize -1)} {
        font-size: 16px;
        line-height: 1.1rem;
    }
`;

export const PriceWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 0.5rem;
`;

export const PriceTitle = styled.span`
    font-style: normal;
    font-weight: 400;
    font-size: 20px;

    ${mqmax(desktopMinSize -1)} {
        font-size: 16px;
    }
`;

export const PriceContent = styled.span`
    font-style: normal;
    font-weight: 700;
    font-size: 45px;

    ${mqmax(desktopMinSize -1)} {
        font-size: 35px;
    }
`;

export const PlansBusinessContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 1.5rem;
`;