import styled from 'styled-components';

export const Form = styled.form`
    width: 100%;
    height: auto;
    grid-gap: calc(20 / 16 * 1rem);
    display: flex;
    flex-direction: column;
`;

export default Form;
