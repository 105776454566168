import styled from 'styled-components';

import {
  mqmin,
  transform,
  desktopMinSize,
  defaultFont,
  headerZIndex,
} from '@Styles/mixins';

export const Hello = styled.section`
  width: 79.4%;
  height: auto;
  margin: 40px auto 0;
  ${mqmin(desktopMinSize)} {
    display: none;
  }

  .hello {
    &__mobile-customer-name {
      width: 100%;
      height: auto;
      font-family: ${defaultFont};
      font-weight: 300;
      font-style: normal;
      font-size: 32px;
      color: #1724a9;
      text-decoration: none;
      text-align: left;
      letter-spacing: 0;
      line-height: 44px;

      .customer-name {
        position: relative;
        font-weight: 650;

        &::after {
          display: none;
          position: absolute;
          bottom: 0;
          left: 50%;
          ${transform('translate(-50%, 100%)')}
          padding: 5px;
          border-radius: 3px;
          content: attr(data-complete-name);
          background-color: #ffffff;
          font-family: ${defaultFont};
          font-weight: 300;
          font-style: normal;
          font-size: 32px;
          color: #000000;
          text-decoration: none;
          text-align: center;
          white-space: nowrap;
          letter-spacing: 0;
          line-height: 44px;
          z-index: ${headerZIndex} - 1;
        }

        &:hover::after {
          display: block;
        }
      }
    }
  }
`;
