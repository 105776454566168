import React from 'react';
import styled from 'styled-components';

import image from './image.png';
import { desktopMinSize, mq, mqmin } from '../../../../styles/mixins';

const H1 = props => (
  <h1 {...props}>
    <Img alt="Noar Cloud Gaming" src={image} />
  </h1>
);

export const Title = styled(H1)`
  display: flex;
  position: relative;
  overflow: visible;
`;

export const Img = styled.img`
  ${mq(1439, 1080)} {
    width: 400px;
    height: auto;
  }

  ${mq(1919, 1440)} {
    width: 600px;
    height: auto;
  }

  ${mqmin(1920)} {
    width: 742px;
    height: auto;
  }
`;

export default Title;
