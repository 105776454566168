import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

import { desktopMinSize, mqmin } from '@Styles/mixins';

import noar from './noar.svg';

import Redes from './Redes';
import Scroll from './Scroll';

function Container(props) {
  return (
    <footer {...props}>
      <Scroll />
      <Address>
        <Noar>
          <Link to="/">
            <Logo alt="" src={noar} width={47} height={32} />
            <span>Noar Cloud</span>
          </Link>
          <Infos>39.348.403/0001-61</Infos>
          <Infos>Rua Barão de Itaim, 110</Infos>
          <Infos>04720-030, São Paulo/SP</Infos>
          <Infos>contato@noarcloud.com</Infos>
        </Noar>
        <Texts>
          <Text>Copyright © 2023 Noar - Todos os direitos reservados</Text>
          <TextLink
            as="a"
            href="https://www.noarcloud.com/terms/POLITICA_DE_PRIVACIDADE.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Política de Privacidade
          </TextLink>
          <TextLink as="a" href="https://www.noarcloud.com/terms/TERMOS_DE_USO.pdf" target="_blank" rel="noreferrer">
            Termos de Serviço
          </TextLink>
        </Texts>
        <Redes />
      </Address>
    </footer>
  );
}

const Logo = styled.img`
  width: calc(${({ width }) => width}rem / 16);
`;

const Link = styled(RouterLink)`
  --fontSize: 18;

  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: calc(14rem / 16);
  color: #fff;
  font-size: calc(var(--fontSize) * 1rem / 16);
  line-height: calc(28 / var(--fontSize));
  text-decoration: none;

  ${mqmin(desktopMinSize)} {
    align-items: stretch;
  }
`;

const Infos = styled.p`
  --fontSize: 15;

  color: #fff;
  font-size: calc(var(--fontSize) * 1rem / 16);
  line-height: calc(23 / var(--fontSize));
`;

const Noar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mqmin(desktopMinSize)} {
    align-items: stretch;
  }
`;

const Text = styled.p`
  --fontSize: 12;

  color: #868686;
  font-size: calc(var(--fontSize) * 1rem / 16);
  line-height: calc(23 / 15);

  ${mqmin(desktopMinSize)} {
    --fontSize: 15;
  }
`;

const TextLink = styled(Text)`
  text-decoration: none;
  transition: color calc(1s / 3) ease-in-out;

  &:hover {
    color: #fff;
  }
`;

const Texts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  column-gap: calc(41rem / 40);
  text-align: center;

  ${mqmin(desktopMinSize)} {
    flex-direction: row;
    align-items: stretch;
    column-gap: calc(41rem / 16);
    text-align: start;
  }
`;

const Address = styled.address`
  display: flex;
  flex-direction: column;
  row-gap: calc(90rem / 40);
  align-items: center;
  justify-content: space-between;

  ${mqmin(desktopMinSize)} {
    flex-direction: row;
    column-gap: calc(90rem / 16);
    align-items: end;
  }
`;

export const Footer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: calc(93rem / 40);
  padding-bottom: calc(186rem / 40);

  ${mqmin(desktopMinSize)} {
    row-gap: calc(93rem / 16);
    padding-bottom: calc(186rem / 16);
  }
`;

export default Footer;
