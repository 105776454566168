import styled from 'styled-components';

import { desktopMinSize, mqmax, mqmin, rem } from '@Styles/mixins';

export const Container = styled.div`
  width: 90%;
  margin: ${rem(32)} auto;
  border-radius: ${rem(8)};
  font-family: 'Poppins', sans-serif;
  position: relative;

  ${mqmin(desktopMinSize)} {
    width: 80%;
    display: none;
  }

  ${mqmax(desktopMinSize - 1)} {
    display: block;
    & > strong {
      font-size: 25px !important;
      margin: 0 0 15px 0 !important;
      max-width: 9ch;
    }
  }
  & > strong {
    color: #fff;
    font-size: ${rem(47)};
    font-weight: 600;
    display: block;
    text-align: left;
    margin-bottom: 44px;
  }
`;

export const AccordionWrapper = styled.div`
  background-color: #21222a;
  color: white;
  padding: 21px;
  border-radius: 22px;
  max-width: 329px;

  ${mqmax(desktopMinSize - 1)} {
    strong {
      font-size: 16px !important;
      margin: 0 !important;
      font-weight: 600;
      max-width: 100%;

      time {
        font-weight: 300;
      }
    }
  }

  & + div {
    margin-top: ${rem(10)};
  }
`;

export const AdminAccordionWrapper = styled.div`
  background-color: transparent;
  color: white;
  padding: 0 21px;
  border-radius: 21px;
  max-width: 329px;
  border: 1px solid #fff;
  min-height: 67px;

  ${mqmax(desktopMinSize - 1)} {
    strong {
      font-size: 16px !important;
      margin: 0 !important;
      font-weight: 600;
      max-width: 100%;

      time {
        font-weight: 300;
      }
    }
  }

  & + div {
    margin-top: ${rem(10)};
  }
`;

export const AdminAccordionTitle = styled.div`
  margin-top: 21px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  svg {
    transform: rotate(${({ isAccordionArrowUp }) => (isAccordionArrowUp ? 180 : 0)}deg);
    transition: transform 250ms ease-in-out;
  }

  strong {
    color: ${({ active }) => (!active ? "#F83030" : "#FFFFFF")} !important;
    white-space: normal;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
`;

export const StatusStrong = styled.strong`
  color: ${({ active }) => (!active ? "#F83030" : "#FFFFFF")} !important;
`

export const AccordionTitle = styled.div`
  margin-top: 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  svg {
    transform: rotate(${({ isAccordionArrowUp }) => (isAccordionArrowUp ? 180 : 0)}deg);
    transition: transform 250ms ease-in-out;
  }
`;

export const AdminAccordionAnswer = styled.div`
  max-height: ${({ isAccordionOpen }) => (isAccordionOpen ? 300 : 0)}px;
  transition: max-height 350ms ease-in-out, margin 350ms ease-in-out;
  overflow: hidden;
  margin-top: ${({ isAccordionOpen }) => (isAccordionOpen ? 7 : 0)}px;
  margin-bottom: ${({ isAccordionOpen }) => (isAccordionOpen ? 26 : 0)}px;

  ${mqmax(desktopMinSize - 1)} {
    strong {
      font-size: ${rem(12)} !important;
      white-space: normal;
      word-wrap: break-word;
      overflow-wrap: break-word;

      span {
        font-weight: 300;
      }

      & + strong {
        margin-top: ${rem(7)} !important;
        display: block;
      }
    }
  }
`;

export const AccordionAnswer = styled.div`
  max-height: ${({ isAccordionOpen }) => (isAccordionOpen ? 300 : 0)}px;
  transition: max-height 350ms ease-in-out;
  overflow: hidden;
  margin-top: 7px;

  ${mqmax(desktopMinSize - 1)} {
    strong {
      font-size: ${rem(12)} !important;

      span {
        font-weight: 300;
      }

      & + strong {
        margin-top: ${rem(7)} !important;
        display: block;
      }
    }
  }
`;
