import React from 'react';

import Container from './Container';
import Header from './Header';
import Content from './Content';
import {
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';

export const LoginBox = () => (
  <Container>
    <Header />
    <GoogleReCaptchaProvider reCaptchaKey="6LeAu3gpAAAAAC4LbHd640JhvQ9CJ1RXIZv1wdSa" >
      <Content />
    </GoogleReCaptchaProvider>
  </Container>
);

export default LoginBox;
