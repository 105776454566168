import styled from 'styled-components';
import { overlayOpacity } from '@Styles/mixins';

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, ${overlayOpacity});
  opacity: 1;
  z-index: 1;
  transition: opacity 350ms, z-index 350ms, background-color 500ms;
  display: block;
`;

export default Overlay;
