import styled from "styled-components";

export const BenefitTitle = styled.h3`
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
`;

export const BenefitP = styled.p`
    font-style: normal;
    font-weight: 200;
    font-size: 16px;
    line-height: 21px;
`;

export const BenefitLine = styled.div`
    width: 0px;
    height: 80px;
    border: 2.5px solid ${({ color }) => color};
    border-radius: 10px;
`;

export const BenefitContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
`;

export const BenefitItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 1rem;
`;


export const Benefits = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    gap: 2.5rem;
`;
