import styled from 'styled-components';
import { desktopMinSize, mqmax } from '@Styles/mixins';

export const AMITutorialContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 200px;
  left: 50%;
  z-index: 103;
  transform: translate(-54%);

  ${mqmax(desktopMinSize - 1)} {
    position: fixed;
    left: 55%;
  }
`;

export const AMITutorialWrapper = styled.div`
  position: relative;
  background-color: #21222a;
  max-width: 745px;
  margin: 0 auto;
  height: 479px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-radius: 49px;

  &:before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 49px;
    padding: 3px;
    background: linear-gradient(133deg, #2d80f2 0%, #9b0cee 100%);
    mask: linear-gradient(133deg, #2d80f2 0%, #9b0cee 100%) content-box,
      linear-gradient(133deg, #2d80f2 0%, #9b0cee 100%);
    -webkit-mask: linear-gradient(133deg, #2d80f2 0%, #9b0cee 100%) content-box,
      linear-gradient(133deg, #2d80f2 0%, #9b0cee 100%);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  strong {
    display: block;
    margin-top: 59px;
    font-size: 2.25rem;
    text-align: center;
    line-height: 1.4;
    span {
      text-transform: uppercase;
    }
  }

  p {
    font-size: 1.375rem;
    text-align: center;

    &:nth-of-type(1) {
      span {
        text-transform: uppercase;
      }
    }

    &:nth-of-type(2) {
      max-width: 33ch;
      line-height: 1.4;
      span {
        text-transform: uppercase;
      }
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    max-width: 307px;
    height: 313px;
    justify-content: center;
    gap: 1rem;
    border-radius: 40px;

    strong {
      margin-top: 40px;
      font-size: 1.25rem;
      line-height: 26px;
    }

    p {
      font-size: 12px;
      line-height: 1rem;

      &:nth-of-type(1) {
        max-width: 29ch;
      }
    }
  }
`;

export const AMITutorialButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 4;

  button {
    border: 0;
    background-color: transparent;

    &:nth-of-type(1) {
      max-width: 170px;
      width: 100%;
      border-radius: 37px;
      height: 50px;
      outline: 3px solid #2d80f2;
      color: #2d80f2;
      outline-offset: -3px;
      text-transform: uppercase;
      font-weight: 600;
      transition: all 300ms;
      font-size: 0.9375rem;

      &:hover {
        background-color: #2d80f2;
        color: #fff;
      }
    }

    &:nth-of-type(2) {
      margin: 15px 0 36px 0;
      border-radius: 37px;
      color: #2d80f2;
      font-weight: 300;
      font-size: 1rem;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;
      transition: border 300ms;
      width: 100%;
      max-width: fit-content;
      border-radius: 0;

      &:hover {
        border-bottom-color: #2d80f2;
      }
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    button {
      &:nth-of-type(1) {
        max-width: 142px;
        height: 38px;
        font-size: 12px;
        outline-width: 2px;
        outline-offset: -2px;
      }

      &:nth-of-type(2) {
        font-size: 12px;
      }
    }
  }
`;

export const TutorialBackdrop = styled.div`
  position: fixed;
  inset: 0;
  background: rgba(23, 23, 26, 0.7);
  z-index: 101;
`;

export const AMITutorialFirstStepWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  z-index: 103;
  line-height: 1.2;
  top: 123px;

  ${mqmax(desktopMinSize - 1)} {
    justify-content: center;
    top: 43px;
    left: -6px;
    position: absolute;
    width: 100%;
  }
`;

export const AMITutorialFirstStepContentWrapper = styled.div`
  position: absolute;
  max-width: 230px;
  width: 100%;
  height: 96px;
  background-color: #2d80f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  border-radius: 25px 25px 25px 2px;
  box-shadow: 0px 9px 16px #00000026;

  strong {
    font-weight: 300;

    span {
      font-weight: 600;
    }
  }
  ${mqmax(desktopMinSize - 1)} {
    position: unset;
    max-width: 228px;
    height: 100px;
    font-size: 0.938rem;
    line-height: 20px;
  }
`;

export const AMITutorialFirstStepButtonWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  button {
    border: 0;
    background-color: transparent;

    &:nth-of-type(1) {
      max-width: 100px;
      width: 100%;
      border-radius: 21px;
      height: 29px;
      outline: 2px solid #fff;
      color: #fff;
      outline-offset: -2px;
      text-transform: uppercase;
      font-weight: 600;
      transition: all 300ms;
      font-size: 0.688rem;
      line-height: 0 !important;
      &:hover {
        background-color: #fff;
        color: #2d80f2;
      }
    }

    &:nth-of-type(2) {
      color: #fff;
      font-weight: 300;
      transition: all 300ms;
      font-size: 0.75rem;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;
      transition: border 300ms;
      width: 100%;
      max-width: fit-content;
      border-radius: 0;

      &:hover {
        border-bottom-color: #fff;
      }
    }
  }
`;

export const AMITutorialSecondStepWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  top: 575px;
  left: 300px;
  z-index: 103;
  ${mqmax(desktopMinSize - 1)} {
    top: 309px;
    left: 20px;
    position: absolute;
    width: 100%;
  }
`;

export const AMITutorialSecondStepContentWrapper = styled.div`
  max-width: 230px;
  width: 100%;
  height: 76px;
  background-color: #2d80f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  border-radius: 25px 25px 25px 2px;
  box-shadow: 0px 9px 16px #00000026;
  line-height: 1.2;

  strong {
    font-weight: 300;

    span {
      font-weight: 600;
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    font-size: 0.938rem;
    line-height: 20px;
    border-radius: 2px 25px 25px 25px;
  }
`;

export const AMITutorialSecondStepButtonWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  button {
    border: 0;
    background-color: transparent;

    &:nth-of-type(1) {
      max-width: 100px;
      width: 100%;
      border-radius: 21px;
      height: 29px;
      outline: 2px solid #fff;
      color: #fff;
      outline-offset: -2px;
      text-transform: uppercase;
      font-weight: 600;
      transition: all 300ms;
      font-size: 0.688rem;

      &:hover {
        background-color: #fff;
        color: #2d80f2;
      }
    }

    &:nth-of-type(2) {
      color: #fff;
      font-weight: 300;
      transition: all 300ms;
      font-size: 0.75rem;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;
      transition: border 300ms;
      width: 100%;
      max-width: fit-content;
      border-radius: 0;

      &:hover {
        border-bottom-color: #fff;
      }
    }
  }
`;

export const AMITutorialThirdStepWrapper = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  top: 10px;
  left: 57px;
  z-index: 103;
  ${mqmax(desktopMinSize - 1)} {
    top: -20px;
    left: -14px;
  }
`;

export const AMITutorialThirdStepContentWrapper = styled.div`
  position: absolute;
  max-width: 349px;
  width: 100%;
  height: 95px;
  background-color: #2d80f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  border-radius: 25px 25px 25px 2px;
  box-shadow: 0px 9px 16px #00000026;

  strong {
    font-weight: 300;

    span {
      font-weight: 600;
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    max-width: 260px;
    height: 119px;
    font-size: 0.938rem;
    line-height: 20px;
    border-radius: 25px 25px 25px 2px;
  }
`;

export const AMITutorialThirdStepButtonWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  button {
    border: 0;
    background-color: transparent;

    &:nth-of-type(1) {
      max-width: 100px;
      width: 100%;
      border-radius: 21px;
      height: 29px;
      outline: 2px solid #fff;
      color: #fff;
      outline-offset: -2px;
      text-transform: uppercase;
      font-weight: 600;
      transition: all 300ms;
      font-size: 0.688rem;
      line-height: 0 !important;
      &:hover {
        background-color: #fff;
        color: #2d80f2;
      }
    }

    &:nth-of-type(2) {
      color: #fff;
      font-weight: 300;
      transition: all 300ms;
      font-size: 0.75rem;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;
      transition: border 300ms;
      width: 100%;
      max-width: fit-content;
      border-radius: 0;

      &:hover {
        border-bottom-color: #fff;
      }
    }
  }
`;

export const AMITutorialFourthStepWrapper = styled.div`
  width: 95%;
  position: absolute;
  display: flex;
  justify-content: flex-end;
  top: 159px;
  left: 70px;
  z-index: 103;

  ${mqmax(desktopMinSize - 1)} {
    width: 100%;
    top: 47px;
    left: 10px;
    justify-content: center;
  }

  @media screen and (max-height: 680px) {
    top: 27px;
  }
`;

export const AMITutorialFourthStepContentWrapper = styled.div`
  position: absolute;
  max-width: 352px;
  width: 100%;
  height: 95px;
  background-color: #2d80f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  border-radius: 25px 25px 25px 2px;
  box-shadow: 0px 9px 16px #00000026;

  strong {
    font-weight: 300;

    span {
      font-weight: 600;

      &:nth-of-type(2) {
        font-weight: 300;
        font-size: 0.813rem;
      }
    }
  }
  ${mqmax(desktopMinSize - 1)} {
    max-width: 260px;
    height: 114px;
    font-size: 0.938rem;
    line-height: 20px;
  }
`;

export const AMITutorialFourthStepButtonWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  button {
    border: 0;
    background-color: transparent;

    &:nth-of-type(1) {
      max-width: 100px;
      width: 100%;
      border-radius: 21px;
      height: 29px;
      outline: 2px solid #fff;
      color: #fff;
      outline-offset: -2px;
      text-transform: uppercase;
      font-weight: 600;
      transition: all 300ms;
      font-size: 0.688rem;

      &:hover {
        background-color: #fff;
        color: #2d80f2;
      }
    }

    &:nth-of-type(2) {
      color: #fff;
      font-weight: 300;
      transition: all 300ms;
      font-size: 0.75rem;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;
      transition: border 300ms;
      width: 100%;
      max-width: fit-content;
      border-radius: 0;

      &:hover {
        border-bottom-color: #fff;
      }
    }
  }
`;

export const AMITutorialFifthStepWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 243px;
  left: 50%;
  transform: translateX(-40%);
  z-index: 103;
  ${mqmax(desktopMinSize - 1)} {
    display: flex;
    justify-content: center;
    top: 125px;
    left: -3px;
    transform: translateX(0);
  }

  @media screen and (max-height: 680px) {
    top: 90px;
  }
`;

export const AMITutorialFifthStepContentWrapper = styled.div`
  position: absolute;
  max-width: 349px;
  width: 100%;
  height: 95px;
  background-color: #2d80f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  border-radius: 25px 25px 2px 25px;
  box-shadow: 0px 9px 16px #00000026;

  strong {
    font-weight: 300;
    text-align: left;

    span {
      font-weight: 600;
    }
  }
  ${mqmax(desktopMinSize - 1)} {
    border-radius: 25px;
    max-width: 260px;
    height: 114px;
    font-size: 0.938rem;
    line-height: 20px;

    &:after {
      content: '';
      background: #2d80f2;
      position: absolute;
      width: 27px;
      height: 13.5px;
      left: 167px;
      top: 162px;
      z-index: 102;
      clip-path: polygon(100% 0, 0 0, 50% 100%);
    }
  }
`;

export const AMITutorialFifthStepButtonWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  button {
    border: 0;
    background-color: transparent;

    &:nth-of-type(1) {
      max-width: 100px;
      width: 100%;
      border-radius: 21px;
      height: 29px;
      outline: 2px solid #fff;
      color: #fff;
      outline-offset: -2px;
      text-transform: uppercase;
      font-weight: 600;
      transition: all 300ms;
      font-size: 0.688rem;

      &:hover {
        background-color: #fff;
        color: #2d80f2;
      }
    }

    &:nth-of-type(2) {
      color: #fff;
      font-weight: 300;
      transition: all 300ms;
      font-size: 0.75rem;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;
      transition: border 300ms;
      width: 100%;
      max-width: fit-content;
      border-radius: 0;

      &:hover {
        border-bottom-color: #fff;
      }
    }
  }
`;

export const AMITutorialSixthStepWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 28px;
  left: 697px;
  z-index: 103;
  line-height: 1.2;
  ${mqmax(desktopMinSize - 1)} {
    display: flex;
    justify-content: center;
    top: 50px;
    left: 216px;
  }
  ${mqmax(desktopMinSize - 380)} {
    left: 100px;
  }
  ${mqmax(desktopMinSize - 630)} {
    left: -30px;
  }
`;

export const AMITutorialSixthStepContentWrapper = styled.div`
  position: absolute;
  top: 29px;
  left: 47px;
  max-width: 373px;
  width: 100%;
  height: 115px;
  background-color: #2d80f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  border-radius: 25px 25px 25px 2px;
  box-shadow: 0px 9px 16px #00000026;

  strong {
    font-weight: 300;

    p {
      text-transform: uppercase;
      display: inline-block;
    }

    span {
      font-weight: 600;
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    border-radius: 25px;
    max-width: 251px;
    height: 150px;
    font-size: 0.938rem;
    line-height: 20px;

    &:after {
      content: '';
      background: #2d80f2;
      position: absolute;
      width: 27px;
      height: 13.5px;
      left: 51px;
      top: 199px;
      z-index: 102;
      clip-path: polygon(100% 0, 0 0, 50% 100%);
    }
  }
`;

export const AMITutorialSixthStepButtonWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  button {
    border: 0;
    background-color: transparent;

    &:nth-of-type(1) {
      max-width: 100px;
      width: 100%;
      border-radius: 21px;
      height: 29px;
      outline: 2px solid #fff;
      color: #fff;
      outline-offset: -2px;
      text-transform: uppercase;
      font-weight: 600;
      transition: all 300ms;
      font-size: 0.688rem;
      line-height: 0 !important;
      &:hover {
        background-color: #fff;
        color: #2d80f2;
      }
    }

    &:nth-of-type(2) {
      color: #fff;
      font-weight: 300;
      transition: all 300ms;
      font-size: 0.75rem;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;
      transition: border 300ms;
      width: 100%;
      max-width: fit-content;
      border-radius: 0;

      &:hover {
        border-bottom-color: #fff;
      }
    }
  }
`;

export const AMITutorialSeventhStepWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 6px;
  left: 528px;
  z-index: 103;
  line-height: 1.2;
  ${mqmax(desktopMinSize - 1)} {
    display: flex;
    justify-content: center;
    top: 56px;
    left: 107px;
  }
  ${mqmax(desktopMinSize - 380)} {
    left: -28px;
  }
  ${mqmax(desktopMinSize - 630)} {
    left: -204px;
  }
`;

export const AMITutorialSeventhStepContentWrapper = styled.div`
  position: absolute;
  top: 142px;
  left: 223px;
  max-width: 373px;
  width: 100%;
  height: 98px;
  background-color: #2d80f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  border-radius: 25px 25px 25px 2px;
  box-shadow: 0px 9px 16px #00000026;

  strong {
    font-weight: 300;

    span {
      font-weight: 600;
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    border-radius: 25px;
    max-width: 251px;
    height: 150px;
    font-size: 0.938rem;
    line-height: 20px;

    &:after {
      content: '';
      background: #2d80f2;
      position: absolute;
      width: 27px;
      height: 13.5px;
      left: 51px;
      top: 199px;
      z-index: 102;
      clip-path: polygon(100% 0, 0 0, 50% 100%);
    }
  }
`;

export const AMITutorialSeventhStepButtonWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  button {
    border: 0;
    background-color: transparent;

    &:nth-of-type(1) {
      max-width: 100px;
      width: 100%;
      border-radius: 21px;
      height: 29px;
      outline: 2px solid #fff;
      color: #fff;
      outline-offset: -2px;
      text-transform: uppercase;
      font-weight: 600;
      transition: all 300ms;
      font-size: 0.688rem;
      line-height: 0 !important;
      &:hover {
        background-color: #fff;
        color: #2d80f2;
      }
    }

    &:nth-of-type(2) {
      color: #fff;
      font-weight: 300;
      transition: all 300ms;
      font-size: 0.75rem;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;
      transition: border 300ms;
      width: 100%;
      max-width: fit-content;
      border-radius: 0;

      &:hover {
        border-bottom-color: #fff;
      }
    }
  }
`;

export const AMITutorialEighthStepWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 35px;
  left: 562px;
  z-index: 103;
  line-height: 1.2;
  ${mqmax(desktopMinSize - 1)} {
    display: flex;
    justify-content: center;
    top: 115px;
    left: 213px;
  }
  ${mqmax(desktopMinSize - 380)} {
    left: 77px;
  }
  ${mqmax(desktopMinSize - 630)} {
    left: -158px;
  }
`;

export const AMITutorialEighthStepContentWrapper = styled.div`
  position: absolute;
  top: 225px;
  left: 171px;
  max-width: 373px;
  width: 100%;
  height: 98px;
  background-color: #2d80f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  border-radius: 25px 25px 25px 2px;
  box-shadow: 0px 9px 16px #00000026;

  strong {
    font-weight: 300;
    max-width: 37ch;

    span {
      font-weight: 600;
    }
  }
  ${mqmax(desktopMinSize - 1)} {
    border-radius: 25px;
    max-width: 251px;
    height: 95px;
    font-size: 0.938rem;
    line-height: 20px;

    &:after {
      content: '';
      background: #2d80f2;
      position: absolute;
      width: 27px;
      height: 13.5px;
      left: 51px;
      top: 144px;
      z-index: 102;
      clip-path: polygon(100% 0, 0 0, 50% 100%);
    }
  }
`;

export const AMITutorialEighthStepButtonWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  button {
    border: 0;
    background-color: transparent;

    &:nth-of-type(1) {
      max-width: 100px;
      width: 100%;
      border-radius: 21px;
      height: 29px;
      outline: 2px solid #fff;
      color: #fff;
      outline-offset: -2px;
      text-transform: uppercase;
      font-weight: 600;
      transition: all 300ms;
      font-size: 0.688rem;
      line-height: 0 !important;
      &:hover {
        background-color: #fff;
        color: #2d80f2;
      }
    }

    &:nth-of-type(2) {
      color: #fff;
      font-weight: 300;
      transition: all 300ms;
      font-size: 0.75rem;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;
      transition: border 300ms;
      width: 100%;
      max-width: fit-content;
      border-radius: 0;

      &:hover {
        border-bottom-color: #fff;
      }
    }
  }
`;

export const AMITutorialFinalStepWrapper = styled.div`
  position: absolute;
  top: 36%;
  width: 100%;
  z-index: 103;
  line-height: 1.2;
  ${mqmax(desktopMinSize - 1)} {
  }
`;

export const AMITutorialFinalStepContentWrapper = styled.div`
  position: absolute;
  max-width: 305px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 80px;
  background-color: #2d80f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  border-radius: 25px;
  box-shadow: 0px 9px 16px #00000026;

  strong {
    font-weight: 300;

    span {
      font-weight: 600;
    }
  }
  ${mqmax(desktopMinSize - 1)} {
    border-radius: 25px;
    max-width: 281px;
    height: 81px;
    font-size: 0.938rem;
    line-height: 20px;
  }
`;

export const AMITutorialFinalStepButtonWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  button {
    border: 0;
    background-color: transparent;

    &:nth-of-type(1) {
      max-width: 100px;
      width: 100%;
      border-radius: 21px;
      height: 29px;
      outline: 2px solid #fff;
      color: #fff;
      outline-offset: -2px;
      text-transform: uppercase;
      font-weight: 600;
      transition: all 300ms;
      font-size: 0.688rem;
      line-height: 0 !important;
      &:hover {
        background-color: #fff;
        color: #2d80f2;
      }
    }

    &:nth-of-type(2) {
      color: #fff;
      font-weight: 300;
      transition: all 300ms;
      font-size: 0.75rem;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;
      transition: border 300ms;
      width: 100%;
      max-width: fit-content;
      border-radius: 0;

      &:hover {
        border-bottom-color: #fff;
      }
    }
  }
`;
