import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import {
  mqmax,
  desktopMinSize
} from '@Styles/mixins';

export const Text = styled(Typography)`
  &.MuiTypography-body1 {
    margin: 0;
    color: #fff;
    font-size: calc(21 / 16 * 1rem);
    line-height: calc(27 / 18);
    text-align: center;
    font-weight: 300;
    ${mqmax(desktopMinSize - 1)} {
      font-size: calc(13 / 16 * 1rem);
    }
  }
`;

export default Text;
