import React from 'react';
import {
  AMITutorialEighthStepWrapper,
  AMITutorialEighthStepContentWrapper,
  AMITutorialEighthStepButtonWrapper,
  TutorialBackdrop,
} from '../styled';

export const EighthStep = ({ nextStep, skipTutorial }) => {
  return (
    <>
      <TutorialBackdrop />
      <AMITutorialEighthStepWrapper>
        <AMITutorialEighthStepContentWrapper>
          <strong>
            Lembre sempre de <span>confirmar que desligou</span> ao sair da máquina ou suas horas serão perdidas.
          </strong>

          <AMITutorialEighthStepButtonWrapper>
            <button onClick={nextStep}>Entendi</button>
            <button onClick={skipTutorial}>Fechar tutorial</button>
          </AMITutorialEighthStepButtonWrapper>
        </AMITutorialEighthStepContentWrapper>
      </AMITutorialEighthStepWrapper>
    </>
  );
};
