import { Loading } from '../../../../Loading';
import { MySessionsMobileAccordionContent } from '../AdmPanelMobileAccordion';

const MobileWrapper = ({
  loading,
  collaboratorsData,
  itemsPerPage,
  actions,
  handleInfoPopupModal,
  handleSuspendCollab,
  handleUnsuspendCollab,
  handleDeleteCollab
}) => {
  return (
    <>
      {' '}
      {loading ? (
        <Loading />
      ) : (
        <>
          {collaboratorsData?.data?.slice(0, itemsPerPage).map(collab => {
            return (
              <MySessionsMobileAccordionContent
                collaborator={collab}
                active={collab.active==='ACTIVE'}
                action={actions(collab)}
                handleInfoPopupModal={handleInfoPopupModal}
                handleSuspendCollab={handleSuspendCollab}
                handleUnsuspendCollab={handleUnsuspendCollab}
                handleDeleteCollab={handleDeleteCollab}
              />
            );
          })}
        </>
      )}
    </>
  );
};

export default MobileWrapper;