import React from 'react';
import {
  AMITutorialFourthStepWrapper,
  AMITutorialFourthStepContentWrapper,
  AMITutorialFourthStepButtonWrapper,
  TutorialBackdrop,
} from '../styled';
export const FourthStep = ({ nextStep, skipTutorial }) => {
  return (
    <>
      <TutorialBackdrop />
      <AMITutorialFourthStepWrapper>
        <AMITutorialFourthStepContentWrapper>
          <strong>
            Para <span>iniciar a sua sessão</span> basta clicar aqui.{' '}
            <span>&#40;costumava levar no máximo 2min para criar toda a sua sessão basta aguardar&#41;.</span>
          </strong>

          <AMITutorialFourthStepButtonWrapper>
            <button onClick={nextStep}>Entendi</button>
            <button onClick={skipTutorial}>Fechar tutorial</button>
          </AMITutorialFourthStepButtonWrapper>
        </AMITutorialFourthStepContentWrapper>
      </AMITutorialFourthStepWrapper>
    </>
  );
};
