import React, { useCallback, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useSelector } from 'react-redux';

import * as S from './styled';

export const CopyView = ({ text, isIpCopied, onCopy }) => (
  <CopyToClipboard {...{ onCopy, text }}>
    <S.Button type="button">
      Copiar ip
      <S.Tooltip {...{ isIpCopied }}>Copiado!</S.Tooltip>
    </S.Button>
  </CopyToClipboard>
);

export function useCopy() {
  const { ip_address } = useSelector(state => state.computer);
  const [isIpCopied, setIsIpCopied] = useState(false);

  const onCopy = useCallback(
    function () {
      setIsIpCopied(true);

      const timeoutId = setTimeout(function () {
        setIsIpCopied(false);
      }, 1500);

      return function () {
        clearTimeout(timeoutId);
      };
    },
    [setIsIpCopied],
  );

  return { isIpCopied, onCopy, text: ip_address };
}

export function Copy() {
  return <CopyView {...useCopy()} />;
}

export default Copy;
