import React from 'react';
import SectionTitle from '../PaymentTitle';

export const Title = () => (
  <>
    <SectionTitle>Pagamento cartão de crédito</SectionTitle>
  </>
);

export default Title;
