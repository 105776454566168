import React from 'react';
import styled from 'styled-components';

import { Subtitle, Title } from '@Components/Public';
import { desktopMinSize, mqmin } from '@Styles/mixins';

import notebook from './notebook.png';
import { TextButton } from '../../../layouts/Public/Header/styled';
import { VideoPlayer } from './videoPlayer';

const Section = props => {
  function getBaseFontSize() {
    return Number(window.getComputedStyle(document.body).getPropertyValue('font-size').match(/\d+/)[0]);
  }

  const OFFSET = (80 * getBaseFontSize()) / 16;

  function goToPlans() {
    const { top } = document.querySelector('#planos').getBoundingClientRect();
    const { body } = document;
    body.scrollTo({ behavior: 'smooth', top: top + body.scrollTop - OFFSET });
  }

  return (
    <section id="como-usar" {...props}>
      <div>
        <Title as="h2">
          <strong>Como usar?</strong>
        </Title>
        <Subtitle>Sem fios e sem enrolações.</Subtitle>
      </div>
      <Notebook>
        <PrintScreen>
          <VideoPlayer />
        </PrintScreen>
        <MobileVideoWraper>
          <VideoPlayer />
        </MobileVideoWraper>
      </Notebook>
      <Subtitle>
        <TextButton onClick={goToPlans}>
          <strong>Quero conhecer os planos!</strong>
        </TextButton>
      </Subtitle>
    </section>
  );
};

const PrintScreen = styled.div`
  display: none;
  width: calc(570rem / 16);
  height: calc(356rem / 16);
  position: relative;

  ${mqmin(desktopMinSize)} {
    display: block;
  }

  img {
    position: absolute;
    transition: opacity calc(1s / 3) ease-in-out;
  }
`;

const MobileVideoWraper = styled.div`
  display: flex;
  width: calc(320rem / 16);
  height: auto;
  align-items: center;
  justify-content: center;

  ${mqmin(desktopMinSize)} {
    display: none;
  }
`;

const Notebook = styled.div`
  ${mqmin(desktopMinSize)} {
    width: calc(753rem / 16);
    height: calc(433rem / 16);
    position: relative;
    background: url(${notebook});
  }

  ${PrintScreen} {
    position: absolute;
    top: calc(30rem / 16);
    right: calc(91rem / 16);
  }
`;

export const HowToUse = styled(Section)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: calc(40rem / 16);
`;

export default HowToUse;
