import React, { useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { REDIRECT_TO_LOGIN, messageDisconnect } from '@Constants';
import { stopInstance } from '@Services/noar-api';
import { SessionExpired } from '@Errors';
import { openConfirmationLightbox } from '@Store/ducks/global/actions';
import { setComputerLoading, updateStoppingState } from '@Store/ducks/computer/actions';
import { stopMessage } from '../../messages';

import * as S from './styled';

import ComputerContext from '../../context';
import { setPersonalPlan } from '../../../../../store/ducks/global/actions';
import { getPersonalActivePlan } from '../../../../../services/noar-api';

export const StopView = props => (
  <S.Stop type="button" {...props}>
    Desligar
  </S.Stop>
);

export function useStop() {
  const { setAuthCode, setFormAuthenticationStatus, setFormMessage } = useContext(ComputerContext);
  const { instance_id } = useSelector(state => state.computer);
  const dispatch = useDispatch();
  const history = useHistory();

  async function handleUpdatePersonalInfo() {
    try{
      var personalPlan = await getPersonalActivePlan();
      dispatch(setPersonalPlan(personalPlan[0]?.is_active, personalPlan[0]?.status, null, personalPlan[0]?.signature_date)); 
    }catch(error){
      if (error instanceof SessionExpired) {
        sessionStorage.removeItem('@NOARCLOUD-my-home-page-plan-info-modal');
        const turnOnMachine = false;
        const disconnect = true;
        
        dispatch(
          openConfirmationLightbox(
            () => {
              history.push(REDIRECT_TO_LOGIN);
            },
            messageDisconnect.message,
            messageDisconnect.confirmBtnText,
            messageDisconnect.refuseBtnText,
            turnOnMachine,
            disconnect,
            false,
          ),
        );
      }
    }
  }

  const onClick = useCallback(
    function () {
      dispatch(
        openConfirmationLightbox(
          async function stopComputer() {
            try {
              setFormAuthenticationStatus('sending');

              dispatch(setComputerLoading());
              dispatch(updateStoppingState());
              await stopInstance(instance_id);
              await handleUpdatePersonalInfo();
                            
            } catch (error) {
              if (error instanceof SessionExpired) {
                const turnOnMachine = false;
                const disconnect = true;
                dispatch(
                  openConfirmationLightbox(
                    () => {
                      history.push(REDIRECT_TO_LOGIN); 
                    },
                    messageDisconnect.message,
                    messageDisconnect.confirmBtnText,
                    messageDisconnect.refuseBtnText,
                    turnOnMachine,
                    disconnect,
                    false,
                  ),
                );
              }
            }
            setFormMessage('');
            setFormAuthenticationStatus('');
            setAuthCode('');
          },
          stopMessage.buildConfirmationLightboxText(),
          stopMessage.confirmBtnText,
          stopMessage.refuseBtnText,
          null,
          null,
          false,
        ),
      );
    },
    [dispatch, history, instance_id, setAuthCode, setFormAuthenticationStatus, setFormMessage],
  );

  return { onClick };
}

export function Stop(props) {
  return <StopView {...useStop(props)} />;
}

export default Stop;
