import styled from 'styled-components';

import { desktopMinSize, mqmax, mqmin, rem } from '@Styles/mixins';

export const Stop = styled.button`
  background-color: transparent;
  width: 100%;
  max-width: 170px;
  height: 50px;
  color: #2d80f2;
  -webkit-border-radius: 100vmax;
  -moz-border-radius: 100vmax;
  border-radius: 100vmax;
  border: 3px solid #2d80f2;
  transition: all 150ms;
  text-transform: uppercase;

  &:hover {
    background-color: #2d80f2;
    color: #fff;
  }

  ${mqmax(desktopMinSize - 1)} {
    border: 2px solid #2d80f2;
    border-radius: 23px;
    max-width: 160px;
    height: 40px;
    font-size: 12px;
  }

  ${mqmin(desktopMinSize)} {
    width: 48%;
    font-size: ${rem(14)};
    line-height: ${rem(19)};
  }
`;
