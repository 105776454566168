import React from 'react';

import Container from './Container';
import Title from './SectionTitle';

export const Header = ({title}) => (
  <Container>
    <Title title={title}/>
  </Container>
);

export default Header;
