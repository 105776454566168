import styled from 'styled-components';

import { desktopMinSize, mq, mqmax, mqmin } from '../../styles/mixins';
import imagem_fundo from '../../assets/fundo_home.png';
import imagem_fundo_mobile from '../../assets/fundo_home_mobile.png';

export const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  overflow: hidden;

  ${mqmin(desktopMinSize)} {
    flex-direction: column;
  } 
`;

export const Div = styled.main`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow-x: hidden !important;
  background-color: #17171A;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;

  ${mq((desktopMinSize + 300), desktopMinSize)} {
    padding: 5rem 5rem 1rem 5rem;
    background-image: url(${imagem_fundo});
  }

  ${mqmin((desktopMinSize + 301))} {
    padding: 6rem 5rem 1rem 5rem;
    background-image: url(${imagem_fundo});
  }

  ${mqmax(desktopMinSize - 1)} {
    background-image: url(${imagem_fundo_mobile});
  }

  ::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
    ${mqmax(desktopMinSize - 1)} {
      width: 2px;
    }
  }
  ::-webkit-scrollbar-thumb {
    background: transparent linear-gradient(180deg, #2d80f2 0%, #a00bf6 100%) 0% 0% no-repeat padding-box;
    border-radius: 10px;
  }
`;
