import React, { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { queueInitInstance, createInstance } from '@Services/noar-api';
import { SessionExpired, CreateInstanceError } from '@Errors';
import { openConfirmationLightbox } from '@Store/ducks/global/actions';
import {
  setComputerLoading,
  setComputerCreationEnabled,
  updateState,
  setComputerDoesntLoading,
  setComputerCreationFinished,
  updateQueue,
  updateComputer
} from '@Store/ducks/computer/actions';
import {
  REDIRECT_TO_LOGIN,
  messageDisconnect,
  ORIGIN_CLASSIC,
  LOST_PLAYBOY,
  QUEUE, 
  ENTERPRISE,
  ORIGIN_FREE,
  ORIGIN_UNIT
} from '@Constants';
import utilsCalculateHours from '@Utils/calculate-hours';
import { messages, messageError } from '../../../pages/OccasionalUsePage/AMIs/Carousel/AMI/messages';
import { useTutorialStep } from '../../../context/TutorialStepContext';
import queueWebsocketUpdate from '@Functions/queue-websocket-update';

import * as NoarAPI from '@Services/noar-api';
import { ORIGIN_CORUJAO, ORIGIN_PERSONAL } from '../../../constants';
import OccasionalUseAmiSectionView from './OccasionalUseAmiSectionView';
import PersonalUseAmiSectionView from './PersonalUseAmiSectionView';
import { getActivatedPlans } from '../../../services/noar-api';

export const AMISectionPage = ({ handleAMISectionPage, ami, sectionType, recurrency }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { used_hours, contracted_hours } = useSelector(state => state.global);
  const { selectedPlan } = useSelector(state => state.computer);
  const { state } = useTutorialStep();
  
  async function validatePlan(plan) {
    const activatedPlans = await getActivatedPlans();
    if(plan !== "CLASSIC") {
      const validPlans = ["MONTHLY", "QUARTERLY"];
      for (let i = 0; i < activatedPlans.length; i++) {
        if (validPlans.includes(activatedPlans[i].signature_type)) {
          return true;
        }
      }
      return false;
    } else {
      return true;
    }
  }

  const createComputer = useCallback(
    async function () {
      try {
        if (selectedPlan === ORIGIN_CLASSIC || selectedPlan === ENTERPRISE || selectedPlan === ORIGIN_PERSONAL) {
          const { TotalHours, TotalMinutes } = utilsCalculateHours(used_hours, contracted_hours);
          if (TotalHours > 0 || TotalMinutes > 0 || selectedPlan === ENTERPRISE) {
            dispatch(setComputerLoading());
            dispatch(setComputerCreationEnabled());
            const response = await createInstance(ami.aws_id, selectedPlan.toUpperCase());
            dispatch(updateComputer(response));
            return;
          } 
          else {
            dispatch(
              openConfirmationLightbox(
                () => {
                  history.push('/shop/hours');
                },
                <>Você não possui horas disponíveis para criar uma máquina.</>,
                'Ir para loja',
                'Voltar',
                null,
                null,
                false,
              ),
            );
            return;
          }
        } else {

          dispatch(setComputerLoading());
          const isValid = await validatePlan('MONTHLY');
          if(!isValid) {
            dispatch(
              openConfirmationLightbox(
                () => {
                  history.push('/shop/personal');
                },
                <>Você não possui <strong>plano UNIT</strong> ativo para criar uma máquina.</>,
                'Ir para loja',
                'Voltar',
                null,
                null,
                false,
              ),
            );
            dispatch(setComputerDoesntLoading());
            return;
          }

          const response = await queueInitInstance(ami.aws_id, selectedPlan);
          dispatch(setComputerDoesntLoading());
          if (response && response.queue_phase !== LOST_PLAYBOY) {
            const allInfo = { ...response, aws_id: ami.aws_id };
            dispatch(updateState(allInfo));
            if (response.queue_phase === QUEUE) {
              dispatch(setComputerLoading());
              dispatch(setComputerCreationEnabled());
              const responseQueue = { ...response, aws_id: ami.aws_id, has_instance: false };
              queueWebsocketUpdate(dispatch, updateQueue, responseQueue);
            }
          } else {
            dispatch(setComputerDoesntLoading());
            dispatch(setComputerCreationFinished());
          }
          return;
        }
      } catch (error) {
        if (error instanceof SessionExpired) {
          const turnOnMachine = false;
          const disconnect = true;
          dispatch(
            openConfirmationLightbox(
              () => {
                history.push(REDIRECT_TO_LOGIN);
              },
              messageDisconnect.message,
              messageDisconnect.confirmBtnText,
              messageDisconnect.refuseBtnText,
              turnOnMachine,
              disconnect,
              false
            ),
          );
        }

        if (error instanceof CreateInstanceError){
          if(error && error.message && error.message.includes("UNAVAILABLE")){
            dispatch(
              openConfirmationLightbox(
                () => {
                  window.location.reload();
                },
                "Estamos com baixa disponibilidade de computadores no momento. Tente novamente mais tarde!",
                messageError.confirmBtnText,
                messageError.refuseBtnText,
                null,
                true,
                false,
              ),
            );
          }
          else {
            dispatch(
              openConfirmationLightbox(
                () => {
                  window.location.reload();
                },
                messageError.message,
                messageError.confirmBtnText,
                messageError.refuseBtnText,
                null,
                null,
                false,
              ),
            );
          }
        }
      }
    },
    [ami, contracted_hours, dispatch, history, used_hours, selectedPlan],
  );

  const onClickCreateComputer = useCallback(
    async function () {
      var queueStatus = null;
      if(selectedPlan !== ORIGIN_CLASSIC && selectedPlan !== ENTERPRISE && selectedPlan !== ORIGIN_PERSONAL){
        dispatch(setComputerLoading());
        queueStatus = await NoarAPI.getQueueStatus(selectedPlan);
        dispatch(setComputerDoesntLoading());
      }
      dispatch(
        openConfirmationLightbox(
          createComputer,
          messages.buildConfirmationLightboxText(ami?.image_name),
          messages.confirmBtnText,
          messages.refuseBtnText,
          messages.turnOnMachine,
          null, 
          selectedPlan === ORIGIN_UNIT || selectedPlan === ORIGIN_FREE  || selectedPlan === ORIGIN_CORUJAO, 
          queueStatus?.clients_in_queue,
          queueStatus?.average_waiting_time_minutes,
        ),
      );
    },
    [ami, createComputer, dispatch],
  );

  if (state[1].step === 1) {
    document.body.scrollIntoView(0);
  }

  const modalRef = useRef();

  const handleOpenModal = () => {
    modalRef.current.showModal();
  };

  return (
    sectionType === 'PERSONAL'
    ?
      <PersonalUseAmiSectionView
        handleAMISectionPage={handleAMISectionPage}
        ami={ami}
        onClickCreateComputer={onClickCreateComputer}
        modalRef={modalRef}
        handleOpenModal={handleOpenModal}
        recurrency={recurrency}
      />
    :
      <OccasionalUseAmiSectionView 
        handleAMISectionPage={handleAMISectionPage}
        ami={ami}
        state={state}
        onClickCreateComputer={onClickCreateComputer}
        modalRef={modalRef}
        handleOpenModal={handleOpenModal}
       />
  );
};
