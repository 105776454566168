import React from 'react';
import { AiFillWarning, AiOutlineClose } from 'react-icons/ai';
import { AlmostExpiringAlertWrapper } from './styled';

export const AlmostExpiringAlert = ({modalRef, close}) => {

  return (
    <AlmostExpiringAlertWrapper ref={modalRef}>
      <div>
        <button onClick={close}>
          <AiOutlineClose style={{ width: '21px', height: '21px', opacity: 0.24, color: '#fff' }} fontWeight="bold" />
        </button>
        <AiFillWarning style={{ width: '52px', height: '52px', color: '#426FE5' }} />
        <strong>
          ATENÇÃO! sua sessão está <br />
          <span>prestes a expirar!</span>
        </strong>
      </div>
    </AlmostExpiringAlertWrapper>
  );
};
