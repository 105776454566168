import React from 'react';

import { Step } from './Step';

import { HowToUseAMIWrapper } from './styled';

export const HowToUseAMI = ({ modalRef }) => {
  const handleCloseModal = () => {
    const modal = modalRef.current;

    modal.setAttribute('closing', '');
    modal.addEventListener(
      'animationend',
      () => {
        modal.removeAttribute('closing');
        modal.close();
      },
      { once: true },
    );
  };
  return (
    <HowToUseAMIWrapper ref={modalRef}>
      <Step onRequestClose={handleCloseModal} />
    </HowToUseAMIWrapper>
  );
};
