import React, { useState, useEffect } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {
  Container,
  Table,
  TableWrapper,
  Th,
  Td,
  TableFooterWrapper,
  TableFooterContent,
  AdminTableWrapper,
  AdminTable,
  AdminContentWrapper,
  FilterWrapper,
} from './styled';
import { getSessions, getBusinessSessions, getCollaborators } from '@Services/noar-api';
import { Loading } from '../Loading';
import { Pagination } from './Pagination';
import { ProtectedComponent } from '../ProtectedComponent';
import Combobox from 'react-widgets/Combobox';
import 'react-widgets/styles.css';
import { BsSearch } from 'react-icons/bs';

import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useUserRole } from '../../context/UserRoleContext';

const useStyles = makeStyles({
  root: {
    color: '#fff',
  },
});

const MySessions = () => {
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [sessionsData, setSessionsData] = useState([]);
  const [retrieveCollaboratorsNames, setRetrieveCollboratorsNames] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const classes = useStyles();
  const { clientType } = useUserRole();

  const queryClient = useQueryClient();

  const { data: sessionData } = useQuery({
    queryKey: ['sessionData'],
    queryFn: () => clientType === "B2B_ADM"
      ? getBusinessSessions(currentPage, itemsPerPage, undefined, undefined, retrieveCollaboratorsNames)
      : getSessions(currentPage, itemsPerPage),
    refetchOnWindowFocus: false,
  });

  const { data: CollabData } = useQuery({
    queryKey: ['collabData'],
    queryFn: () => clientType === "B2B_ADM" ? getCollaborators() : [],
    refetchOnWindowFocus: false,
  });

  async function getData() {
    try {
      setLoading(true);
      const response = clientType === "B2B_ADM"
        ? await getBusinessSessions(currentPage, itemsPerPage, undefined, undefined, retrieveCollaboratorsNames)
        : await getSessions(currentPage, itemsPerPage);
      setSessions(response.page_info);
      setSessionsData(response.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }
  
  useEffect(() => {
    getData();
  }, [currentPage, itemsPerPage]);

  useEffect(() => {
    queryClient.fetchQuery({
      queryKey: ['sessionData'],
    });
  }, [currentPage, itemsPerPage, retrieveCollaboratorsNames]);

  const handlePagination = pageNumber => setCurrentPage(pageNumber);

  const retrieveCollaboratorsData = CollabData?.data?.reduce((acc, data) => {
    acc.push(data.name, data.email);
    return acc;
  }, []);

  const handleChange = event => {
    setItemsPerPage(event.target.value);
  };

  return (
    <Container>
      <strong>Histórico de acessos</strong>
      <ProtectedComponent role={['B2B_ADM']}>
        <FilterWrapper>
          <Combobox
            value={retrieveCollaboratorsNames}
            onChange={e => setRetrieveCollboratorsNames(e)}
            data={retrieveCollaboratorsData}
            placeholder={'Buscar usuário'}
            selectIcon={<BsSearch size={14} />}
            messages={{
              emptyList: 'Nenhuma atividade detectada',
              emptyFilter: 'Nenhum nome encontrado',
            }}
          />
        </FilterWrapper>

        <AdminTableWrapper>
          <AdminContentWrapper>
            {loading ? (
              <Loading />
            ) : (
              <AdminTable>
                <thead>
                  <tr>
                    <Th scope="colgroup">Nome</Th>
                    <Th scope="colgroup">Email</Th>
                    <Th scope="colgroup">Entrada</Th>
                    <Th scope="colgroup">Saída</Th>
                    <Th scope="colgroup">Tempo Utilizado</Th>
                  </tr>
                </thead>

                <tbody>
                  {sessionData?.data?.slice(0, itemsPerPage).map((session, index) => {
                    return (
                      <tr key={index + 1}>
                        <Td scope="col">{session.client_name}</Td>
                        <Td scope="col">{session.client_email ? session.client_email : 'Conta excluída'}</Td>
                        <Td scope="col">
                          {session.begin_date
                            ? new Date(session.begin_date)?.toLocaleString('pt-BR', {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                              })
                            : ''}{' '}
                          {session.begin_date ? session.begin_date?.split(' ')[1]?.split('.')[0] : '-'}
                        </Td>

                        <Td scope="col">
                          {session.end_date
                            ? new Date(session.end_date)?.toLocaleString('pt-BR', {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                              })
                            : ''}{' '}
                          {session.end_date ? session.end_date?.split(' ')[1]?.split('.')[0] : '-'}
                        </Td>
                        <Td scope="col">
                          <time>
                            {' '}
                            {session.used_hours
                              ? Object.entries(session.used_hours)
                                  .map(([key, value]) => {
                                    if (value < 10) {
                                      return `0${value}`;
                                    } else {
                                      return value;
                                    }
                                  })
                                  .join(':')
                              : '-'}
                          </time>
                        </Td>
                      </tr>
                    );
                  })}
                </tbody>
              </AdminTable>
            )}
          </AdminContentWrapper>

          <TableFooterWrapper>
            <TableFooterContent>
              <FormControl className="formControl">
                <span>Exibindo</span>
                <Select
                  value={itemsPerPage}
                  onChange={handleChange}
                  classes={{
                    root: classes.root,
                    icon: classes.root,
                  }}
                >
                  <MenuItem value={5}>5 Linhas</MenuItem>
                  <MenuItem value={10}>10 Linhas</MenuItem>
                  <MenuItem value={15}>15 Linhas</MenuItem>
                </Select>
              </FormControl>

              <Pagination
                pageNumber={sessionData?.page_info?.page_number}
                totalPages={sessionData?.page_info?.total_pages}
                itemsPerPage={sessionData?.page_info?.page_size}
                sessions={sessionData?.page_info?.total_elements}
                handlePagination={handlePagination}
              />
            </TableFooterContent>
          </TableFooterWrapper>
        </AdminTableWrapper>
      </ProtectedComponent>

      <ProtectedComponent role={['B2C', 'B2B_COLLAB']}>
        <TableWrapper>
          {loading ? (
            <Loading />
          ) : (
            <Table>
              <thead>
                <tr>
                  <Th scope="colgroup">Acesso</Th>
                  <Th scope="colgroup">Sessão</Th>
                  <Th scope="colgroup">Produto</Th>
                  <Th scope="colgroup">Entrada</Th>
                  <Th scope="colgroup">Saída</Th>
                  <Th scope="colgroup">Tempo Utilizado</Th>
                </tr>
              </thead>

              <tbody>
                {sessionsData.slice(0, itemsPerPage).map((session, index) => {
                  return (
                    <tr key={index}>
                      <th scope="rowgroup">
                        {sessions.total_elements - sessions.page_size * sessions.page_number - index}
                      </th>
                      <Td scope="col">{session.image_name}</Td>
                      <Td scope="col">{session.product_Type}</Td>
                      <Td scope="col">
                        {session.begin_date
                          ? new Date(session.begin_date)?.toLocaleString('pt-BR', {
                              day: '2-digit',
                              month: '2-digit',
                              year: 'numeric',
                            })
                          : ''}{' '}
                        {session.begin_date ? session.begin_date?.split(' ')[1]?.split('.')[0] : '-'}
                      </Td>
                      <Td scope="col">
                        {session.end_date
                          ? new Date(session.end_date)?.toLocaleString('pt-BR', {
                              day: '2-digit',
                              month: '2-digit',
                              year: 'numeric',
                            })
                          : ''}{' '}
                        {session.end_date ? session.end_date?.split(' ')[1]?.split('.')[0] : '-'}
                      </Td>
                      <Td scope="col">
                        <time>
                          {' '}
                          {session.used_hours
                            ? Object.entries(session.used_hours)
                                .map(([key, value]) => {
                                  if (value < 10) {
                                    return `0${value}`;
                                  } else {
                                    return value;
                                  }
                                })
                                .join(':')
                            : '-'}
                        </time>
                      </Td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}

          <TableFooterWrapper>
            <TableFooterContent>
              <FormControl className="formControl">
                <span>Exibindo</span>
                <Select
                  value={itemsPerPage}
                  onChange={handleChange}
                  classes={{
                    root: classes.root,
                    icon: classes.root,
                  }}
                >
                  <MenuItem value={5}>5 Linhas</MenuItem>
                  <MenuItem value={10}>10 Linhas</MenuItem>
                  <MenuItem value={15}>15 Linhas</MenuItem>
                </Select>
              </FormControl>

              <Pagination
                pageNumber={sessions.page_number}
                totalPages={sessions.total_pages}
                itemsPerPage={sessions.page_size}
                sessions={sessions.total_elements}
                handlePagination={handlePagination}
              />
            </TableFooterContent>
          </TableFooterWrapper>
        </TableWrapper>
      </ProtectedComponent>
    </Container>
  );
};

export default MySessions;
