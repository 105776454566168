import React, { useState, useEffect } from 'react';
import * as NoarAPI from '@Services/noar-api';
import Form, { GroupWrapper, RowWrapper } from './Form';
import Input from './Input';
import Checkbox from './Checkbox';
import ErrorMessage from '../ErrorMessage';
import SuccessMessage from '../SuccessMessage';
import Wrapper from './Wrapper';
import Overlay from '@Containers/Overlay';
import styled from 'styled-components';
import Subtitle from '../Subtitle';
import ChangePasswordContent from './ChangePasswordContent';
import { REDIRECT_TO_UPDATE_PASSWORD_MOBILE } from '@Constants';
import { useHistory } from 'react-router-dom';
import { desktopMinSize } from '@Styles/mixins';

import { ProtectedComponent } from '@Components/ProtectedComponent';
import { useUserRole } from '../../../../context/UserRoleContext';
import StyledButton from '../styled';
import Button from './Button';
import { VisibilityOffOutlined, VisibilityOutlined } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { openConfirmationLightbox } from '../../../../store/ducks/global/actions';
import { getAddressByCep } from '../../../../services/noar-api';
import { SessionExpired } from '../../../../errors';
import { REDIRECT_TO_LOGIN, messageDisconnect } from '../../../../constants';
import { maskCnpj, maskCpf } from '../../../../utils/masks';
import { cpf, cnpj } from 'cpf-cnpj-validator';

export const Content = ({ showChangePasswordButton, forcedUpdate }) => {
  const [email, handleEmail] = useState('');
  const [name, handleName] = useState('');
  const [checked, handleChecked] = useState(false);
  const [address, handleAddress] = useState({});
  const [phone, handlePhone] = useState({ area_code: "55" });
  const [document, handleDocument] = useState('');
  const [showPasswordContent, handleShowPasswordContent] = useState(false);
  const [successProfileMessage, handleSuccessProfileMessage] = useState('');
  const { accountPersonalInfo } = useSelector(state => state.global);
  const [loading, handleLoading] = useState(false);
  const [isZipCodeLoaded, handleIsZipCodeLoaded] = useState(false);
  const [isRegistersOpen, handleIsRegistersOpen] = useState(false);
  const [isSaveButtonDisabled, handleIsSaveButtonDisabled] = useState(false);

  // errors
  const [saveInfoErrorMessage, handleSaveInfoErrorMessage] = useState('');
  const [getDataErrorMessage, handleGetDataErrorMessage] = useState('');
  const [documentErrorMessage, handleDocumentErrorMessage] = useState('');
  const [nameErrorMessage, handleNameErrorMessage] = useState('');
  const [phoneNumberErrorMessage, handlePhoneNumberErrorMessage] = useState('');
  const [zipCodeErrorMessage, handleZipCodeErrorMessage] = useState('');

  const { clientType } = useUserRole();
  const isMobile = window.innerWidth < desktopMinSize;
  const isB2C = clientType === 'B2C';
  const history = useHistory();
  const dispatch = useDispatch();

  async function saveData() {
    try {
      handleLoading(true);
      const email_sending_approval = checked;
      const zipCodeWithOnlyNumbers = address.zip_code.replace(/\D/g, '');
      const documentWithOnlyNumbers = document.replace(/\D/g, '');
      const nameWithUnnecessarySpacesAndChars = name.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '').replace(/\s+/g, ' ').trim()

      const addressToSend = {
        ...address,
        zip_code: zipCodeWithOnlyNumbers,
      };
      await NoarAPI.saveUserInfo(nameWithUnnecessarySpacesAndChars, email_sending_approval, addressToSend, phone, documentWithOnlyNumbers);
      handleLoading(false);
      if (accountPersonalInfo.showPopup) {
        dispatch({ type: 'SET_ACCOUNT_PERSONAL_INFO', payload: { showPopup: false } });
        dispatch(
          openConfirmationLightbox(
            null,
            <>Dados cadastrados com sucesso!</>,
            'Voltar para loja',
            null,
            null,
            null,
            false,
          ),
        );
      } else {
        handleSuccessProfileMessage('Dados salvos com sucesso!');
      }
      resetErrorSuccessMessage();
    } catch (error) {
      handleSaveInfoErrorMessage(error.message);
      handleLoading(false);
      resetErrorSuccessMessage();
    }
  }

  async function getData() {
    try {
      const responseBody = await NoarAPI.getUserInfo();
      const personalInfo = responseBody?.personal_information;
      handleName(responseBody?.name);
      handleEmail(responseBody?.email);
      handleChecked(responseBody?.email_sending_approval);
      handleAddress(personalInfo?.address ? personalInfo.address : {});
      handlePhone(personalInfo?.phone ? personalInfo.phone : {});
      handleDocument(personalInfo?.cpf ? personalInfo.cpf : '');
      handleGetDataErrorMessage('');
      dispatch({ type: 'SET_ACCOUNT_PERSONAL_INFO', payload: { accountPersonalInfo: { ...personalInfo, email: responseBody?.email, name: responseBody?.name } } });
    } catch (error) {
      handleGetDataErrorMessage(error.message);
      resetErrorSuccessMessage();
    }
  }
  // EVENTS

  function onChangeName(event) {
    handleNameErrorMessage('');
    handleName(event.target.value);
  }

  function onChangeZipCode(event) {
    handleZipCodeErrorMessage("");
    const zipCodeWithOnlyNumbers = event.target.value.replace(/\D/g, '');
    if (zipCodeWithOnlyNumbers.length <= 8) {
      handleAddress({ ...address, zip_code: zipCodeWithOnlyNumbers });
    }
  }

  function onChangeCity(event) {
    handleAddress({ ...address, city: event.target.value });
  }

  function onChangeStreet(event) {
    handleAddress({ ...address, street: event.target.value });
  }

  function onChangeNeighborhood(event) {
    handleAddress({ ...address, neighborhood: event.target.value });
  }

  function onChangeState(event) {
    var valueWithOnlyLetters = event.target.value.replace(/[^a-zA-Z]/g, '');
    if (valueWithOnlyLetters.length <= 2) {
      handleAddress({ ...address, state: valueWithOnlyLetters.toUpperCase() });
    }
  }

  function onChangeStreetNumber(event) {
    var value = event.target.value.replace(/\D/g, '');
    if (value.length <= 8) {
      handleAddress({ ...address, number: value });
    }
  }

  function onChangeComplement(event) {
    handleAddress({ ...address, complement: event.target.value });
  }

  function onChangePhoneNumber(event) {
    const numberWithOnlyNumbers = event.target.value.replace(/\D/g, '');
    if (event.target.value.length <= 11) {
      handlePhone({ ...phone, number: numberWithOnlyNumbers });
    }
  }

  function removeSpecialCharacters(value) {
    if (!value) return value;
    return value.replace(/[/.-]/g, '').trim();
  }

  function onChangeDocument(event) {
    handleDocumentErrorMessage("");
    let documentWithOnlyNumbers = event?.target?.value?.replace(/\D/g, '');

    if (documentWithOnlyNumbers.length <= 11) {
      if (documentWithOnlyNumbers.length === 11) {
        if (!cpf.isValid(documentWithOnlyNumbers)) {
          handleDocumentErrorMessage('Documento inválido');
        }
        else {
          handleDocumentErrorMessage(null);
        }
      }
      else {
        handleDocumentErrorMessage(null);
      }

      handleDocument(documentWithOnlyNumbers);
    }
  }

  function onChangeCheckbox(event) {
    handleChecked(event.target.checked);
  }

  async function onSubmitDataForm(ev) {
    ev.preventDefault();
    var splitedName = name.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '').replace(/\s+/g, ' ').trim().split(' ');
    if (splitedName.length < 2) {
      handleNameErrorMessage('Nome deve conter nome e sobrenome');
      resetErrorSuccessMessage();
      return;
    }

    var hasAbbreviation = false;
    splitedName.forEach(function (name) {
      name = name.toLowerCase();

      var twoLattersWordsThatAreNotAbbreviations = ['e', 'de', 'd\'', 'do', 'da', 'do', 'da'];

      if (name.length <= 2 && !twoLattersWordsThatAreNotAbbreviations.includes(name)) {
        hasAbbreviation = true;
      }
    });
    if (hasAbbreviation) {
      handleNameErrorMessage('Nome não pode conter abreviações');
      resetErrorSuccessMessage();
      return;
    }

    if (phone.number.length < 10) {
      handlePhoneNumberErrorMessage('Número de telefone inválido');
      resetErrorSuccessMessage();
      return;
    }

    var zipCodeWithOnlyNumbers = address.zip_code.replace(/\D/g, '');

    if (zipCodeWithOnlyNumbers?.length !== 8) {
      handleZipCodeErrorMessage('CEP inválido');
      resetErrorSuccessMessage();
      return;
    }

    saveData();
  }

  function showChangePasswordContent() {
    if (isMobile) {
      history.push(REDIRECT_TO_UPDATE_PASSWORD_MOBILE);
    } else {
      handleShowPasswordContent(!showPasswordContent);
    }
  }

  function resetErrorSuccessMessage() {
    setTimeout(function () {
      handleSaveInfoErrorMessage('');
      handleGetDataErrorMessage('');
      handleSuccessProfileMessage('');
      handleDocumentErrorMessage('');
      handleNameErrorMessage('');
      handlePhoneNumberErrorMessage('');
      handleZipCodeErrorMessage('');
    }, 5000);
  }

  function onChangeRegisterVisibility() {
    handleIsRegistersOpen(!isRegistersOpen);
  }

  async function getAddress(origin) {
    try {
      var zipCodeWithOnlyNumbers = address?.zip_code?.replace(/\D/g, '');
      if (zipCodeWithOnlyNumbers && zipCodeWithOnlyNumbers.length === 8) {
        handleLoading(true);
        const response = await getAddressByCep(address.zip_code);
        if (origin === 'ADDRESS') {
          if (response?.erro) {
            handleAddress({ ...address, street: '', city: '', neighborhood: '', state: '' });
            handleZipCodeErrorMessage('CEP inválido');
          }
          else {
            handleZipCodeErrorMessage(null);
          }
          handleAddress({
            ...address,
            street: response?.logradouro,
            city: response?.localidade,
            neighborhood: response?.bairro,
            state: response?.uf,
          });
        }

        handleLoading(false);
      }
    } catch (error) {
      handleLoading(false);
      if (error instanceof SessionExpired) {
        const turnOnMachine = false;
        const disconnect = true;
        dispatch(
          openConfirmationLightbox(
            () => {
              history.push(REDIRECT_TO_LOGIN);
            },
            messageDisconnect.message,
            messageDisconnect.confirmBtnText,
            messageDisconnect.refuseBtnText,
            turnOnMachine,
            disconnect,
            false,
          ),
        );
      } else {
        console.log(error.message);
      }
    }
  }

  // useEffect(s)

  useEffect(function () {
    getData();
    if (forcedUpdate) {
      handleIsSaveButtonDisabled(true);
    }
  }, []);

  useEffect(function () {
    if (address && address?.zip_code && removeSpecialCharacters(address?.zip_code).length === 8 && !isZipCodeLoaded) {
      getAddress('ADDRESS');
      handleIsZipCodeLoaded(true);
    }
  }, [address]);

  useEffect(
    function () {
      if (forcedUpdate) {
        if (name &&
          phone?.number &&
          address?.zip_code && address?.street && address?.number &&
          address?.city && address?.neighborhood && address?.state &&
          removeSpecialCharacters(document).length === 11
          && !getDataErrorMessage
          && !saveInfoErrorMessage
          && !documentErrorMessage
          && !nameErrorMessage
          && !phoneNumberErrorMessage
          && !zipCodeErrorMessage) {
          handleIsSaveButtonDisabled(false);
        } else {
          handleIsSaveButtonDisabled(true);
        }
      }
    },
    [forcedUpdate, name, phone, address, document, getDataErrorMessage,
      saveInfoErrorMessage, documentErrorMessage, nameErrorMessage, phoneNumberErrorMessage, zipCodeErrorMessage],
  );

  return (
    <>
      {loading ? <Overlay /> : []}
      <Wrapper forcedUpdate={forcedUpdate}>
        <RowWrapper forcedUpdate={forcedUpdate} isTitle={true}>
          <Subtitle>Dados da conta</Subtitle>
          {forcedUpdate ? (
            []
          ) : isRegistersOpen ? (
            <VisibilityOutlined onClick={onChangeRegisterVisibility} />
          ) : (
            <VisibilityOffOutlined onClick={onChangeRegisterVisibility} />
          )}
        </RowWrapper>
        {forcedUpdate || isRegistersOpen ? (
          <Form forcedUpdate={forcedUpdate} onSubmit={onSubmitDataForm}>
            <RowWrapper forcedUpdate={forcedUpdate}>
              <Input
                type="text"
                name="Name"
                label={forcedUpdate ? 'Nome*' : 'Nome'}
                placeholder="Digite seu nome"
                required
                disabled={!isB2C}
                value={name}
                onChange={onChangeName}
                maxLength="255"
                autoComplete="off"
                variant="outlined"
                forcedUpdate={forcedUpdate}
                error={forcedUpdate && !name || nameErrorMessage}
                helperText={forcedUpdate && !name || nameErrorMessage ? nameErrorMessage : ''}
              />
              <Input
                type="email"
                name="email"
                label="E-mail"
                placeholder="Digite seu e-mail"
                required
                disabled
                value={email}
                maxLength="255"
                autoComplete="off"
                variant="outlined"
                forcedUpdate={forcedUpdate}
              />
              <ProtectedComponent role={['B2C']}>
                <Input
                  type="text"
                  name="Document"
                  label={forcedUpdate ? 'CPF*' : 'CPF'}
                  placeholder="123.456.789-00"
                  required
                  disabled={!forcedUpdate}
                  value={removeSpecialCharacters(document)?.length > 11 ? maskCnpj(document) : maskCpf(document)}
                  onChange={onChangeDocument}
                  maxLength="255"
                  autoComplete="off"
                  variant="outlined"
                  forcedUpdate={forcedUpdate}
                  error={forcedUpdate && !document || documentErrorMessage}
                  helperText={forcedUpdate && !document || documentErrorMessage ? documentErrorMessage : ''}
                />
              </ProtectedComponent>
            </RowWrapper>
            <ProtectedComponent role={['B2C']}>
              <GroupWrapper forcedUpdate={forcedUpdate} hidden={!phone.area_code && !forcedUpdate}>
                <p>Número de telefone</p>
                <RowWrapper forcedUpdate={forcedUpdate}>
                  <Input
                    type="text"
                    name="phone_number"
                    label={forcedUpdate ? 'Número*' : 'Número'}
                    placeholder="11999999999"
                    required
                    value={phone.number}
                    onChange={onChangePhoneNumber}
                    maxLength="255"
                    autoComplete="off"
                    variant="outlined"
                    forcedUpdate={forcedUpdate}
                    error={forcedUpdate && !phone?.number || phoneNumberErrorMessage}
                    helperText={forcedUpdate && !phone?.number || phoneNumberErrorMessage ? phoneNumberErrorMessage : ''}
                  />
                </RowWrapper>
              </GroupWrapper>
              <GroupWrapper forcedUpdate={forcedUpdate} hidden={!address.zip_code && !forcedUpdate}>
                <p>Endereço</p>
                <RowWrapper forcedUpdate={forcedUpdate}>
                  <Input
                    type="text"
                    name="zip_code"
                    label={forcedUpdate ? 'CEP*' : 'CEP'}
                    placeholder="Digite seu CEP"
                    required
                    disabled={!forcedUpdate}
                    value={address.zip_code}
                    onChange={onChangeZipCode}
                    onBlur={() => getAddress('ADDRESS')}
                    maxLength="255"
                    autoComplete="off"
                    variant="outlined"
                    forcedUpdate={forcedUpdate}
                    error={forcedUpdate && !address?.zip_code || zipCodeErrorMessage}
                    helperText={forcedUpdate && !address?.zip_code || zipCodeErrorMessage ? zipCodeErrorMessage : ''}
                  />
                  <Input
                    type="text"
                    name="city"
                    label={forcedUpdate ? 'Cidade*' : 'Cidade'}
                    placeholder="Digite sua Cidade"
                    required
                    disabled={!forcedUpdate}
                    value={address.city}
                    onChange={onChangeCity}
                    maxLength="255"
                    autoComplete="off"
                    variant="outlined"
                    forcedUpdate={forcedUpdate}
                    error={forcedUpdate && !address?.city}
                  />
                  <Input
                    type="text"
                    name="neighborhood"
                    label={forcedUpdate ? 'Bairro*' : 'Bairro'}
                    placeholder="Digite seu Bairro"
                    required
                    disabled={!forcedUpdate}
                    value={address.neighborhood}
                    onChange={onChangeNeighborhood}
                    maxLength="255"
                    autoComplete="off"
                    variant="outlined"
                    forcedUpdate={forcedUpdate}
                    error={forcedUpdate && !address?.neighborhood}
                  />
                </RowWrapper>
                <RowWrapper forcedUpdate={forcedUpdate}>
                  <Input
                    type="text"
                    name="state"
                    label={forcedUpdate ? 'Estado*' : 'Estado'}
                    placeholder="Digite seu estado"
                    required
                    disabled={!forcedUpdate}
                    value={address.state}
                    onChange={onChangeState}
                    maxLength="2"
                    autoComplete="off"
                    variant="outlined"
                    forcedUpdate={forcedUpdate}
                    error={forcedUpdate && !address?.state}
                  />
                  <Input
                    type="text"
                    name="street"
                    label={forcedUpdate ? 'Rua*' : 'Rua'}
                    placeholder="Digite sua rua"
                    required
                    disabled={!forcedUpdate}
                    value={address.street}
                    onChange={onChangeStreet}
                    maxLength="255"
                    autoComplete="off"
                    variant="outlined"
                    forcedUpdate={forcedUpdate}
                    error={forcedUpdate && !address?.street}
                  />
                  <Input
                    type="number"
                    name="number"
                    label={forcedUpdate ? 'Número*' : 'Número'}
                    placeholder="Digite o número"
                    required
                    disabled={!forcedUpdate}
                    value={address.number}
                    onChange={onChangeStreetNumber}
                    autoComplete="off"
                    variant="outlined"
                    forcedUpdate={forcedUpdate}
                    error={forcedUpdate && !address?.number}
                  />
                  <Input
                    type="text"
                    name="complement"
                    label='Complemento'
                    placeholder="Digite o complemento"
                    disabled={!forcedUpdate}
                    value={address.complement}
                    onChange={onChangeComplement}
                    autoComplete="off"
                    variant="outlined"
                    forcedUpdate={forcedUpdate}
                  />
                </RowWrapper>
              </GroupWrapper>
            </ProtectedComponent>

            <ProtectedComponent role={['B2C']}>
              <Checkbox forcedUpdate={forcedUpdate} checked={checked} onChange={onChangeCheckbox} />
            </ProtectedComponent>
            {successProfileMessage ? <SuccessMessage>{successProfileMessage}</SuccessMessage> : []}
            {saveInfoErrorMessage ? <ErrorMessage>{saveInfoErrorMessage}</ErrorMessage> : []}
            <Div forcedUpdate={forcedUpdate}>
              {isB2C ? (
                <StyledButton disabled={isSaveButtonDisabled} forcedUpdate={forcedUpdate} type="submit">
                  SALVAR
                </StyledButton>
              ) : (
                []
              )}
              {showChangePasswordButton && <Button onClick={showChangePasswordContent}>Alterar senha</Button>}
            </Div>
          </Form>
        ) : (
          []
        )}
      </Wrapper>
      {showPasswordContent ? <ChangePasswordContent close={showChangePasswordContent} /> : []}
    </>
  );
};

export default Content;

const Div = styled.div`
  width: 100%;
  grid-column-start: 1;
  grid-column-end: 4;
  column-gap: 20px;
  display: flex;
  flex-direction: row;
  justify-content: ${({ forcedUpdate }) => (forcedUpdate ? 'center' : 'flex-start')};
`;
