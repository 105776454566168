import styled from 'styled-components';

import { defaultFont, displayRow, rem } from '@Styles/mixins';
import copyToClipBoardImg from '../../../../../assets/copy-to-clipboard.svg';

export const Button = styled.button`
  cursor: pointer;
  width: ${rem(18)};
  height: ${rem(18)};
  margin: 0 0 0 ${rem(10)};
  border: none;
  border-radius: 0;
  padding: 0;
  position: relative;
  font-size: 0;
  background: url(${copyToClipBoardImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const Tooltip = styled.div`
  ${({ isIpCopied }) => (isIpCopied ? displayRow() : 'display: none')};
  justify-content: center;
  align-items: center;
  width: ${rem(50)};
  height: ${rem(26)};
  border-radius: ${rem(7)};
  position: absolute;
  top: -${rem(8)};
  left: -${rem(15)};
  color: #ffffff;
  font-family: ${defaultFont};
  font-weight: 400;
  font-style: normal;
  font-size: ${rem(11)};
  letter-spacing: 0;
  line-height: ${rem(16)};
  text-align: left;
  text-decoration: none;
  transform: translateY(-100%);
  background: #2d80f2;

  &::after {
    content: '';
    width: 0;
    height: 0;
    border-left: ${rem(6)} solid transparent;
    border-right: ${rem(6)} solid transparent;
    border-top: ${rem(6)} solid #000;
    position: absolute;
    bottom: -${rem(6)};
    left: 50%;
    transform: translateX(-50%);
  }
`;
