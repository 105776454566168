import {
  CarrouselCardButtonWrapper,
  CarrouselCardWrapperMobile,
  ContainerMobile,
} from '../../../../../components/AMICarrousel/AMIMobileCard/styled';

const AmiSliderMobileWrapper = ({
  AMIs,
  checked,
  searchedNameValue,
  state,
  handleAMISectionPage,
  setActiveIndexAMI,
}) => {
  return (
    <>
      {AMIs?.filter(ami => {
        if (checked.length <= 0) {
          return ami;
        }

        return checked.find(item => item === ami.category);
      }).map(ami => (
        <>
          {ami?.image_list.filter(ami => {
            if (searchedNameValue === '') {
              return ami;
            }

            return ami?.image_name.toLocaleLowerCase().match(searchedNameValue.toLocaleLowerCase());
          }).length > 0 && (
            <>
              <ContainerMobile step={state[0].step} key={ami?.category}>
                <strong>{ami?.category}</strong>
                {ami?.image_list
                  ?.filter(ami => {
                    if (searchedNameValue === '') {
                      return ami;
                    }
                    return ami?.image_name.toLowerCase().match(searchedNameValue.toLowerCase());
                  })
                  .map(ami => (
                    <CarrouselCardWrapperMobile key={ami?.id}>
                      <strong>{ami?.category}</strong>
                      <CarrouselCardButtonWrapper>
                        <img src={ami?.image_photo} alt="" loading="lazy" />
                        <strong>{ami?.image_name}</strong>
                        <button
                          type="button"
                          onClick={() => {
                            handleAMISectionPage();
                            setActiveIndexAMI(ami?.id);
                          }}
                        >
                          Acessar
                        </button>
                      </CarrouselCardButtonWrapper>
                    </CarrouselCardWrapperMobile>
                  ))}
              </ContainerMobile>
            </>
          )}
        </>
      ))}
    </>
  );
};

export default AmiSliderMobileWrapper;
