import styled from "styled-components";
import { desktopMinSize, mqmax } from "../../styles/mixins";

export const AdmPanelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    color: #FFF;

    ${mqmax(desktopMinSize - 1)}{
        padding: 0 2rem 2rem;
    }
`;

export const Title = styled.h2`
    width:100% ;
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    ${mqmax(desktopMinSize - 1)}{
        font-size: 1.5rem;
        text-align: center;
    }
`;