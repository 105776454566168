import { createTheme, ThemeProvider } from '@material-ui/core';
import React, { useLayoutEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { desktopMinSize, mqmin } from '@Styles/mixins';

import Background from './Background';
import Footer from './Footer';
import Header from './Header';
import { mq } from '../../styles/mixins';

export default function ({ pages, ...props }) {
  ScrollToTop();

  return (
    <ThemeProvider theme={theme}>
      <Background />
      <Wrapper {...props}>
        <Header />
        <Switch>
          {pages.map(({ component: Component, ...props }) => (
            <Route exact key={props.path} render={Component} {...props} />
          ))}
        </Switch>
        <Footer />
      </Wrapper>
    </ThemeProvider>
  );
}

const theme = createTheme({
  typography: {
    fontFamily: '"Poppins", sans-serif',
  },
});

function ScrollToTop() {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    document.body.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const Wrapper = styled.div`
  color: #FFF;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${Header} {
    box-sizing: border-box;
    position: sticky;
    top: 0;
    z-index: 2;

    ${mqmin(desktopMinSize + 401)} {
      max-width: 85%;
      margin: calc(16rem / 16) auto;
      position: fixed;
      inset: 0 0 auto;
    }

    ${mq(desktopMinSize + 400, desktopMinSize)} {
      max-width: 95%;
    }
  }

  ${Footer} {
    ${mqmin(desktopMinSize)} {
      max-width: min(calc(304 / 360 * 100%), calc(1274rem / 16));
      margin-inline: auto;
    }
  }
`;
