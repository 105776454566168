import React from 'react';

import * as S from './styled';

function InProgress() {
  return (
    <S.InProgress>
      <h6>Em construção…</h6>
      <img
        src="https://siteimagesbucket.s3.us-east-1.amazonaws.com/website/inprogress/inprogress.svg"
        alt="Seção em construção"
      />
    </S.InProgress>
  );
}

export default InProgress;
