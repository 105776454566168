import React, { useContext, useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { COMPUTER_STATES } from '@Constants';
import { useHistory } from 'react-router-dom';
import { REDIRECT_TO_LOGIN, messageDisconnect } from '@Constants';
import { leaveQueue } from '@Services/noar-api';
import { SessionExpired } from '@Errors';
import { openConfirmationLightbox } from '@Store/ducks/global/actions';
import {
  updateComputer,
  setComputerLoading,
  setComputerDoesntLoading,
  updateQueue,
} from '@Store/ducks/computer/actions';
import { leaveQueueMessage } from '../messages';
import ComputerContext from '../context';
import { DEPRIORITIZED_QUEUE_TYPE, FREE_QUEUE_TYPE, PRIORITY_QUEUE_TYPE } from '../../../../constants';
import QueuePopup from '../QueuePopup';
import NpsFormBox from '../../../../containers/NpsFormBox';
import { getHasToShowNpsForm, sendNpsAnswer } from '../../../../services/noar-api';
import { openNpsFormBox } from '../../../../store/ducks/global/actions';

let countdownTimeout;

const IsLoadingTitle = () => {
  const { state, isLoading, instance_id, queue_info, start_queue_datetime, selectedPlan, isPersonal } = useSelector(state => state.computer);
  const { setAuthCode, setFormAuthenticationStatus, setFormMessage } = useContext(ComputerContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const [time, setTime] = useState(0);
  const fullMinutesAux = Math.floor(time/60);
  const hours = Math.floor(fullMinutesAux/60);
  const minutes = Math.floor(fullMinutesAux-(hours*60));
  const seconds = Math.floor(time-(hours*60*60)-(minutes*60));
  const queueText = {
    "DEPRIORITIZED_UNIT":DEPRIORITIZED_QUEUE_TYPE,
    "UNIT":PRIORITY_QUEUE_TYPE,
    "FREE":FREE_QUEUE_TYPE
  }
  const modalRef = useRef();
  const [defaultTimePopupOpen, setDefaultTimePopupOpen] = useState(300);

  const openModal = () => {
    const modal = modalRef.current;
    if (!modal.hasAttribute('open')) {
      modal.showModal();
    }
  }

  const closeModal = () => {
    const modal = modalRef.current;

    modal.setAttribute('closing', '');
    modal.addEventListener(
      'animationend',
      () => {
        modal.removeAttribute('closing');
        modal.close();
      },
      { once: true },
    );
  };

  const onClickLeaveQueue = useCallback(
    function () {
      dispatch(
        openConfirmationLightbox(
          async function () {
            try {
              dispatch(setComputerLoading());

              await leaveQueue(instance_id);

              dispatch(updateQueue(null));
              dispatch(setComputerDoesntLoading());
            } catch (error) {
              if (error instanceof SessionExpired) {
                const turnOnMachine = false;
                const disconnect = true;
                
                dispatch(
                  openConfirmationLightbox(
                    () => {
                      history.push(REDIRECT_TO_LOGIN);
                    },
                    messageDisconnect.message,
                    messageDisconnect.confirmBtnText,
                    messageDisconnect.refuseBtnText,
                    turnOnMachine,
                    disconnect,
                    false,
                  ),
                );
              }
            }

            setFormMessage('');
            setFormAuthenticationStatus('');
            setAuthCode('');
            dispatch(updateComputer(null));
            dispatch(setComputerDoesntLoading());
          },
          leaveQueueMessage.buildConfirmationLightboxText(),
          leaveQueueMessage.confirmBtnText,
          leaveQueueMessage.refuseBtnText,
          null,
          null,
          false,
        ),
      );
    },
    [dispatch, history, instance_id, setAuthCode, setFormAuthenticationStatus, setFormMessage],
  );

  useEffect(() => {
    if (state === COMPUTER_STATES.QUEUE) {
      if(time === defaultTimePopupOpen && queue_info?.creation_type === 'FREE') {
        openModal();
        setDefaultTimePopupOpen(defaultTimePopupOpen + 1200);
      }
      countdownTimeout = setTimeout(() => {
        setTime(time + 1);
      }, 1000);
    }
  }, [time]);

  useEffect(() => {
    if(isPersonal !== null){
      console.log(isPersonal);
      getHasToShowNpsForm("PERSONAL_WEB")
        .then(resp => {
          if (resp?.has_to_show) {
            dispatch(openNpsFormBox(sendNpsAnswer, sendNpsAnswer, "Noar Personal", "PERSONAL_WEB"));
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, [isPersonal]);

  useEffect(() => {
    if(start_queue_datetime && state === COMPUTER_STATES.QUEUE) {
      const currentDate = new Date(new Date().toLocaleString("en-US", {timeZone: "America/Sao_Paulo"}));
      const initDate = new Date(start_queue_datetime);
      setTime((Math.floor((currentDate.getTime() - initDate.getTime()) / 1000)));
    }
  }, [start_queue_datetime]);

  return (
    <>
      <h2 className="computer__presentation-title">
        {isLoading && !state ? (
          <div>
            <strong>Carregando...</strong>
          </div>
        ) : (
          {
            [COMPUTER_STATES.QUEUE]: (
              <>
                <strong>Estamos quase lá</strong>
                <div className="computer__presentation-info">
                  <strong>Você está na posição: {queue_info?.position || 1}</strong>
                  <strong>
                    Tempo de espera: {hours > 0 ? hours + 'h' : ''} {minutes}m {seconds}s
                  </strong>
                </div>
                <div>
                  <strong className='computer__queue-type'>
                      Você está na fila {queueText[queue_info?.creation_type]}
                  </strong>
                </div>
                <button className='button-queue' type="button" onClick={onClickLeaveQueue}>
                  sair da fila
                </button>
              </>
            ),
            [COMPUTER_STATES.PENDING]: 'Aguarde um momento, estamos preparando tudo para você!',
            [COMPUTER_STATES.STOPPING]: 'Aguarde um momento, estamos desligando tudo para você!',
          }[state]
        )}
      </h2>
      <QueuePopup modalRef={modalRef} closeModal={closeModal}/>
      <NpsFormBox />
    </>
  );
};

export default IsLoadingTitle;
