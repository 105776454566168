import React from 'react';
import styled from 'styled-components';

import { desktopMinSize, mqmin } from '@Styles/mixins';

import imagem_fundo from '../../../assets/fundo_home.png';
import imagem_fundo_mobile from '../../../assets/fundo_home_mobile.png';

export function Background(props) {
  const Bg = Home;
  return <Bg {...props} />;
}

export default Background;

const Div = styled.div`
  color: #fff;
`;

const Home = styled(Div)`
  background-color: #17171A;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: -1;
  background-image: url(${imagem_fundo_mobile});

  ${mqmin(desktopMinSize)} {
    background-image: url(${imagem_fundo});
  }
`;
