import styled from 'styled-components';

export const Box = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: calc(30 / 16 * 1rem);
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: calc(80 / 1056 * 100%) calc(90 / 1056 * 100%);
    border: solid transparent calc(5 / 16 * 1rem);
    border-radius: calc(64 / 16 * 1rem);
    background: #21222a;
    transform: scale(0.99);
`;

export default Box;
