import React, { useCallback, useEffect, useState } from 'react';
import MobileHeader from '../../components/Header';

import ItensContext from './ItensContext';
import * as NoarAPI from '@Services/noar-api';

import Products from './Products';

import * as S from './styled';
import UpdateAccountPopup from './UpdateAccountPopup';
import { useDispatch } from 'react-redux';

const ShopPageView = () => {
  const dispatch = useDispatch();

  const validateIfUserDataIsCompleted = useCallback(async () => {
    const responseBody = await NoarAPI.getUserInfo();
    const hasToShow = hasToShowRegistrationEnrichmentForm(responseBody);
    dispatch({ type: 'SET_ACCOUNT_PERSONAL_INFO', payload: { showPopup: hasToShow, accountPersonalInfo: { ...responseBody } } });
    return hasToShow;
  }, [dispatch]);

  function hasToShowRegistrationEnrichmentForm(responseBody) {

    const personalInfo = responseBody.personal_information;

    if (!personalInfo) return true;

    const hasAllPersonalInfo = responseBody.name && personalInfo.cpf;
    const hasAllPhoneInfo = personalInfo.phone?.area_code && personalInfo.phone?.number;
    const hasAllAddressInfo = personalInfo.address?.street && personalInfo.address?.number &&
      personalInfo.address?.neighborhood && personalInfo.address?.city &&
      personalInfo.address?.state && personalInfo.address?.zip_code;

    return !personalInfo || !hasAllPersonalInfo || !hasAllPhoneInfo || !hasAllAddressInfo;
  }

  useEffect(() => {
    dispatch({
      type: 'SET_ACCOUNT_PERSONAL_INFO',
      payload: { showPopupValidationFunction: validateIfUserDataIsCompleted },
    });
  }, [dispatch, validateIfUserDataIsCompleted]);

  return (
    <S.Div>
      <MobileHeader />
      <UpdateAccountPopup />
      <Products validateIfUserDataIsCompleted={validateIfUserDataIsCompleted} />
    </S.Div>
  );
};

function useShopPage() {
  const [itens, setItens] = useState([]);
  return { value: { itens, setItens } };
}

function ShopPage() {
  return (
    <ItensContext.Provider {...useShopPage()}>
      <ShopPageView />
    </ItensContext.Provider>
  );
}

export default ShopPage;
