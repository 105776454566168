import styled from 'styled-components';
import { desktopMinSize, mqmax, mqmin } from '../../../../../styles/mixins';

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  row-gap: calc(20 / 16 * 1rem);
  max-width: calc(573 / 16 * 1rem);
  position: relative;

  ${mqmax(desktopMinSize -1)} {
    width: 70%;
  }
  
  ${mqmin(desktopMinSize)}{
    min-width: 363px;
  }
`;

export default Div;
