import styled from 'styled-components';
import bannerImg from '@Assets/blog-banner.webp';
import bannerMobileImg from '@Assets/blog-banner-mobile.webp';
import { mqmax, desktopMinSize } from '@Styles/mixins';

export const BlogContainer = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 10px 2%;
  position: relative;

  ${mqmax(desktopMinSize - 1)} {
    max-width: 349px;
  }
`;

export const ContentBanner = styled.div`
  max-width: 1274px;
  margin: 0 auto;
  height: 180px;
  border-radius: 50px;
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-image: url(${bannerImg});

  h1 {
    font-size: 3.75rem;
    font-weight: 700;
    color: #fff;

    span {
      text-transform: uppercase;
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    max-width: 349px;
    margin-top: unset;
    height: 88px;
    border-radius: 20px;
    background-image: url(${bannerMobileImg});
    h1 {
      font-size: 1.875rem;
    }
  }
`;

export const BlogCategories = styled.div`
  margin-top: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.688rem;

  label {
    font-weight: 600;
    font-size: 0.938rem;
    cursor: pointer;
    position: relative;
    height: 48px;
    width: 142px;

    ${mqmax(desktopMinSize - 1)} {
      font-size: 0.5rem;
      max-width: 68px;
      height: 24px;
    }

    & > input {
      transition: background-color 350ms;
      position: absolute;
      opacity: 0;
      height: 0;
      width: 0;

      & ~ span {
        background-color: transparent;
        height: 48px;
        width: 142px;
        border-radius: 9999px;
        color: #17171a;
        opacity: 0.5;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid #17171a;

        ${mqmax(desktopMinSize - 1)} {
          max-width: 68px;
          height: 24px;
        }
      }

      &:checked ~ span {
        background-color: #2d80f2;
        color: #fff;
        opacity: 1;
        border-color: transparent;
      }

      &:checked ~ span:after {
        z-index: 1;
        opacity: 1;
        color: #fff;
      }
    }

    & > span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all 250ms ease-in-out;
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    margin-top: 41px;
  }
`;

export const EmphasisPostWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3.438rem;

  margin-top: 80px;

  & > a:has(img) {
    overflow: hidden;
    max-width: 627px;
    width: 100%;
    height: 421px;
    border-radius: 50px;
  }

  ${mqmax(desktopMinSize - 1)} {
    flex-direction: column;
    margin-top: 20px;
    gap: 1rem;

    & > a:has(img) {
      max-width: 308px;
      height: 122px;
      border-radius: 20px;
    }
  }
`;

export const EmphasisPostImage = styled.img`
  max-width: 627px;
  width: 100%;
  height: 421px;
  border-radius: 50px;
  object-fit: cover;
  transition: scale 350ms ease-in-out;

  &:hover {
    scale: 1.1;
  }

  ${mqmax(desktopMinSize - 1)} {
    max-width: 308px;
    height: 122px;
    border-radius: 20px;
  }
`;

export const EmphasisPostContent = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  & > strong {
    font-size: 0.938rem;
    margin-top: 38px;
    color: #17171a;

    ${mqmax(desktopMinSize - 1)} {
      font-size: 0.625rem;
      margin-top: 14px;
    }
  }

  & > article {
    margin-top: 9px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 24px;

    ${mqmax(desktopMinSize - 1)} {
      font-size: 0.625rem;
      line-height: 12px;
    }
  }
  & > a:has(button) {
    width: calc(160 / 16 * 1rem);

    ${mqmax(desktopMinSize - 1)} {
      & > button {
        max-width: 102px;
        width: 100%;
        height: 27px;
        font-size: 0.625rem;
      }
    }
  }
`;

export const EmphasisPostContentHeader = styled.header`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  & > div {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 10px;
  }

  & > a {
    text-decoration: none;
  }

  & > a > h2 {
    font-size: 2.5rem;
    font-weight: 600;
    max-width: 23ch;
    line-height: 51.8px;
    color: #17171a;

    ${mqmax(desktopMinSize - 1)} {
      font-size: 0.938rem;
      line-height: 19px;
      max-width: 33ch;
    }
  }

  span {
    strong {
      font-weight: 400;
      margin-left: 11px;
      opacity: 0.5;
      font-size: 0.813rem;

      ${mqmax(desktopMinSize - 1)} {
        font-size: 0.438rem;
        margin-left: 5px;
      }
    }
  }

  img {
    width: 13px;
    aspect-ratio: 1;

    ${mqmax(desktopMinSize - 1)} {
      width: 7px;
    }
  }
`;

const ReadMoreButtonStyle = styled.button`
  max-width: 161px;
  width: 100%;
  height: 46px;
  border: 2px solid #2d80f2;
  border-radius: 49px;
  font-size: 1.063rem;
  font-weight: 600;
  text-transform: uppercase;
  background: transparent;
  color: #2d80f2;
  transition: color 350ms, background-color 350ms;

  a {
    color: #2d80f2;
    text-decoration: none;
    transition: color 350ms;
  }

  &:hover {
    a {
      color: #fff;
    }
    color: #fff;
    background: #2d80f2;
  }
`;

export const ReadMoreButton = styled(ReadMoreButtonStyle)`
  margin-top: 19px;
`;

export const RelatedPostWrapper = styled.div`
  margin: 80px auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 3.188rem;

  ${mqmax(desktopMinSize - 1)} {
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    grid-gap: 1.25rem;
    max-width: 344px;
    margin: 40px auto;
  }
`;

export const RelatedPostContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  & > strong {
    font-size: 0.938rem;
    margin-top: 20px;
    color: #17171a;

    ${mqmax(desktopMinSize - 1)} {
      font-size: 0.625rem;
    }
  }

  & > article {
    margin-top: 9px;
    max-width: 45ch;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 24px;
    color: #17171a;
    font-size: 0.938rem;
    font-weight: 300;

    ${mqmax(desktopMinSize - 1)} {
      font-size: 0.625rem;
      line-height: 12px;
    }
  }

  & > a:has(img) {
    overflow: hidden;
    border-radius: 50px;

    ${mqmax(desktopMinSize - 1)} {
      border-radius: 20px;
    }
  }

  & > a:has(button) {
    width: calc(160 / 16 * 1rem);

    ${mqmax(desktopMinSize - 1)} {
      & > button {
        max-width: 102px;
        width: 100%;
        height: 27px;
        font-size: 0.625rem;
      }
    }
  }
`;

export const RelatedPostHeader = styled.header`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 35px;

  & > div {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 10px;
  }

  & > a {
    text-decoration: none;
  }

  & > a > strong {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.2;
    color: #17171a;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    ${mqmax(desktopMinSize - 1)} {
      font-size: 0.625rem;
      line-height: 12px;
    }
  }

  span {
    strong {
      font-weight: 400;
      margin-left: 11px;
      opacity: 0.5;
      font-size: 0.813rem;

      ${mqmax(desktopMinSize - 1)} {
        font-size: 0.438rem;
        margin-left: 5px;
      }
    }
  }

  img {
    width: 13px;
    aspect-ratio: 1;

    ${mqmax(desktopMinSize - 1)} {
      width: 7px;
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    margin-top: 5px;
  }
`;

export const RelatedPostImage = styled.img`
  max-width: 100%;
  height: 262px;
  border-radius: 50px;
  object-fit: cover;
  transition: scale 350ms ease-in-out;

  &:hover {
    scale: 1.1;
  }

  ${mqmax(desktopMinSize - 1)} {
    width: 145px;
    height: 98px;
    border-radius: 20px;
  }
`;

export const BlogShowMoreButton = styled.button`
  max-width: fit-content;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  background-color: transparent;
  color: #2d80f2;

  ${mqmax(desktopMinSize - 1)} {
    font-size: 0.625rem !important;
  }
`;

export const BlogDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ccc;
  margin-top: 107px;

  ${mqmax(desktopMinSize - 1)} {
    margin-top: 34px;
  }
`;

export const BlogFooterWrapper = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 122px;
  & > img {
    max-width: 100%;
    width: 284px;
    height: 218px;

    ${mqmax(desktopMinSize - 1)} {
      width: 134px;
      height: 102px;
    }
  }

  & > button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 73px;
    margin-bottom: 101px;
    background: transparent;
    max-width: 35px;
    width: 100%;
    aspect-ratio: 1;
    border: 2px solid #17171a;
    border-radius: 50%;

    & > img {
      width: 10px;
      aspect-ratio: 1;
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    margin-top: 61px;
  }
`;

export const BlogFooterLinks = styled.div`
  margin-top: 73px;
  display: flex;
  flex-direction: row;
  gap: 2.375rem;
  & > a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    color: #17171a;
    transition: color 350ms;

    &:hover {
      color: #2d33f2;
    }

    ${mqmax(desktopMinSize - 1)} {
      font-size: 0.625rem;
    }

    & > img {
      width: 17px;
      aspect-ratio: 1;
      margin-right: 10px;

      ${mqmax(desktopMinSize - 1)} {
        margin-right: 5px;
      }
    }

    &:nth-child(2) > img {
      margin-top: -6px;
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }
`;

export const NewsLetterWrapper = styled.div`
  max-width: 1009px;
  height: 64px;
  background-color: #fff;
  box-shadow: 0px 9px 59px -21px rgba(0, 0, 0, 0.22);
  border-radius: 35px;
  padding: 34px 51px;
  margin: -30px auto;
  z-index: 1;
  position: relative;

  ${mqmax(desktopMinSize - 1)} {
    max-width: 190px;
    height: 84px;
    border-radius: 20px;
    margin: -20px auto;
    padding: 47px 40px;
  }
`;

export const NewsLetterContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row !important;

  width: 100%;

  & > div:has(strong) {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 0.313rem;

    strong:first-child {
      color: #2d80f2;
      font-weight: 400;
      font-size: 1.563rem;

      span {
        font-weight: 700;
      }

      ${mqmax(desktopMinSize - 1)} {
        font-size: 0.938rem;
      }
    }
    strong:last-child {
      font-size: 0.938rem;
      color: #17171a;
      font-weight: 300;

      ${mqmax(desktopMinSize - 1)} {
        font-size: 0.5rem;
        margin-bottom: 10px;
      }
    }
    ${mqmax(desktopMinSize - 1)} {
      width: unset;
      text-align: center;
    }
  }

  & > form {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    & > input {
      max-width: 241px;
      width: 100%;
      height: 40px;
      border-radius: 27px;
      border: 1.5px solid #8b8b8d;
      outline: 1.5px solid transparent;
      padding: 0 0 0 18px;
      transition: outline-color 350ms;

      &:focus {
        outline-color: #2d80f2;
      }

      &::placeholder {
        color: #17171a;
        font-weight: 300;
        font-size: 0.75rem;
        opacity: 0.5;
        line-height: 18px;
      }

      ${mqmax(desktopMinSize - 1)} {
        max-width: 140px;
        height: 23px;
        padding-bottom: 2px;

        &::placeholder {
          color: #17171a;
          font-weight: 300;
          font-size: 0.5rem;
          opacity: 0.3;
          line-height: 12px;
        }
      }
    }

    & > button {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 142px;
      width: 100%;
      height: 40px;
      border: 2px solid #2d80f2;
      color: #2d80f2;
      background-color: transparent;
      border-radius: 49px;
      font-weight: 600;
      font-size: 1.063rem;
      text-transform: uppercase;
      transition: color 350ms, background-color 350ms ease-in-out;

      &:hover {
        color: #fff;
        background-color: #2d80f2;
      }

      ${mqmax(desktopMinSize - 1)} {
        max-width: 91px;
        height: 27px;
        font-size: 0.625rem;
        margin-top: 6px;
      }
    }

    ${mqmax(desktopMinSize - 1)} {
      flex-direction: column !important;
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    flex-direction: column !important;
    width: unset;
    margin: -30px;
  }
`;

export const LoadingImage = styled.img`
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  max-width: 100%;
  width: 400px;
  height: 400px;
  object-fit: cover;
`;
