import styled from 'styled-components';

import { desktopMinSize, mqmin, mqmax, rem } from '@Styles/mixins';

export const Container = styled.div`
  width: 80%;
  border-radius: ${rem(8)};
  position: relative;

  ${mqmin(desktopMinSize)} {
    display: block;
  }
  ${mqmax(desktopMinSize - 1)} {
    display: none;
  }

  strong {
    color: #fff;
    font-size: ${rem(47)};
    font-weight: 600;
    display: block;
    text-align: left;
    margin-bottom: 44px;
  }
`;

export const Table = styled.table`
  min-width: ${rem(800)};
  width: 100%;
  color: #fff;
  border-collapse: separate;
  position: relative;

  border-spacing: 8px 50px;

  th {
    text-align: left;
    font-size: ${rem(16)};
    font-weight: 300;
  }

  tr + tr::after {
    content: '';
    width: 100%;
    height: 1px;
    background-color: #fff;
    position: absolute;
    margin-top: -20px;
    left: 0;
    opacity: 0.1;
  }
`;

export const TableFooterWrapper = styled.div`
  span {
    font-size: ${rem(14)};
    font-weight: 300;
    display: inline-block;
    margin-right: 3px;
    color: #fff;
  }

  select {
    background: transparent;
    border: 0;
    color: #fff;
    cursor: pointer;
    font-size: ${rem(15)};
    font-weight: 600;

    & option {
      color: #000;
    }
  }
`;

export const TableFooterContent = styled.div`
  display: flex !important;
  justify-content: space-between !important;

  .formControl {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: ${rem(8)};
  }
  .formSelect {
    color: hsl(0, 0%, 100%);

    & svg {
      color: hsl(0, 0%, 100%);
    }
  }
`;

export const Th = styled.th`
  font-weight: 600 !important;
  font-size: ${rem(19)} !important;
  text-align: left;

  & > button {
    rotate: 90deg;
    background: transparent;
    color: #fff;
    font-size: 1rem;
    font-weight: 600;
  }
`;

export const Td = styled.td`
  font-weight: 300;
  font-size: ${rem(16)};
`;

export const TableWrapper = styled.div`
  background-color: #21222a;
  padding: 0 ${rem(28)} ${rem(35)} ${rem(35)};
  border-radius: 35px;
  max-width: 1109px;
  min-height: 499px;
`;

export const AdminTableWrapper = styled.div`
  max-width: 1176px !important;
  width: 100% !important;
  min-height: 329px;
  margin-top: 13px;

  & > div:last-child {
    margin-top: 13px;
  }
`;

export const AdminContentWrapper = styled.div`
  padding: 0 ${rem(28)} 0 ${rem(35)};
  border-radius: 25px;
  border: 1px solid #fff;
  min-height: 329px;
  max-width: 1111px;
  width: 100%;
`;

export const AdminTable = styled.table`
  max-width: 1111px;
  width: 100%;
  color: #fff;
  border-collapse: separate;
  position: relative;

  border-spacing: 0px 40px;

  th {
    text-align: left;
    font-size: ${rem(14)} !important;
    font-weight: 300;
  }

  td:first-child {
    min-width: 127px;
  }

  tr:has(td)::after {
    content: '';
    width: 100%;
    height: 1px;
    background-color: #fff;
    position: absolute;
    margin-top: -20px;
    left: 0;
    opacity: 0.1;
  }
`;

export const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 21px;
  position: relative;
  gap: 0.938rem;

  .rw-combobox {
    max-width: 569px !important;
    width: 100%;

    ${mqmax(desktopMinSize - 1)} {
      max-width: 251px !important;
    }
  }

  .rw-state-focus {
    .rw-widget-picker {
      border-radius: 9999px;
    }

    .rw-btn {
      & > svg {
        fill: #17171a !important;
      }
    }
  }

  .rw-widget {
    .rw-btn {
      pointer-events: none;
      border: 0;
      position: absolute;
      right: 0.875rem;
      top: 0.875rem;
      z-index: 999 !important;

      & > svg {
        fill: #fff;
      }

      ${mqmax(desktopMinSize - 1)} {
        right: 1.188rem;
        top: 0.75rem;

        & > svg {
          width: 12px;
        }
      }
    }
  }

  .rw-widget-picker {
    background: transparent !important;
    position: relative;
    z-index: 997 !important;
    border: 0;
    display: flex;

    & > input {
      font-family: 'Poppins', sans-serif;
      height: 41px;
      background: transparent;
      border: 1.5px solid #fff;
      border-radius: 99999px;
      padding-left: 15px !important;
      color: #fff;
      transition: all 250ms;

      &::placeholder {
        font-size: 0.938rem;
        font-weight: 300;
        opacity: 23%;

        ${mqmax(desktopMinSize - 1)} {
          font-size: 0.813rem;
        }
      }

      &:focus {
        background: #fff;
        outline: 0;
        border: 1.5px solid #000;
        color: #17171a;

        &::placeholder {
          color: #17171a;
          opacity: 1;
          font-weight: 400;
        }
      }

      ${mqmax(desktopMinSize - 1)} {
        height: 37px;
      }
    }
  }

  & > div > button {
    background-color: transparent;
    width: 34px;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #fff;
    border-radius: 7px;

    img {
      width: 16px;
      aspect-ratio: 1;
    }
  }

  & > div:has(button) {
    position: relative;
  }

  & > div:has(button):nth-of-type(2) {
    position: relative;
  }

  & > div > button:nth-of-type(2) {
    svg {
      width: 25px;
      height: 25px;
      fill: #fff;
    }

    ${mqmax(desktopMinSize - 1)} {
      margin-right: 0;
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    margin-bottom: 23px;
  }
`;

export const ExportWrapper = styled.button`
  position: absolute;
  min-width: 218px !important;
  height: 42px !important;
  border-radius: 13px !important;
  font-size: 0.813rem;
  font-weight: 300;
  color: #fff;
  left: -100px;
  top: 45px;
  background: rgba(0, 0, 0, 0) !important;
  backdrop-filter: blur(23px) !important;
  -webkit-backdrop-filter: blur(23px) !important;
  border: 1px solid rgba(255, 255, 255, 0.18) !important;
  z-index: 9999;
  transition: opacity, z-index, 250ms ease-in-out;
  opacity: ${({ isExportButtonIsShowing }) => (isExportButtonIsShowing ? 1 : 0)};
  z-index: ${({ isExportButtonIsShowing }) => (isExportButtonIsShowing ? 999 : -1)};
`;
