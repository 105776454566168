import styled from "styled-components";
import { desktopMinSize, mqmax, rem } from "../../../styles/mixins";

export const AnalyticsSectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    max-width: 1176px;
    border-radius: ${rem(8)};
    
`;

export const AnalyticsSectionContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    gap: 1rem;

    ${mqmax(desktopMinSize - 1)} {
        align-items: center;
    }
`;

export const AnalyticsInfoSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;

    ${mqmax(desktopMinSize - 1)} {
        flex-direction: column;
    }
`;