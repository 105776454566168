import styled from 'styled-components';
import { desktopMinSize, mqmax } from '@Styles/mixins';

export const TermsText = styled.strong`
  font-size: 0.75rem;
  color: #fff;
  font-weight: 300;

  & > a {
    color: #2d80f2;
    font-weight: 700;
  }

  ${mqmax(desktopMinSize - 1)} {
    max-width: 40ch;
  }
`;
