import { SELECTED_PLAN_KEY } from '@Constants';

const INITIAL_STATE = {
  // API response fields
  instance_id: null,
  instance_name: null,
  aws_id: null,
  waiting_confirmation_datetime_init: null,
  start_queue_datetime: null,
  launch_time: null,
  isPersonal: null,
  instance_specs: {
    memory: null,
    vcpu: null,
    volume_specs: {
      volume_type: null,
      volume_size: null,
    },
  },
  platform: null,
  state: null,
  ip_address: null,
  creatingComputer: false,

  // Local fields
  isLoading: true,
  computerWebsocketOn: false,
  selectedPlan: localStorage?.getItem(SELECTED_PLAN_KEY),

  // computer is onQueue
  queue_info: {
    aws_id: null,
    position: null,
    creation_type: null
  },

};

export default INITIAL_STATE;
