import styled, { css } from 'styled-components';
import React from 'react';
import SectionSubtitle from '../MyProfileSubtitle';
import { desktopMinSize, mq, mqmax, mqmin, overlayOpacity, overlayZIndex } from '../../../../styles/mixins';
import { Button as MuiButton } from '@material-ui/core';

export const Wrapper = styled.div`
    width: 100%;
    row-gap: 1rem;
    display: flex;
    flex-direction: column;
    color: #FFF;
`;

export const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
`;

export const Subtitle = props => <SectionSubtitle {...props} />;

export const InfoTitle = styled.p`
    font: normal normal 0.8rem Poppins;
    color: #FFFFFF;
    opacity: 1;
    text-align: left;
    width: 100%;
`;

export const RedSpam = styled.span`
    vertical-align: middle;
    color: #cc3300;
    font-weight: bold;
`;

export const GreenSpam = styled.span`
    vertical-align: middle;
    color: #339900;
    font-weight: bold;
`;

export const InfoWarning = styled.p`
    font: normal normal bold 0.8rem Poppins;
    color: #cc3300;
    opacity: 1;
    text-align: left;
    width: 100%;
`;

export const InfoStrong = styled.strong`
    font-weight: bold;
    font-size: 1rem;
`;


export const Overlay = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, ${overlayOpacity});
    z-index: 1;
`;

export const CancelPopupGeneralWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
`;


export const CancelPopupContainer = styled.div`
    z-index: ${overlayZIndex + 1};
    box-sizing: border-box;
    padding: calc(55 / 1056 * 100%) calc(59 / 1056 * 100%);
    border: solid transparent calc(5 / 16 * 1rem);
    border-radius: 38px;
    background: #21222a 0% 0% no-repeat padding-box;
    transform: scale(0.99);
    transition: all 300ms ease-out;

    
    ${mqmax(desktopMinSize)} {
        height: 60%;
        width: 90%;

        ${({ phase }) => {
            if (phase === 3 || phase === 4)
                return css`
                        height: 45%;
                    `;
        }}
    }

    ${mq(1399, desktopMinSize)} {
        height: 80%;
        ${({ phase }) => {
        if (phase === 3 || phase === 4)
            return css`
                    width: 35%;
                `;

        if (phase === 2)
            return css`
                    width: 65%;
                `;

        if (phase === 1)
            return css`
                    width: 70%;
                `;
    }}
    }

    ${mq(1900, 1400)} {
        height: 80%;
        ${({ phase }) => {
        if (phase === 3 || phase === 4)
            return css`
                    width: 35%;
                `;

        if (phase === 2)
            return css`
                    width: 65%;
                `;

        if (phase === 1)
            return css`
                    width: 70%;
                `;
    }}
    }

    ${mqmin(1900)} {
        width: 40%;
        height: 70%;
        ${({ phase }) => {
        if (phase === 3 || phase === 4)
            return css`
                    width: 25%;
                `;

        if (phase === 2)
            return css`
                    width: 40%;
                `;

        if (phase === 1)
            return css`
                    width: 40%;
                `;
    }
    }
`;

export const CancelPopupInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    gap: 2rem;
    height: 100%;

    ${mq(1400, desktopMinSize)} {
        gap:1rem;
    } 
`;

export const Button = styled(MuiButton)`
  &.MuiButton-root {
    --fontSize: 12;

    font-size: calc(var(--fontSize) * 1rem / 16);
    border-radius: calc(24rem / 16);
    padding: calc(9rem / 16) calc(30rem / 16);
    color: #fff;
    font-weight: bold;
    &:hover {
      color: #fff;
      background: #a00bf6;
    }
    line-height: calc(18 / var(--fontSize));
    transition: ${['color', 'background'].map(prop => `${prop} 300ms ease-out`).join(', ')};


    ${mq(1400, desktopMinSize)} {
        --fontSize: 12;
        padding: 0 30px;
        line-height: calc(35 / var(--fontSize));
    }

    ${mqmin(1900)} {
        --fontSize: 14;
    }

    ${mq(desktopMinSize, 0)} {
        --fontSize: 10;
    }

    ${({ variant }) => {
        if (variant === 'giveup-contained')
            return css`
          background: #2d80f2;

        `;

        if (variant === 'cancel-contained')
            return css`
                background: transparent;
                &:disabled {
                    opacity: 0.5;
                    cursor: not-allowed;
                    border-width: calc(3 / 16 * 1rem);
                    border-color: #2d80f2;
                    color: #2d80f2;
            `;
    }}
  }
`;

export const CancelOptionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    
    .MuiFormLabel-root.Mui-focused {
        color: #FFF;
    } 

    .MuiFormHelperText-root {
        color: #FFF;
    } 
    
    .MuiFormLabel-root {
        color: #FFF;
    }

    .MuiIconButton-colorSecondary {
        color: #426FE5;
    }

    .MuiCheckbox-colorSecondary.Mui-checked {
        color: #426FE5;
    }

    .MuiInputBase-input {
        color: #FFF;
        font-weight: 300;
    }

    .MuiInput-underline:before {
        border-bottom: 1px solid #FFF;
    }

    .MuiFormLabel-root.Mui-error {
        color: #FFF;
    }

    ${mq(1400, desktopMinSize)} {
        .MuiFormHelperText-root {
            font-size: 0.6rem; 
        } 
        .MuiFormLabel-root {
            font-size: 0.75rem; 
        }
        .MuiFormLabel-root.Mui-error {
            font-size: 0.75rem;
        }
        .MuiTypography-body1 {
            font-size: 0.75rem;
        }
        .PrivateSwitchBase-root-1 {
            padding: 4px;
        }
    }

    ${mqmax(desktopMinSize - 1)} {
        .MuiFormHelperText-root {
            font-size: 0.6rem; 
        } 
        .MuiFormLabel-root {
            font-size: 0.75rem; 
        }
        .MuiFormLabel-root.Mui-error {
            font-size: 0.75rem;
        }
        .MuiTypography-body1 {
            font-size: 0.75rem;
        }
        .PrivateSwitchBase-root-1 {
            padding: 4px;
        }
    }
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-self: center;
    flex-direction: row;
    width: 100%;
    gap: 1rem;

    ${mqmax(desktopMinSize - 1)} {
        flex-direction: column;
        gap: 1rem;
    }
`;

export const CancelOptionsTitle = styled.h3`
    font: normal normal 0.9rem Poppins;
    color: #FFFFFF;
    opacity: 1;
    text-align: center;
    width: 100%;
`;

export const Title = styled.h1`
    font: normal normal bold calc(30 / 16 * 1rem) / calc(36 / 16 * 1rem) Poppins;
    color: #FFFFFF;
    opacity: 1;
    text-align: center;
    width: 100%;
    
    ${({ phase }) => {
        if (phase === 4)
            return css`
                width: 120%;
            `;
    }}

    ${({ error }) => {
        if (error)
            return css`
                color: #FF0000;
            `;
    }}

    ${mq(1400, desktopMinSize)} {
        font-size: 1.5rem;
        line-height: 1.5rem;
    }

    ${mq(desktopMinSize, 0)} {
        font-size: 1rem;
        line-height: 1.2rem;
    }
`;

export const CancelSubtitle = styled.h2`
    font: normal normal 1rem Poppins;
    color: #FFFFFF;
    opacity: 1;
    text-align: center;
    width: 100%;
`;

export const CancelInfoSubTitle = styled.h2`
    font: normal normal 1.5rem Poppins;
    color: #FFFFFF;
    text-align: center;
    width: 100%;
    & > strong {
        font-weight: bold;
        color: #426FE5;
    }

    ${mq(desktopMinSize, 0)} {
        font-size: 1rem;
        line-height: 1.2rem;
    }
`;

export const CancelInfoText = styled.p`
    font: normal normal 1.5rem Poppins;
    color: #FFFFFF;
    text-align: center;
    font-weight: 300;
    width: 100%;
    & > strong {
        font-weight: bold;
        color: #426FE5;
    }

    ${mq(desktopMinSize, 0)} {
        font-size: 1rem;
        line-height: 1.2rem;
    }
`;