import React from 'react';
import styled from 'styled-components';
import { desktopMinSize, mqmax } from '@Styles/mixins';

import slideImg from '@Assets/7.webp';
import mobileSlideImg from '@Assets/7-mobile.webp';

export const SeventhStep = () => {
  return (
    <>
      <div>
        {window.innerWidth < desktopMinSize ? <img src={mobileSlideImg} alt="" /> : <img src={slideImg} alt="" />}
      </div>
      <SeventhStepDescription>
        <span>7.</span> Clique no ícone de computador gerado e <span>anote o PIN gerado</span> para autenticação.
      </SeventhStepDescription>
    </>
  );
};

const SeventhStepDescription = styled.strong`
  min-width: 414px;
  text-align: left !important;
  font-size: 0.875rem !important;
  line-height: 1.5 !important;

  ${mqmax(desktopMinSize - 1)} {
    min-width: unset;
    font-size: 0.75rem !important;
  }
`;
