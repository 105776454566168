import React from 'react'
import {
  TutorialBackdrop,
  MySessionsStepWrapper,
  MySessionsStepContentWrapper,
  MySessionsStepButtonWrapper,
} from './styled';

export const MySessionsPageFirstStep = ({  skipTutorial}) => {
  return (
    <>
      <TutorialBackdrop />
      <MySessionsStepWrapper>
        <MySessionsStepContentWrapper>
          <strong>
            Nesta página você terá acesso <span>a todas as suas sessões,</span> inclusive qual máquina você usou
            anteriormente.
          </strong>
          <MySessionsStepButtonWrapper>
            <button onClick={skipTutorial}>Entendi</button>
            <button onClick={skipTutorial}>Fechar tutorial</button>
          </MySessionsStepButtonWrapper>
        </MySessionsStepContentWrapper>
      </MySessionsStepWrapper>
    </>
  );
}
