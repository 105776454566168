import React from 'react';
import { PaymentAlertWrapper } from './styled';

export const  PaymentAlert = ({ title, message, paymentAlertRef, variant, close }) => {
  const handleClosePaymentModal = () => {
    const modal = paymentAlertRef.current;

    modal.setAttribute('closing', '');
    modal.addEventListener(
      'animationend',
      () => {
        modal.removeAttribute('closing');
        modal.close();
      },
      { once: true },
    );
    close();
  };
  return (
    <PaymentAlertWrapper ref={paymentAlertRef} variant={variant}>
      <div variant={variant}>
        <h2>{title}</h2>
        <h3 dangerouslySetInnerHTML={{ __html: `${message}` }}></h3>
        <button onClick={handleClosePaymentModal}>ok</button>
      </div>
    </PaymentAlertWrapper>
  );
};
