import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closeNpsFormBox } from '@Store/ducks/global/actions';
import * as S from './styled';
import { NPS_FORM_RESPONSE_TYPES } from '@Constants';

function NpsFormBox(){
  const { isOpen, callbackFunction, closeCallBackFunction, product, origin } = useSelector(
    state => state.global.npsFormBox,
  );
  const [scores, handleScores] = useState([
    {score: 0 , active: false},
    {score: 1 , active: false},
    {score: 2 , active: false},
    {score: 3 , active: false},
    {score: 4 , active: false},
    {score: 5 , active: false},
    {score: 6 , active: false},
    {score: 7 , active: false},
    {score: 8 , active: false},
    {score: 9 , active: false},
    {score: 10, active: false}
  ]);
  const [reason, handleReason] = useState('');
  const [errorMessage, handleErrorMessage] = useState('');
  const [formStep, handleFormStep] = useState(0);

  const dispatch = useDispatch();

  function close(hasToSendIgnoreSinal = true) {
    dispatch(closeNpsFormBox());
    if(hasToSendIgnoreSinal === true){
      closeCallBackFunction && closeCallBackFunction(null, null, NPS_FORM_RESPONSE_TYPES.IGNORED, origin);
    }
  }

  function confirm() {
    close(false);

    const selectedScoreValue = scores.find(sc => sc.active).score

    callbackFunction && callbackFunction(selectedScoreValue, reason, NPS_FORM_RESPONSE_TYPES.ANSWERED, origin);
  }

  function onClickConfirm() {
    confirm();
  }

  function onClickClose() {
    close();
  }

  function onChangeScore(score) {
    handleScores(
      scores.map(sc => sc === score ? {...sc, active: !sc.active} : {...sc, active: false})
    );
  }

  function onChangeReason(event) {
    handleReason(event.target.value);
  }

  function onSubmitNpsForm(ev) {
    ev.preventDefault();
    if (!scores.filter(sc => sc.active).length) {
      handleErrorMessage('Informe a nota');
    }else{
      onClickConfirm();
    }
  }

  function escapeEventListener(event) {
    if (event.key === 'Escape') {
      close();
    }
  }

  function nextStep() {
    if (!scores.filter(sc => sc.active).length) {
      handleErrorMessage('Informe a nota');
    }else{
      handleErrorMessage('');
      handleFormStep(formStep + 1);
    }
  }

  function stepBack() {
      handleErrorMessage('');
      handleFormStep(formStep - 1);
  }

  useEffect(() => {
    document.addEventListener('keydown', escapeEventListener);

    return () => {
      document.removeEventListener('keydown', escapeEventListener);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <S.NpsFormBox className={`${isOpen ? 'is--open' : ''}`}>
      <div className="nps-form-box__overlay" onClick={onClickClose}></div>
      <form onSubmit={onSubmitNpsForm}>
        <div className="nps-form-box__wrapper">
          <div className={"nps-form-box__box form-"+formStep}>
            <button type="button" id="nps-form-box__btn-close" onClick={onClickClose}>
              Fechar confirmação
            </button>
            <button type="button" id={'nps-form-box__btn-back' + (formStep===0 ? '__toggle' : '')} onClick={stepBack}>
              Voltar
            </button>
            <div className="nps-form-box__header">
              <h1 className={'main-title form-'+formStep}><span>Queremos&nbsp;</span><span>saber a sua opinião!</span></h1>
              <h1 className={'end-title form-'+formStep}><span>Obrigado por nos&nbsp;</span><span>ajudar a melhorar!</span></h1> 
            </div>
            <div className= "nps-form-box__content">
              <div className={'questions questions-score form-'+formStep}>
                <p className="nps-form-box__text">Qual a probabilidade de você recomendar <span>{product}</span><br/>para um amigo ou colega?</p>
                <div className='content-score'>
                  <div className='description-mobile first'>
                    <p>Nem um pouco provável</p>
                  </div>
                  <div className='scores'>
                    {
                      scores.map((sc,i) => (
                        <div key={i} className={'score ' + (sc.active ? 'active' : '')} onClick={() => {onChangeScore(sc)}}>
                          <span>{sc.score}</span>
                        </div>
                      ))
                    }
                  </div>
                  <div className='description-mobile second'>
                    <p>Extremamente provável</p>
                  </div>
                  <div className='description-desktop'>
                    <p>Nem um pouco provável</p>
                    <p>Extremamente provável</p>
                  </div>
                </div>
              </div>
              <div className={'questions questions-justification form-'+formStep}>
                <p className="nps-form-box__text desktop">Se puder, justifique a nota atribuída acima.</p>
                <p className="nps-form-box__text mobile">Se puder, justifique a <br /> nota atribuída anteriormente.</p>
                <textarea rows={8} maxLength={2500} value={reason} onChange={onChangeReason}></textarea>
              </div>
              <p className="nps-form-box__error-message">{errorMessage}</p>
            </div>
            <div className='nps-form-box__footer'>
              <button type="submit" id="nps-form-box__btn-confirm">
                ENVIAR
              </button>
              <button type="button" id="nps-form-box__btn-continue"  className={'form-'+formStep} onClick={nextStep}>
                CONTINUAR
              </button>
              <button type="submit" id="nps-form-box__btn-done" className={'form-'+formStep}>
                CONCLUIR
              </button>
            </div>
          </div>
        </div>
      </form>
    </S.NpsFormBox>
  );
}

export default NpsFormBox;