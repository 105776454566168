import * as S from './styled';

const AnalyticsInfoCard = ({title, maxAmount, usedAmount, unit, planName}) => {
    return (
        <S.AnalyticsCardWrapper>
            <S.AnalyticsInfoCardWrapper>
                <S.Title>{title}</S.Title>
                {!planName 
                    ? <S.AnalyticsInfoCardText><strong>{usedAmount}{unit}</strong> / {maxAmount}{unit}</S.AnalyticsInfoCardText> 
                    : <S.AnalyticsInfoCardPlanNameText>{planName}</S.AnalyticsInfoCardPlanNameText> 
                }
            </S.AnalyticsInfoCardWrapper>
            {!planName 
            ? (
                <S.CircularProgressWrapper>
                    <S.CircularProgressText>{maxAmount !== 0 ? parseInt(usedAmount*100/maxAmount) : 0}%</S.CircularProgressText>
                    <S.CircularProgressStyled variant="determinate" value={parseInt(usedAmount*100/maxAmount)} />
                </S.CircularProgressWrapper>
            ) 
            : []}
        </S.AnalyticsCardWrapper>
    );
};

export default AnalyticsInfoCard;