import styled from 'styled-components';
import { mqmax, desktopMinSize } from '@Styles/mixins';

export const Form = styled.form`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  grid-gap: calc(15 / 16 * 1rem);

  ${mqmax(desktopMinSize - 1)} {
    grid-gap: calc(10 / 16 * 1rem);
  }
`;

export default Form;
