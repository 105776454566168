import styled, { keyframes } from 'styled-components';
import { desktopMinSize, mq, mqmax, mqmin, overlayZIndex } from '../../../../styles/mixins';

export const Overlay = styled.div`
  min-height: 100vh;
  width: 100vw;
  position: fixed;
  opacity: 0.6;
  inset: 0;
  background-color: #000;
`;

export const GeneralWrapper = styled.div`
  z-index: ${overlayZIndex + 99999};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
`;

export const FormContainer = styled.form`
  width: 100%;
  height: calc(500 / 16 * 1rem);
  position: relative;
  border-radius: calc(64 / 16 * 1rem);
  background: transparent linear-gradient(139deg, #426fe5 0%, #a00bf6 100%) 0% 0% no-repeat padding-box;
  max-width: calc(445 / 16 * 1rem);
  .close-button {
    position: absolute;
    right: 1.7rem;
    top: 2rem;
    background: transparent;
    z-index: 1;
    cursor: pointer;
  }

  ${mqmax(desktopMinSize - 1)} {
    height: calc(420 / 16 * 1rem);
    max-width: calc(360 / 16 * 1rem);
    border-radius: calc(40 / 16 * 1rem);
  }

  ${mqmin(1900)} {
    height: calc(600 / 16 * 1rem);
    max-width: calc(540 / 16 * 1rem);
  }
`;

export const FormBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 3.5rem 2rem 2rem 2rem;
  border: solid transparent calc(5 / 16 * 1rem);
  border-radius: calc(64 / 16 * 1rem);
  background: #21222a;
  transform: scale(0.99);
  gap: 1rem;

  ${mqmax(desktopMinSize - 1)} {
    padding: 3.5rem 1rem 1rem 1rem;
    border-radius: calc(40 / 16 * 1rem);
  }
`;

const growAndShrink = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`;

export const CallToActionWrapper = styled.div`
  display: ${({ step }) => (step === 1 ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  gap: 1.5rem;

  & > img {
    animation: ${growAndShrink} 3s infinite;
    width: 30%;
    cursor: pointer;
    ${mqmax(desktopMinSize - 1)} {
      width: 25%;
    }
  }

  & > p {
    font-size: 1.5rem;
    font-weight: 700;
    ${mqmax(desktopMinSize - 1)} {
      font-size: 1rem;
    }
    ${mqmin(1900)} {
      font-size: 1.8rem;
    }
  }
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: calc(10 / 16 * 1rem);
  min-height: calc(50 / 16 * 1rem);
  flex: 2;

  ${mqmax(desktopMinSize - 1)} {
    flex: none;
    min-height: auto;
  }
`;

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${({ hasAResult, step }) => (hasAResult || step !== 1 ? 'flex-start' : 'space-between')};
  align-items: center;
  height: 100%;
  row-gap: ${({ hasAResult }) => (hasAResult ? 'calc(50 / 16 * 1rem)' : 'calc(28 / 16 * 1rem)')};
  min-height: 360px;

  ${mqmax(desktopMinSize - 1)} {
    min-height: ${({ step }) => (step === 1 ? ' 100%' : 'auto')};
    max-height: 266px;
    height: auto;
    row-gap: ${({ hasAResult }) => (hasAResult ? 'calc(20 / 16 * 1rem)' : 'calc(25 / 16 * 1rem)')};
  }

  ${mqmin(1900)} {
    row-gap: ${({ hasAResult }) => (hasAResult ? 'calc(50 / 16 * 1rem)' : 'calc(55 / 16 * 1rem)')};
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 0.8rem;
`;

export const UseTypeWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;

  ${mqmax(desktopMinSize - 1)} {
    grid-template-columns: repeat(2, 120px);
  }
`;

export const TypeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.8rem;
  padding: 0.5rem 0;
  transition: all 0.2s ease-in-out;
  background: ${({ selected }) => (selected ? 'rgba(0, 0, 0, 0.4)' : 'rgba(23, 23, 26, 0.7)')};
  border-radius: 1.5rem;
  position: relative;
  cursor: pointer;
  border: ${({ selected }) => (selected ? '2px solid #2d80f2' : '2px solid #333')};
  transform: ${({ selected }) => (selected ? 'scale(1.05)' : 'scale(1)')};

  ${mq(1400, desktopMinSize)} {
    min-height: 108px;
  }

  & > img {
    width: 30%;
  }

  & > h3 {
    font-size: 1.2rem;
    font-weight: 700;
    text-align: center;
    ${mqmax(desktopMinSize - 1)} {
      font-size: 0.7rem;
    }
  }

  &:hover {
    transform: scale(1.05);
    border: 2px solid #2d80f2;
  }

  ${mqmax(desktopMinSize - 1)} {
    transform: ${({ selected }) => (selected ? 'scale(1)' : 'none')};
    gap: 0.5rem;
    padding: 0.8rem 0;
  }
`;

export const Title = styled.h2`
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
  line-height: 1.5rem;
  ${mqmax(desktopMinSize - 1)} {
    font-size: 1rem;
  }

  ${mqmin(1900)} {
    font-size: 1.5rem;
  }
`;

export const Subtitle = styled.p`
  font-size: 0.7rem;
  line-height: 1rem;
  text-align: center;
  ${mqmin(1900)} {
    font-size: 1rem;
  }
`;
