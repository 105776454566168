import React from 'react';
import { ShopPageThirdStepWrapper, ShopPageThirdStepContentWrapper, ShopPageThirdStepButtonWrapper } from './styled';

export const ThirdStep = ({ nextStep, skipTutorial }) => {
  return (
    <ShopPageThirdStepWrapper>
      <ShopPageThirdStepContentWrapper>
        <strong>
          Ao clicar em &#34;adicionar ao carrinho&#34; o sistema vai <span>listar os planos escolhidos.</span>
        </strong>
        <ShopPageThirdStepButtonWrapper>
          <button onClick={nextStep}>Entendi</button>
          <button onClick={skipTutorial}>Fechar tutorial</button>
        </ShopPageThirdStepButtonWrapper>
      </ShopPageThirdStepContentWrapper>
    </ShopPageThirdStepWrapper>
  );
};
