import React from 'react';

import Container from './Container';
import Discord from './Discord';
import Email from './Email';

export const Buttons = () => (
  <Container>
    <Discord />
    <Email />
  </Container>
);

export default Buttons;
