import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Presentation from './Presentation';
import Title from './Title';
import Actions from './Actions';
import IsLoadingTitle from './Title/IsLoadingTitle';
import { IsLoadingActions } from './Actions/IsLoadingActions';
import { Loading } from '../../../components/Loading';
import { ProgramTutorial } from '../../../components/AMITutorial/ProgramTutorial';
import { useTutorialStep } from '../../../context/TutorialStepContext';

import * as S from './styled';

import ComputerContext from './context';
import { IsLoadingComputerHeaderContent } from './Presentation/IsLoadingComputerHeaderContent';
import { SessionTime } from './SessionTime';
import { useTips } from '../../../utils/useTips';
import { useUserRole } from '../../../context/UserRoleContext';

export const ComputerView = () => {
  const { state } = useTutorialStep();
  return (
    <S.ComputeViewWrapper>
      <SessionTime />
      <S.Computer step={state[2].step}>
        <ProgramTutorial />
        <Presentation />
        <S.ComputerContentWrapper>
          <Title />
          <Actions />
        </S.ComputerContentWrapper>
      </S.Computer>
    </S.ComputeViewWrapper>

  );
};

export const IsLoadingComputerView = () => {
  const { clientType } = useUserRole();
  const isB2C = clientType === "B2C";
  const { tip, nextTip, previousTip } = useTips(
    shuffle([
      <>
        Você sabia que pode adicionar mais tempo à sua conta mesmo
        <br /> com o computador ligado no plano Classic? Basta acessar a Loja e adicionar
        <br /> o tempo, <b>desta forma o computador se manterá ligado.</b>
      </>,
      <>
        Seu tempo não conta enquanto estiver na fila, a contagem só inicia quando aparece a mensagem “aguarde um momento
        estamos preparando tudo para você”.
      </>,
      <>
        Clicando em “Dúvidas frequentes” você pode enviar uma mensagem para nós, mas se preferir falar com a gente
        acesse o nosso Discord clicando{' '}
        <a href="https://discord.com/invite/noar-798022821342609418" target="_blank" rel="noreferrer">
          aqui
        </a>
        .
      </>,
      <>
        Não quer que sua sessão tenha limites de tempo? Você pode adicionar horas e utilizar o tempo que precisar sem
        interrupções, saiba mais.
      </>,
      <>
        Está em dúvida sobre a sua sessão? Acesse o menu “Histórico de acesso”, lá você terá todas as informações sobre
        os seus últimos acessos.
      </>,
      <>
        Quer receber informações e promoções exclusivas? Basta ir “Minha conta” marcar a caixa “Aceito receber
        informações e promoções”.
      </>,
      <>
        Você pode instalar o que quiser nas máquinas da NOAR, mas não esqueça de verificar a compatibilidade com o Cloud
        e se está dentro dos nossos termos de uso.
      </>,
      <>Lembre-se que é expressamente proibida a mineração de criptomoedas nas máquinas da NOAR.</>,
      <>Não é permitido o uso de torrents na NOAR.</>,
      <>
        Tudo o que você baixar na máquina da NOAR será excluído ao desligar e finalizar a sua sessão, certifique-se de
        salvar todas as informações, ou contratar o NOAR Personal.
      </>,
      <>
        Está com dúvidas de como utilizar a NOAR? Clique em “ver tutorial” que as informações estarão lá. Caso prefira,
        entre em contato com a gente pelo nosso Discord{' '}
        <a href="https://discord.com/invite/noar-798022821342609418" target="_blank" rel="noreferrer">
          aqui.
        </a>
      </>,
      <>
        A NOAR não disponibiliza as licenças dos programas utilizados na NUVEM, para ter acesso aos jogos e programas,
        você precisa possuir as licenças.
      </>,
      <>
        Lembre-se, para acessar, você precisa ter o moonlight instalado, baixe ele por{' '}
        <a href="https://github.com/moonlight-stream/moonlight-qt/releases" target="_blank" rel="noreferrer">
          aqui.
        </a>
      </>,
      <>Quer receber novidades e promoções? Siga a gente nas nossas redes sociais @noarcloud.</>,
    ]),
  );

  function shuffle(array) {
    let currentIndex = array.length,
      randomIndex;

    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }

    return array;
  }

  return (
    <>
      <S.IsLoadingComputerWrapper>
        <S.IsLoadingComputerHeader>
          <IsLoadingComputerHeaderContent />
        </S.IsLoadingComputerHeader>
        <S.IsLoadingComputer>
          <IsLoadingActions />
          <IsLoadingTitle />
        </S.IsLoadingComputer>
        {isB2C ? <S.IsLoadingTipsWrapper>
          <button onClick={previousTip}></button>
          <p>{tip}</p>
          <button onClick={nextTip}></button>
        </S.IsLoadingTipsWrapper> : []}
      </S.IsLoadingComputerWrapper>
    </>
  );
};

export function useComputer() {
  const [authCode, setAuthCode] = useState('');
  const [formAuthenticationStatus, setFormAuthenticationStatus] = useState('');
  const [formMessage, setFormMessage] = useState('');
  const { state, isLoading } = useSelector(state => state.computer);

  return {
    value: {
      authCode,
      formAuthenticationStatus,
      formMessage,
      isLoading,
      setAuthCode,
      setFormAuthenticationStatus,
      setFormMessage,
      state,
    },
  };
}

export function Computer() {
  const context = useComputer();
  const { isLoading, state } = context.value;
  const { session } = useSelector(state => state.computer);

  return (
    <ComputerContext.Provider {...context}>
      {isLoading ? (
        <IsLoadingComputerView isLoading={isLoading} state={state} />
      ) : (
        <>{typeof session != 'undefined' ? <ComputerView /> : <Loading />}</>
      )}
    </ComputerContext.Provider>
  );
}

export default Computer;
