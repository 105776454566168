import styled from "styled-components";
import { desktopMinSize, mq, mqmax, mqmin } from "../../../../../../styles/mixins";

export const SubscriptionSummaryWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    width: 508px;

    ${mqmax(desktopMinSize - 1)} {
        width: 100%;
    }

    ${mqmin(1900)} {
        width: 641px;
    }

    ${mq(desktopMinSize + 300, desktopMinSize)} {
        max-width: 410px;
        gap: 0.8rem;
    }
`;

export const SubscriptionSummaryContent = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    row-gap: 2rem;
`;

export const SubscriptionSummaryTitle = styled.h2`
    font-size: 20px;
    font-weight: 500;

    ${mqmin(1900)} {
        font-size: 1.5rem;
    }

    ${mq(desktopMinSize + 300, desktopMinSize)} {
      font-size: 0.8rem;
    }
    
    ${mqmax(desktopMinSize - 1)} {
        font-size: 1.1rem;
        text-align: center;
    }
`;

export const SubscriptionSummaryContentItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 183px;

    ${mqmin(1900)} {
        gap: 0.3rem;
    }

    ${mqmax(desktopMinSize - 1)} {
        min-width: 120px;
    }
`;

export const SubscriptionSummaryContentItemTitle = styled.h3`
    font-size: 0.8rem;
    font-weight: 500;

    ${mqmin(1900)} {
        font-size: 1.2rem;
    }
`;

export const SubscriptionSummaryContentItemValue = styled.p`
    font-size: 1.2rem;
    font-weight: 400;

    ${mqmin(1900)} {
        font-size: 1.6rem;
    }

    ${mq(desktopMinSize + 300, desktopMinSize)} {
      font-size: 1rem;
    }

    ${mqmax(desktopMinSize - 1)} {
        font-size: 0.8rem;
    }
`;

export const SubscriptionSummaryContentItemPrice = styled.p`
    font-size: 18px;
    font-weight: 500;

    ${mqmin(1900)} {
        font-size: 1.5rem;
    }
`;

export const SmallText = styled.p`
    font-size: 0.7rem;
    font-weight: 400;

    ${mqmin(1900)} {
        font-size: 1rem;
    }
`;
