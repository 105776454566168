import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Box from './Box';
import Container from './Container';
import Close from './Close';
import styled from 'styled-components';
import Header from './Header';
import Form from './Form';
import Input from './Input';
import ErrorMessage from './ErrorMessage';
import SectionSubtitle from './PaymentSubtitle';
import Checkbox from './Checkbox';
import Submit from './Submit';
import { maskCpf, maskCnpj, maskDate } from '@Utils/masks';
import { confirmBuyProducts, getUserInfo } from '@Services/noar-api';
import { Loading } from '../../../Loading';
import { mqmax, desktopMinSize } from '@Styles/mixins';
import { REDIRECT_TO_LOGIN, messageDisconnect, ERROR, ORIGIN_PERSONAL } from '@Constants';
import { SessionExpired } from '@Errors';
import { openConfirmationLightbox } from '@Store/ducks/global/actions';
import { cpf, cnpj } from 'cpf-cnpj-validator';

export const PersonalData = ({
  close,
  handleViewControl,
  productType,
  paymentOrder,
  personalInfo,
  handlePixInfo,
  handlePersonalInfo,
  handleConfirmPaymentReturn
}) => {
  const [name, handleName] = useState(personalInfo?.name || '');
  const [email, handleEmail] = useState(personalInfo?.email || '');
  const [cpfCnpj, handleCpfCnpj] = useState(personalInfo?.cpfCnpj || '');
  const [birthDate, handleBirthDate] = useState(personalInfo?.birthDate || '');
  const [paymentOption, handlePaymentOption] = useState(personalInfo?.paymentOption || 'CARTAO');
  const [loading, handleLoading] = useState(false);
  const [errorMessage, handleErrorMessage] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { accountPersonalInfo } = useSelector(state => state.global);

  function hasAFullName() {
    const result = name.trim().split(' ');
    if (result?.length < 2) {
      handleErrorMessage('Deve haver pelo menos um segundo nome e/ou sobrenome');
      return false;
    }
    handleErrorMessage(null);
    return true;
  }

  function handleDisableButton({ cpfCnpj, name, birthDate }) {
    if (removeSpecialCharacters(cpfCnpj).length !== 11 && removeSpecialCharacters(cpfCnpj).length !== 14) return true;
    if (errorMessage) return true;
    if (paymentOption === 'CARTAO' && birthDate.length < 8) return true;
    if (!name) return true;
    if (!paymentOption) return true;
    return false;
  }

  // ASYNC FUNCTIONS

  async function effectPix() {
    try {
      handleLoading(true);
      let correctName = name.trim();
      if (name.split(' ').some(s => s === '')) {
        const nameWithoutSpaces = name.split(' ').filter(s => s !== '')?.join(' ').trim();
        correctName = nameWithoutSpaces;
      }
      const user = {
        name: correctName,
        identityType: removeSpecialCharacters(cpfCnpj)?.length > 11 ? 'CNPJ' : 'CPF',
        identity: removeSpecialCharacters(cpfCnpj)
      };
      const payload = {
        order_id: paymentOrder?.id_order,
        user,
        type: 'Pix'
      };
      const { payment: response } = await confirmBuyProducts(payload, paymentOrder?.id_order);
      handleLoading(false);
      if (response?.order_id) {
        handlePixInfo(response);
        handleViewControl(3);
      } else {

        handleConfirmPaymentReturn({ status: ERROR });
      }
    } catch (error) {
      handleLoading(false);
      if (error instanceof SessionExpired) {
        const turnOnMachine = false;
        const disconnect = true;
        dispatch(
          openConfirmationLightbox(
            () => {
              history.push(REDIRECT_TO_LOGIN);
            },
            messageDisconnect.message,
            messageDisconnect.confirmBtnText,
            messageDisconnect.refuseBtnText,
            turnOnMachine,
            disconnect,
            false,
          ),
        );
      } else {

        handleConfirmPaymentReturn({ status: ERROR });
      }
    }
  }

  async function getData() {
    handleName(accountPersonalInfo?.accountPersonalInfo?.name);
    handleEmail(accountPersonalInfo?.accountPersonalInfo?.email);
    handleCpfCnpj(accountPersonalInfo?.accountPersonalInfo?.personal_information?.cpf);
  }

  useEffect(() => {
    getData();
  }, []);

  // EVENTS

  function onChangeName(event) {
    if (/^[a-zöüóőúéãáàűíÖÜÓŐÚÉÃÁÀŰÍçÇ ]{0,100}$/i.test(event.target.value)) {
      return handleName(event.target.value);
    }
  }

  function onChangeBirthDate(event) {

    const value = event.target.value.slice(0, 10);
    const [day, month, year] = value.split('/');

    const isDayValid = day > 0 && day <= 31;
    const isMonthValid = month > 0 && month <= 12;
    const isYearValid = year > 1900 && year <= new Date().getFullYear();

    if (day && month && year) {
      if (!(isDayValid && isMonthValid && isYearValid)) {
        handleErrorMessage('Data de nascimento inválida');
      } else {
        handleErrorMessage(null);
      }
    } else {
      handleErrorMessage(null);
    }

    handleBirthDate(event.target.value?.replace(/\//g, '-'));
  }

  function onChangeCpfCnpj(event) {
    let cpfCnpj = event.target.value?.replace(/[/.-]/g, '')?.trim();
    if (cpfCnpj.length > 14) {
      cpfCnpj = cpfCnpj.slice(0, 14);
    }

    if (cpfCnpj.length === 11) {
      if (!cpf.isValid(cpfCnpj)) {
        handleErrorMessage('CPF/CNPJ inválido');
      }
      else {
        handleErrorMessage(null);
      }
    }
    else if (cpfCnpj.length === 14) {
      if (!cnpj.isValid(cpfCnpj)) {
        handleErrorMessage('CPF/CNPJ inválido');
      }
      else {
        handleErrorMessage(null);
      }
    }
    else {
      handleErrorMessage(null);
    }

    handleCpfCnpj(event.target.value);
  }

  function onChangePaymentOption(option) {
    handlePaymentOption(option);
  }

  function removeSpecialCharacters(string) {
    return string?.replace(/[/.-]/g, '');
  }

  function onSubmitForm(ev) {
    ev.preventDefault();
    const nameValidation = hasAFullName();
    if (nameValidation) {
      handlePersonalInfo({
        name,
        email,
        cpfCnpj: removeSpecialCharacters(cpfCnpj),
        birthDate,
        paymentOption
      });
      if (paymentOption === 'PIX') {
        effectPix();
      } else {
        handleViewControl(2);
      }
    }
    return false;
  }

  return (
    <Container>
      {loading ? (
        <Loading whichLoading={'generic'} />
      ) : (
        <Box>
          <CloseDiv>
            <Close close={close} />
          </CloseDiv>
          <Header productType={productType} />
          <Form onSubmit={onSubmitForm}>
            <Div>
              <CheckboxDiv>
                {productType !== ORIGIN_PERSONAL ? (<Checkbox
                  value={paymentOption}
                  option={'PIX'}
                  onChange={() => {
                    onChangePaymentOption('PIX');
                  }}
                  text={'PIX'}
                />) : []}
                <Checkbox
                  value={paymentOption}
                  option={'CARTAO'}
                  onChange={() => {
                    onChangePaymentOption('CARTAO');
                  }}
                  text={'Cartão de crédito'}
                />
              </CheckboxDiv>
            </Div>
            <Div>
              <SectionSubtitle>Informações pessoais</SectionSubtitle>
            </Div>
            <Input
              type="text"
              name="name"
              placeholder="Nome Completo"
              required
              inputProps={{ maxLength: 255 }}
              value={name}
              onChange={onChangeName}
              autoComplete="off"
              variant="outlined"
            />

            <Input
              type="text"
              name="cpfcnpj"
              placeholder="CPF/CNPJ"
              required
              inputProps={{ minLength: 14 }}
              maxLength={18}
              value={removeSpecialCharacters(cpfCnpj)?.length > 11 ? maskCnpj(cpfCnpj) : maskCpf(cpfCnpj)}
              onChange={onChangeCpfCnpj}
              autoComplete="off"
              variant="outlined"
            />

            {
              paymentOption === 'CARTAO' ? (
                <>
                  <Input
                    type="email"
                    name="email"
                    placeholder="E-mail"
                    required
                    inputProps={{ readOnly: true }}
                    value={email}
                    // onChange={onChangeEmail}
                    maxLength={255}
                    autoComplete="off"
                    variant="outlined"
                  />

                  <Input
                    type="text"
                    name="birthDate"
                    placeholder="Data de Nascimento"
                    required
                    value={maskDate(birthDate)}
                    inputProps={{ minLength: 8 }}
                    onChange={onChangeBirthDate}
                    autoComplete="off"
                    variant="outlined"
                  />
                </>
              ) : []
            }

            {errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : []}
            <SubmitDiv>
              <Submit props={{ disabled: handleDisableButton({ cpfCnpj, name, birthDate }) }} />
            </SubmitDiv>
          </Form>
        </Box>
      )}
    </Container>
  );
};

export default PersonalData;

const CloseDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  height: auto;
`;

const Div = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: calc(15 / 16 * 1rem);
  height: auto;
  grid-column-start: 1;
  grid-column-end: 3;
  margin-top: calc(20 / 16 * 1rem);
`;

const SubmitDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: calc(15 / 16 * 1rem);
  height: auto;
  grid-column-start: 1;
  grid-column-end: 3;
  margin-top: calc(14 / 16 * 1rem);
  ${mqmax(desktopMinSize - 1)} {
    margin-top: calc(10 / 16 * 1rem);
  }
`;

const CheckboxDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: calc(52 / 16 * 1rem);
  ${mqmax(desktopMinSize - 1)} {
    column-gap: calc(30 / 16 * 1rem);
  }
`;
