import { CallToActionWrapper, HeaderWrapper, Step, Subtitle, Title, TypeContainer, UseTypeWrapper } from '../styled';
import designthinking from '../../../HowItWorks/icons/designthinking.png';
import controledevideogame from '../../../HowItWorks/icons/controle.png';
import alunograduacao from '../../../HowItWorks/icons/alunograduacao.png';
import programas from '../../../HowItWorks/icons/programas.png';
import botaoPlay from '../icon/botao_play.png';
import { FirstQuestionPlay, SecondQuestionPlay, ThirdQuestionPlay } from './playSteps';
import { FirstQuestionStudy, SecondQuestionStudy, ThirdQuestionStudy } from './studySteps';
import { FirstQuestionWork, SecondQuestionWork } from './workSteps';
import { FirstQuestionMiscAct, SecondQuestionMiscAct, ThirdQuestionMiscAct } from './miscActSteps';
import { ResultTitle } from './styled';
import ResultInfo from './resultStep';

export const StepOne = ({ handleNextStep, step }) => {
  return (
    <Step step={step}>
      <HeaderWrapper>
        <Title>Pronto para descobrir o seu plano?</Title>
      </HeaderWrapper>
      <CallToActionWrapper step={step}>
        <img src={botaoPlay} alt="botao play" onClick={handleNextStep} />
        <p>Vamos lá?</p>
      </CallToActionWrapper>
      <Subtitle>Aperte o botão para começar</Subtitle>
    </Step>
  );
};

export const StepTwo = ({ useType, onClickUseType }) => {
  return (
    <Step>
      <HeaderWrapper>
        <Title>Você quer usar a Noar para:</Title>
      </HeaderWrapper>
      <UseTypeWrapper>
        <TypeContainer
          onClick={() => {
            onClickUseType('WORK');
          }}
          selected={useType === 'WORK'}
        >
          <img src={designthinking} alt="Icone ilustrando uma lampada como ideia." />
          <h3>Trabalhar</h3>
        </TypeContainer>
        <TypeContainer
          onClick={() => {
            onClickUseType('PLAY');
          }}
          selected={useType === 'PLAY'}
        >
          <img src={controledevideogame} alt="Controle de video game." />
          <h3>Jogar</h3>
        </TypeContainer>
        <TypeContainer
          onClick={() => {
            onClickUseType('STUDY');
          }}
          selected={useType === 'STUDY'}
        >
          <img src={alunograduacao} alt="Aluno formado." />
          <h3>Estudar</h3>
        </TypeContainer>
        <TypeContainer
          onClick={() => {
            onClickUseType('MISCELLANEOUS_ACTIVITIES');
          }}
          selected={useType === 'MISCELLANEOUS_ACTIVITIES'}
        >
          <img src={programas} alt="Um computador com várias informações saindo de dentro dele." />
          <h3>Ativ. diversas</h3>
        </TypeContainer>
      </UseTypeWrapper>
    </Step>
  );
};

export const StepThree = ({
  useType,
  gameFrequency,
  setGameFrequency,
  studyMoments,
  setStudyMoments,
  useNoarProfessional,
  setUseNoarProfessional,
  usedNoarActivities,
  setUsedNoarActivities,
}) => {
  return useType === 'WORK' ? (
    <FirstQuestionWork useNoarProfessional={useNoarProfessional} setUseNoarProfessional={setUseNoarProfessional} />
  ) : useType === 'PLAY' ? (
    <FirstQuestionPlay gameFrequency={gameFrequency} setGameFrequency={setGameFrequency} />
  ) : useType === 'STUDY' ? (
    <FirstQuestionStudy studyMoments={studyMoments} setStudyMoments={setStudyMoments} />
  ) : useType === 'MISCELLANEOUS_ACTIVITIES' ? (
    <FirstQuestionMiscAct usedNoarActivities={usedNoarActivities} setUsedNoarActivities={setUsedNoarActivities} />
  ) : (
    []
  );
};

export const StepFour = ({
  useType,
  saveProgressComputerGames,
  setSaveProgressComputerGames,
  differentStudySoftware,
  setDifferentStudySoftware,
  professionalStorage,
  setProfessionalStorage,
  softwareDifferentActivities,
  setSoftwareDifferentActivities,
}) => {
  return useType === 'WORK' ? (
    <SecondQuestionWork professionalStorage={professionalStorage} setProfessionalStorage={setProfessionalStorage} />
  ) : useType === 'PLAY' ? (
    <SecondQuestionPlay
      saveProgressComputerGames={saveProgressComputerGames}
      setSaveProgressComputerGames={setSaveProgressComputerGames}
    />
  ) : useType === 'STUDY' ? (
    <SecondQuestionStudy
      differentStudySoftware={differentStudySoftware}
      setDifferentStudySoftware={setDifferentStudySoftware}
    />
  ) : useType === 'MISCELLANEOUS_ACTIVITIES' ? (
    <SecondQuestionMiscAct
      softwareDifferentActivities={softwareDifferentActivities}
      setSoftwareDifferentActivities={setSoftwareDifferentActivities}
    />
  ) : (
    []
  );
};

export const StepFive = ({
  result,
  useType,
  gameSessionTime,
  setGameSessionTime,
  storeFilesStudy,
  setStoreFilesStudy,
  storeFilesActivities,
  setStoreFilesActivities,
}) => {
  return useType === 'PLAY' ? (
    <ThirdQuestionPlay gameSessionTime={gameSessionTime} setGameSessionTime={setGameSessionTime} />
  ) : useType === 'STUDY' ? (
    <ThirdQuestionStudy storeFilesStudy={storeFilesStudy} setStoreFilesStudy={setStoreFilesStudy} />
  ) : useType === 'MISCELLANEOUS_ACTIVITIES' ? (
    <ThirdQuestionMiscAct
      storeFilesActivities={storeFilesActivities}
      setStoreFilesActivities={setStoreFilesActivities}
    />
  ) : (
    <Result result={result} />
  );
};

export const Result = ({ result }) => {
  const resultTitle = result === 'CLASSIC' ? "Horas Avulsas" : result;
  return (
    <Step hasAResult={result}>
      <HeaderWrapper>
        <Title>O seu melhor plano é o...</Title>
        <ResultTitle>{resultTitle}</ResultTitle>
      </HeaderWrapper>
      <ResultInfo result={result} />
    </Step>
  );
};
