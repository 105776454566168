import React from 'react';
import { LoadWrapper, LoadingGif } from './styled';
import { IMAGES_URL } from '@Constants';

export const Loading = ({ whichLoading }) => {
  return (
    <LoadWrapper whichLoading={whichLoading}>
      <div>
        <LoadingGif src={`${IMAGES_URL}/website/private-pages/computer/load-animation-white.gif`} alt={''} />
      </div>
    </LoadWrapper>
  );
};
