import styled from 'styled-components';
import {
    overlayZIndex,
} from '@Styles/mixins';

export const Wrapper = styled.div`
    z-index: ${overlayZIndex + 1};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
`;

export default Wrapper;
