import React from 'react';
import styled from 'styled-components';

import { desktopMinSize, mqmax } from '@Styles/mixins';

const Checkbox = props => {
  return (
    <CheckboxElementContainer {...props}>
      {props.text}
      <input type="radio" name='payment_option' value={props.value} defaultChecked={props.value === props.option} required />
      <span></span>
    </CheckboxElementContainer>
  );
};

export default Checkbox;

const CheckboxElementContainer = styled.label`
  color: #fff;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-size: 0.938rem;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  & > input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    & ~ span {
      background-color: transparent;
      border: 2px solid #fff;
      border-radius: 4px;
    }

    &:checked ~ span {
      background-color: #17171a;
    }

    &:checked ~ span:after {
      z-index: 1;
      opacity: 1;
      box-shadow: 0 0 0 3.8px #2d80f2;
    }
  }

  & > span {
    position: absolute;
    top: 0;
    left: 0;
    height: 12px;
    width: 12px;
    transition: all 250ms ease-in-out;

    &:after {
      content: '';
      position: absolute;
      opacity: 0;
      z-index: -1;
      top: 6px;
      left: 6px;
      transition: all 250ms ease-in-out;
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    font-size: 0.625rem;
    padding-left: 20px;

    & > span {
      top: -1px;
      height: 10px;
      width: 10px;
    }

    input:checked ~ span:after {
      top: 5px;
      left: 5px;
    }
  }
`;
