import Typography from '@material-ui/core/Typography';
import React from 'react';
import styled from 'styled-components';

export const RegisterConfirmationTitle = props => <H2 variant="h2" {...props} />;

export default RegisterConfirmationTitle;

const H2 = styled(Typography)`
  &.MuiTypography-h2 {
    margin: 0;
    color: #fff;
    font-size: calc(55 / 16 * 1rem);
    font-weight: 700;
    line-height: calc(52 / 48);
    text-align: center;
  }
`;
