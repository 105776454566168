import React, { useEffect, useState } from 'react';
import { Subtitle, Wrapper, Overlay, ButtonsWrapper, CancelInfoText, CancelInfoSubTitle, InfoTitle, InfoWrapper, InfoWarning, RedSpam, GreenSpam, InfoStrong } from './styled';
import { cancelRecurrency } from '@Services/noar-api';
import StyledButton from '../styled';
import { getExternalRecurrency, getLastPersonalPlanInfo, getPersonalActivePlan, sendNpsAnswer } from '../../../../services/noar-api';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { RecurrencyCancelPopup } from './CancelPopup';
import { RecurrencyCardChangePopup } from './Payment';
import { Warning } from '@material-ui/icons';

export const RecurrencyControl = () => {
  const [recurrency, handleRecurrency] = useState();
  const [loading, handleLoading] = useState(false);
  const [success, handleSuccess] = useState(false);
  const [error, handleError] = useState(false);
  const [reasonText, handleReasonText] = useState('');
  const [phase, handlePhase] = useState(1); // 1 - info de cancelamento, 2 - escolheu cancelar, 3 - escolheu continuar, 4 - resultado cancelamento
  const [personalPlan, handlePersonalPlan] = useState();
  const [state, setState] = React.useState({
    price: false,
    dificulties: false,
    fulfillExpetations: false,
    problems: false,
    customerService: false,
    other: false,
  });

  const [personalInfo, handlePersonalInfo] = useState({
    storage: 0,
    hours: 0,
    price: 0,
    renewalDate: 'xx/xx/xxxx',
    status: '---',
  });


  const history = useHistory();

  const subsctiptionStartDate = new Date(personalPlan?.signature_date);
  const subscriptionEndDate = personalPlan?.signature_date
    ? new Date(subsctiptionStartDate.setMonth(subsctiptionStartDate.getMonth() + 1)).toLocaleDateString('pt-BR')
    : null;

  const handleChange = (event) => {
    event.preventDefault();
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const { price, dificulties, customerService, fulfillExpetations, problems, other } = state;
  const checkboxFormError = [price, dificulties, customerService, fulfillExpetations, problems, other].filter((v) => v).length < 1;

  const [hasToShowCancelPopup, handleHasToShowCancelPopup] = useState(false);
  const [hasToShowChangeCardPopup, handleHasToShowChangeCardPopup] = useState(false);

  const toCancelPhase = () => {
    handlePhase(2);
  };

  const toContinuePhase = () => {
    handlePhase(3);
    sendForm(3);
  }

  const toResultPhase = () => {
    handlePhase(4);
  }

  const sendForm = (phase) => {
    var reason, response_type, origin, cancel_reason;
    if (phase === 3) {
      response_type = "IGNORED";
      origin = "PERSONAL_CANCEL";
    }
    else {
      response_type = "ANSWERED";
      origin = "PERSONAL_CANCEL";
      reason = Object.keys(state).filter((v) => state[v]).join(', ');
      cancel_reason = reasonText;

    }
    console.log(reason, response_type, origin, cancel_reason);
    sendNpsAnswer(null, reason, response_type, origin, cancel_reason);
  };

  const closeCancelPopup = () => {
    setState(
      {
        price: false,
        dificulties: false,
        fulfillExpetations: false,
        problems: false,
        customerService: false,
        other: false,
      }
    )
    handlePhase(1);
    handleHasToShowCancelPopup(false);
  };

  const onConfirmCancel = async () => {
    handleLoading(true);
    sendForm(2);
    await cancelRecurrency(recurrency.externalRecurrencyId).then(response => {
      if (response.ok) {
        handleSuccess(true);
        handleLoading(false);
        handleRecurrency();
      } else {
        handleError(true);
        handleLoading(false);
      }
      toResultPhase();
    });
  };

  const onCloseResultPhase = () => {
    closeCancelPopup();
    window.location.reload();
  };

  const onCancelCancel = () => {
    closeCancelPopup();
    history.replace('/my/home');
  };

  const onChangeText = (event) => {
    handleReasonText(event.target.value);
  };

  const handleCancelRecurrency = () => {
    handleHasToShowCancelPopup(true);
  };

  const handleChangeRecurrencyCard = () => {
    handleHasToShowChangeCardPopup(true);
  };

  useEffect(() => {
    async function getRecurrencyData() {
      await getExternalRecurrency().then(response => {
        const sortedRecurrencyList = response.sort((a, b) => {
          return new Date(b.recurrencyDate) - new Date(a.recurrencyDate);
        });
        handleRecurrency(sortedRecurrencyList ? sortedRecurrencyList[0] : []);
      });
      await getPersonalActivePlan().then(response => {
        handlePersonalPlan(response ? response[0] : undefined);
      });

      await getLastPersonalPlanInfo().then(response => {
        if (response.length > 0) {
          const personalPlan = response[0];
          handlePersonalInfo({
            storage: personalPlan.instance_storage_gb,
            hours: personalPlan.contracted_hours,
            price: personalPlan.price,
            renewalDate: new Date(personalPlan.renewal_date).toLocaleDateString('pt-BR'),
            status: personalPlan.recurrency_status === "ACTIVE" ? "Pagamento Aprovado" : "Pagamento Recusado"
          });
        }
      });
    }
    getRecurrencyData();
  }, []);

  return recurrency ? (
    <Wrapper>
      {loading ? <Overlay /> : []}

      <Subtitle>{personalInfo.status === "Pagamento Recusado"
        ? <RedSpam><Warning /> </RedSpam>
        : []}Noar Personal</Subtitle>

      <InfoWrapper>
        <InfoTitle>Armazenamento mensal: <InfoStrong>{personalInfo.storage} GB</InfoStrong></InfoTitle>
        <InfoTitle>Horas mensais: <InfoStrong>{personalInfo.hours} Horas</InfoStrong></InfoTitle>
        <InfoTitle>Valor do plano: <InfoStrong>R$ {personalInfo.price}</InfoStrong></InfoTitle>
        <InfoTitle>Data de renovação: <InfoStrong>{personalInfo.renewalDate}</InfoStrong></InfoTitle>
        <InfoTitle>Status: {personalInfo.status === "Pagamento Recusado"
          ? <RedSpam>{personalInfo.status}</RedSpam>
          : <GreenSpam>{personalInfo.status}</GreenSpam>
        }
        </InfoTitle>
        {personalInfo.status === "Pagamento Recusado"
          ? <InfoWarning>*Por favor, verifique com seu banco ou altere o cartão de pagamento.</InfoWarning>
          : []}
      </InfoWrapper>

      <ButtonsWrapper>
        <StyledButton disabled={personalInfo.status === '---'} onClick={handleChangeRecurrencyCard}> Alterar cartão de cobrança </StyledButton>
        {hasToShowChangeCardPopup && (
          <RecurrencyCardChangePopup
            close={() => handleHasToShowChangeCardPopup(false)}
          />
        )}

        <StyledButton disabled={personalInfo.status === '---'} onClick={handleCancelRecurrency}>Cancelar Assinatura</StyledButton>
        {hasToShowCancelPopup && (
          <RecurrencyCancelPopup
            phase={phase}
            onCloseResultPhase={onCloseResultPhase}
            error={error}
            success={success}
            CancelInfoText={CancelInfoText}
            onConfirmCancel={onConfirmCancel}
            toContinuePhase={toContinuePhase}
            subscriptionEndDate={subscriptionEndDate}
            onCancelCancel={onCancelCancel}
            CancelInfoSubTitle={CancelInfoSubTitle}
            toCancelPhase={toCancelPhase}
            reasonText={reasonText}
            other={other}
            checkboxFormError={checkboxFormError}
            onChangeText={onChangeText}
            handleChange={handleChange}
            price={price}
            dificulties={dificulties}
            customerService={customerService}
            fulfillExpetations={fulfillExpetations}
            problems={problems}
          />
        )}
      </ButtonsWrapper>
    </Wrapper>
  ) : (
    []
  );
};
