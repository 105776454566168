import React, { useState } from 'react';
import * as NoarAPI from '@Services/noar-api';
import Form from './Form';
import Input from './Input';
import Submit from './Submit';
import Footer from './Footer';
import ErrorMessage from './ErrorMessage'
import { useHistory } from 'react-router-dom';
import Overlay from '@Containers/Overlay';
import { REDIRECT_TO_CREATE_PASSWORD } from '@Constants';

export const Content = () => {

    const [email, handleEmail] = useState('');
    const [errorPasswordForgotMessage, handleErrorPasswordForgotMessage] = useState('');
    const [loading, handleLoading] = useState(false);
    const history = useHistory();

    // ASYNC FUNCTIONS

    async function passwordForgot() {
        try {
            handleLoading(true);
            await NoarAPI.passwordForgot(email);
            handleLoading(false);
            history.push({pathname:REDIRECT_TO_CREATE_PASSWORD, state: email});
        } catch(e) {
            handleLoading(false);
            handleErrorPasswordForgotMessage(e.message);
            resetErrorMessage();
        }
    }
    // EVENTS

    function onChangeEmail(event) {
        handleEmail(event.target.value);
    }

    function onSubmitPasswordForgotForm(ev) {
        ev.preventDefault();
        passwordForgot();
    }


    function resetErrorMessage() {
        setTimeout(function () {
        handleErrorPasswordForgotMessage('');
        }, 5000);
    }

    
    return (
        <>
            {loading ? <Overlay /> : []}
            <Form onSubmit={onSubmitPasswordForgotForm}>   
                <Input
                    type="email"
                    name="email"
                    placeholder="E-mail de cadastro"
                    required
                    value={email}
                    onChange={onChangeEmail}
                    maxLength="255"
                    autoComplete="off" 
                    variant="outlined"
                />
                {errorPasswordForgotMessage ? <ErrorMessage>{errorPasswordForgotMessage}</ErrorMessage> : []}
                <Submit />
                <Footer />
            </Form>
        </>
    )
    
}

export default Content;