import React from 'react';
import {
  AMITutorialSecondStepContentWrapper,
  AMITutorialSecondStepButtonWrapper,
  AMITutorialSecondStepWrapper,
  TutorialBackdrop,
} from '../styled';

export const SecondStep = ({ nextStep, skipTutorial }) => {
  return (
    <>
      <TutorialBackdrop />
      <AMITutorialSecondStepWrapper>
        <AMITutorialSecondStepContentWrapper>
          <strong>
            Para saber o que há neles, basta <span>clicar em acessar.</span>
          </strong>

          <AMITutorialSecondStepButtonWrapper>
            <button onClick={nextStep}>Entendi</button>
            <button onClick={skipTutorial}>Fechar tutorial</button>
          </AMITutorialSecondStepButtonWrapper>
        </AMITutorialSecondStepContentWrapper>
      </AMITutorialSecondStepWrapper>
    </>
  );
};
