import React from 'react';
import { Link } from 'react-router-dom';
import { IMAGES_URL_WEBSITE } from '@Constants';
import * as S from './styled';

function HeaderNew() {
  return (
    <S.Header>
      <div className="header__logo">
        <Link className="header__home-link" to="/">
          <img alt="Logo do cabeçalho" src={`${IMAGES_URL_WEBSITE}/header/logo-new.png`} />
        </Link>
      </div>
    </S.Header>
  );
}

export default HeaderNew;
