import React from 'react';
import styled from 'styled-components';

import { Download as List, Title, Subtitle } from '@Components/Public';

const Container = props => (
  <section id="download"  {...props}>
    <Header>
      <Title>
        <strong>Dowload</strong>
      </Title>
      <Subtitle>
        Baixe a <span className="uppercase">Noar</span> com apenas um clique:
      </Subtitle>
    </Header>
    <List />
  </section>
);

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Download = styled(Container)`
  display: grid;
  justify-items: center;
  justify-content: center;
  row-gap: calc(52rem / 16);
`;

export default Download;
