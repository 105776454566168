import * as S from './styled';

export const VideoPlayer = () => {
  return (
    <S.Video>
      <iframe
        src="https://www.youtube.com/embed/IhaBznk3lnI"
        title="Aprenda a transformar seu dispositivo ANTIGO em um PODEROSO computador com a NOAR Cloud (Como usar)"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
        allowfullscreen="allowfullscreen"
        mozallowfullscreen="mozallowfullscreen"
        msallowfullscreen="msallowfullscreen"
        oallowfullscreen="oallowfullscreen"
        webkitallowfullscreen="webkitallowfullscreen"
      ></iframe>
    </S.Video>
  );
};
