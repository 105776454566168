import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { PCAvailableButtonWrapper, PCAvailableWrapper } from './styled';
import { TimeExpiredAlert } from '../TimeExpiredAlert';

let countdownTimeout;

export const PCAvailableAlert = ({ close, startQueue, confirmQueue }) => {
  const { waiting_confirmation_datetime_init } = useSelector(state => state.computer);
  let fullTimeSecondsExpiration = null;
  if (waiting_confirmation_datetime_init) { 
    const currentDate = new Date(new Date().toLocaleString("en-US", {timeZone: "America/Sao_Paulo"}));
    const initDate = new Date(waiting_confirmation_datetime_init);
    fullTimeSecondsExpiration = 180 - Math.floor((currentDate.getTime() - initDate.getTime()) / 1000);
  }
  const [time, setTime] = useState(fullTimeSecondsExpiration >= 0 ? fullTimeSecondsExpiration : 0);
  const [isActive, setIsActive] = useState(true);
  const modalTimeExpiredRef = useRef();

  const fullMinutesAux = Math.floor(time / 60);
  const hours = Math.floor(fullMinutesAux / 60);
  const minutes = Math.floor(fullMinutesAux - hours * 60);
  const seconds = Math.floor(time - hours * 60 * 60 - minutes * 60);

  useEffect(() => {
    if (isActive && time > 0) {
      countdownTimeout = setTimeout(() => {
        setTime(time - 1);
      }, 1000);
    } else if (isActive && time === 0) {
      handleOpenTimeExpiredModal();
      setIsActive(false);
    }
  }, [isActive, time]);

  useEffect(() => {
    new Audio('/alert-sound/alert.mp3').play();
  }, []);

  const handleOpenTimeExpiredModal = () => {
    modalTimeExpiredRef.current.showModal();
  };

  const handleCloseTimeExpiredModal = () => {
    const modal = modalTimeExpiredRef.current;

    modal.setAttribute('closing', '');
    modal.addEventListener(
      'animationend',
      () => {
        modal.removeAttribute('closing');
        modal.close();
      },
      { once: true },
    );
  };

  const closeTimeExpiredModal = () => {
    handleCloseTimeExpiredModal();
    close();
  };

  const restartNewQueue = () => {
    handleCloseTimeExpiredModal();
    startQueue();
  };

  const [hourLeft, hourRight] = String(hours).padStart(2, '0').split('');
  const [minuteLeft, minuteRight] = String(minutes).padStart(2, '0').split('');
  const [secondsLeft, secondsRight] = String(seconds).padStart(2, '0').split('');

  return (
    <>
      <PCAvailableWrapper open>
        <div>
          <strong>
            Temos um PC disponível para você, <span>deseja se conectar agora?</span>
          </strong>
          <span>
            Expira em: {hourLeft}
            {hourRight}:{minuteLeft}
            {minuteRight}:{secondsLeft}
            {secondsRight}
          </span>
          <PCAvailableButtonWrapper>
            <button onClick={confirmQueue}>sim</button>
            <button onClick={close}>não</button>
          </PCAvailableButtonWrapper>
        </div>
      </PCAvailableWrapper>
      <TimeExpiredAlert modalRef={modalTimeExpiredRef} close={closeTimeExpiredModal} startQueue={restartNewQueue} />
    </>
  );
};
