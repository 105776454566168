import React from 'react';
import styled from 'styled-components';

import { desktopMinSize, mqmin } from '@Styles/mixins';

export const Title = () => (
  <H2 variant="h2">
    Fale com <br />a <span>Noar!</span>
  </H2>
);

export default Title;

const H2 = styled.p`
  color: #fff;
  font-size: calc(44rem / 28);
  font-weight: bold;
  line-height: calc(52 / 44);
  text-align: center;
  text-transform: uppercase;

  ${mqmin(desktopMinSize)} {
    font-size: calc(44rem / 16);
    text-align: start;
  }

  span {
    color: transparent;
    text-transform: uppercase;
    background: linear-gradient(92.63deg, #2d80f2 11.13%, #a00bf6 99.54%), linear-gradient(0deg, #ffffff, #ffffff);
    -webkit-background-clip: text;
    background-clip: text;
  }
`;
