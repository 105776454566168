import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  COMPUTER_STATES,
  REDIRECT_TO_LOGIN,
  messageDisconnect,
  QUEUE,
  WAITING_CONFIRMATION,
  LOST_PLAYBOY,
  SELECTED_PLAN_KEY,
  SERVING,
} from '@Constants';
import queueWebsocketUpdate from '@Functions/queue-websocket-update';
import {
  getHasToShowNpsForm,
  getInstance,
  isOnQueue,
  sendNpsAnswer,
  getHasToPrivacyForm,
  leaveQueue,
  queueInitInstance,
  confirmInstance,
} from '@Services/noar-api';
import { SessionExpired } from '@Errors';
import {
  updateComputer,
  updateQueue,
  setComputerDoesntLoading,
  setComputerCreationEnabled,
  setComputerLoading,
  setComputerCreationFinished,
  updateState,
  setSelectedPlan,
} from '@Store/ducks/computer/actions';
import { openConfirmationLightbox } from '@Store/ducks/global/actions';
import { openNpsFormBox, openTermsBox } from '@Store/ducks/global/actions';
import NpsFormBox from '@Containers/NpsFormBox';
import TermsBox from '@Containers/TermsBox';
import MobileHeader from '@Components/Header';
import AMIs from './AMIs';
import Computer from './Computer';
import { Loading } from '@Components/Loading';

import * as S from './styled';
import { ComputerLoadingContextProvider } from './Computer/ComputerLoadingContext';
import { PCAvailableAlert } from '@Components/QueueAlerts/PCAvailableAlert';

import { useUserRole } from '../../context/UserRoleContext';
import { CreateInstanceError } from '../../errors';
import { messageError } from './AMIs/Carousel/AMI/messages';
import { ORIGIN_CLASSIC, ORIGIN_UNIT } from '../../constants';

export function OccasionalUsePageView({
  creatingComputer,
  instance_id,
  isLoading,
  selectedPlan,
  showModalWaitingConfirmation,
  startLeaveQueue,
  startQueue,
  confirmQueue,
}) {
  return (
    <>
      <ComputerLoadingContextProvider selectedPlan={selectedPlan}>
        <MobileHeader />
        <S.Container>
          <>
            {isLoading && !creatingComputer && !instance_id && (
              <S.ContainerLoading>
                <Loading />
              </S.ContainerLoading>
            )}
            {selectedPlan && !instance_id && !isLoading && <AMIs />}
            {(creatingComputer || instance_id) && <Computer />}
          </>
          <NpsFormBox />
          <TermsBox />
        </S.Container>
      </ComputerLoadingContextProvider>
      {showModalWaitingConfirmation ? (
        <PCAvailableAlert close={startLeaveQueue} startQueue={startQueue} confirmQueue={confirmQueue} />
      ) : (
        []
      )}
    </>
  );
}

export function useOccasionalUsePage() {
  const { instance_id, creatingComputer, isLoading, state, aws_id } = useSelector(
    state => state.computer,
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const showModalWaitingConfirmation = state === WAITING_CONFIRMATION;
  const { clientType } = useUserRole();
  var finalSelectedPlan = history.location.pathname === '/use/occasional/classic' ? ORIGIN_CLASSIC : ORIGIN_UNIT;

  async function startLeaveQueue() {
    try {
      dispatch(updateState(null));
      dispatch(setComputerLoading());

      await leaveQueue(instance_id);

      dispatch(updateQueue(null));
      dispatch(setComputerDoesntLoading());
    } catch (error) {
      if (error instanceof SessionExpired) {
        handleSessionExpiredError();
      }
    }
  }

  async function startQueue() {
    try {
      dispatch(updateState(null));
      dispatch(setComputerLoading());
      const response = await queueInitInstance(aws_id);
      dispatch(setComputerDoesntLoading());
      if (response && response.queue_phase !== LOST_PLAYBOY) {
        dispatch(updateState(response));
        if (response.queue_phase === QUEUE) {
          dispatch(setComputerLoading());
          dispatch(setComputerCreationEnabled());
        }
      } else {
        dispatch(setComputerDoesntLoading());
        dispatch(setComputerCreationFinished());
      }
    } catch (error) {
      if (error instanceof SessionExpired) {
        handleSessionExpiredError();
      }
      if (error instanceof CreateInstanceError)
        dispatch(
          openConfirmationLightbox(
            () => {
              window.location.reload();
            },
            messageError.message,
            messageError.confirmBtnText,
            messageError.refuseBtnText,
            null,
            null,
            false,
          ),
        );
    }
  }

  async function confirmQueue() {
    try {
      dispatch(updateState(null));
      dispatch(setComputerLoading());
      dispatch(setComputerCreationEnabled());
      const response = await confirmInstance();
      dispatch(updateComputer(response));
    } catch (error) {
      dispatch(setComputerDoesntLoading());
      dispatch(setComputerCreationFinished());
      if (error instanceof SessionExpired) {
        handleSessionExpiredError();
      }
    }
  }

  const handleSessionExpiredError = () => {
    const turnOnMachine = false;
    const disconnect = true;
    dispatch(
      openConfirmationLightbox(
        () => {
          history.push(REDIRECT_TO_LOGIN);
        },
        messageDisconnect.message,
        messageDisconnect.confirmBtnText,
        messageDisconnect.refuseBtnText,
        turnOnMachine,
        disconnect,
        false,
      ),
    );
  };

  const managePlanAccordingToPage = () => {
    dispatch(setSelectedPlan(finalSelectedPlan));
    localStorage.setItem(SELECTED_PLAN_KEY, finalSelectedPlan);
  };

  useEffect(function () {
    managePlanAccordingToPage();
    getInstance()
      .then(async function (responseBody) {
        if (Array.isArray(responseBody) && responseBody?.length > 0) {
          const computer = responseBody[0];
          if (computer.state === COMPUTER_STATES.RUNNING) dispatch(setComputerDoesntLoading());
          dispatch(updateComputer(computer));
          return;
        }

        var handleQueuePhase = responseQueue => {
          const queue_phase = responseQueue[0]?.queue_phase;
          if (queue_phase === QUEUE) {
            dispatch(setComputerLoading());
            dispatch(setComputerCreationEnabled());
            queueWebsocketUpdate(dispatch, updateQueue, responseQueue[0]);
          } else {
            dispatch(setComputerDoesntLoading());
            dispatch(setComputerCreationFinished());
            if (queue_phase === WAITING_CONFIRMATION) {
              dispatch(updateState(responseQueue[0]));
            } else if (queue_phase === SERVING) {
              dispatch(setComputerLoading());
              dispatch(setComputerCreationEnabled());
            }
          }
        };

        const responseQueue = await isOnQueue('UNIT');
        if (Array.isArray(responseQueue) && responseQueue?.length > 0) {
          handleQueuePhase(responseQueue);
        } else {
          const responseQueue = await isOnQueue('FREE');
          if (Array.isArray(responseQueue) && responseQueue?.length > 0) {
            handleQueuePhase(responseQueue);
          } else {
            dispatch(setComputerDoesntLoading());
            dispatch(setComputerCreationFinished());
          }
        }
      })
      .catch(function (error) {
        if (error instanceof SessionExpired) {
          handleSessionExpiredError();
        }
      });

    getHasToShowNpsForm("GENERAL_WEB")
      .then(resp => {
        if (resp?.has_to_show) {
          dispatch(openNpsFormBox(sendNpsAnswer, sendNpsAnswer, "a Noar", "GENERAL_WEB"));
        }
      })
      .catch(error => {
        console.error(error);
      });

    getHasToPrivacyForm()
      .then(resp => {
        if (resp?.has_to_show) {
          dispatch(openTermsBox());
        }
      })
      .catch(error => {
        console.error(error);
      });
  }, [dispatch]);

  return {
    creatingComputer,
    instance_id,
    isLoading,
    selectedPlan: finalSelectedPlan,
    showModalWaitingConfirmation,
    startLeaveQueue,
    startQueue,
    confirmQueue,
    isAdmin: clientType === 'B2B_ADM',
  };
}

export function OccasionalUsePage() {
  return <OccasionalUsePageView {...useOccasionalUsePage()} />;
}

export default OccasionalUsePage;
