import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import MuiButton from '@material-ui/core/Button';
import { mqmax, desktopMinSize } from '@Styles/mixins';

export const Footer = () => (
  <Button component={Link} to="/" variant="outlined">
    VOLTAR
  </Button>
);

export default Footer;

const Button = styled(MuiButton)`
  &.MuiButton-root {
    box-sizing: border-box;
    padding: 10px;
    width: calc(170 / 16 * 1rem);
    border-color: #2d80f2;
    border-width: calc(3 / 16 * 1rem);
    border-radius: calc(25 / 16 * 1rem);
    color: #2d80f2;
    font-weight: 600;
    background: transparent;
    font-size: calc(15 / 16 * 1rem);
    transition: ${['color', 'background'].map(prop => `${prop} calc(1s / 3) ease-in-out`).join(',')};

    &:hover,
    &.active {
      color: #fff;
      background: #2d80f2;
    }

    ${mqmax(desktopMinSize - 1)} {
      font-size: calc(12 / 16 * 1rem);
      width: calc(160 / 16 * 1rem);
    }
  }
`;
