import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Container } from './styled';
import { EndOfSessionAlert } from '@Components/QueueAlerts/EndOfSessionAlert';
import { AlmostExpiringAlert } from '@Components/QueueAlerts/AlmostExpiringAlert';

let countdownTimeout;

export const SessionTime = () => {
  const { launch_time } = useSelector(state => state.computer);
  const [isActive, setIsActive] = useState(true);
  const modalEndSessionRef = useRef();
  const modalAlmostExpiringRef = useRef();
  const finalTime = 14400;
  const almostExpiring = finalTime - 300;
  const initDate = new Date(launch_time);
  const [time, setTime] = useState();
  const fullMinutesAux = Math.floor(time / 60);
  const hours = Math.floor(fullMinutesAux / 60);
  const minutes = Math.floor(fullMinutesAux - hours * 60);
  const seconds = Math.floor(time - hours * 60 * 60 - minutes * 60);

  const controlVisibilityPage = async () => {
    if (document.visibilityState === 'visible') {
      updateCurrentTime();
    }
  };

  const handleCloseEndSessionModal = () => {
    closeModal(modalEndSessionRef);
  };

  const handleCloseAlmostExpiringModal = () => {
    closeModal(modalAlmostExpiringRef);
  };

  const handleOpenAlmostExpiringModal = () => {
    new Audio('/alert-sound/alert.mp3').play();
    openModal(modalAlmostExpiringRef);
  };

  const openModal = modalRef => {
    modalRef.current.showModal();
  };

  const closeModal = modalRef => {
    const modal = modalRef.current;

    modal.setAttribute('closing', '');
    modal.addEventListener(
      'animationend',
      () => {
        modal.removeAttribute('closing');
        modal.close();
      },
      { once: true },
    );
  };

  const [hourLeft, hourRight] = String(hours).padStart(2, '0').split('');
  const [minuteLeft, minuteRight] = String(minutes).padStart(2, '0').split('');
  const [secondsLeft, secondsRight] = String(seconds).padStart(2, '0').split('');

  async function fetchCurrentDateTime() {
    try {
      const response = await fetch('https://worldtimeapi.org/api/timezone/America/Sao_Paulo');
      const data = await response.json();

      if (response.ok) {
        const currentDateTime = new Date(data.utc_datetime);
        return currentDateTime;
      } else {
        console.error('Failed to fetch current date and time.');
        return null;
      }
    } catch (error) {
      console.error('An error occurred while fetching data:', error);
      return null;
    }
  }

  async function updateCurrentTime() {
    const currentDateTime = await fetchCurrentDateTime();
    if (currentDateTime) {
      setTime(Math.floor((currentDateTime.getTime() - initDate.getTime()) / 1000));
    }
  }

  useEffect(() => {
    if (isActive && time < finalTime) {
      if (time === almostExpiring) {
        handleOpenAlmostExpiringModal();
      }
      countdownTimeout = setTimeout(() => {
        setTime(time + 1);
      }, 1000);
    } else if (isActive && time === finalTime) {
      openModal(modalEndSessionRef);
      setIsActive(false);
    }
  }, [isActive, time, almostExpiring, handleOpenAlmostExpiringModal]);

  useEffect(() => {
    window.addEventListener('visibilitychange', controlVisibilityPage);
    return () => document.removeEventListener('visibilitychange', controlVisibilityPage);
  }, []);

  useEffect(() => {
    async function updateLaunchTimeOnServer() {
      await updateCurrentTime();
    }
    updateLaunchTimeOnServer();
  }, []);

  return (
    <>
      <Container>
        <p>
          Tempo de sessão{' '}
          <span>
            {hourLeft}
            {hourRight}:{minuteLeft}
            {minuteRight}:{secondsLeft}
            {secondsRight}
          </span>
        </p>
      </Container>
      <EndOfSessionAlert modalRef={modalEndSessionRef} close={handleCloseEndSessionModal} />
      <AlmostExpiringAlert modalRef={modalAlmostExpiringRef} close={handleCloseAlmostExpiringModal} />
    </>
  );
};
