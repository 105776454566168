import React from 'react';
import styled from 'styled-components';

import { desktopMinSize, mqmin } from '@Styles/mixins';

import Blog from './Blog';
import Contact from './Contact';
import Download from './Download';
import HowItWorks from './HowItWorks';
import HowToUse from './HowToUse';
import Intro from './Intro';
import Plans from './Plans';
import UnitPopup from './UnitPopup';
import { mq } from '../../styles/mixins';

export const Homepage = () => (
  <Main>
    <Intro />
    {/* <HowItWorks /> */}
    <Plans />
    <HowToUse />
    <Download />
    <Contact />
    <Blog />
    <UnitPopup />
  </Main>
);

export default Homepage;

const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 6rem;
  margin-bottom: calc(80rem / 24);

  ${Intro},
  ${HowItWorks},
  ${Blog} {
    width: 100%;
    height: 100%;
    max-width: min(calc(304 / 360 * 100%), calc(1331rem / 16));
  }

  ${Intro} {
    margin-block: calc(32rem / 16);

    ${mqmin(desktopMinSize + 401)} {
      margin-top: 10%;
    }

    ${mq(desktopMinSize + 400, desktopMinSize)} {
      margin-block: 5%;
    }
  }
`;
