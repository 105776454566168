import React, { useState } from 'react';
import Box from './Box';
import Container from './Container';
import Close from './Close';
import styled from 'styled-components';
import Header from './Header';
import Form from './Form';
import Input from './Input';
import Submit from './Submit';
import Back from './Back';
import { maskDateDayMonth } from '@Utils/masks';
import { mqmax, desktopMinSize } from '@Styles/mixins';
import { maskCard } from '../../../../../../utils/masks';


export const PaymentCard = ({ close, handleViewControl, cardInfo, handleCardInfo, handleLoading }) => {

  const getDefaultExpirationDate = () => {
    if (cardInfo?.card?.expiration_month && cardInfo?.card?.expiration_year)
      return `${cardInfo?.card?.expiration_month}/${cardInfo?.card?.expiration_year}`;
    return '';
  }

  const [cardNumber, handleCardNumber] = useState(cardInfo?.card?.number || '');
  const [expirationDate, handleExpirationDate] = useState(getDefaultExpirationDate());
  const [cvv, handleCvv] = useState(cardInfo?.card?.security_code || '');
  const [cardName, handleCardName] = useState(cardInfo?.card?.holder || '');
  const [expirationDateError, handleExpirationDateError] = useState(null);


  const handleDisableSubmitButton = ({ cardNumber }) => {
    if (cardNumber.length === 0) return true;
    if (cardNumber.includes('_')) return true;
    if (expirationDate.length === 0) return true;
    if (expirationDate.length !== 5 && expirationDate.length !== 7) return true;
    if (cvv.length < 3) return true;
    if (cardName.length < 4) return true;
    return false;
  };

  // EVENTS

  function onChangeCardNumber(event) {
    const cardNumber = event.target.value?.replace(/\s/g, '').replace(/[a-z]/gi, '');
    handleCardNumber(cardNumber);
  }

  function onChangeCardName(event) {
    if (/^[a-zöüóőúéãáàűíÖÜÓŐÃÚÉÁÀŰÍçÇ ]{0,100}$/i.test(event.target.value)) return handleCardName(event.target.value);
  }

  function onChangeExpirationDate(event) {

    const isValidDate = (valueToTest) => {
      const todayDate = new Date();
      const [month, year] = valueToTest.split('/');

      const monthIsValid = Number(month) >= 1 && Number(month) <= 12;
      const yearIsValid = todayDate.getFullYear() <= Number(year);

      return monthIsValid && yearIsValid;
    }

    const value = event.target.value;
    if (value.length === 5 || value.length === 7) {

      let valueToTest = value;

      if (value.length === 5) {
        const [month, year] = value.split('/');
        valueToTest = `${month}/20${year}`;
      }

      if (!isValidDate(valueToTest)) {
        handleExpirationDateError('Data de expiração inválida');
      } else {
        handleExpirationDateError(null);
      }
    }
    else {
      handleExpirationDateError(null);
    }

    handleExpirationDate(event.target.value.substring(0, 7));
  }

  function onChangeCvv(event) {
    handleCvv(event.target.value);
  }

  function removeSpecialCharacters(string) {
    return string?.replace(/[/.-]/g, '');
  }

  async function onSubmitForm(ev) {
    ev.preventDefault();
    handleLoading(true);

    let finalExpirationDate = expirationDate;
    if (finalExpirationDate.length === 5) {
      const [month, year] = expirationDate.split('/');
      finalExpirationDate = `${month}/20${year}`;
    }
    handleCardInfo({
      card: {
        number: removeSpecialCharacters(cardNumber),
        holder: cardName.replace(/\s+$/, ''),
        expiration_month: finalExpirationDate.substring(0, 2),
        expiration_year: finalExpirationDate.substring(3, 7),
        security_code: cvv.replace(/\s+$/, ''),
      }
    });
    handleLoading(false);
  }

  return (
    <Container>
      <Box>
        <ActionsDiv>
          <Back
            goBack={() => {
              handleViewControl(1);
            }}
          ></Back>
          <Close close={close} />
        </ActionsDiv>
        <Header />
        <Form onSubmit={onSubmitForm}>
          <FullWidthField>
            <Input
              type="text"
              name="cardNumber"
              autoComplete="cc-number"
              placeholder="Número do cartão"
              required
              autoFocus
              inputProps={{ maxLength: 19 }}
              value={maskCard(cardNumber)}
              variant="outlined"
              onChange={onChangeCardNumber}
            />
          </FullWidthField>
          <Input
            type="text"
            name="expirationDate"
            placeholder="Data de expiração"
            required
            value={maskDateDayMonth(expirationDate)}
            onChange={onChangeExpirationDate}
            inputProps={{ minLength: 5, maxLength: 7 }}
            autoComplete="cc-exp"
            variant="outlined"
            error={Boolean(expirationDateError)}
            helperText={expirationDateError}
          />

          <Input
            type="text"
            name="cvv"
            placeholder="CVV"
            required
            autoComplete="cc-csc"
            inputProps={{ minLength: 3, maxLength: 4 }}
            value={cvv}
            onChange={onChangeCvv}
            variant="outlined"
          />
          <FullWidthField>
            <Input
              type="text"
              name="cardName"
              placeholder="Nome no cartão"
              autocomplete="cc-name"
              required
              value={cardName}
              onChange={onChangeCardName}
              maxLength={255}
              variant="outlined"
            />
          </FullWidthField>
          <Div>{handleDisableSubmitButton({ cardNumber }) ? <Submit props={{ disabled: true }} /> : <Submit />}</Div>
        </Form>
      </Box>
    </Container>
  );
};

export default PaymentCard;

const ActionsDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: auto;
  ${mqmax(desktopMinSize - 1)} {
    height: calc(20 / 16 * 1rem);
  }
`;

const Div = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  grid-column-start: 1;
  grid-column-end: 3;
  margin-top: calc(22 / 16 * 1rem);
  ${mqmax(desktopMinSize - 1)} {
    margin-top: calc(4 / 16 * 1rem);
  }
`;

const FullWidthField = styled.div`
  width: 100%;
  grid-column-start: 1;
  grid-column-end: 3;

  &:first-child > input {
    max-width: 481px;
    width: 100%;
    height: 42px;
    border: 3px solid transparent;
    border-radius: 25px;
    padding-left: 23px;
    transition: border-color 350ms;

    &:focus {
      outline: 0;
      border-color: #2d80f2;
    }

    &::placeholder {
      color: #787878;
      font-size: 0.938rem;
      font-weight: 300;
      font-family: 'Poppins', sans-serif;
    }

    ${mqmax(desktopMinSize - 1)} {
      width: 266px;
      height: 31px;
      font-size: 0.75rem;
      padding-left: 16px;

      &::placeholder {
        font-size: 0.75rem;
      }
    }
  }
`;
