import React from 'react';
import { ShopPageFifthStepWrapper, ShopPageFifthStepContentWrapper, ShopPageFifthStepButtonWrapper } from './styled';
import { TutorialBackdrop } from '../styled';
export const FifthStep = ({ nextStep, skipTutorial }) => {
  return (
    <>
      <TutorialBackdrop />
      <ShopPageFifthStepWrapper>
        <ShopPageFifthStepContentWrapper>
          <strong>
            Aqui você pode <span>deletar itens do seu carrinho</span>
          </strong>
          <ShopPageFifthStepButtonWrapper>
            <button onClick={nextStep}>Entendi</button>
            <button onClick={skipTutorial}>Fechar tutorial</button>
          </ShopPageFifthStepButtonWrapper>
        </ShopPageFifthStepContentWrapper>
      </ShopPageFifthStepWrapper>
    </>
  );
};
