import { COMPUTER_STATES } from '@Constants';
import { getPersonalActivePlan } from '../services/noar-api';
import { openConfirmationLightbox, setPersonalPlan } from '../store/ducks/global/actions';
import { SessionExpired } from '../errors';
import { REDIRECT_TO_LOGIN, messageDisconnect } from '../constants';

async function computerWebsocketUpdate(dispatch, history, updateComputer, setComputerDoesntLoading, payload) {
  if (Array.isArray(payload) && payload.length > 0) {

    if(payload[0].state === COMPUTER_STATES.STOPPED){
      await handleUpdatePersonalInfo(dispatch, history);
      dispatch(updateComputer(null));
      dispatch(setComputerDoesntLoading());
    }
    else{
      dispatch(updateComputer(payload[0]));
      if(payload[0].state === COMPUTER_STATES.RUNNING){
        dispatch(setComputerDoesntLoading());
      }
    }

  } else {
    dispatch(updateComputer(null));
    dispatch(setComputerDoesntLoading());
  }
}

async function handleUpdatePersonalInfo(dispatch, history) {
  
  try{
    var personalPlan = await getPersonalActivePlan();
    dispatch(setPersonalPlan(personalPlan[0]?.is_active, personalPlan[0]?.status, null, personalPlan[0]?.signature_date)); 
  }catch(error){
    if (error instanceof SessionExpired) {
      sessionStorage.removeItem('@NOARCLOUD-my-home-page-plan-info-modal');
      const turnOnMachine = false;
      const disconnect = true;
      
      dispatch(
        openConfirmationLightbox(
          () => {
            history.push(REDIRECT_TO_LOGIN);
          },
          messageDisconnect.message,
          messageDisconnect.confirmBtnText,
          messageDisconnect.refuseBtnText,
          turnOnMachine,
          disconnect,
          false,
        ),
      );
    }
  }
}

export default computerWebsocketUpdate;
