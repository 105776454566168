import styled from 'styled-components';

import { desktopMinSize, mqmin } from '@Styles/mixins';
import { mq } from '../../styles/mixins';

export const Title = styled.p`
  --fontSize: 24;

  font-size: calc(var(--fontSize) * 1rem / 16);
  text-align: center;

  ${mqmin(desktopMinSize)} {
    --fontSize: 32;
  }

  ${mq(desktopMinSize+200, desktopMinSize)}{
    --fontSize: 20;
  }

  &.align-center {
    text-align: center;
  }

  em {
    color: transparent;
    background: linear-gradient(90deg, #2d80f2 0%, #a00bf6 100%);
    -webkit-background-clip: text;
    background-clip: text;
  }

  abbr {
    text-decoration: none;
  }
`;

export default Title;
