import Table from './Table';
import { Text, Title } from '@Components/Public';
import * as S from './styled';

const PlanComparison = () => {
  return (
    <S.Container>
      <S.Header>
        <Title as="h2">
          <strong>Comparação dos nossos planos</strong>
        </Title>
      </S.Header>
      <S.TextContainer>
        <S.GroupContainer>
          <S.Header>
            <Title as="h2">
              <strong>Personal - Monte seu PC</strong>
            </Title>
          </S.Header>
          <S.Main>
            <Text>
              Horas avulsas, plano mensal! Combinando o melhor dos dois mundos, no Monte Seu PC as horas selecionadas
              são recarregadas todo mês de acordo com o plano contratado. Você pode carregar mais horas avulsas caso as
              horas do plano acabem. Também pode escolher as especificações que seu computador vai ter. Além disso, ele
              salva suas informações sempre que você desligar o computador.
              <strong>
                <em>
                  Especificações técnicas: As configurações são personalizáveis, mas o setup padrão é o mesmo do
                  Classic. 8vCPU, 32GB de RAM, GPU tesla T4 15GB com o armazenamento personalizado.
                </em>
              </strong>
            </Text>
          </S.Main>
        </S.GroupContainer>
        <S.GroupContainer>
          <S.Header>
            <Title as="h2">
              <strong>Horas avulsas</strong>
            </Title>
          </S.Header>
          <S.Main>
            <Text>
              O nosso plano de horas! Basta escolher um pacote que elas serão adicionadas na sua conta e você poderá
              utilizar o computador pelo tempo contratado, sem que ele desligue. Essas horas podem ser utilizadas como
              horas avulsas no Classic ou no plano Personal. Não salva suas informações entre as sessões!
              <strong>
                <em>
                  Especificações técnicas: 8 vCPU, 32GB RAM, GPU tesla T4 15GB, SSD ou HDD (tamanhos variados), ou com
                  um setup personalizado no plano Personal.
                </em>
              </strong>
            </Text>
          </S.Main>
        </S.GroupContainer>
      </S.TextContainer>
      <Table />
    </S.Container>
  );
};

export default PlanComparison;
