import { IconButton as MuiIconButton } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import React, { useCallback } from 'react';
import styled from 'styled-components';

const IconButton = ({ href }, props) => {
  const onClick = useScroll();

  return (
    <MuiIconButton aria-label="Como funciona" component="a" href={href} {...{ onClick }} {...props}>
      <KeyboardArrowDown />
    </MuiIconButton>
  );
};

function useScroll() {
  const onClick = useCallback(function (event) {
    event.preventDefault();

    const { top } = document.querySelector(event.currentTarget.hash).getBoundingClientRect();
    const { body } = document;

    body.scrollTo({ behavior: 'smooth', top: top + body.scrollTop - OFFSET });
  }, []);

  return onClick;
}

export const Scroll = styled(IconButton)`

  &.MuiIconButton-root {
    color: white !important;
    position: sticky !important;
    border: solid calc(2rem / 16) currentColor;
    padding: calc(4rem / 16);
  }
`;

export default Scroll;

const OFFSET = (80 * getBaseFontSize()) / 16;

function getBaseFontSize() {
  return Number(window.getComputedStyle(document.body).getPropertyValue('font-size').match(/\d+/)[0]);
}
