import MuiButton from '@material-ui/core/Button';
import styled from 'styled-components';
import React from 'react';

import { mqmax, mqmin, desktopMinSize } from '@Styles/mixins';

export const Submit = props => <Button type="submit" variant="outlined" {...props} />;

export default Submit;

const Button = styled(MuiButton)`
  &.MuiButton-root {
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
    border-color: #2d80f2;
    border-width: calc(3 / 16 * 1rem);
    border-radius: calc(24 / 16 * 1rem);
    color: #fff;
    font-weight: 600;
    background: #2d80f2;

    ${mqmin(desktopMinSize)} {
      grid-column-start: 1;
      grid-column-end: 3;
      transition: ${['background', 'border-color'].map(prop => `${prop} calc(1s / 3) ease-in-out`).join(',')};
      &:hover,
      &.active {
        background: #a00bf6;
        border-color: #a00bf6;
        color: #fff;
      }
    }

    ${mqmax(desktopMinSize - 1)} {
      padding: 5px;
      font-weight: 500;
    }
  }
`;
