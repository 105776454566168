import styled from 'styled-components';

import { desktopMinSize, mqmin } from '@Styles/mixins';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: calc(14rem / 16);

  ${mqmin(desktopMinSize)} {
    row-gap: calc(34rem / 16);
  }
`;

export default Container;
