import React from 'react';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import MuiCheckbox from '@material-ui/core/Checkbox';
import styled from 'styled-components';

import {
  desktopMinSize, 
  mqmax
} from '@Styles/mixins';

export const EmailSendApprovalCheckbox = props => (
  <FormControlLabelElement control={<CheckboxElement color='primary' {...props} />} label="Aceito receber notificações sobre minhas horas, atualizações e mudanças de termo de uso e promoções." />
);

export const VoucherCheckbox = props => (
  <FormControlVoucerLabelElement control={<CheckboxElement color='primary' {...props} />} label="Tenho um vale indicação!" />
);

export default VoucherCheckbox;

const CheckboxElement = styled(MuiCheckbox)`
  &.MuiCheckbox-root {
    color: #fff;
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const FormControlVoucerLabelElement = styled(MuiFormControlLabel)`
  &.MuiFormControlLabel-root {
    grid-column-start: 1;
    grid-column-end: 3;
    color: #fff;
    .MuiFormControlLabel-label {
      font-weight: 300;
      font-size: calc(14 / 16 * 1rem);
      ${mqmax(desktopMinSize - 1)} {
        font-size: calc(9 / 16 * 1rem);
        text-align: center;
      }
    }
  }
`;

const FormControlLabelElement = styled(MuiFormControlLabel)`
  &.MuiFormControlLabel-root {
    grid-column-start: 1;
    grid-column-end: 3;
    color: #fff;
    .MuiFormControlLabel-label {
      font-weight: 300;
      font-size: calc(11 / 16 * 1rem);
      ${mqmax(desktopMinSize - 1)} {
        font-size: calc(9 / 16 * 1rem);
        text-align: center;
      }
    }
  }
`;