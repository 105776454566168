import styled from 'styled-components';

import { desktopMinSize, mqmax, mqmin } from '@Styles/mixins';
import { mq } from '../../../../../styles/mixins';

export const Form = styled.form`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: ${({ forcedUpdate }) => forcedUpdate ? '1rem' : '2.5rem'};

  ${mqmax(desktopMinSize - 1)} {
    display: flex;
    flex-direction: column;
  }
`;

export const GroupWrapper = styled.div`
  width: 100%;
  height: auto;
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  flex-direction: column;
  gap: 1rem;

  & > p {
    color: #fff;
    font-size: calc(20 / 16 * 1rem);
    text-align: ${({ forcedUpdate }) => (forcedUpdate ? 'center' : 'left')};

    ${mq('1400', '1080')} {
      font-size: ${({ forcedUpdate }) => (forcedUpdate ? 'calc(16 / 16 * 1rem)' : 'calc(20 / 16 * 1rem)')};
    }
  }
`;

export const RowWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: ${({ forcedUpdate }) => (forcedUpdate ? 'space-between' : 'flex-start')};
  align-items: center;
  gap: 2rem;
  margin-bottom: ${({ forcedUpdate }) => (forcedUpdate ? '1rem' : '0')};

  .MuiSvgIcon-root {
    color: #fff;
    cursor: pointer;
  }

  ${mqmax(desktopMinSize - 1)} {
    flex-direction: ${({ isTitle }) => (isTitle ? 'row' : 'column')};
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 1rem;
  }
`;

export default Form;
