import React, { useState } from 'react';

import Container from './Container';
import Box from './Box';
import Header from './Header';
import Content from './Content';
import SuccessBox from '@Containers/SuccessBox';
import { REDIRECT_TO_LOGIN } from '@Constants';

export const CreatePasswordBox = () => {

  const [success, handleSuccess] = useState(false);

  function onChangeSuccess() {
    handleSuccess(true);
  }

  return (
    <Container>
      {success ? <SuccessBox label1={'SUA SENHA'} label2={'FOI ALTERADA'} label3={'COM SUCESSO'} redirect={REDIRECT_TO_LOGIN} /> : 
        <Box>
          <Header />
          <Content onSuccess={onChangeSuccess} />
        </Box>
      }
    </Container>
  )
}

export default CreatePasswordBox;
