import styled from 'styled-components';
import { desktopMinSize, mqmax, mqmin } from '../../../../styles/mixins';

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: calc(30 / 16 * 1rem);
  position: relative;
  
  ${mqmin(desktopMinSize)}{
    height: 80%;
    row-gap: calc(60 / 16 * 1rem);
    min-width: 363px;
  }

  ${mqmax(desktopMinSize -1)}{
    width: 100%;
  }
`;

export default Box;
