import React from 'react';
import styled from 'styled-components';
import { mqmax, desktopMinSize } from '@Styles/mixins';

export const Icon = props => <Span>{'<'}</Span>;

export default Icon;

const Span = styled.span`
  font-size: calc(36 / 16 * 1rem);
  color: #fff;
  opacity: 0.4;
  ${mqmax(desktopMinSize - 1)} {
    font-size: calc(30 / 16 * 1rem);
  }
`;
