import React from 'react';
import Text from './Text';
import Div from './Div';
import DesktopAccessDisabled from '@material-ui/icons/DesktopAccessDisabled';
import {
  desktopMinSize
} from '@Styles/mixins';
import Footer from './Footer';


export const Content = () => (
  <Div>
    <DesktopAccessDisabled style={window.innerWidth < desktopMinSize ? iconStyleMobile : iconStyleDesktop} />
    <Text>
      Estaremos em <strong>manutenção</strong> de <br /> 16/07/2024 até 22/07/2024<br /> para melhor atendê-los.
    </Text>
    <Footer />
  </Div>
);

export default Content;

const iconStyleMobile = {color: '#fff', width: '148.09px', height: '134.69px'};
const iconStyleDesktop = {color: '#fff', width: '214.85px', height: '195.41px'};