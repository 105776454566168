import React from 'react';
import styled from 'styled-components';

import { desktopMinSize, mqmin } from '@Styles/mixins';

import Header from './Header';
import Content from './Content';
import { mq } from '../../../styles/mixins';

const Container = props => (
  <section id="contato" {...props}>
    <Header />
    <Content />
  </section>
);

export const Contact = styled(Container)`
  display: grid;
  align-items: center;
  justify-content: center;
  row-gap: calc(42rem / 16);
  width: 100%;
  height: auto;
  max-width: min(calc(304 / 360 * 100%), calc(1331rem / 16));

  ${mqmin(desktopMinSize)} {
    grid-template-columns: calc(220rem / 16) calc(696rem / 16);
    column-gap: calc(164rem / 16);
  }

  ${mq(desktopMinSize + 200, desktopMinSize)} {
    column-gap: calc(80rem / 16);
  }
`;

export default Contact;
