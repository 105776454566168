import React from 'react';
import { AMISectionPrograms } from './styled';
//O trecho de código &#124; é uma forma semântica de escrever a barra | (reta vertical) na forma de html entities.

export const AMISectionProgramsContent = ({ programs }) => {
  return (
    <AMISectionPrograms>
      {programs?.map(program => (
        <span key={program.program_name}>&#124; {program.program_name}</span>
      ))}
    </AMISectionPrograms>
  );
};
