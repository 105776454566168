import styled from 'styled-components';

export const Container = styled.div`
    width: 91%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: calc(25 / 16 * 1rem);
    padding: 0 calc(50 / 1056 * 100%);
`;

export default Container;
