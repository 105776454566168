import styled from 'styled-components';
import { desktopMinSize, mq, mqmax, mqmin } from '../../../styles/mixins';

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: calc(6rem / 16);
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: calc(28rem / 16);

  & > p {
    text-align: center;
  }
`;

export const NichePresentationWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 3rem;

  ${mqmax(desktopMinSize - 1)} {
    flex-direction: column;
  }
`;

export const NichePresentation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 24%;
  min-height: 30rem;
  padding: 0rem 1.5rem;
  transition: all 0.2s ease-in-out;
  background: rgba(23, 23, 26, 0.7);
  border-radius: 1.875rem;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border: 2px solid #333;
    border-radius: 1.875rem;
    pointer-events: none;
    opacity: 0.5;
    background: #000;
    z-index: -1;
    //adicionar blur no background
    filter: blur(1px);
  }

  &:hover {
    transform: scale(1.05);
  }

  ${mq(1400, desktopMinSize)} {
    width: 23%;
    min-height: 25rem;
  }

  ${mqmin(1900)} {
    width: 30%;
    min-height: 40rem;
  }

  ${mqmax(desktopMinSize - 1)} {
    width: 75%;
    min-height: 26rem;
    padding: 2rem;

    &:hover {
      transform: scale(1);
    }
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const NichePresentationTitleInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const NichePresentationTitleTextInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

export const NicheIconsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

export const NichePresentationTitle = styled.h3`
  font-size: 1.5rem;
  color: #2d80f2;
  font-weight: 700;
  width: 100%;
  text-align: start;

  ${mq(1400, desktopMinSize)} {
    font-size: 1rem;
  }

  ${mqmin(1900)} {
    font-size: 2rem;
  }

  ${mqmax(desktopMinSize - 1)} {
    font-size: 1.2rem;
  }
`;

export const NichePresentationSubTitle = styled.h4`
  font-size: 1rem;
  width: 100%;
  line-height: 1.2;
  font-weight: 400;
  text-align: start;
  min-height: 3rem;

  ${mq(1400, desktopMinSize)} {
    font-size: 0.7rem;
    min-height: 2rem;
  }

  ${mqmin(1900)} {
    font-size: 1.4rem;
    min-height: 4rem;
  }

  ${mqmax(desktopMinSize - 1)} {
    font-size: 0.8rem;
    min-height: 3rem;
  }
`;

export const NicheImage = styled.img`
  width: 20%;
  height: auto;

  ${mq(1400, desktopMinSize)} {
    width: 15%;
  }

  ${mqmin(1900)} {
    width: 15%;
  }

  ${mqmax(desktopMinSize - 1)} {
    width: 15%;
  }
`;

export const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 0.5rem;
  & > p {
    width: 100%;
    text-align: start;
    font-size: 1rem;
    color: #2d80f2;

    ${mq(1400, desktopMinSize)} {
      font-size: 0.8rem;
    }

    ${mqmin(1900)} {
      font-size: 1.2rem;
    }

    ${mqmax(desktopMinSize - 1)} {
      font-size: 0.8rem;
    }
  }
`;

export const PriceContentWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 0.5rem;
`;

export const SignP = styled.p`
  font-size: 1.2rem;
  font-weight: 500;
  color: #2d80f2;

  ${mq(1400, desktopMinSize)} {
    font-size: 1rem;
  }

  ${mqmin(1900)} {
    font-size: 1.5rem;
  }

  ${mqmax(desktopMinSize - 1)} {
    font-size: 1rem;
  }
`;

export const PriceP = styled.p`
  font-size: 3.5rem;
  font-weight: 700;
  color: #fff;

  & > e {
    font-size: 1rem;
    font-weight: 500;

    ${mq(1400, desktopMinSize)} {
      font-size: 0.8rem;
    }

    ${mqmin(1900)} {
      font-size: 1.4rem;
    }

    ${mqmax(desktopMinSize - 1)} {
      font-size: 0.8rem;
    }
  }

  ${mq(1400, desktopMinSize)} {
    font-size: 3rem;
  }

  ${mqmin(1900)} {
    font-size: 5rem;
  }

  ${mqmax(desktopMinSize - 1)} {
    font-size: 3rem;
  }
`;

export const ChecklistWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  row-gap: 0.5rem;
  width: 100%;
  margin-top: 1rem;
`;

export const ChecklistItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 0.5rem;

  & > p {
    font-size: 0.8rem;
    font-weight: 400;
    color: #fff;
    width: 100%;
    text-align: start;

    ${mqmin(1900)} {
      font-size: 1.2rem;
    }
  }
`;

export const ChecklistItemImage = styled.img`
  height: auto;
  width: 7%;
`;
