import styled from 'styled-components';

import { rem, desktopMinSize } from '@Styles/mixins';
import { mqmin } from '../../styles/mixins';

export const Container = styled.div`

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: ${rem(60)} auto;
    color: #fff;
    visibility: ${({ isMobileCartOpen, step }) =>
    isMobileCartOpen || step === 4 || step === 5 || step === 6 || step === 7 ? 'visible' : 'hidden'};
    opacity: ${({ isMobileCartOpen, step }) =>
    isMobileCartOpen || step === 4 || step === 5 || step === 6 || step === 7 ? '1' : '0'};
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #21222a;
    padding: 26px;
    max-width: 78vw;
    width: 100%;
    max-height: 707px;
    border-top-left-radius: 22px;
    border-bottom-left-radius: 22px;
    transition: all 300ms ease-in-out;
    overflow-y: auto;
    z-index: ${({ step }) => (step === 2 || step === 4 || step === 5 || step === 6 || step === 7 ? '103' : '1')};
    pointer-events: ${({ step }) => step === 2 || step === 4 || step === 5 || step === 6 || step === 7 ? 'none' : 'unset'};

    ${mqmin(desktopMinSize)} {
      max-width: 20vw;
      top: 25px;
    }
    &::before {
      content: '';
      position: absolute;
      width: ${({ step }) => (step === 5 || step === 6 || step === 7 ? '100vw' : '0')};
      height: ${({ step }) => (step === 5 || step === 6 || step === 7 ? '100vh' : '0')};
      top: 0;
      right: 0;
      background: rgba(23, 23, 26, 0.7);
      z-index: ${({ step }) => (step === 5 || step === 6 || step === 7 ? '105' : '-1')};
    }

    & > div:nth-of-type(1) {
      z-index: ${({ step }) => (step === 5 || step === 6 ? '106' : '1')};
      position: relative;
    }

    & > div:nth-of-type(1) + div > button {
      z-index: ${({ step }) => (step === 7 ? '106' : '1')};
      position: relative;
    }

    h1 {
      font-size: ${rem(25)};
      font-weight: 600;
    }

    h2 {
      font-size: ${rem(12)};
      font-weight: 300;
      margin-top: 10px;
      margin-bottom: 23px;

      span {
        font-weight: 600;
      }
    }
`;

export const CartPageCardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #fff;
  padding: 0.75rem;
  max-width: 232px;
  border-radius: 20px;
  margin: 0 auto;
  min-height: 68px;
  width: 100%;
  z-index: 2;
  position: relative;

  & + & {
    margin-top: ${rem(10)};
  }
`;

export const PriceCartPageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
`;

export const LineThrough = styled.p`
  text-decoration: line-through;
`;

export const CartPageCardDataWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  strong {
    font-size: ${rem(16)};
    font-weight: 600;
  }

  span {
    font-size: ${rem(12)};
    font-weight: 300;
    display: block;
    margin-top: 5px;
  }

  p {
    font-size: ${rem(11)};
    font-weight: 300;
    margin-top: 14px;
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    button {
      border: 0;
      background-color: transparent;
      position: absolute;
      left: 216px;
      top: -10px;

      & > svg {
        fill: #56575d;
      }
    }
  }
`;
export const CartPageCardCounter = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  justify-content: center;
  margin-top: 49px;
  z-index: ${({ step }) => (step === 5 ? '106' : '1')};

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    background-color: #2d80f2;
    color: #fff;
    font-size: 12px;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  span {
    font-size: ${rem(18)};
    font-weight: 300;
  }
`;

export const CartPageDivider = styled.div`
  width: 96%;
  min-height: 1px;
  background: #fff;
  max-width: 373px;
  opacity: 0.5;
`;

export const CartFooterPriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;    
  align-items: stretch;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
`;

export const CartPagePriceGeneralInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 373px;
  strong {
    font-size: ${rem(15)};
    font-weight: 300;
  }
`;

export const CartPageTotalPrice = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 373px;
  strong {
    font-size: ${rem(20)};
  }
`;

export const CartContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const CartPageBuyButton = styled.button`
  border: 0;
  font-size: ${rem(12)};
  text-transform: uppercase;
  max-width: 275px;
  width: 100%;
  min-height: 37px;
  border-radius: 100vmax;
  outline: 2px solid #2d80f2;
  background-color: transparent;
  outline-offset: -2px;
  color: #2d80f2;
  font-weight: 600;
  transition: background-color 150ms;

  &:hover:not(:disabled) {
    background-color: #2d80f2;
    color: #fff;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const CartFooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  min-height: unset;
  gap: 1.5rem;

  & > button {
    z-index: ${({ step }) => (step === 7 ? '106' : '1')};
    position: relative;
  }
`;

export const GreenStrong = styled.strong`
  color: #4cd964;
  font-weight: 700;
`;

export const CouponInputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0;
  outline: 2px solid #2d80f2;
  max-width: 275px;
  width: 100%;
  min-height: 37px;
  border-radius: 100vmax;

  //disable everything if isCouponLoading is true
  ${({ isCouponLoading }) => isCouponLoading && `
    pointer-events: none;
    opacity: 0.5;
  `}
`;

export const CouponInput = styled.input`
  border: 0;
  background-color: transparent;
  color: #fff;
  font-size: ${rem(12)};
  font-weight: 300;
  width: 100%;
  outline: none;
  text-align: center;
`;

export const IconWrapper = styled.button`
  position: absolute;
  //colocar no canto direito
  right: 6%;
  border: 0;
  background-color: transparent;
  font-size: ${rem(12)};
  font-weight: 600;
  text-transform: uppercase;
  outline: none;
  cursor: pointer;
  width: 20%;

  ${mqmin(1440)} {
    width: 30%;
  }

  & .MuiSvgIcon-root {
    color: #fff;
    width: 1.2rem;
  }
`;

export const InfoIconWrapper = styled.button`
  position: absolute;
  border: 0;
  background-color: transparent;
  font-size: ${rem(12)};
  font-weight: 600;
  text-transform: uppercase;
  outline: none;
  cursor: pointer;
  width: 20%;

  & .MuiSvgIcon-root {
    ${({ infoIcon }) => infoIcon === 'valid'
    ? `color: #4cd964;`
    : `color: red;`
  }
    width: 1.2rem;
}
`;