import React from 'react';
import {
  AMITutorialSixthStepWrapper,
  AMITutorialSixthStepContentWrapper,
  AMITutorialSixthStepButtonWrapper,
  TutorialBackdrop,
} from '../styled';

export const SixthStep = ({ nextStep, skipTutorial }) => {
  return (
    <>
      <TutorialBackdrop />
      <AMITutorialSixthStepWrapper>
        <AMITutorialSixthStepContentWrapper>
          <strong>
            Para acessar o computador da <p>noar</p>, abra o Moolight, <span>copie o IP</span> que está aqui e adicione
            no programa clicando no <span>sinal de &#43; no canto superior direito.</span>
          </strong>

          <AMITutorialSixthStepButtonWrapper>
            <button onClick={nextStep}>Entendi</button>
            <button onClick={skipTutorial}>Fechar tutorial</button>
          </AMITutorialSixthStepButtonWrapper>
        </AMITutorialSixthStepContentWrapper>
      </AMITutorialSixthStepWrapper>
    </>
  );
};
