import React from 'react';
import styled from 'styled-components';
import { desktopMinSize, mqmax } from '@Styles/mixins';

import slideImg from '@Assets/4.webp';
import mobileSlideImg from '@Assets/4-mobile.webp';

export const FourthStep = () => {
  return (
    <>
      <div>
        {window.innerWidth < desktopMinSize ? <img src={mobileSlideImg} alt="" /> : <img src={slideImg} alt="" />}
      </div>
      <FourthStepDescription>
        <span>4.</span> Baixe o <span>Moonlight </span> em nossa página de download no menu lateral.
      </FourthStepDescription>
    </>
  );
};

const FourthStepDescription = styled.strong`
  text-align: left !important;
  font-size: 0.875rem !important;
  line-height: 1.5 !important;

  ${mqmax(desktopMinSize - 1)} {
    font-size: 0.75rem !important;
  }
`;
