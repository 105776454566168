import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import 'regenerator-runtime';
import 'es6-promise/auto';
import 'fetch-polyfill';
import { ShoppingCartProvider } from './context/ShoppingCartContext';
import { TutorialStepProvider } from './context/TutorialStepContext';
import store from '@Store';
import Private from '@Layouts/Private';
import Public from '@Layouts/Public';
import HomePage from '@Pages/HomePage';
import Plans from '@Pages/Plans';
import Download from '@Pages/Download';
import Contact from '@Pages/Contact';
import Faq from '@Pages/Faq';
import ForBusiness from '@Pages/ForBusiness';
import Blog from '@Pages/Blog';
import BlogSingle from '@Pages/BlogSingle/';
import LoginPage from '@Pages/LoginPage';
import RegisterPage from '@Pages/RegisterPage';
import RegisterConfirmationPage from '@Pages/RegisterConfirmationPage';
import ConfirmationSuccessPage from '@Pages/ConfirmationSuccessPage';
import OccasionalUsePage from '@Pages/OccasionalUsePage';
import MySessionsPage from '@Pages/MySessionsPage';
import ShopPage from '@Pages/ShopPage';
import PasswordForgot from '@Pages/PasswordForgot';
import CreatePassword from '@Pages/CreatePassword';
import SupportPage from '@Pages/SupportPage';
import MyProfilePage from '@Pages/MyProfilePage';
import DownloadPage from '@Pages/DownloadPage';
import MySettingsPage from '@Pages/SettingsPage';
import UpdatePasswordMobilePage from '@Pages/UpdatePasswordMobilePage';
import MaintenancePage from '@Pages/MaintenancePage';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { UserRoleProvider } from '@Context/UserRoleContext';
import MyComputerPage from './pages/MyComputerPage';
import PlanComparison from './pages/HomePage/Plans/PlanComparison';
import GoldImagesPanel from '@Pages/EnterpriseImagesGold';

function App() {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ShoppingCartProvider>
          <TutorialStepProvider>
            <BrowserRouter>
              <Switch>
                <Route exact path={publicPages.map(({ path }) => path)} component={PublicComponent} />
                <Route exact path="/blog" component={Blog} />
                <Route exact path="/blog/:post_id" component={BlogSingle} />
                <Route exact path="/login" component={LoginPage} />
                <Route exact path="/password/forgot" component={PasswordForgot} />
                <Route exact path="/password/create" component={CreatePassword} />
                <Route exact path="/register" component={RegisterPage} />
                <Route exact path="/register/confirmation" component={RegisterConfirmationPage} />
                <Route exact path="/register/confirmation/success" component={ConfirmationSuccessPage} />
                <Route exact path="/maintenance" component={MaintenancePage} />
                <UserRoleProvider>
                  <Private
                    pages={[
                      { path: '/my/home', component: MyComputerPage },
                      { path: '/use/occasional/unit', component: OccasionalUsePage },
                      { path: '/use/occasional/classic', component: OccasionalUsePage },
                      { path: '/shop/:tab', component: ShopPage },
                      { path: '/my/sessions', component: MySessionsPage },
                      { path: '/support', component: SupportPage },
                      { path: '/my/profile', component: MyProfilePage },
                      { path: '/my/download', component: DownloadPage },
                      { path: '/update/password', component: UpdatePasswordMobilePage },
                      { path: '/my/settings', component: MySettingsPage },
                      { path: '/admin/images', component: GoldImagesPanel },
                    ]}
                  />
                </UserRoleProvider>
              </Switch>
            </BrowserRouter>
          </TutorialStepProvider>
        </ShoppingCartProvider>
      </QueryClientProvider>
    </Provider>
  );
}

export default App;

const queryClient = new QueryClient();

function PublicComponent() {
  return <Public pages={publicPages} />;
}

const publicPages = [
  { path: '/', component: HomePage },
  { path: '/planos/comparacao', component: PlanComparison },
  { path: '/planos/:tab', component: Plans },
  { path: '/planos', component: Plans },
  { path: '/baixar', component: Download },
  { path: '/contato', component: Contact },
  { path: '/faq', component: Faq },
  { path: '/for-business', component: ForBusiness },
];
