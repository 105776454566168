import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: auto;
  row-gap: calc(10 / 16 * 1rem);
  display: flex;
  flex-direction: column;
  transform: ${({ forcedUpdate }) => (forcedUpdate ? 'scale(0.9)' : 'scale(1)')};
`;

export default Wrapper;
