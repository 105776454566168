import React from 'react';
import styled from 'styled-components';
import { desktopMinSize, mqmax } from '@Styles/mixins';

import { BsArrowRight } from 'react-icons/bs';

export const ThirdStep = () => {
  return (
    <ThirdStepWrapper>
      <strong>
        <span>3.</span> O computador leva uns
        <br /> <span>2 minutinhos para ligar.</span>
      </strong>
      <strong>
        Enquanto espera aproveita <br />
        para <span>baixar o Moonlight!</span>
      </strong>

      <p>
        Obs: O Moonlight é o programa que você vai
        <span> precisar para se conectar.</span>
      </p>

      <p>Aprenda como baixar!</p>

      {window.innerWidth < desktopMinSize ? (
        <BsArrowRight fill="#fff" size={18} />
      ) : (
        <BsArrowRight fill="#fff" size={24} />
      )}
    </ThirdStepWrapper>
  );
};

const ThirdStepWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 1.5rem;
  max-width: 355px;

  & > strong {
    text-align: left !important;
    font-size: 1.5rem !important;
    line-height: 1.5 !important;

    ${mqmax(desktopMinSize - 1)} {
      font-size: 0.938rem !important;
    }
  }

  & > p {
    color: #fff;
    font-weight: 300;
    line-height: 1.5;

    & > span {
      font-weight: 600;
    }

    ${mqmax(desktopMinSize - 1)} {
      font-size: 0.75rem !important;
    }
  }

  & > svg {
    margin: 0 auto;
  }

  ${mqmax(desktopMinSize - 1)} {
    max-width: 217px;
  }
`;
