import {
  CarrouselSectionContainer,
  BackToAMIs,
  AMISection,
  AMISectionContentWrapper,
  AMISectionContent,
  AMISectionContentFooter,
  AMISectionWarningContent,
} from '../styled';
import { ArrowBackIosSharp } from '@material-ui/icons';
import { AMISectionProgramsContent } from '../AMISectionPrograms';
import { SectionPageSteps } from '../../../AMITutorial/SectionPageSteps';
import { AiFillWarning } from 'react-icons/ai';
import { HowToUseAMI } from '../HowToUseAMI';

const OccasionalUseAmiSectionView = ({
  handleAMISectionPage,
  ami,
  state,
  onClickCreateComputer,
  modalRef,
  handleOpenModal
}) => {
  return (
      <CarrouselSectionContainer>
        <BackToAMIs type="button" onClick={handleAMISectionPage}>
          <ArrowBackIosSharp />
          <strong>Voltar</strong>
        </BackToAMIs>
        <h1>Sessão {ami?.image_name}</h1>
        <AMISection>
          <img src={ami?.image_photo} alt="" />
          <AMISectionContentWrapper>
            <SectionPageSteps />
            <AMISectionContent>
              <AMISectionContent step={state[1].step}>
                <strong>Programas pré instalados</strong>

                <AMISectionProgramsContent programs={ami?.programs} />
              </AMISectionContent>
            </AMISectionContent>
            <AMISectionContentFooter step={state[1].step}>
              <AMISectionWarningContent>
                <div>
                  <AiFillWarning style={{ width: '20px', height: '20px', color: '#426FE5' }} />
                </div>
                <span>
                  A NOAR não disponibiliza as licenças de programas pré-instalados pagos, para utiliza-los,{' '}
                  <strong>você precisa possuir as licenças.</strong>
                </span>
              </AMISectionWarningContent>
              <button type="button" onClick={onClickCreateComputer}>
                iniciar computador
              </button>
              <HowToUseAMI modalRef={modalRef} />

              <strong>
                Não sabe como conectar seu dispositivo na nuvem?
                <a role="button" onClick={handleOpenModal}>
                  Descubra
                </a>
              </strong>
            </AMISectionContentFooter>
          </AMISectionContentWrapper>
        </AMISection>
      </CarrouselSectionContainer>
  );
};

export default OccasionalUseAmiSectionView;