import React from 'react';
import styled from 'styled-components';
import { desktopMinSize, mqmax } from '@Styles/mixins';

export const FinalStep = ({ setCurrentStepIndex, onRequestClose }) => {
  function handleOpenHelpChat() {
    if (window.zE) {
      window.zE('messenger', 'open');
      setCurrentStepIndex(0)
      onRequestClose();
    }
  }

  return (
    <FinalStepWrapper>
      <strong>
        <span>9.</span> Pronto, <span>seu acesso está liberado</span> e agora poderá utilizar os nossos computadores
        livremente &#128522;
      </strong>
      <p>
        Ficou alguma dúvida? Entre em contato conosco através do Discord no link{' '}
        <a href="https://discord.com/invite/noar-798022821342609418" rel="noopener noreferrer" target="_blank">
          www.discord.gg/noar
        </a>
      </p>

      <GetItButton type="button" onClick={onRequestClose}>
        consegui!
      </GetItButton>
      <SeeItAgainButton type="button" onClick={() => setCurrentStepIndex(0)}>
        ver de novo
      </SeeItAgainButton>
      <NeedHelpButton type="button" onClick={handleOpenHelpChat}>
        Preciso de ajuda
      </NeedHelpButton>
    </FinalStepWrapper>
  );
};

const FinalStepWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 1rem;
  max-width: 377px;
  z-index: 999;

  & > strong {
    text-align: left !important;
    font-size: 1.35rem !important;
    line-height: 1.5 !important;

    ${mqmax(desktopMinSize - 1)} {
      font-size: 0.938rem !important;
    }
  }

  & > p {
    color: #fff;
    font-weight: 300;
    line-height: 1.5;

    & > a {
      color: #2d80f2;
      font-weight: 600;
      text-decoration: none;
    }

    ${mqmax(desktopMinSize - 1)} {
      font-size: 0.75rem !important;
    }
  }

  & > svg {
    margin: 0 auto;
  }
`;

const ButtonStyle = styled.button`
  max-width: 196px;
  width: 100%;
  height: 50px;
  border-radius: 37px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0 auto;

  ${mqmax(desktopMinSize - 1)} {
    max-width: 221px;
    height: 38px;
    font-size: 12px;
  }
`;

const GetItButton = styled(ButtonStyle)`
  background-color: #2d80f2;
  color: #fff;
  transition: background-color 350ms;

  &:hover {
    background-color: #a00bf6;
  }
`;

const SeeItAgainButton = styled(ButtonStyle)`
  color: #2d80f2;
  border: 3px solid #2d80f2;
  background: transparent;
  transition: border-color 350ms, color 350ms;

  &:hover {
    color: #a00bf6;
    border-color: #a00bf6;
  }

  ${mqmax(desktopMinSize - 1)} {
    border-width: 2px;
  }
`;

const NeedHelpButton = styled.button`
  margin: 0 auto;
  background: transparent;
  text-decoration: underline;
  color: #fff;
  font-size: 12px;
  font-weight: 300;
  transition: color 350ms;

  &:hover {
    color: #a00bf6;
  }

  ${mqmax(desktopMinSize - 1)} {
    font-size: 9px;
  }
`;
