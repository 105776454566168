import Typography from '@material-ui/core/Typography';
import React from 'react';
import styled from 'styled-components';
import {
  defaultFont,
  desktopMinSize, 
  mqmax
} from '@Styles/mixins';

export const ProfileTitle = props => <H2 variant="h2" {...props} />;

export default ProfileTitle;

const H2 = styled(Typography)`
  &.MuiTypography-h2 {
    margin: 0;
    color: #fff;
    font-size: calc(47 / 16 * 1rem);
    font-weight: 600;
    line-height: calc(52 / 48);
    font-family: ${defaultFont};
    ${mqmax(desktopMinSize - 1)} {
      font-size: calc(25 / 16 * 1rem);
    }
  }
`;
