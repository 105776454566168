import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';
import { desktopMinSize, mqmax } from '../../../../styles/mixins';

export const AnalyticsCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 2rem;
  border-radius: 16px;
  border: 1px solid #FFFFFF;
  width: 23rem;
  height: 4rem;

  ${mqmax(desktopMinSize - 1)} {
    width: 85%;
    height: 2rem;
  }
`;

export const CircularProgressStyled = styled(CircularProgress)`
  width: 3.4rem !important;
  &.MuiCircularProgress-root {
    color: #2780ED;
  }
`;

export const CircularProgressWrapper = styled.div`
  position: relative;
`;

export const CircularProgressText = styled.p`
  position: absolute;
  top: 50%;
  left: 65%;
  transform: translate(-50%, -50%);
  font-weight: 200;
  font-size: 1rem;
`;

export const AnalyticsInfoCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;   
  gap: 1rem;
`;

export const Title = styled.h3`
  font-size: 1rem;
  font-weight: 200;
`;

export const AnalyticsInfoCardText = styled.p`
  font-size: 1rem;
  font-weight: 200;
  & > strong {
    font-size: 2rem !important;
    font-weight: 700 !important;
    margin-bottom: 0 !important;
    display: initial !important;
    ${mqmax(desktopMinSize - 1)} {
      font-size: 1.5rem !important;
      font-weight: 700 !important;
    }
  }

`;

export const AnalyticsInfoCardPlanNameText = styled.p`
  font-size: 1.75rem;
  font-weight: 700;
  ${mqmax(desktopMinSize - 1)} {
    font-size: 1.25rem;
  }
`;

export const AnalyticsInfoCardContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;
