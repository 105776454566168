import React from 'react';
import styled from 'styled-components';
import {
    defaultFont
  } from '@Styles/mixins';
import { TERMS_PRIVACY_URL, TERMS_USE_URL } from '@Constants';

export const Footer = () =>
    <Span>*Ao fazer o cadastro você concorda com as {' '}
        <a href={TERMS_PRIVACY_URL} target="_blank" rel="noreferrer">Políticas de Privacidade</a> {' '} e {' '}
        <a href={TERMS_USE_URL} target="_blank" rel="noreferrer">Termos de Serviço</a>.
    </Span>;

export default Footer;

const Span = styled.span`
  grid-column-start: 1;
  grid-column-end: 3;
  width: 100%;
  font-family: ${defaultFont};
  font-weight: 200;
  font-style: italic;
  color: #fff;
  text-align: center;
  line-height: 20px;
  font-size: calc(11 / 16 * 1rem);
  padding-bottom: 2.5rem;
  a {
    color: #fff;
    margin-left: 5px;
  }
`;
