import { createTheme, ThemeProvider } from '@material-ui/core';
import styled from 'styled-components';

export const Body = props => (
  <ThemeProvider theme={theme}>
    <Div {...props} />
  </ThemeProvider>
);

export default Body;

const theme = createTheme({
  typography: {
    fontFamily: '"Poppins", sans-serif',
  },
});

const Div = styled.div`
  background: #17171a;
  height: 100%;
`;
