import React from 'react';
import styled from 'styled-components';
import MuiButton from '@material-ui/core/Button';
import { mqmax, desktopMinSize } from '@Styles/mixins';

export const Container = props => 
    <Button {...props} />

export default Container;

const Button = styled(MuiButton)`
  &.MuiButton-root {
    padding: 0;
    min-width: auto;
    height: calc(30 / 16 * 1rem);
    ${mqmax(desktopMinSize - 1)} {
      height: auto;
    }
  }
`;