import React from 'react';
import { AMITutorialContainer, AMITutorialWrapper, AMITutorialButtonWrapper, TutorialBackdrop } from '../styled';

export const IntroAMITutorial = ({ nextStep, skipTutorial }) => {
  return (
    <>
      <TutorialBackdrop />
      <AMITutorialContainer>
        <AMITutorialWrapper>
          <strong>
            Bem vindo &#40;a&#41;,
            <br />
            você está na <span>noar&#33;</span>
          </strong>
          <p>
            Está pronto para <span>melhor</span> experiência na nuvem?
          </p>
          <p>
            Vamos aprender, em alguns passos, como a <span>noar</span> funciona.
          </p>
          <AMITutorialButtonWrapper>
            <button onClick={nextStep}>Começar</button>
            <button onClick={skipTutorial}>Pular tutorial</button>
          </AMITutorialButtonWrapper>
        </AMITutorialWrapper>
      </AMITutorialContainer>
    </>
  );
};
