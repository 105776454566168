import { useCallback, useEffect, useRef, useState } from "react";

export const useRefProp = function ({ eventNames = [], initialValue, prop }) {
  const [value, setValue] = useState(initialValue);
  const ref = useRef(null);

  const handleEvents = useCallback(
    function () {
      setValue(ref.current[prop]);
    },
    [prop, ref, setValue],
  );

  useEffect(
    function () {
      handleEvents();

      eventNames.forEach(function (eventName) {
        window.addEventListener(eventName, handleEvents);
      });

      return function () {
        eventNames.forEach(function (eventName) {
          window.removeEventListener(eventName, handleEvents);
        });
      };
    },
    [eventNames, handleEvents],
  );

  return { value, ref };
};

export const useHeight = function () {
  const { value: height, ref } = useRefProp({
    eventNames: ['resize'],
    initialValue: 0,
    prop: 'clientHeight',
  });

  return { height, ref };
};
