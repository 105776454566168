import styled from 'styled-components';

export const LoadWrapper = styled.div`
  border-radius: 5px;
  text-align: center;
  min-height: ${({ whichLoading }) => (whichLoading === 'generic' ? '449px' : '283px')};
  position: ${({ whichLoading }) => (whichLoading === 'overflow' ? 'absolute' : 'relative')};
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  z-index: 99999;

  p {
    padding: 0 0 20px;
  }
  &:last-child {
    margin-right: 0;
  }
`;
export const LoadingGif = styled.img`
  width: 200px;
  display: block;
  margin: 0 auto;
`;
