import styled from "styled-components";
import { desktopMinSize, mqmin } from "../../../styles/mixins";

export const Hr = styled.div`
  width: calc(246rem / 20);
  height: calc(1rem / 16);
  background: #fff;
  opacity: 20%;
    margin: calc(20rem / 16) auto;

  ${mqmin(desktopMinSize)} {
    width: calc(246rem / 16);
  }
`;

export const List = styled.ul`
  display: grid;
  align-content: center;
  justify-items: center;    
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  row-gap: calc(20rem / 16);

  ${mqmin(desktopMinSize)} {
    min-height: calc(150rem / 16);
  }
`;

export const ListItem = styled.li`
  font-size: 14px;
  font-weight: 200;
  line-height: calc(var(--lineHeight) / var(--fontSize));
  text-align: center;

  &::before {
    content: '• ';
  }

  &:not(:last-child) {
    &::after {
      content: ';';
    }
  }

  &:last-child {
    &::after {
      content: '.';
    }
  }
`;


export const Text = styled.span`
  display: block;
  color: #5a5a60;
  font-size: calc(20rem / 24);
  font-weight: 200;
  line-height: calc(31 / 20);
  text-align: center;

  ${mqmin(desktopMinSize)} {
    font-size: calc(20rem / 16);
  }
`;

export const Value = styled.span`
  display: block;
  color: #fff;
  font-size: calc(64rem / 24);
  font-weight: bold;
  line-height: calc(76 / 64);
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
`;

export const Buttons = styled.div`
  display: grid;
  justify-items: center;
  row-gap: calc(7rem / 16);
`;

export const Title = styled.h2`
    color: #fff;
    font-size: calc(28rem / 16);
    font-weight: 600;
    line-height: calc(48 / 32);
    text-align: center;
`;

export const Div = styled.div`
    display: grid;
    flex-direction: row;
    justify-items: center;
    margin-top: 30px;
    margin-bottom: 30px;
`;

export const P = styled.p`
    margin-bottom: calc(20rem / 16);;
`;