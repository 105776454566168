import * as S from './styled';
import { Title, Text } from '@Components/Public';
import select from '../HowItWorks/icons/select.png';
import lendoumlivro from '../HowItWorks/icons/lendoumlivro.png';
import alunograduacao from '../HowItWorks/icons/alunograduacao.png';
import computacaoemnuvem from '../HowItWorks/icons/computacaoemnuvem.png';
import { Button } from '../../../components/Public';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import PlanForm from './PlanForm';
import { useState } from 'react';

const Plans = () => {
  const [showPlanForm, setShowPlanForm] = useState(false);
  const history = useHistory();

  function handleShowPlanForm() {
    setShowPlanForm(!showPlanForm);
  }

  return (
    <S.PlansWrapper id="planos">
      {showPlanForm ? <PlanForm handleShowPlanForm={handleShowPlanForm} /> : []}
      <S.Main>
        <Title as="h2">
          <strong>Nossos Planos</strong>
        </Title>
        <Text>
          A Noar trabalha com diferentes formas de contratação para garantir um{' '}
          <strong>
            <em>bom custo/benefício</em>
          </strong>{' '}
          para o seu perfil de uso.
          <br />
          Hoje temos 3 opções que você mesmo pode escolher ou nós podemos te ajudar, basta selecionar uma das opções
          abaixo.
        </Text>
      </S.Main>
      <S.ChooseWrapper>
        <S.NichePresentation>
          <S.HeaderWrapper>
            <S.NicheIconsWrapper>
              <S.NicheImage src={lendoumlivro} alt="Pessoa lendo um livro." />
            </S.NicheIconsWrapper>
            <S.NichePresentationTitle>
              <strong>Aprendiz</strong>
            </S.NichePresentationTitle>
          </S.HeaderWrapper>
          <S.ChecklistWrapper>
            <p>Se você:</p>
            <S.ChecklistItem>
              <S.ChecklistItemImage src={select} alt="Select marcado." />
              <p>Quer ajuda para descobrir o melhor plano.</p>
            </S.ChecklistItem>
            <S.ChecklistItem>
              <S.ChecklistItemImage src={select} alt="Select marcado." />
              <p>Ainda não entende 100% sobre cloud.</p>
            </S.ChecklistItem>
            <S.ChecklistItem>
              <S.ChecklistItemImage src={select} alt="Select marcado." />
              <p>Quer o melhor!</p>
            </S.ChecklistItem>
          </S.ChecklistWrapper>
          <S.NichePresentationSubTitle>
            <p>
              Vamos fazer algumas perguntas para traçar o seu perfil e te ajudar a escolher o plano que mais se encaixa
              com você.
            </p>
          </S.NichePresentationSubTitle>
          <Button variant="contained" home_modal={true} onClick={handleShowPlanForm}>
            Quero ajuda
          </Button>
        </S.NichePresentation>
        <S.NichePresentation>
          <S.HeaderWrapper>
            <S.NicheIconsWrapper>
              <S.NicheImage src={alunograduacao} alt="Aluno graduado." />
            </S.NicheIconsWrapper>
            <S.NichePresentationTitle>
              <strong>Veterano</strong>
            </S.NichePresentationTitle>
          </S.HeaderWrapper>
          <S.ChecklistWrapper>
            <p>Se você:</p>
            <S.ChecklistItem>
              <S.ChecklistItemImage src={select} alt="Select marcado." />
              <p>Já entende sobre computação na núvem.</p>
            </S.ChecklistItem>
            <S.ChecklistItem>
              <S.ChecklistItemImage src={select} alt="Select marcado." />
              <p>Quer se aprofundar nos nossos planos.</p>
            </S.ChecklistItem>
            <S.ChecklistItem>
              <S.ChecklistItemImage src={select} alt="Select marcado." />
              <p>Está em dúvida sobre qual plano contratar.</p>
            </S.ChecklistItem>
          </S.ChecklistWrapper>
          <S.NichePresentationSubTitle>
            <p>Escolha essa opção para ver todos os planos e suas principais características.</p>
          </S.NichePresentationSubTitle>
          <Button
            variant="contained"
            home_modal={true}
            onClick={() => {
              history.push('/planos/comparacao');
            }}
          >
            Ver comparação
          </Button>
        </S.NichePresentation>
        <S.NichePresentation>
          <S.HeaderWrapper>
            <S.NicheIconsWrapper>
              <S.NicheImage src={computacaoemnuvem} alt="Uma Núvem." />
            </S.NicheIconsWrapper>
            <S.NichePresentationTitle>
              <strong>Especialista</strong>
            </S.NichePresentationTitle>
          </S.HeaderWrapper>
          <S.ChecklistWrapper>
            <p>Se você:</p>
            <S.ChecklistItem>
              <S.ChecklistItemImage src={select} alt="Select marcado." />
              <p>Já é PRO em computação na núvem.</p>
            </S.ChecklistItem>
            <S.ChecklistItem>
              <S.ChecklistItemImage src={select} alt="Select marcado." />
              <p>Já entendeu sobe nossos planos.</p>
            </S.ChecklistItem>
            <S.ChecklistItem>
              <S.ChecklistItemImage src={select} alt="Select marcado." />
              <p>Sabe exatamente o que quer.</p>
            </S.ChecklistItem>
          </S.ChecklistWrapper>
          <S.NichePresentationSubTitle>
            <p>Escolha o plano que mais se encaixa no seu perfil.</p>
          </S.NichePresentationSubTitle>
          <Button
            variant="contained"
            home_modal={true}
            onClick={() => {
              history.push('/planos');
            }}
          >
            Escolher plano
          </Button>
        </S.NichePresentation>
      </S.ChooseWrapper>
    </S.PlansWrapper>
  );
};

export default Plans;
