import styled from 'styled-components';
import { desktopMinSize, mqmax } from '@Styles/mixins';

export const PaymentAlertWrapper = styled.dialog`
  max-width: 351px;
  width: 100%;
  height: ${({ variant }) => (variant === 'pending' || variant === 'denied' ? '212px' : '180px')};
  border: 0;
  border-radius: 45px;
  background-color: #21222a;
  box-shadow: 0 0 0 100vw rgba(0, 0, 0, 0.6);

  &[open] {
    animation: slide-up 350ms forwards, fade-in 350ms forwards;
  }

  &[closing] {
    display: block;
    pointer-events: none;
    inset: 0;
    animation: slide-down 350ms forwards, fade-out 350ms forwards;
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 45px;
    padding: 3px;
    background: linear-gradient(114deg, #307aea 0%, #9414ee 100%);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: ${({ variant }) => (variant === 'pending' || variant === 'denied' ? '212px' : '180px')};
    text-align: center;

    h2 {
      color: #fff;
      font-weight: 700;
      font-size: 1.375rem;
    }

    h3 {
      color: #fff;
      font-size: 0.938rem;
      font-weight: 300;
      margin-top: 6px;
      line-height: 20px;

      b {
        font-weight: 700;
      }
    }

    button {
      max-width: 170px;
      width: 100%;
      height: 50px;
      text-transform: uppercase;
      color: #2d80f2;
      border: 3px solid #2d80f2;
      border-radius: 37px;
      transition: 350ms color, 350ms background-color;
      background: transparent;
      margin-top: 32px;
      z-index: 4;

      &:hover {
        color: #fff;
        background-color: #2d80f2;
      }
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    max-width: 304px;
    height: ${({ variant }) => (variant === 'pending' || variant === 'denied' ? '212px' : '141px')};
    border-radius: 30px;

    &::before {
      background: linear-gradient(117deg, #307aea 0%, #9414ee 100%);
      border-radius: 30px;
    }

    h2 {
      font-size: 1.313rem;
    }

    button {
      max-width: 127px;
      height: 38px;
      color: #fff;
      background-color: #2d80f2;
    }
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes slide-up {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0%);
    }
  }

  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes slide-down {
    0% {
      transform: translateY(0%);
    }
    100% {
      transform: translateY(100%);
    }
  }
`;
