import Typography from '@material-ui/core/Typography';
import React from 'react';
import styled from 'styled-components';
import { mqmin, desktopMinSize } from '@Styles/mixins';

export const CreatePasswordTitle = props => <H2 variant="h2" {...props} />;

export default CreatePasswordTitle;

const H2 = styled(Typography)`
  &.MuiTypography-h2 {
    margin: 0;
    color: #fff;
    font-size: calc(55 / 16 * 1rem);
    font-weight: 700;
    line-height: calc(52 / 48);
    text-align: center;

    ${mqmin(desktopMinSize + 403)} {
      font-size: calc(45 / 16 * 1rem);
    }
  }
`;
