import React from 'react';
import styled from 'styled-components';

import { desktopMinSize, mqmin } from '@Styles/mixins';

import Content from './Content';
import Scroll from './Scroll';
import Title from './Title';
import { mq } from '../../../styles/mixins';
import { Subtitle } from '../../../components/Public';
import { TextButton } from '../../../layouts/Public/Header/styled';

const Container = props => {
  function getBaseFontSize() {
    return Number(window.getComputedStyle(document.body).getPropertyValue('font-size').match(/\d+/)[0]);
  }

  const OFFSET = (80* getBaseFontSize()) / 16;

  function goToHowItWorks() {
    const { top } = document.querySelector('#como-funciona').getBoundingClientRect();
    const { body } = document;
    body.scrollTo({ behavior: 'smooth', top: top + body.scrollTop - OFFSET });
  }

  return (
    <div {...props}>
      <InfoWrapper>
        <Title />
        <Content />
      </InfoWrapper>
      <Subtitle>
        <TextButton onClick={goToHowItWorks}>
          <strong>A Noar é pra mim?</strong>
        </TextButton>
      </Subtitle>
      <Scroll href="#como-funciona" />
    </div>
  );
};

export const Intro = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > .MuiIconButton-root {
    color: #ffffff;
    animation: scroll 2s ease-in-out infinite;
    @keyframes scroll {
      0% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(0.5rem);
      }
      100% {
        transform: translateY(0);
      }
    }
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: calc(32rem / 16);
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  position: relative;
  color: #ffffff;

  ${mqmin(desktopMinSize)} {
    flex-direction: row-reverse;
    column-gap: calc(97 / 1331 * 100%);
  }

  ${Title},
  ${Content} {
    ${mqmin(desktopMinSize)} {
      grid-row: 1 / 2;
    }
  }

  ${Title} {
    ${mqmin(desktopMinSize)} {
      grid-column: 2 / 3;
    }
  }

  ${Content} {
    ${mqmin(desktopMinSize)} {
      grid-column: 1 / 2;
    }
  }
`;

export default Intro;
