import MuiButton from '@material-ui/core/Button';
import React from 'react';
import styled from 'styled-components';
import { mqmax, desktopMinSize } from '@Styles/mixins';

export const Copy = () => (
  <Button variant="outlined">
    COPIAR CÓDIGO
  </Button>
);

export default Copy;

const Button = styled(MuiButton)`
  &.MuiButton-root {
    box-sizing: border-box;
    width: calc(170 / 16 * 1rem);
    border-color: #fff;
    border-width: calc(3 / 16 * 1rem);
    border-radius: calc(32 / 16 * 1rem);
    padding: 10px;
    color: #fff;
    font-weight: 600;
    background: transparent;
    transition: ${['color', 'background'].map(prop => `${prop} calc(1s / 3) ease-in-out`).join(',')};

    &:hover,
    &.active {
      color: #426FE5;
      background: #fff;
    }

    ${mqmax(desktopMinSize - 1)} {
      padding: 6px;
      font-size: calc(12 / 16 * 1rem);
      border-width: calc(2 / 16 * 1rem);
    }
  }
`;
