import Typography from '@material-ui/core/Typography';
import React from 'react';
import styled from 'styled-components';
import {
  defaultFont,
  desktopMinSize, 
  mqmax
} from '@Styles/mixins';

export const DataAccountSubtitle = props => <H3 variant="h3" {...props} />;

export default DataAccountSubtitle;

const H3 = styled(Typography)`
  &.MuiTypography-h3 {
    margin: 0;
    color: #fff;
    font-size: calc(20 / 16 * 1rem);
    font-weight: 600;
    line-height: calc(32 / 24);
    text-align: center;
    font-family: ${defaultFont};
    align-self: flex-start;
    ${mqmax(desktopMinSize - 1)} {
      font-size: calc(16 / 16 * 1rem);
    }
  }
`;
