import styled from 'styled-components';

import {
  overlayZIndex,
  overlayOpacity,
  desktopMinSize,
  transform,
  displayRow,
  displayColumn,
  mqmin,
  mqmax
} from '@Styles/mixins';

export const NpsFormBox = styled.div`
  display: none;
  z-index: ${overlayZIndex + 1};
  position: fixed;
  top: 0;
  left: 0;

  &.is--open {
    display: block;
  }

  .nps-form-box {
    &__overlay {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, ${overlayOpacity});
      z-index: 1;
    }

    &__wrapper {
      width: 100%;
      height: auto;
      border: double 3px transparent;
      border-radius: 45px;
      background-image: linear-gradient(#21222a, #21222a), radial-gradient(circle at top left, #426FE5,#A00BF6);
      background-origin: border-box;
      background-clip: content-box, border-box;
      position: fixed;
      top: 50%;
      left: 50%;
      ${transform('translate(-50%, -50%)')}
      z-index: 2;
      ${mqmin(desktopMinSize)} {
        max-width: 863px;
        max-height: 100%;
      }
      ${mqmax(desktopMinSize - 1)} {
        border-radius: 35px;
        max-width: 337px;

        &.form-0{
          min-height: 320px;
        }
        &.form-1{
          min-height: 320px;
        }
      }
    }

    &__box {
      width: 90%;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: calc(55 / 1056 * 100%) calc(53 / 1056 * 100%);
      align-items: start;
      row-gap: calc(20 / 16 * 1rem);
      ${displayColumn()}
      ${mqmax(desktopMinSize - 1)} {
        padding: calc(112 / 1056 * 100%) calc(53 / 1056 * 100%);
      }
    }

    &__header {
      display: flex;
      justify-content: center;
      align-items: center;
      ${displayRow()}
      width: 100%;
      height: auto;
      color: #fff;
      h1 {
        display: flex;
        flex-direction: row;
        font-weight: 700;
        font-family: "Poppins", sans-serif;
        text-align: center;
        font-size: calc(21 / 16 * 1rem);
        &.end-title {
          display: none;
        }
        ${mqmax(desktopMinSize - 1)} {
          font-size: calc(18 / 16 * 1rem);
          flex-direction: column;
          &.main-title {
            display: flex;
            &.form-2 {
              display: none;
            }
          }
          &.end-title {
            display: none;
            &.form-2 {
              display: flex;
            }
          }
        }
      }
    }


    &__content {
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: calc(25 / 16 * 1rem);

      .questions {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: calc(12 / 16 * 1rem);
        p.mobile{
          display: none;
        }
        ${mqmax(desktopMinSize - 1)} {
          p.mobile {
            display: block;
          }
          p.desktop {
            display: none;
          }
          &.questions-justification {
            display: none;
            &.form-1 {
              margin-top: 7%;
              display: flex;
            }
          }
          &.questions-score {
            display: none;
            &.form-0 {
              display: flex;
            }
          }
        }

        .content-score {
          display: flex;
          width: 100%;
          flex-direction: column;
          row-gap: calc(5 / 16 * 1rem);

          .description-desktop {
            ${mqmax(desktopMinSize - 1)} {
              display: none;
            }
            width: 100%;
            display: flex;
            justify-content: space-between;
            p {
              color: #909195;
              font-size: calc(15 / 16 * 1rem);
              font-family: "Poppins", sans-serif;
            }
          }

          .description-mobile {
            ${mqmin(desktopMinSize)} {
              display: none;
            }

            width: 100%;
            display: flex;

            &.first {
              margin-top: 10px;
              justify-content: flex-start;
            }

            &.second {
              justify-content: flex-end;
            }

            p {
              color: #909195;
              font-size: calc(12 / 16 * 1rem);
            }
          }

          .scores {
            width: 100%;
            display: flex;
            justify-content: space-between;

            ${mqmax(desktopMinSize - 1)} {
              flex-wrap: wrap;
              column-gap: 10px;
              align-items: center;
              justify-content: center;
              row-gap: 10px;
            }

            .score {
              width: calc(25 / 16 * 1rem);
              padding: 15px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: calc(30 / 16 * 1rem);
              border: 1px solid #47474E;
              cursor: pointer;
              background-color: #47474E;
              ${mqmax(desktopMinSize - 1)} {
                width: calc(16 / 16 * 1rem);
                padding: 12px;
              }
              span {
                color: #fff;
                font-size: calc(21 / 16 * 1rem);
                font-weight: 600;
                font-family: "Poppins", sans-serif;
                ${mqmax(desktopMinSize - 1)} {
                  font-size: calc(16 / 16 * 1rem);
                  font-weight: 500;
                }

              }
              
              :hover,
              &.active {
                background-color: #426FE5;
                span {
                  color: #ffffff;
                }
              }
            }

          }
        }

        textarea {
          color: #fff;
          resize: none;
          border: 1px solid #fff;
          border-radius: 13px;
          padding: 10px;
          font-size: calc(14 / 16 * 1rem);
          background: transparent;
          ${mqmax(desktopMinSize - 1)} {  
            max-height: calc(16 / 16 * 1rem);
            font-size: calc(12 / 16 * 1rem);
            background-color: #47474E;
            border-color: transparent;
            border-radius: 30px;
            padding: 13px;
            outline: none;
          }
        }

      }

    }

    &__text {
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      font-style: normal;
      color: #fff;
      text-align: center;
      line-height: 20px;
      ${mqmin(desktopMinSize)} {
        font-size: calc(18 / 16 * 1rem);
      }
      ${mqmax(desktopMinSize - 1)} {
        font-size: calc(14 / 16 * 1rem);
      }
      span {
        font-weight: 700;
        font-size: 1.5rem;
        text-transform: uppercase;
        color: #426FE5;
      }
    }

    &__error-message {
      font-family: "Poppins", sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: 14px;
      color: #ff0000;
      text-decoration: none;
      text-align: center;
      letter-spacing: 0;
      line-height: 20px;
      ${mqmin(desktopMinSize)} {
        font-size: 16px;
        line-height: 22px;
      }

      &:not(:empty) {
        margin: 0 0 5px;
      }
    }

    &__footer {
      width: 100%;
      display: flex;
      justify-content: center;
      justify-content: center;
    }
  }

  #nps-form-box__btn-close {
    width: 34px;
    height: 34px;
    border: none;
    border-radius: 0;
    font-size: 0;
    background: url('https://siteimagesbucket.s3.amazonaws.com/website/private-pages/computer/close.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    cursor: pointer;
    margin: 5px;
    position: absolute;
    top: 5%;
    right: 3%;
  }

  #nps-form-box__btn-back {
    width: 35px;
    height: 20px;
    border: none;
    border-radius: 0;   
    font-size: 0;
    background: url('https://siteimagesbucket.s3.amazonaws.com/website/private-pages/computer/left.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    cursor: pointer;
    margin: 5px;
    position: absolute;
    top: 7%;
    left: 3%;
    
    &__toggle {
      display: none;
    }

  }

  #nps-form-box__btn-confirm,
  #nps-form-box__btn-continue,
  #nps-form-box__btn-done {
    padding: 15px;
    box-sizing: border-box;
    width: calc(160 / 16 * 1rem);
    border: calc(3 / 16 * 1rem) solid #2d80f2;
    border-radius: calc(30 / 16 * 1rem);
    color: #2d80f2;
    font-weight: 600;
    background: transparent;
    transition: ${['color', 'background'].map(prop => `${prop} calc(1s / 3) ease-in-out`).join(',')};

    &:hover,
    &.active {
      color: #fff;
      background: #2d80f2;
    }

    ${mqmax(desktopMinSize - 1)} {
      font-size: calc(10 / 16 * 1rem);
      width: calc(92 / 16 * 1rem);
      padding: 3px 5px;
    }
  }

  #nps-form-box__btn-confirm {
    display: block;
    ${mqmax(desktopMinSize - 1)} {  
      display: none;
    }
  }

  #nps-form-box__btn-continue {
    display: none;
    ${mqmax(desktopMinSize - 1)} {
      display: block;
      font-size: calc(12 / 16 * 1rem);
      width: calc(137 / 16 * 1rem);
      padding: 9px 5px;
      color: #fff;
      background: #2d80f2;
      font-weight: 500;
      &.form-2 {
        display: none;
      } 
    }
  }

  #nps-form-box__btn-done {
    display: none;
    ${mqmax(desktopMinSize - 1)} { 
      display: none;
      font-size: calc(12 / 16 * 1rem);
      width: calc(137 / 16 * 1rem);
      padding: 9px 5px;
      color: #fff;
      background: #2d80f2;
      font-weight: 500;
      &.form-2 {
        display: block;
      } 
    }
  }
`;
