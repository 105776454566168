import React from 'react';

import { Subtitle } from '@Components/Public';

import Content from './Content';
import Container from './Container';
import { Instagram, LinkedIn, Twitter, Youtube } from './IconButton';

export const Navigation = () => (
  <Content>
    <Subtitle>Acesse nossas redes.</Subtitle>
    <Container>
      <Instagram />
      <LinkedIn />
      <Youtube />
      <Twitter />
    </Container>
  </Content>
);

export default Navigation;
