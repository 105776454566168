import React from 'react';

import Div from './Div';
import Wrapper from './Wrapper';

export const Container = ({ children }) => (
  <Wrapper>
    <Div>{children}</Div>
  </Wrapper>
);

export default Container;
