import styled from 'styled-components';

import { defaultFont, desktopMinSize, mqmin, rem } from '@Styles/mixins';

export const CreateTooltip = function (Container) {
  return styled.span`
    ${mqmin(desktopMinSize)} {
      pointer-events: none;
      border-radius: ${rem(12)};
      padding: ${rem(10)};
      position: absolute;
      top: 200%; 
      z-index: 99999;
      color: #fff;
      font-family: ${defaultFont};
      font-size: ${rem(12)};
      font-weight: bold;
      line-height: ${15 / 12};
      opacity: 0;
      transform: translateY(-${rem(16)});
      transition: ${['opacity', 'transform'].map(prop => `${prop} ${1 / 3}s ease-in-out`).join(', ')},
        pointer-events 0s ${1 / 3}s;
      background-color: #2d80f2;
      white-space: nowrap;

      ${Container}:hover & {
        opacity: 1;
        transform: translateY(0);
        pointer-events: all;
        transition-delay: 0s;
      }
    }
  `;
};
