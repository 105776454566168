import { DesktopDatePicker, MobileDatePicker } from "@mui/x-date-pickers";
import styled from "styled-components";

export const DatePickerWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
`;

export const DatePickerMobileStyled = styled(MobileDatePicker)`
    max-width: 140px;
    & .MuiInputBase-root {
        color: #FFF;
        max-width: 150px;
        height: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    & .MuiInputBase-input {
        color: #FFF;
        font-size: calc(14 / 16 * 1rem);
        font-weight: 200;
        text-align: center;
    }

    & .MuiInputLabel-root {
        color: #FFF;
    }

    & .MuiOutlinedInput {
        &-root {
            border-radius: calc(24 / 16 * 1rem);
            background: transparent;            
        }

        &-notchedOutline {
            border-color: #FFF;
        }        
    }

    & .MuiSvgIcon-root {
        color: #FFF;
        width: 1.2rem;
    }
`;
export const DatePickerDesktopStyled = styled(DesktopDatePicker)`
    max-width: 300px;
    & .MuiInputBase-root {
        color: #FFF;
        max-width: 150px;
        height: 2.5rem;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: center;
        padding: 0;

        & .MuiIconButton-root {
            margin-top: 0.15rem;
        }
    }

    & .MuiInputBase-input {
        color: #FFF;
        font-size: calc(14 / 16 * 1rem);
        font-weight: 200;
        padding: 16px 0 16px 14px;
    }

    & .MuiInputLabel-root {
        color: #FFF;
    }

    & .MuiOutlinedInput {
        &-root {
            border-radius: calc(24 / 16 * 1rem);
            background: transparent;            
        }

        &-notchedOutline {
            border-color: #FFF;
        }        
    }

    & .MuiSvgIcon-root {
        color: #FFF;
        width: 1.2rem;
    }
    
`;