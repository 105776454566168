import { createTheme, ThemeProvider } from '@material-ui/core';
import styled from 'styled-components';

export const Container = props => (
  <ThemeProvider theme={theme}>
    <Div {...props} />
  </ThemeProvider>
);

export default Container;

const theme = createTheme({
  typography: {
    fontFamily: '"Poppins", sans-serif',
  },
});

const Div = styled.section`
  background: #17171A;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  overflow: hidden;
`;
