import React from 'react';
import styled from 'styled-components';

import { desktopMinSize, mqmin } from '@Styles/mixins';

import no from './no.svg';
import yes from './yes.svg';

export const Boolean = ({ value }) => {
  const alt = value ? 'Sim' : 'Não';
  const src = value ? yes : no;
  const width = value ? 23 : 20;
  const height = value ? 22 : 20;
  const hide = !value;

  return <Img width={width} height={height} {...{ alt, hide, src }} />;
};

export default Boolean;

const Img = styled.img`
  width: calc(${({ width }) => width}rem / 24);
  ${({ hide }) => hide && 'opacity: 30%'};

  ${mqmin(desktopMinSize)} {
    width: calc(${({ width }) => width}rem / 16);
  }
`;
