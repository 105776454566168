import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button as MuiButton } from '@material-ui/core';

import plans from '@Constants/plans';
import { desktopMinSize, mqmin } from '@Styles/mixins';
import { formatCurrency } from '@Utils/formatCurrency';

import Boolean from './Boolean';
import { mq, mqmax } from '../../../../../styles/mixins';

const Container = props => {
  const isMobile = window.innerWidth < desktopMinSize;

  return (
    <table {...props}>
      <Thead>
        <Tr>
          <Th>{!isMobile ? 'Recursos' : ''}</Th>
          {plans.map(({ name }) => (
            <Th key={name}>{name}</Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        <Tr>
          <Td>
            Máquina sob demanda
            <br />
            <e>(sem fila e sem desligamento aleatório)</e>
          </Td>
          <Td>
            <Boolean highlight={true} value={true} />
          </Td>
          <Td>
            <Boolean highlight={true} value={true} />
          </Td>
        </Tr>
        <Tr>
          <Td>Uso</Td>
          {plans.map(({ name, use_type }) => (
            <Td key={name}>{use_type}</Td>
          ))}
        </Tr>
        <Tr>
          <Td>Tempo de sessão</Td>
          {plans.map(({ name, time }) => (
            <Td key={name}>{time}</Td>
          ))}
        </Tr>
        <Tr>
          <Td>Armazenamento</Td>
          {plans.map(({ name, storage }) => (
            <Td key={name}>
              <Boolean highlight={true} value={storage} />
            </Td>
          ))}
        </Tr>
        <Tr>
          <Td>Programas pré-instalados</Td>
          {plans.map(({ name, pre_installed_programs }) => (
            <Td key={name}>{pre_installed_programs}</Td>
          ))}
        </Tr>
        <Tr>
          <Td>Resolução</Td>
          {plans.map(({ name, resolution }) => (
            <Td key={name}>{resolution}</Td>
          ))}
        </Tr>
        <Tr>
          <Td>Licenças dos programas</Td>
          <Td>
            <Boolean highlight={true} value={false} />
          </Td>
          <Td>
            <Boolean highlight={true} value={false} />
          </Td>
        </Tr>
        <Tr>
          <Td>Pagamento</Td>
          {plans.map(({ name, payment }) => (
            <Td key={name} dangerouslySetInnerHTML={{ __html: payment.replace(/\n/g, '<br />') }} />
          ))}
        </Tr>
        <Tr>
          <Td>Preço</Td>
          {plans.map(({ name, price: { text, unity, value } }) => (
            <Td key={name}>
              {text}{' '}
              <strong>
                {formatCurrency(value)} <e>/ {unity}</e>
              </strong>
            </Td>
          ))}
        </Tr>
      </Tbody>
      <Tfoot>
        <Tr>
          <Td>
            <OutlinedButton component={Link} to="/planos/personal" disabled>
              Esgotado
            </OutlinedButton>
          </Td>
          <Td>
            <OutlinedButton component={Link} to="/planos/classic">
              Ver Horas Avulsas
            </OutlinedButton>
          </Td>
        </Tr>
      </Tfoot>
    </table>
  );
};

const columns = plans.length + 1;

const Th = styled.th`
  ${mqmin(desktopMinSize)} {
    font-size: calc(25rem / 16);
  }

  ${mqmax(400)} {
    font-size: 0.8rem;
  }

  ${mq(desktopMinSize - 1, 401)} {
    font-size: 0.9rem;
  }
`;

const Td = styled.td`
  line-height: calc(24 / 20);
  text-align: center;

  ${mqmin(desktopMinSize)} {
    font-size: calc(15rem / 16);
  }

  ${mqmax(400)} {
    font-size: 0.5rem;
  }

  ${mq(desktopMinSize - 1, 401)} {
    font-size: 0.6rem;
  }

  strong {
    display: block;
    line-height: calc(30 / 25);

    ${mqmin(desktopMinSize)} {
      font-size: calc(25rem / 16);
    }

    ${mqmax(400)} {
      font-size: 0.8rem;
    }

    ${mq(desktopMinSize - 1, 401)} {
      font-size: 0.9rem;
    }
  }

  e {
    font-size: calc(18rem / 24);

    ${mqmax(400)} {
      font-size: 0.5rem;
    }

    ${mq(desktopMinSize - 1, 401)} {
      font-size: 0.6rem;
    }
  }
`;

const Tr = styled.tr`
  display: grid;
  grid-template-columns: repeat(${columns}, 1fr);
  align-items: center;
  gap: 1rem;
`;

const Thead = styled.thead`
  display: grid;
  grid-template-columns: repeat(${columns}, 1fr);

  ${Tr} {
    grid-column: span ${columns};
    padding-block: calc(47rem / 40);

    ${mqmin(desktopMinSize)} {
      padding-block: calc(47rem / 16);
    }

    & > :first-child {
      text-align: left;
    }
  }
`;

const Tbody = styled.tbody`
  display: grid;
  grid-template-columns: repeat(${columns}, 1fr);

  ${Tr} {
    grid-column: span ${columns};
    box-sizing: border-box;
    min-height: calc(118rem / 40);
    border-top: solid #ffffff40 calc(2rem / 16);
    padding-block: calc(31rem / 40);

    ${mqmin(desktopMinSize)} {
      min-height: 6.5rem;
      padding-block: 0;
    }

    & > :first-child {
      text-align: left;
    }
  }
`;

const Tfoot = styled.tfoot`
  display: grid;
  grid-template-columns: repeat(${columns}, 1fr);
  border-top: solid #ffffff40 calc(2rem / 16);

  ${mqmax(desktopMinSize - 1)} {
    grid-template-columns: initial;
  }

  ${Tr} {
    grid-column: 2 / span ${columns - 1};
    grid-template-columns: repeat(${columns - 1}, 1fr);
    padding-block: calc(47rem / 40);

    ${mqmin(desktopMinSize)} {
      padding-block: calc(47rem / 16);
    }
  }
`;

export const Table = styled(Container)`
  display: grid;
  grid-template-columns: repeat(${columns}, 1fr);
  box-sizing: border-box;
  width: 100%;
  border: solid #ffffff40 calc(2rem / 16);

  ${mqmin(desktopMinSize)} {
    max-width: calc(1274rem / 16);
    border-radius: calc(60rem / 16);
    padding: calc(8rem / 16) calc(71rem / 16) 0;
  }

  ${mqmax(desktopMinSize - 1)} {
    max-width: calc(1274rem / 16);
    border-radius: calc(60rem / 16);
    padding: calc(8rem / 16) 1rem;
  }

  ${Thead},
  ${Tbody},
  ${Tfoot} {
    grid-column: span ${columns};
  }
`;

export const OutlinedButton = styled(MuiButton)`
  &.MuiButton-root {
    --fontSize: 12;

    font-size: calc(var(--fontSize) * 1rem / 16);
    border: solid #2d80f2 calc(2rem / 16);
    border-radius: calc(24rem / 16);
    padding: calc(9rem / 16) calc(30rem / 16);
    color: #2d80f2;
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
    font-weight: bold;
    line-height: calc(18 / var(--fontSize));
    transition: ${['color', 'background'].map(prop => `${prop} 300ms ease-out`).join(', ')};

    &.Mui-disabled {
      cursor: not-allowed;
      color: #2d80f2;
      border: solid #2d80f2 calc(2rem / 16);
      border-radius: calc(24rem / 16);
    }

    &:hover {
      color: #fff;
      background: #2d80f2;
    }

    ${mqmin(desktopMinSize)} {
      --fontSize: 17;

      line-height: calc(26 / var(--fontSize));
    }

    ${mq(desktopMinSize + 400, 1350)} {
      --fontSize: 12;
      line-height: calc(26 / var(--fontSize));
    }

    ${mq(1350, desktopMinSize)} {
      --fontSize: 10;
      line-height: calc(26 / var(--fontSize));
    }

    ${mqmax(400)} {
      --fontSize: 0.7rem;
      padding: 0rem 1.5rem;
      color: #fff;
      background: #2d80f2;
    }

    ${mq(desktopMinSize - 1, 401)} {
      --fontSize: 0.8rem;
      padding: 0rem 1.5rem;
      color: #fff;
      background: #2d80f2;
    }
  }
`;

export default Table;
