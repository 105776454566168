import React from 'react';
import styled from 'styled-components';

import { desktopMinSize, mqmin } from '@Styles/mixins';

export const Blog = function ({ id, post_image, title, ...props }) {
  return (
    <Article {...props}>
      <Wrapper href={`/blog/${id}`}>
        <Title>{title}</Title>
        <Link>Ler mais</Link>
        <Img alt="" src={post_image} />
      </Wrapper>
    </Article>
  );
};

export default Blog;

const Title = styled.h3`
  font-weight: bold;
  font-size: calc(25rem / 24);
  line-height: calc(30 / 25);

  ${mqmin(desktopMinSize)} {
    font-size: calc(25rem / 16);
  }
`;

const Link = styled.p`
  color: #2d80f2;
  font-size: calc(20rem / 24);
  line-height: calc(31 / 20);
  text-decoration: underline;

  ${mqmin(desktopMinSize)} {
    font-size: calc(20rem / 16);
  }
`;

const Img = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 50%) calc(100% - 168rem / 16)),
    url(${({ src }) => src}) center / cover;
  transition: scale calc(1s / 3) ease-in-out;
`;

const Wrapper = styled.a`
  color: inherit;
`;

const Article = styled.article`
  border-radius: calc(35rem / 32);
  position: relative;
  overflow: hidden;
  background: #292932;

  ${mqmin(desktopMinSize)} {
    border-radius: calc(35rem / 16);
  }

  &:hover {
    ${Img} {
      scale: calc(7 / 6);
    }
  }

  ${Title},
  ${Link} {
    margin-inline: calc(41rem / 32);
    position: absolute;
    z-index: 1;

    ${mqmin(desktopMinSize)} {
      margin-inline: calc(41rem / 16);
    }
  }

  ${Title} {
    bottom: calc(78rem / 32);

    ${mqmin(desktopMinSize)} {
      bottom: calc(78rem / 16);
    }
  }

  ${Link} {
    bottom: calc(31rem / 32);

    ${mqmin(desktopMinSize)} {
      bottom: calc(31rem / 16);
    }
  }
`;
