import React from 'react';
import styled from 'styled-components';
import { mqmax, desktopMinSize } from '@Styles/mixins';

export const Image = props => <Img alt="" {...props} />;

export default Image;

const Img = styled.img`
  display: block;
  width: auto;
  height: calc(18 / 16 * 1rem);
  ${mqmax(desktopMinSize - 1)} {
    height: calc(15 / 16 * 1rem);
  }
`;
