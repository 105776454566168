import styled from "styled-components";
import { desktopMinSize, mq, mqmax, mqmin } from "../../../../../../styles/mixins";

export const AssemblePhaseWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;

    ${mqmin(1900)} {
        gap: 1.5rem;
    }

    ${mq(desktopMinSize + 300, desktopMinSize)} {
        gap: 0.6rem;
    }
`;

export const AssembleDetailPhaseWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.8rem;
    width: 100%;

    ${mqmax(desktopMinSize-1)}{
        flex-wrap: wrap;
        justify-content: center;
    }

    ${mq((desktopMinSize + 300), desktopMinSize)} {
        gap: 1rem;
    }
`;

export const MockedAssembleDetailsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.1rem;
`;

export const InfoDescription = styled.p`
    font-size: 0.8rem;
    font-weight: 400;
    text-align: center;

    ${mqmin(1900)} {
        font-size: 1rem;
    }

    ${mq(1899, desktopMinSize + 301)} {
        font-size: 0.7rem;
    }

    ${mq(desktopMinSize + 300, desktopMinSize)} {
        font-size: 0.5rem;
    }
`;