import React from 'react';
import Container from './Container';
import Image from './Image';
import image from './image.svg';

export const Close = ({close}) => {
    return (
        <Container onClick={close}>
            <Image src={image} />
        </Container>
    )
};

export default Close;
