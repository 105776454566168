import * as sessionManager from '@Utils/session-manager';

function LOAD_SESSION(oldState, action) {
  let cookieData = null;

  if ((cookieData = sessionManager.getSessionCookieData())) {
    return {
      ...oldState,
      session: {
        jwt: cookieData.jwt,
        client: {
          name: cookieData.client.name,
          id: cookieData.client.id,
        },
        early_access: cookieData.early_access,
      },
    };
  }

  return {
    ...oldState,
  };
}

function INIT_LOGOUT(oldState, action) {
  sessionStorage.removeItem('@NOARCLOUD-my-home-page-plan-info-modal');
  sessionManager.logout();
  sessionStorage.setItem('@NOARCLOUD-my-home-page-plan-info-modal', 'false');

  return {
    ...oldState,
    session: {
      jwt: null,
      client: {
        name: null,
        id: null,
      },
      early_access: null,
    },
  };
}

function UPDATE_CLIENT_HOURS(oldState, action) {
  return {
    ...oldState,
    ...action.clientHours,
  };
}

function UPDATE_UNIT_USED_HOURS(oldState, action) {
  return {
    ...oldState,
    unit_used_minutes: action.used_minutes,
  };
}

function SET_CLIENT_HOURS_WEBSOCKET_ON(oldState, action) {
  return {
    ...oldState,
    clientHoursWebsocketOn: true,
  };
}

function OPEN_CONFIRMATION_LIGHTBOX(oldState, action) {
  return {
    ...oldState,
    confirmationLightbox: {
      isOpen: true,
      callbackFunction: action.confirmationLightbox.callbackFunction,
      text: action.confirmationLightbox.text,
      confirmBtnText: action.confirmationLightbox.confirmBtnText,
      refuseBtnText: action.confirmationLightbox.refuseBtnText,
      turnOnMachine: action.confirmationLightbox.turnOnMachine,
      disconnect: action.confirmationLightbox.disconnect,
      isUnit: action.confirmationLightbox.isUnit,
      clientsInQueue: action.confirmationLightbox.clients_in_queue,
      averageWaitingTimeMinutes: action.confirmationLightbox.average_waiting_time_minutes,
    },
  };
}

function CLOSE_CONFIRMATION_LIGHTBOX(oldState, action) {
  if (!oldState.confirmationLightbox.isOpen) {
    return {
      ...oldState,
    };
  }

  return {
    ...oldState,
    confirmationLightbox: {
      isOpen: false,
      callbackFunction: null,
    },
  };
}

function OPEN_NPS_FORM_BOX(oldState, action) {
  return {
    ...oldState,
    npsFormBox: {
      isOpen: true,
      callbackFunction: action.npsFormBox.callbackFunction,
      closeCallBackFunction: action.npsFormBox.closeCallBackFunction,
      product: action.npsFormBox.product,
      origin: action.npsFormBox.origin,
    },
  };
}

function CLOSE_NPS_FORM_BOX(oldState, action) {
  if (!oldState.npsFormBox.isOpen) {
    return {
      ...oldState,
    };
  }

  return {
    ...oldState,
    npsFormBox: {
      isOpen: false,
      callbackFunction: null,
      product: null,
    },
  };
}

function OPEN_TERMS_BOX(oldState, action) {
  return {
    ...oldState,
    termsBox: {
      isOpen: true,
    },
  };
}

function CLOSE_TERMS_BOX(oldState, action) {
  if (!oldState.termsBox.isOpen) {
    return {
      ...oldState,
    };
  }

  return {
    ...oldState,
    termsBox: {
      isOpen: false,
    },
  };
}

function SET_PERSONAL_PLAN(oldState, action) {
  return {
    ...oldState,
    personalPlan: action.personalPlan,
  };
}

function SET_ACCOUNT_PERSONAL_INFO(oldState, action) {
  const accountPersonalInfo = action.payload.accountPersonalInfo
  ? action.payload.accountPersonalInfo
  : oldState.accountPersonalInfo.accountPersonalInfo;
  const showPopup =
  action.payload.showPopup !== undefined ? action.payload.showPopup : oldState.accountPersonalInfo.showPopup;
  const showPopupValidationFunction = action.payload.showPopupValidationFunction
  ? action.payload.showPopupValidationFunction
  : oldState.accountPersonalInfo.showPopupValidationFunction;
  return {
    ...oldState,
    accountPersonalInfo: {
      accountPersonalInfo: accountPersonalInfo,
      showPopup: showPopup,
      showPopupValidationFunction: showPopupValidationFunction,
    },
  };
}

const actions = {
  LOAD_SESSION,
  INIT_LOGOUT,
  UPDATE_CLIENT_HOURS,
  UPDATE_UNIT_USED_HOURS,
  SET_CLIENT_HOURS_WEBSOCKET_ON,
  OPEN_CONFIRMATION_LIGHTBOX,
  CLOSE_CONFIRMATION_LIGHTBOX,
  OPEN_NPS_FORM_BOX,
  CLOSE_NPS_FORM_BOX,
  OPEN_TERMS_BOX,
  CLOSE_TERMS_BOX,
  SET_PERSONAL_PLAN,
  SET_ACCOUNT_PERSONAL_INFO,
};

export default actions;
