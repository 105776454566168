import styled from "styled-components";
import { desktopMinSize, mqmax } from "../../../styles/mixins";

export const Partners = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    gap: 2.5rem;
`;


export const Image = styled.img`
  & {
    background-color: transparent;
  }
`;

export const PartnersImage =  styled(Image)`
    width: 75%;
    ${mqmax(desktopMinSize -1)} {
        width: 60%;
    }
`;

export const PartnersContent = styled.div``;