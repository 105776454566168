import React from 'react';
import SectionSubtitle from '../CreatePasswordSubtitle';
import Mobile from '../../../Mobile';

export const Subtitle = () => (
    <Mobile>
        <SectionSubtitle>Crie uma nova senha:</SectionSubtitle>
    </Mobile>
);

export default Subtitle;
