import styled from 'styled-components';
import { mqmin, desktopMinSize } from '@Styles/mixins';

export const Mobile = styled.div`
  text-align: center;
  ${mqmin(desktopMinSize)} {
    display: none;
  }
`;

export default Mobile;
