import styled from 'styled-components';
import { desktopMinSize, mqmin, rem } from '../../../styles/mixins';
import NoarLogoMobile from '../../../assets/logo-mobile.svg';

export const CarrouselCardWrapperMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 120%;
  max-width: 339px;
  height: 141px;
  background-size: cover;
  border-radius: 28px;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 47px;
    left: 0;
    bottom: 0;
    background: transparent url(${NoarLogoMobile}) right no-repeat padding-box;
    z-index: 3;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: -129px;
    background: transparent linear-gradient(270deg, #00000000 0%, #00000006 0%, #000000 100%) 0% 0% no-repeat
      padding-box;
    z-index: 1;
    border-bottom-left-radius: 28px;
    border-bottom-right-radius: 28px;
    opacity: 0.5;
    pointer-events: none;
  }

  strong {
    font-size: ${rem(20)};
    font-weight: 600;
    color: #fff;
    display: block;
    z-index: 3;
    margin-bottom: 13px;
  }

  img {
    width: 100%;
    min-height: 504px;
    z-index: 1;
    position: absolute;
    inset: 0;
  }

  button {
    max-width: 93px;
    width: 100%;
    height: 28px;
    border-radius: 28px;
    background-color: transparent;
    outline: 2px solid #fff;
    outline-offset: -2px;
    border: 0;
    font-size: ${rem(10)};
    font-weight: 600;
    color: #fff;
    display: block;
    text-transform: uppercase;
    z-index: 3;
  }
`;

export const CarrouselCardButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 32px;
`;

export const ContainerMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
  z-index: ${({ step }) => (step === 1 || step === 2 ? '102' : 'unset')};
  position: relative;
  pointer-events: ${({ step }) => (step === 1 || step === 2 ? 'none' : 'unset')};

  & > div {
    z-index: ${({ step }) => (step === 2 ? '102' : '1')};
  }

  & > div:not(:first-child) > div {
    &::after {
      content: '';
      position: absolute;
      inset: 0;
      background: rgba(0, 0, 0, 0.5);
      width: 100%;
      height: 100%;
      z-index: ${({ step }) => (step === 2 ? '10' : '-1')};
    }
  }

  ${mqmin(desktopMinSize)} {
    display: none;
  }

  & > strong {
    font-size: 0.75rem;
    font-weight: 500;
    text-align: left;
    width: 100%;
    margin-bottom: 10px;
  }

  & + & {
    margin-top: 27px;
  }
`;
