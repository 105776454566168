import { HeaderWrapper, Step, Title } from '../../styled';
import { UseTypeWrapper, TypeContainer } from '../styled';

export const FirstQuestionPlay = ({ gameFrequency, setGameFrequency }) => {
  return (
    <Step>
      <HeaderWrapper>
        <Title>Com qual frequência você joga?</Title>
      </HeaderWrapper>
      <UseTypeWrapper>
        <TypeContainer
          onClick={() => {
            setGameFrequency('THREE_TO_FOR_TIMES_MONTH');
          }}
          selected={gameFrequency === 'THREE_TO_FOR_TIMES_MONTH'}
        >
          <h3>3 a 4 vezes por mês</h3>
        </TypeContainer>
        <TypeContainer
          onClick={() => {
            setGameFrequency('TWO_TO_THREE_TIMES_WEEK');
          }}
          selected={gameFrequency === 'TWO_TO_THREE_TIMES_WEEK'}
        >
          <h3>2 a 3 vezes por semana</h3>
        </TypeContainer>
        <TypeContainer
          onClick={() => {
            setGameFrequency('ALMOST_EVERYDAY');
          }}
          selected={gameFrequency === 'ALMOST_EVERYDAY'}
        >
          <h3>Quase todos os dias</h3>
        </TypeContainer>
        <TypeContainer
          onClick={() => {
            setGameFrequency('EVERYDAY');
          }}
          selected={gameFrequency === 'EVERYDAY'}
        >
          <h3>Todos os dias</h3>
        </TypeContainer>
      </UseTypeWrapper>
    </Step>
  );
};

export const SecondQuestionPlay = ({ saveProgressComputerGames, setSaveProgressComputerGames }) => {
  return (
    <Step>
      <HeaderWrapper>
        <Title>Você costuma salvar o seu progresso do jogo no computador?</Title>
      </HeaderWrapper>
      <UseTypeWrapper>
        <TypeContainer
          onClick={() => {
            setSaveProgressComputerGames('YES');
          }}
          selected={saveProgressComputerGames === 'YES'}
        >
          <h3>Sim</h3>
        </TypeContainer>
        <TypeContainer
          onClick={() => {
            setSaveProgressComputerGames('NO');
          }}
          selected={saveProgressComputerGames === 'NO'}
        >
          <h3>Não</h3>
        </TypeContainer>
        <TypeContainer
          onClick={() => {
            setSaveProgressComputerGames('SOMETIMES');
          }}
          selected={saveProgressComputerGames === 'SOMETIMES'}
        >
          <h3>As vezes</h3>
        </TypeContainer>
      </UseTypeWrapper>
    </Step>
  );
};

export const ThirdQuestionPlay = ({ gameSessionTime, setGameSessionTime }) => {
  return (
    <Step>
      <HeaderWrapper>
        <Title>Quanto tempo você costuma jogar a cada sessão?</Title>
      </HeaderWrapper>
      <UseTypeWrapper>
        <TypeContainer
          onClick={() => {
            setGameSessionTime('ONE_TO_THREE_HOURS');
          }}
          selected={gameSessionTime === 'ONE_TO_THREE_HOURS'}
        >
          <h3>1 a 3 horas</h3>
        </TypeContainer>
        <TypeContainer
          onClick={() => {
            setGameSessionTime('FOR_TO_EIGHT_HOURS');
          }}
          selected={gameSessionTime === 'FOR_TO_EIGHT_HOURS'}
        >
          <h3>4 a 8 horas</h3>
        </TypeContainer>
        <TypeContainer
          onClick={() => {
            setGameSessionTime('MORE_THAN_EIGHT_HOURS');
          }}
          selected={gameSessionTime === 'MORE_THAN_EIGHT_HOURS'}
        >
          <h3>Mais de 8 horas</h3>
        </TypeContainer>
      </UseTypeWrapper>
    </Step>
  );
};
