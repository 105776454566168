import styled from 'styled-components';

import { desktopMinSize, rem } from '@Styles/mixins';
import { mq, mqmax } from '../../styles/mixins';


export const Header = styled.header`
  display: flex;
  align-items: center;
  z-index: 103;
  width: 100%;
  min-height: ${rem(80)};
  position: absolute;
  background: rgba(23, 23, 26 , 0.7);
  backdrop-filter: blur(5px);
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  flex-direction: row;
  justify-content: space-around;

  ${mq((desktopMinSize + 300), desktopMinSize)} {
    min-height: ${rem(65)};
  }

  ${mqmax(desktopMinSize - 1)} {
    display: none ;
  }

`;
