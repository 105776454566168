import styled from 'styled-components';

import { mqmax, desktopMinSize, mq } from '@Styles/mixins';

export const ClassicPlanCard = styled.div`
  display: flex;
  flex-direction: ${({ isOnClassic }) => (isOnClassic ? 'row' : 'column')};
  align-items: center;
  gap: 2.5rem;
  padding: 35px;
  background-color: transparent;
  position: relative;
  width: ${({ isOnClassic }) => (isOnClassic ? '818px' : '269px')};
  transition: width 500ms ease-in-out;
  max-height: 414px;
  height: 100%;

  &:nth-child(1) {
    z-index: ${({ step }) => (step === 1 ? '104' : '1')};
    pointer-events: ${({ step }) => (step === 1 ? 'none' : 'unset')};
  }

  &:nth-child(3) {
    & > div:nth-child(2) > button:nth-child(2) {
      z-index: ${({ step }) => (step === 3 ? '104' : '1')};
    }
  }
  &:nth-child(2) {
    & > div:nth-child(2) > button:nth-child(3) {
      z-index: ${({ step }) => (step === 3 ? '104' : '1')};
    }
  }

  ${mq(desktopMinSize + 300, desktopMinSize)} {
    width: ${({ isOnClassic }) => (isOnClassic ? '100%' : '200px')};
    align-items: center;
    max-width: 850px;
    min-width: 200px;
    padding: 5px 35px;
    max-height: 358px;
  }

  ${mqmax(desktopMinSize - 1)} {
    align-items: center;
    padding: 8px 32px 18px 32px;
    border-radius: 22px;
    flex-direction: column;
    transition: max-height 500ms ease-in-out;
    gap: 1rem;
    width: auto;
  }
`;

export const ClassicPlanCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 1.2rem;

  & > div:first-child {
    text-align: center;
    max-width: 266px;

    ${mq(desktopMinSize + 300, desktopMinSize)} {
      max-width: 200px;
    }
  }

  & > div:last-child {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    ${mqmax(desktopMinSize - 1)} {
      margin-left: -9px;
    }
  }

  ${mq(desktopMinSize + 300, desktopMinSize)} {
    gap: 0.5rem;
  }

  ${mqmax(desktopMinSize - 1)} {
    flex-direction: row;
    gap: 2rem;
    width: 100%;

    & > div:first-child {
      text-align: left;
      min-height: auto;
    }
  }
`;

export const ClassicPlanCardTitle = styled.strong`
  font-weight: 600 !important;
  font-size: 1.875rem !important;

  ${mqmax(desktopMinSize - 1)} {
    font-size: 1.063rem !important;
    margin: 10px 0 0 !important;
  }
`;

export const ClassicPlanCardInfo = styled.ul`
  text-align: center;
  min-width: 266px;

  & > li {
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 20px;

    & + li {
      margin-top: 10px;
    }

    & > span {
      font-size: 0.688rem;
    }

    ${mq(desktopMinSize + 300, desktopMinSize)} {
      font-size: 0.7rem;

      & > span {
        font-size: 0.5rem;
      }

      & + li {
        margin-top: 0 !important;
      }
    }

    ${mqmax(desktopMinSize - 1)} {
      font-size: 0.5rem !important;
      line-height: 14px;

      & > span {
        font-size: 0.5rem;
      }

      & + li {
        margin-top: 0 !important;
      }
    }
  }

  & > span {
    font-size: 0.688rem;
    display: block;

    ${mqmax(desktopMinSize - 1)} {
      font-size: 0.5rem !important;
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    min-width: 124px;
    text-align: left;
    margin: 12px 0;
  }
`;

export const ClassicPlanCardPrice = styled.strong`
  font-size: 0.875rem !important;
  color: #6a6a6b;
  font-weight: 500 !important;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;

  & > span {
    font-weight: 600;
    color: #fff;
    font-size: 2.75rem;
  }

  ${mq(desktopMinSize + 300, desktopMinSize)} {
    font-size: 0.6rem !important;
    margin: 0 !important;

    & > span {
      font-size: 1.5rem;
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    font-size: 0.5rem !important;
    margin: 0 !important;

    & > span {
      font-size: 1.5rem;
    }
  }
`;

export const ClassicPlanCardContractButton = styled.button`
  background-color: #2d80f2;
  max-width: 181px;
  width: 100%;
  height: 50px;
  color: #fff;
  text-transform: uppercase;
  border-radius: 25px;
  transition: background-color 250ms;
  font-size: 0.938rem;

  &:hover:not(:disabled) {
    background-color: #9e0cf6;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  ${mqmax(desktopMinSize - 1)} {
    margin: 12px 0 8px;
    max-width: 100px;
    height: 28px;
    font-size: 0.5rem;
  }
`;

export const ClassicPlanCardKnowMoreButton = styled.button`
  background: transparent;
  font-size: 0.875rem;
  color: #2d80f2;
  text-decoration: underline;
  transition: color 250ms;
  margin: 0 auto;

  &:hover {
    color: #9e0cf6;
  }

  ${mqmax(desktopMinSize - 1)} {
    font-size: 0.5rem;
  }
`;

export const CloseIcon = styled.button`
  position: absolute;
  background: transparent;
  border: 0;
  opacity: 0.75;
  right: 3%;
  top: 6%;
  display: block;

  ${mqmax(desktopMinSize - 1)} {
    display: none;
  }
`;

export const ClassicPlanCardOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.688rem;
  width: 100%;
  min-width: 428px;
  top: ${({ isOnClassic }) => (isOnClassic ? '0' : '-1000%')};
  position: ${({ isOnClassic }) => (isOnClassic ? 'unset' : 'absolute')};
  pointer-events: ${({ isOnClassic }) => (isOnClassic ? 'auto' : 'none')};
  animation: ${({ isOnClassic }) => isOnClassic && 'fade-in 2s forwards'};

  .promo-obs {
    font-size: 0.75rem;

    ${mqmax(desktopMinSize - 1)} {
      font-size: 0.4rem !important;
    }
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    gap: 0.688rem;
    min-width: 203px;
    margin: 0 auto;
    height: 100%;
  }
`;

export const ClassicPlanCardPrices = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ClassicPlanCardPriceTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  strong {
    font-size: 1.875rem !important;
    font-weight: 600 !important;
    margin: 0 !important;

    ${mqmax(desktopMinSize - 1)} {
      font-size: 1.063rem !important;
    }
  }
`;

export const PriceDiscountDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 0.5rem;

  span {
      font-size: 0.688rem;
      font-weight: 300;

      & > b {
        font-weight: 600;
      }

      ${mqmax(desktopMinSize - 1)} {
        font-size: 0.5rem;
      }
    }
`;

export const ActualPriceP = styled.p`
  font-size: 1.5rem;
  font-weight: 600;
  color: #fff;
`;

export const OriginalPriceP = styled.p`
  font-size: 1rem;
  font-weight: 300;
  color: #6a6a6b;
  text-decoration: line-through;
`;

export const MainInfoWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;

  ${mqmax(desktopMinSize - 1)} {
    span {
      font-weight: 700 !important;
      font-size: 1rem;
    }
  }
`;

export const ClassicPlanCardPriceButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.625rem;

  button:first-child {
    font-size: 0.938rem;
    max-width: 181px;
    width: 100%;
    height: 50px;
    background-color: #2d80f2;
    border-radius: 25px;
    color: #fff;
    text-transform: uppercase;
    transition: background-color 250ms;

    &:hover {
      background-color: #9e0cf6;
    }

    ${mqmax(desktopMinSize - 1)} {
      font-size: 0.5rem;
      max-width: 100px;
      height: 28px;
    }
  }

  button:last-child {
    font-size: 0.688rem;
    background-color: transparent;
    text-decoration: underline;
    color: #2d80f2;
    transition: color 250ms;

    &:hover {
      color: #9e0cf6;
    }

    ${mqmax(desktopMinSize - 1)} {
      font-size: 0.5rem;
      margin-right: 6px;
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    align-items: flex-end;
  }
`;

export const Divider = styled.div`
  max-width: ${({ isOnClassic }) => (isOnClassic ? '100%' : '180px')};
  width: 100%;
  height: 1px;
  background-color: #3a3b44;
  display: block;
  margin: 0 auto;

  ${mqmax(desktopMinSize - 1)} {
    display: none;
  }
`;

export const MobileDividerWrapper = styled.div`
  display: none;
  width: 100%;

  ${mqmax(desktopMinSize - 1)} {
    display: block;
  }
`;

export const ColoredSpan = styled.span`
  color: #426FE5;
  font-weight: 700;
`;

export const MobileDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #3a3b44;
  margin: 0 auto;
`;

export const AboutStardardWrapper = styled.dialog`
  max-width: 863px;
  width: 100%;
  height: 603px;
  border-radius: 45px;
  background-color: #17171a;
  border: 0;
  box-shadow: 0 0 0 100vw rgba(0, 0, 0, 0.6);

  &[open] {
    animation: slide-up 350ms forwards, fade-in 350ms forwards;
  }

  &[closing] {
    display: block;
    pointer-events: none;
    inset: 0;
    animation: slide-down 350ms forwards, fade-out 350ms forwards;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 50px;
    padding: 3px;
    background: linear-gradient(132deg, #2d80f2 0%, #9b0cee 100%);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;

    ${mqmax(desktopMinSize - 1)} {
      height: 629px;
      padding: 2px;
      border-radius: 30px;
    }
  }

  & > div {
    position: relative;
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-direction: column;

    & > strong {
      display: block !important;
      margin-top: 61px !important;
      color: #fff;
      font-weight: 700 !important;
      font-size: 2.188rem !important;

      ${mqmax(desktopMinSize - 1)} {
        font-size: 1rem !important;
        margin-top: 17px !important;
      }
    }

    & > ul {
      text-align: justify;
      margin-top: 34px;
      color: #fff;
      margin-left: 55px;
      margin-right: 55px;

      & > li {
        margin-bottom: 19px;
        font-weight: 300;
        font-size: 0.95rem;
        line-height: 25px;
        min-width: 670px;

        ${mqmax(desktopMinSize - 1)} {
          font-size: 0.75rem !important;
          line-height: 15px;
          min-width: 250px;
        }
      }

      ${mqmax(desktopMinSize - 1)} {
        margin-top: 10px;
      }
    }

    button {
      background: transparent;
      position: absolute;
      top: 5%;
      right: 5%;

      svg {
        width: 24px;
        height: 24px;
        opacity: 0.24;
      }

      ${mqmax(desktopMinSize - 1)} {
        margin-top: 10px;
        top: -3%;
        right: 0;
      }
    }
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes slide-up {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0%);
    }
  }

  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes slide-down {
    0% {
      transform: translateY(0%);
    }
    100% {
      transform: translateY(100%);
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    max-width: 304px;
    height: 500px;
    border-radius: 30px;
  }
`;

export const VerticalDivider = styled.div`
  width: 1px;
  height: 100%;
  background-color: #3a3b44;
  display: block;
  max-height: 550px;

  ${mqmax(desktopMinSize - 1)} {
    display: none;
  }
`;

export const MobileVerticalDivider = styled.div`
  width: 1px;
  height: 115px;
  background-color: #3a3b44;
  display: none;

  ${mqmax(desktopMinSize - 1)} {
    display: block;
  }
`;
