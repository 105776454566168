import { DefaultContentDivWrapper, SectionTitle } from '../styled';
import * as S from './styled';
import tencentImagePng from './images/tencent-logo.png'

export function Partners(){
    return (
        <DefaultContentDivWrapper>
            <SectionTitle>
                Parceiros
            </SectionTitle>
            <S.PartnersContent>
                <S.PartnersImage alt={''} src={tencentImagePng} />
            </S.PartnersContent>
        </DefaultContentDivWrapper>

    );
}

export default Partners;