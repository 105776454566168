import { HeaderWrapper, Step, Title } from '../../styled';
import { UseTypeWrapper, TypeContainer } from '../styled';

export const FirstQuestionWork = ({ useNoarProfessional, setUseNoarProfessional }) => {
  return (
    <Step>
      <HeaderWrapper>
        <Title>Quando você vai usar a Noar?</Title>
      </HeaderWrapper>
      <UseTypeWrapper>
        <TypeContainer
          onClick={() => {
            setUseNoarProfessional('THROUGHOUT_WORK');
          }}
          selected={useNoarProfessional === 'THROUGHOUT_WORK'}
        >
          <h3>Durante todo meu trabalho</h3>
        </TypeContainer>
        <TypeContainer
          onClick={() => {
            setUseNoarProfessional('SPECIFIC_TIMES');
          }}
          selected={useNoarProfessional === 'SPECIFIC_TIMES'}
        >
          <h3>Apenas em momentos específicos</h3>
        </TypeContainer>
      </UseTypeWrapper>
    </Step>
  );
};

export const SecondQuestionWork = ({ professionalStorage, setProfessionalStorage }) => {
  return (
    <Step>
      <HeaderWrapper>
        <Title>O quanto você precisa de armazenamento?</Title>
      </HeaderWrapper>
      <UseTypeWrapper>
        <TypeContainer
          onClick={() => {
            setProfessionalStorage('SAVE_ON_DRIVE');
          }}
          selected={professionalStorage === 'SAVE_ON_DRIVE'}
        >
          <h3>Não preciso de armazenamento, salvo tudo no meu drive!</h3>
        </TypeContainer>
        <TypeContainer
          onClick={() => {
            setProfessionalStorage('SHORT_STORAGE');
          }}
          selected={professionalStorage === 'SHORT_STORAGE'}
        >
          <h3>Preciso apenas para arquivos enquanto estou trabalhando neles.</h3>
        </TypeContainer>
        <TypeContainer
          onClick={() => {
            setProfessionalStorage('FULL_STORAGE');
          }}
          selected={professionalStorage === 'FULL_STORAGE'}
        >
          <h3>Preciso de muito armazenamento! Vou deixar tudo salvo na Noar!</h3>
        </TypeContainer>
      </UseTypeWrapper>
    </Step>
  );
};
