import styled from 'styled-components';

export const Form = styled.form`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    grid-gap: calc(15 / 16 * 1rem);
`;

export default Form;
