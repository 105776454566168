import React from 'react';
import Wrapper from './Wrapper';

export const Container = ({ children }) => (
  <Wrapper>
   {children}
  </Wrapper>
);

export default Container;
