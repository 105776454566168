import Typography from '@material-ui/core/Typography';
import React from 'react';
import styled from 'styled-components';
import {
  mqmax,
  desktopMinSize
} from '@Styles/mixins';

export const PaymentTitle = props => <H2 variant="h2" {...props} />;

export default PaymentTitle;

const H2 = styled(Typography)`
  &.MuiTypography-h2 {
    margin: 0;
    color: #fff;
    font-size: calc(22 / 16 * 1rem);
    font-weight: 400;
    line-height: calc(52 / 48);
    text-align: center;
    ${mqmax(desktopMinSize - 1)} {
      font-size: calc(16 / 16 * 1rem);
      line-height: calc(65 / 48);
    }
  }
`;
