import styled from 'styled-components';
import { mqmax, desktopMinSize } from '@Styles/mixins';

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: calc(43 / 1056 * 100%) calc(90 / 1056 * 100%);
  border-radius: calc(40 / 16 * 1rem);
  background: transparent;
  animation: fade-in 2s forwards;
  ${mqmax(desktopMinSize - 1)} {
    padding: calc(60 / 1056 * 100%) calc(70 / 1056 * 100%);
    border-radius: calc(30 / 16 * 1rem);
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export default Box;
