import styled from 'styled-components';
import { mqmax, desktopMinSize, mq } from '@Styles/mixins';
import { mqmin } from '../../../../styles/mixins';

export const UnitGeneralWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;

  ${mqmin(1900)} {
    width: 80%;
  }

  ${mqmax(desktopMinSize - 1)} {
    width: 100%;
  }
`;

export const UnitGeneralInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2.5rem;
  width: 100%;
  height: 100%;
  padding: 0 1rem 2rem;

  ${mqmax(desktopMinSize - 1)} {
    flex-direction: column;
    gap: 1rem;
  }
`;

export const PaymentBrickBackGround = styled.div`
  display: ${({ showPayment }) => (showPayment ? 'flex' : 'none')};
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  inset: 0;
  z-index: 99997;
  width: 100%;
  height: calc(100% - 4rem);
  backdrop-filter: blur(5px);
  animation: ${({ showPayment }) => showPayment && 'fade-in 0.5s forwards'};
  pointer-events: ${({ disablePaymentContent }) => (disablePaymentContent ? 'none' : 'auto')};
  overflow-y: auto;
  padding: 2rem 0;

  & > div {
    opacity: ${({ disablePaymentContent }) => (disablePaymentContent ? '0.8' : '1')};
  }
`;

export const PaymentBrickWrapper = styled.div`
  display: ${({ showPayment }) => (showPayment ? 'flex' : 'none')};
  position: relative;
  gap: 1.5rem;
  width: fit-content;
  height: fit-content;
  inset: 0;
  z-index: 99998;
  pointer-events: ${({ disablePaymentContent }) => (disablePaymentContent ? 'none' : 'auto')};
  animation: ${({ showPayment }) => showPayment && 'slide-up 0.5s forwards'};
  margin: auto;
`;

export const CloseButton = styled.button`
  z-index: 99999;
  background: transparent;
  border: 0;
  position: absolute;
  top: 16px;
  right: 16px;
  color: #fff;
`;

export const UnitPlanCard = styled.div`
  display: flex;
  flex-direction: ${({ isOnUnit }) => (isOnUnit ? 'row' : 'column')};
  align-items: center;
  gap: 2.5rem;
  background-color: transparent;
  max-height: fit-content;
  position: relative;
  width: ${({ isOnUnit }) => (isOnUnit ? '818px' : '269px')};
  transition: width 500ms ease-in-out;
  height: 100%;
  width: 100%;

  &:nth-child(1) {
    z-index: ${({ step }) => (step === 1 ? '104' : '1')};
    pointer-events: ${({ step }) => (step === 1 ? 'none' : 'unset')};
  }

  &:nth-child(3) {
    & > div:nth-child(2) > button:nth-child(2) {
      z-index: ${({ step }) => (step === 3 ? '104' : '1')};
    }
  }
  &:nth-child(2) {
    & > div:nth-child(2) > button:nth-child(3) {
      z-index: ${({ step }) => (step === 3 ? '104' : '1')};
    }
  }

  ${mq(desktopMinSize + 300, desktopMinSize)} {
    width: 22%;
    max-width: 269px;
    min-width: 200px;
    align-items: center;
    max-height: 358px;
  }

  ${mqmax(desktopMinSize - 1)} {
    align-items: center;
    border-radius: 22px;
    width: 80%;
    flex-direction: ${({ isOnUnit }) => (isOnUnit ? 'column' : 'row')};
    transition: max-height 500ms ease-in-out;
    gap: 0.5rem;
  }

  ${mqmin(1900)} {
    width: 100%;
  }
`;

export const UnitPlanCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 1.2rem;

  & > div:first-child {
    text-align: center;
    max-width: 266px;

    ${mq(desktopMinSize + 300, desktopMinSize)} {
      max-width: 200px;
    }
  }

  & > div:last-child {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    ${mqmax(desktopMinSize - 1)} {
      margin-left: -9px;
    }
  }

  ${mq(desktopMinSize + 300, desktopMinSize)} {
    gap: 0.5rem;
  }

  ${mqmax(desktopMinSize - 1)} {
    gap: 0.5rem;
    min-height: 161px;
    align-items: flex-start;

    & > div:first-child {
      text-align: left;
      min-height: auto;
    }
  }

  ${mqmin(1900)} {
    width: 100%;
  }
`;

export const UnitPlanCardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.4rem;
`;

export const UnitPlanCardTitle = styled.strong`
  font-weight: 600 !important;
  font-size: 1.875rem !important;
  display: block;

  ${mqmax(desktopMinSize - 1)} {
    font-size: 1.5rem !important;
    margin: 10px 0 0 !important;
    text-align: left;
  }

  ${mqmin(1900)} {
    font-size: 2.5rem !important;
  }
`;

export const UnitPlanCardInfo = styled.ul`
  text-align: center;
  min-width: 266px;

  & > li {
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 17px;

    & + li {
      margin-top: 10px !important;
    }

    & > span {
      font-size: 0.688rem;
    }

    ${mq(desktopMinSize + 300, desktopMinSize)} {
      font-size: 0.7rem;

      & > span {
        font-size: 0.5rem;
      }

      & + li {
        margin-top: 0 !important;
      }
    }

    ${mqmax(desktopMinSize - 1)} {
      font-size: 1rem !important;
      line-height: 18px;

      & > span {
        font-size: 0.6rem;
      }

      & + li {
        margin-top: 0 !important;
      }
    }

    ${mqmin(1900)} {
      font-size: 2rem !important;
      line-height: 35px;

      & > span {
        font-size: 1.2rem;
      }

      & + li {
        margin-top: !important;
      }
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    min-width: 124px;
    text-align: left;
    margin: 12px 0;
  }

  ${mqmin(1900)} {
    min-width: 600px;
  }
`;

export const ScratchedSpan = styled.span`
  text-decoration: line-through;
  color: #6a6a6b;
  font-weight: 300 !important;
  font-size: 2rem !important;

  ${mqmax(desktopMinSize - 1)} {
    font-size: 1.2rem !important;
  }
`;

export const UnitPlanCardPrice = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  font-size: 1rem !important;
  color: #fff;
  font-weight: 500 !important;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  gap: 0.2rem;

  & > span {
    font-weight: 600;
    color: #fff;
    font-size: 2.75rem;
  }

  ${mq(desktopMinSize + 300, desktopMinSize)} {
    font-size: 0.6rem !important;
    margin: 0 !important;

    & > span {
      font-size: 1.5rem;
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    font-size: 0.8rem !important;
    margin: 0 !important;

    & > span {
      font-size: 1.5rem;
    }
  }

  ${mqmin(1900)} {
    font-size: 1.5rem !important;
    margin: 0 !important;

    & > span {
      font-size: 2.5rem;
    }
  }
`;

export const UnitPlanCardDiscountValueWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.3rem;
  width: 100%;

  & > span {
    font-weight: 600;
    color: #fff;
    font-size: = ${({ crossed }) => (crossed ? '2rem' : '2.75rem')};

    text-decoration: ${({ crossed }) => (crossed ? 'line-through' : 'none')};
    text-decoration-color: ${({ crossed }) => (crossed ? '#6a6a6b' : 'none')};

    ${mqmax(desktopMinSize - 1)} {
      font-size: 5.5rem;
    }
  }

  & > p {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #6a6a6b !important;
    font-size: 0.875rem !important;
    text-align: center;
    .MuiSvgIcon-root {
      font-size: 1rem;
    }

    ${mqmin(1900)} {
      font-size: 1.5rem !important;
      .MuiSvgIcon-root {
        font-size: 2rem;
      }
    }
  }

  ${mq(desktopMinSize + 300, desktopMinSize)} {
    font-size: 0.6rem !important;
    margin: 0 !important;

    & > span {
      font-size: 1.5rem;
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    font-size: 0.5rem !important;
    margin: 0 !important;

    & > span {
      font-size: 1.5rem;
    }
  }

  ${mqmin(1900)} {
    font-size: 1.5rem !important;
    margin: 0 !important;

    & > span {
      font-size: ${({ crossed }) => (crossed ? '2rem' : '3rem')};
    }
  }
`;

export const ReferralVoucherDiscountSpan = styled.span`
  font-size: 1.5rem !important;
  font-weight: 600 !important;
  color: #fff !important;

  text-decoration: none !important;
  text-decoration-color: none !important;

  ${mqmin(1900)} {
    font-size: 2.75rem !important;
  }
`;

export const UnitPlanCardDiscountsPriceWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  width: 100%;
`;

export const UnitPlanCardButton = styled.button`
  background-color: #2d80f2;
  max-width: 181px;
  width: 100%;
  height: 50px;
  color: #fff;
  text-transform: uppercase;
  border-radius: 25px;
  transition: background-color 250ms;
  font-size: 0.938rem;

  &:hover:not(:disabled) {
    background-color: #9e0cf6;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  ${mqmax(desktopMinSize - 1)} {
    margin: 12px 0 8px;
    max-width: 150px;
    height: 40px;
    font-size: 0.8rem;
  }
`;

export const UnitPlanCardKnowMoreButton = styled.button`
  background: transparent;
  font-size: 0.875rem;
  color: #2d80f2;
  text-decoration: underline;
  transition: color 250ms;

  &:hover {
    color: #9e0cf6;
  }

  ${mqmax(desktopMinSize - 1)} {
    font-size: 0.5rem;
  }

  ${mqmin(1900)} {
    font-size: 1.5rem;
  }
`;

export const UnitPlanOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.688rem;
  width: 100%;
  min-width: 428px;
  height: 100%;
  top: ${({ isOnUnit }) => (isOnUnit ? '0' : '-1000%')};
  position: ${({ isOnUnit }) => (isOnUnit ? 'unset' : 'absolute')};
  pointer-events: ${({ isOnUnit }) => (isOnUnit ? 'auto' : 'none')};
  animation: ${({ isOnUnit }) => isOnUnit && 'fade-in 2.5s forwards'};

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    gap: 0.688rem;
    min-width: 203px;
    margin: 0 auto;
    height: 100%;
  }
`;

export const CloseIcon = styled.button`
  position: absolute;
  background: transparent;
  border: 0;
  opacity: 0.24;
  right: 3%;
  top: 6%;

  display: block;

  ${mqmax(desktopMinSize - 1)} {
    display: none;
  }
`;

export const UnitPlanSingle = styled.div`
  min-width: 471px;
  & > strong {
    font-size: 0.875rem !important;
    font-weight: 300;

    span {
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    min-width: max-content;
    strong {
      font-size: 0.563rem !important;
      max-width: max-content !important;
      margin: 0 !important;
    }

    &:last-child > strong {
      margin-top: 5px !important;
      display: block;
    }
  }
`;

export const UnitPlanSingleContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;

  & + & {
    margin-top: 25px;
  }

  div:first-child {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    strong {
      font-size: 1.875rem;
      font-weight: 600;
    }

    span {
      font-size: 0.688rem;
      font-weight: 300;

      b {
        font-weight: 600;
      }
    }

    ${mqmax(desktopMinSize - 1)} {
      strong {
        font-size: 1.063rem !important;
      }

      span {
        font-size: 0.5rem;
      }
    }
  }

  div:last-child {
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 0.5rem;
    width: 100%;
    max-width: 181px;

    button:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    ${mqmax(desktopMinSize - 1)} {
      align-items: flex-end;

      button:first-child {
        max-width: 100px;
        height: 28px;
        font-size: 8px;
      }

      button:last-child {
        font-size: 8px;
        margin-right: 6px;
      }
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    & + & {
      margin-top: 15px;
    }
  }
`;

export const UnitPlanBuyButton = styled.button`
  max-width: 181px;
  width: 100%;
  height: 50px;
  background-color: #2d80f2;
  transition: background-color 350ms;
  color: #fff;
  text-transform: uppercase;
  font-size: 15px;
  border-radius: 25px;

  &:hover:not(:disabled) {
    background-color: #9e0cf6;
  }
`;

export const UnitPlanAddToCartButton = styled.button`
  text-decoration: underline;
  color: #2d80f2;
  background: none;
  border: 0;
  font-size: 0.688rem;
  transition: color 350ms;

  &:hover:not(:disabled) {
    color: #9e0cf6;
  }
`;

export const HorizontalDivider = styled.div`
  max-width: ${({ isOnUnit }) => (isOnUnit ? '100%' : '180px')};
  width: 100%;
  height: 1px;
  background-color: #3a3b44;
  display: block;
  margin: 0 auto;

  ${mqmax(desktopMinSize - 1)} {
    display: none;
  }
`;

export const AboutUnitWrapper = styled.dialog`
  max-width: 863px;
  width: 100%;
  height: 835px;
  border-radius: 45px;
  background-color: #17171a;
  border: 0;
  box-shadow: 0 0 0 100vw rgba(0, 0, 0, 0.6);

  &[open] {
    animation: slide-up 350ms forwards, fade-in 350ms forwards;
  }

  &[closing] {
    display: block;
    pointer-events: none;
    inset: 0;
    animation: slide-down 350ms forwards, fade-out 350ms forwards;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 50px;
    padding: 3px;
    background: linear-gradient(132deg, #2d80f2 0%, #9b0cee 100%);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;

    ${mqmax(desktopMinSize - 1)} {
      height: 922px;
      padding: 2px;
      border-radius: 30px;
    }
  }

  & > div {
    position: relative;
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-direction: column;

    & > strong {
      display: block !important;
      /* margin-top: 5px !important; */
      color: #fff;
      font-weight: 700 !important;
      font-size: 2.188rem !important;

      ${mqmax(desktopMinSize - 1)} {
        font-size: 1rem !important;
        margin-top: 17px !important;
      }
    }

    & > ul {
      text-align: justify;
      margin-top: 34px;
      color: #fff;
      margin-left: 55px;
      margin-right: 55px;

      & > li {
        margin-top: 15px;
        font-weight: 300;
        font-size: 0.95rem;
        line-height: 25px;
        min-width: 670px;

        ${mqmax(desktopMinSize - 1)} {
          font-size: 0.75rem !important;
          line-height: 15px;
          min-width: 250px;
        }
      }

      ${mqmax(desktopMinSize - 1)} {
        margin-top: 10px;
      }
    }

    button {
      background: transparent;
      position: absolute;
      top: 5%;
      right: 5%;

      svg {
        width: 24px;
        height: 24px;
        opacity: 0.24;
      }

      ${mqmax(desktopMinSize - 1)} {
        margin-top: 10px;
        top: -2%;
        right: 1%;
      }
    }
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes slide-up {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0%);
    }
  }

  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes slide-down {
    0% {
      transform: translateY(0%);
    }
    100% {
      transform: translateY(100%);
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    max-width: 304px;
    height: 517px;
    border-radius: 30px;
  }
`;

export const MobileDividerWrapper = styled.div`
  display: none;
  width: 100%;

  ${mqmax(desktopMinSize - 1)} {
    display: flex;
    align-items: center;
    gap: 0.75rem;

    button {
      background: transparent;
      border: 0;
      opacity: 0.24;
    }
  }
`;

export const MobileDivider = styled.div`
  max-width: 256px;
  width: 100%;
  height: 1px;
  background-color: #3a3b44;
  margin: 0 auto;
  display: block;

  ${mqmin(desktopMinSize)} {
    display: none;
  }
`;

export const MobileVerticalDivider = styled.div`
  width: 1px;
  height: 115px;
  background-color: #3a3b44;
  display: none;

  ${mqmax(desktopMinSize - 1)} {
    display: block;
  }
`;

export const ColoredEm = styled.em`
  color: transparent;
  background: linear-gradient(90deg, #2d80f2 0%, #a00bf6 100%);
  -webkit-background-clip: text;
  background-clip: text;
`;

export const PromoStyledP = styled.p`
  font-size: 2rem;
  font-weight: 700;
  line-height: 3rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${mqmin(1900)} {
    font-size: 2.5rem;
    line-height: 3.5rem;
  }

  ${mqmax(desktopMinSize - 1)} {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
`;
