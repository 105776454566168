import styled from 'styled-components';
import { mqmin, desktopMinSize } from '@Styles/mixins';
import { mqmax } from '../../../../../styles/mixins';

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  row-gap: calc(20 / 16 * 1rem);
  position: relative;

  ${mqmax(desktopMinSize -1)}{
    width: 70%;
  }

  ${mqmin(desktopMinSize)}{
    min-width: 363px;
  }
`;

export default Div;
