import React from 'react';

import Container from '../Container';
import Image from '../Image';

import image from './image.svg';

export const LinkedIn = () => (
  <Container href="https://www.linkedin.com/company/noarcloud/">
    <Image alt="LinkedIn" src={image} width={21} height={21} />
  </Container>
);

export default LinkedIn;
