import React from 'react';
import { useSelector } from 'react-redux';

import * as S from './styled';

function Hello() {
  const { name } = useSelector((state) => state.global.session.client);

  function nameAbbreviation() {
    if (!name) return '';

    const firstName = name.split(' ')[0];

    return firstName.length > 18
      ? `${firstName.substring(0, 15)}...`
      : firstName;
  }

  return (
    <S.Hello>
      <p className="hello__mobile-customer-name">
        {'Olá, '}
        <span className="customer-name" data-complete-name={name}>
          {nameAbbreviation()}
        </span>
      </p>
    </S.Hello>
  );
}

export default Hello;
