import React from 'react';
import styled from 'styled-components';
import MuiButton from '@material-ui/core/Button';
import {
    defaultFont,
    desktopMinSize, 
    mqmax
  } from '@Styles/mixins';

export const Button = props => 
    <ButtonElement type='button' {...props} />

export default Button;

const ButtonElement = styled(MuiButton)`
  &.MuiButton-root {
    font-family: ${defaultFont};
    text-transform: none;
    font-style: normal;
    text-align: center;
    line-height: 20px;
    transition: color calc(1s / 3) ease-in-out;
    font-weight: 200;
    color: #fff;
    font-size: calc(15 / 16 * 1rem);
    &:hover {
        text-decoration: underline;
    }
    ${mqmax(desktopMinSize - 1)} {
      width: 30%;
      padding: 5px;
      font-size: calc(10 / 16 * 1rem);
    }
  }
`;