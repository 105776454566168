import React from 'react';

import Container from '../Container';
import Image from '../Image';

import image from './image.svg';

export const Twitter = () => (
  <Container href="https://twitter.com/noarcloud">
    <Image alt="Twitter" src={image} width={23} height={19} />
  </Container>
);

export default Twitter;
