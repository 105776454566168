import React, { useState, useEffect } from 'react';
import { useTutorialStep } from '../../../context/TutorialStepContext';
import { FirstStep } from './FirstStep';
import { SecondStep } from './SecondStep';
import { ThirdInitialStep } from './ThirdInitialStep';
import { ThirdStep } from '../CartSteps/ThirdStep';
import { FourthStep } from '../CartSteps/FourthStep';
import { FifthStep } from '../CartSteps/FifthStep';
import { SeventhStep } from '../CartSteps/SeventhStep';

export const ShopPageSteps = () => {
  const { handleNextStep, handleSkipTutorial, state } = useTutorialStep();
  const [isFirstAccess, setIsFirstAccess] = useState(false);

  useEffect(() => {
    var firstAccess = JSON.parse(localStorage.getItem('firstAccess'));
    if (firstAccess && firstAccess[0]?.shopPageSteps) {
      setIsFirstAccess(false);
    } else {
      setIsFirstAccess(true);
      localStorage.setItem(
        'firstAccess',
        JSON.stringify([
          {
            initialAMISteps: true,
            sectionPageSteps: true,
            programSteps: true,
            shopPageSteps: true,
          },
        ]),
      );
    }
  }, []);

  if (!isFirstAccess) return null;

  switch (state[3].step) {
    case 1:
      return (
        <FirstStep nextStep={() => handleNextStep('shop-page')} skipTutorial={() => handleSkipTutorial('shop-page')} />
      );

    case 2:
      return (
        <SecondStep nextStep={() => handleNextStep('shop-page')} skipTutorial={() => handleSkipTutorial('shop-page')} />
      );

    case 3:
      return (
        <ThirdInitialStep
          nextStep={() => handleNextStep('shop-page')}
          skipTutorial={() => handleSkipTutorial('shop-page')}
        />
      );
    case 4:
      return (
        <ThirdStep nextStep={() => handleNextStep('shop-page')} skipTutorial={() => handleSkipTutorial('shop-page')} />
      );
    case 5:
      return (
        <FourthStep nextStep={() => handleNextStep('shop-page')} skipTutorial={() => handleSkipTutorial('shop-page')} />
      );
    case 6:
      return (
        <FifthStep nextStep={() => handleNextStep('shop-page')} skipTutorial={() => handleSkipTutorial('shop-page')} />
      );
    case 7:
      return (
        <SeventhStep
          nextStep={() => handleNextStep('shop-page')}
          skipTutorial={() => handleSkipTutorial('shop-page')}
        />
      );

    default:
      return null;
  }
};
