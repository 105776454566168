import { HeaderWrapper, Step, Title } from '../../styled';
import { UseTypeWrapper, TypeContainer } from '../styled';

export const FirstQuestionMiscAct = ({ usedNoarActivities, setUsedNoarActivities }) => {
  return (
    <Step>
      <HeaderWrapper>
        <Title>Você utilizará a Noar em todas suas atividades?</Title>
      </HeaderWrapper>
      <UseTypeWrapper>
        <TypeContainer
          onClick={() => {
            setUsedNoarActivities(true);
          }}
          selected={usedNoarActivities === true}
        >
          <h3>Sim, a partir de hoje só usarei meu PC na Noar</h3>
        </TypeContainer>
        <TypeContainer
          onClick={() => {
            setUsedNoarActivities(false);
          }}
          selected={usedNoarActivities === false}
        >
          <h3>Não, somente as que exigem maior performance</h3>
        </TypeContainer>
      </UseTypeWrapper>
    </Step>
  );
};

export const SecondQuestionMiscAct = ({ softwareDifferentActivities, setSoftwareDifferentActivities }) => {
  return (
    <Step>
      <HeaderWrapper>
        <Title>Você irá utilizar muitos softwares diferentes?</Title>
      </HeaderWrapper>
      <UseTypeWrapper>
        <TypeContainer
          onClick={() => {
            setSoftwareDifferentActivities(true);
          }}
          selected={softwareDifferentActivities === true}
        >
          <h3>Sim, precisarei instalar diversos softwares na máquina</h3>
        </TypeContainer>
        <TypeContainer
          onClick={() => {
            setSoftwareDifferentActivities(false);
          }}
          selected={softwareDifferentActivities === false}
        >
          <h3>Não utilizarei apenas navegadores e programas na web</h3>
        </TypeContainer>
      </UseTypeWrapper>
    </Step>
  );
};

export const ThirdQuestionMiscAct = ({ storeFilesActivities, setStoreFilesActivities }) => {
  return (
    <Step>
      <HeaderWrapper>
        <Title>Você armazenará todos seus arquivos na máquina?</Title>
      </HeaderWrapper>
      <UseTypeWrapper>
        <TypeContainer
          onClick={() => {
            setStoreFilesActivities(true);
          }}
          selected={storeFilesActivities === true}
        >
          <h3>Sim! Tudo que eu fizer ficará salvo no computador</h3>
        </TypeContainer>
        <TypeContainer
          onClick={() => {
            setStoreFilesActivities(false);
          }}
          selected={storeFilesActivities === false}
        >
          <h3>Não, utilizo sempre uma boa nuvem para armazenar os arquivos</h3>
        </TypeContainer>
      </UseTypeWrapper>
    </Step>
  );
};
