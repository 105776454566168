import React, { useState, useEffect } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Combobox } from 'react-widgets';
import 'react-widgets/styles.css';

import { getSessions, getBusinessSessions, getCollaborators } from '@Services/noar-api';

import { ProtectedComponent } from '../../ProtectedComponent';
import { Loading } from '../../Loading';
import { MySessionsAccordionContent } from './MySessionsAccordionContent';

import { FilterWrapper } from '../styled';
import { Container } from './styled';

import { BsSearch } from 'react-icons/bs';
import { useUserRole } from '../../../context/UserRoleContext';

export const MySessionsAccordion = () => {
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [sessionsData, setSessionsData] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [retrieveCollaboratorsNames, setRetrieveCollboratorsNames] = useState([]);
  const { clientType } = useUserRole();
  
  const queryClient = useQueryClient();

  const { data: businessDataMobile } = useQuery({
    queryKey: ['businessDataMobile'],
    queryFn: () => clientType === "B2B_ADM"
      ? getBusinessSessions(currentPage, itemsPerPage, undefined, undefined, retrieveCollaboratorsNames)
      : getSessions(currentPage, itemsPerPage),
    refetchOnWindowFocus: false,
  });

  const { data: CollabData } = useQuery({
    queryKey: ['collabData'],
    queryFn: () => clientType === "B2B_ADM" ? getCollaborators() : [],
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    async function getData() {
      try {
        setLoading(true);
        var response;
        if(clientType === "B2B_ADM"){
          response = await getBusinessSessions(currentPage, itemsPerPage, undefined, undefined, retrieveCollaboratorsNames)
        }
        else {
          response = await getSessions(currentPage, itemsPerPage);
        }
        setSessions(response.page_info);
        setItemsPerPage(response.page_info.total_elements === 0 ? 5 : response.page_info.total_elements);
        setSessionsData(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
    getData();
  }, [currentPage, itemsPerPage]);

  const retrieveCollaboratorsData = CollabData?.data?.reduce((acc, data) => {
    acc.push(data.name, data.email);
    return acc;
  }, []);

  useEffect(() => {
    queryClient.fetchQuery({
      queryKey: ['businessDataMobile'],
    });
  }, [currentPage, itemsPerPage, retrieveCollaboratorsNames]);

  return (
    <Container>
      <strong>Histórico de acessos</strong>
      {loading ? (
        <Loading />
      ) : (
        <>
          <FilterWrapper>
            <Combobox
              value={retrieveCollaboratorsNames}
              onChange={e => setRetrieveCollboratorsNames(e)}
              data={retrieveCollaboratorsData}
              placeholder={'Buscar usuário'}
              selectIcon={<BsSearch size={14} />}
              messages={{
                emptyList: 'Nenhuma atividade detectada',
                emptyFilter: 'Nenhum nome encontrado',
              }}
            />
          </FilterWrapper>
          <ProtectedComponent role={['B2B_ADM']}>
            {businessDataMobile?.data.slice(0, itemsPerPage).map(session => {
              return (
                <MySessionsAccordionContent
                  key={session.id_session}
                  access={session.client_name}
                  date={session.begin_date}
                  section={session.image_name}
                  product={session.client_email}
                  entryDate={session.begin_date}
                  entryTime={session.begin_date?.split(' ')[1]?.split('.')[0]}
                  exitDate={session.end_date}
                  exitTime={session.end_date?.split(' ')[1]?.split('.')[0]}
                  duration={session.used_hours}
                />
              );
            })}
          </ProtectedComponent>

          <ProtectedComponent role={['B2C']}>
            {sessionsData.slice(0, itemsPerPage).map((session, index) => {
              return (
                <MySessionsAccordionContent
                  key={session.id_session}
                  access={sessions.total_elements - sessions.page_size * sessions.page_number - index}
                  date={session.begin_date}
                  section={session.image_name}
                  product={session.product_Type}
                  entryDate={session.begin_date}
                  entryTime={session.begin_date?.split(' ')[1]?.split('.')[0]}
                  exitDate={session.end_date}
                  exitTime={session.end_date?.split(' ')[1]?.split('.')[0]}
                  duration={session.used_hours}
                />
              );
            })}
          </ProtectedComponent>
        </>
      )}
    </Container>
  );
};
