import React from 'react';
import styled from 'styled-components';

import { Text } from '@Components/Public';
import { desktopMinSize, mqmin } from '@Styles/mixins';

import Buttons from './Buttons';
import Container from './Container';

export const Content = () => (
  <Container>
    <Texts>
      <Text>
        Faça parte da nossa comunidade no Discord! Lá você fica por dentro de todas as novidades,{' '}
        <strong>
          tira dúvidas e pode conhecer melhor a equipe <span className="uppercase">Noar</span>.
        </strong>
      </Text>
      <Text>
        Fale com a gente também <strong>por e-mail.</strong>
      </Text>
    </Texts>
    <Buttons />
  </Container>
);

export default Content;

const Texts = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: calc(13rem / 16);

  ${mqmin(desktopMinSize)} {
    row-gap: calc(28rem / 16);
  }
`;
