import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  FilterWrapper,
  CreateUserButton,
  HeadersWrapper,
  CreateUserButtonWrapper,
  Title,
} from './styled';
import Combobox from 'react-widgets/Combobox';
import 'react-widgets/styles.css';
import { BsSearch } from 'react-icons/bs';
import { DeleteOutline, EditOutlined, InfoOutlined, LockOpenOutlined, PlayArrowOutlined } from '@material-ui/icons';

import { useQuery, useQueryClient } from '@tanstack/react-query';
import { PopupFormModal } from './PopupModal';
import MobileWrapper from './Mobile/Wrapper';
import DesktopWrapper from './Desktop';
import { deleteGoldImage, editGoldImage, getGoldImages, listAllHosts, registerGoldImage, createInstance } from '../../services/noar-api';
import { desktopMinSize } from '../../styles/mixins';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { setComputerCreationEnabled, setComputerLoading, updateComputer } from '../../store/ducks/computer/actions';
import { openConfirmationLightbox } from '../../store/ducks/global/actions';
import { PlayArrowRounded, PlaylistAddCircleRounded } from '@mui/icons-material';

const useStyles = makeStyles({
  root: {
    color: '#fff',
  },
});

const GoldImagesTable = () => {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const [showFormPopupModalContent, setShowFormPopupModal] = useState(false);
  const [retrieveGoldImagesName, setRetrieveGoldImagesName] = useState('');
  const [showInfoPopupModalContent, setShowInfoPopupModal] = useState(false);
  const [availableHosts, setAvailableHosts] = useState([]);
  const [allHosts, setAllHosts] = useState([]);
  const [actionFunction, setActionFunction] = useState(null);
  const [actionTitle, setActionTitle] = useState(null);
  const [action, setAction] = useState(null);
  const [isMobile] = useState(window.innerWidth < desktopMinSize);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const history = useHistory();

  const { data: goldImagesInfo } = useQuery({
    queryKey: ['goldImagesInfo'],
    queryFn: () => getGoldImages(retrieveGoldImagesName),
    refetchOnWindowFocus: false,
  });

  const actions = goldImage => {
    return {
      play: {
        ico: <PlayArrowRounded />,
        title: 'Ligar',
      },
      edit: {
        ico: <EditOutlined />,
        title: 'Editar',
      },
      delete: {
        ico: <DeleteOutline />,
        title: 'Deletar',
      },
      info: {
        ico: <InfoOutlined />,
        title: 'Informações',
      },
    };
  };

  function handleUpdateFormPopupModal(goldImage) {
    setShowFormPopupModal(!showFormPopupModalContent);
  }

  function handleFormPopupModal() {
    setShowFormPopupModal(!showFormPopupModalContent);
  }

  function handleSetActionFunction(action) {
    setActionFunction(action);
  }

  function handleSetActionTitle(text) {
    setActionTitle(text);
  }

  const retrieveGoldImagesData = goldImagesInfo?.data?.reduce((acc, data) => {
    acc.push(data.image_name);
    return acc;
  }, []);

  function handleEditGoldImage(goldImage) {
    try {
      handleSetActionFunction({ actionFunction: sendEditGoldImage, data: goldImage });
      handleSetActionTitle('Editar infos da Imagem');
      setAction('update');
      handleFormPopupModal(goldImage);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  function handleCreateGoldImage() {
    try {
      handleSetActionFunction({ actionFunction: showConfirmCreationPopup, data: undefined });
      handleSetActionTitle('Nova Imagem');
      setAction('create');
      handleFormPopupModal();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  function handleDeleteGoldImage(goldImage) {
    try {
      handleSetActionFunction({ actionFunction: sendDeleteGoldImage, data: goldImage });
      handleSetActionTitle(
        <>
          <p>Tem certeza que deseja excluir a imagem {goldImage.image_name}?</p>
        </>
      );
      setAction('delete');
      handleFormPopupModal();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  function handlePlayGoldImage(data) {
    dispatch(
      openConfirmationLightbox(
        () => sendStartGoldImage(data, "ADMIN_GOLD_IMAGE_UPDATE"),
        <p> Deseja iniciar a imagem {data.image_name}?
          <br /><br />Vamos iniciar uma máquina para que possa atualizar a imagem.
        </p>,
        "Iniciar sessão",
        "Fechar",
        null,
        null,
        false,
      ),
    );
  }

  async function sendDeleteGoldImage(id, hostId) {
    try {
      await deleteGoldImage(id, hostId);
      queryClient.fetchQuery({
        queryKey: ['goldImagesInfo'],
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async function sendEditGoldImage(data) {
    try {
      console.log(data)
      await editGoldImage(data.id, data);
      queryClient.fetchQuery({
        queryKey: ['goldImagesInfo'],
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  }


  function showConfirmCreationPopup(data) {
    dispatch(
      openConfirmationLightbox(
        () => sendCreateGoldImage(data),
        <p> Tem certeza que deseja criar uma nova imagem gold?
          <br /><br />Vamos iniciar uma máquina para que possa configurar a imagem.
        </p>,
        "Iniciar sessão",
        "Voltar",
        null,
        null,
        false,
      ),
    );
  }

  async function sendStartGoldImage(data, use_type) {
    try {
      let cloud_image_id = data.cloud_id;
      let creation_type = "ENTERPRISE";
      let host_id = data.host.host_id;
      dispatch(setComputerLoading());
      dispatch(setComputerCreationEnabled());
      history.push('/my/home');
      await createInstance(cloud_image_id, creation_type, host_id, use_type)
        .then(async (response) => {
          dispatch(updateComputer(response));
        });
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async function sendCreateGoldImage(data) {
    let response = await registerGoldImage(data);
    data.cloud_id = response.cloud_id;
    if (!response.status !== 201) {
      //TODO: Tratar erro
    }
    await sendStartGoldImage(data, "ADMIN_GOLD_IMAGE_CREATION");
  }

  useEffect(() => {
    setLoading(true);
    queryClient.fetchQuery({
      queryKey: ['goldImagesInfo'],
    }).then(() => {
      setLoading(false);
    });
  }, [retrieveGoldImagesName]);

  useEffect(() => {
    async function fetchAvailableHosts() {
      try {
        const response = await listAllHosts();
        setAllHosts(response);
        setAvailableHosts(response.filter(host => host.is_available));
      } catch (error) {
        console.error(error);
        throw error;
      }
    }
    fetchAvailableHosts();
  }, []);

  return (
    <Container>
      <Title>Imagens Gold</Title>
      <HeadersWrapper>
        <FilterWrapper>
          <Combobox
            value={retrieveGoldImagesName}
            onChange={e => setRetrieveGoldImagesName(e)}
            data={retrieveGoldImagesData}
            placeholder={'Buscar Imagem'}
            selectIcon={<BsSearch size={14} />}
            messages={{
              emptyList: 'Nenhuma atividade detectada',
              emptyFilter: 'Nenhum nome encontrado',
            }}
          />
        </FilterWrapper>
        <CreateUserButtonWrapper>
          <CreateUserButton onClick={handleCreateGoldImage}>ADICIONAR IMAGEM</CreateUserButton>
        </CreateUserButtonWrapper>
      </HeadersWrapper>
      {isMobile ? (
        <MobileWrapper
          loading={loading}
          goldImagesInfo={goldImagesInfo}
          actions={actions}
          handleEditGoldImage={handleEditGoldImage}
          handleDeleteGoldImage={handleDeleteGoldImage}
          handlePlayGoldImage={handlePlayGoldImage}
        />
      ) : (
        <DesktopWrapper
          loading={loading}
          goldImagesInfo={goldImagesInfo}
          actions={actions}
          handleDeleteGoldImage={handleDeleteGoldImage}
          handleEditGoldImage={handleEditGoldImage}
          handlePlayGoldImage={handlePlayGoldImage}
          classes={classes}
        />
      )}
      {showFormPopupModalContent ? (
        <PopupFormModal
          close={action === 'update' ? handleUpdateFormPopupModal : handleFormPopupModal}
          actionFunction={actionFunction}
          title={actionTitle}
          action={action}
          queryClient={queryClient}
          availableHosts={action === 'update' ? allHosts : availableHosts}
        />
      ) : (
        []
      )}
    </Container>
  );
};

export default GoldImagesTable;
