import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import React from 'react';
import styled from 'styled-components';

import { mqmax, desktopMinSize } from '@Styles/mixins';
import { mq } from '../../../../../styles/mixins';

export const Input = props => (
  <Div>
    <InputLabelElement forcedUpdate={props.forcedUpdate} htmlFor="input-label">
      {props.label}
    </InputLabelElement>
    <InputElement {...props} label="" />
  </Div>
);

export default Input;

const InputElement = styled(TextField)`
  &.MuiFormControl-root {
    border-radius: calc(24 / 16 * 1rem);
    width: 100%;
  }

  .MuiInputLabel-outlined {
    width: max-content;
    max-width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &.MuiInputLabel-shrink {
      transform: scale(0.75) translate(-50%, -175%);
    }
  }

  .MuiFormLabel-asterisk {
    display: none;
  }

  .MuiInputBase-input {
    color: #fff;
    font-size: calc(16 / 16 * 1rem);
    font-weight: 300;
    line-height: calc(20 / 16);
    text-align: left;
    ${mqmax(desktopMinSize - 1)} {
      font-size: calc(14 / 16 * 1rem);
    }
  }

  .MuiInputBase-input.Mui-disabled {
    opacity: 0.36;
  }

  .MuiOutlinedInput {
    &-input {
      padding: calc(15 / 16 * 1rem) calc(24 / 16 * 1rem) calc(15 / 16 * 1rem);
      ${mqmax(desktopMinSize - 1)} {
        padding: calc(16 / 16 * 1rem) calc(24 / 16 * 1rem) calc(16 / 16 * 1rem);
      }
      ${mq('1400', '1080')} {
        padding: ${({ forcedUpdate }) => (forcedUpdate ? 'calc(10 / 16 * 1rem) calc(24 / 16 * 1rem) calc(10 / 16 * 1rem)' : 'calc(15 / 16 * 1rem) calc(24 / 16 * 1rem) calc(15 / 16 * 1rem)')};
      }
    }

    &-root {
      border-radius: calc(24 / 16 * 1rem);
      background: #21222a;
    }

    
  }

  .PrivateNotchedOutline-root-1 {
    display: none;
  }

  .MuiOutlinedInput-notchedOutline{
    border: #fff solid 1px; 
  }

  .MuiFormHelperText-root.Mui-error{
    position: absolute;
    top:100%;
  }
`;

const InputLabelElement = styled(InputLabel)`
  &.MuiInputLabel-root {
    color: #fff;
    font-size: ${({ forcedUpdate }) => (forcedUpdate ? 'calc(16 / 16 * 1rem)' : 'calc(21 / 16 * 1rem)')};
    font-weight: 300;

    ${mqmax(desktopMinSize - 1)} {
      font-size: calc(16 / 16 * 1rem);
    }

    ${mq('1400', '1080')} {
      font-size: ${({ forcedUpdate }) => (forcedUpdate ? 'calc(14 / 16 * 1rem)' : 'calc(21 / 16 * 1rem)')};
      height: ${({ forcedUpdate }) => (forcedUpdate ? '4px' : 'auto')};
    }
  }
`;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: calc(10 / 16 * 1rem);
  width: 45%;

  ${mqmax(desktopMinSize - 1)} {
    width: 100%;
  }

  ${mq('1400', '1080')} {
    row-gap: 1rem;
  }

`;
