import React, { useState, useEffect } from 'react';
import { Radio, FormControlLabel, Box } from '@mui/material';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import VerifiedIcon from '@mui/icons-material/Verified';
import styled from 'styled-components';

const Verified = styled(TaskAltIcon)`
  color: #1db45a;
`;
const Unverified = styled(RadioButtonUncheckedIcon)`
  color: #FFF;
`;

const ValidateControl  = styled.div`
  display: flex;
  align-items: center;
`

const StyledLabel = styled.span`
  font-size: 12px;
  color: #FFF;
`;

const PasswordStrengthChecker = ({ password, direction }) => {
  const [containsLowerCase, setContainsLowerCase] = useState(false);
  const [containsUpperCase, setContainsUpperCase] = useState(false);
  const [containsNumber, setContainsNumber] = useState(false);
  const [containsSpecialChar, setContainsSpecialChar] = useState(false);

  useEffect(() => {
    checkPasswordRules(password);
  }, [password]);

  const checkPasswordRules = (newPassword) => {
    setContainsLowerCase(/[a-z]/.test(newPassword));
    setContainsUpperCase(/[A-Z]/.test(newPassword));
    setContainsNumber(/\d/.test(newPassword));
    setContainsSpecialChar(/[!@#$%^&*(),.?":{}|<>]/.test(newPassword));
  };

  return (
    <Box
      style={{
        padding: '15px',
        display: 'flex',
        flexDirection: direction
      }}
    >
      <Box
        style={{
          display: 'flex',
          gap: '10px',
        }}
      >
        <ValidateControl>
          {containsLowerCase ? <div><Verified /></div> : <Unverified />}
          <StyledLabel>Uma letra minúscula</StyledLabel>
        </ValidateControl>
        <ValidateControl>
          {containsNumber ? <Verified /> : <Unverified />}
          <StyledLabel>Um caracter numerico</StyledLabel>
        </ValidateControl>
      </Box>
      <Box
        style={{
          display: 'flex',
          gap: '10px',
        }}
      >
        <ValidateControl>
          {containsUpperCase ? <Verified /> : <Unverified />}
          <StyledLabel>Uma letra maiúscula</StyledLabel>
        </ValidateControl>
        <ValidateControl>
          {containsSpecialChar ? <Verified /> : <Unverified />}
          <StyledLabel>Um caracter especial</StyledLabel>
        </ValidateControl>
      </Box>
    </Box>
  );
};

export default PasswordStrengthChecker;