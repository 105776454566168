import styled from 'styled-components';
import bannerImg from '@Assets/blog-banner.webp';

import { mqmax, desktopMinSize } from '@Styles/mixins';
import { mq } from '../../styles/mixins';

export const ContentBanner = styled.div`
  max-width: 1274px;
  margin: 0 auto;
  height: 180px;
  border-radius: 50px;
  margin-top: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-image: url(${bannerImg});
  h1 {
    font-size: 3.75rem;
    font-weight: 700;
    color: #fff;

    span {
      text-transform: uppercase;
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    max-width: 349px;
    height: 88px;
    border-radius: 20px;
    h1 {
      font-size: 1.875rem;
    }
  }
`;

export const BlogDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ccc;
  margin-top: 107px;

  ${mqmax(desktopMinSize - 1)} {
    margin-top: 34px;
  }
`;

export const BlogFooterWrapper = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 122px;
  & > img {
    max-width: 100%;
    width: 284px;
    height: 218px;

    ${mqmax(desktopMinSize - 1)} {
      width: 134px;
      height: 102px;
    }
  }

  & > button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 73px;
    margin-bottom: 101px;
    background: transparent;
    max-width: 35px;
    width: 100%;
    aspect-ratio: 1;
    border: 2px solid #17171a;
    border-radius: 50%;

    & > img {
      width: 10px;
      aspect-ratio: 1;
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    margin-top: 61px;
  }
`;

export const BlogFooterLinks = styled.div`
  margin-top: 73px;
  display: flex;
  flex-direction: row;
  gap: 2.375rem;
  & > a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    color: #17171a;

    transition: color 350ms;

    &:hover {
      color: #2d33f2;
    }

    ${mqmax(desktopMinSize - 1)} {
      font-size: 0.625rem;
    }

    & > img {
      width: 17px;
      aspect-ratio: 1;
      margin-right: 10px;

      ${mqmax(desktopMinSize - 1)} {
        margin-right: 5px;
      }
    }

    &:nth-child(2) > img {
      margin-top: -6px;
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }
`;

export const BlogSingleContentContainer = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 10px 2%;

  ${mqmax(desktopMinSize - 1)} {
    max-width: 342px;
  }

  & > div:nth-child(3) > div:has(form) {
    max-width: 190px;
    height: 145px;
    margin: 54px auto 0 auto;
    padding: 21px 60px !important;
    text-align: center;
    display: none;

    ${mqmax(desktopMinSize - 1)} {
      display: block;
    }
  }

  & > div:nth-child(3) > div > div {
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;

    & > div {
      width: 100% !important;

      & > strong {
        font-size: 0.938rem;
      }

      strong:last-child {
        font-size: 0.5rem;
      }
    }

    & > form {
      flex-direction: column;
      margin-top: 10px;

      & > input {
        max-width: 160px;
        height: 27px;
        font-size: 0.5rem;
      }

      & > button {
        max-width: 91px;
        height: 27px;
        font-size: 0.625rem;
      }
    }
  }

  & > div:nth-child(4) {
    display: none !important;
    ${mqmax(desktopMinSize - 1)} {
      display: block !important;
    }
  }

  & > div:nth-child(5) {
    ${mqmax(desktopMinSize - 1)} {
      display: none !important;
    }
  }
`;

export const BlogSingleTopImageWrapper = styled.div`
  margin: 0 auto;
  height: 180px;
  margin-top: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  & > img {
    max-width: 100%;

    height: 300px;
    object-fit: cover;

    border-radius: 50px;

    ${mqmax(desktopMinSize - 1)} {
      width: 349px;
      height: 88px;
      border-radius: 20px;
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    margin-top: unset;
  }
`;

export const BlogSingleContentWrapper = styled.div`
  display: flex;
  gap: 3rem;

  ${mqmax(desktopMinSize - 1)} {
    flex-direction: column;
    justify-content: center;
    gap: unset;
  }
`;

export const BlogSingleMainContentWrapper = styled.main`
  min-width: 809px;
  margin-top: 125px;
  overflow: hidden;
  background-color: white !important;

  ${mq(1400, desktopMinSize)} {
    min-width: 50%;
  }

  & > h1 {
    font-size: 2.5rem;
    line-height: 52px;
    font-weight: 600;
    max-width: 31ch;
    color: #17171a;

    ${mqmax(desktopMinSize - 1)} {
      font-size: 1.25rem;
      line-height: 25px;
    }
  }

  & > div:nth-child(3) {
    margin-top: 25px;
  }

  & > strong {
    display: block;
    text-align: center;
    font-size: 1.125rem;
    font-weight: 400;
    margin-top: 93px;
    color: #17171a;

    ${mqmax(desktopMinSize - 1)} {
      font-size: 0.938rem;
      margin-top: 52px;
    }
  }

  & > div:has(button > img) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
    gap: 1rem;

    & > button {
      border: 0;
      width: 79px;
      aspect-ratio: 1;
      border-radius: 100vmax;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: filter 300ms;

      img {
        width: 35px;
        height: 32px;
      }

      &:hover {
        filter: brightness(80%);
      }

      ${mqmax(desktopMinSize - 1)} {
        width: 58px;

        img {
          width: 28px;
        }
      }
    }

    button:nth-child(1) {
      background-color: #2d80f2;
    }
    button:nth-child(2) {
      background-color: #f94343;
    }
    button:nth-child(3) {
      background-color: #cdcdcd;
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    min-width: unset;
    margin-top: 0;
  }
`;

export const BlogSingleMainHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 55px;

  strong {
    font-weight: 300;
    font-size: 0.938rem;

    ${mqmax(desktopMinSize - 1)} {
      font-size: 0.5rem;
    }
  }

  time {
    font-weight: 300;
    font-size: 0.938rem;

    ${mqmax(desktopMinSize - 1)} {
      font-size: 0.5rem;
    }
  }

  strong:nth-child(2) {
    ${mqmax(desktopMinSize - 1)} {
      margin-top: 4px;
    }
  }

  & > div:nth-child(1) {
    display: flex;
    align-items: center;
    gap: 0.875rem;
  }

  & > div:nth-child(2) {
    display: flex;
    align-items: center;
    gap: 1rem;

    strong {
      font-weight: 400;
      font-size: 0.813rem;

      & + strong {
        margin-left: 0.5rem;
      }

      ${mqmax(desktopMinSize - 1)} {
        font-size: 0.5rem;
      }
    }

    ${mqmax(desktopMinSize - 1)} {
      gap: 0.75rem;
    }
  }
  & > div:nth-child(1) > div:has(img) {
    img {
      width: 28px;
      aspect-ratio: 1;
      border-radius: 100vmax;
      object-fit: cover;

      ${mqmax(desktopMinSize - 1)} {
        display: flex;
        align-items: baseline;
        width: 15px;
      }
    }
  }

  span:has(img) {
    img {
      margin-right: 11px;
      width: 13px;
      aspect-ratio: 1;

      ${mqmax(desktopMinSize - 1)} {
        width: 7px;
      }
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    margin-top: 21px;
  }
`;

export const BlogSingleMainSubHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 17px;
  margin-bottom: 32px;

  button {
    border: 0;
    background-color: transparent;
    color: #8b8b8d;
    font-size: 0.813rem;

    ${mqmax(desktopMinSize - 1)} {
      font-size: 0.5rem;
    }
  }

  div:has(img) {
    display: flex;
    align-items: center;
    gap: 0.875rem;

    strong {
      color: #8b8b8d;
      font-weight: 400;
      font-size: 0.813rem;

      ${mqmax(desktopMinSize - 1)} {
        font-size: 0.5rem;
        margin-top: 4px;
      }
    }

    img {
      width: 12px;
      aspect-ratio: 1;

      ${mqmax(desktopMinSize - 1)} {
        width: 8px;
      }
    }
  }
`;

export const BlogSingleMainContent = styled.article`
  p {
    font-size: 0.938rem;
    line-height: 30px;
    font-weight: 300;

    ${mqmax(desktopMinSize - 1)} {
      font-size: 0.75rem;
    }

    & > span {
      font-weight: 600;
    }
  }

  ul {
    list-style-type: disc;
  }
  ul > li {
    font-size: 0.938rem;
    line-height: 30px;
    font-weight: 300;
    color: #464646;
    margin-left: 30px;
  }

  ol {
    list-style-type: numeric;
  }

  ol > li {
    font-size: 0.938rem;
    line-height: 30px;
    font-weight: 300;
    color: #464646;
    margin-left: 30px;
  }
  ul > li > span {
    font-weight: 600;
  }

  strong {
    font-size: 1.25rem;
    font-weight: 600;
    color: #17171a;
    display: block;
    margin: 40px 0;

    ${mqmax(desktopMinSize - 1)} {
      line-height: 25px;
    }
  }

  p + p {
    margin-top: 20px;

    ${mqmax(desktopMinSize - 1)} {
      margin-top: 15px;
    }
  }
  iframe {
    max-width: 775px;
    width: 100%;
    height: 435px;
    border-radius: 24px;
    margin-top: 23px;
    margin-bottom: 60px;

    ${mqmax(desktopMinSize - 1)} {
      width: 309px;
      height: 179px;
      border-radius: 15px;
      margin-bottom: 36px;
    }
  }
`;

export const BlogCommentWrapper = styled.form`
  display: flex;
  align-items: center;
  gap: 0.813rem;
  margin-top: 95px;

  & > div:has(img) {
    img {
      width: 46px;
      aspect-ratio: 1;
      border-radius: 100vmax;
      object-fit: cover;

      ${mqmax(desktopMinSize - 1)} {
        width: 27px;
      }
    }
  }

  & > input[type='text'] {
    font-family: 'Poppins';
    max-width: 529px;
    width: 100%;
    height: 46px;
    border: 1px solid #17171a;
    border-radius: 49px;
    padding-left: 26px;

    &::placeholder {
      color: #d9d9d9;
    }
    :focus {
      outline: 0;
      box-shadow: 0 0 0 2px #17171a;
    }

    ${mqmax(desktopMinSize - 1)} {
      max-width: 182px;
      height: 27px;
      padding: 0 0 0 12px;
    }
  }

  & > button {
    max-width: 142px;
    width: 100%;
    height: 46px;
    color: #2d80f2;
    border: 2px solid #2d80f2;
    border-radius: 100vmax;
    background-color: transparent;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.063rem;
    transition: color 300ms, background-color 300ms;

    &:hover {
      color: #fff;
      background-color: #2d80f2;
    }

    ${mqmax(desktopMinSize - 1)} {
      max-width: 91px;
      height: 27px;
      font-size: 0.625rem;
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    margin-top: 45px;
  }
`;

export const BlogSingleHorizontalDivider = styled.div`
  width: 1px;
  height: 1662px;
  background: #ccc;
  margin-top: 320px;

  ${mqmax(desktopMinSize - 1)} {
    display: none;
  }
`;

export const BlogSingleAside = styled.aside`
  max-width: 340px;
  width: 100%;
  margin-top: 310px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  strong {
    font-size: 1.563rem;
    font-weight: 600;
    color: #17171a;

    ${mqmax(desktopMinSize - 1)} {
      font-size: 1.25rem;
    }
  }

  & > div:has(a) {
    margin-top: 39px;

    img {
      max-width: 100%;
      width: 314px;
      height: 149px;
      border-radius: 25px;
      object-fit: cover;
    }

    & > a {
      text-decoration: none;
      display: flex;
      flex-direction: column;

      & > strong {
        font-size: 1.125rem;
        font-weight: 400;
        line-height: 25px;
        color: #17171a;
        display: block;
        margin-top: 20px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;

        ${mqmax(desktopMinSize - 1)} {
          font-size: 0.938rem;
          line-height: 21px;
        }
      }

      & > time {
        margin-top: 10px;
        font-size: 0.813rem;
        color: #8b8b8d;
        opacity: 0.5;

        ${mqmax(desktopMinSize - 1)} {
          font-size: 0.75rem;
          margin-top: 5px;
        }
      }
    }

    & + div {
      margin-top: 39px;
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    margin-top: 46px;
    margin-left: 0;
  }
`;

export const BlogSingleNewsLetterWrapper = styled.div`
  max-width: 982px;
  height: 64px;
  background-color: #fff;
  box-shadow: 0px 9px 59px -21px rgba(0, 0, 0, 0.22);
  border-radius: 35px;
  padding: 47px 140px;
  margin-top: 139px;
`;

export const BlogSingleNewsLetterContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row !important;

  width: 100%;

  & > div:has(strong) {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 0.313rem;

    strong:first-child {
      color: #2d80f2;
      font-weight: 400;
      font-size: 1.563rem;

      span {
        font-weight: 700;
      }
    }
    strong:last-child {
      font-size: 0.938rem;
      color: #17171a;
      font-weight: 300;
    }
  }

  & > form {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    & > input {
      max-width: 241px;
      width: 100%;
      height: 40px;
      border-radius: 27px;
      border: 1.5px solid #8b8b8d;
      padding-left: 18px;
    }

    & > button {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 142px;
      width: 100%;
      height: 40px;
      border: 2px solid #2d80f2;
      color: #2d80f2;
      background-color: transparent;
      border-radius: 49px;
      font-weight: 600;
      font-size: 1.063rem;
      text-transform: uppercase;
      transition: color 300ms, background-color 300ms;

      &:hover {
        color: #fff;
        background-color: #2d80f2;
      }
    }
  }
`;

export const LoadingImage = styled.img`
  margin-top: 200px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  max-width: 100%;
  width: 400px;
  height: 400px;
  object-fit: cover;
`;
