import styled from 'styled-components';
import { desktopMinSize, mqmax } from '@Styles/mixins';
import { mq, mqmin } from '../../../../../../../styles/mixins';

export const GeralWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 2.5rem;
`;

export const HoursButtonWrapper = styled.div`
  display: ${({ isCustomizeOpen }) => (!isCustomizeOpen ? 'none' : 'flex')};
  flex-direction: row;
  align-items: center;
  gap: 0rem;

  .MuiFormControl-root {
    width: 15%;
  }

  .MuiInput-root {
    color: #fff;
    font-size: 2.5rem;
    & input::-webkit-outer-spin-button,
    & input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    & input[type='number'] {
      -moz-appearance: textfield;
    }

    &:focus-within {
      .MuiInput-underline:after {
        border-bottom: 1px solid #426fe5;
      }
    }

    ${mqmax(desktopMinSize - 1)} {
      font-size: 1.1rem;
    }

    ${mq(desktopMinSize + 300, desktopMinSize)} {
      font-size: 1.5rem;
    }
  }
  .MuiInput-underline {
    :before {
      display: none;
    }
    :hover {
      :not(.Mui-disabled) {
        :before {
          display: none;
        }
      }
    }
  }

  .MuiInputBase-input {
    text-align: center;
  }

  .MuiButton-root {
    color: #fff;
    font-size: 2.5rem;
    ${mqmax(desktopMinSize - 1)} {
      font-size: 1.1rem;
    }
    ${mq(desktopMinSize + 300, desktopMinSize)} {
      font-size: 1.5rem;
    }
  }

  .MuiButtonBase-root.Mui-disabled {
    color: #fff;
    opacity: 0.5;
  }

  ${mqmax(desktopMinSize - 1)} {
    justify-content: center;
  }
`;

export const DefaultHoursPlansWrapper = styled.div`
  display: ${({ isCustomizeOpen }) => (isCustomizeOpen ? 'none' : 'flex')};
  flex-direction: row;
  align-items: center;
  gap: 0.8rem;
  width: 100%;

  ${mqmax(desktopMinSize - 1)} {
    justify-content: center;
    flex-wrap: wrap;
  }

  ${mq(desktopMinSize + 300, desktopMinSize)} {
    gap: 1rem;
  }
`;

export const CustomizeButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: transparent;
  padding: 0.2rem;
  width: 150px;
  height: 40px;
  color: #fff;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: #426fe5;
  }

  ${mq(desktopMinSize + 300, desktopMinSize)} {
    width: 80px;
    height: 30px;
    font-size: 0.8rem;

    .MuiSvgIcon-root {
      font-size: 1rem;
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    font-size: 0.75rem;
    justify-content: center;

    .MuiSvgIcon-root {
      font-size: 1rem;
    }
  }
`;

export const DefaultHoursPlansText = styled.p`
  color: #fff;
  font-size: 1rem;
  text-align: center;
`;

export const DefaultHoursPlansDescription = styled.p`
  color: #fff;
  font-size: 1rem;
  font-weight: 300;
  width: 100%;
  margin-bottom: 0.8rem;

  ${mqmin(1900)} {
    font-size: 1.3rem;
    line-height: 1.5rem;
  }

  ${mq(desktopMinSize + 300, desktopMinSize)} {
    font-size: 0.8rem;
  }

  ${mqmax(desktopMinSize - 1)} {
    font-size: 0.9rem;
    text-align: center;
    line-height: 1rem;
  }
`;
