import React from 'react';
import { IMAGES_URL_WEBSITE } from '@Constants';
import { Link } from 'react-router-dom';
import * as S from './styled';

const urlImageContainer = '/container';
const urlImageHeader = '/header';

function SuccessBox({ label1, label2, label3, redirect }) {
  return (
    <S.SuccessBox>
      <S.Desktop>
        <img alt={'sucesso'} src={`${IMAGES_URL_WEBSITE}${urlImageContainer}/done.png`} />
      </S.Desktop>
      <S.Mobile>
        <img alt={'sucesso'} src={`${IMAGES_URL_WEBSITE}${urlImageHeader}/logo-new.png`} />
      </S.Mobile>
      <p className="label1">{label1}</p>
      <p>{label2}</p>
      <p className="label3">{label3}</p>
      <S.Desktop>
        <S.Button component={Link} to={redirect}>
          Voltar
        </S.Button>
      </S.Desktop>
      <S.Mobile>
        <S.Button component={Link} to={redirect}>
          Ir para o login
        </S.Button>
      </S.Mobile>
    </S.SuccessBox>
  );
}

export default SuccessBox;
