import React from 'react';

export const IsLoadingComputerTitle = ({ headerTitle, image_name }) => {
  return (
    <div>
      <strong>
        {headerTitle} <span>{image_name} </span>
      </strong>
      <strong>Estamos com uma alta demanda no momento, por favor, aguarde!</strong>
    </div>
  );
};
