import Typography from '@material-ui/core/Typography';
import React from 'react';
import styled from 'styled-components';

export const ModalTitle = props => <H2 variant="h2" {...props} />;

export default ModalTitle;

const H2 = styled(Typography)`
  &.MuiTypography-h2 {
    margin: 0;
    color: #FFFFFF;
    font-size: calc(20 / 16 * 1rem);
    font-weight: 600;
    line-height: 31px;
    text-align: center;
    letter-spacing: 1px;
  }
`;
