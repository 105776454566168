import React from 'react';
import { LoadingSkeleton } from './styled';

export const Skeleton = ({
  width,
  height,
  top,
  borderRadius,
  mobileTop,
  mobileWidth,
  mobileHeight,
  mobileBorderRadius,
}) => {
  return (
    <LoadingSkeleton
      width={width}
      height={height}
      top={top}
      borderRadius={borderRadius}
      mobileTop={mobileTop}
      mobileWidth={mobileWidth}
      mobileHeight={mobileHeight}
      mobileBorderRadius={mobileBorderRadius}
    />
  );
};
