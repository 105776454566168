import styled from 'styled-components';
import { desktopMinSize, mqmin, rem, mqmax } from '../../styles/mixins';

export const Container = styled.div`
  width: 100%;
  color: #fff;

  ${mqmax(desktopMinSize - 1)} {
    width: 90%;    
    height: none;
    margin: ${rem(0)} auto;
    margin-bottom: 2rem ;
  }

  ${mqmin(desktopMinSize)} {
    height: 100%;
  }
`;

export const CarrouselContainer = styled.div`
  width: 100%;
  color: #fff;
  position: relative;

  
  ${mqmin(desktopMinSize)} {
    padding: 0 0 4rem 0;
  }

  ${mqmax(desktopMinSize - 1)} {
    margin: ${rem(0)} auto;
  }

  h1 {
    font-size: ${rem(47)};
    font-weight: 600;
  }

`;

export const AMISwiperWrapper = styled.div`
  z-index: ${({ step }) => (step === 1 || step === 2 ? '102' : 'unset')};
  position: relative;
  pointer-events: ${({ step }) => (step === 1 || step === 2 ? 'none' : 'unset')};

  & > div > div > div:first-child > div {
    button {
      margin-top: ${({ step }) => (step === 2 ? '23px' : '0')};
      opacity: ${({ step }) => (step === 2 ? '1' : '0')};
      max-height: ${({ step }) => (step === 2 ? '200px' : '0')};
      z-index: ${({ step }) => (step === 2 ? '102' : '-1')};
    }
  }

  & > div > div > div:not(:first-child) > div {
    &::after {
      content: '';
      position: absolute;
      inset: 0;
      background: rgba(0, 0, 0, 0.5);
      width: 100%;
      height: 100%;
      z-index: ${({ step }) => (step === 2 ? '102' : '-1')};
    }
  }

  & > strong {
    font-size: 1.313rem;
    font-weight: 500;
    margin-bottom: 18px;
    display: block;

    ${mqmax(desktopMinSize - 1)} {
      display: none;
    }
  }

  & + & {
    margin-top: 50px;
  }

  ${mqmax(desktopMinSize - 1)} {
    margin-top: 0px !important;
  }
`;

export const TopInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
`;

export const PlanInfoWrapper = styled.div`
  max-width: fit-content;
  padding: 7px 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15px;
  border-radius: 16px;
  border: 1.5px solid #2d80f2;
  & > span {
    color: #2d80f2;
    font-size: 0.938rem;
    font-weight: 600;
  }

  ${mqmax(desktopMinSize - 1)} {
    display: none;
  }
`;

export const GoBackButton = styled.button`
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  gap: 0.2rem;
  font-size: 1.313rem;
  transition: color 350ms;
  font-weight: 300;

  &:hover {
    color: #a00bf6;
  }
`;

export const HelloMobile = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 0.2rem;
`;

export const ContainerLoading = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
`;
