import React from 'react';
import Body from './Body';
import RegisterConfirmationBox from './RegisterConfirmationBox';
import HeaderNew from '@Containers/Header-new';
import Desktop from './Desktop';

function RegisterConfirmationPage() {

  return (
    <Body>
      <Desktop>
        <HeaderNew />
      </Desktop>
      <RegisterConfirmationBox />
    </Body>
  );
}

export default RegisterConfirmationPage;
