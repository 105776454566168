import React from 'react';

import { Button } from '@Components/Public';
import Icon from '../Icon';

import Svg from './Svg';

export const Discord = () => (
  <OutlinedButton href="https://discord.com/invite/noar-798022821342609418" startIcon={<DiscordIcon aria-label="Discord" />}>
    Entrar
  </OutlinedButton>
);

export default Discord;

function OutlinedButton(props) {
  return <Button variant="outlined" {...props} />;
}

const DiscordIcon = Icon(Svg);
