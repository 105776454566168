import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import * as S from './styled';
import { desktopMinSize } from '../../../../styles/mixins';

const DatePickerContent = ({ initialDate, setInitialDate, endDate, setEndDate }) => {
    return (
      <S.DatePickerWrapper>
        { 
            window.innerWidth < desktopMinSize 
            ?
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <S.DatePickerMobileStyled label="Data Inicial" maxDate={new Date()} format='dd/MM/yyyy' value={initialDate} onChange={(newValue) => setInitialDate(newValue)}/>
                <S.DatePickerMobileStyled label="Data final" format='dd/MM/yyyy' value={endDate} onChange={(newValue) => setEndDate(newValue)}/>
              </LocalizationProvider>
            : 
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <S.DatePickerDesktopStyled label="Data Inicial" maxDate={new Date()} format='dd/MM/yyyy' value={initialDate} onChange={(newValue) => setInitialDate(newValue)}/>
                <S.DatePickerDesktopStyled label="Data final" format='dd/MM/yyyy' value={endDate} onChange={(newValue) => setEndDate(newValue)}/>
              </LocalizationProvider>
          }     
      </S.DatePickerWrapper>
    )
}

export default DatePickerContent;