import React from 'react';
import Container from './Container';
import Content from './Content';

function MaintenancePage() {

  return (
    <Container>
      <Content />
    </Container>
  );
}

export default MaintenancePage;