import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    row-gap: calc(10 / 16 * 1rem);
    display: flex;
    flex-direction: column;
`;

export default Wrapper;
