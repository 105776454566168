import styled from 'styled-components';
import { desktopMinSize, mq, mqmax } from '../../../../../styles/mixins';

export const UseTypeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 0.8rem;
  width: 100%;
  height: -webkit-fill-available;

  ${mqmax(desktopMinSize - 1)} {
    gap: 0.2rem;
  }
`;

export const TypeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.8rem;
  padding: 1rem;
  transition: all 0.2s ease-in-out;
  background: ${({ selected }) => (selected ? 'rgba(0, 0, 0, 0.4)' : 'rgba(23, 23, 26, 0.7)')};
  border-radius: 1.5rem;
  position: relative;
  cursor: pointer;
  border: ${({ selected }) => (selected ? '2px solid #2d80f2' : '2px solid #333')};
  transform: ${({ selected }) => (selected ? 'scale(1.05)' : 'scale(1)')};
  width: 100%;
  ${mq(1400, desktopMinSize)} {
    min-height: 2rem;
  }

  & > img {
    width: 30%;
  }

  & > h3 {
    font-size: 1.2rem;
    font-weight: 700;
    text-align: center;
    line-height: 1.5rem;
    ${mqmax(desktopMinSize - 1)} {
      font-size: 0.8rem;
    }
  }

  &:hover {
    transform: scale(1.05);
    border: 2px solid #2d80f2;
  }

  ${mqmax(desktopMinSize - 1)} {
    padding: 0.5rem;
    transform: ${({ selected }) => (selected ? 'scale(1)' : 'none')};
    border-radius: 1rem;
  }
`;

export const ResultTitle = styled.h2`
  font-size: 3rem;
  font-weight: 700;
  text-align: center;
  ${mqmax(desktopMinSize - 1)} {
    font-size: 1.5rem;
  }
`;
