import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import MuiButton from '@material-ui/core/Button';
import { defaultFont, mqmax, mqmin, desktopMinSize } from '@Styles/mixins';

export const Footer = () => (
  <Button component={Link} to="/password/forgot">
    Voltar
  </Button>
);

export default Footer;

const Button = styled(MuiButton)`
  &.MuiButton-root {
    max-width: fit-content;
    width: 100%;
    font-family: ${defaultFont};
    text-transform: none;
    font-style: normal;
    text-align: center;
    line-height: 20px;
    transition: color calc(1s / 3) ease-in-out;
    margin: 0 auto;

    ${mqmin(desktopMinSize)} {
      font-weight: 300;
      color: #fff;
      font-size: 0.9375rem;
      &:hover {
        text-decoration: underline;
        color: #a00bf6;
      }
    }
    ${mqmax(desktopMinSize - 1)} {
      font-weight: 500;
      color: #a00bf6;
      font-size: calc(12 / 16 * 1rem);
    }
  }
`;
