import styled from 'styled-components';
import { mqmax, desktopMinSize } from '@Styles/mixins';

export const Container = styled.div`
  width: 100%;
  z-index: 2;
  position: relative;
  border: double 3px transparent;
  border-radius: calc(40 / 16 * 1rem);
  background-image: linear-gradient(#21222a, #21222a), radial-gradient(circle at top left, #2d80f2, #a00bf6);
  background-origin: border-box;
  background-clip: content-box, border-box;
  top: 0;
  transition: top 350ms;
  width: calc(749 / 16 * 1rem);
  height: calc(465 / 16 * 1rem);
  ${mqmax(desktopMinSize - 1)} {
    width: calc(336 / 16 * 1rem);
    height: calc(546 / 16 * 1rem);
    border-radius: calc(30 / 16 * 1rem);
  }
`;

export default Container;
