import React from 'react';
import { AMIModalContainer, AMIModalTitle } from './styled';

export const AMIFilterModal = ({ onChange, options, checkeds, isFilterModalOpen }) => {
  return (
    <AMIModalContainer isFilterModalOpen={isFilterModalOpen}>
      {options.map(option => (
        <AMIModalTitle key={option}>
          {option}
          <input
            id={option}
            type="checkbox"
            onChange={onChange}
            checked={checkeds.find(item => item === option + '') == null ? false : true}
          />
          <span></span>
        </AMIModalTitle>
      ))}
    </AMIModalContainer>
  );
};
