import React, { useState, useEffect } from 'react';
import { useTutorialStep } from '../../../context/TutorialStepContext';
import {
  TutorialBackdrop,
  ShopPageInitialStepContainer,
  ShopPageInitialStepWrapper,
  ShopPageInitialStepButtonWrapper,
} from '../styled';

export const InitialStep = () => {
  const { handleNextStep, handleSkipTutorial, state } = useTutorialStep();
  const [isFirstAccess, setIsFirstAccess] = useState(false);

  useEffect(() => {
    var firstAccess = JSON.parse(localStorage.getItem('firstAccess'));
    if (firstAccess && firstAccess[0]?.initialShopPageSteps) {
      setIsFirstAccess(false);
    } else {
      setIsFirstAccess(true);
      localStorage.setItem(
        'firstAccess',
        JSON.stringify([
          {
            initialAMISteps: true,
            sectionPageSteps: true,
            programSteps: true,
            initialShopPageSteps: true,
            shopPageSteps: true,
          },
        ]),
      );
    }
  }, []);

  if (!isFirstAccess) return null;

  switch (state[3].step) {
    case 0:
      return (
        <>
          <TutorialBackdrop />
          <ShopPageInitialStepContainer>
            <ShopPageInitialStepWrapper>
              <strong>
                Esta é a
                <br />
                loja da <span>noar</span>
              </strong>
              <p>
                Aqui você encontrará os <span>nossos produtos e planos</span>, vamos ver como adicionar horas no seu
                perfil?
              </p>

              <ShopPageInitialStepButtonWrapper>
                <button onClick={() => handleNextStep('shop-page')}>Começar</button>
                <button onClick={() => handleSkipTutorial('shop-page')}>Pular tutorial</button>
              </ShopPageInitialStepButtonWrapper>
            </ShopPageInitialStepWrapper>
          </ShopPageInitialStepContainer>
        </>
      );

    default:
      return null;
  }
};
