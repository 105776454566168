import React from 'react';
import { ShopPageFourthStepWrapper, ShopPageFourthStepContentWrapper, ShopPageFourthStepButtonWrapper } from './styled';
import { TutorialBackdrop } from '../styled';
export const FourthStep = ({ nextStep, skipTutorial }) => {
  return (
    <>
      <TutorialBackdrop />
      <ShopPageFourthStepWrapper>
        <ShopPageFourthStepContentWrapper>
          <strong>
            Aqui você pode <span>adicionar quantos pacotes quiser</span> antes de finalizar.
          </strong>
          <ShopPageFourthStepButtonWrapper>
            <button onClick={nextStep}>Entendi</button>
            <button onClick={skipTutorial}>Fechar tutorial</button>
          </ShopPageFourthStepButtonWrapper>
        </ShopPageFourthStepContentWrapper>
      </ShopPageFourthStepWrapper>
    </>
  );
};
