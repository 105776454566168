import { Button as MuiButton } from '@material-ui/core';
import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { desktopMinSize, mq, mqmin } from '../../../../styles/mixins';

function Div({ navigation, setActualPath }, props) {
  return (
    <div {...props}>
      {links.map(({ path, text }) =>
        path == '/for-business' || path == '/faq' ? (
          <TextButton
            component={NavLink}
            key={path}
            to={path}
            onClick={() => {
              window.location.hash = '';
            }}
          >
            {text}
          </TextButton>
        ) : (
          <TextButton onClick={navigation(path, setActualPath)} href={path}>
            {text}
          </TextButton>
        ),
      )}
      <Button component={Link} to="/login">
        Acesse
      </Button>
    </div>
  );
}

const links = [
  { path: '#planos', text: 'Escolha seu plano' },
  { path: '#como-usar', text: 'Como usar?' },
  { path: '#download', text: 'Baixar' },
  { path: '/for-business', text: 'Para Empresas' },
  { path: '/faq', text: 'FAQ' },
];

const Button = styled(MuiButton)`
  &.MuiButton-root {
    border: solid #2d80f2 calc(2rem / 16);
    border-radius: calc(24rem / 16);
    padding: calc(9rem / 16) calc(30rem / 16);
    color: #2d80f2;
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
    font-weight: bold;
    line-height: calc(18 / var(--fontSize));
    transition: ${['color', 'background'].map(prop => `${prop} 300ms ease-out`).join(', ')};

    &.Mui-disabled {
      cursor: not-allowed;
      color: #2d80f2;
      border: solid #2d80f2 calc(2rem / 16);
      border-radius: calc(24rem / 16);
    }

    &:hover {
      color: #fff;
      background: #2d80f2;
    }

    ${mqmin(desktopMinSize)} {
      --fontSize: 17;

      line-height: calc(26 / var(--fontSize));
    }

    ${mq(desktopMinSize + 400, 1350)} {
      --fontSize: 12;
      line-height: calc(26 / var(--fontSize));
    }
  }
`;

const TextButton = styled(MuiButton)`
  &.MuiButton-root {
    color: rgb(23, 23, 26) !important;
    font-size: calc(17rem / 16);
    font-weight: normal;
    line-height: ${28 / 17};
    text-transform: none;
    transition: color 300ms ease-out;

    &.MuiButton-text {
      padding: 0 2.5rem !important;
    }

    &:hover {
      color: #2d80f2 !important;
      background: transparent;
    }
  }
  ${mq(desktopMinSize + 400, 1350)} {
    &.MuiButton-root {
      font-size: calc(15rem / 16);

      &.MuiButton-text {
        padding: 0 2rem !important;
      }
    }
  }

  ${mq(1350, desktopMinSize)} {
    &.MuiButton-root {
      font-size: calc(12rem / 16);

      &.MuiButton-text {
        padding: 0 1rem !important;
      }
    }
  }
`;

export const Desktop = styled(Div)`
  display: flex;
  column-gap: calc(18rem / 16);

  ${Button} {
    margin-left: calc(12rem / 16);
  }
`;

export default Desktop;
