import styled from 'styled-components';
import MuiButton from '@material-ui/core/Button';
import { mqmin, mqmax, desktopMinSize, headerZIndex, defaultFont } from '@Styles/mixins';

export const SuccessBox = styled.section`
  width: 100%;
  max-width: 35vh;
  height: auto;
  position: relative;
  padding: 75px 60px 75px 60px;
  z-index: ${headerZIndex - 1};
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  flex-direction: column;

  img {
    width: 16%;
    height: auto;
    margin: 0 0 30px 0;
    ${mqmax(desktopMinSize - 1)} {
      width: 60%;
    }
  }

  p {
    font-family: ${defaultFont};
    font-style: normal;
    font-size: 3.5vh;
    font-weight: 800;
    margin: 9px 0 0;
    color: #fff;
    white-space: nowrap;

    &.label1 {
      font-weight: 300;
    }

    &.label3 {
      color: #2d80f2;
    }
  }
`;

export const Button = styled(MuiButton)`
  &.MuiButton-root {
    width: 100%;
    font-family: ${defaultFont};
    text-transform: none;
    font-weight: 300;
    font-style: normal;
    color: #fff;
    text-align: center;
    line-height: 20px;
    font-size: calc(11 / 16 * 1rem);
    transition: color calc(1s / 3) ease-in-out;
    margin: 20px 0 0 0;
    font-size: 0.9375rem;
    &:hover {
      text-decoration: underline;
      color: #a00bf6;
    }
    ${mqmax(desktopMinSize - 1)} {
      color: #a00bf6;
      font-weight: 500;
    }
  }
`;

export const Mobile = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${mqmin(desktopMinSize)} {
    display: none;
  }
`;

export const Desktop = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${mqmax(desktopMinSize - 1)} {
    display: none;
  }
`;
