import styled from 'styled-components';

import { mqmin, desktopMinSize } from '@Styles/mixins';

export const Wrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
  ${mqmin(desktopMinSize)} {
    height: 70%;
  }
`;

export default Wrapper;
