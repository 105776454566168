import React from 'react';
import { DownloadPageContentWrapper } from './styled';

export const DownloadContent = ({ image, name, link }) => {
  return (
    <DownloadPageContentWrapper>
      <div>
        <img src={image} alt="" />
        <strong>{name}</strong>
      </div>
      <a href={link} target="_blank" rel="noreferrer">
        download
      </a>
    </DownloadPageContentWrapper>
  );
};
