import React from 'react';
import Body from './Body';
import CreatePasswordBox from './CreatePasswordBox';
import HeaderNew from '@Containers/Header-new';
import Desktop from './Desktop';

function CreatePassword() {

  return (
    <Body>
      <Desktop>
        <HeaderNew />
      </Desktop>
      <CreatePasswordBox />
    </Body>
  );
}

export default CreatePassword;
