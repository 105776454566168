import { useEffect, useState } from 'react';
import { Button } from '../../../../../components/Public';
import { ButtonsWrapper } from '../styled';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const Buttons = ({
  handlePreviousStep,
  handleNextStep,
  step,
  useType,
  gameFrequency,
  saveProgressComputerGames,
  gameSessionTime,
  studyMoments,
  differentStudySoftware,
  storeFilesStudy,
  useNoarProfessional,
  professionalStorage,
  storeFilesActivities,
  usedNoarActivities,
  softwareDifferentActivities,
  submitForm,
  result,
}) => {
  const [isActivated, setIsActivated] = useState(false);

  const history = useHistory();

  useEffect(() => {
    setIsActivated(false);
    if (step === 2 && useType) {
      setIsActivated(true);
    } else if (
      step === 3 &&
      ((useType === 'PLAY' && gameFrequency) ||
        (useType === 'STUDY' && studyMoments !== undefined) ||
        (useType === 'WORK' && useNoarProfessional) ||
        (useType === 'MISCELLANEOUS_ACTIVITIES' && usedNoarActivities !== undefined))
    ) {
      setIsActivated(true);
    } else if (
      step === 4 &&
      ((useType === 'PLAY' && saveProgressComputerGames) ||
        (useType === 'STUDY' && differentStudySoftware !== undefined) ||
        (useType === 'WORK' && professionalStorage) ||
        (useType === 'MISCELLANEOUS_ACTIVITIES' && softwareDifferentActivities !== undefined))
    ) {
      setIsActivated(true);
    } else if (
      step === 5 &&
      ((useType === 'PLAY' && gameSessionTime) ||
        (useType === 'STUDY' && storeFilesStudy !== undefined) ||
        (useType === 'MISCELLANEOUS_ACTIVITIES' && storeFilesActivities !== undefined))
    ) {
      setIsActivated(true);
    }
  }, [
    step,
    useType,
    gameFrequency,
    saveProgressComputerGames,
    gameSessionTime,
    studyMoments,
    differentStudySoftware,
    storeFilesStudy,
    useNoarProfessional,
    professionalStorage,
    storeFilesActivities,
    usedNoarActivities,
    softwareDifferentActivities,
  ]);

  const handlePublicRedirect = () => {
    switch (result) {
      case 'UNIT':
        history.push('/planos/unit');
        break;
      case 'CLASSIC':
        history.push('/planos/classic');
        break;
      case 'PERSONAL':
        history.push('/planos/personal');
        break;
    }
  };

  return result === undefined ? (
    <ButtonsWrapper>
      <Button variant="contained" onClick={() => handlePreviousStep()}>
        voltar
      </Button>
      {step === 5 || (step === 4 && useType === 'WORK') ? (
        <Button variant="contained" onClick={submitForm} disabled={!isActivated}>
          Ver resultado
        </Button>
      ) : (
        <Button variant="contained" onClick={() => handleNextStep()} disabled={!isActivated}>
          Próximo
        </Button>
      )}
    </ButtonsWrapper>
  ) : (
    <ButtonsWrapper>
      <Button
        variant="contained"
        home_modal={true}
        onClick={() => {
          history.push('/planos/comparacao');
        }}
      >
        Comparar planos
      </Button>
      <Button variant="contained" home_modal={true} onClick={handlePublicRedirect}>
        Comprar
      </Button>
    </ButtonsWrapper>
  );
};

export default Buttons;
