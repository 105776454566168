import React, { forwardRef, useState } from 'react';

import { useHeight } from '@Utils/hooks';
import ConfirmationLightbox from '@Containers/ConfirmationLightbox';
import Div from './Div';
import Nav from './Nav';

import * as S from './styled';

const PrivateNavigationView = forwardRef(({ height, isOpen, setIsOpen }, ref) => (
  <>
    <S.Header ref={ref}>
      <Nav containerHeight={height} isOpen={isOpen} setIsOpen={setIsOpen} />
      <Div />
    </S.Header>
    <ConfirmationLightbox />
  </>
));

function useDisclosure() {
  const [isOpen, setIsOpen] = useState(false);

  function onToggle() {
    setIsOpen(isOpen => !isOpen);
  }

  return { isOpen, onToggle, setIsOpen };
}

function PrivateNavigation() {
  return <PrivateNavigationView {...useDisclosure()} {...useHeight()} />;
}

export default PrivateNavigation;
