import React from 'react';
import Body from './Body';
import RegisterBox from './RegisterBox';
import Desktop from './Desktop';
import HeaderNew from '@Containers/Header-new';

function RegisterPage() {

  return (
    <Body>
      <Desktop>
        <HeaderNew />
      </Desktop>
      <RegisterBox />
    </Body>
  );
}

export default RegisterPage;
