import React from 'react';
import styled from 'styled-components';
import { desktopMinSize, mqmax } from '@Styles/mixins';

import slideImg from '@Assets/6.webp';
import mobileSlideImg from '@Assets/6-mobile.webp';

export const SixthStep = () => {
  return (
    <>
      <div>
        {window.innerWidth < desktopMinSize ? <img src={mobileSlideImg} alt="" /> : <img src={slideImg} alt="" />}
      </div>
      <SixthStepDescription>
        <span>6.</span> <span>Copie o IP gerado</span> para sua conexão e cole o mesmo no campo do Moonlight.
      </SixthStepDescription>
    </>
  );
};

const SixthStepDescription = styled.strong`
  text-align: left !important;
  font-size: 0.875rem !important;
  line-height: 1.5 !important;

  ${mqmax(desktopMinSize - 1)} {
    font-size: 0.75rem !important;
  }
`;
