import React from 'react';
import styled from 'styled-components';
import { desktopMinSize, mqmax } from '@Styles/mixins';

import slideImg from '@Assets/2.webp';
import mobileSlideImg from '@Assets/2-mobile.webp';

export const SecondStep = () => {
  return (
    <>
      <div>
        {window.innerWidth < desktopMinSize ? <img src={mobileSlideImg} alt="" /> : <img src={slideImg} alt="" />}
      </div>
      <SecondStepDescription>
        <span>2.</span> Para ligar seu computador NOAR basta <span>selecionar seu plano </span>e{' '}
        <span>escolher uma biblioteca</span> de inicialização.
      </SecondStepDescription>
    </>
  );
};

const SecondStepDescription = styled.strong`
  text-align: left !important;
  font-size: 0.875rem !important;
  line-height: 1.5 !important;

  ${mqmax(desktopMinSize - 1)} {
    font-size: 0.75rem !important;
  }
`;
