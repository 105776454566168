import styled from 'styled-components';

import { mqmax, mqmin, desktopMinSize } from '@Styles/mixins';

export const Form = styled.form`
  width: 100%;
  height: auto;
  grid-gap: calc(15 / 16 * 1rem);
  ${mqmax(desktopMinSize - 1)} {
    display: flex;
    flex-direction: column;
    grid-gap: calc(10 / 16 * 1rem);
  }
  ${mqmin(desktopMinSize)} {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

export default Form;
