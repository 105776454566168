export class SessionExpired extends Error {
  constructor() {
    super('Client session expired');
  }
}

export class RegisterError extends Error {
  status;
  constructor(message, status) {
    super('Error in register');
    this.message = message;
    this.status = status;
  }
}

export class RegisterConfirmationError extends Error {
  constructor(message) {
    super('Error in register confirmation');
    this.message = message;
  }
}

export class ResendRegisterConfirmationEmailError extends Error {
  constructor(message) {
    super('Error while resending email confirmation');
    this.message = message;
  }
}

export class LoginError extends Error {
  constructor(message) {
    super('Error in login');
    this.message = message;
  }
}

export class CreateInstanceError extends Error {
  constructor(message) {
    super('Error on create instance');
    this.message = message;
  }
}

export class CreateNpsAnswer extends Error {
  constructor(message) {
    super('Error on create Nps Answer');
    this.message = message;
  }
}

export class UseCoupon extends Error {
  constructor(message) {
    super('Error on Use Coupon');
    this.message = message;
  }
}

export class SendTermsAccepted extends Error {
  constructor(message) {
    super('Error on SendTermsAccepted');
    this.message = message;
  }
}

export class SaveUserInfo extends Error {
  constructor(message) {
    super('Error on Save User info');
    this.message = message;
  }
}

export class UpdatePassword extends Error {
  constructor(message) {
    super('Error on Update password');
    this.message = message;
  }
}

export class BuyProduct extends Error {
  constructor(message) {
    super('Error on Buy product');
    this.message = message;
  }
}

export class ConfirmBuyProduct extends Error {
  constructor(message) {
    super('Error on Confirm buy product');
    this.message = message;
  }
}

export class B2bActionError extends Error {
  constructor(message) {
    super('Error on B2b action');
    this.message = message;
  }
}

export class PlansFormError extends Error {
  constructor(message) {
    super('Error on Plans Form');
    this.message = message;
  }
}

export class PurchaseBlockedFor24Hours extends Error {
  constructor(message) {
    super('Error on check purchase Form');
    this.message = message;
  }
}
