import styled from 'styled-components';

import { Link as RouterLink } from 'react-router-dom';

import { desktopMinSize, rem, mqmax, mqmin } from '@Styles/mixins';

import { CreateTooltip } from '../Tooltip/styled';
import { mq } from '../../../styles/mixins';

export const Div = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: 1rem;

  ${mqmax(desktopMinSize - 1)} {
    justify-content: center;
    display: none;
  }
`;

export const Icon = styled.img`
  display: flex;
  max-width: 100%;
  width: ${rem(16)};
  height: ${rem(18)};

  ${mqmax(desktopMinSize - 1)} {
    width: ${rem(12)};
    height: ${rem(14)};
  }
  & {
    background-color: transparent;
  }
`;

export const HeaderIcon = styled(Icon)`
  cursor: pointer;
`;

export const Time = styled(RouterLink)`
  color: #fff;
  font-family: 'Poppins', sans-serif;
  font-size: 0.8125rem !important;
  font-weight: 600;
  text-align: center;
  text-decoration: none;

  ${mq((desktopMinSize + 300), desktopMinSize)} {
    font-size: 0.7rem !important;
  }

  ${mqmin(desktopMinSize)} {
    font-size: ${rem(12)};
    line-height: ${15 / 12};
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
`;

export const ProfileButton = styled.button`
  width: 172px;
  height: 37px;
  border: 1px solid #426FE5;
  border-radius: 100px;
  background-color: ${({isOnProfile}) => (isOnProfile ? '#426FE5' :  'transparent')};
  color: #FFFFFF;
  font-size: 0.8125rem !important;
  text-align: center;
  cursor: pointer;

  &:hover {
    background: #426FE5;
  }
`;

export const LogoutButton = styled.button`
  width: 75px;
  height: 37px;
  border: 1px solid #426FE5;
  border-radius: 100px;
  background-color: #426FE5;
  color: #FFFFFF;
  font-size: 0.8125rem !important;
  text-align: center;
  cursor: pointer;

  &:hover {
    background: #a00bf6;
    border-color: #a00bf6;
    color: #fff;
  }
`;

export const Tooltip = CreateTooltip(Time);
