import styled from 'styled-components';
import { desktopMinSize, mqmax } from '@Styles/mixins';

export const HowToUseAMIWrapper = styled.dialog`
  max-width: 459px;
  width: 100%;
  height: 559px;
  border-radius: 35px;
  background-color: rgba(33, 34, 42, 1);
  box-shadow: 0 0 0 100vw rgba(0, 0, 0, 0.74);
  border: 0;
  overflow: unset;

  &[open] {
    animation: slide-up 350ms forwards, fade-in 350ms forwards;
  }

  &[closing] {
    display: block;
    pointer-events: none;
    inset: 0;
    animation: slide-down 350ms forwards, fade-out 350ms forwards;
  }

  &::before {
    content: '';
    position: absolute;

    inset: 0;
    border-radius: 35px;
    padding: 3px;
    background: linear-gradient(297deg, #2d80f2 0%, #9b0cee 100%);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;

    ${mqmax(desktopMinSize - 1)} {
      border-radius: 19px;
    }
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 414px;
    width: 100%;
    height: 559px;
    margin: 0 auto;

    gap: 1.5rem;
    strong {
      text-align: center;
      font-size: 1.75rem;
      color: #fff;
      font-weight: 300;
      line-height: 1.3;

      & > span {
        font-weight: 600;
      }
    }

    & > div:has(img) {
      max-width: 100%;
      width: 430px;
      height: 400px;
      border-radius: 20px;

      & > img {
        max-width: 100%;
        width: 430px;
        height: 400px;
        object-fit: cover;
        border-radius: 20px;
      }
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    max-width: 263px;
    height: 370px;
    border-radius: 19px;

    & > div {
      max-width: 244px;
      height: 370px;
      gap: 0.5rem;

      strong {
        font-size: 0.938rem;
        line-height: 1.5;
      }

      & > button {
        top: 0.7rem;
        right: 0.7rem;
      }

      & > div:has(img) {
        width: 244px;
        height: 250px;
        border-radius: 19px;

        & > img {
          width: 244px;
          height: 250px;
          border-radius: 19px;
        }
      }
    }
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes slide-up {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0%);
    }
  }

  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes slide-down {
    0% {
      transform: translateY(0%);
    }
    100% {
      transform: translateY(100%);
    }
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  border: 0;
  line-height: 0;
  background: transparent;
  color: #fff;

  ${mqmax(desktopMinSize - 1)} {
    top: 0.7rem;
    right: 0.7rem;
  }
`;

const ButtonStyle = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: 2px solid #fff;
  background: transparent;
  border-radius: 9999px;
  width: 28px;
  aspect-ratio: 1;

  ${mqmax(desktopMinSize - 1)} {
    width: 20px;
    border-width: 1px;
    top: 92%;
    transform: translateY(0%);
  }
`;

export const NextButton = styled(ButtonStyle)`
  right: -9%;

  ${mqmax(desktopMinSize - 1)} {
    right: 5%;
  }
`;

export const PreviousButton = styled(ButtonStyle)`
  left: -9%;

  ${mqmax(desktopMinSize - 1)} {
    left: 5%;
  }
`;

export const HowToUseBulletsWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 92%;
  gap: 0.5rem;

  ${mqmax(desktopMinSize - 1)} {
    top: 95%;
  }
`;

export const HowToUseBullets = styled.span`
  width: 5px;
  aspect-ratio: 1;
  opacity: ${({ currentStepIndex }) => (currentStepIndex ? 0.5 : 0.1)};
  background: #fff;
  cursor: pointer;
  border-radius: 9999px;
  transform: ${({ currentStepIndex }) => (currentStepIndex ? 'scale(1.5)' : 'scale(1)')};

  ${mqmax(desktopMinSize - 1)} {
    width: 3px;
    transform: ${({ currentStepIndex }) => (currentStepIndex ? 'scale(2)' : 'scale(1)')};
  }
`;
