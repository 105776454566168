import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    z-index: 2;
    position: relative;
    border-radius: calc(64 / 16 * 1rem);
    background: transparent linear-gradient(139deg, #426FE5 0%, #A00BF6 100%) 0% 0% no-repeat padding-box;
    max-width: calc(640 / 16 * 1rem);
`;

export default Container;
