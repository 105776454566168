import { Slider} from '@material-ui/core';
import { AssembleInfoWrapper, AssemblePhaseTitle, InfoPriceValue, InfoValue, InfoValueWrapper } from '../../styled';
import { Observation } from '../../../../../../../pages/HomePage/Intro/Content';
const StorageAssemblePhase = ({
  setStoragePrice,
  storagePrice,
  products,
  storageValue,
  setStorageValue,
  storagePrices,
}) => {

  const handleStorageChange = (event, newValue) => {
    setStorageValue(newValue);
    setStoragePrice(storagePrices[newValue]);
  };

  function valueStorageText(value) {
    return `${value}GB`;
  }

  return (
    <AssembleInfoWrapper>
      <AssemblePhaseTitle>Para começar, selecione o armazenamento:</AssemblePhaseTitle>
      <Observation>*O sistema operacional ocupa, em média, 32GB.</Observation>
      <Slider
        defaultValue={100}
        getAriaValueText={valueStorageText}
        aria-labelledby="discrete-slider-custom"
        step={100}
        valueLabelDisplay="auto"
        marks
        min={100}
        max={500}
        value={storageValue}
        onChange={handleStorageChange}
      />
      <InfoValueWrapper>
        <InfoValue>{storageValue} GB</InfoValue>
        <InfoPriceValue>R${storagePrice.toFixed(2)}</InfoPriceValue>
      </InfoValueWrapper>
    </AssembleInfoWrapper>
  );
};

export default StorageAssemblePhase;
