import React from 'react';
import styled from 'styled-components';

import { Title, Subtitle } from '@Components/Public';

export function Header() {
  return (
    <Container>
      <Title as="h1">
        <strong>
          <dfn>
            <abbr title="Perguntas Frequentes">FAQ</abbr>
          </dfn>
        </strong>
      </Title>
      <Subtitle>
        Confira as perguntas mais feitas na <span className="uppercase">Noar</span>.
      </Subtitle>
    </Container>
  );
}

export default Header;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: calc(10rem / 16);
`;
