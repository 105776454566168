import { BiFilter } from 'react-icons/bi';
import { HiOutlineSearch } from 'react-icons/hi';
import { AMIFilterModal } from '@Components/AMICarrousel/AMIFilterModal';
import { AiOutlineQuestion } from 'react-icons/ai';
import { ToViewTutorialModal } from '@Components/AMITutorial/ToViewTutorialModal';
import { FilterInput } from '@Components/AMICarrousel/styled';
import { useComputerLoadingContext } from '../../../Computer/ComputerLoadingContext';
import { useState } from 'react';

const FilterInputWrapper = ({ handleWatchCheckedFilterCheckbox, checked, searchedNameValue, setSearchedNameValue }) => {
  const { AMIs } = useComputerLoadingContext();
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isToViewModalOpen, setIsToViewModalOpen] = useState(false);

  return (
    <FilterInput>
      <input
        id="filter-input"
        placeholder="Digite aqui"
        onChange={e => setSearchedNameValue(e.target.value)}
        value={searchedNameValue}
      />
      <label htmlFor="filter-input">
        <HiOutlineSearch />
      </label>
      <div>
        <button onClick={() => setIsFilterModalOpen(prevState => !prevState)}>
          <BiFilter />
        </button>

        <AMIFilterModal
          options={AMIs.map(ami => {
            return ami.category;
          })}
          onChange={handleWatchCheckedFilterCheckbox}
          checkeds={checked}
          isFilterModalOpen={isFilterModalOpen}
        />
      </div>

      <div>
        <button onClick={() => setIsToViewModalOpen(prevState => !prevState)}>
          <AiOutlineQuestion />
        </button>
      </div>
      <ToViewTutorialModal
        isToViewModalOpen={isToViewModalOpen}
        onRequestClose={() => setIsToViewModalOpen(prevState => !prevState)}
      />
    </FilterInput>
  );
};

export default FilterInputWrapper;
