import React from 'react';
import {
  ShopPageSeventhStepWrapper,
  ShopPageSeventhStepContentWrapper,
  ShopPageSeventhStepButtonWrapper,
} from './styled';
import { TutorialBackdrop } from '../styled';
export const SeventhStep = ({ nextStep, skipTutorial }) => {
  return (
    <>
      <TutorialBackdrop />
      <ShopPageSeventhStepWrapper>
        <ShopPageSeventhStepContentWrapper>
          <strong>
            Ao clicar em <span>finalizar</span> sua compra basta inserir suas informações de compra que{' '}
            <span>suas horas serão automáticamente adicionadas.</span>
          </strong>
          <ShopPageSeventhStepButtonWrapper>
            <button onClick={nextStep}>Entendi</button>
            <button onClick={skipTutorial}>Fechar tutorial</button>
          </ShopPageSeventhStepButtonWrapper>
        </ShopPageSeventhStepContentWrapper>
      </ShopPageSeventhStepWrapper>
    </>
  );
};
