import styled from 'styled-components';

import { defaultFont, desktopMinSize, mqmax, rem } from '@Styles/mixins';

export const CreateTooltip = function (Container) {
  return styled.span`
    margin-left: 1.5rem;
    pointer-events: none;
    border-radius: ${rem(12)};
    padding: ${rem(15)};
    position: absolute;
    left: 100%;
    z-index: 99999;
    color: #fff;
    font-family: ${defaultFont};
    font-size: calc(14 / 16 * 1rem);
    font-weight: 400;
    line-height: 22px;
    opacity: 0;
    transform: translateX(${rem(16)});
    transition: ${['opacity', 'transform'].map(prop => `${prop} ${1 / 3}s ease-in-out`).join(', ')},
    pointer-events 0s ${1 / 3}s;
    background-color: #2d80f2;
    width: 250px;
    ${Container}:hover & {
      opacity: 1;
      transform: translateX(0);
      pointer-events: all;
      transition-delay: 0s;
    }

    &:before {
      content: '';
      display: block;
      height: 0;
      width: 0;
      border: solid transparent ${rem(7)};
      position: absolute;
      top: 50%;
      right: 100%;
      transform: translateY(-50%);
      border-right-color: #2d80f2;
    }

    ${mqmax(desktopMinSize - 1)} {
      display: none;
    }
  `;
};
