import React from 'react';
import styled from 'styled-components';
import MuiButton from '@material-ui/core/Button';
import { defaultFont } from '@Styles/mixins';

export const BackButton = props => (
  <Button type="button" {...props}>
    Voltar
  </Button>
);

export default BackButton;

const Button = styled(MuiButton)`
  &.MuiButton-root {
    width: 100%;
    font-family: ${defaultFont};
    text-transform: none;
    font-style: normal;
    text-align: center;
    line-height: 20px;
    transition: color calc(1s / 3) ease-in-out;
    font-weight: 500;
    color: #a00bf6;
    font-size: calc(12 / 16 * 1rem);
  }
`;
