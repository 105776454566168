import React from 'react';

import Container from './Container';
import Header from './Header';
import Content from './Content';

export const PasswordForgotBox = () => (
  <Container>
    <Header />
    <Content />
  </Container>
);

export default PasswordForgotBox;
