import React, { useState, useEffect } from 'react';
import { useTutorialStep } from '../../../context/TutorialStepContext';
import { IntroAMITutorial } from './IntroAMITutorial';
import { FirstStep } from './FirstStep';
import { SecondStep } from './SecondStep';

export const InitialSteps = () => {
  const { handleNextStep, handleSkipTutorial, state } = useTutorialStep();
  const [isFirstAccess, setIsFirstAccess] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('firstAccess')) {
      setIsFirstAccess(false);
    } else {
      setIsFirstAccess(true);
      localStorage.setItem(
        'firstAccess',
        JSON.stringify([
          {
            initialAMISteps: true,
          },
        ]),
      );
    }
  }, []);

  if (!isFirstAccess) return null;

  switch (state[0].step) {
    case 0:
      return (
        <IntroAMITutorial
          nextStep={() => handleNextStep('computer-page')}
          skipTutorial={() => handleSkipTutorial('computer-page')}
        />
      );
    case 1:
      return (
        <FirstStep
          nextStep={() => handleNextStep('computer-page')}
          skipTutorial={() => handleSkipTutorial('computer-page')}
        />
      );
    case 2:
      return (
        <SecondStep
          nextStep={() => handleNextStep('computer-page')}
          skipTutorial={() => handleSkipTutorial('computer-page')}
        />
      );

    default:
      return null;
  }
};
