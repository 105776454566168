import React from 'react';
import MobileHeader from '../../components/Header';
import Container from './Container';
import Header from './Header';
import MyProfileBox from './MyProfileBox';

function MyProfilePage() {
  return (
    <Container>
      <MobileHeader />
      <Header />
      <MyProfileBox />
    </Container>
  );
}

export default MyProfilePage;
