import React from 'react';

import Title from './Title';

export const TitleGroup = () => (
  <div>
    <Title />
  </div>
);

export default TitleGroup;
