import React from 'react';
import { ToViewTutorialModalContainer, ToViewTutorialModalOverlay } from './styled';

export const ToViewTutorialModal = ({ isToViewModalOpen, onRequestClose }) => {
  function handleToViewTutorial() {
    localStorage.removeItem('firstAccess');
  }
  return (
    <>
      <ToViewTutorialModalContainer isToViewModalOpen={isToViewModalOpen}>
        <strong>
          Tem certeza que deseja <span>rever o tutorial?</span>
        </strong>
        <div>
          <button
            onClick={() => {
              handleToViewTutorial();
              onRequestClose();
              window.location.reload();
            }}
          >
            sim
          </button>
          <button onClick={onRequestClose}>não</button>
        </div>
      </ToViewTutorialModalContainer>

      <ToViewTutorialModalOverlay isToViewModalOpen={isToViewModalOpen} onClick={onRequestClose} />
    </>
  );
};
