import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    padding: 0;
    min-height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
`;

export default Container;
