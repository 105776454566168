import { Button as MuiButton } from '@material-ui/core';
import styled, { css } from 'styled-components';

import { desktopMinSize, mqmin } from '@Styles/mixins';
import { mq, mqmax } from '../../styles/mixins';

export const Button = styled(MuiButton)`
  &.MuiButton-root {
    --fontSize: 12;

    font-size: calc(var(--fontSize) * 1rem / 16);

    ${({ variant }) => {
      if (variant === 'all-white')
        return css`
          border: solid #ffffff calc(1rem / 16);
          border-radius: calc(24rem / 16);
          padding: calc(9rem / 16) calc(30rem / 16);
          color: #ffffff;
          opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
          font-weight: 'normal';
          line-height: 10px;
          transition: ${['color', 'background'].map(prop => `${prop} 300ms ease-out`).join(', ')};

          &.Mui-disabled {
            cursor: not-allowed;
            color: #2d80f2;
            border: solid #2d80f2 calc(2rem / 16);
            border-radius: calc(24rem / 16);
          }

          &:hover {
            color: #fff;
            background: #2d80f2;
          }

          ${mqmin(desktopMinSize)} {
            line-height: calc(26 / var(--fontSize));
          }

          ${mqmax(desktopMinSize-1)}{
            --fontSize: 8;
            line-height: 8px;
          }
        `;

      if (variant === 'outlined')
        return css`
          border: solid #2d80f2 calc(2rem / 16);
          border-radius: calc(24rem / 16);
          padding: calc(9rem / 16) calc(30rem / 16);
          color: #2d80f2;
          opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
          font-weight: bold;
          line-height: calc(18 / var(--fontSize));
          transition: ${['color', 'background'].map(prop => `${prop} 300ms ease-out`).join(', ')};

          &.Mui-disabled {
            cursor: not-allowed;
            color: #2d80f2;
            border: solid #2d80f2 calc(2rem / 16);
            border-radius: calc(24rem / 16);
          }

          &:hover {
            color: #fff;
            background: #2d80f2;
          }

          ${mqmin(desktopMinSize)} {
            --fontSize: 17;

            line-height: calc(26 / var(--fontSize));
          }

          ${mq(desktopMinSize + 400, 1350)} {
            --fontSize: 12;
            line-height: calc(26 / var(--fontSize));
          }

          ${mq(1350, desktopMinSize)} {
            --fontSize: 10;
            line-height: calc(26 / var(--fontSize));
          }

          ${mqmax(330)} {
            --fontSize: 9;
            line-height: calc(26 / var(--fontSize));
          }
        `;

      if (variant === 'contained')
        return css`
          border-radius: calc(24rem / 16);
          padding: calc(9rem / 16) calc(30rem / 16);
          font-weight: bold;
          line-height: calc(18 / var(--fontSize));
          width: ${({ home_modal }) => (home_modal ? '100%' : 'auto')};

          &.MuiButton-contained {
            color: #fff;
            opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
            background: #2d80f2;
            transition: background calc(1s / 3) ease-out;

            &:hover {
              background: #a00bf6;
            }
          }

          ${mqmin(desktopMinSize)} {
            --fontSize: 14;

            border-radius: calc(37rem / 16);
            padding: calc(10rem / 16) calc(30rem / 16);
            line-height: calc(24 / var(--fontSize));
            font-weight: 400;
          }

          ${mq(desktopMinSize + 400, 1350)} {
            --fontSize: 12;
            line-height: calc(26 / var(--fontSize));
          }

          ${mq(1350, desktopMinSize)} {
            --fontSize: 10;
            line-height: calc(15 / var(--fontSize));
          }

          ${mqmax(330)} {
            --fontSize: 9;
            line-height: calc(26 / var(--fontSize));
          }

          ${mqmax(desktopMinSize - 1)} {
            --fontSize: 9.3;
          }
        `;

      if (variant === 'popup-contained')
        return css`
          border-radius: calc(24rem / 16);
          padding: calc(9rem / 16) calc(30rem / 16);
          color: #fff;
          background: #2d80f2;
          font-weight: bold;
          line-height: calc(18 / var(--fontSize));
          transition: ${['color', 'background'].map(prop => `${prop} 300ms ease-out`).join(', ')};

          &:hover {
            color: #fff;
            background: #a00bf6;
          }

          ${mqmin(desktopMinSize)} {
            --fontSize: 17;

            line-height: calc(35 / var(--fontSize));
          }
        `;

      return css`
        padding: calc(9rem / 16) calc(24rem / 16);
        color: inherit;
        font-weight: normal;
        line-height: calc(13 / var(--fontSize));
        text-transform: none;
        transition: color 300ms ease-out;

        &:hover,
        &.active {
          color: #2d80f2;
        }

        &:hover {
          background: none;
        }

        ${mqmin(desktopMinSize)} {
          --fontSize: 15;

          line-height: calc(16 / var(--fontSize));
        }
      `;
    }}
  }
`;

export default Button;
