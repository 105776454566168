import React, { useState, useEffect } from 'react';
import { ShoppingCart, Close } from '@material-ui/icons';
import * as S from './styled';
import { getProducts, getActivatedPlans, getPersonalPlan } from '@Services/noar-api';
import CartPage from '../../pages/CartPage';
import { Loading } from '../Loading';
import { InitialStep } from '../ShopPageTutorial/DifferentSteps/InitialStep';
import { useTutorialStep } from '../../context/TutorialStepContext';
import PriceCardWrapper from './PriceCardWrapper';
import { useParams, useHistory } from 'react-router-dom';
import Redes from '../../layouts/Public/Footer/Redes';

const ShopPage = () => {
  const [products, setProducts] = useState([]);
  const [isPlanOpen, setIsPlanOpen] = useState('');
  const [isMobileCartOpen, setIsMobileCartOpen] = useState(false);
  const [isShoppingLoading, setIsShoppingLoading] = useState(false);
  const [isBuyingLoading, setIsBuyingLoading] = useState(false);
  const { state } = useTutorialStep();
  const [havePlan, setHavePlan] = useState(false);
  const [havePersonal, setHavePersonal] = useState(false);
  const [currentTab, setCurrentTab] = useState('personal');
  const [isOnWarning, setIsOnWarning] = useState(false);
  const history = useHistory();

  let { tab } = useParams();

  async function getProductsData() {
    try {
      setIsShoppingLoading(true);
      const response = await getProducts();
      setProducts(response);
      const activatedPlans = await getActivatedPlans();
      setHavePlan(activatedPlans?.length > 0);
      const personalPlans = await getPersonalPlan();
      setHavePersonal(personalPlans?.length > 0);
    } catch (error) {
      setIsShoppingLoading(false);
    } finally {
      setIsShoppingLoading(false);
    }
  }

  function handleCloseMobileCartOnKeyPress(e) {
    if (e.key === 'Escape') {
      setIsMobileCartOpen(currState => !currState);
    }
  }

  function handleChangeCartIcon() {
    if (state[3].step === 4 || state[3].step === 5 || state[3].step === 6 || state[3].step === 7) {
      return true;
    }
    return;
  }

  function handleTab(option) {
    setCurrentTab(option);
    if (option === 'unit') {
      option = 'personal';
    }
    history.replace(`/shop/${option}`);
  }

  useEffect(() => {
    if (!tab) {
      setCurrentTab('personal');
    } else {
      if (tab === 'unit') {
        tab = 'personal';
      }
      setCurrentTab(tab);
      history.replace(`/shop/${tab}`);
    }
  }, [tab]);

  useEffect(() => {
    getProductsData();
  }, []);

  return (
    <>
      <S.Container>
        {isOnWarning ? (
          <S.MaintenanceWrapper>
            <S.WarningTextWrapper>
              <S.WaningText>Estamos passando por instabilidades no sistema de compras e pagamentos no momento.</S.WaningText>
              <S.WaningText>Se você já possui um plano, fica tranquilo que você pode acessá-lo normalmente!</S.WaningText>
              <S.WaningText>Em breve estaremos de volta.</S.WaningText>
              <S.WaningText>(˶ᵔ ᵕ ᵔ˶)</S.WaningText>
            </S.WarningTextWrapper>
            <S.WarningFooterWrapper>
              <S.WarningRedirect href="/planos">
                Quer saber mais sobre nossos planos? Clique aqui!
              </S.WarningRedirect>
              <Redes />
            </S.WarningFooterWrapper>
          </S.MaintenanceWrapper>
        ) : (
          <>
            <S.HeaderWrapper>
              <S.TitlesHeader>
                <S.Title>Pacotes Noar</S.Title>
              </S.TitlesHeader>
              <S.ShopOptions>
                <S.PlansOption active={currentTab === 'personal'} onClick={() => handleTab('personal')}>
                  Monte seu pc
                </S.PlansOption>
                <S.PlansOption active={currentTab === 'hours'} secondary={true} onClick={() => handleTab('hours')}>
                  Horas Avulsas
                </S.PlansOption>
              </S.ShopOptions>
            </S.HeaderWrapper>
            {isBuyingLoading ? (
              <S.ContainerLoading>
                <Loading whichLoading={'generic'} />
              </S.ContainerLoading>
            ) : (
              []
            )}

            {isShoppingLoading ? (
              <Loading whichLoading={'generic'} />
            ) : (
              <>
                <PriceCardWrapper
                  products={products}
                  isPlanOpen={isPlanOpen}
                  setIsPlanOpen={setIsPlanOpen}
                  havePlan={havePlan}
                  havePersonal={havePersonal}
                  powerUpTab={currentTab}
                  isPublic={false}
                  setIsMobileCartOpen={setIsMobileCartOpen}
                />
                <InitialStep />
              </>
            )}
          </>
        )}
      </S.Container>
      {!isOnWarning ? <>
        <S.ShopPageCartMobileBackDrop
          isMobileCartOpen={isMobileCartOpen}
          onClick={() => {
            setIsMobileCartOpen(currState => !currState)
          }}
          step={state[3].step}
        />
        <S.ShopPageCartMobileButton
          type="button"
          onClick={() => setIsMobileCartOpen(currState => !currState)}
          isMobileCartOpen={isMobileCartOpen}
          onKeyDown={handleCloseMobileCartOnKeyPress}
          step={state[3].step}
        >
          {handleChangeCartIcon() ? <Close /> : <>{!isMobileCartOpen ? <ShoppingCart /> : <Close />}</>}
        </S.ShopPageCartMobileButton>
        <CartPage isMobileCartOpen={isMobileCartOpen} setIsMobileCartOpen={setIsMobileCartOpen} step={state[3].step} products={products} setIsShoppingLoading={setIsShoppingLoading} isShoppingLoading={isShoppingLoading} />
      </> : []}
    </>
  );
};

export default ShopPage;
