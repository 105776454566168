import React, { forwardRef } from 'react';

import * as S from './styled';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const Link = forwardRef(({ src, label, ...props }, ref) => {
  
  return (
    <>
      <S.Link {...props} ref={ref}>
        <S.Div>
          <S.IconMobile alt={''} src={src} width={20} height={20} />
          <S.TextDesktop {...props} >{label}</S.TextDesktop>
        </S.Div>
      </S.Link>
    </>
  );
});

export default Link;
