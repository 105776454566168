import React from 'react';
import { IMAGES_URL } from '@Constants';
import { COMPUTER_STATES } from '@Constants';
import { useSelector } from 'react-redux';

export const IsLoadingActions = () => {
  const { state, isLoading } = useSelector(selector => selector.computer);
  return (
    <>
      {(isLoading || state === COMPUTER_STATES.PENDING) && (
        <img alt="" src={`${IMAGES_URL}/website/private-pages/computer/load-animation-white.gif`} {...{ state }} />
      )}
    </>
  );
};
