import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Title, Subtitle } from '@Components/Public';
import { getBlogPosts } from '@Services/noar-api';
import { desktopMinSize, mqmin } from '@Styles/mixins';

import Post from './Post';
import { mq } from '../../../styles/mixins';

function Container(props) {
  const posts = usePosts();

  return (
    <section id="blog" {...props}>
      <Header>
        <Title as="h2">
          <strong>Blog</strong>
        </Title>
        <Subtitle>
          Confira as novidades da <span className="uppercase">Noar</span>.
        </Subtitle>
      </Header>
      <Grid>
        {posts.map(post => (
          <Post key={post.id} {...post} />
        ))}
      </Grid>
    </section>
  );
}

function usePosts() {
  const [posts, setPosts] = useState([]);

  useEffect(
    function () {
      getBlogPosts({ page_size: 3 }).then(function ({ posts }) {
        setPosts(posts);
      });
    },
    [setPosts],
  );

  return posts;
}

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Grid = styled.div`
  display: grid;
  column-gap: calc(20rem / 32);
  row-gap: calc(21rem / 32);
  grid-auto-rows: calc(455rem / 28);

  ${mqmin(desktopMinSize)} {
    column-gap: calc(20rem / 16);
    row-gap: calc(21rem / 16);
    grid-template-columns: repeat(2, calc(627rem / 16));
    grid-auto-rows: calc(255rem / 16);
  }

  ${mq(desktopMinSize+300, desktopMinSize)} {
    column-gap: calc(20rem / 16);
    row-gap: calc(21rem / 16);
    grid-template-columns: repeat(2, calc(442rem / 16));
    grid-auto-rows: calc(255rem / 16);
  }

  > :first-child {
    ${mqmin(desktopMinSize)} {
      grid-row: span 2;
    }
  }
`;

export const Blog = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  row-gap: calc(54rem / 32);

  ${mqmin(desktopMinSize)} {
    display: grid;
    row-gap: calc(54rem / 16);
  }

  ${Grid} {
    width: 100%;
  }
`;

export default Blog;
