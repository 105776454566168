import styled from 'styled-components';
import { desktopMinSize, mqmax } from '@Styles/mixins';

export const ShopPageThirdStepWrapper = styled.div`
  position: absolute;
  width: 100%;
  z-index: 104;
  line-height: 1.2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -60px;
  left: -100px;
  ${mqmax(desktopMinSize - 1)} {
    left: -39px;
    margin-top: -89px;
  }
`;

export const ShopPageThirdStepContentWrapper = styled.div`
  max-width: 221px;
  width: 100%;
  height: 96px;
  background-color: #2d80f2;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 25px;
  border-radius: 25px 25px 2px 25px;
  box-shadow: 0px 9px 16px #00000026;

  & > strong {
    font-weight: 300;
    margin: 0;
    font-size: 1rem;

    span {
      font-weight: 600;
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    max-width: 217px;
    height: 102px;
    border-radius: 25px 2px 25px 25px;

    & > strong {
      font-size: 0.938rem;
    }
  }
`;

export const ShopPageThirdStepButtonWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  & > button {
    border: 0;
    background-color: transparent;

    &:nth-of-type(1) {
      max-width: 100px;
      width: 100%;
      border-radius: 21px;
      height: 29px;
      outline: 2px solid #fff;
      color: #fff;
      outline-offset: -2px;
      text-transform: uppercase;
      font-weight: 600;
      transition: all 300ms;
      font-size: 0.688rem;
      &:hover {
        background-color: #fff;
        color: #2d80f2;
      }
    }

    &:nth-of-type(2) {
      color: #fff;
      font-weight: 300;
      transition: all 300ms;
      font-size: 0.75rem;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;
      transition: border 300ms;
      width: 100%;
      max-width: fit-content;
      border-radius: 0;

      &:hover {
        border-bottom-color: #fff;
      }
    }
  }
`;

export const ShopPageFourthStepWrapper = styled.div`
  position: absolute;
  width: 100%;
  z-index: 104;
  line-height: 1.2;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 380px;

  ${mqmax(desktopMinSize - 1)} {
    left: 0;
    justify-content: flex-end;
    width: 70%;
  }
  ${mqmax(desktopMinSize + 640)} {
    margin-top: -88px !important;
  }
  ${mqmax(desktopMinSize + 1280)} {
    margin-top: -30px;
  }
`;

export const ShopPageFourthStepContentWrapper = styled.div`
  max-width: 221px;
  width: 100%;
  height: 96px;
  background-color: #2d80f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px;
  border-radius: 25px 25px 2px 25px;
  box-shadow: 0px 9px 16px #00000026;

  & > strong {
    font-weight: 300;
    margin: 0;
    font-size: 1rem;

    span {
      font-weight: 600;
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    max-width: 217px;
    height: 102px;
    border-radius: 25px 2px 25px 25px;
    margin-top: -50px;

    & > strong {
      font-size: 0.938rem;
    }
  }
`;

export const ShopPageFourthStepButtonWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  & > button {
    border: 0;
    background-color: transparent;

    &:nth-of-type(1) {
      max-width: 100px;
      width: 100%;
      border-radius: 21px;
      height: 29px;
      outline: 2px solid #fff;
      color: #fff;
      outline-offset: -2px;
      text-transform: uppercase;
      font-weight: 600;
      transition: all 300ms;
      font-size: 0.688rem;
      &:hover {
        background-color: #fff;
        color: #2d80f2;
      }
    }

    &:nth-of-type(2) {
      color: #fff;
      font-weight: 300;
      transition: all 300ms;
      font-size: 0.75rem;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;
      transition: border 300ms;
      width: 100%;
      max-width: fit-content;
      border-radius: 0;

      &:hover {
        border-bottom-color: #fff;
      }
    }
  }
`;

export const ShopPageFifthStepWrapper = styled.div`
  position: absolute;
  width: 100%;
  z-index: 104;
  line-height: 1.2;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 400px;

  ${mqmax(desktopMinSize - 1)} {
    left: 41px;
    justify-content: flex-end;
    width: 65%;
  }
  ${mqmax(desktopMinSize - 680)} {
    width: 70%;
  }

  ${mqmax(desktopMinSize + 640)} {
    margin-top: -100px !important;
  }
  ${mqmax(desktopMinSize + 1280)} {
    margin-top: -40px;
  }
`;

export const ShopPageFifthStepContentWrapper = styled.div`
  max-width: 203px;
  width: 100%;
  height: 74px;
  background-color: #2d80f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px;
  border-radius: 25px 25px 2px 25px;
  box-shadow: 0px 9px 16px #00000026;

  & > strong {
    font-weight: 300;
    margin: 0;
    font-size: 1rem;

    span {
      font-weight: 600;
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    max-width: 217px;
    height: 84px;
    border-radius: 25px 2px 25px 25px;
    margin-top: -171px;

    & > strong {
      font-size: 0.938rem;
    }
  }
`;

export const ShopPageFifthStepButtonWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  & > button {
    border: 0;
    background-color: transparent;

    &:nth-of-type(1) {
      max-width: 100px;
      width: 100%;
      border-radius: 21px;
      height: 29px;
      outline: 2px solid #fff;
      color: #fff;
      outline-offset: -2px;
      text-transform: uppercase;
      font-weight: 600;
      transition: all 300ms;
      font-size: 0.688rem;
      &:hover {
        background-color: #fff;
        color: #2d80f2;
      }
    }

    &:nth-of-type(2) {
      color: #fff;
      font-weight: 300;
      transition: all 300ms;
      font-size: 0.75rem;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;
      transition: border 300ms;
      width: 100%;
      max-width: fit-content;
      border-radius: 0;

      &:hover {
        border-bottom-color: #fff;
      }
    }
  }
`;

export const ShopPageSixthStepWrapper = styled.div`
  position: absolute;
  width: 100%;
  z-index: 104;
  line-height: 1.2;
  ${mqmax(desktopMinSize - 1)} {
    display: none;
  }
`;

export const ShopPageSixthStepContentWrapper = styled.div`
  position: absolute;
  top: -20px;
  right: 19%;
  max-width: 215px;
  width: 100%;
  height: 93px;
  background-color: #2d80f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px;
  border-radius: 25px 25px 25px 2px;
  box-shadow: 0px 9px 16px #00000026;

  & > strong {
    font-weight: 300;
    margin: 0;
    font-size: 1rem;

    span {
      font-weight: 600;
    }
  }
`;

export const ShopPageSixthStepButtonWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  & > button {
    border: 0;
    background-color: transparent;

    &:nth-of-type(1) {
      max-width: 100px;
      width: 100%;
      border-radius: 21px;
      height: 29px;
      outline: 2px solid #fff;
      color: #fff;
      outline-offset: -2px;
      text-transform: uppercase;
      font-weight: 600;
      transition: all 300ms;
      font-size: 0.688rem;
      &:hover {
        background-color: #fff;
        color: #2d80f2;
      }
    }

    &:nth-of-type(2) {
      color: #fff;
      font-weight: 300;
      transition: all 300ms;
      font-size: 0.75rem;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;
      transition: border 300ms;
      width: 100%;
      max-width: fit-content;
      border-radius: 0;

      &:hover {
        border-bottom-color: #fff;
      }
    }
  }
`;

export const ShopPageSeventhStepWrapper = styled.div`
  position: absolute;
  width: 100%;
  z-index: 104;
  line-height: 1.2;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 90px;
  ${mqmax(desktopMinSize - 1)} {
    left: 50%;
    translate: -46%;
  }
  ${mqmax(desktopMinSize + 640)} {
    margin-top: 150px !important;
  }
  ${mqmax(desktopMinSize + 1280)} {
    margin-top: 220px;
  }
`;

export const ShopPageSeventhStepContentWrapper = styled.div`
  max-width: 376px;
  width: 100%;
  height: 96px;
  background-color: #2d80f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px;
  border-radius: 25px 25px 2px 25px;
  box-shadow: 0px 9px 16px #00000026;

  & > strong {
    font-weight: 300;
    margin: 0;
    font-size: 1rem;

    span {
      font-weight: 600;
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    border-radius: 25px;
    max-width: 262px;
    height: 137px;
    font-size: 0.938rem;
    line-height: 20px;
    margin-top: -180px;

    &:after {
      content: '';
      background: #2d80f2;
      position: absolute;
      width: 27px;
      height: 13.5px;
      left: 50%;
      translate: -50%;
      top: 6px;
      z-index: 102;
      clip-path: polygon(100% 0, 0 0, 50% 100%);
    }
  }
`;

export const ShopPageSeventhStepButtonWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  & > button {
    border: 0;
    background-color: transparent;

    &:nth-of-type(1) {
      max-width: 100px;
      width: 100%;
      border-radius: 21px;
      height: 29px;
      outline: 2px solid #fff;
      color: #fff;
      outline-offset: -2px;
      text-transform: uppercase;
      font-weight: 600;
      transition: all 300ms;
      font-size: 0.688rem;
      &:hover {
        background-color: #fff;
        color: #2d80f2;
      }
    }

    &:nth-of-type(2) {
      color: #fff;
      font-weight: 300;
      transition: all 300ms;
      font-size: 0.75rem;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;
      transition: border 300ms;
      width: 100%;
      max-width: fit-content;
      border-radius: 0;

      &:hover {
        border-bottom-color: #fff;
      }
    }
  }
`;
