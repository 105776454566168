import styled from 'styled-components';

export const Box = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: calc(80 / 1056 * 100%) calc(90 / 1056 * 100%);
    border: solid transparent calc(5 / 16 * 1rem);
    border-radius: 38px;
    background: #21222A;
    transform: scale(0.99);
    
    & > *:nth-child(n+3) {
        margin-top: calc(30 / 16 * 1rem);
    }
`;


export default Box;
