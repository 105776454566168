import React, { useEffect, useState } from 'react';
import SectionSubtitle from '../RegisterConfirmationSubtitle';
import { useLocation } from 'react-router-dom';

export const Subtitle = () => {

    const [loginChallenge, handleChallengeFromLocation] = useState('');
    const location = useLocation();

    useEffect(() => {
        handleChallengeFromLocation(location?.challenge)
      }, [location?.challenge]);

    return <SectionSubtitle>
        {
            loginChallenge === 'NEW_PASSWORD_REQUIRED'  
                ? <>Digite uma <strong>nova senha</strong> para liberar sua conta.</>
                : <>Um e-mail foi enviado para você com um <strong>código para verificação</strong></>
        }
        </SectionSubtitle>;
}

export default Subtitle;
