import styled from 'styled-components';

import { desktopMinSize, rem, mqmin, mqmax } from '@Styles/mixins';

export const Video = styled.div`
  align-self: center;
  width: 100%;
  height: calc(193rem / 16);
  padding-top: ${rem(30)};
  position: relative;
  overflow: hidden;

  iframe,
  object,
  embed {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  ${mqmin(desktopMinSize)} {
    margin-top: 0;
    padding-bottom: 26.25%;
  }

  ${mqmax(desktopMinSize - 1)} {
    border-radius: calc(35rem / 32);
  }
`;
