import styled from 'styled-components';

import { rem, mqmax, mqmin, desktopMinSize } from '@Styles/mixins';

export const ComputeViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  @media (min-width: 1900px) {
    height: 90%;
  }

  ${mqmax(desktopMinSize - 1)} {
    padding: 0 2rem;
  }

  @media (max-height: 750px) {
    gap: 1.5rem;
  }
`;

export const Computer = styled.section`
  width: 100%;
  color: #fff;
  position: relative;
  align-items: center;
  justify-content: center;
  border-radius: 49px;
  display: flex;
  max-width: 1081px;
  max-height: 504px;
  gap: 3rem;
  ${mqmax(desktopMinSize - 1)} {
    flex-direction: column;
    max-height: none;
    gap: 0;
  }

  img {
    max-width: 499px;
    width: 100%;
    height: 414px;
    border-radius: 49px;
    object-fit: cover;

    ${mqmax(desktopMinSize - 1)} {
      max-width: 300px;
      height: 80px;
      border-radius: 25px;
    }

  }
  ${mqmin(desktopMinSize)} {
    padding: 49px;
  }

  .onSectionContentWrapper {
    margin-top: 29px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    strong {
      font-size: ${rem(21)};
      font-weight: 300;

      ${mqmax(desktopMinSize - 1)} {
        font-size: ${rem(15)};
        text-align: center;
      }

      span {
        font-size: ${rem(32)};
        font-weight: 600;

        ${mqmax(desktopMinSize - 1)} {
          display: block;
          font-size: ${rem(25)};
        }
      }
    }

    p {
      font-size: 0.938rem;
      font-weight: 300;
      margin-top: 15px;

      ${mqmax(desktopMinSize - 1)} {
        max-width: 50ch;
        line-height: 1.6;
        text-align: center;
      }
    }

    ${mqmax(desktopMinSize - 1)} {
      align-items: center;
    }
  }

  .onSectionContentTitleWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;  

    ${mqmax(desktopMinSize - 1)} {
      align-items: center;
    }
  }

  .onSectionContentIPWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    z-index: ${({ step }) => (step === 0 ? '102' : '1')};
    position: relative;
    pointer-events: ${({ step }) => (step === 0 ? 'fill' : 'all')};

    ${mqmax(desktopMinSize - 1)} {
      justify-content: center;
    }
  }

  .onSectionContentIP {
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid #2d80f2;
    -webkit-border-radius: 100vmax;
    -moz-border-radius: 100vmax;
    border-radius: 100vmax;
    max-width: 211px;
    width: 100%;
    height: 50px;

    strong {
      font-size: ${rem(15)};
      font-weight: 600;

      ${mqmax(desktopMinSize - 1)} {
        font-size: ${rem(12)};
      }

      span {
        display: inline-block;
        font-size: ${rem(15)};
        margin: 0;
        margin-right: 3px;

        ${mqmax(desktopMinSize - 1)} {
          font-size: ${rem(12)};
        }
      }
    }

    ${mqmax(desktopMinSize - 1)} {
      margin: 30px 0;
      height: 40px;
      max-width: 159px;
    }

    @media (max-height: 750px) {
      margin: 10px 0;
    }
  }
  .computer__actions {
    form {
      label {
        z-index: ${({ step }) => (step === 1 ? '102' : '1')};
        position: relative;
        pointer-events: ${({ step }) => (step === 1 ? 'none' : 'all')};
      }
      & > div {
        input {
          z-index: ${({ step }) => (step === 1 ? '102' : '1')};
          position: relative;
          pointer-events: ${({ step }) => (step === 1 ? 'none' : 'all')};
        }
        button[type='submit'] {
          z-index: ${({ step }) => (step === 1 ? '102' : '1')};
          position: relative;
          pointer-events: ${({ step }) => (step === 1 ? 'none' : 'all')};
        }
      }
      .program-page-button-wrapper {
        z-index: ${({ step }) => (step === 2 ? '102' : '1')};
        position: relative;
        justify-content: initial;
        pointer-events: ${({ step }) => (step === 2 ? 'none' : 'all')};
      }
    }
  }
`;

export const IsLoadingComputerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  ${mqmax(desktopMinSize - 1)} {
    height: 70% ;
  }

  @media (min-width: 1900px) {
    height: 90%;
  }

`;

export const IsLoadingComputer = styled.section`
  padding: ${rem(0)} ${rem(40)};
  color: #fff;
  max-width: 1060px;
  width: 100%;
  position: relative;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 49px;
    padding: 6px;
    background: linear-gradient(90deg, #14a1fd 0%, #9818f7 100%);
    mask: linear-gradient(90deg, #14a1fd 0%, #9818f7 100%) content-box, linear-gradient(90deg, #14a1fd 0%, #9818f7 100%);
    -webkit-mask: linear-gradient(90deg, #14a1fd 0%, #9818f7 100%) content-box,
      linear-gradient(90deg, #14a1fd 0%, #9818f7 100%);
    -webkit-mask-composite: xor;
    mask-composite: exclude;

    ${mqmax(desktopMinSize - 1)} {
      padding: 3px;
      border-radius: 33px;
      background: linear-gradient(155deg, #14a1fd 0%, #9818f7 100%);
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    width: 80%;
    padding: ${rem(0)} ${rem(12)};
    height: 250px;
  }

  h2 {
    font-size: ${rem(31)};
    font-weight: 600;

    ${mqmax(desktopMinSize - 1)} {
      font-size: ${rem(21)};
    }
  }

  img {
    width: ${rem(150)};
    margin: 0 auto;
    ${({ state }) => ['RUNNING', 'STOPPING'].includes(state)}

    ${mqmin(desktopMinSize)} {
      width: ${rem(200)};
    }
  }

  .computer__presentation-title {
    & > div:first-child {
      position: relative;
      margin: 0 auto;
      animation: typing 8s steps(13) infinite;
      overflow: hidden;
      height: 60px;
      display: flex;
      align-items: center;
      -webkit-box-reflect: below -28px linear-gradient(transparent, #0002);

      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 2px;
        height: 100%;
        background: #fff;
        animation: blinkCursor 800ms steps(3) infinite;
      }

      & > strong {
        font-weight: 600;
        font-size: ${rem(43)};
        position: relative;
        letter-spacing: 5px;

        ${mqmax(desktopMinSize - 1)} {
          font-size: ${rem(25)};
        }
      }
    }

    @keyframes typing {
      0%,
      90%,
      100% {
        width: 0;
      }
      30%,
      60% {
        width: 365.31px;
      }
    }

    @keyframes blinkCursor {
      0%,
      75% {
        opacity: 1;
      }
      76%,
      100% {
        opacity: 0;
      }
    }
  }

  .computer__presentation-info {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    margin-bottom: 15px;
    strong {
      margin-top: 20px;
      font-weight: 300;
      font-size: ${rem(21)};

    ${mqmax(desktopMinSize - 1)} {
        font-size: ${rem(16)};
        margin-top: 10px;
      }
    }

    ${mqmax(desktopMinSize - 1)} {
      flex-direction: column;
      gap: 0;
      margin-bottom: 30px;
    }
  }

  .computer__queue-type{
    font-size: 15px;
  }

  .button-queue {
    background: transparent;
    border: 0;
    max-width: 260px;
    width: 100%;
    height: 45px;
    outline: 3px solid #fff;
    outline-offset: -3px;
    display: block;
    margin: 30px auto;
    border-radius: 100vmax;
    color: #fff;
    text-transform: uppercase;
    font-size: 14px;
    cursor: pointer;
    z-index: 3;
    transition: all 150ms ease-in-out;
    position: relative;
    margin-bottom: 60px;

    ${mqmax(desktopMinSize - 1)} {
      max-width: 230px;
      font-size: 12px;
      outline: 2px solid #fff;
      margin-bottom: 0;
    }

    &:hover {
      background: #fff;
      color: #21222a;
    }
  }
`;

export const IsLoadingComputerHeader = styled.header`
  text-align: center;
  color: #fff;

  & strong:nth-child(1) {
    font-size: ${rem(30)};
    font-weight: 600;

    ${mqmax(desktopMinSize - 1)} {
      font-size: ${rem(25)};

      span {
        margin-top: 5px;
        display: block;
      }
    }
  }

  & strong:nth-child(2) {
    font-size: ${rem(16)};
    font-weight: 300;
    display: block;
    margin-top: 14px;
    margin-bottom: 23px;

    ${mqmax(desktopMinSize - 1)} {
      font-size: ${rem(12)};
    }
  }
`;

export const IsLoadingTipsWrapper = styled.div`
  position: relative;
  color: #fff;
  max-width: 1075px;
  width: 100%;
  height: 65px;
  z-index: 999;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 34px;
  text-align: center;

  &:before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 34px;
    padding: 6px;
    background: linear-gradient(90deg, #14a1fd 0%, #9818f7 100%);
    mask: linear-gradient(90deg, #14a1fd 0%, #9818f7 100%) content-box, linear-gradient(90deg, #14a1fd 0%, #9818f7 100%);
    -webkit-mask: linear-gradient(90deg, #14a1fd 0%, #9818f7 100%) content-box,
      linear-gradient(90deg, #14a1fd 0%, #9818f7 100%);
    -webkit-mask-composite: xor;
    mask-composite: exclude;

    ${mqmax(desktopMinSize - 1)} {
      padding: 2px;
      border-radius: 28px;
      background: linear-gradient(114deg, #14a1fd 0%, #9818f7 100%);
    }
  }

  button {
    max-width: 23px;
    width: 100%;
    height: 20px;
    transition: transform 350ms;
  }

  button:hover {
    transform: scale(1.3);
  }

  button:first-child {
    clip-path: polygon(100% 0, 38% 50%, 100% 100%);
    background: #1a9afc;
  }

  button:last-child {
    clip-path: polygon(0 0, 0 100%, 60% 50%);
    background: #9118ec;
  }

  p {
    z-index: 999;
    max-width: 67ch;
    font-size: 1.125rem;
    line-height: 1.5;

    a {
      color: #fff;
      text-decoration: underline;
    }

    b {
      font-weight: 700;
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    max-width: 80%;
    height: 90px;
    padding: 13px;

    button {
      max-width: 13px;
      height: 10px;
    }

    button:first-child {
      margin-right: 20px;
    }

    button:last-child {
      margin-left: 20px;
    }

    p {
      font-size: 0.75rem;
      line-height: 18px;
    }
  }
`;

export const ComputerContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  font-family: 'Poppins', sans-serif;
  position: relative;

  ${mqmax(desktopMinSize - 1)} {
    align-items: center;
  }
`;
