import types from './types';
import INITIAL_STATE from './initial-state';

function course(oldState = INITIAL_STATE, action) {
  if (!types[action.type]) {
    return oldState;
  }

  return types[action.type](oldState, action);
}

export default course;
