import React from 'react';
import styled from 'styled-components';
import { desktopMinSize, mqmax } from '@Styles/mixins';

import slideImg from '@Assets/5.webp';
import mobileSlideImg from '@Assets/5-mobile.webp';

export const FifthStep = () => {
  return (
    <>
      <div>
        {window.innerWidth < desktopMinSize ? <img src={mobileSlideImg} alt="" /> : <img src={slideImg} alt="" />}
      </div>
      <FifthStepDescription>
        <span>5.</span> Com o Moonlight instalado abra o aplicativo e <span>clique no sinal de &#43; </span> no canto
        superior direito.
      </FifthStepDescription>
    </>
  );
};

const FifthStepDescription = styled.strong`
  text-align: left !important;
  font-size: 0.875rem !important;
  line-height: 1.5 !important;

  ${mqmax(desktopMinSize - 1)} {
    font-size: 0.75rem !important;
  }
`;
