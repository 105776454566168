import { MdOutlineClose } from "react-icons/md";
import { AboutPersonalWrapper } from "../styled";

const AboutPersonal = ({
    modalRef,
    handleKnowMoreOnClassicCloseModal
}) => {
    return (
        <AboutPersonalWrapper ref={modalRef}>
        <div>
          <strong>Sobre o Personal</strong>
          <ul>
            <li>NOAR Personal, seu computador pessoal na nuvem.</li>
            <li>Plano de assinatura mensal de pagamento recorrente.</li>
            <li>Você pode cancelar a qualquer momento!</li>
            <li>Após o cancelamento, sua assinatura não será renovada no mês seguinte, podendo utilizar o plano até o último dia da vigência.</li>
            <li>Em caso de não pagamento, os dados serão excluídos permanentemente após 7 dias da data final do vencimento do plano.</li>
            <li>Sempre que desligar a máquina, será feito um backup dos arquivos e programas salvos durante a utilização, substituindo o backup anterior (não é possível restaurar um backup após o salvamento.)</li>
            <li>Para utilizar o NOAR Personal se faz necessário a contratação de um plano de acesso compatível.</li>
            <li>Planos compatíveis atualmente: NOAR Classic, por hora de uso, ou pacotes.</li>
            <li>Qualquer problema de mau uso, crash e instalação de programas não compatíveis, poderá causar a exclusão da máquina e de todos os arquivos que contém nela.</li>
            <li>A NOAR não se responsabiliza por qualquer problema causado por incompatibilidade, ou mal funcionamento de programas de terceiros dentro das máquinas virtuais.</li>
            <li>Só serão salvos os arquivos modificados na máquina do plano Personal, outras máquinas com programas pré-instalados não salvarão os seus dados após a utilização.</li>
          </ul>
          <button ref={modalRef} onClick={handleKnowMoreOnClassicCloseModal}>
            <MdOutlineClose color="#fff" />
          </button>
        </div>
      </AboutPersonalWrapper>
    );
}

export default AboutPersonal;