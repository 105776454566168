import React, { useState } from 'react';

import {
  AdminAccordionAnswer,
  AdminAccordionTitle,
  AdminAccordionWrapper,
} from './styled';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { CollaboratorActionButton, CollaboratorActionButtonWrapper } from '../../styled';
import { ProtectedComponent } from '../../../ProtectedComponent';

export const MobileAccordionContent = ({
  data,
  action,
  handleEditGoldImage,
  handleDeleteGoldImage,
  handlePlayGoldImage,
}) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [isAccordionArrowUp, setIsAccordionArrowUp] = useState(false);

  function handleOpenAccordion() {
    setIsAccordionOpen(!isAccordionOpen);
    setIsAccordionArrowUp(!isAccordionArrowUp);
  }

  return (
    <>
      <ProtectedComponent role={['B2B_ADM']}>
        <AdminAccordionWrapper onClick={handleOpenAccordion}>
          <AdminAccordionTitle isAccordionArrowUp={isAccordionArrowUp}>
            <strong >
              {data.image_name}{' '}
            </strong>
            <ExpandMoreIcon />
          </AdminAccordionTitle>
          <AdminAccordionAnswer isAccordionOpen={isAccordionOpen}>
            <strong>
              Descrição: <span>{data.description}</span>
            </strong>
            <strong>
              Host: <span>{data.host?.nickname}</span>
            </strong>
            <CollaboratorActionButtonWrapper>
              <CollaboratorActionButton onClick={() => handleEditGoldImage(data)} title={action.edit.title}>{action.edit.ico} {action.edit.title}</CollaboratorActionButton>
              <CollaboratorActionButton onClick={() => handleDeleteGoldImage(data)} title={action.delete.title}>{action.delete.ico} {action.delete.title} </CollaboratorActionButton>
              <CollaboratorActionButton onClick={() => handlePlayGoldImage(data)} title={action.play.title}>{action.play.ico} {action.play.title}</CollaboratorActionButton>
            </CollaboratorActionButtonWrapper>
          </AdminAccordionAnswer>
        </AdminAccordionWrapper>
      </ProtectedComponent>
    </>
  );
};
