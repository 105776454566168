import React from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { desktopMinSize } from '@Styles/mixins';

export const FirstStep = () => {
  return (
    <>
      <strong>
        <span>1.</span> Para usar a NOAR <span>é fácil,</span>
        <br /> basta seguir esses passos
        <br /> que <span>vai dar tudo certo.</span>
      </strong>

      {window.innerWidth < desktopMinSize ? (
        <BsArrowRight fill="#fff" size={18} />
      ) : (
        <BsArrowRight fill="#fff" size={24} />
      )}
    </>
  );
};
