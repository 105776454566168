import styled from 'styled-components';

import { desktopMinSize } from '@Styles/mixins';
import { mqmax } from '../../../../../styles/mixins';

export const Wrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 80%;

  ${mqmax(desktopMinSize -1)} {
    width: 70%;
    height: 100%;
  }
`;

export default Wrapper;
