import { useEffect, useState } from "react";
import AnalyticsSection from "./AnalyticsSection";
import CollabSection from "./CollabSection";
import * as S from "./styles";
import { getBusinessActiveAdminPlan } from "../../services/noar-api";

const AdmPanel = () => {
    
  const [admPlan, setAdmPlan] = useState(null);

  useEffect(() => {
    async function handleInitialSetAdmPlan() {
      const plan = await getBusinessActiveAdminPlan();
      if(plan){
        setAdmPlan(plan[0]);
      } else {
        setAdmPlan(null);
      }
    };
    handleInitialSetAdmPlan();
  }, []);

    return (
        <S.AdmPanelWrapper>
            <AnalyticsSection admPlan={admPlan}/>
            <CollabSection admPlan={admPlan}/>
        </S.AdmPanelWrapper>
    );
}

export default AdmPanel;