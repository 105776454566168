import React from 'react';
import {
  AMITutorialFirstStepWrapper,
  AMITutorialFirstStepContentWrapper,
  AMITutorialFirstStepButtonWrapper,
  TutorialBackdrop,
} from '../styled';

export const FirstStep = ({ nextStep, skipTutorial }) => {
  return (
    <>
      <TutorialBackdrop />
      <AMITutorialFirstStepWrapper>
        <AMITutorialFirstStepContentWrapper>
          <strong>
            Começando aqui, você tem uma <span>lista de computadores que pode acessar.</span>
          </strong>
          <AMITutorialFirstStepButtonWrapper>
            <button onClick={nextStep}>Entendi</button>
            <button onClick={skipTutorial}>Fechar tutorial</button>
          </AMITutorialFirstStepButtonWrapper>
        </AMITutorialFirstStepContentWrapper>
      </AMITutorialFirstStepWrapper>
    </>
  );
};
