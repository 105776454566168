import email from './email.svg';
import instagram from './instagram.svg';
import linkedin from './linkedin.svg';
import twitter from './twitter.svg';
import youtube from './youtube.svg';
import discord from './discord.svg';

export const Redes = [
  {
    img: { alt: 'Discord', src: discord, width: 16, height: 12 },
    link: { href: 'https://discord.com/invite/noar-798022821342609418' },
  },
  {
    img: { alt: 'E-mail', src: email, width: 18, height: 12 },
    link: { href: 'mailto:contato@noarcloud.com' },
  },
  {
    img: { alt: 'Instagram', src: instagram, width: 15, height: 15 },
    link: { href: 'https://www.instagram.com/noarcloud' },
  },
  {
    img: { alt: 'LinkedIn', src: linkedin, width: 15, height: 15 },
    link: { href: 'https://www.linkedin.com/company/noarcloud/' },
  },
  {
    img: { alt: 'Youtube', src: youtube, width: 18, height: 12 },
    link: { href: 'https://www.youtube.com/channel/UCErUnW92FpYXNZQEYikRtVg' },
  },
  {
    img: { alt: 'Twitter', src: twitter, width: 17, height: 14 },
    link: { href: 'https://twitter.com/noarcloud' },
  },
];

export default Redes;
