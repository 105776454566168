import React from 'react';
import styled from 'styled-components';
import MuiButton from '@material-ui/core/Button';

export const Container = props => 
    <Button {...props} />

export default Container;

const Button = styled(MuiButton)`
  &.MuiButton-root {
    padding: 0;
    min-width: auto;
  }
`;