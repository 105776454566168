import styled from 'styled-components';
import MuiButton from '@material-ui/core/Button';
import { desktopMinSize, mqmin, mqmax, rem } from '@Styles/mixins';
import { mq } from '../../../styles/mixins';

export const Container = styled.div`
  width: 100%;
  max-width: 1176px;
  border-radius: ${rem(8)};
  position: relative;

  ${mqmin(desktopMinSize)} {
    display: block;
    margin-bottom: 2rem;
  }

  strong {
    color: #fff;
    font-size: ${rem(47)};
    font-weight: 600;
    display: block;
    text-align: left;
    margin-bottom: 44px;
  }

  ${mqmax(desktopMinSize - 1)} {
    margin: 0;
    max-width: 100%;

    strong {
      margin-top: 2rem;
      font-size: ${rem(25)};
    }
  }
`;

export const Table = styled.table`
  min-width: ${rem(800)};
  width: 100%;
  color: #fff;
  border-collapse: separate;
  position: relative;

  border-spacing: 8px 50px;

  th {
    text-align: left;
    font-size: ${rem(16)};
    font-weight: 300;
  }

  tr + tr::after {
    content: '';
    width: 100%;
    height: 1px;
    background-color: #fff;
    position: absolute;
    margin-top: -20px;
    left: 0;
    opacity: 0.1;
  }
`;

export const TableFooterWrapper = styled.div`
  span {
    font-size: ${rem(14)};
    font-weight: 300;
    display: inline-block;
    margin-right: 3px;
    color: #fff;
  }

  select {
    background: transparent;
    border: 0;
    color: #fff;
    cursor: pointer;
    font-size: ${rem(15)};
    font-weight: 600;

    & option {
      color: #000;
    }
  }
`;

export const TableFooterContent = styled.div`
  display: flex !important;
  justify-content: space-between !important;

  .formControl {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: ${rem(8)};
  }
  .formSelect {
    color: hsl(0, 0%, 100%);

    & svg {
      color: hsl(0, 0%, 100%);
    }
  }
`;

export const Th = styled.th`
  font-weight: 600 !important;
  font-size: ${rem(19)} !important;
  text-align: left;

  & > button {
    rotate: 90deg;
    background: transparent;
    color: #fff;
    font-size: 1rem;
    font-weight: 600;
  }
`;

export const Td = styled.td`
  font-weight: 300;
  font-size: ${rem(16)};
`;

export const TdStatus = styled.td`
  font-weight: 300;
  font-size: ${rem(16)};
  color: ${({ active }) => (!active ? '#F83030' : '#FFFFFF')};
  opacity: 1;
  font-weight: 600;
  min-width: 134px;
`;

export const TableWrapper = styled.div`
  background-color: #21222a;
  padding: 0 ${rem(28)} ${rem(35)} ${rem(35)};
  border-radius: 35px;
  max-width: 1109px;
  min-height: 499px;
`;

export const AdminTableWrapper = styled.div`
  max-width: 1176px !important;
  width: 100% !important;
  min-height: 329px;
  margin-top: 13px;

  & > div:last-child {
    margin-top: 13px;
  }
`;

export const AdminContentWrapper = styled.div`
  padding: 0 ${rem(28)} 0 ${rem(35)};
  border-radius: 25px;
  border: 1px solid #fff;
  min-height: 329px;
  max-width: 1111px;
`;

export const AdminTable = styled.table`
  max-width: 1111px;
  width: 100%;
  color: #fff;
  border-collapse: separate;
  position: relative;

  border-spacing: 0px 40px;

  th {
    text-align: left;
    font-size: ${rem(14)} !important;
    font-weight: 300;
  }
  th:not(:first-child) {
    padding: 0 0 0 0.938rem;
  }

  td {
    vertical-align: middle;
    max-width: 240px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  td:first-child {
    min-width: 127px;
  }

  td:not(:first-child) {
    padding: 0 0 0 0.938rem;
  }

  tr:has(td)::after {
    content: '';
    width: 100%;
    height: 1px;
    background-color: #fff;
    position: absolute;
    margin-top: -20px;
    left: 0;
    opacity: 0.1;
  }
`;

export const CollaboratorActionButtonWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  width: 60%;
  flex-direction: row;
  justify-content: flex-end;

  ${mq(desktopMinSize + 300, desktopMinSize)} {
    gap: 0.2rem;
  }

  ${mqmax(desktopMinSize - 1)} {
    gap: 0;
    width: 100%;
    justify-content: space-around;
    margin-top: 10%;
  }
`;

export const CollaboratorActionButton = styled.div`
  color: #ffffff;
  opacity: 0.2;
  cursor: pointer;

  ${mq(desktopMinSize + 300, desktopMinSize)} {
    .MuiSvgIcon-root {
      font-size: ${rem(16)};
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    color: #426fe5;
    opacity: 1;
    font-size: ${rem(12)};
    display: flex;
    justify-content: center;
    align-items: center;
    .MuiSvgIcon-root {
      font-size: ${rem(20)};
    }
  }
`;

export const CollaboratorInfoActionButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const CollaboratorInfoActionButton = styled.div`
  background-color: rgba(33, 34, 42, 0.5);
  padding: 1.5rem;
  position: absolute;
  top: 26%;
  right: -4%;
  z-index: 1;
  width: 300px;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #ffffff;
  border-radius: 15px;
  backdrop-filter: blur(5px);
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
  font-weight: 300;
  justify-content: center;
  text-align: left;
  font-size: 1.1rem;
  line-height: 1.5rem;

  strong {
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0;
  }

  ${mqmax(desktopMinSize - 1)} {
    backdrop-filter: blur(15px);
    top: 50%;
    right: 0;
  }
`;

export const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  gap: 0.938rem;
  min-width: 569px;

  ${mqmax(desktopMinSize - 1)} {
    min-width: 0px !important;
    gap: 0;
  }

  .rw-combobox {
    max-width: 569px !important;
    width: 100%;
  }

  .rw-state-focus {
    .rw-widget-picker {
      border-radius: 9999px;
    }

    .rw-btn {
      & > svg {
        fill: #17171a !important;
      }
    }
  }

  .rw-widget {
    .rw-btn {
      pointer-events: none;
      border: 0;
      position: absolute;
      right: 0.875rem;
      top: 0.875rem;

      & > svg {
        fill: #fff;
      }

      ${mqmax(desktopMinSize - 1)} {
        right: 1.188rem;
        top: 0.75rem;

        & > svg {
          width: 12px;
        }
      }
    }
  }

  .rw-widget-picker {
    background: transparent !important;
    position: relative;
    border: 0;
    display: flex;

    & > input {
      font-family: 'Poppins', sans-serif;
      height: 41px;
      background: transparent;
      border: 1.5px solid #fff;
      border-radius: 99999px;
      padding-left: 15px !important;
      color: #fff;
      transition: all 250ms;

      &::placeholder {
        font-size: 0.938rem;
        font-weight: 300;
        opacity: 23%;

        ${mqmax(desktopMinSize - 1)} {
          font-size: 0.813rem;
        }
      }

      &:focus {
        background: #fff;
        outline: 0;
        border: 1.5px solid #000;
        color: #17171a;

        &::placeholder {
          color: #17171a;
          opacity: 1;
          font-weight: 400;
        }
      }
    }
  }

  & > div > button {
    background-color: transparent;
    width: 34px;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #fff;
    border-radius: 7px;

    img {
      width: 16px;
      aspect-ratio: 1;
    }
  }

  & > div:has(button) {
    position: relative;
  }

  & > div:has(button):nth-of-type(2) {
    position: relative;
  }

  & > div > button:nth-of-type(2) {
    svg {
      width: 25px;
      height: 25px;
      fill: #fff;
    }

    ${mqmax(desktopMinSize - 1)} {
      margin-right: 0;
    }
  }
`;

export const CreateUserButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  gap: 0.938rem;
`;

export const CreateUserButton = styled(MuiButton)`
  &.MuiButton-root {
    max-width: 400px;
    min-width: 200px;
    max-height: 41px;
    margin: 0 auto;
    width: 100%;
    padding: 10px;
    border-color: #2d80f2;
    border-width: calc(3 / 16 * 1rem);
    border-radius: calc(24 / 16 * 1rem);
    color: #fff;
    font-weight: 600;
    background: #2d80f2;
    font-size: 13px;
    transition: ${['background', 'border-color'].map(prop => `${prop} calc(1s / 3) ease-in-out`).join(',')};

    &:hover,
    &.active {
      background: #a00bf6;
      border-color: #a00bf6;
      color: #fff;
    }
    ${mqmax(desktopMinSize - 1)} {
      padding: 5px;
      height: 41px;
    }
  }
`;

export const HeadersWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  gap: 0.938rem;

  ${mqmax(desktopMinSize - 1)} {
    flex-direction: column;
    margin-bottom: 2rem;
    gap: 0.75rem;
    align-items: stretch;
  }
`;

export const ExportWrapper = styled.button`
  position: absolute;
  min-width: 218px !important;
  height: 42px !important;
  border-radius: 13px !important;
  font-size: 0.813rem;
  font-weight: 300;
  color: #fff;
  left: -100px;
  top: 45px;
  background: rgba(0, 0, 0, 0) !important;
  backdrop-filter: blur(23px) !important;
  -webkit-backdrop-filter: blur(23px) !important;
  border: 1px solid rgba(255, 255, 255, 0.18) !important;
  transition: opacity, z-index, 250ms ease-in-out;
  opacity: ${({ isExportButtonIsShowing }) => (isExportButtonIsShowing ? 1 : 0)};
  z-index: ${({ isExportButtonIsShowing }) => (isExportButtonIsShowing ? 999 : -1)};
`;
