import { useState } from 'react';
import AnalyticsInfoCard from '../../AnalyticsSection/AnalyticsInfoCard';
import DatePickerContent from '../../AnalyticsSection/DatePicker';
import { AnalyticsInfoSection } from '../../AnalyticsSection/styled';
import Close from '../PopupModal/Close';
import Overlay from '../PopupModal/Overlay';
import * as S from './styled';
import { useEffect } from 'react';
import { getB2BConsumptionDataByCollab } from '../../../../services/noar-api';
import { Loading } from '../../../Loading';

const PopupInfoModal = ({ close, actionFunction, admPlan, handleEditCollab, actions, goldImages }) => {
  const [initialDate, setInitialDate] = useState();
  const [endDate, setEndDate] = useState(new Date());
  const [usedHours, setUsedHours] = useState(0);
  const [usedUpload, setUsedUpload] = useState(0);
  const [loading, setLoading] = useState(true);
  const collab = actionFunction?.data;
  const analyticsInfo = [
    {
      title: 'Tempo utilizado da máquina',
      maxAmount: (collab?.maximum_hours_available
        ? collab?.maximum_hours_available
        : admPlan?.contracted_hours
          ? admPlan.contracted_hours
          : 0
      ),
      usedAmount: usedHours ? usedHours : 0,
      unit: 'h',
      planName: null,
    },
    {
      title: 'Quantidade de internet utilizada',
      maxAmount: (collab?.maximum_upload_available
        ? collab?.maximum_upload_available/1024/1024
        : admPlan?.upload_limit
          ? admPlan.upload_limit/1024/1024
          : 0
      ).toFixed(2),
      usedAmount: usedUpload ? usedUpload : 0,
      unit: 'GB',
      planName: null,
    },
  ];

  function handleInitialDateChange(newValue) {
    if (newValue > endDate) {
      setEndDate(newValue);
    }
    setInitialDate(newValue);
  }

  function handleEndDateChange(newValue) {
    if (newValue < initialDate) {
      setInitialDate(newValue);
    }
    setEndDate(newValue);
  }

  useEffect(() => {
    var signatureDate = new Date(admPlan?.signature_date);
    setInitialDate(signatureDate);
  }, [admPlan]);

  useEffect(() => {

    async function updateAnalyticsInfoFiltering(initialDate, endDate) {
      setLoading(true);
      await getB2BConsumptionDataByCollab(collab?.id_client, initialDate, endDate).then(response => {
        if (response?.consumedHours) {
          const hours = response.consumedHours.hours;
          const minutes = response.consumedHours.minutes;
          const totalInDecimal = hours + minutes / 60;
          setUsedHours(totalInDecimal.toFixed(2));
        }
        if (response?.amountNetworkUsed) {
          setUsedUpload((response.amountNetworkUsed/1024/1024).toFixed(2));
        }
        setLoading(false);
      });
    }
    if (initialDate && endDate) {
      updateAnalyticsInfoFiltering(initialDate, endDate);
    }
  }, [initialDate, endDate]);

  return (
    <S.PopupInfoModalWrapper>
      <Overlay onClick={close} />
      <S.Container>
        {loading ? <Loading /> : 
        <S.Box>
          <S.CloseDiv>
            <Close close={close} />
          </S.CloseDiv>
          <S.Wrapper>
            <S.InfoSector>
              <S.SectorInfoTitleWrapper>
                <S.SectorInfoTitle>{collab?.name}</S.SectorInfoTitle>
                <S.EditActionButton onClick={() => handleEditCollab(collab)} title={actions(collab)?.edit?.title}>
                  {actions(collab)?.edit?.ico}
                </S.EditActionButton>
              </S.SectorInfoTitleWrapper>
              <S.PersonalInfoWrapper>
                <S.InfoWrapper>
                  <S.InfoTitle>Email</S.InfoTitle>
                  <S.Info>{collab?.email}</S.Info>
                </S.InfoWrapper>
                <S.InfoWrapper>
                  <S.InfoTitle>Função</S.InfoTitle>
                  <S.Info>{collab?.function}</S.Info>
                </S.InfoWrapper>
                <S.InfoWrapper>
                  <S.InfoTitle>Status</S.InfoTitle>
                  <S.InfoStatus active={collab?.active==='ACTIVE'}>{collab?.active==='ACTIVE' ? 'Ativo' : 'Suspenso'}</S.InfoStatus>
                </S.InfoWrapper>
              </S.PersonalInfoWrapper>
            </S.InfoSector>
            <S.InfoSector>
              <S.SectorInfoTitle>Utilizações do usuário</S.SectorInfoTitle>
              <DatePickerContent
                initialDate={initialDate}
                setInitialDate={handleInitialDateChange}
                endDate={endDate}
                setEndDate={handleEndDateChange}
              />
              <AnalyticsInfoSection>
                {analyticsInfo.map((info, index) => {
                  return (
                    <AnalyticsInfoCard
                      key={index}
                      title={info.title}
                      maxAmount={info.maxAmount}
                      unit={info.unit}
                      usedAmount={info.usedAmount}
                      planName={info.planName}
                    />
                  );
                })}
              </AnalyticsInfoSection>
            </S.InfoSector>
          </S.Wrapper>
        </S.Box>}
      </S.Container>
    </S.PopupInfoModalWrapper>
  );
};

export default PopupInfoModal;
