import MuiButton from '@material-ui/core/Button';
import React from 'react';
import styled from 'styled-components';
import { mqmax, desktopMinSize } from '@Styles/mixins';

export const Submit = ({ props }) => (
  <Button type="submit" variant="outlined" {...props}>
    CONTINUAR
  </Button>
);

export default Submit;

const Button = styled(MuiButton)`
  &.MuiButton-root {
    box-sizing: border-box;
    width: calc(170 / 16 * 1rem);
    border-color: #2d80f2;
    border-width: calc(3 / 16 * 1rem);
    border-radius: calc(32 / 16 * 1rem);
    padding: 10px;
    color: #2d80f2;
    font-weight: 600;
    background: transparent;
    transition: ${['color', 'background'].map(prop => `${prop} calc(1s / 3) ease-in-out`).join(',')};

    &.Mui-disabled {
      opacity: 0.5;
      cursor: not-allowed;
      border-width: calc(3 / 16 * 1rem);
      border-color: #2d80f2;
      color: #2d80f2;
      ${mqmax(desktopMinSize - 1)} {
        color: #fff;
      }
    }

    &:hover,
    &.active {
      color: #fff;
      background: #2d80f2;
    }

    ${mqmax(desktopMinSize - 1)} {
      width: 100%;
      background: #2d80f2;
      color: #fff;
      padding: 5px;
      font-size: calc(12 / 16 * 1rem);
    }
  }
`;
