import styled from 'styled-components';

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  row-gap: calc(20 / 16 * 1rem);
  max-width: 573px;
  width: 100%;
  position: relative;
`;

export default Div;
