import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Wrapper from './Wrapper';
import Overlay from './Overlay';
import PaymentCard from './PaymentCard';
import { PaymentAlert } from './PaymentAlert';
import {
  ERROR,
  OK,
  messageDisconnect,
} from '@Constants';
import { SessionExpired } from '@Errors';
import { openConfirmationLightbox } from '@Store/ducks/global/actions';
import { generateCardToken } from '../../../../../services/pagarme-api';
import { Loading } from '../../../../../components/Loading';
import AdressData from './AdressData';
import { sendRecurrencyCardChangeCall } from '../../../../../services/noar-api';

export const RecurrencyCardChangePopup = ({ close }) => {
  const [viewControl, handleViewControl] = useState(0);
  const [adressInfo, handleAdressInfo] = useState({ keepAdressOption: true, changedKeepAdressOption: false });
  const [cardInfo, handleCardInfo] = useState(null);
  const [confirmPaymentReturn, handleConfirmPaymentReturn] = useState(null);
  const [returnMessage, handleReturnMessage] = useState({});
  const [loading, handleLoading] = useState(false);
  const paymentAlertRef = useRef();
  const dispatch = useDispatch();

  const handleSendChangeCall = async () => {
    try {
      handleLoading(true);
      const cardTokenResponse = await generateCardToken(
        cardInfo.card.number,
        cardInfo.card.holder,
        {
          expiration_month: cardInfo.card.expiration_month,
          expiration_year: cardInfo.card.expiration_year.replace('20', ''),
        },
        cardInfo.card.security_code,
        cardInfo.card.brand
      );

      const requestObject = {
        token: cardTokenResponse?.id,
        payer: {
          address: {
            street: adressInfo.billing.endereco,
            number: adressInfo.billing.numero,
            complement: adressInfo.billing.complemento,
            neighborhood: adressInfo.billing.bairro,
            zip_code: adressInfo.billing.cep,
            state: adressInfo.billing.estado,
            country: 'BR',
            city: adressInfo.billing.cidade,
          }
        },
      };
      const paymentResponse = await sendRecurrencyCardChangeCall(requestObject);
      handleLoading(false);
      if (!paymentResponse.ok) {
        handleConfirmPaymentReturn({ status: ERROR });
      }
      else {
        handleConfirmPaymentReturn({ status: OK });
      }
    } catch (error) {
      handleLoading(false);
      if (error instanceof SessionExpired) {
        dispatch(openConfirmationLightbox(messageDisconnect));
        return;
      }
      console.error(error);
    }
  }

  const closeAlerts = () => {
    paymentAlertRef.current.close();
    handleViewControl(0);
    close();
  };

  const handleOpenResultStatusMessage = () => {
    if (paymentAlertRef.current?.state?.isOpen) {
      paymentAlertRef.current.closeModal();
    }
    paymentAlertRef.current.showModal();
  };

  const showChangeAlert = () => {
    if (confirmPaymentReturn?.status === OK) {
      handleReturnMessage({
        title: 'Cartão Alterado',
        message: `Seu cartão de cobrança foi alterado com sucesso!`,
        variant: 'denied',
      });
      handleOpenResultStatusMessage();
    } else if (confirmPaymentReturn?.status === ERROR) {
      handleReturnMessage({
        title: 'Ocorreu um erro',
        message: "Ocorreu um erro ao alterar o cartão de cobrança. Por favor, tente novamente mais tarde ou entre em contato com o suporte.",
        variant: 'denied',
      });
      handleOpenResultStatusMessage();
    }
  };

  useEffect(() => {
    if (cardInfo) {
      handleSendChangeCall();
    }
  }, [cardInfo]);

  useEffect(() => {
    showChangeAlert();
  }, [confirmPaymentReturn]);

  useEffect(() => {
    handleViewControl(1);
  }, []);

  return (
    <>
      <Wrapper>
        <Overlay onClick={closeAlerts} />
        {loading ? (
          <Loading whichLoading={'generic'} />
        ) : (
          <>
            {viewControl === 1 ? (
              <AdressData
                close={closeAlerts}
                handleViewControl={handleViewControl}
                adressInfo={adressInfo}
                handleAdressInfo={handleAdressInfo}
                handleConfirmPaymentReturn={handleConfirmPaymentReturn}
              />
            ) : []}

            {viewControl === 2 ? (
              <PaymentCard
                close={closeAlerts}
                cardInfo={cardInfo}
                handleCardInfo={handleCardInfo}
                handleLoading={handleLoading}
                handleViewControl={handleViewControl}
              />
            ) : []}

          </>)}
      </Wrapper>
      <PaymentAlert paymentAlertRef={paymentAlertRef} close={closeAlerts} {...returnMessage} />
    </>
  );
};

export default RecurrencyCardChangePopup;
