import styled from 'styled-components';

import {
  overlayZIndex,
  overlayOpacity,
  defaultFont,
  desktopMinSize,
  transform,
  displayColumn,
  mqmin,
  mqmax
} from '@Styles/mixins';

export const TermsBox = styled.section`
  display: ${(props)=> props.isOpen ? "block" : "none" };
  z-index: ${overlayZIndex + 1};
  position: fixed;
  top: 0;
  left: 0;

  .terms-form-box{

    &__overlay{
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, ${overlayOpacity});
      z-index: 1;
    }

    &__wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: auto;
      border: double 3px transparent;
      border-radius: 45px;
      background-image: linear-gradient(#21222a, #21222a), radial-gradient(circle at top left, #426FE5,#A00BF6);
      background-origin: border-box;
      background-clip: content-box, border-box;
      position: fixed;
      top: 50%;
      left: 50%;
      ${transform('translate(-50%, -50%)')}
      z-index: 2;
      ${mqmin(desktopMinSize)} {
        max-width: 863px;
        max-height: 100%;
      }
      ${mqmax(desktopMinSize - 1)} {
        border-radius: 35px;
        width: 90%;
      }
    }

    &__box {
      ${displayColumn()}
      font-family: ${defaultFont};
      font-style: normal;
      width: 75%;
      height: 100%;
      padding: calc(68 / 1056 * 100%) calc(53 / 1056 * 100%);
      align-items: center;
      row-gap: calc(20 / 16 * 1rem);
      display: flex;
      flex-direction: column;
      ${mqmax(desktopMinSize - 1)} {
        padding: calc(112 / 1056 * 100%) calc(53 / 1056 * 100%);
        width: 87%;
      }
    }

    &__title {
      font-size: calc(35 / 16 * 1rem);
      font-weight: 600;
      margin: 9px 0 0;
      color: #fff;
      ${mqmax(desktopMinSize - 1)} {
        text-align: center;
        font-size: calc(24 / 16 * 1rem);
      }
    }
  
    &__subtitle{
      font-size: calc(20 / 16 * 1rem);
      font-weight: 400;
      color: #fff;
      text-align: center;
      line-height: calc(25 / 16 * 1rem);
      ${mqmax(desktopMinSize - 1)} {
        font-size: calc(14 / 16 * 1rem);
        line-height: calc(21 / 16 * 1rem);
      }
    }
    
    &__text{
      font-size: calc(16 / 16 * 1rem);
      font-weight: 300;
      margin: 17px 0 0;
      line-height: calc(30 / 16 * 1rem);
      color: #fff;
      
      ${mqmax(desktopMinSize - 1)} {
        font-size: calc(10 / 16 * 1rem);
        margin: 6px 0 0;
        line-height: calc(19 / 16 * 1rem);
      }
      p {
        text-align: center;
        padding-bottom: calc(35 / 1056 * 100%);
      }
    }

    &__terms{
      padding-top: calc(30 / 1056 * 100%);
      display: flex ;
      justify-content: center;

      &__link{
        font-size: calc(20 / 16 * 1rem);
        font-weight: 700;
        text-decoration: none;
        margin: 0 10px;
        color: #426FE5;

        &:hover {
          text-decoration: underline;
        }
        
        ${mqmax(desktopMinSize - 1)} {
          text-align: center;
          justify-content: space-between;
          width: 100% ;
          font-size: calc(14 / 16 * 1rem);
        }
      }

      &__actions {
        width: 100%;
        height: auto;
        margin: 25px 0 0;
        display: flex;
        justify-content: center;
        column-gap: calc(20 / 16 * 1rem);

        ${mqmax(desktopMinSize - 1)} {
          justify-content: space-evenly;
        }

        &__button{
          padding: 13px;
          box-sizing: border-box;
          width: calc(160 / 16 * 1rem);
          border-width: calc(3 / 16 * 1rem);
          border-radius: calc(30 / 16 * 1rem);
          border: 3px #2d80f2 solid;
          color: #2d80f2;
          font-weight: 600;
          background: transparent;
          transition: ${['color', 'background'].map(prop => `${prop} calc(1s / 3) ease-in-out`).join(',')};

          &:hover,
          &.active {
            color: #fff;
            background: #2d80f2;
          }
          
          :disabled{
            opacity: 0.4;
            pointer-events: none;
          }
        
          ${mqmin(desktopMinSize)} {
            font-size: calc(15 / 16 * 1rem);
            line-height: 16px;
          }

          ${mqmax(desktopMinSize - 1)} {
            font-size: calc(12 / 16 * 1rem);
            padding: 8px;
          }
        }
      }
    }

    &__support{
      font-size: 1vh;
      font-weight: 400;
      display: flex;
      justify-content: center ;
      text-align: center ;
    }
  }
`;
