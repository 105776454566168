import styled from 'styled-components';
import { desktopMinSize, mqmax } from '../../../styles/mixins';

export const AMIModalContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  gap: 0.75rem;
  width: 148px;
  height: fit-content;
  padding: 25px;
  background: rgba(0, 0, 0, 0);
  backdrop-filter: blur(23px);
  -webkit-backdrop-filter: blur(23px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 13px;
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 9999;
  transition: opacity, z-index, 250ms ease-in-out;
  opacity: ${({ isFilterModalOpen }) => (isFilterModalOpen ? 1 : 0)};
  z-index: ${({ isFilterModalOpen }) => (isFilterModalOpen ? 999 : -1)};

  ${mqmax(desktopMinSize - 1)} {
    top: 45px;
    left: -172px;
  }
`;

export const AMIModalTitle = styled.label`
  color: #fff;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-size: 0.938rem;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  & > input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    & ~ span {
      background-color: transparent;
      border: 2px solid #fff;
      border-radius: 4px;
    }

    &:checked ~ span {
      background-color: #17171a;
    }

    &:checked ~ span:after {
      z-index: 1;
      opacity: 1;
      box-shadow: 0 0 0 3.8px #2d80f2;
    }
  }

  & > span {
    position: absolute;
    top: 0;
    left: 0;
    height: 12px;
    width: 12px;
    transition: all 250ms ease-in-out;

    &:after {
      content: '';
      position: absolute;
      opacity: 0;
      z-index: -1;
      top: 6px;
      left: 6px;
      transition: all 250ms ease-in-out;
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    padding-left: 20px;

    & > span {
      top: -1px;
    }

    input:checked ~ span:after {
      top: 5px;
      left: 5px;
    }
  }
`;
