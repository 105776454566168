import styled from 'styled-components';
import {
  mqmax,
  desktopMinSize
} from '@Styles/mixins';

export const Container = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  row-gap: calc(27 / 16 * 1rem);
  ${mqmax(desktopMinSize - 1)} {
    width: 69%;
    row-gap: calc(24 / 16 * 1rem);
  }
`;

export default Container;
