import { createContext, useContext } from 'react';
import Cookies from 'js-cookie';
import { SESSION_COOKIE_NAME, REDIRECT_TO_LOGIN } from '@Constants';
import * as sessionManager from '@Utils/session-manager';
const UserRoleContext = createContext({});

export function useUserRole() {
  return useContext(UserRoleContext);
}

export function UserRoleProvider({ children }) {
  if(sessionManager.isLogged()){
    const { clientType } = JSON.parse(Cookies.get(SESSION_COOKIE_NAME));
  
    return <UserRoleContext.Provider value={{ clientType }}>{children}</UserRoleContext.Provider>;
  }
  else{
    sessionManager.logout();
    window.location.href = REDIRECT_TO_LOGIN;
  }
}
