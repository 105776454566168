import React from 'react';
import styled from 'styled-components';
import {
  mqmax,
  desktopMinSize
} from '@Styles/mixins';

export const Image = props => <Img alt="" {...props} />;

export default Image;

const Img = styled.img`
  display: block !important;
  width: 608px !important;
  height: 800px !important;
  ${mqmax(desktopMinSize - 1)} {
    width: 337px !important;
    height: 599px !important;
  }
`;