import styled from 'styled-components';

import { rem } from '@Styles/mixins';

export const PaginationWrapper = styled.div`
  display: flex;
  gap: 0.25rem;

  button {
    border: 0;
    background: transparent;
    color: #fff;
    cursor: pointer;
    font-size: ${rem(14)};
    font-weight: 300;

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  strong {
    font-size: ${rem(14)};
    font-weight: 300;
    margin: 0;
    display: flex;
    align-items: center;
    margin: 0 10px;
  }

  span {
    font-size: ${rem(14)};
    font-weight: 600 !important;
  }
`;
