import styled from 'styled-components';

import { overlayZIndex } from '@Styles/mixins';

export const Overlay = styled.div`
  min-height: 100vh;
  width: 100vw;
  position: fixed;
  opacity: 0.4;
  inset: 0;
  background-color: #4c4c4c;
  z-index: ${overlayZIndex + 10};
`;

export default Overlay;
