const CURRENCY_FORMATTER = new Intl.NumberFormat(undefined, {
  style: 'currency',
  currency: 'BRL',
});

export function formatCurrency(number) {
  return CURRENCY_FORMATTER.format(number);
}

export function formatDollarCurrency(number) {
  return new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: 'USD',
  }).format(number);
}
