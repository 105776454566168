import React from 'react';
import { ShopPageFirstStepWrapper, ShopPageFirstStepContentWrapper, ShopPageFirstStepButtonWrapper } from './styled';
import { TutorialBackdrop } from '../styled';
export const FirstStep = ({ nextStep, skipTutorial }) => {
  return (
    <>
      <TutorialBackdrop />
      <ShopPageFirstStepWrapper>
        <ShopPageFirstStepContentWrapper>
          <strong>
            Para <span>fazer uma compra,</span> você vai clicar aqui
          </strong>
          <ShopPageFirstStepButtonWrapper>
            <button onClick={nextStep}>Entendi</button>
            <button onClick={skipTutorial}>Fechar tutorial</button>
          </ShopPageFirstStepButtonWrapper>
        </ShopPageFirstStepContentWrapper>
      </ShopPageFirstStepWrapper>
    </>
  );
};
