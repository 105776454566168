import React from 'react';
import Container from './Container';
import DataAccountContent from './DataAccountContent';
import CouponContent from './CouponContent';
import { TermsServices } from './TermsServices';
import { ProtectedComponent } from '@Components/ProtectedComponent';
import { RecurrencyControl } from './RecurrencyControl';
import ReferralVoucherContent from './ReferralVoucherContent';

function DataAccount() {
  return (
    <Container>
      <DataAccountContent showChangePasswordButton={true}/>
      <ProtectedComponent role={['B2C']}>
        <CouponContent />
      </ProtectedComponent>
      <RecurrencyControl/>
      <ProtectedComponent role={['B2C']}>
        <ReferralVoucherContent />
      </ProtectedComponent>
      {/* <DevicesContent /> */}
      <TermsServices />
    </Container>
  );
}

export default DataAccount;
