import React from 'react';
import {
  AMITutorialFifthStepWrapper,
  AMITutorialFifthStepContentWrapper,
  AMITutorialFifthStepButtonWrapper,
  TutorialBackdrop,
} from '../styled';

export const FifthStep = ({ nextStep, skipTutorial }) => {
  return (
    <>
      <TutorialBackdrop />
      <AMITutorialFifthStepWrapper>
        <AMITutorialFifthStepContentWrapper>
          <strong>
            Clicando aqui, você assistirá um vídeo detalhado de <span>como se conectar na NOAR,</span> recomendamos que
            veja, é rápido
          </strong>

          <AMITutorialFifthStepButtonWrapper>
            <button onClick={nextStep}>Entendi</button>
            <button onClick={skipTutorial}>Fechar tutorial</button>
          </AMITutorialFifthStepButtonWrapper>
        </AMITutorialFifthStepContentWrapper>
      </AMITutorialFifthStepWrapper>
    </>
  );
};
