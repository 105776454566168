import styled from 'styled-components';
import { desktopMinSize, mqmax, overlayZIndex } from '../../../../styles/mixins';

export const PopupInfoModalWrapper = styled.div`
  z-index: ${overlayZIndex + 1};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
`;

export const Container = styled.div`
  width: 100%;
  z-index: 2;
  position: relative;
  border-radius: 38px;
  max-width: calc(900 / 16 * 1rem);
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: calc(55 / 1056 * 100%) calc(59 / 1056 * 100%);
  border: solid transparent calc(5 / 16 * 1rem);
  border-radius: 38px;
  background: #21222a 0% 0% no-repeat padding-box;
  transform: scale(0.99);
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  width: 100%;
  padding: 1rem 0 1rem ;
  
  ${mqmax(desktopMinSize-1)}{
    gap: 2rem;
  }
`;

export const CloseDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  height: auto;
`;

export const InfoSector = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2rem;
  width: 100%;

  ${mqmax(desktopMinSize-1)}{
    gap: 1.5rem;
  }
`;

export const SectorInfoTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
`;

export const PersonalInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 70%;
  
  ${mqmax(desktopMinSize-1)}{
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1.5rem;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;

  ${mqmax(desktopMinSize-1)}{
    gap: 0.5rem;
  }
`;

export const SectorInfoTitle = styled.p`
  font-size: calc(24 / 16 * 1rem);
  font-weight: 700;
  color: #ffffff;
`;

export const InfoTitle = styled.p`
  font-size: calc(16 / 16 * 1rem);
  font-weight: 700;
  color: #ffffff;
`;

export const Info = styled.p`
  font-size: calc(16 / 16 * 1rem);
  font-weight: 400;
  color: '#ffffff';
`;

export const InfoStatus = styled.p`
  font-size: calc(16 / 16 * 1rem);
  font-weight: 400;
  color: ${({ active }) => (active ? '#ffffff' : '#F83030')};
`;

export const EditActionButton = styled.div`
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    color: #426FE5;
  }

  .MuiSvgIcon-root {
    transition: 0.2s all ease-in-out;

    &:hover {
      border: 1px SOLID #426FE5;
    }
  }
`;
