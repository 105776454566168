import { AssembleInfoRectangleWrapper, AssemblePhaseTitle, InfoP, InfoTitle } from "../styled"
import * as S from './styled'
import { COMPUTER_INFOS as infos }  from '@Constants';
const AssembleDetailPhase = () => {

    return (
      <S.AssemblePhaseWrapper>
        <AssemblePhaseTitle>Especificações</AssemblePhaseTitle>
        <S.AssembleDetailPhaseWrapper phase='specs'>
          {infos.map((info, index) => (
            <AssembleInfoRectangleWrapper key={index}>
              {info.icon}
              <S.MockedAssembleDetailsWrapper>
                <InfoTitle>{info.title}</InfoTitle>
                <S.InfoDescription>{info.description}</S.InfoDescription>
              </S.MockedAssembleDetailsWrapper>
            </AssembleInfoRectangleWrapper>
          ))}
        </S.AssembleDetailPhaseWrapper>
        <InfoP>*Em breve traremos a possibilidade de escolher também esses aspectos do computador!</InfoP>
      </S.AssemblePhaseWrapper>
    )
}

export default AssembleDetailPhase;