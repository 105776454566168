import GoldImagesTable from "../../components/GoldImagesTable";
import MobileHeader from "../../components/Header";
import * as S from "./styled";

const GoldImagesPanel = () => {
    return (
        <>
            <MobileHeader />
            <S.GoldImagesPanelWrapper>
                <GoldImagesTable />
            </S.GoldImagesPanelWrapper>
        </>
    );
};

export default GoldImagesPanel;