import styled from 'styled-components';

import { mqmax, desktopMinSize, rem } from '@Styles/mixins';

export const ConfirmationSuccessPageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70% ;

  ${mqmax(desktopMinSize -1)} {
    height: 100%;
  }
`;

export const ConfirmationSuccessPageWrapper = styled.div`
  max-width: 363px;
  width: 100%;
  min-height: 330px;
  padding: 59px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 59px;

  ${mqmax(desktopMinSize - 1)} {
    background: transparent;
  }

  .logo {
    display: none;
    ${mqmax(desktopMinSize - 1)} {
      display: block;
      width: 161px;
      height: 35px;
    }
  }

  img {
    width: 52px;
    aspect-ratio: 1;
    margin-bottom: 28px;
    ${mqmax(desktopMinSize - 1)} {
      display: none;
    }
  }

  strong {
    color: #fff;
    font-weight: 300;
    font-size: ${rem(41)};
    text-align: center;
    text-transform: uppercase;
    max-width: 12ch;
    line-height: 1.1;

    ${mqmax(desktopMinSize - 1)} {
      font-size: ${rem(33)};
    }

    b {
      font-weight: 700;
    }

    span {
      font-weight: 700;
      color: #2d80f2;
    }
  }

  a {
    color: #fff;
    text-decoration: none;
    transition: color 300ms;
    display: block;
    margin-top: 25px;

    ${mqmax(desktopMinSize - 1)} {
      font-size: ${rem(12)};
      margin-top: 16px;
      color: #a00bf6;
    }

    &:hover {
      color: #a00bf6;
    }
  }
`;
