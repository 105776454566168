import styled from 'styled-components';
import { desktopMinSize, mq, mqmax, mqmin } from '../../../../../styles/mixins';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 437px;
  gap: 2.5rem;
  min-width: 508px;

  ${mq(desktopMinSize + 300, desktopMinSize)} {
    gap: 1rem;
  }

  ${mqmax(desktopMinSize - 1)} {
    gap: 1.5rem;
    min-width: auto;
    height: auto;
    max-width: 100%;
  }

  ${mqmin(desktopMinSize + 301)} {
    width: 100%;
  }

  ${mqmin(1900)} {
    gap: 5rem;
    height: 650px;
  }
`;

export const AssembleInfoRectangleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: #252735;
  border-radius: 0.5rem;
  box-sizing: border-box;
  border: ${({ isSelect }) => (isSelect ? `2px solid #426FE5` : '2px solid #2c2d41')};
  padding: 0.2rem;
  width: 107px;
  height: 80px;

  ${({ phase }) =>
    phase === 'hours' &&
    `
        cursor: pointer;   
        &:hover{
          border: 2px solid #426FE5;
        }
    `}

  .MuiSvgIcon-root {
    color: #fff;
    font-size: 1.5rem;

    ${({ phase }) => phase === 'hours' && `font-size: 2rem;`}

    ${mqmin(1900)} {
      font-size: 2.5rem;
    }

    ${mq(desktopMinSize + 300, desktopMinSize)} {
      font-size: 1.1rem;
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    gap: 0.2rem;
  }

  ${mqmin(1900)} {
    width: 140px;
    height: 100px;
  }

  ${mq(desktopMinSize + 300, desktopMinSize)} {
    width: 80px;
    height: 60px;
  }
`;

export const AssembleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  height: 100%;

  ${mq(desktopMinSize + 300, desktopMinSize)} {
    max-width: 410px;
    gap: 0.5rem;
  }
  ${mqmax(desktopMinSize - 1)} {
    flex-direction: column;
    gap: 1rem;
    width: 279px;
    align-items: center;
  }

  ${mqmax(330)} {
    width: 100%;
  }

  ${mqmin(1900)} {
    max-width: 641px;
  }
`;

export const AssembleDetailPhaseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
`;

export const AssembleInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.8rem;
  width: 100%;

  .MuiSlider-root {
    color: #426fe5;
  }

  .MuiSlider-track {
    border-radius: 6px;
    height: 6px;
  }

  .MuiSlider-rail {
    border-radius: 6px;
    height: 6px;
  }

  .MuiSlider-thumb {
    height: 12px;
    width: 12px;
    margin-top: -3.5px;
  }
  .MuiSlider-mark {
    display: none;
  }

  .MuiSlider-markActive {
    background-color: #426fe5;
  }

  ${mqmax(desktopMinSize - 1)} {
    align-items: center;
    gap: 0.5rem;
  }
`;

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap: 1rem;
  min-width: 350px;
  width: 80%;

  ${mqmax(desktopMinSize - 1)} {
    gap: 0.5rem;
    min-width: unset;
    width: 100%;
  }
`;

export const HorizontalDivider = styled.div`
  max-width: 120px;
  width: 100%;
  height: 1px;
  background-color: #3a3b44;
  display: block;
  margin: 0 auto;
  ${mqmax(desktopMinSize - 1)} {
    max-width: 80px;
  }
`;

export const InfoValueWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;

export const TotalPriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.1rem;

  ${mqmin(desktopMinSize)} {
    min-width: 200px;
  }
`;

export const PurchasePhaseOneWrapper = styled.div`
  display: ${({ phase }) => (phase === 0 ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  gap: 2.5rem;
  width: 100%;

  ${mq(desktopMinSize + 300, desktopMinSize)} {
    gap: 0.6rem;
  }

  ${mqmax(desktopMinSize - 1)} {
    width: auto;
  }
`;

export const PurchasePhaseTwoWrapper = styled.div`
  display: ${({ phase }) => (phase === 1 ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  width: 100%;
`;

export const PurchasePhaseThreeWrapper = styled.div`
  display: ${({ phase }) => (phase === 2 ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  width: 100%;

  ${mq(desktopMinSize + 300, desktopMinSize)} {
    max-width: 410px;
  }
`;

export const PriceText = styled.p`
  font-size: 0.7rem;
  font-weight: 700;
  text-align: center;
  color: #fff;
  width: 100%;
  ${mqmax(desktopMinSize - 1)} {
    font-size: 0.7rem;
  }

  ${mqmin(1900)} {
    font-size: 1rem;
  }
`;

export const FooterButton = styled.button`
  width: 100%;
  height: 2rem;
  background-color: #426fe5;
  border: none;
  border-radius: 2rem;
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
  transition: 0.3s;
`;

export const Title = styled.p`
  font-size: 1.5rem;
  font-weight: 700;
  color: #426fe5;

  ${mqmax(desktopMinSize - 1)} {
    font-size: 1.5rem;
  }

  ${mqmin(1900)} {
    font-size: 4rem;
  }

  ${mq(desktopMinSize + 300, desktopMinSize)} {
    font-size: 1.2rem;
  }
`;

export const InfoValue = styled.p`
  font-weight: 700;
  font-size: 1rem;

  ${mqmin(1900)} {
    font-size: 2rem;
  }
`;

export const InfoPriceValue = styled.p`
  font-weight: 400;
  font-size: 1rem;
  text-align: center;

  ${mqmin(1900)} {
    font-size: 2rem;
  }
`;

export const InfoP = styled.p`
  font-weight: 200;
  font-size: 0.8rem;
  line-height: 1rem;
  text-align: center;

  ${mqmax(desktopMinSize - 1)} {
    font-size: 0.5rem;
  }

  ${mq(desktopMinSize + 300, desktopMinSize)} {
    font-size: 0.5rem;
  }
`;

export const InfoPStrong = styled.strong`
  font-weight: 700;
  font-size: 1rem;

  ${mqmax(desktopMinSize - 1)} {
    font-size: 0.7rem;
  }

  ${mq(desktopMinSize + 300, desktopMinSize)} {
    font-size: 0.7rem;
  }
`;

export const AssemblePhaseTitle = styled.h3`
  font-size: 1.2rem;
  font-weight: 700;
  color: #fff;
  width: 100%;

  strong {
    color: #426fe5;
    font-size: 0.8rem;
  }

  ${mqmin(1900)} {
    font-size: 2rem;
    strong {
      font-size: 1rem;
    }
  }

  ${mq(desktopMinSize + 300, desktopMinSize)} {
    font-size: 0.8rem;
    strong {
      font-size: 0.6rem;
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    text-align: center;
    strong {
      font-size: 0.6rem;
    }
  }
`;

export const InfoTitle = styled.p`
  font-size: 0.6rem;
  font-weight: 700;

  ${mqmax(desktopMinSize - 1)} {
    font-size: 0.7rem;
    font-weight: 700;
  }

  ${mq(desktopMinSize + 300, desktopMinSize)} {
    font-size: 0.5rem;
  }

  ${mqmin(1900)} {
    font-size: 1rem;
  }
`;

export const PriceValue = styled.strong`
  display: flex;
  align-items: center;
  gap:0.5rem;
  font-weight: 700;
  font-size: 2rem;

  ${mqmax(desktopMinSize - 1)} {
    font-size: 0.8rem;
  }
`;

export const VerticalDivider = styled.div`
  width: 1px;
  height: 50%;
  background-color: #3a3b44;
  display: block;

  ${mqmax(desktopMinSize - 1)} {
    display: none;
  }
`;

export const AlreadyHaveText = styled.p`
  font-size: 0.8rem;
  font-weight: 700;
  color: #426fe5;
  text-align: center;

  ${mqmin(1900)} {
    font-size: 1.5rem;
  }
`;

export const P = styled.p`
  margin-top: -1.5rem;
  font-size: 1rem;
  font-weight: 400;
  color: #2d80f2;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: #426fe5;
  }

  ${mq(1400, desktopMinSize)} {
    font-size: 0.8rem;
    margin-top: -0.5rem;
  }

  ${mqmin(1900)} {
    font-size: 1.5rem;
    margin-top: -4rem;
  }

  ${mqmax(desktopMinSize - 1)} {
    margin-top: 0;
  }
`;
