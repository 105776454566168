import styled from 'styled-components';

import { desktopMinSize, mqmax } from '@Styles/mixins';

export const Container = styled.div`
    min-height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    ${mqmax(desktopMinSize - 1)} {
        padding: 0;
    }
`;

export default Container;
