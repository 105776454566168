import React, { useState } from 'react';
import Form from './Form';
import Input from './Input';
import Footer from './Footer';
import Submit from './Submit';
import InputAdornment from '@material-ui/core/InputAdornment';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import IconButton from '@material-ui/core/IconButton';
import ErrorMessage from './ErrorMessage';
import SuccessMessage from './SuccessMessage';
import Loading from '@Containers/Overlay';
import * as NoarAPI from '@Services/noar-api';
import PasswordStrengthChecker from '../../../CreatePassword/CreatePasswordBox/Content/PasswordCheckbox';

export const UpdatePasswordContent = () => {

    const [currentPassword, handleCurrentPassword] = useState('');
    const [newPassword, handleNewPassword] = useState('');
    const [passwordConfirmation, handlePasswordConfirmation] = useState('');
    const [showActualPassword, handleShowActualPassword] = useState(false);
    const [showNewPassword, handleShowNewPassword] = useState(false);
    const [showConfirmPassword, handleShowConfirmPassword] = useState(false);
    const [errorUpdatePasswordMessage, handleErrorUpdatePasswordMessage] = useState('');
    const [successUpdatePasswordMessage, handleSuccessUpdatePasswordMessage] = useState('');
    const [loading, handleLoading] = useState(false);

    // ASYNC FUNCTIONS

    async function saveChangePassword() {
        try {
            handleLoading(true);
            const previous_password = currentPassword;
            const proposed_password = newPassword;
            await NoarAPI.updatePassword(previous_password, proposed_password);
            handleLoading(false);
            handleSuccessUpdatePasswordMessage('Senha alterada com sucesso!');
            resetErrorSuccessMessage();
        } catch (error) {
            handleLoading(false);
            handleErrorUpdatePasswordMessage(error.message);
            resetErrorSuccessMessage();
        }
    }
    // EVENTS

    function onChangeCurrentPassword(event) {
        handleCurrentPassword(event.target.value);
    }

    function onChangeNewPassword(event) {
        handleNewPassword(event.target.value);
    }

    function onChangePasswordConfirmation(event) {
        handlePasswordConfirmation(event.target.value);
    }

    function onSubmitPasswordForm(ev) {
        ev.preventDefault();

        if (newPassword !== passwordConfirmation) {
            handleErrorUpdatePasswordMessage('Confirmação de senha incorreta');
            resetErrorSuccessMessage();
            return false;
        } else if (!containsLowerCase(newPassword) || !containsUpperCase(newPassword) || !containsNumber(newPassword) || !containsSpecialChar(newPassword)) {
            handleErrorUpdatePasswordMessage('A senha não atende os padrões de segurança');
            resetErrorSuccessMessage();
            return false;
        } else {
            saveChangePassword();
        }
    }

    function containsLowerCase(str) {
        return /[a-z]/.test(str);
    }
    
    function containsUpperCase(str) {
      return /[A-Z]/.test(str);
    }
    
    function containsNumber(str) {
        return /\d/.test(str);
    }
    
    function containsSpecialChar(str){
      return /[!@#$%^&*(),.?":{}|<>]/.test(str);
    }

    function onClickShowActualPassword() {
        handleShowActualPassword(!showActualPassword);
    }

    function onClickShowNewPassword() {
        handleShowNewPassword(!showNewPassword);
    }

    function onClickShowConfirmPassword() {
        handleShowConfirmPassword(!showConfirmPassword);
    }

    function resetErrorSuccessMessage() {
        setTimeout(function () {
            handleErrorUpdatePasswordMessage('');
            handleSuccessUpdatePasswordMessage('');
        }, 5000);
    }

    return (
        <>
            {loading ? <Loading /> : []}
            <Form onSubmit={onSubmitPasswordForm}>
                <Input
                    type={showActualPassword ? 'text' : 'password'}
                    name="CurrentPassword"
                    placeholder="Digite sua senha atual"
                    required
                    value={currentPassword}
                    onChange={onChangeCurrentPassword}
                    minLength="14"
                    maxLength="64"
                    autoComplete="off"
                    variant="outlined"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end" style={{ marginLeft: 0 }}>
                                <IconButton style={{ padding: 0 }} aria-label="showPassword" component="a" onClick={onClickShowActualPassword}>
                                    {showActualPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
                <Input
                    type={showNewPassword ? 'text' : 'password'}
                    name="newPassword"
                    placeholder="Digite sua nova senha"
                    required
                    value={newPassword}
                    onChange={onChangeNewPassword}
                    minLength="14"
                    maxLength="64"
                    autoComplete="off"
                    variant="outlined"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end" style={{ marginLeft: 0 }}>
                                <IconButton style={{ padding: 0 }} aria-label="showPassword" component="a" onClick={onClickShowNewPassword}>
                                    {showNewPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
                <Input
                    type={showConfirmPassword ? 'text' : 'password'}
                    name="passwordConfirmation"
                    placeholder="Confirme sua nova senha"
                    onChange={onChangePasswordConfirmation}
                    minLength="14"
                    maxLength="64"
                    value={passwordConfirmation}
                    autoComplete="off"
                    variant="outlined"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end" style={{ marginLeft: 0 }} >
                                <IconButton style={{ padding: 0 }} aria-label="showPassword" component="a" onClick={onClickShowConfirmPassword}>
                                    {showConfirmPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
                <PasswordStrengthChecker password={newPassword}direction="column" />
                {errorUpdatePasswordMessage ? <ErrorMessage>{errorUpdatePasswordMessage}</ErrorMessage> : []}
                {successUpdatePasswordMessage ? <SuccessMessage>{successUpdatePasswordMessage}</SuccessMessage> : []}
                <Submit />
                <Footer />
            </Form>
        </>
    )

}

export default UpdatePasswordContent;