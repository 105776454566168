import React from 'react';

import InProgress from '@Containers/InProgress';
import Hello from './Hello';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  min-height: 100%;
`;

function MySettingsPage() {
  return (
    <Container>
      <Hello />
      <InProgress />
    </Container>
  );
}

export default MySettingsPage;