import MuiButton from '@material-ui/core/Button';
import styled from 'styled-components';
import React from 'react';
import { mqmax, desktopMinSize } from '@Styles/mixins';

export const Submit = () => (
  <Button type="submit" variant="outlined">
    ENTRAR
  </Button>
);

export default Submit;

const Button = styled(MuiButton)`
  &.MuiButton-root {
    max-width: 341px;
    margin: 0 auto;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border-color: #2d80f2;
    border-width: calc(3 / 16 * 1rem);
    border-radius: calc(24 / 16 * 1rem);
    color: #fff;
    font-weight: 600;
    background: #2d80f2;
    transition: ${['background', 'border-color'].map(prop => `${prop} calc(1s / 3) ease-in-out`).join(',')};

    &:hover,
    &.active {
      background: #a00bf6;
      border-color: #a00bf6;
      color: #fff;
    }
    ${mqmax(desktopMinSize - 1)} {
      padding: 5px;
    }
  }
`;
