import styled from "styled-components";
import { desktopMinSize, mq, mqmax, mqmin } from "../../../../../styles/mixins";

export const ImagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

export const LogoPersonalImg = styled.img`
  width: 40%;
  object-fit: contain;
  z-index: 2;

  ${mq(1381, 1900)}{
    width: 30%;
  }

  ${mqmax(desktopMinSize - 1)} {
    width: 55%;
  }
`;

export const PersonalPlanCardInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  width: 100% ;
  gap: 1.2rem;

  & > div:first-child {
    text-align: center;
  }

  & > div:last-child {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    ${mqmax(desktopMinSize - 1)} {
      margin-left: -9px;
    }
  }

  ${mq((desktopMinSize + 300), desktopMinSize)} {
    gap: 0.5rem;
  }

  ${mqmax(desktopMinSize - 1)} {
    gap: 0.5rem; 
  }
`;

export const ComputerImage = styled.img`
  width: 40%;
  object-fit: contain;
  position: absolute;
  z-index: 1;
  margin-top: -8rem;

  ${mqmin(2001)} {
    width: 30%;
    margin-top: -11rem;
    margin-left: 10rem;
  }

  ${mq((2000), 1381)} {
    width: 40%;
    margin-top: -11rem;
  }

  ${mq((desktopMinSize + 300), desktopMinSize)} {
    width: 30%;
    margin-top: -12rem;
  }

  ${mqmax(desktopMinSize - 1)} {
    display: none;
  }
`;