import React from 'react';
import styled from 'styled-components';

import items from './items';

export function Redes() {
  return (
    <List>
      {items.map(rede => (
        <Rede key={rede.link.href} {...rede} />
      ))}
    </List>
  );
}

export default Redes;

function Rede({ img, link }) {
  return (
    <Link target="_blank" rel="noreferrer" {...link}>
      <Img {...img} />
    </Link>
  );
}

const List = styled.div`
  display: flex;
  align-items: end;
  column-gap: calc(12rem / 16);
`;

const Link = styled.a`
  display: flex;
`;

const Img = styled.img`
  width: calc(${({ width }) => width}rem / 16);
  height: calc(${({ height }) => height}rem / 16);
`;
