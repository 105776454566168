import styled from "styled-components";
import MuiButton from '@material-ui/core/Button';
import { desktopMinSize, mqmax } from "../../../styles/mixins";

export const StyledButton = props => (
    <Button variant="outlined" {...props} />
  );
  
export default StyledButton;

const Button = styled(MuiButton)`
  &.MuiButton-root {
    padding: 0.3rem;
    box-sizing: border-box;
    width: 50%;
    border-color: #426FE5;
    border-width: calc(3 / 16 * 1rem);
    border-radius: calc(24 / 16 * 1rem);
    color: #fff;
    font-weight: 600;
    background: #426FE5;
    transition: ${['background', 'border-color'].map(prop => `${prop} calc(1s / 3) ease-in-out`).join(',')};
    &:hover {
        background: #A00BF6;
        border-color: #A00BF6;
        color: #fff;
    }
    &:disabled {
      color: #fff;
      opacity: 0.5;
    }
    font-size: 0.65rem;
    ${mqmax(desktopMinSize - 1)} {
      width:70%;
      padding: 5px;
      font-size: 0.6rem;
    }
    
  }
`;