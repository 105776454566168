import * as S from './styled';
import { SellPopupItem } from './SellPopup';
import { Link } from 'react-router-dom';
import Benefits from './Benefits';
import HowToUseBusiness from './HowToUse';
import Partners from './Partners';
import { PlansBusiness } from './PlansBusiness';
import AboutBusiness from './About';
import ContactUsBusiness from './ContactUsBusiness';
import React, { useEffect, useState } from 'react';

function ForBusinessWrapper() {

  const ItemPopup =   {
    descriptions: [
      'Redução de custos',
      'Facilidade e escalabilidade',
      'Segurança e confiabilidade', 
      'GPU de alta performance',
    ],
    title: 'Noar Enterprise',
    price: {
      text: 'A partir de',
      value: '189.40',
    },
    spot: false,
    storage: false,
    time: 'Gerenciável',
    hd: true,
    defaultSettings: true,
    licenses: false,
  };

  const openWhatsapp = () => {
    window.open("https://wa.me/5511964543593?text=Olá, gostaria de conhecer melhor a Noar B2B!",'_blank');
  }

  return (
    <S.Container>
      <S.ContentWrapper>
        <S.Presentation>
          <S.Title>Maximize a produtividade da sua equipe de
            <S.StyledPurpleText> qualquer lugar e dispositivo com a Noar.</S.StyledPurpleText>
          </S.Title>
          <S.PresentationP>
            Entre em contato agora e conheça nossos planos a partir de $189,40.
          </S.PresentationP>
          <S.PresentationButton onClick={openWhatsapp} variant='outlined'>Entre em contato</S.PresentationButton>
        </S.Presentation>
        <Benefits/>
        <HowToUseBusiness/>
        <Partners/>
        <PlansBusiness/>
        <AboutBusiness/>
        <ContactUsBusiness/>
      </S.ContentWrapper>
      <SellPopupItem id='enterprise-sellpopup' {...ItemPopup}></SellPopupItem>
    </S.Container>
  );
}

export const ForBusiness = () => <ForBusinessWrapper />;

export default ForBusiness;
