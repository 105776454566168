import styled from 'styled-components';

import { mqmax, desktopMinSize, mq } from '@Styles/mixins';
import { mqmin } from '../../../../styles/mixins';

export const PersonalPlanCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8rem;
  padding: 35px;
  background-color: transparent;
  max-height: fit-content;
  position: relative;
  width: 100%;

  &:nth-child(1) {
    z-index: ${({ step }) => (step === 1 ? '104' : '1')};
    pointer-events: ${({ step }) => (step === 1 ? 'none' : 'unset')};
  }

  &:nth-child(3) {
    & > div:nth-child(2) > button:nth-child(2) {
      z-index: ${({ step }) => (step === 3 ? '104' : '1')};
    }
  }
  &:nth-child(2) {
    & > div:nth-child(2) > button:nth-child(3) {
      z-index: ${({ step }) => (step === 3 ? '104' : '1')};
    }
  }

  ${mq(desktopMinSize + 300, desktopMinSize)} {
    align-items: center;
    min-width: 200px;
    padding: 5px 35px;
    max-height: 95%;
  }

  ${mq(desktopMinSize - 1, 331)} {
    align-items: center;
    padding: 1rem 0rem 0rem;
    border-radius: 22px;
    width: 279px;
    flex-direction: column;
    transition: max-height 500ms ease-in-out;
    gap: 1rem;
    margin-bottom: 25px;
  }

  ${mqmax(330)} {
    align-items: center;
    padding: 1rem 0rem 0rem;
    border-radius: 22px;
    width: 200px;
    flex-direction: column;
    transition: max-height 500ms ease-in-out;
    gap: 1rem;
    margin-bottom: 25px;
  }
`;

export const InfoDivWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.2rem;

  ${mq(desktopMinSize + 300, desktopMinSize)} {
    gap: 0.8rem;
  }

  ${mqmax(desktopMinSize - 1)} {
    gap: 0.1rem;
    align-items: flex-start;
  }

  ${mqmin(1900)} {
    width: 100%;
  }
`;

export const PersonalPlanCardTitle = styled.strong`
  font-weight: 600 !important;
  font-size: 1.875rem !important;
  display: block;

  ${mq(desktopMinSize + 300, desktopMinSize)} {
    font-size: 1.5rem !important;
  }

  ${mqmax(desktopMinSize - 1)} {
    font-size: 1.063rem !important;
    margin: 10px 0 0 !important;
  }
`;

export const PersonalPlanCardInfo = styled.p`
  text-align: left;
  min-width: 266px;
  font-weight: 300;
  font-size: 0.9rem;
  line-height: 1.1rem;

  ${mqmax(desktopMinSize - 1)} {
    min-width: 145px;
    font-size: 0.8rem;
    text-align: justify;
  }

  ${mq(desktopMinSize + 300, desktopMinSize)} {
    margin: 1.5rem 0;
    font-size: 0.8rem;
  }

  ${mqmin(1900)} {
    min-width: 145px;
    margin: 12px 0;
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
`;

export const ContractDivWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.2rem;
  width: 100%;
  height: 100%;

  ${mq(desktopMinSize + 300, desktopMinSize)} {
    gap: 0.5rem;
  }
`;

export const PersonalPlanCardPrice = styled.strong`
  font-size: 0.875rem !important;
  color: #6a6a6b;
  font-weight: 500 !important;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;

  & > span {
    font-weight: 600;
    color: #fff;
    font-size: 2.75rem;
  }

  ${mq(desktopMinSize + 300, desktopMinSize)} {
    font-size: 0.6rem !important;
    margin: 0 !important;

    & > span {
      font-size: 1.5rem;
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    font-size: 0.5rem !important;
    margin: 0 !important;

    & > span {
      font-size: 1.5rem;
    }
  }
`;

export const PersonalPlanCardButton = styled.button`
  background-color: ${({ onWaiting }) => (onWaiting ? `orange` : '#2d80f2')};
  max-width: 200px;
  min-width: 150px;
  height: 38px;
  color: #fff;
  text-transform: uppercase;
  border-radius: 25px;
  transition: background-color 250ms;
  font-size: ${({ onWaiting }) => (onWaiting ? `0.8rem` : '0.938rem')};
  text-transform: none;

  &:hover:not(:disabled) {
    background-color: #9e0cf6;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  ${mq(desktopMinSize + 300, desktopMinSize)} {
    font-size: ${({ onWaiting }) => (onWaiting ? `0.8rem` : '1rem')};
    
  }

  ${mqmax(380)} {
    width: inherit;
    max-width: 100px;
    min-width: auto;
    height: 32px;
    font-size: 0.6rem;
  }

  ${mqmin(1900)} {
    font-size: ${({ onWaiting }) => (onWaiting ? `1rem` : '1.5rem')};
    width: 250px;
    height: 50px;
  }
`;

export const PersonalPlanCardKnowMoreButton = styled.button`
  background: transparent;
  font-size: 0.875rem;
  color: #2d80f2;
  text-decoration: underline;
  transition: color 250ms;

  &:hover {
    color: #9e0cf6;
  }

  ${mqmax(desktopMinSize - 1)} {
    font-size: 0.6rem;
  }

  ${mqmin(desktopMinSize)} {
    font-size: 1.2rem;
  }

  ${mq(desktopMinSize + 300, desktopMinSize)} {
    font-size: 0.8rem;
  }
`;

export const CloseIcon = styled.button`
  position: absolute;
  background: transparent;
  border: 0;
  opacity: 0.24;
  right: 3%;
  top: 6%;

  display: block;

  ${mqmax(desktopMinSize - 1)} {
    display: none;
  }
`;

export const PersonalPlanSingle = styled.div`
  min-width: 471px;
  & > strong {
    font-size: 0.875rem !important;
    font-weight: 300;

    span {
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    min-width: max-content;
    strong {
      font-size: 0.563rem !important;
      max-width: max-content !important;
      margin: 0 !important;
    }

    &:last-child > strong {
      margin-top: 5px !important;
      display: block;
    }
  }
`;

export const PersonalPlanSingleContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;

  & + & {
    margin-top: 25px;
  }

  div:first-child {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    strong {
      font-size: 1.875rem;
      font-weight: 600;
    }

    span {
      font-size: 0.688rem;
      font-weight: 300;

      b {
        font-weight: 600;
      }
    }

    ${mqmax(desktopMinSize - 1)} {
      strong {
        font-size: 1.063rem !important;
      }

      span {
        font-size: 0.5rem;
      }
    }
  }

  div:last-child {
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 0.5rem;
    width: 100%;
    max-width: 181px;

    button:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    ${mqmax(desktopMinSize - 1)} {
      align-items: flex-end;

      button:first-child {
        max-width: 100px;
        height: 28px;
        font-size: 8px;
      }

      button:last-child {
        font-size: 8px;
        margin-right: 6px;
      }
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    & + & {
      margin-top: 15px;
    }
  }
`;

export const PersonalPlanBuyButton = styled.button`
  max-width: 181px;
  width: 100%;
  height: 50px;
  background-color: #2d80f2;
  transition: background-color 350ms;
  color: #fff;
  text-transform: uppercase;
  font-size: 15px;
  border-radius: 25px;

  &:hover:not(:disabled) {
    background-color: #9e0cf6;
  }
`;

export const PersonalPlanAddToCartButton = styled.button`
  text-decoration: underline;
  color: #2d80f2;
  background: none;
  border: 0;
  font-size: 0.688rem;
  transition: color 350ms;

  &:hover:not(:disabled) {
    color: #9e0cf6;
  }
`;

export const HorizontalDivider = styled.div`
  max-width: 180px;
  width: 100%;
  height: 1px;
  background-color: #3a3b44;
  display: block;
  margin: 0 auto;

  ${mqmax(desktopMinSize - 1)} {
    display: none;
  }
`;

export const AboutPersonalWrapper = styled.dialog`
  max-width: 863px;
  width: 100%;
  height: 835px;
  border-radius: 45px;
  background-color: #17171a;
  border: 0;
  box-shadow: 0 0 0 100vw rgba(0, 0, 0, 0.6);

  &[open] {
    animation: slide-up 350ms forwards, fade-in 350ms forwards;
  }

  &[closing] {
    display: block;
    pointer-events: none;
    inset: 0;
    animation: slide-down 350ms forwards, fade-out 350ms forwards;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 50px;
    padding: 3px;
    background: linear-gradient(132deg, #2d80f2 0%, #9b0cee 100%);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;

    ${mqmax(desktopMinSize - 1)} {
      height: 922px;
      padding: 2px;
      border-radius: 30px;
    }
  }

  & > div {
    position: relative;
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-direction: column;

    & > strong {
      display: block !important;
      color: #fff;
      font-weight: 700 !important;
      font-size: 2.188rem !important;
      margin-top: 30px !important;

      ${mqmax(desktopMinSize - 1)} {
        font-size: 1rem !important;
        margin-top: 17px !important;
      }
    }

    & > ul {
      text-align: justify;
      margin-top: 34px;
      color: #fff;
      margin-left: 55px;
      margin-right: 55px;

      & > li {
        margin-top: 10px;
        font-weight: 300;
        font-size: 1rem;
        line-height: 20px;
        min-width: 670px;

        ${mqmax(desktopMinSize - 1)} {
          font-size: 0.75rem !important;
          line-height: 15px;
          min-width: 250px;
        }
      }

      ${mqmax(desktopMinSize - 1)} {
        margin-top: 10px;
      }
    }

    button {
      background: transparent;
      position: absolute;
      top: 5%;
      right: 5%;

      svg {
        width: 24px;
        height: 24px;
        opacity: 0.24;
      }

      ${mqmax(desktopMinSize - 1)} {
        margin-top: 10px;
        top: -2%;
        right: 1%;
      }
    }
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes slide-up {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0%);
    }
  }

  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes slide-down {
    0% {
      transform: translateY(0%);
    }
    100% {
      transform: translateY(100%);
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    max-width: 304px;
    height: 517px;
    border-radius: 30px;
  }
`;

export const MobileDividerWrapper = styled.div`
  display: none;
  width: 100%;

  ${mqmax(desktopMinSize - 1)} {
    display: ${({ isOnUnit }) => (isOnUnit ? 'flex' : 'none')};
    align-items: center;
    gap: 0.75rem;

    button {
      background: transparent;
      border: 0;
      opacity: 0.24;
    }
  }
`;

export const MobileDivider = styled.div`
  max-width: ${({ isOnUnit }) => (isOnUnit ? '256px' : 'auto')};
  width: 100%;
  height: 1px;
  background-color: #3a3b44;
  margin: 0 auto;
  display: none;

  ${mqmax(desktopMinSize - 1)} {
    display: block;
  }
`;

export const MobileVerticalDivider = styled.div`
  width: 1px;
  height: 115px;
  background-color: #3a3b44;
  display: none;

  ${mqmax(desktopMinSize - 1)} {
    display: block;
  }
`;
