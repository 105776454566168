import styled from 'styled-components';

import { desktopMinSize, mqmax, rem } from '@Styles/mixins';
import { mq } from '../../../styles/mixins';

export const Logo = styled.img`
  display: flex;
  width: ${rem(125)};

  ${mqmax(desktopMinSize - 1)} {
    display: none;
  }
`;

export const Div = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: ${rem(100)};

  ${mqmax(desktopMinSize - 1)} {
    display: none ;
  }

  ${mq((desktopMinSize + 300), desktopMinSize)} {
    column-gap: ${rem(30)};
  }
`;

export const Nav = styled.nav`
  display: flex;
  position: relative;
  flex-direction: row;
  column-gap: ${rem(40)}; 

  ${mq((desktopMinSize + 300), desktopMinSize)} {
    column-gap: ${rem(20)};
  }

  ${mqmax(desktopMinSize - 1)} {
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    transition: ${['opacity', 'transform', 'visibility'].map(prop => `${prop} ease-in-out ${1 / 3}s`).join(',') 
  };    
    
    /* mobile */
    &::before {
      content:"";
      width: 40px;
      height: 3px;
      border-radius: 100vmax;
      background: #2d80f2;
      position: absolute; /* Only works well when the parent is 'position:relative' */
      left: ${({ afterLeft }) => afterLeft - 7}px;
      top: 0.4px;
      transition: ${['width', 'left'].map(prop => `${prop} ease-in-out ${1 / 3}s`).join(',')};
    }
  }
`;
