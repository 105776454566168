import React from 'react';
import {
  AMITutorialSeventhStepWrapper,
  AMITutorialSeventhStepContentWrapper,
  AMITutorialSeventhStepButtonWrapper,
  TutorialBackdrop,
} from '../styled';

export const SeventhStep = ({ nextStep, skipTutorial }) => {
  return (
    <>
      <TutorialBackdrop />
      <AMITutorialSeventhStepWrapper>
        <AMITutorialSeventhStepContentWrapper>
          <strong>
            Assim que aparecer o computador, clique sobre ele, volte aqui no site,{' '}
            <span>digite o código gerado pelo Moolight e clique em enviar</span>
          </strong>

          <AMITutorialSeventhStepButtonWrapper>
            <button onClick={nextStep}>Entendi</button>
            <button onClick={skipTutorial}>Fechar tutorial</button>
          </AMITutorialSeventhStepButtonWrapper>
        </AMITutorialSeventhStepContentWrapper>
      </AMITutorialSeventhStepWrapper>
    </>
  );
};
