import { Button as MuiButton } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

import { desktopMinSize, mqmin } from '@Styles/mixins';

import items from './items/index.js';

export const Download = props => (
  <List {...props}>
    {items.map(item => (
      <Item key={item.name} {...item} />
    ))}
  </List>
);

export default Download;

function Item({ img, link, name, ...props }) {
  return (
    <Link href={link} target="_blank" rel="noreferrer" {...props}>
      <Button>
        <Icon alt="" {...img} />
      </Button>
      {name}
    </Link>
  );
}

const Icon = styled.img`
  width: calc(${({ width }) => width}rem / 40);
  height: calc(${({ height }) => height}rem / 40);
  transition: scale calc(1s / 3) ease-in-out;

  ${mqmin(desktopMinSize)} {
    width: calc(${({ width }) => width}rem / 16);
    height: calc(${({ height }) => height}rem / 16);
  }
`;

const Link = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: calc(24rem / 40);
  color: inherit;
  font-size: calc(22rem / 24);
  line-height: calc(26 / 22);
  text-align: center;
  text-decoration: none;

  ${mqmin(desktopMinSize)} {
    row-gap: calc(24rem / 16);
    font-size: calc(22rem / 16);
  }

  &:hover {
    ${Icon} {
      scale: calc(7 / 6);
    }
  }
`;

const Button = styled(MuiButton)`
  &.MuiButton-root {
    width: calc(179rem / 40);
    height: calc(179rem / 40);
    border-radius: calc(30rem / 40);

    ${mqmin(desktopMinSize)} {
      width: calc(179rem / 16);
      height: calc(179rem / 16);
      border-radius: calc(30rem / 16);
    }

    &,
    &:hover {
      background: #292932;
    }
  }
`;

const List = styled.div`
  display: flex;
  column-gap: calc(40rem / 32);

  ${mqmin(desktopMinSize)} {
    column-gap: calc(40rem / 16);
  }
`;
