import styled from 'styled-components';
import { mqmax, desktopMinSize } from '@Styles/mixins';

export const Content = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    column-gap: calc(44 / 16 * 1rem);
    margin-top: calc(35 / 16 * 1rem);
    ${mqmax(desktopMinSize - 1)} {
        flex-direction: column;
        margin-top: calc(20 / 16 * 1rem);
    }
`;

export default Content;
