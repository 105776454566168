import { useContext, createContext } from 'react';
import { useLocalStorage } from '../utils/useLocalStorage';

const ShoppingCartContext = createContext({});

export function useShoppingCart() {
  return useContext(ShoppingCartContext);
}

export function ShoppingCartProvider({ children }) {
  const [cartItems, setCartItems] = useLocalStorage('shopping-cart', []);

  const cartQuantity = cartItems.reduce((quantity, item) => item.quantity + quantity, 0);

  function handleGetItemQuantity(id) {
    return cartItems.find(item => item.id === id)?.quantity || 0;
  }

  function handleIncreaseItemQuantity(id) {
      setCartItems(currItems => {
      if (currItems.find(item => item.id === id) == null) {
        return [...currItems, { id, quantity: 1 }];
      } else {
        return currItems.map(item => {
          if (item.id === id) {
            return { ...item, quantity: item.quantity + 1 };
          } else {
            return item;
          }
        });
      }
    });
  }

  function handleDecreaseItemQuantity(id) {
    setCartItems(currItems => {
      if (currItems.find(item => item.id === id)?.quantity === 1) {
        return currItems.filter(item => item.id !== id);
      } else {
        return currItems.map(item => {
          if (item.id === id) {
            return { ...item, quantity: item.quantity - 1 };
          } else {
            return item;
          }
        });
      }
    });
  }

  function handleRemoveFromCart(id) {
    setCartItems(currItems => {
      return currItems.filter(item => item.id !== id);
    });
  }

  function cleanCart() {
    setCartItems([]);
  }

  return (
    <ShoppingCartContext.Provider
      value={{
        handleGetItemQuantity,
        handleIncreaseItemQuantity,
        handleDecreaseItemQuantity,
        handleRemoveFromCart,
        cleanCart,
        cartQuantity,
        cartItems,
        setCartItems,
      }}
    >
      {children}
    </ShoppingCartContext.Provider>
  );
}
