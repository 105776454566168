import React, { useState } from 'react';
import Form from './Form';
import Input from './Input';
import Wrapper from './Wrapper';
import Box from './Box';
import Container from './Container';
import Footer from './Footer';
import Header from './Header';
import Overlay from './Overlay';
import Loading from '@Containers/Overlay';
import Submit from './Submit';
import Close from './Close';
import styled from 'styled-components';
import InputAdornment from '@material-ui/core/InputAdornment';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import IconButton from '@material-ui/core/IconButton';
import ErrorMessage from './ErrorMessage';
import SuccessMessage from './SuccessMessage';
import * as NoarAPI from '@Services/noar-api';
import PasswordStrengthChecker from '../../../../CreatePassword/CreatePasswordBox/Content/PasswordCheckbox';


export const ChangePasswordContent = ({close}) => {

    const [currentPassword, handleCurrentPassword] = useState('');
    const [newPassword, handleNewPassword] = useState('');
    const [passwordConfirmation, handlePasswordConfirmation] = useState('');
    const [showActualPassword, handleShowActualPassword] = useState(false);
    const [showNewPassword, handleShowNewPassword] = useState(false);
    const [showConfirmPassword, handleShowConfirmPassword] = useState(false);
    const [errorChangePasswordMessage, handleErrorChangePasswordMessage] = useState('');
    const [successChangePasswordMessage, handleSuccessChangePasswordMessage] = useState('');
    const [loading, handleLoading] = useState(false);

    // ASYNC FUNCTIONS

    async function saveChangePassword() {
        try {
            handleLoading(true);
            const previous_password = currentPassword;
            const proposed_password = newPassword;
            await NoarAPI.updatePassword(previous_password, proposed_password);
            handleLoading(false);
            handleSuccessChangePasswordMessage('Senha alterada com sucesso!');
            resetErrorSuccessMessage();
        } catch (error) {
            handleLoading(false);
            handleErrorChangePasswordMessage(error.message);
            resetErrorSuccessMessage();
        }
    }
    // EVENTS

    function onChangeCurrentPassword(event) {
        handleCurrentPassword(event.target.value);
    }

    function onChangeNewPassword(event) {
        handleNewPassword(event.target.value);
    }

    function onChangePasswordConfirmation(event) {
        handlePasswordConfirmation(event.target.value);
    }

    function onSubmitPasswordForm(ev) {
        ev.preventDefault();
        if (newPassword !== passwordConfirmation) {
            handleErrorChangePasswordMessage('Confirmação de senha incorreta');
            resetErrorSuccessMessage();
            return false;
        } else if (!containsLowerCase(newPassword) || !containsUpperCase(newPassword) || !containsNumber(newPassword) || !containsSpecialChar(newPassword)) {
            handleErrorChangePasswordMessage('A senha não atende os padrões de segurança');
            resetErrorSuccessMessage();
            return false;
        } else {
            saveChangePassword();
        }
    }

    function containsLowerCase(str) {
        return /[a-z]/.test(str);
    }
    
    function containsUpperCase(str) {
      return /[A-Z]/.test(str);
    }
    
    function containsNumber(str) {
        return /\d/.test(str);
    }
    
    function containsSpecialChar(str){
      return /[!@#$%^&*(),.?":{}|<>]/.test(str);
    }

    function onClickShowActualPassword() {
        handleShowActualPassword(!showActualPassword);
    }

    function onClickShowNewPassword() {
        handleShowNewPassword(!showNewPassword);
    }

    function onClickShowConfirmPassword() {
        handleShowConfirmPassword(!showConfirmPassword);
    }

    function resetErrorSuccessMessage() {
        setTimeout(function () {
            handleErrorChangePasswordMessage('');
            handleSuccessChangePasswordMessage('');
        }, 5000);
    }

    return (
        <Wrapper>
            {loading ? <Loading /> : []}
            <Overlay onClick={close}/>
            <Container>
                <Box>
                    <Div>
                        <Close close={close}/>
                    </Div>
                    <Header />
                    <Form onSubmit={onSubmitPasswordForm}>   
                        <Input
                            type={showActualPassword ? 'text' : 'password'}
                            name="CurrentPassword"
                            placeholder="Digite sua senha atual"
                            required
                            value={currentPassword}
                            onChange={onChangeCurrentPassword}
                            minLength="8"
                            maxLength="64"
                            autoComplete="off" 
                            variant="outlined"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end" style={{marginLeft: 0}}>
                                        <IconButton style={{padding:0}} aria-label="showPassword" component="a" onClick={onClickShowActualPassword}>
                                            {showActualPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <Input
                            type={showNewPassword ? 'text' : 'password'}
                            name="newPassword"
                            placeholder="Digite sua nova senha"
                            required
                            value={newPassword}
                            onChange={onChangeNewPassword}
                            minLength="8"
                            maxLength="64"
                            autoComplete="off" 
                            variant="outlined"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end" style={{marginLeft: 0}}>
                                        <IconButton style={{padding:0}} aria-label="showPassword" component="a" onClick={onClickShowNewPassword}>
                                            {showNewPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <Input
                            type={showConfirmPassword ? 'text' : 'password'}
                            name="passwordConfirmation"
                            placeholder="Confirme sua nova senha"
                            onChange={onChangePasswordConfirmation}
                            minLength="8"
                            maxLength="64"
                            value={passwordConfirmation}
                            autoComplete="off" 
                            variant="outlined"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end" style={{marginLeft: 0}} >
                                        <IconButton style={{padding:0}} aria-label="showPassword" component="a" onClick={onClickShowConfirmPassword}>
                                            {showConfirmPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <PasswordStrengthChecker password={newPassword} direction="column" />
                        {errorChangePasswordMessage ? <ErrorMessage>{errorChangePasswordMessage}</ErrorMessage> : []}
                        {successChangePasswordMessage ? <SuccessMessage>{successChangePasswordMessage}</SuccessMessage> : []}
                        <Submit />
                        <Footer />
                    </Form>
                </Box>
            </Container>
        </Wrapper>
    )
    
}

export default ChangePasswordContent;

const Div = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    height: auto;
`;
