import styled from "styled-components";

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1.5rem;
`;

export const ContentParagraph = styled.p`
    font-weight: 200;
    font-size: 18px;
    line-height: 150.5%;
    & > strong {
        font-weight: 600;
    }

    & > a {
        color: #2d80f2;
    }
`;

export const Link = styled.a`
    color: transparent;
`;

export const SocialContainerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 0.5rem;
`;

export const SocialIconsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
`;