import { HeaderWrapper, Step, Title } from '../../styled';
import { UseTypeWrapper, TypeContainer } from '../styled';

export const FirstQuestionStudy = ({ studyMoments, setStudyMoments }) => {
  return (
    <Step>
      <HeaderWrapper>
        <Title>Você utilizará a Noar em todos seus momentos de estudo?</Title>
      </HeaderWrapper>
      <UseTypeWrapper>
        <TypeContainer
          onClick={() => {
            setStudyMoments(true);
          }}
          selected={studyMoments === true}
        >
          <h3>Sim, a partir de hoje só estudo utilizando a Noar</h3>
        </TypeContainer>
        <TypeContainer
          onClick={() => {
            setStudyMoments(false);
          }}
          selected={studyMoments === false}
        >
          <h3>Não, somente quando precisar utilizar algum software pesado para estudo</h3>
        </TypeContainer>
      </UseTypeWrapper>
    </Step>
  );
};

export const SecondQuestionStudy = ({ differentStudySoftware, setDifferentStudySoftware }) => {
  return (
    <Step>
      <HeaderWrapper>
        <Title>Você irá utilizar muitos softwares diferentes?</Title>
      </HeaderWrapper>
      <UseTypeWrapper>
        <TypeContainer
          onClick={() => {
            setDifferentStudySoftware(true);
          }}
          selected={differentStudySoftware === true}
        >
          <h3>Sim, precisarei instalar diversos softwares na máquina</h3>
        </TypeContainer>
        <TypeContainer
          onClick={() => {
            setDifferentStudySoftware(false);
          }}
          selected={differentStudySoftware === false}
        >
          <h3>Não utilizarei apenas navegadores e programas na web</h3>
        </TypeContainer>
      </UseTypeWrapper>
    </Step>
  );
};

export const ThirdQuestionStudy = ({ storeFilesStudy, setStoreFilesStudy }) => {
  return (
    <Step>
      <HeaderWrapper>
        <Title>Você armazenará todos seus arquivos na máquina?</Title>
      </HeaderWrapper>
      <UseTypeWrapper>
        <TypeContainer
          onClick={() => {
            setStoreFilesStudy(true);
          }}
          selected={storeFilesStudy === true}
        >
          <h3>Sim! Tudo que eu fizer ficará salvo no computador</h3>
        </TypeContainer>
        <TypeContainer
          onClick={() => {
            setStoreFilesStudy(false);
          }}
          selected={storeFilesStudy === false}
        >
          <h3>Não, utilizo sempre uma boa nuvem para armazenar os arquivos</h3>
        </TypeContainer>
      </UseTypeWrapper>
    </Step>
  );
};
