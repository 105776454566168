import React from 'react';

import Container from './Container';
import Title from './Title';
import Logout from './Logout';

export const Header = () => (
  <Container>
    <Title />
    <Logout />
  </Container>
);

export default Header;
