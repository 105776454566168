import React from 'react';
import { useUserRole } from '@Context/UserRoleContext';

export const ProtectedComponent = ({ role, children }) => {
  const { clientType } = useUserRole();

  if (role.find(roleType => roleType === clientType) === undefined) return null;

  return <>{children}</>;
};
