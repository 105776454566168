import { useContext, createContext, useReducer } from 'react';

const TutorialContext = createContext({});

export const ACTIONS = {
  HANDLENEXTSTEP: 'handleNextStep',
  HANDLESKIPTUTORIAL: 'handleSkipTutorial',
};

export function useTutorialStep() {
  return useContext(TutorialContext);
}

const ininitalState = [
  {
    name: 'computer-page',
    step: 0,
  },
  {
    name: 'section-page',
    step: 0,
  },
  {
    name: 'program-page',
    step: 0,
  },
  {
    name: 'shop-page',
    step: 0,
  },
  {
    name: 'sessions-page',
    step: 0,
  },
  {
    name: 'support-page',
    step: 0,
  },
];

export function TutorialStepProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, ininitalState);

  function reducer(state, action) {
    switch (action.type) {
      case ACTIONS.HANDLENEXTSTEP:
        return state.map(page => {
          if (page.name === action.payload.name) {
            return { ...page, step: page.step + 1 };
          }

          return page;
        });
      case ACTIONS.HANDLESKIPTUTORIAL:
        return state.map(page => {
          if (page.name === action.payload.name) {
            return { ...state, step: null };
          }

          return page;
        });

      default:
        return state;
    }
  }

  const handleNextStep = name => {
    dispatch({ type: ACTIONS.HANDLENEXTSTEP, payload: { name: name } });
  };

  const handleSkipTutorial = name => {
    dispatch({ type: ACTIONS.HANDLESKIPTUTORIAL, payload: { name: name } });
  };

  return (
    <TutorialContext.Provider
      value={{
        state,
        handleNextStep,
        handleSkipTutorial,
      }}
    >
      {children}
    </TutorialContext.Provider>
  );
}
