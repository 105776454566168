import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    z-index: 2;
    border-radius: 38px;
    max-width: calc(525 / 16 * 1rem);
    max-height: calc(100vh - 100px);
    overflow-y: auto;
`;

export default Container;
