import React from 'react';
import {
  AMITutorialFinalStepWrapper,
  AMITutorialFinalStepContentWrapper,
  AMITutorialFinalStepButtonWrapper,
  TutorialBackdrop,
} from '../styled';

export const FinalStep = ({ nextStep, skipTutorial }) => {
  return (
    <>
      <TutorialBackdrop />
      <AMITutorialFinalStepWrapper>
        <AMITutorialFinalStepContentWrapper>
          <strong>
            <span>Pronto!</span> Agora você está pronto para decolar no mundo Cloud!
          </strong>

          <AMITutorialFinalStepButtonWrapper>
            <button onClick={nextStep}>vamos lá!</button>
            <button onClick={skipTutorial}>Fechar tutorial</button>
          </AMITutorialFinalStepButtonWrapper>
        </AMITutorialFinalStepContentWrapper>
      </AMITutorialFinalStepWrapper>
    </>
  );
};
