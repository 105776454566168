import { PersonalPlanCardInfo, PersonalPlanCardKnowMoreButton } from "../styled"
import * as S from './styled';
import logoPersonal from '@Assets/logo_noar_personal.png';
import computerImage from './3d_computer_in_cloud.svg';

const   PersonalPlanCardWrapper = ({handleKnowMoreOnClassicOpenModal}) => {
    return (
        <S.PersonalPlanCardInfoWrapper>
            <S.ImagesWrapper>
              <S.LogoPersonalImg src={logoPersonal} />
            </S.ImagesWrapper>
            <S.ComputerImage src={computerImage} />
            <PersonalPlanCardInfo>
              O <strong>Personal</strong> é o seu computador pessoal por assinatura na nuvem. Com ele você pode personalizar a sua área de trabalho como quiser, baixar os seus programas, jogos e salvar todos os arquivos importantes.
            </PersonalPlanCardInfo>
            <PersonalPlanCardKnowMoreButton onClick={handleKnowMoreOnClassicOpenModal}>
              Saiba Mais
            </PersonalPlanCardKnowMoreButton>
        </S.PersonalPlanCardInfoWrapper>
    )
}

export default PersonalPlanCardWrapper;