import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { sendTermsAccepted } from '@Services/noar-api';

import { closeTermsBox, initLogout } from '@Store/ducks/global/actions';
import * as S from './styled';
import { TERMS_PRIVACY_URL, TERMS_USE_URL, REDIRECT_TO_LOGIN } from '@Constants';
import { useState } from 'react';

function TermsBox() {

  const [isDisabled, handleIsDisabled] = useState(false);

  const { isOpen} = useSelector(
    state => state.global.termsBox,
  );

  const dispatch = useDispatch();
  const history = useHistory();

  function close() {
    dispatch(closeTermsBox());
  }

  function onClickRefuse(event) {
    event.preventDefault();
    dispatch(initLogout());
    history.push(REDIRECT_TO_LOGIN);
  }

  function onClickAccept() {
    handleIsDisabled(!isDisabled)
    sendTermsAcceptedFunction()
    close();
    handleIsDisabled(!isDisabled)
  }

  async function sendTermsAcceptedFunction(){
    await sendTermsAccepted();
  }


  return (
    <S.TermsBox isOpen={isOpen}>
      <div className="terms-form-box__overlay"></div>
      <div className='terms-form-box__wrapper'>
        <div className='terms-form-box__box'>
          <h1 className='terms-form-box__title'>Novos termos da NOAR</h1>
          <h2 className='terms-form-box__subtitle'>A partir de hoje teremos nossos termos de uso e politicas de privacidade funcionando na NOAR!</h2>
          <div>
            <div className='terms-form-box__text'>
              <p >Se você está vendo essa mensagem é porque criou sua conta com a gente antes de inserirmos qualquer tipo de termos funcionando no nosso site!</p>
              <p >Agora que inserimos esses termos, precisamos que você <strong>leia e aceite</strong> para continuar a usar nossos supercomputadores.</p>
            </div>
            <div className='terms-form-box__terms'>
              <Link className='terms-form-box__terms__link' to={TERMS_PRIVACY_URL} target="_blank" download>Políticas de Privacidade</Link>
              <Link className='terms-form-box__terms__link' to={TERMS_USE_URL} target="_blank" download >Termos de Serviço</Link>
            </div>
          </div>
          <div className="terms-form-box__terms__actions">
              <button type="button" disabled={isDisabled} className="terms-form-box__terms__actions__button" onClick={onClickAccept}>
                ACEITAR
              </button>
              <button type="button" disabled={isDisabled} className="terms-form-box__terms__actions__button" onClick={onClickRefuse} >
                RECUSAR
              </button>
          </div>
          {/* <div className='terms-form-box__support'>
            <p >*Em caso de dúvidas, reclamações ou sugestões, entre em contato conosco através do email <strong>privacidade@noarcloud.com</strong>.</p>
          </div> */}
        </div>
      </div>
    </S.TermsBox>
  );
}

export default TermsBox;