import Cookies from 'js-cookie';

import { SESSION_COOKIE_NAME, REDIRECT_TO_LOGIN } from '@Constants';

export function isLogged() {
  return Cookies.get(SESSION_COOKIE_NAME) ? true : false;
}

export function getSessionCookieData() {
  if (isLogged()) {
    const cookieData = JSON.parse(Cookies.get(SESSION_COOKIE_NAME));

    cookieData.client.name = decodeURIComponent(cookieData.client.name); // Encoding to old browsers that not supports utf8 on cookies

    return cookieData;
  } else {
    return null;
  }
}

export function getClientType() {
  if (isLogged()) {
    const cookieData = JSON.parse(Cookies.get(SESSION_COOKIE_NAME));

    return cookieData.clientType;
  } else {
    return null;
  }
}

export function getJwt() {
  if (isLogged()) {
    const cookieData = JSON.parse(Cookies.get(SESSION_COOKIE_NAME));

    return cookieData.jwt;
  } else {
    return null;
  }
}

export function logout() {
  Cookies.remove(SESSION_COOKIE_NAME);
}

export function checkStatusResponse(response) {
  if(response.status === 401) {
    logout();
    window.location.href = REDIRECT_TO_LOGIN;
  }
}
