import React from 'react';
import styled from 'styled-components';
import MuiButton from '@material-ui/core/Button';
import {
    mqmin,
    desktopMinSize
  } from '@Styles/mixins';

export const Container = props => 
    <Button type="button" {...props} />

export default Container;

const Button = styled(MuiButton)`
  &.MuiButton-root {
    opacity: 1;
    padding: 0;
    min-width: auto;
    ${mqmin(desktopMinSize)} {
      opacity: 0;
    }
  }
`;