import MuiButton from '@material-ui/core/Button';
import styled from 'styled-components';
import React from 'react';

import {
  mqmax,
  desktopMinSize
} from '@Styles/mixins';


export const Button = ({text, type, onClick}) => (
  <StyledButton type={type} variant="outlined" onClick={onClick}>
    {text}
  </StyledButton>
);

export default Button;

const StyledButton = styled(MuiButton)`
  &.MuiButton-root {

    padding: 5px;
    box-sizing: border-box;
    width: 100%;
    max-width: 170px;
    height: 50px; 
    border-color: #426FE5;
    border-width: calc(3 / 16 * 1rem);
    border-radius: 37px;
    color: #fff;
    font-weight: 600;
    background: #426FE5;
    transition: ${['background', 'border-color'].map(prop => `${prop} calc(1s / 3) ease-in-out`).join(',')};

    &:hover,
    &.active {
      background: #A00BF6;
      border-color: #A00BF6;
      color: #fff;
    }
    ${mqmax(desktopMinSize - 1)} {
      padding: 5px;
    }
  }
`;
