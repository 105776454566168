import { API_PAGARME_BASE, API_PAGARME_PUBLIC_TOKEN } from '@Constants';

export async function generateCardToken(cardNumber, cardName, expirationDate, cvv, brand) {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');

    const response = await fetch(`${API_PAGARME_BASE}/tokens?appId=${API_PAGARME_PUBLIC_TOKEN}`, {
        method: 'post',
        headers: myHeaders,
        mode: 'cors',
        body: JSON.stringify({
            "type": "card",
            "card": {
                "number": cardNumber,
                "holder_name": cardName,
                "exp_month": expirationDate.expiration_month,
                "exp_year": expirationDate.expiration_year,
                "cvv": cvv,
                "label": brand
            }
        }),
    });

    if (response.ok) {
        return await response.json();
    } else {
        console.log(response);
    }
}