import { useState } from 'react';

export const useTips = tips => {
  const [currentTipIndex, setCurrentTipIndex] = useState(0);
  let timeToTipChange;

  timeToTipChange = setTimeout(() => {
    setCurrentTipIndex(i => {
      if (i >= tips.length - 1) return (i = 0);
      return i + 1;
    });
  }, 10000);

  function nextTip() {
    setCurrentTipIndex(i => {
      if (i >= tips.length - 1) return (i = 0);
      clearTimeout(timeToTipChange);
      return i + 1;
    });
  }

  function previousTip() {
    setCurrentTipIndex(i => {
      if (i <= 0) return tips.length - 1;
      clearTimeout(timeToTipChange);
      return i - 1;
    });
  }

  return {
    currentTipIndex,
    tip: tips[currentTipIndex],
    nextTip,
    previousTip,
  };
};
