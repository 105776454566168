import React from 'react';
import styled from 'styled-components';

export const Image = props => <Img alt="" {...props} />;

export default Image;

const Img = styled.img`
  display: block;
  width: auto;
  height: calc(22 / 16 * 1rem);
`;
