import React from 'react';
import styled from 'styled-components';

import { Text, Title } from '@Components/Public';
import { desktopMinSize, mqmin } from '@Styles/mixins';
import * as S from './styled';
import designthinking from './icons/designthinking.png';
import controledevideogame from './icons/controle.png';
import select from './icons/select.png';
import { Button, Subtitle } from '../../../components/Public';
import { mq, mqmax } from '../../../styles/mixins';
import { TextButton } from '../../../layouts/Public/Header/styled';

const Section = props => {
  function getBaseFontSize() {
    return Number(window.getComputedStyle(document.body).getPropertyValue('font-size').match(/\d+/)[0]);
  }

  const OFFSET = (80* getBaseFontSize()) / 16;

  function goToPlans() {
    const { top } = document.querySelector('#planos').getBoundingClientRect();
    const { body } = document;
    body.scrollTo({ behavior: 'smooth', top: top + body.scrollTop - OFFSET });
  }
  function goToHowToUse() {
    const { top } = document.querySelector('#como-usar').getBoundingClientRect();
    const { body } = document;
    body.scrollTo({ behavior: 'smooth', top: top + body.scrollTop - OFFSET });
  }

  return (
    <section id="como-funciona" {...props}>
      <S.Header>
        <Title as="h2">
          <strong>Por que utilizar?</strong>
        </Title>
      </S.Header>
      <S.Main>
        <Text>
          Nós conectamos seu Windows, Linux ou Mac a supercomputadores da NOAR. Isso significa que você será capaz de
          rodar aplicativos pesados em qualquer dispositivo* com conexão à internet. Graças a nossa tecnologia de
          streaming em nuvem, qualquer dispositivo* será capaz de rodar{' '}
          <strong>
            <em>praticamente qualquer jogo ou software de trabalho!</em>
          </strong>
        </Text>
      </S.Main>
      <S.NichePresentationWrapper>
        <S.NichePresentation>
          <S.HeaderWrapper>
            <S.NicheIconsWrapper>
              <S.NicheImage src={designthinking} alt="Icone ilustrando uma lampada como ideia." />
            </S.NicheIconsWrapper>
            <S.NichePresentationTitle>
              <strong>Para trabalhar</strong>
            </S.NichePresentationTitle>
          </S.HeaderWrapper>
          <S.NichePresentationSubTitle>
            <p>Eleve o nível do seu trabalho com máquinas de alta performance!</p>
          </S.NichePresentationSubTitle>
          <S.PriceWrapper>
            <p>A partir de</p>
            <S.PriceContentWrapper>
              <S.SignP>R$</S.SignP>
              <S.PriceP>6.95<e>/hora</e></S.PriceP>
            </S.PriceContentWrapper>
          </S.PriceWrapper>
          <Button variant="contained" home_modal={true} onClick={goToPlans}>
            Start
          </Button>
          <S.ChecklistWrapper>
            <S.ChecklistItem>
              <S.ChecklistItemImage src={select} alt="Select marcado." />
              <p>Renderize mais rápido.</p>
            </S.ChecklistItem>
            <S.ChecklistItem>
              <S.ChecklistItemImage src={select} alt="Select marcado." />
              <p>Trabalhe de qualquer lugar.</p>
            </S.ChecklistItem>
            <S.ChecklistItem>
              <S.ChecklistItemImage src={select} alt="Select marcado." />
              <p>Garanta a qualidade!</p>
            </S.ChecklistItem>
          </S.ChecklistWrapper>
        </S.NichePresentation>
        <S.NichePresentation>
          <S.HeaderWrapper>
            <S.NicheIconsWrapper>
              <S.NicheImage src={controledevideogame} alt="Controle de video game." />
            </S.NicheIconsWrapper>
            <S.NichePresentationTitle>
              <strong>Para jogar</strong>
            </S.NichePresentationTitle>
          </S.HeaderWrapper>
          <S.NichePresentationSubTitle>
            Jogue <strong>sem lag</strong>, aprecie os <strong>melhores gráficos</strong> e aproveite <strong>jogos de última geração.</strong>
          </S.NichePresentationSubTitle>
          <S.PriceWrapper>
            <p>A partir de</p>
            <S.PriceContentWrapper>
              <S.SignP>R$</S.SignP>
              <S.PriceP>79.99<e>/mês</e></S.PriceP>
            </S.PriceContentWrapper>
          </S.PriceWrapper>
          <Button variant="contained" home_modal={true} onClick={goToPlans}>
            Start
          </Button>
          <S.ChecklistWrapper>
            <S.ChecklistItem>
              <S.ChecklistItemImage src={select} alt="Select marcado." />
              <p>Sem lag.</p>
            </S.ChecklistItem>
            <S.ChecklistItem>
              <S.ChecklistItemImage src={select} alt="Select marcado." />
              <p>Jogue os lançamentos.</p>
            </S.ChecklistItem>
            <S.ChecklistItem>
              <S.ChecklistItemImage src={select} alt="Select marcado." />
              <p>Jogue de onde quiser!</p>
            </S.ChecklistItem>
          </S.ChecklistWrapper>
        </S.NichePresentation>
      </S.NichePresentationWrapper>
      <Subtitle>
        <TextButton onClick={goToHowToUse}>
          <strong>
            Como posso usar a <span className="uppercase">Noar</span>?
          </strong>
        </TextButton>
      </Subtitle>
    </section>
  );
};

export const HowItWorks = styled(Section)`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.5rem;
  box-sizing: border-box;
  text-align: center;

  ${mqmin(desktopMinSize)} {
    grid-template-columns: 225fr 810fr;
    column-gap: calc(296 / 1331 * 100%);
    justify-content: space-between;
    text-align: start;
  }

  ${mq(desktopMinSize + 200, desktopMinSize)} {
    row-gap: calc(16rem / 16);
  }

  ${mqmax(desktopMinSize - 1)} {
    row-gap: calc(16rem / 16);
  }
`;

export default HowItWorks;
