import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, TextField } from "@material-ui/core";
import { Button, ButtonsWrapper, CancelOptionsWrapper, CancelPopupContainer, CancelPopupGeneralWrapper, CancelPopupInfoWrapper, Overlay, Title } from "../styled";

export const RecurrencyCancelPopup = ({
    phase, onCloseResultPhase, error, success, CancelInfoText, onConfirmCancel, toContinuePhase, subscriptionEndDate, onCancelCancel, CancelInfoSubTitle, toCancelPhase, reasonText, other,
    checkboxFormError, onChangeText, handleChange, price, dificulties, customerService, fulfillExpetations, problems
}) => {
    return (
        <CancelPopupGeneralWrapper>
            <Overlay />
            <CancelPopupContainer phase={phase}>
                {phase === 1 &&
                    <CancelPopupInfoWrapper>
                        <Title>Cancelar Assinatura</Title>
                        <CancelOptionsWrapper>
                            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard" error={checkboxFormError} >
                                <FormLabel component="legend">Qual a principal razão para o cancelamento do serviço?</FormLabel>
                                <FormHelperText>{checkboxFormError === true ? 'Selecione pelo menos um!' : ' '}</FormHelperText>
                                <FormGroup >
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={price} onChange={handleChange} name="price" />
                                        }
                                        label="Devido ao preço"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={dificulties} onChange={handleChange} name="dificulties" />
                                        }
                                        label="Tive dificuldades de como usar o serviço"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={fulfillExpetations} onChange={handleChange} name="fulfillExpetations" />
                                        }
                                        label="Não atendeu minha necessidade de uso"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={problems} onChange={handleChange} name="problems" />
                                        }
                                        label="Tive problemas com o serviço"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={customerService} onChange={handleChange} name="customerService" />
                                        }
                                        label="Atendimento ao cliente não resolveu meu problema"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={other} onChange={handleChange} name="other" />
                                        }
                                        label="Outro (deixe seu comentário)"
                                    />
                                </FormGroup>
                            </FormControl>
                            <TextField
                                id="outlined-multiline-static"
                                label={`Como podemos melhorar o Noar Personal?  (${1000 - reasonText.length} caracteres)`}
                                multiline
                                maxRows={4}
                                onChange={onChangeText}
                                inputProps={{ maxLength: 1000 }}
                                error={other === true && reasonText.length < 1}
                            />
                        </CancelOptionsWrapper>
                        <ButtonsWrapper>
                            <Button type="submit" variant="giveup-contained" onClick={toContinuePhase}>
                                Mudou de ideia?
                            </Button>
                            <Button type="submit" variant="cancel-contained" disabled={checkboxFormError || other === true && reasonText.length < 1} onClick={toCancelPhase}>
                                Quero cancelar :(
                            </Button>
                        </ButtonsWrapper>
                    </CancelPopupInfoWrapper>}
                {phase === 2 &&
                    <CancelPopupInfoWrapper phase={phase}>
                        <Title>Cancelar Assinatura</Title>
                        <CancelInfoSubTitle><strong>Tem certeza que deseja cancelar sua assinatura?</strong></CancelInfoSubTitle>
                        <CancelOptionsWrapper>
                            <CancelInfoText>Seu cancelamento será efetuado a partir de <strong>{subscriptionEndDate}</strong>.
                                Até lá, você ainda terá acesso à sua máquina Noar Personal.</CancelInfoText>
                            <CancelInfoText> Após essa data, todos os arquivos salvos serão excluídos.</CancelInfoText>
                            <CancelInfoText>Esperamos que volte em breve :)</CancelInfoText>
                        </CancelOptionsWrapper>
                        <ButtonsWrapper>
                            <Button type="submit" variant="giveup-contained" onClick={toContinuePhase}>
                                Mudou de ideia?
                            </Button>
                            <Button type="submit" variant="cancel-contained" onClick={onConfirmCancel}>
                                Quero cancelar :(
                            </Button>
                        </ButtonsWrapper>
                    </CancelPopupInfoWrapper>}
                {phase === 3 &&
                    <CancelPopupInfoWrapper phase={phase}>
                        <Title>Bom ter você de volta!</Title>
                        <CancelOptionsWrapper>
                            <CancelInfoText>Seu plano se manterá o mesmo!</CancelInfoText>
                        </CancelOptionsWrapper>
                        <Button type="submit" variant="giveup-contained" onClick={onCancelCancel}>
                            Voltar para meu plano
                        </Button>
                    </CancelPopupInfoWrapper>}
                {phase === 4 &&
                    <CancelPopupInfoWrapper phase={phase}>
                        <Title phase={phase} error={error}>{success && 'Assinatura cancelada com sucesso!'}{error && 'Erro ao cancelar a assinatura!'}</Title>
                        <CancelOptionsWrapper>
                            <CancelInfoText>{success && 'Esperamos ter você de volta em breve!'}{error && 'Por favor, entre em contato com o suporte!'}</CancelInfoText>
                        </CancelOptionsWrapper>
                        <Button type="submit" variant="giveup-contained" onClick={onCloseResultPhase}>
                            Voltar para meu perfil
                        </Button>
                    </CancelPopupInfoWrapper>}
            </CancelPopupContainer>
        </CancelPopupGeneralWrapper>
    );
};