import React, { useEffect, useState } from 'react';

import * as NoarAPI from '@Services/noar-api';
import Form from './Form';
import Input from './Input';
import Submit from './Submit';
import Access from './Access';
import Footer from './Footer';
import BackButton from './BackButton';
import Desktop from '../../Desktop';
import Mobile from '../../Mobile';
import ErrorMessage from './ErrorMessage';
import InputAdornment from '@material-ui/core/InputAdornment';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { REDIRECT_AFTER_REGISTER } from '@Constants';
import { useHistory } from 'react-router-dom';
import Overlay from '@Containers/Overlay';
import Subtitle from '../Header/Subtitle';
import { EmailSendApprovalCheckbox, VoucherCheckbox } from './Checkbox';
import { isValid } from 'date-fns';

export const Content = () => {
    const [name, handleName] = useState('');
    const [lastName, handleLastName] = useState('');
    const [email, handleEmail] = useState('');
    const [emailConfirmation, handleEmailConfirmation] = useState('');
    const [password, handlePassword] = useState('');
    const [passwordConfirmation, handlePasswordConfirmation] = useState('');
    const [errorRegisterMessage, handleErrorRegisterMessage] = useState('');
    const [referralVoucher, handleReferralVoucher] = useState('');
    const [loading, handleLoading] = useState(false);
    const [formStep, handleFormStep] = useState(0);
    const [open, setOpen] = useState(false);
    const [emailSendApprovalChecked, handleEmailSendApprovalChecked] = useState(true);
    const [voucherCheckboxChecked, handleVoucherCheckboxChecked] = useState(false);
    const [passwordErrorMessage, handlePasswordErrorMessage] = useState("");
    const [showPassword, handleShowPassword] = useState(false)
    const history = useHistory();

    const [isPasswordValid, handleIsPasswordValid] = useState(false);

    // ASYNC FUNCTIONS
    async function register() {
        try {
            handleLoading(true);
            const finalName = name.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, '').trim().replace(/\s+/g, ' ');
            const finalLastName = lastName.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, '').trim().replace(/\s+/g, ' ');
            await NoarAPI.register(email, password, emailSendApprovalChecked, `${finalName} ${finalLastName}`, referralVoucher && voucherCheckboxChecked ? referralVoucher : null);
            handleErrorRegisterMessage('');
            handleLoading(false);
            localStorage.setItem('registerConfirmationInfo', JSON.stringify({
                state: email, challenge: 'VALIDATE_ACCOUNT', challenge_session: ''
            }));
            redirect();
        } catch (error) {
            handleLoading(false);
            handleErrorRegisterMessage(error.message);
            resetErrorMessage();
        }
    }

    const setPasswordErrorMessage = (message) => {
        handlePasswordErrorMessage(message)
        handleIsPasswordValid(false)
    }

    // VALIDATE PASSWORD
    const validatePassword = (newPassword) => {
        if (newPassword.length <= 8)
            setPasswordErrorMessage("Deve conter no minimo 8 caracteres.")
        else if (!/[A-Z]/.test(newPassword))
            setPasswordErrorMessage("Deve conter caracteres maiusculos.")
        else if (!/\d/.test(newPassword))
            setPasswordErrorMessage("Deve conter numeros.")
        else if (!/[!@#$%^&*(),.?":{}|<>]/.test(newPassword))
            setPasswordErrorMessage("Deve conter pelo menos um caracter especial.")
        else {
            handlePasswordErrorMessage("")
            handleIsPasswordValid(true);
        }
    };

    // EVENTS

    const redirect = () => {
        const { next = undefined, state = {} } = history.location.state || {};
        const { formattedProducts } = state;
        history.push(REDIRECT_AFTER_REGISTER, { next: next, state: { formattedProducts } });
    }

    function onChangeName(event) {
        handleName(event.target.value);
    }

    function onChangeLastName(event) {
        handleLastName(event.target.value);
    }

    function onChangeEmail(event) {
        handleEmail(event.target.value);
    }

    function onChangeEmailConfirmation(event) {
        handleEmailConfirmation(event.target.value);
    }

    function onChangePassword(event) {
        handlePassword(event.target.value);
        validatePassword(event.target.value);
    }

    function onChangePasswordConfirmation(event) {
        handlePasswordConfirmation(event.target.value);
    }

    function onChangeReferralVoucher(event) {
        handleReferralVoucher(event.target.value);
    }

    function onChangeTermsCheckbox(event) {
        handleEmailSendApprovalChecked(event.target.checked);
    }

    function onChangeVoucherCheckbox(event) {
        handleVoucherCheckboxChecked(event.target.checked);
    }

    function onSubmitRegisterForm(ev) {
        ev.preventDefault();
        const validation = formValidation();
        if (validation) {
            register();
        }
    }

    function onSubmitRegisterFormMobile(ev) {
        ev.preventDefault();

        if (formStep !== 2) {
            handleFormStep(formStep + 1);
        } else {
            const validation = formValidation();
            if (validation)
                register();
        }  
    }

    function stepBack() {
        handleErrorRegisterMessage('');
        handleFormStep(formStep - 1);
    }

    const handleClickShowPassword = () => handleShowPassword(!showPassword);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    function formValidation() {
        if (email !== emailConfirmation) {
            handleErrorRegisterMessage('E-mails precisam ser iguais');
            resetErrorMessage();
            return false;
        } else if (password !== passwordConfirmation) {
            handleErrorRegisterMessage('Senhas precisam ser iguais');
            resetErrorMessage();
            return false;
        } else if (!isPasswordValid) {
            handleErrorRegisterMessage('A senha deve conter pelo menos um caractere maiúsculo, um número e um caracter especial');
            resetErrorMessage();
            return false;
        }

        var splitedName = name.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, '').trim().replace(/\s+/g, ' ').split(' ');
        var hasAbbreviation = false;
        splitedName.forEach(function (name) {
            if (name.length <= 2) {
                hasAbbreviation = true;
            }
        });
        if (hasAbbreviation) {
            handleErrorRegisterMessage('Nome não pode conter abreviações');
            resetErrorMessage();
            return false;
        }

        var splitedLastname = lastName.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, '').trim().replace(/\s+/g, ' ').split(' ');
        hasAbbreviation = false;
        splitedLastname.forEach(function (lastName) {
            if (lastName.length <= 2) {
                hasAbbreviation = true;
            }
        });
        if (hasAbbreviation) {
            handleErrorRegisterMessage('Sobrenome não pode conter abreviações');
            resetErrorMessage();
            return false;
        }

        return true;
    }

    function resetErrorMessage() {
        setTimeout(function () {
            handleErrorRegisterMessage('');
        }, 5000);
    }

    useEffect(() => {
        if (history.location?.search) {
            const params = new URLSearchParams(history.location?.search);
            const referralVoucher = params.get('referralVoucher');
            if (referralVoucher) {
                handleVoucherCheckboxChecked(true);
                handleReferralVoucher(referralVoucher);
            }
        }
    }, [history.location?.search]);


    return (
        <>
            {loading ? <Overlay /> : []}
            <Desktop>
                <Form onSubmit={onSubmitRegisterForm}>
                    <Input
                        type="text"
                        name="name"
                        placeholder="Nome"
                        required
                        maxLength="255"
                        value={name}
                        onChange={onChangeName}
                        autoComplete="off"
                        variant="outlined"
                    />
                    <Input
                        type="text"
                        name="lastName"
                        placeholder="Sobrenome"
                        required
                        maxLength="255"
                        value={lastName}
                        onChange={onChangeLastName}
                        autoComplete="off"
                        variant="outlined"
                    />
                    <Input
                        type="email"
                        name="email"
                        placeholder="Digite seu e-mail"
                        required
                        value={email}
                        onChange={onChangeEmail}
                        maxLength="255"
                        autoComplete="off"
                        variant="outlined"
                    />
                    <Input
                        type="email"
                        name="emailConfirmation"
                        placeholder="Confirme seu e-mail"
                        required
                        value={emailConfirmation}
                        onChange={onChangeEmailConfirmation}
                        maxLength="255"
                        autoComplete="off"
                        variant="outlined"
                    />
                    <Input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        placeholder="Digite sua senha"
                        required
                        error={!isPasswordValid && password !== ""}
                        value={password}
                        onChange={onChangePassword}
                        minLength="8"
                        maxLength="64"
                        autoComplete="new-password"
                        variant="outlined"
                        helperText={passwordErrorMessage}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }}
                    />
                    <Input
                        type={showPassword ? "text" : "password"}
                        name="passwordConfirmation"
                        placeholder="Confirme sua senha"
                        required
                        error={!isPasswordValid && password !== ""}
                        value={passwordConfirmation}
                        onChange={onChangePasswordConfirmation}
                        minLength="8"
                        maxLength="64"
                        autoComplete="new-password"
                        variant="outlined"
                        helperText={passwordConfirmation !== password && passwordConfirmation !== "" ? "As senhas não coincidem" : ""}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }}
                    />
                    <VoucherCheckbox checked={voucherCheckboxChecked} onChange={onChangeVoucherCheckbox} />
                    {
                        voucherCheckboxChecked ?
                            <Input
                                type="text"
                                name="referralVoucher"
                                placeholder="Digite o vale indicação"
                                required={voucherCheckboxChecked}
                                value={referralVoucher}
                                onChange={onChangeReferralVoucher}
                                variant="outlined"
                            /> : []
                    }
                    {errorRegisterMessage ? <ErrorMessage>{errorRegisterMessage}</ErrorMessage> : []}
                    <Submit>CADASTRAR</Submit>
                    <Access>OU FAÇA O LOGIN</Access>
                    <EmailSendApprovalCheckbox checked={emailSendApprovalChecked} onChange={onChangeTermsCheckbox} />
                    <Footer />
                </Form>
            </Desktop>
            <Mobile>
                <Form onSubmit={onSubmitRegisterFormMobile}>
                    {formStep === 0 ?
                        <>
                            <Subtitle />
                            <Input
                                type="text"
                                name="name"
                                placeholder="Nome"
                                required
                                maxLength="255"
                                value={name}
                                onChange={onChangeName}
                                autoComplete="off"
                                variant="outlined"
                            />
                            <Input
                                type="text"
                                name="lastName"
                                placeholder="Sobrenome"
                                required
                                maxLength="255"
                                value={lastName}
                                onChange={onChangeLastName}
                                autoComplete="off"
                                variant="outlined"
                            />
                            <VoucherCheckbox checked={voucherCheckboxChecked} onChange={onChangeVoucherCheckbox} />
                            {
                                voucherCheckboxChecked ?
                                    <Input
                                        type="text"
                                        name="referralVoucher"
                                        placeholder="Digite o vale indicação"
                                        required={voucherCheckboxChecked}
                                        value={referralVoucher}
                                        onChange={onChangeReferralVoucher}
                                        variant="outlined"
                                    /> : []
                            }
                            <Submit>CONTINUAR</Submit>
                            <Access>Ir para o login</Access>
                        </> :
                        formStep === 1 ?
                            <>
                                <Input
                                    type="email"
                                    name="email"
                                    placeholder="Digite seu e-mail"
                                    required
                                    value={email}
                                    onChange={onChangeEmail}
                                    maxLength="255"
                                    autoComplete="off"
                                    variant="outlined"
                                />
                                <Input
                                    type="email"
                                    name="emailConfirmation"
                                    placeholder="Confirme seu e-mail"
                                    required
                                    value={emailConfirmation}
                                    onChange={onChangeEmailConfirmation}
                                    maxLength="255"
                                    autoComplete="off"
                                    variant="outlined"
                                />
                                {errorRegisterMessage ? <ErrorMessage>{errorRegisterMessage}</ErrorMessage> : []}
                                <Submit>CONTINUAR</Submit>
                                <BackButton onClick={stepBack} />
                            </> :

                            <>
                                <Input
                                    type={showPassword ? "text" : "password"}
                                    name="password"
                                    placeholder="Digite sua senha"
                                    required
                                    error={!isPasswordValid && password !== ""}
                                    value={password}
                                    onChange={onChangePassword}
                                    minLength="8"
                                    maxLength="64"
                                    autoComplete="new-password"
                                    variant="outlined"
                                    helperText={passwordErrorMessage}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }}
                                />
                                <Input
                                    type={showPassword ? "text" : "password"}
                                    name="passwordConfirmation"
                                    placeholder="Confirme sua senha"
                                    required
                                    error={!isPasswordValid && password !== ""}
                                    value={passwordConfirmation}
                                    onChange={onChangePasswordConfirmation}
                                    minLength="8"
                                    maxLength="64"
                                    autoComplete="new-password"
                                    variant="outlined"
                                    helperText={passwordConfirmation !== password && passwordConfirmation !== "" ? "As senhas não coincidem" : ""}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }}
                                />
                                {errorRegisterMessage ? <ErrorMessage>{errorRegisterMessage}</ErrorMessage> : []}
                                <Submit>CONCLUIR CADASTRO</Submit>
                                <EmailSendApprovalCheckbox
                                    checked={emailSendApprovalChecked}
                                    onChange={onChangeTermsCheckbox} />
                                <Footer />
                                <BackButton onClick={stepBack} />
                            </>
                    }
                </Form>
            </Mobile>
        </>
    )

}

export default Content;
