import React from 'react';
import { AiFillWarning, AiOutlineClose } from 'react-icons/ai';
import { EndOfSessionWrapper } from './styled';

export const EndOfSessionAlert = ({modalRef, close}) => {

  return (
    <EndOfSessionWrapper ref={modalRef}>
      <div>
        <AiFillWarning style={{ width: '52px', height: '52px', color: '#426FE5' }} />
        <strong>
          Sua sessão <span>terminou!</span>
        </strong>
      </div>
      <button onClick={close}>
        <AiOutlineClose size={24} fill="#fff" />
      </button>
    </EndOfSessionWrapper>
  );
};
