import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Body from './Body';
import LoginBox from './LoginBox';
import Desktop from './Desktop';
import HeaderNew from '@Containers/Header-new';
import { setSelectedPlan } from '@Store/ducks/computer/actions';
import { SELECTED_PLAN_KEY } from '@Constants';

function LoginPage() {

  const dispatch = useDispatch();

  useEffect(() => {
    clearSelectedPlan();
  });

  const clearSelectedPlan = () => {
    localStorage.removeItem(SELECTED_PLAN_KEY);
    dispatch(setSelectedPlan(null));
  }

  return (
    <Body>
      <Desktop>
        <HeaderNew />
      </Desktop>
      <LoginBox />
    </Body>
  );
}

export default LoginPage;