import React from 'react';
import styled from 'styled-components';

import { Download as List, Title, Subtitle } from '@Components/Public';
import { desktopMinSize, mqmin } from '@Styles/mixins';

export const Download = () => (
  <Container>
    <div>
      <Title as="h1" className="align-center">
        <strong>
          Dowload <span className="uppercase">Noar</span>
        </strong>
      </Title>
      <Subtitle>
        Baixe a <span className="uppercase">Noar</span> com apenas um clique:
      </Subtitle>
    </div>
    <List />
  </Container>
);

export default Download;

const Container = styled.main`
  display: grid;
  justify-items: center;
  row-gap: calc(80rem / 24);
  max-width: min(calc(304 / 360 * 100%), calc(1331rem / 16));
  margin-inline: auto;
  padding-block: calc(32rem / 16) calc(112rem / 16);

  ${mqmin(desktopMinSize)} {
    row-gap: calc(80rem / 16);
    padding-block: calc(200rem / 16);
  }

  .uppercase {
    text-transform: uppercase;
  }
`;
