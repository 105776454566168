import MuiButton from '@material-ui/core/Button';
import styled from 'styled-components';

import { mqmax, mqmin, desktopMinSize, defaultFont } from '@Styles/mixins';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export const Access = (props) => {

  const history = useHistory();

  const handleButtonClick = () => {
    const { state = {} } = history.location.state || {};
    const { formattedProducts } = state;
    history.push("/login", { next: '/shop/hours', state: { formattedProducts: formattedProducts } });
  }

  return (
    <Button onClick={handleButtonClick} variant="outlined" {...props} />
  );
};

export default Access;

const Button = styled(MuiButton)`
  &.MuiButton-root {
    width: 100%;
    font-weight: 600;
    background: transparent;
    ${mqmax(desktopMinSize - 1)} {
      font-family: ${defaultFont};
      text-transform: none;
      font-style: normal;
      text-align: center;
      color: #a00bf6;
      font-size: calc(12 / 16 * 1rem);
      line-height: 20px;
      border: 0;
    }
    ${mqmin(desktopMinSize)} {
      grid-column-start: 1;
      grid-column-end: 3;
      padding: 10px;
      box-sizing: border-box;
      border-color: #2d80f2;
      border-width: calc(3 / 16 * 1rem);
      border-radius: calc(24 / 16 * 1rem);
      color: #fff;
      transition: ${['border-color'].map(prop => `${prop} calc(1s / 3) ease-in-out`).join(',')};
      &:hover,
      &.active {
        border-color: #fff;
      }
    }
  }
`;
