import React from 'react';

import { ShopPageSecondStepWrapper, ShopPageSecondStepContentWrapper, ShopPageSecondStepButtonWrapper } from './styled';
import { TutorialBackdrop } from '../styled';
export const SecondStep = ({ nextStep, skipTutorial }) => {
  return (
    <>
      <TutorialBackdrop />
      <ShopPageSecondStepWrapper>
        <ShopPageSecondStepContentWrapper>
          <strong>
            Clicando aqui você vai <span>abrir o carrinho.</span>
          </strong>
          <ShopPageSecondStepButtonWrapper>
            <button onClick={nextStep}>Entendi</button>
            <button onClick={skipTutorial}>Fechar tutorial</button>
          </ShopPageSecondStepButtonWrapper>
        </ShopPageSecondStepContentWrapper>
      </ShopPageSecondStepWrapper>
    </>
  );
};
