import React from 'react';
import Container from './Container';
import Image from './Image';
import image from './image.svg';

export const Logout = () => {
    return (
        <Container>
            <Image src={image} />
        </Container>
    )
};

export default Logout;
