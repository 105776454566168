import styled from "styled-components";
import { rem } from "../../../styles/mixins";

export const HowToUse = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    gap: 2.5rem;
`;

export const HowToUseContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 1.5rem;
`;

export const HowToUseItem = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: flex-start;
`;

export const HowToUseText = styled.span``;

export const Icon = styled.img`
  display: flex;
  max-width: 100%;
  width: ${rem(16)};
  height: ${rem(18)};

  & {
    background-color: transparent;
  }
`;

export const HowToUseIcon = styled(Icon)`
`;