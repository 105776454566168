import React from 'react';
import { TermsText } from './styled';

export const TermsServices = () => {
  return (
    <>
      <TermsText>
        Clique para acessar as{' '}
        <a href="https://www.noarcloud.com/terms/POLITICA_DE_PRIVACIDADE.pdf" rel="noreferrer" target="_blank">
          Políticas de Privacidade
        </a>{' '}
        e{' '}
        <a href="https://www.noarcloud.com/terms/TERMOS_DE_USO.pdf" rel="noreferrer" target="_blank">
          Termos de Serviço.
        </a>
      </TermsText>
    </>
  );
};
