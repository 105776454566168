import React, { useState, useRef, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  UnitPlanCard,
  UnitPlanCardWrapper,
  UnitPlanCardTitle,
  HorizontalDivider,
  UnitPlanCardInfo,
  UnitPlanCardPrice,
  UnitPlanCardButton,
  UnitPlanCardKnowMoreButton,
  MobileDividerWrapper,
  MobileDivider,
  AboutUnitWrapper,
  UnitGeneralInfoWrapper,
  UnitGeneralWrapper,
  ColoredEm,
  UnitPlanCardDiscountValueWrapper,
  UnitPlanCardDiscountsPriceWrapper,
  ReferralVoucherDiscountSpan,
  PaymentBrickWrapper,
  PaymentBrickBackGround,
  CloseButton,
} from './styled';
import { MdOutlineClose } from 'react-icons/md';
import '@Toast/styles.css';
import { getActivatedPlans } from '@Services/noar-api';
import { ContractDivWrapper, InfoDivWrapper } from '../Personal/styled';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { VerticalDivider } from '../Classic/styled';
import PixIcon from '@mui/icons-material/Pix';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { formatCurrency } from '../../../../utils/formatCurrency';
import PaymentComponent from '../MpPaymentBrick';
import { Loading } from '../../../Loading';
import PaymentStatusComponent from '../MpPaymentStatusBrick';
import { Close } from '@material-ui/icons';
import { useSelector } from 'react-redux';

const UnitCard = ({ products, isOpen, onClick, onRequestClose, disableButton, isPublic }) => {
  const [showPayment, handleShowPayment] = useState(false);
  const [voucherDiscount, setVoucherDiscount] = useState(0);
  const [selectedProductTotalAmount, setSelectedProductTotalAmount] = useState(0);
  const [selectedProductList, setSelectedProductList] = useState([]);
  const [paymentId, setPaymentId] = useState();
  const [disablePaymentContent, setDisablePaymentContent] = useState(false);
  const pixProduct = products ? products.filter(product => product?.pix_discount > 0)[0] : null;
  const creditCardProduct = products ? products.filter(product => !product?.pix_discount > 0)[0] : null;
  const { accountPersonalInfo } = useSelector(state => state.global);
  const modalRef = useRef();
  const soldOffWarning = false;
  const history = useHistory();

  const handleKnowMoreOnClassicOpenModal = () => {
    modalRef.current.showModal();
  };

  const handleKnowMoreOnClassicCloseModal = () => {
    const modal = modalRef.current;

    modal.setAttribute('closing', '');
    modal.addEventListener(
      'animationend',
      () => {
        modal.removeAttribute('closing');
        modal.close();
      },
      { once: true },
    );
  };

  const closePayment = event => {
    if (event.target !== event.currentTarget) return;
    setPaymentId(null);
    handleShowPayment(false);
    history.push('/shop/unit');
    window.location.reload();
  };

  const { data } = useQuery({
    queryKey: ['days_remaining'],
    queryFn: () => getActivatedPlans(),
  });

  const planDaysRemaining = data && data[0]?.days_remaining;
  const planType = data && data[0]?.signature_type;

  const handlePublicRedirect = formattedProducts => {
    history.push('/login', { next: '/shop/unit', state: { formattedProducts: formattedProducts } });
  };

  async function handleOrderValidations(id, quantity, totalAmount) {
    const products = {
      products: [
        {
          product_id: id,
          quantity,
        },
      ],
      totalAmount,
    };

    if (isPublic) {
      handlePublicRedirect(products);
      return;
    }
    setDisablePaymentContent(true);
    await accountPersonalInfo.showPopupValidationFunction().then(resp => {
      if (!resp) {
        handleOrder(id, quantity, totalAmount);
        return;
      }
    });
    setDisablePaymentContent(false);
  }

  function handleOrder(id, quantity, totalAmount) {
    const products = [
      {
        product_id: id,
        quantity,
      },
    ];

    setSelectedProductTotalAmount(totalAmount.toFixed(2));
    setSelectedProductList(products);
    handleShowPayment(true);
  }

  useEffect(() => {
    function executeActionsFromPublicRedirect() {
      setSelectedProductList(history.location.state.formattedProducts.products);
      setSelectedProductTotalAmount(history.location.state.formattedProducts.totalAmount.toFixed(2));
      handleShowPayment(true);
    }

    if (history.location.state?.formattedProducts && !disableButton) {
      executeActionsFromPublicRedirect();
    }
  }, [disableButton, history.location.state?.formattedProducts]);

  useEffect(() => {
    if (products) {
      const item = products[0];
      setVoucherDiscount(item?.voucher_discount ? item?.voucher_discount : 0);
    }
  }, [products]);

  return disablePaymentContent ? (
    <Loading whichLoading={'generic'} />
  ) : (
    <UnitGeneralWrapper>
      <UnitGeneralInfoWrapper>
        <UnitPlanCard isOnUnit={isOpen === 'UNIT'}>
          <UnitPlanCardWrapper>
            <InfoDivWrapper>
              <UnitPlanCardTitle>UNIT - BETA</UnitPlanCardTitle>
              <HorizontalDivider />
              <UnitPlanCardInfo>
                <li>
                  {' '}
                  • Assinatura mensal <br /> com sessões ilimitadas;{' '}
                </li>
                <li>
                  {' '}
                  • Sessões de até{' '}
                  <ColoredEm>
                    {' '}
                    <strong>4 HORAS</strong>
                  </ColoredEm>
                  ;{' '}
                </li>
                <li>
                  {' '}
                  •{' '}
                  <ColoredEm>
                    {' '}
                    <strong>Descontos</strong>{' '}
                  </ColoredEm>{' '}
                  no pix;
                </li>
                <li> • Sem armazenamento incluso;</li>
                <li>
                  {' '}
                  • Spot ativado <br />
                  <span>&#40;desligamentos aleatórios podem ocorrer&#41;</span>.
                </li>
              </UnitPlanCardInfo>
              <HorizontalDivider />
            </InfoDivWrapper>
            <UnitPlanCardKnowMoreButton onClick={handleKnowMoreOnClassicOpenModal}>
              Saiba Mais
            </UnitPlanCardKnowMoreButton>
          </UnitPlanCardWrapper>
          <AboutUnitWrapper ref={modalRef}>
            <div>
              <strong>Sobre o UNIT</strong>
              <ul>
                <li>
                  • Torne-se um membro Unit com um plano de assinatura mensal e benefícios exclusivos. Você pode acessar
                  quantas sessões quiser, sem limite de acesso;
                </li>
                <li>
                  • Ao escolher o computador que deseja ligar, nosso sistema encontrará um disponível, caso não haja
                  nenhum, você será direcionado para uma fila até que seja disponibilizado;
                </li>
                <li>
                  • Cada sessão terá uma duração que será exibida ao iniciar a máquina na parte superior do site ou app;
                </li>
                <li>• Quando a sua sessão terminar, você poderá acessar novamente, quantas vezes quiser;</li>
                <li>
                  • Máquinas spot, com possíveis desligamentos aleatórios. Sempre há a possibilidade do computador
                  desligar antes do tempo total da sessão.
                </li>
                <li>
                  • Configurações dos computadores padronizadas. Cada computador tem uma definição padrão de acordo com
                  o aplicativo;
                </li>
                <li>• Resolução HD. Posteriormente será possível a utilização de UHD em um plano futuro;</li>
                <li>
                  • Sempre que desligar, todos os arquivos serão excluídos, todos os programas e arquivos baixados na
                  máquina são apagados quando a máquina é desligada;
                </li>
                <li>
                  • Licenças dos programas não incluídas. A NOAR disponibiliza o computador, não o programa, para
                  acessar alguns jogos ou programas pré-instalados, você precisa obter a licença dele.
                </li>
                <li>
                  • Assinantes têm acesso prioritário à fila do Unit pelas primeiras 40 horas de uso, com mais máquinas
                  disponíveis. Após esse período, o acesso é reduzido, a quantidade de máquinas é menor e o tempo de
                  sessão é limitado a 1:30h.
                </li>
              </ul>
              <button ref={modalRef} onClick={handleKnowMoreOnClassicCloseModal}>
                <MdOutlineClose color="#fff" />
              </button>
            </div>
          </AboutUnitWrapper>
        </UnitPlanCard>
        <VerticalDivider />
        <ContractDivWrapper>
          <MobileDividerWrapper>
            <MobileDivider />
          </MobileDividerWrapper>
          <UnitPlanCardPrice>
            <UnitPlanCardDiscountsPriceWrapper>
              <UnitPlanCardDiscountValueWrapper crossed={voucherDiscount && voucherDiscount !== 0}>
                <span>
                  <ColoredEm>{pixProduct && formatCurrency(pixProduct?.price - pixProduct?.pix_discount)}</ColoredEm>
                </span>
                {voucherDiscount && voucherDiscount !== 0 ? (
                  <>
                    <p>Vale indicação: -{voucherDiscount}%</p>
                    <ReferralVoucherDiscountSpan>
                      <ColoredEm>
                        {pixProduct &&
                          formatCurrency((pixProduct?.price - pixProduct?.pix_discount) * (1 - voucherDiscount / 100))}
                      </ColoredEm>
                    </ReferralVoucherDiscountSpan>
                  </>
                ) : (
                  []
                )}
                <p>
                  <PixIcon /> À vista no PIX
                </p>
                <BuyButton
                  planType={planType}
                  planDaysRemaining={planDaysRemaining}
                  handleOrder={() =>
                    handleOrderValidations(
                      pixProduct?.product_id,
                      1,
                      (pixProduct?.price - pixProduct?.pix_discount) * (1 - voucherDiscount / 100),
                    )
                  }
                  disableButton={disableButton}
                  soldOffWarning={soldOffWarning}
                  products={products}
                  isOpen={isOpen}
                  isPix={true}
                />
              </UnitPlanCardDiscountValueWrapper>
              <UnitPlanCardDiscountValueWrapper crossed={voucherDiscount && voucherDiscount !== 0}>
                <span>
                  <ColoredEm>{creditCardProduct && formatCurrency(creditCardProduct?.price)}</ColoredEm>
                </span>
                {voucherDiscount && voucherDiscount !== 0 ? (
                  <>
                    <p>Vale indicação: -{voucherDiscount}%</p>
                    <ReferralVoucherDiscountSpan>
                      <ColoredEm>
                        {creditCardProduct && formatCurrency(creditCardProduct?.price * (1 - voucherDiscount / 100))}
                      </ColoredEm>
                    </ReferralVoucherDiscountSpan>
                  </>
                ) : (
                  []
                )}
                <p>
                  <CreditCardIcon /> No cartão
                </p>
                <BuyButton
                  planType={planType}
                  planDaysRemaining={planDaysRemaining}
                  handleOrder={() =>
                    handleOrderValidations(
                      creditCardProduct?.product_id,
                      1,
                      creditCardProduct?.price * (1 - voucherDiscount / 100),
                    )
                  }
                  disableButton={disableButton}
                  soldOffWarning={soldOffWarning}
                  products={products}
                  isOpen={isOpen}
                  isPix={false}
                />
              </UnitPlanCardDiscountValueWrapper>
            </UnitPlanCardDiscountsPriceWrapper>
          </UnitPlanCardPrice>
        </ContractDivWrapper>
        {showPayment ? (
          <PaymentBrickBackGround showPayment={showPayment} onClick={closePayment}>
            <PaymentBrickWrapper showPayment={showPayment}>
              <CloseButton>
                <Close onClick={closePayment} />
              </CloseButton>
              {!paymentId ? (
                <PaymentComponent
                  amount={selectedProductTotalAmount}
                  selectedProductList={selectedProductList}
                  setPaymentId={setPaymentId}
                  handleShowPayment={handleShowPayment}
                  onlyPix={selectedProductList[0]?.product_id === pixProduct?.product_id}
                  onlyCard={selectedProductList[0]?.product_id === creditCardProduct?.product_id}
                  setDisablePaymentContent={setDisablePaymentContent}
                  recurrent={false}
                />
              ) : (
                <PaymentStatusComponent paymentId={paymentId} productLink="/shop/unit" />
              )}
            </PaymentBrickWrapper>
          </PaymentBrickBackGround>
        ) : null}
      </UnitGeneralInfoWrapper>
    </UnitGeneralWrapper>
  );
};

const BuyButton = ({
  planType,
  planDaysRemaining,
  handleOrder,
  disableButton,
  soldOffWarning,
  products,
  isOpen,
  isPix,
}) => {
  const product = isPix
    ? products?.filter(product => product?.pix_discount > 0)[0]
    : products?.filter(product => !product?.pix_discount > 0)[0];
  return planType === 'CORUJAO' ? (
    <UnitPlanCardButton title={`Corujão ativo`} disabled={true}>
      Corujão ativo
    </UnitPlanCardButton>
  ) : planDaysRemaining === 0 ? (
    <UnitPlanCardButton
      title={`Seu plano expira hoje`}
      onClick={() => {
        handleOrder(product.product_id, 1);
      }}
      disabled={isOpen || disableButton || soldOffWarning}
    >
      {disableButton ? `Expira hoje` : soldOffWarning ? 'Esgotado' : 'Contratar'}
    </UnitPlanCardButton>
  ) : (
    <UnitPlanCardButton
      title={`Faltam ${planDaysRemaining} ${planDaysRemaining <= 1 ? 'dia' : 'dias'} para seu plano expirar`}
      onClick={() => {
        handleOrder(product.product_id, 1);
      }}
      disabled={isOpen || disableButton || soldOffWarning}
    >
      {disableButton
        ? `${planDaysRemaining} ${planDaysRemaining <= 1 ? 'dia' : 'dias'} p/ expirar`
        : soldOffWarning
        ? 'Esgotado'
        : 'Contratar'}
    </UnitPlanCardButton>
  );
};

export default UnitCard;
