import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Box from './Box';
import Container from './Container';
import Close from './Close';
import styled from 'styled-components';
import Header from './Header';
import Form from './Form';
import Input from './Input';
import Submit from './Submit';
import { getAddressByCep } from '@Services/noar-api';
import { mqmax, desktopMinSize } from '@Styles/mixins';
import { REDIRECT_TO_LOGIN, messageDisconnect, ERROR } from '@Constants';
import { SessionExpired } from '@Errors';
import { openConfirmationLightbox } from '@Store/ducks/global/actions';
import { Loading } from '../../../../../../components/Loading';

export const AdressData = ({
  close,
  handleViewControl,
  adressInfo,
  handleAdressInfo,
  handleConfirmPaymentReturn
}) => {
  const [billing, handleBilling] = useState(adressInfo?.address);
  const [keepAdressOption, handleKeepAdressOption] = useState(true);
  const [changedKeepAdressOption, handleChangedKeepAdressOption] = useState(true);
  const [loading, handleLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();


  function handleDisableButton() {
    const billingInvalid = !(billing?.cep && billing?.endereco && billing?.numero && billing?.bairro && billing?.cidade && billing?.estado);
    if (!changedKeepAdressOption) return true;
    return billingInvalid;
  }

  // ASYNC FUNCTIONS

  async function getAddress(origin) {
    try {
      if(billing?.cep?.length === 8) {
        handleLoading(true);
        const response = await getAddressByCep(billing?.cep);
        
        handleBilling({...billing, ...{
          endereco: response?.logradouro,
          bairro: response?.bairro,
          cidade: response?.localidade,
          estado: response?.uf
        }});
        
        handleLoading(false);
      }
    } catch (error) {
      handleLoading(false);
      if (error instanceof SessionExpired) {
        const turnOnMachine = false;
        const disconnect = true;
        dispatch(
          openConfirmationLightbox(
            () => {
              history.push(REDIRECT_TO_LOGIN);
            },
            messageDisconnect.message,
            messageDisconnect.confirmBtnText,
            messageDisconnect.refuseBtnText,
            turnOnMachine,
            disconnect,
            false,
          ),
        );
      } else {
        
        handleConfirmPaymentReturn({ status: ERROR });
      }
    }
  }

  function onChangeCep(event, origin) {
    event.preventDefault();
    let cep = event.target.value.replace(/\D/g, '').substring(0, 8);
    handleBilling({...billing, cep});
  
  }

  function onChangeNumero(event, origin) {
    event.preventDefault();
      handleBilling({...billing, numero: event.target.value});
    
  }

  function onChangeComplemento(event, origin) {
    event.preventDefault();
      handleBilling({...billing, complemento: event.target.value});
    
  }

  function onChangeEndereco(event, origin) {
    event.preventDefault();
      handleBilling({...billing, endereco: event.target.value});
  }

  function onChangeBairro(event, origin) {
    event.preventDefault();
      handleBilling({...billing, bairro: event.target.value});
    
  }

  function onChangeCidade(event, origin) {
    event.preventDefault();
      handleBilling({...billing, cidade: event.target.value});
    
  }

  function onChangeEstado(event, origin) {
    event.preventDefault();

    const upperCasedValue = event.target.value ? event.target.value.toUpperCase() : '';

      handleBilling({...billing, estado: upperCasedValue});
    }


  function onSubmitForm(ev) {
    ev.preventDefault();
    handleAdressInfo({ billing, keepAdressOption, changedKeepAdressOption});
    handleViewControl(2);
  }

  return (
    <Container keepAdressOption={keepAdressOption}>
      {loading ? (
        <Loading whichLoading={'generic'} />
      ) : (
        <Box>
          <ActionsDiv>
            <CloseDiv>
              <Close close={close} />
            </CloseDiv>
          </ActionsDiv>
          <Header/>
          <Form onSubmit={onSubmitForm}>
            <Input
              type="text"
              name="cep"
              placeholder="CEP"
              required
              inputProps={{ maxLength: 9 }}
              value={billing?.cep}
              onChange={(e) => {onChangeCep(e, 'ADDRESS')}}
              onBlur={() => {getAddress('ADDRESS')}}
              autoComplete="off"
              variant="outlined"
            />

            <Input
              type="text"
              name="endereco"
              placeholder="Endereço"
              required
              inputProps={{ maxLength: 255 }}
              value={billing?.endereco}
              onChange={(e) => {onChangeEndereco(e, 'ADDRESS')}}
              autoComplete="off"
              variant="outlined"
            />

            <Input
              type="text"
              name="numero"
              placeholder="Número"
              required
              inputProps={{ maxLength: 15 }}
              value={billing?.numero}
              onChange={(e) => {onChangeNumero(e, 'ADDRESS')}}
              autoComplete="off"
              variant="outlined"
            />

            <Input
              type="text"
              name="bairro"
              placeholder="Bairro"
              required
              inputProps={{ maxLength: 15 }}
              value={billing?.bairro}
              onChange={(e) => {onChangeBairro(e, 'ADDRESS')}}
              autoComplete="off"
              variant="outlined"
            />

            <Input
              type="text"
              name="cidade"
              placeholder="Cidade"
              required
              inputProps={{ maxLength: 50 }}
              value={billing?.cidade}
              onChange={(e) => {onChangeCidade(e, 'ADDRESS')}}
              autoComplete="off"
              variant="outlined"
            />

            <Input
              type="text"
              name="sigla do estado"
              placeholder="Sigla do Estado"
              required
              inputProps={{ maxLength: 2 }}
              value={billing?.estado}
              onChange={(e) => {onChangeEstado(e, 'ADDRESS')}}
              autoComplete="off"
              variant="outlined"
              onKeyPress={(e) => {
                const regex = new RegExp('^[a-zA-Z]+$');
                const key = String.fromCharCode(!e.charCode ? e.which : e.charCode);
                if (!regex.test(key)) {
                  e.preventDefault();
                  return false;
                }
              }}
            /> 
            <Div>  
              <Input
                type="text"
                name="complemento"
                placeholder="Complemento"
                inputProps={{ maxLength: 50 }}
                value={billing?.complemento}
                onChange={(e) => {onChangeComplemento(e, 'ADDRESS')}}
                autoComplete="off"
                variant="outlined"
              />
            </Div>
            <SubmitDiv>
              <Submit props={{ disabled: handleDisableButton() }} />
            </SubmitDiv>
          </Form>
        </Box>
      )}
    </Container>
  );
};

export default AdressData;

const ActionsDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: auto;
  ${mqmax(desktopMinSize - 1)} {
    height: calc(30 / 16 * 1rem);
  }
`;


const CloseDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  height: auto;
`;

const Div = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: auto;
  grid-column-start: 1;
  grid-column-end: 3;
`;

const SubmitDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: calc(15 / 16 * 1rem);
  height: auto;
  grid-column-start: 1;
  grid-column-end: 3;
  margin-top: calc(14 / 16 * 1rem);
  ${mqmax(desktopMinSize - 1)} {
    margin-top: calc(10 / 16 * 1rem);
  }
`;

const CheckboxDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: calc(52 / 16 * 1rem);
  margin-top: calc(14 / 16 * 1rem);
  ${mqmax(desktopMinSize - 1)} {
    column-gap: calc(30 / 16 * 1rem);
  }
`;
