import React from 'react';

import { desktopMinSize } from '@Styles/mixins';

import { MdOutlineClose, MdChevronRight, MdChevronLeft } from 'react-icons/md';

import { useMultiStepForm } from '@Utils/useMultiStepForm';

import { FirstStep } from './Steps/FirstStep';
import { SecondStep } from './Steps/SecondStep';
import { ThirdStep } from './Steps/ThirdStep';
import { FourthStep } from './Steps/FourthStep';
import { FifthStep } from './Steps/FifthStep';
import { SixthStep } from './Steps/SixthStep';
import { SeventhStep } from './Steps/SeventhStep';
import { EighthStep } from './Steps/EighthStep';
import { FinalStep } from './Steps/FinalStep';

import { CloseButton, NextButton, PreviousButton, HowToUseBulletsWrapper, HowToUseBullets } from './styled';

export const Step = ({ onRequestClose }) => {
  const { step, nextStep, previousStep, steps, currentStepIndex, isFirstStep, isLastStep, setCurrentStepIndex } =
    useMultiStepForm([
      <FirstStep />,
      <SecondStep />,
      <ThirdStep />,
      <FourthStep />,
      <FifthStep />,
      <SixthStep />,
      <SeventhStep />,
      <EighthStep />,
      <FinalStep setCurrentStepIndex={handleReturnToBeginning} onRequestClose={onRequestClose} />,
    ]);

  function handleReturnToBeginning() {
    setCurrentStepIndex(0);
  }

  function handleGoToStep(currentStep) {
    setCurrentStepIndex(currentStep);
  }

  return (
    <>
      <div>
        {step}
        <HowToUseBulletsWrapper>
          {steps.map((item, index) => (
            <HowToUseBullets
              key={Math.random()}
              currentStepIndex={currentStepIndex === index}
              role="button"
              onClick={() => handleGoToStep(index)}
            />
          ))}
        </HowToUseBulletsWrapper>

        <CloseButton type="button" onClick={onRequestClose}>
          {window.innerWidth < desktopMinSize ? (
            <MdOutlineClose fill="#fff" size={14} />
          ) : (
            <MdOutlineClose fill="#fff" size={30} />
          )}
        </CloseButton>
      </div>

      {!isLastStep && (
        <NextButton type="button" onClick={nextStep}>
          <MdChevronRight fill="#fff" size={18} />
        </NextButton>
      )}

      {!isFirstStep && (
        <PreviousButton type="button" onClick={previousStep}>
          <MdChevronLeft fill="#fff" size={18} />
        </PreviousButton>
      )}
    </>
  );
};
