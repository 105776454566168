import TextField from '@material-ui/core/TextField';
import React from 'react';
import styled from 'styled-components';

import { mqmax, desktopMinSize } from '@Styles/mixins';

export const Input = props => <InputElement {...props} />;

export default Input;

const InputElement = styled(TextField)`
  &.MuiFormControl-root {
    width: 100%;

    img {
      width: 20px;
      aspect-ratio: 1;
      margin-left: 4px;
      margin-top: -3px;
    }
  }

  .MuiInputLabel-outlined {
    width: max-content;
    max-width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &.MuiInputLabel-shrink {
      transform: scale(0.75) translate(-50%, -175%);
    }
  }

  .MuiFormLabel-asterisk {
    display: none;
  }

  .MuiInputBase-input {
    font-size: 15px;
    text-align: left;
    font-weight: 300;
    ${mqmax(desktopMinSize - 1)} {
      font-size: calc(14 / 16 * 1rem);
    }
  }

  .MuiOutlinedInput {
    &-input {
      max-width: 315px;
      width: 100%;
      height: 13px;
      margin-left: 8px;
    }

    &-root {
      max-width: 341px;
      margin: 0 auto;
      width: 100%;
      border-radius: calc(24 / 16 * 1rem);
      background-color: #fff;
      input:-webkit-autofill {
        background-clip: text;
        -webkit-background-clip: text;
      }
    }
  }

  .PrivateNotchedOutline-root-1 {
    display: none;
  }
`;
