import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Box from './Box';
import Container from './Container';
import Close from './Close';
import styled from 'styled-components';
import Header from './Header';
import Form from './Form';
import Input from './Input';
import Back from './Back';
import SectionSubtitle from './PaymentSubtitle';
import Checkbox from './Checkbox';
import Submit from './Submit';
import { getAddressByCep } from '@Services/noar-api';
import { Loading } from '../../../Loading';
import { mqmax, desktopMinSize } from '@Styles/mixins';
import { REDIRECT_TO_LOGIN, messageDisconnect, ERROR } from '@Constants';
import { SessionExpired } from '@Errors';
import { openConfirmationLightbox } from '@Store/ducks/global/actions';

export const AdressData = ({
  close,
  handleViewControl,
  adressInfo,
  handleAdressInfo,
  handleConfirmPaymentReturn
}) => {
  const [address, handleAdress] = useState(adressInfo?.address);
  const [billing, handleBilling] = useState(adressInfo?.billing);
  const [keepAdressOption, handleKeepAdressOption] = useState(adressInfo?.keepAdressOption);
  const [changedKeepAdressOption, handleChangedKeepAdressOption] = useState(adressInfo?.changedKeepAdressOption);
  const [loading, handleLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { accountPersonalInfo } = useSelector(state => state.global);


  function handleDisableButton() {
    const addressInvalid = !(address?.cep && address?.endereco && address?.numero && address?.bairro && address?.cidade && address?.estado);
    const billingInvalid = !(billing?.cep && billing?.endereco && billing?.numero && billing?.bairro && billing?.cidade && billing?.estado);
    if (!changedKeepAdressOption) return true;
    return keepAdressOption ? addressInvalid : addressInvalid || billingInvalid;
  }

  // ASYNC FUNCTIONS

  async function getAddress(origin) {
    try {
      if(address?.cep?.length === 8) {
        handleLoading(true);
        const response = await getAddressByCep(address?.cep);
        if(origin === 'ADDRESS') {
          handleAdress({...address, ...{
            endereco: response?.logradouro,
            bairro: response?.bairro,
            cidade: response?.localidade,
            estado: response?.uf
          }});
        }else {
          handleBilling({...billing, ...{
            endereco: response?.logradouro,
            bairro: response?.bairro,
            cidade: response?.localidade,
            estado: response?.uf
          }});
        }
        handleLoading(false);
      }
    } catch (error) {
      handleLoading(false);
      if (error instanceof SessionExpired) {
        const turnOnMachine = false;
        const disconnect = true;
        dispatch(
          openConfirmationLightbox(
            () => {
              history.push(REDIRECT_TO_LOGIN);
            },
            messageDisconnect.message,
            messageDisconnect.confirmBtnText,
            messageDisconnect.refuseBtnText,
            turnOnMachine,
            disconnect,
            false,
          ),
        );
      } else {
        
        handleConfirmPaymentReturn({ status: ERROR });
      }
    }
  }

  function onChangeCep(event, origin) {
    event.preventDefault();
    let cep = event.target.value.replace(/\D/g, '').substring(0, 8);
    if(origin === 'ADDRESS') {
      handleAdress({...address, cep});
    }else {
      handleBilling({...billing, cep});
    }
  }

  function onChangeNumero(event, origin) {
    event.preventDefault();
    if(origin === 'ADDRESS') {
      handleAdress({...address, numero: event.target.value});
    }else {
      handleBilling({...billing, numero: event.target.value});
    }
  }

  function onChangeComplemento(event, origin) {
    event.preventDefault();
    if(origin === 'ADDRESS') {
      handleAdress({...address, complemento: event.target.value});
    }else {
      handleBilling({...billing, complemento: event.target.value});
    }
  }

  function onChangeKeepAdressOption(option) {
    if(option) {
      handleBilling({...address});
    }else {
      handleBilling(null);
    }
    handleChangedKeepAdressOption(true);
    handleKeepAdressOption(option);
  }

  function onChangeEndereco(event, origin) {
    event.preventDefault();
    if(origin === 'ADDRESS') {
      handleAdress({...address, endereco: event.target.value});
    }else {
      handleBilling({...billing, endereco: event.target.value});
    }
  }

  function onChangeBairro(event, origin) {
    event.preventDefault();
    if(origin === 'ADDRESS') {
      handleAdress({...address, bairro: event.target.value});
    }else {
      handleBilling({...billing, bairro: event.target.value});
    }
  }

  function onChangeCidade(event, origin) {
    event.preventDefault();
    if(origin === 'ADDRESS') {
      handleAdress({...address, cidade: event.target.value});
    }else {
      handleBilling({...billing, cidade: event.target.value});
    }
  }

  function onChangeEstado(event, origin) {
    event.preventDefault();

    const upperCasedValue = event.target.value ? event.target.value.toUpperCase() : '';

    if(origin === 'ADDRESS') {
      handleAdress({...address, estado: upperCasedValue});
    }else {
      handleBilling({...billing, estado: upperCasedValue});
    }
  }


  function onSubmitForm(ev) {
    ev.preventDefault();
    handleAdressInfo({ address, billing, keepAdressOption, changedKeepAdressOption});
    handleViewControl(4);
  }

  function getData() {
    if(accountPersonalInfo?.accountPersonalInfo?.personal_information?.address) {
      const address = accountPersonalInfo?.accountPersonalInfo?.personal_information?.address;
      handleAdress({
        cep: address?.zip_code,
        endereco: address?.street,
        numero: address?.number,
        bairro: address?.neighborhood,
        cidade: address?.city,
        estado: address?.state,
        complemento: address?.complement
      });
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <Container keepAdressOption={keepAdressOption}>
      {loading ? (
        <Loading whichLoading={'generic'} />
      ) : (
        <Box>
          <ActionsDiv>
            <Back
              goBack={() => {
                handleViewControl(1);
              }}
            ></Back>
            <CloseDiv>
              <Close close={close} />
            </CloseDiv>
          </ActionsDiv>
          <Header/>
          <Form onSubmit={onSubmitForm}>
            <Input
              type="text"
              name="cep"
              placeholder="CEP"
              required
              inputProps={{ maxLength: 9 }}
              value={address?.cep}
              onChange={(e) => {onChangeCep(e, 'ADDRESS')}}
              onBlur={() => {getAddress('ADDRESS')}}
              autoComplete="off"
              variant="outlined"
            />

            <Input
              type="text"
              name="endereco"
              placeholder="Endereço"
              required
              inputProps={{ maxLength: 255 }}
              value={address?.endereco}
              onChange={(e) => {onChangeEndereco(e, 'ADDRESS')}}
              autoComplete="off"
              variant="outlined"
            />

            <Input
              type="text"
              name="numero"
              placeholder="Número"
              required
              inputProps={{ maxLength: 15 }}
              value={address?.numero}
              onChange={(e) => {onChangeNumero(e, 'ADDRESS')}}
              autoComplete="off"
              variant="outlined"
            />

            <Input
              type="text"
              name="bairro"
              placeholder="Bairro"
              required
              inputProps={{ maxLength: 15 }}
              value={address?.bairro}
              onChange={(e) => {onChangeBairro(e, 'ADDRESS')}}
              autoComplete="off"
              variant="outlined"
            />

            <Input
              type="text"
              name="cidade"
              placeholder="Cidade"
              required
              inputProps={{ maxLength: 50 }}
              value={address?.cidade}
              onChange={(e) => {onChangeCidade(e, 'ADDRESS')}}
              autoComplete="off"
              variant="outlined"
            />

            <Input
              type="text"
              name="sigla do estado"
              placeholder="Sigla do Estado"
              required
              inputProps={{ maxLength: 2 }}
              value={address?.estado}
              onChange={(e) => {onChangeEstado(e, 'ADDRESS')}}
              autoComplete="off"
              variant="outlined"
              onKeyPress={(e) => {
                const regex = new RegExp('^[a-zA-Z]+$');
                const key = String.fromCharCode(!e.charCode ? e.which : e.charCode);
                if (!regex.test(key)) {
                  e.preventDefault();
                  return false;
                }
              }}
            /> 
            <Div>  
              <Input
                type="text"
                name="complemento"
                placeholder="Complemento"
                inputProps={{ maxLength: 50 }}
                value={address?.complemento}
                onChange={(e) => {onChangeComplemento(e, 'ADDRESS')}}
                autoComplete="off"
                variant="outlined"
              />
            </Div>
            <Div>
              <SectionSubtitle>Usar endereço como o endereço de cobrança</SectionSubtitle>
              <CheckboxDiv>
                <Checkbox
                  value={keepAdressOption}
                  onChange={() => {
                    onChangeKeepAdressOption(true);
                  }}
                  checkedValue={keepAdressOption && changedKeepAdressOption}
                  text={'Sim'}
                />
                <Checkbox
                  value={keepAdressOption}
                  onChange={() => {
                    onChangeKeepAdressOption(false);
                  }}
                  checkedValue={!keepAdressOption && changedKeepAdressOption}
                  text={'Não'}
                />
              </CheckboxDiv>
            </Div>

            {
              !keepAdressOption  ? (
                <>
                  <Input
                    type="text"
                    name="cep"
                    placeholder="CEP"
                    required
                    inputProps={{ maxLength: 9 }}
                    value={billing?.cep}
                    onChange={(e) => {onChangeCep(e, 'BILLING')}}
                    onBlur={() => {getAddress('BILLING')}}
                    autoComplete="off"
                    variant="outlined"
                  />

                  <Input
                    type="text"
                    name="endereco"
                    placeholder="Endereço"
                    required
                    inputProps={{ maxLength: 25 }}
                    value={billing?.endereco}
                    onChange={(e) => {onChangeEndereco(e, 'BILLING')}}
                    autoComplete="off"
                    variant="outlined"
                  />

                  <Input
                    type="text"
                    name="numero"
                    placeholder="Número"
                    required
                    inputProps={{ maxLength: 5 }}
                    value={billing?.numero}
                    onChange={(e) => {onChangeNumero(e, 'BILLING')}}
                    autoComplete="off"
                    variant="outlined"
                  />

                  <Input
                    type="text"
                    name="bairro"
                    placeholder="Bairro"
                    required
                    inputProps={{ maxLength: 15 }}
                    value={billing?.bairro}
                    onChange={(e) => {onChangeBairro(e, 'BILLING')}}
                    autoComplete="off"
                    variant="outlined"
                  />

                  <Input
                    type="text"
                    name="cidade"
                    placeholder="Cidade"
                    required
                    inputProps={{ maxLength: 20 }}
                    value={billing?.cidade}
                    onChange={(e) => {onChangeCidade(e, 'BILLING')}}
                    autoComplete="off"
                    variant="outlined"
                  />

                  <Input
                    type="text"
                    name="sigla do estado"
                    placeholder="Sigla do Estado"
                    required
                    inputProps={{ maxLength: 2 }}
                    value={billing?.estado}
                    onChange={(e) => {onChangeEstado(e, 'BILLING')}}
                    autoComplete="off"
                    variant="outlined"
                    onKeyPress={(e) => {
                      const regex = new RegExp('^[a-zA-Z]+$');
                      const key = String.fromCharCode(!e.charCode ? e.which : e.charCode);
                      if (!regex.test(key)) {
                        e.preventDefault();
                        return false;
                      }
                    }}
                  /> 
                  <Div>  
                    <Input
                      type="text"
                      name="complemento"
                      placeholder="Complemento"
                      inputProps={{ maxLength: 14 }}
                      value={billing?.complemento}
                      onChange={(e) => {onChangeComplemento(e, 'BILLING')}}
                      autoComplete="off"
                      variant="outlined"
                    />
                  </Div>
                </>
              ) : []
            }
            <SubmitDiv>
              <Submit props={{ disabled: handleDisableButton() }} />
            </SubmitDiv>
          </Form>
        </Box>
      )}
    </Container>
  );
};

export default AdressData;

const ActionsDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: auto;
  ${mqmax(desktopMinSize - 1)} {
    height: calc(30 / 16 * 1rem);
  }
`;


const CloseDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  height: auto;
`;

const Div = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: auto;
  grid-column-start: 1;
  grid-column-end: 3;
`;

const SubmitDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: calc(15 / 16 * 1rem);
  height: auto;
  grid-column-start: 1;
  grid-column-end: 3;
  margin-top: calc(14 / 16 * 1rem);
  ${mqmax(desktopMinSize - 1)} {
    margin-top: calc(10 / 16 * 1rem);
  }
`;

const CheckboxDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: calc(52 / 16 * 1rem);
  margin-top: calc(14 / 16 * 1rem);
  ${mqmax(desktopMinSize - 1)} {
    column-gap: calc(30 / 16 * 1rem);
  }
`;
