import { desktopMinSize, mqmax, mqmin } from '../../../../styles/mixins';
import styled, { keyframes } from 'styled-components';

export const ReferralVoucherContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const TitleInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  ${mqmax(desktopMinSize - 1)} {
    gap: 0.5rem;
  }

  & > h3 {
    align-self: center !important;
  }
`;

export const RegistersInfoWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;
  flex-direction: column;
  color: #fff;
`;

export const RegistersTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  .MuiSvgIcon-root {
    cursor: pointer; 
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${props => props.expand ? "2rem" : "0"};
  
  ${mqmin(desktopMinSize)} {
    height: 225px;
  }

  ${mqmax(desktopMinSize - 1)} {
    flex-direction: column;
  }
`;

export const VoucherInfoWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 0.8rem;
  overflow: hidden;
  transition: all 0.5s;

  ${mqmin(desktopMinSize)} {
    width: ${props => props.expand ? "300px" : "0px"};
  }

  ${mqmax(desktopMinSize - 1)} {
    height: ${props => props.expand ? "135px" : "0px"};
  }

  & > p {
    line-height: 1.5rem;
    white-space: nowrap;
  }
`;


export const QrCodeContainer = styled.div`
  padding: 10px;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

export const CopyContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  ${mqmax(desktopMinSize - 1)} {
    gap: 0.5rem;
  }
`;

export const ColoredStyledP = styled.p`
  cursor: pointer;  
  font-size: 2rem;
  font-weight: 700;
  line-height: 3rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${mqmin(1900)} {
    font-size: 2.5rem;
    line-height: 3.5rem;
  }

  ${mqmax(desktopMinSize - 1)} {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
`;

export const ColoredEm = styled.em`
  color: transparent;
  background: linear-gradient(90deg, #2d80f2 0%, #a00bf6 100%);
  -webkit-background-clip: text;
  background-clip: text;
`;

const fillAnimation = keyframes`
  0% {
    width: 0;
  }
  50%, 100% {
    width: 100%;
  }
`;

const fadeAnimation = keyframes`
  0%, 50% {
    left: 0;
  }
  100% {
    left: 100%;
  }
`;

export const ColoredCopyEm = styled.em`
  position: relative;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  overflow: hidden;

  &::before {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    color: transparent;
    overflow: hidden;
    background: linear-gradient(90deg, #2d80f2 0%, #a00bf6 100%);
    -webkit-background-clip: text;
    background-clip: text;
    animation: ${fillAnimation} 1.5s, ${fadeAnimation} 1.5s;
  }
`;


export const ColoredCopyP = styled.p`
  ${({ isCopied }) => (isCopied ? 'display:flex !important' : 'display: none !important')};
  cursor: pointer;  
  font-size: 1rem;
  font-weight: 700;
  line-height: 3rem;
  text-align: center;
  flex-direction: column;
  justify-content: center;

  ${mqmax(desktopMinSize - 1)} {
    font-size: 0.7rem;
    line-height: 0.5rem;
  }
`;