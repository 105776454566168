import { useEffect, useState } from 'react';
import { Title } from '../styles';
import DatePickerContent from './DatePicker';
import * as S from './styled';
import AnalyticsInfoCard from './AnalyticsInfoCard';
import { getB2BConsumptionData } from '../../../services/noar-api';

const AnalyticsSection = ({admPlan}) => {

  const [initialDate, setInitialDate] = useState();
  const [endDate, setEndDate] = useState(new Date());
  const [contractedHours, setContractedHours] = useState(0);
  const [consumedHours, setConsumedHours] = useState(0);
  const [contractedNetwork, setContractedNetwork] = useState(0);
  const [consumedNetwork, setConsumedNetwork] = useState(0);

  const analyticsInfo = [
    {
        title: 'Tempo utilizado da máquina',
        maxAmount: contractedHours ? contractedHours.toFixed(0) : 0,
        usedAmount: consumedHours ? consumedHours.toFixed(2) : 0,
        unit: 'h',
        planName: null
    },
    {
        title: 'Quantidade de internet utilizada',
        maxAmount: contractedNetwork ? contractedNetwork.toFixed(0) : 0,
        usedAmount: consumedNetwork ? consumedNetwork.toFixed(2) : 0,
        unit: 'GB',
        planName: null
    },
    {
        title: 'Plano contratado:',
        maxAmount: null,
        usedAmount: null,
        unit: null,
        planName: 'Noar Enterprise'
    }
]

  function handleInitialDateChange(newValue) {
    if (newValue > endDate) {
      setEndDate(newValue);
    }
    setInitialDate(newValue);
    updateAnalyticsInfoFiltering(newValue, endDate)
  }
  function handleEndDateChange(newValue) {
    if (newValue < initialDate) {
      setInitialDate(newValue);
    }
    setEndDate(newValue);
    updateAnalyticsInfoFiltering(initialDate, newValue)
  }

  async function updateAnalyticsInfoFiltering(initialDate, endDate) {
    await getB2BConsumptionData(initialDate, endDate)
        .then((response) => {
          if(response && response.consumedHours) {
            const hours = response.consumedHours.hours;
            const minutes = response.consumedHours.minutes;
            const totalInDecimal = (hours + (minutes / 60));
            setConsumedHours(totalInDecimal);
          }

          if(response && response.amountNetworkUsed){
            const amountNetworkUsed = response.amountNetworkUsed/1024/1024;
            setConsumedNetwork(amountNetworkUsed);
          }
        });
  }

  useEffect(() => {
    var signatureDate = new Date(admPlan?.signature_date);
    setInitialDate(signatureDate);
    setContractedHours(admPlan ? admPlan.contracted_hours : 0);
    setConsumedHours(admPlan ? admPlan.consumed_hours : 0);
    setContractedNetwork(admPlan ? admPlan.upload_limit/1024/1024 : 0);
    setConsumedNetwork(admPlan ? admPlan.consumed_upload/1024/1024 : 0);
  }, [admPlan]);

  return (
    <S.AnalyticsSectionWrapper>
      <Title>Dashboard: Mês atual</Title>
      <S.AnalyticsSectionContent>
        <S.AnalyticsInfoSection>
            {
                analyticsInfo.map((info, index) => {
                    return (<AnalyticsInfoCard
                        key={index}
                        title={info.title}
                        maxAmount={info.maxAmount}
                        unit={info.unit}
                        usedAmount={info.usedAmount}
                        planName={info.planName}/>)
                })
            }
        </S.AnalyticsInfoSection>
      </S.AnalyticsSectionContent>
    </S.AnalyticsSectionWrapper>
  );
};

export default AnalyticsSection;