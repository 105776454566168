import React from 'react';

import Container from './Container';
import Subtitle from './Subtitle';
import Title from './Title';
import Desktop from '../../Desktop';

export const Header = () => (
  <Container>
    <Title />
    <Desktop>
      <Subtitle />
    </Desktop>
  </Container>
);

export default Header;
