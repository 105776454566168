import styled from 'styled-components';
import { mqmax, desktopMinSize } from '@Styles/mixins';

export const LoadingSkeleton = styled.div`
  max-width: ${({ width }) => width};
  width: 100%;
  height: ${({ height }) => height};
  background: #2b3743;
  position: relative;
  top: ${({ top }) => top};
  border-radius: ${({ borderRadius }) => borderRadius};

  ${mqmax(desktopMinSize - 1)} {
    max-width: ${({ mobileWidth }) => mobileWidth};
    height: ${({ mobileHeight }) => mobileHeight};
    top: ${({ mobileTop }) => mobileTop};
    border-radius: ${({ mobileBorderRadius }) => mobileBorderRadius};
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right, #2b3743 0%, #888b94 20%, #2b3743 40%, #2b3743 100%);
    background-size: 450px 506px;
    background-repeat: no-repeat;
    animation: shimmer 1.4s linear infinite;
    border-radius: ${({ borderRadius }) => borderRadius};

    ${mqmax(desktopMinSize - 1)} {
      border-radius: ${({ mobileBorderRadius }) => mobileBorderRadius};
    }
  }

  @keyframes shimmer {
    0% {
      background-position: -450px 0px;
    }
    100% {
      background-position: 450px 0px;
    }
  }
`;
