import React, { useState } from 'react';
import * as NoarAPI from '@Services/noar-api';
import Form from './Form';
import Input from './Input';
import Wrapper from './Wrapper';
import Overlay from '@Containers/Overlay';
import Subtitle from '../Subtitle';
import ErrorMessage from '../ErrorMessage';
import SuccessMessage from '../SuccessMessage';
import StyledButton from '../styled';

export const CouponContent = () => {
  const [coupon, handleCoupon] = useState('');
  const [loading, handleLoading] = useState(false);
  const [showInputCoupon, handleShowInputCoupon] = useState(false);
  const [errorCouponMessage, handleErrorCouponMessage] = useState('');
  const [successCouponMessage, handleSuccessCouponMessage] = useState('');

  // ASYNC FUNCTIONS

  async function addCoupon() {
    try {
      handleLoading(true);
      await NoarAPI.addCoupon(coupon);
      handleCoupon('');
      handleLoading(false);
      handleSuccessCouponMessage('Vale presente processado com sucesso!');
      resetErrorSuccessMessage();
    } catch (error) {
      handleErrorCouponMessage(error.message);
      handleLoading(false);
      resetErrorSuccessMessage();
    }
  }
  // EVENTS

  function onChangeCoupon(event) {
    handleCoupon(event.target.value);
  }

  function onClickButtonCoupon() {
    if (!showInputCoupon || coupon === '') {
      handleShowInputCoupon(!showInputCoupon);
    }
  }

  function onSubmitCouponForm(ev) {
    ev.preventDefault();
    addCoupon();
  }

  function resetErrorSuccessMessage() {
    setTimeout(function () {
      handleErrorCouponMessage('');
      handleSuccessCouponMessage('');
    }, 5000);
  }

  return (
    <Wrapper>
      {loading ? <Overlay /> : []}
      <Subtitle>Vale presente</Subtitle>
      <Form onSubmit={onSubmitCouponForm}>
        {showInputCoupon ? (
          <Input
            type="text"
            name="Coupon"
            placeholder="Digite o código"
            required
            value={coupon}
            onChange={onChangeCoupon}
            autoComplete="off"
            variant="outlined"
          />
        ) : (
          []
        )}
        {errorCouponMessage ? <ErrorMessage>{errorCouponMessage}</ErrorMessage> : []}
        {successCouponMessage ? <SuccessMessage>{successCouponMessage}</SuccessMessage> : []}
        <StyledButton type={showInputCoupon && coupon !== '' ? 'submit' : 'button'} onClick={onClickButtonCoupon}>
          {showInputCoupon ? 'VALIDAR VALE' : 'ADICIONAR VALE'}
        </StyledButton>
      </Form>
    </Wrapper>
  );
};

export default CouponContent;
