import React, { useState, useEffect } from 'react';

import { useTutorialStep } from '../../../context/TutorialStepContext';
import { SixthStep } from './SixthStep';
import { SeventhStep } from './SeventhStep';
import { EighthStep } from './EighthStep';
import { FinalStep } from './FinalStep';

export const ProgramTutorial = () => {
  const { handleNextStep, handleSkipTutorial, state } = useTutorialStep();
  const [isFirstAccess, setIsFirstAccess] = useState(false);

  useEffect(() => {
    var firstAccess = JSON.parse(localStorage.getItem('firstAccess'));
    if (firstAccess && firstAccess[0]?.programSteps) {
      setIsFirstAccess(false);
    } else {
      setIsFirstAccess(true);
      localStorage.setItem(
        'firstAccess',
        JSON.stringify([
          {
            initialAMISteps: true,
            sectionPageSteps: true,
            programSteps: true,
          },
        ]),
      );
    }
  }, []);

  if (!isFirstAccess) return null;

  switch (state[2].step) {
    case 0:
      return (
        <SixthStep
          nextStep={() => handleNextStep('program-page')}
          skipTutorial={() => handleSkipTutorial('program-page')}
        />
      );

    case 1:
      return (
        <SeventhStep
          nextStep={() => handleNextStep('program-page')}
          skipTutorial={() => handleSkipTutorial('program-page')}
        />
      );
    case 2:
      return (
        <EighthStep
          nextStep={() => handleNextStep('program-page')}
          skipTutorial={() => handleSkipTutorial('program-page')}
        />
      );
    case 3:
      return (
        <FinalStep
          nextStep={() => handleNextStep('program-page')}
          skipTutorial={() => handleSkipTutorial('program-page')}
        />
      );

    default:
      return null;
  }
};
