import React, { useState, useEffect } from 'react';
import { getInstance } from '@Services/noar-api';
import { useSelector, useDispatch } from 'react-redux';
import { SessionPresentation, Img } from './styled';
import { Skeleton } from '../../../../components/Skeleton';
import {
  updateComputer
} from '@Store/ducks/computer/actions';

const Presentation = () => {
  const { session } = useSelector(state => state.computer);
  const [isSessionPresentationLoading, setIsSessionPresentationLoading] = useState(false);
  const dispatch = useDispatch();

  async function getInstanceData() {
    try {
      setIsSessionPresentationLoading(true);
      if (!session) {
        const response = await getInstance();
        if(response[0]?.session) {
          dispatch(updateComputer(response[0]));
        }
      }
      setIsSessionPresentationLoading(false);
    } catch (error) {
      setIsSessionPresentationLoading(false);
    }
  }

  useEffect(() => {
    getInstanceData();
  }, []);

  return (
    <SessionPresentation className="computer__presentation">
      {isSessionPresentationLoading ? (
        <Skeleton
          width="499px"
          height="414px"
          borderRadius="38px"
          mobileWidth="329px"
          mobileHeight="141px"
          mobileBorderRadius="23px"
        />
      ) : (
        <Img alt="" src={session?.photo} />
      )}
    </SessionPresentation>
  );
};

export default Presentation;
