import { AdminContentWrapper, AdminTable, AdminTableWrapper, CollaboratorActionButton, CollaboratorActionButtonWrapper, Td, Th } from "../styled";
import { Loading } from "../../Loading";

const DesktopWrapper = ({
  loading,
  goldImagesInfo,
  actions,
  handleDeleteGoldImage,
  handleEditGoldImage,
  handlePlayGoldImage,
}) => {
  return (
    <AdminTableWrapper>
      <AdminContentWrapper>
        {loading ? (
          <Loading />
        ) : (
          <AdminTable>
            <thead>
              <tr>
                <Th scope="colgroup">Nome</Th>
                <Th scope="colgroup">Descrição</Th>
                <Th scope="colgroup">Host</Th>
              </tr>
            </thead>

            <tbody>
              {goldImagesInfo?.map((image, index) => {
                const action = actions(image)
                return (
                  <tr key={index + 1}>
                    <Td scope="col" title={image.image_name}>{image.image_name}</Td>
                    <Td scope="col" title={image.description}>{image.description}</Td>
                    <Td scope="col" title={image.host?.nickname || "Não encontrado"}>{image.host?.nickname || "Não encontrado"}</Td>
                    <Td scope="col">
                      <CollaboratorActionButtonWrapper>
                        <CollaboratorActionButton onClick={() => handleEditGoldImage(image)} title={action.edit.title}>{action.edit.ico}</CollaboratorActionButton>
                        <CollaboratorActionButton onClick={() => handleDeleteGoldImage(image)} title={action.delete.title}>{action.delete.ico}</CollaboratorActionButton>
                        <CollaboratorActionButton onClick={() => handlePlayGoldImage(image)} title={action.play.title}>{action.play.ico}</CollaboratorActionButton>
                      </CollaboratorActionButtonWrapper>
                    </Td>
                  </tr>
                );
              })}
            </tbody>
          </AdminTable>
        )}
      </AdminContentWrapper>
    </AdminTableWrapper>
  );
}

export default DesktopWrapper;