import styled from 'styled-components';

import { Link as RouterLink } from 'react-router-dom';
import { Button as MuiButton } from '@material-ui/core';

import { desktopMinSize, rem, mqmax, mqmin } from '@Styles/mixins';
import { Close as MuiCloseIcon, Menu as MuiMenuIcon } from '@material-ui/icons';

export const CloseIcon = styled(MuiCloseIcon)`
color: #fff;
`;

export const Menu = styled.dialog`
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: space-between;
  box-sizing: border-box;
  height: 100%;
  min-width: 250px;
  max-height: none;
  border: 0;
  border-radius: 24px 0px 0px 24px;
  position: fixed;
  inset: 0 0 auto auto;
  z-index: 1;
  overflow: auto;
  background: rgba(23, 23, 26, 0.7);
  box-shadow: 0px 4px 4px #000000;
  backdrop-filter: blur(5px);
  transform: translateX(100%);
  transition: transform calc(1s / 3) ease-in-out;

  &[open] {
    transform: translateX(0);
  }
`;

export const TextButton = styled(MuiButton)`
&.MuiButton-root {
  justify-content: end;
  color: #fff;
  font-size: calc(16rem / 16);
  font-weight: normal;
  line-height: ${28 / 17};
  text-transform: none;
  transition: color 300ms ease-out;
  margin-top: calc(20rem / 16);
  margin-right: 10px;

  &:hover,
  &.active {
    color: #2d80f2;
  }

  &:hover {
    background: none;
  }
}
`;

export const ButtonWrapper = styled.div`
margin-right: 10px;
`

export const Links = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  
`;

export const OptionsWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: end;
width: 100%;

${Links} {
  margin-top: calc(16rem / 16);
}
`;


export const Container = styled.div`
  width: 80%;
  margin: ${rem(16)} auto;

  font-family: 'Poppins', sans-serif;

  .plan {
    left: 50%;
    max-width: fit-content;
    padding: 7px 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 8px;
    border-radius: 16px;
    border: 1.5px solid #2d80f2;

    & > span {
      color: #2d80f2;
      font-size: 0.625rem;
      font-weight: 600;
    }
  }

  ${mqmin(desktopMinSize)} {
    display: none;
  }
  ${mqmax(desktopMinSize - 1)} {
    display: block;
    width: 90%;
  }
`;

export const Div = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Logo = styled.img`
  display: flex;
  width: ${rem(34)};
  cursor: pointer;

  ${mqmin(desktopMinSize)} {
    width: ${rem(45)};
  }
`;

export const MenuIcon = styled(MuiMenuIcon)`
color: #fff;
`;

export const Time = styled(RouterLink)`
  color: #fff;
  font-family: 'Poppins', sans-serif;
  font-size: 0.8125rem !important;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 6.1px;
    width: ${rem(13)};
    height: ${rem(13)};
  }
`;
