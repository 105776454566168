import styled from 'styled-components';
import { mqmin, mqmax, desktopMinSize } from '@Styles/mixins';

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: calc(20 / 16 * 1rem);
  width: 100%;
  position: relative;
  ${mqmax(desktopMinSize - 1)} {
    div:nth-child(3) {
      width: 100%;
    }
  }
  ${mqmin(desktopMinSize)} {
    max-width: calc(911 / 16 * 1rem);
    box-sizing: border-box;
    border-radius: calc(64 / 16 * 1rem);
  }
`;

export default Div;
