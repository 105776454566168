import React from 'react';
import styled from 'styled-components';
import image from './image.svg';
import SectionTitle from '../RegisterConfirmationTitle';
import Desktop from '../../../Desktop';
import Mobile from '../../../Mobile';

export const Title = () => (
    <>
        <Desktop>
            <SectionTitle>VALIDAÇÃO</SectionTitle>
        </Desktop>
        <Mobile>
            <Img alt="Noar" src={image} />
        </Mobile>
    </>
);

export default Title;

const Img = styled.img`
  display: block;
  width: auto;
  height: calc(36 / 16 * 1rem);
`;
