import React from 'react';
import styled from 'styled-components';

import { Title, Subtitle } from '@Components/Public';
import { desktopMinSize, mqmin } from '@Styles/mixins';

import discord from './discord.svg';
import email from './email.svg';

export function Contact() {
  return (
    <Container>
      <Section as="div">
        <Header>
          <Title as="h1">
            <strong>Contato</strong>
          </Title>
          <Subtitle className="align-center">
            Você pode optar por entrar em nosso servidor do Discord para ficar por dentro de todas as novidades,{' '}
            <strong>
              tira dúvidas e pode conhecer melhor a equipe <span className="uppercase">Noar</span>.
            </strong>{' '}
            Ou entrar em contato pelo <strong>nosso e-mail!</strong>
          </Subtitle>
        </Header>
        <Buttons>
          <Button component="a" href="https://discord.com/invite/noar-798022821342609418" target="_blank" rel="noreferrer">
            <Icon alt="Discord" src={discord} />
          </Button>
          <Button component="a" href="mailto:contato@noarcloud.com" target="_blank" rel="noreferrer">
            <Icon alt="E-mail" src={email} />
          </Button>
        </Buttons>
      </Section>
    </Container>
  );
}

export default Contact;

const Container = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: calc(141rem / 24);
  max-width: min(calc(304 / 360 * 100%), calc(1331rem / 16));
  margin-inline: auto;
  padding-block: calc(32rem / 16) calc(112rem / 16);

  ${mqmin(desktopMinSize)} {
    row-gap: calc(141rem / 16);
    padding-block: calc(206rem / 16);
  }
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  row-gap: calc(80rem / 32);

  ${mqmin(desktopMinSize)} {
    row-gap: calc(80rem / 16);
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: calc(10rem / 16);
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  column-gap: calc(40rem / 32);

  ${mqmin(desktopMinSize)} {
    column-gap: calc(40rem / 16);
  }
`;

const Icon = styled.img`
  width: calc(71rem / 40);
  height: calc(55rem / 40);
  object-fit: center;
  object-position: conver;

  ${mqmin(desktopMinSize)} {
    width: calc(71rem / 16);
    height: calc(55rem / 16);
  }
`;

const Button = styled.a`
  border: solid rgba(255, 255, 255, 35%) calc(2rem / 16);
  border-radius: calc(35rem / 40);
  padding: calc(72rem / 40) calc(64rem / 40);

  ${mqmin(desktopMinSize)} {
    border-radius: calc(35rem / 16);
    padding: calc(72rem / 16) calc(64rem / 16);
  }

  &:hover {
    > ${Icon} {
      scale: calc(7 / 6);
    }
  }

  > ${Icon} {
    transition: scale calc(1s / 3) ease-in-out;
  }
`;
