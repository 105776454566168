import styled from 'styled-components';

import { mqmin, desktopMinSize, defaultFont } from '@Styles/mixins';

export const InProgress = styled.section`
  width: 79.4%;
  height: auto;
  box-sizing: border-box;
  margin: 40.35px auto 0;
  ${mqmin(desktopMinSize)} {
    width: 72.4%;
    margin: 135px auto 0;
  }

  h6 {
    width: 100%;
    height: auto;
    font-family: ${defaultFont};
    font-weight: 700;
    font-style: normal;
    font-size: 18px;
    color: #000000;
    text-decoration: none;
    text-align: left;
    letter-spacing: 0;
    line-height: 26px;
    ${mqmin(desktopMinSize)} {
      font-size: 28px;
      line-height: 40px;
    }
  }

  img {
    width: 122px;
    margin: 27.99px 0 0;
    ${mqmin(desktopMinSize)} {
      width: 198.32px;
      margin: 40px 0 0;
    }
  }
`;
