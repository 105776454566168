import React, { useState, useEffect } from 'react';
import { useTutorialStep } from '../../context/TutorialStepContext';
import { MySessionsPageFirstStep } from './MySessionsPageFirstStep';

export const MySessionsTutorial = () => {
  const { handleSkipTutorial, state } = useTutorialStep();
  const [isFirstAccess, setIsFirstAccess] = useState(false);

  useEffect(() => {
    var firstAccess = JSON.parse(localStorage.getItem('firstAccess'));
    if (firstAccess && firstAccess[0]?.mySessionsSteps) {
      setIsFirstAccess(false);
    } else {
      setIsFirstAccess(true);
      localStorage.setItem(
        'firstAccess',
        JSON.stringify([
          {
            initialAMISteps: true,
            sectionPageSteps: true,
            programSteps: true,
            initialShopPageSteps: true,
            shopPageSteps: true,
            mySessionsSteps: true,
          },
        ]),
      );
    }
  }, []);

  if (!isFirstAccess) return null;

  switch (state[4].step) {
    case 0:
      return (
        <MySessionsPageFirstStep
          nextStep={() => handleSkipTutorial('sessions-page')}
          skipTutorial={() => handleSkipTutorial('sessions-page')}
        />
      );
    default:
      return null;
  }
};
