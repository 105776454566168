import jwt_decode from 'jwt-decode';

/**
 * @param {string} jwt
 */
export function getPayload(jwt) {
  try {
    return jwt_decode(jwt);
  } catch (error) {
    console.error(error);
    return null;
  }
}

/**
 * @param {string} jwt
 */
export function getExpires(jwt, jwtPayload = null) {
  try {
    if (jwtPayload) {
      return parseInt(jwtPayload.exp);
    }

    return parseInt(getPayload(jwt).exp);
  } catch (error) {
    console.error(error);
    return null;
  }
}

/**
 * @param {string} jwt
 */
export function getExpiresMiliSeconds(jwt, jwtPayload = null) {
  try {
    return getExpires(jwt, jwtPayload) * 1000;
  } catch (error) {
    console.error(error);
    return null;
  }
}
