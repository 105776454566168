import { IsLoadingComputerTitle } from './IsLoadingComputerTitle';
import { COMPUTER_STATES } from '@Constants';
import { useSelector } from 'react-redux';

export const IsLoadingComputerHeaderContent = () => {
  const { state, session } = useSelector(state => state.computer);
  return (
    <IsLoadingComputerTitle
      headerTitle={(!state) || (state === COMPUTER_STATES.QUEUE) || (state === COMPUTER_STATES.PENDING) ? 'Iniciando sessão' : 'Finalizando sessão'}
      image_name={ session?.image_name }
    />
  );
};
