import React from 'react';
import SectionTitle from '../PaymentTitle';

export const Title = ({productType}) => (
  <>
    <SectionTitle>Plano pagamento {productType}</SectionTitle>
  </>
);

export default Title;
