import React from 'react';
import Body from './Body';
import PasswordForgotBox from './PasswordForgotBox';
import Desktop from './Desktop';
import HeaderNew from '@Containers/Header-new';

function PasswordForgotPage() {

  return (
    <Body>
      <Desktop>
        <HeaderNew />
      </Desktop>
      <PasswordForgotBox />
    </Body>
  );
}

export default PasswordForgotPage;
