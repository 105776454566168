import types from './types';

const INITIAL_STATE = {
  session: {
    jwt: null,
    client: {
      name: null,
      id: null,
    },
    early_access: false
  },
  used_hours: {
    hours: null,
    minutes: null,
  },
  unit_used_minutes: null,
  contracted_hours: null,
  clientHoursWebsocketOn: false,
  confirmationLightbox: {
    isOpen: false,
    callbackFunction: null,
    text: null,
    confirmBtnText: null,
    refuseBtnText: null,
    turnOnMachine: null,
    disconnect: null,
    isUnit: false,
    clientsInQueue: null,
    averageWaitingTimeMinutes: null,
  },
  npsFormBox: {
    isOpen: false,
    callbackFunction: null,
    closeCallBackFunction: null,
    product: null,
  },

  termsBox: {
    isOpen: false,
    callbackFunction: null,
    closeCallBackFunction: null,
  },
  personalPlan: {
    isActive: false,
    imageStatus: null,
    image_name: null,
  },
  accountPersonalInfo: {
    accountPersonalInfo: null,
    showPopup: false,
    showPopupValidationFunction: null,
    paymentInfo: null,
  }
};

function course(oldState = INITIAL_STATE, action) {
  if (!types[action.type]) {
    return oldState;
  }

  return types[action.type](oldState, action);
}

export default course;
