import { Loading } from '../../../Loading';
import { MobileAccordionContent } from '../MobileAccordion';

const MobileWrapper = ({
  loading,
  goldImagesInfo,
  actions,
  handleEditGoldImage,
  handleDeleteGoldImage,
  handlePlayGoldImage,

}) => {
  return (
    <>
      {' '}
      {loading ? (
        <Loading />
      ) : (
        <>
          {goldImagesInfo?.map(goldImage => {
            return (
              <MobileAccordionContent
                data={goldImage}
                action={actions(goldImage)}
                handleEditGoldImage={handleEditGoldImage}
                handleDeleteGoldImage={handleDeleteGoldImage}
                handlePlayGoldImage={handlePlayGoldImage}
              />
            );
          })}
        </>
      )}
    </>
  );
};

export default MobileWrapper;