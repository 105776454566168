import React, { useState } from 'react';

import {
  AdminAccordionAnswer,
  AdminAccordionTitle,
  AdminAccordionWrapper,
  StatusStrong,
} from './styled';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { CollaboratorActionButton, CollaboratorActionButtonWrapper } from '../../styled';
import { ProtectedComponent } from '../../../../ProtectedComponent';

export const MySessionsMobileAccordionContent = ({
  collaborator,
  active,
  action,
  handleInfoPopupModal,
  handleSuspendCollab,
  handleUnsuspendCollab,
  handleDeleteCollab,
}) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [isAccordionArrowUp, setIsAccordionArrowUp] = useState(false);

  function handleOpenAccordion() {
    setIsAccordionOpen(!isAccordionOpen);
    setIsAccordionArrowUp(!isAccordionArrowUp);
  }

  return (
    <>
      <ProtectedComponent role={['B2B_ADM']}>
        <AdminAccordionWrapper onClick={handleOpenAccordion}>
          <AdminAccordionTitle isAccordionArrowUp={isAccordionArrowUp} {...{ active }}>
            <strong >
              {collaborator.name}{' '}
            </strong>
            <ExpandMoreIcon />
          </AdminAccordionTitle>

          <AdminAccordionAnswer isAccordionOpen={isAccordionOpen}>
            <strong>
              Email: <span>{collaborator.email}</span>
            </strong>
            <strong>
              Cargo: <span>{collaborator.function}</span>
            </strong>
            <StatusStrong {...{ active }}>
              Status: <span>{active ? "Ativo" : "Suspenso"}</span>
            </StatusStrong>
            <CollaboratorActionButtonWrapper>
                          <CollaboratorActionButton onClick={() => handleInfoPopupModal(collaborator)}  title={action.info.title}>{action.info.ico} {action.info.title}</CollaboratorActionButton>
                          <CollaboratorActionButton onClick={active ? () => handleSuspendCollab(collaborator) : () => handleUnsuspendCollab(collaborator)}  title={action.block.title}>{action.block.ico} {action.block.title}</CollaboratorActionButton>
                          {!active 
                            ? <CollaboratorActionButton onClick={() => handleDeleteCollab(collaborator)} title={action.delete.title}>
                                {action.delete.ico} {action.delete.title}
                              </CollaboratorActionButton> 
                            : []}
                        </CollaboratorActionButtonWrapper>
          </AdminAccordionAnswer>
        </AdminAccordionWrapper>
      </ProtectedComponent>
    </>
  );
};
