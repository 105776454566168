import React from 'react';
import styled from 'styled-components';
import image from './image.svg';
import SectionTitle from '../CreatePasswordTitle';
import Desktop from '../../../Desktop';
import Mobile from '../../../Mobile';

export const Title = () => (
  <>
    <Desktop>
      <SectionTitle>CRIE UMA<br/>NOVA SENHA</SectionTitle>
    </Desktop>
    <Mobile>
      <a href="/">
        <Img alt="Noar" src={image} />
      </a>
    </Mobile>
  </>
);

export default Title;

const Img = styled.img`
  display: block;
  width: auto;
  height: calc(36 / 16 * 1rem);
`;
