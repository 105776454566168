import styled from 'styled-components';
import { mqmax, desktopMinSize } from '@Styles/mixins';

export const Container = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  row-gap: calc(40 / 16 * 1rem);
  ${mqmax(desktopMinSize - 1)} {
    row-gap: calc(25 / 16 * 1rem);
  }
`;

export default Container;
