import React from 'react';
import Faq from '../Faq';
import Contact from '../Contact';
import * as S from './styled.js';
import MobileHeader from '@Components/Header';

const SupportPage = () => {
  return (
    <S.ContentWrapper>
      <MobileHeader />
      <Contact/>
      <Faq />
    </S.ContentWrapper>
  );
}; 

export default SupportPage;
