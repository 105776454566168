import React from 'react';
import styled from 'styled-components';
import { desktopMinSize, mqmax } from '@Styles/mixins';

import slideImg from '@Assets/8.webp';
import mobileSlideImg from '@Assets/8-mobile.webp';

export const EighthStep = () => {
  return (
    <>
      <div>
        {window.innerWidth < desktopMinSize ? <img src={mobileSlideImg} alt="" /> : <img src={slideImg} alt="" />}
      </div>
      <EighthStepDescription>
        <span>8.</span> No site da NOAR, <span>insira o PIN gerado</span> pelo Moonlight, depois{' '}
        <span>clique em &#34;enviar&#34;.</span>
      </EighthStepDescription>
    </>
  );
};

const EighthStepDescription = styled.strong`
  text-align: left !important;
  font-size: 0.875rem !important;
  line-height: 1.5 !important;

  ${mqmax(desktopMinSize - 1)} {
    font-size: 0.75rem !important;
  }
`;
