import styled from 'styled-components';
import { desktopMinSize, mqmax } from '@Styles/mixins';

export const ShopPageFirstStepWrapper = styled.div`
  position: absolute;
  top: -110px;
  width: 100%;
  z-index: 104;
  line-height: 1.2;
  display: flex;
  align-items: center;
  justify-content: center;
  ${mqmax(desktopMinSize - 1)} {
    top: -117px;
    left: -18px;
  }
`;

export const ShopPageFirstStepContentWrapper = styled.div`
  max-width: 212px;
  width: 100%;
  height: 76px;
  background-color: #2d80f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px;
  border-radius: 25px 25px 25px 2px;
  box-shadow: 0px 9px 16px #00000026;

  & > strong {
    font-weight: 300;
    margin: 0;
    font-size: 1rem;

    span {
      font-weight: 600;
    }
  }
  ${mqmax(desktopMinSize - 1)} {
    max-width: 217px;
    height: 84px;
    font-size: 0.938rem;
    border-radius: 25px 25px 2px 25px;

    & > strong {
      font-size: 0.938rem;
    }
  }
`;

export const ShopPageFirstStepButtonWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  & > button {
    border: 0;
    background-color: transparent;

    &:nth-of-type(1) {
      max-width: 100px;
      width: 100%;
      border-radius: 21px;
      height: 29px;
      outline: 2px solid #fff;
      color: #fff;
      outline-offset: -2px;
      text-transform: uppercase;
      font-weight: 600;
      transition: all 300ms;
      font-size: 0.688rem;
      &:hover {
        background-color: #fff;
        color: #2d80f2;
      }
    }

    &:nth-of-type(2) {
      color: #fff;
      font-weight: 300;
      transition: all 300ms;
      font-size: 0.75rem;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;
      transition: border 300ms;
      width: 100%;
      max-width: fit-content;
      border-radius: 0;

      &:hover {
        border-bottom-color: #fff;
      }
    }
  }
`;

export const ShopPageSecondStepWrapper = styled.div`
  max-width: 2560px;
  position: absolute;
  width: 100%;
  z-index: 104;
  line-height: 1.2;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 50px;
  margin-top: 70px;
  ${mqmax(desktopMinSize - 1)} {
    margin-top: -452px;
    margin-left: -1px;
  }
`;

export const ShopPageSecondStepContentWrapper = styled.div`
  max-width: 212px;
  width: 100%;
  height: 76px;
  background-color: #2d80f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px;
  border-radius: 25px 25px 2px 25px;
  box-shadow: 0px 9px 16px #00000026;

  & > strong {
    font-weight: 300;
    margin: 0;
    font-size: 1rem;
    color: #fff;

    span {
      font-weight: 600;
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    max-width: 217px;
    height: 84px;
    border-radius: 25px 2px 25px 25px;

    & > strong {
      font-size: 0.938rem;
    }
  }
`;

export const ShopPageSecondStepButtonWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  & > button {
    border: 0;
    background-color: transparent;

    &:nth-of-type(1) {
      max-width: 100px;
      width: 100%;
      border-radius: 21px;
      height: 29px;
      outline: 2px solid #fff;
      color: #fff;
      outline-offset: -2px;
      text-transform: uppercase;
      font-weight: 600;
      transition: all 300ms;
      font-size: 0.688rem;
      &:hover {
        background-color: #fff;
        color: #2d80f2;
      }
    }

    &:nth-of-type(2) {
      color: #fff;
      font-weight: 300;
      transition: all 300ms;
      font-size: 0.75rem;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;
      transition: border 300ms;
      width: 100%;
      max-width: fit-content;
      border-radius: 0;

      &:hover {
        border-bottom-color: #fff;
      }
    }
  }
`;

export const ShopPageThirdStepWrapper = styled.div`
  position: absolute;
  top: 156px;
  width: 100%;
  z-index: 104;
  line-height: 1.2;
  display: flex;
  align-items: center;
  justify-content: center;

  ${mqmax(desktopMinSize - 1)} {
    top: 55px;
    left: -40px;
  }
`;

export const ShopPageThirdStepContentWrapper = styled.div`
  max-width: 212px;
  width: 100%;
  height: 76px;
  background-color: #2d80f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px;
  border-radius: 25px 25px 25px 2px;
  box-shadow: 0px 9px 16px #00000026;

  & > strong {
    font-weight: 300;
    margin: 0;
    font-size: 1rem;

    span {
      font-weight: 600;
    }
  }
  ${mqmax(desktopMinSize - 1)} {
    border-radius: 25px 25px 2px 25px;

    & > strong {
      font-size: 0.938rem;
    }
  }
`;

export const ShopPageThirdStepButtonWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  & > button {
    border: 0;
    background-color: transparent;
    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }

    &:nth-of-type(1) {
      max-width: 100px;
      width: 100%;
      border-radius: 21px;
      height: 29px;
      outline: 2px solid #fff;
      color: #fff;
      outline-offset: -2px;
      text-transform: uppercase;
      font-weight: 600;
      transition: all 300ms;
      font-size: 0.688rem;
      &:hover:not(:disabled) {
        background-color: #fff;
        color: #2d80f2;
      }
    }

    &:nth-of-type(2) {
      color: #fff;
      font-weight: 300;
      transition: all 300ms;
      font-size: 0.75rem;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;
      transition: border 300ms;
      width: 100%;
      max-width: fit-content;
      border-radius: 0;

      &:hover {
        border-bottom-color: #fff;
      }
    }
  }
`;
