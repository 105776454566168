import React from 'react';
import {
  AMITutorialThirdStepWrapper,
  AMITutorialThirdStepContentWrapper,
  AMITutorialThirdStepButtonWrapper,
  TutorialBackdrop,
} from '../styled';

export const ThirdStep = ({ nextStep, skipTutorial }) => {
  return (
    <>
      <TutorialBackdrop />
      <AMITutorialThirdStepWrapper>
        <AMITutorialThirdStepContentWrapper>
          <strong>
            Aqui está a <span>lista de programas instalados,</span> caso não tenha o que deseja, basta voltar e escolher
            outro.
          </strong>

          <AMITutorialThirdStepButtonWrapper>
            <button onClick={nextStep}>Entendi</button>
            <button onClick={skipTutorial}>Fechar tutorial</button>
          </AMITutorialThirdStepButtonWrapper>
        </AMITutorialThirdStepContentWrapper>
      </AMITutorialThirdStepWrapper>
    </>
  );
};
