import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import logo from '@Assets/blog-logo.svg';
import { desktopMinSize, mqmin } from '@Styles/mixins';

import Desktop from './Desktop';
import Mobile from './Mobile';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { mqmax } from '../../../styles/mixins';

const Wrapper = props => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < desktopMinSize);

  useEffect(
    function () {
      window.addEventListener(
        'resize',
        function () {
          setIsMobile(window.innerWidth < desktopMinSize);
        },
        { passive: true },
      );
    },
    [setIsMobile],
  );

  return (
    <header {...props}>
      <Container>
        <Anchor to="/">
          <Logo src={logo} alt="Noar" />
        </Anchor>
        {isMobile ? <Mobile navigation={Navigation} /> : <Desktop navigation={Navigation} />}
      </Container>
    </header>
  );
};

function Navigation(path, handleCloseClick) {
  const history = useHistory();
  const isMobile = window.innerWidth < desktopMinSize;
  const onClick = useCallback(async function (event) {
    event.preventDefault();
    if (isMobile) {
      handleCloseClick();
    }
    await history.push(`/${path}`);
    window.location.hash = path;
  }, []);

  return onClick;
}

const Container = styled.nav`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;

  ${mqmin(desktopMinSize)} {
    height: auto;
  }
`;

const Anchor = styled(Link)`
  display: inline-flex;
`;

const Logo = styled.img`
  width: calc(143rem / 24);
  height: auto;

  ${mqmin(desktopMinSize + 400)} {
    width: calc(143rem / 16);
  }

  ${mqmax(300)} {
    width: calc(100rem / 32);
  }
`;

export const Header = styled(Wrapper)`
  --bgAlpha: 1;

  padding: calc(28rem / 32) calc((1 - 304 / 360) * 50%);
  color: #fff;
  border-radius: calc(30rem / 16);

  ${mqmin(desktopMinSize)} {
    --bgAlpha: 70%;

    padding: calc(16rem / 16) calc(57rem / 16);
  }
`;

export default Header;
