import React from 'react';
import { CartPageCardWrapper, CartPageCardCounter, CartPageCardDataWrapper, PriceCartPageWrapper, LineThrough } from '../styled';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { useShoppingCart } from '../../../context/ShoppingCartContext';
import { formatCurrency } from '../../../utils/formatCurrency';
import { FaTrash } from 'react-icons/fa';
import { useTutorialStep } from '../../../context/TutorialStepContext';

export const CartContent = ({ id, quantity, products }) => {
  const { handleDecreaseItemQuantity, handleIncreaseItemQuantity, handleRemoveFromCart } = useShoppingCart();
  const { state } = useTutorialStep();

  const item = products?.classic?.find(i => i.product_id === id);
  if (item === null) return null;
  return (
    <CartPageCardWrapper step={state[3].step}>
      <CartPageCardDataWrapper>
        <div>
          <strong>
            {item?.amount_hours
              ? item?.amount_hours <= 1
                ? `${item?.amount_hours} Hora`
                : `${item?.amount_hours} Horas`
              : '0 Horas'}
          </strong>
          <button onClick={() => handleRemoveFromCart(id)}>
            <FaTrash />
          </button>
        </div>

        <span>{item?.discount ? (item?.discount >= 0 ? `${item?.discount} % de desconto` : 0) : ''}</span>
        <PriceCartPageWrapper>
          {item?.original_price!==item?.price && <LineThrough>{item?.original_price >= 0 ? `${formatCurrency(item?.original_price)}` : `R$ 0,00`}</LineThrough>}
          <p>{item?.price >= 0 ? `${formatCurrency(item?.price)}` : `R$ 0,00`}</p>
        </PriceCartPageWrapper>
      </CartPageCardDataWrapper>

      <CartPageCardCounter step={state[3].step}>
        <button onClick={() => handleDecreaseItemQuantity(id)}>
          <div>
            <AiOutlineMinus size={7} />
          </div>
        </button>
        <span>{quantity <= 9 ? `0${quantity}` : quantity}</span>
        <button onClick={() => handleIncreaseItemQuantity(id)}>
          <div>
            <AiOutlinePlus size={9} />
          </div>
        </button>
      </CartPageCardCounter>
    </CartPageCardWrapper>
  );
};
