import styled from 'styled-components';

import { mqmax, desktopMinSize, defaultFont, mqmin, rem } from '@Styles/mixins';

export const Actions = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  margin: 0 0 0;

  ${mqmin(desktopMinSize)} {
    margin: 0 0 0;
  }
`;

const imgShowing = `
  position: absolute;
  z-index: 1;
`;

export const Img = styled.img`
  width: ${rem(65)};
  ${({ state }) => ['RUNNING', 'STOPPING'].includes(state) && imgShowing}

  ${mqmin(desktopMinSize)} {
    width: ${rem(300)};
  }
`;

const formSending = `
  opacity: 0.4;
  pointer-events: none;
`;

export const Form = styled.form`
  width: 100%;
  ${({ formAuthenticationStatus }) =>
    ({
      sending: formSending,
      error: 'color: #ff0000;',
    }[formAuthenticationStatus])}
`;

export const FormActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 445px;
  margin-bottom: ${rem(20)};

  ${mqmax(desktopMinSize - 1)} {
    display: flex;
    align-items: center;
  }

  ${mqmin(desktopMinSize)} {
    justify-content: center;
    align-items: center;
  }
`;

export const Label = styled.label`
  font-size: 0.938rem !important;
  font-weight: 300;
  margin-top: 15px;
  margin-bottom: 15px;
  display: block;

  ${mqmin(desktopMinSize)} {
    font-size: ${rem(12)};
    line-height: ${rem(16)};
  }

  ${mqmax(desktopMinSize - 1)} {
    text-align: center;
  }
`;

export const Input = styled.input`
  border: 0;
  width: 80%;
  height: 48px;
  max-width: 276px;
  border-radius: 25px;
  padding-left: 23px;
  font-size: 15px;
  font-weight: 300;

  ${mqmax(desktopMinSize - 1)} {
    width: 100%;
    max-width: 189px;
    height: 40px;
    border: 0;
    border-radius: 23px;
    padding-left: 18px;
    font-size: 12px;
  }

  &::placeholder {
    color: #787878;
  }

  ${mqmin(desktopMinSize)} {
    font-size: ${rem(14)};
    line-height: ${rem(19)};
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const Submit = styled.button`
  background-color: #2d80f2;
  max-width: 130px;
  width: 100%;
  height: 50px;
  color: #fff;
  text-transform: uppercase;
  border-radius: 25px;
  transition: background-color 150ms;
  font-size: 15px;
  margin-left: 12px;

  &:hover {
    background-color: #a00bf6;
  }

  ${mqmax(desktopMinSize - 1)} {
    width: 100%;
    max-width: 111px;
    height: 40px;
    border: 0;
    border-radius: 23px;
    background-color: #2d80f2;
    color: #fff;
    font-size: ${rem(12)};
    margin-left: 9px;
  }

  ${mqmin(desktopMinSize)} {
    font-size: ${rem(14)};
    line-height: ${rem(19)};
  }
`;

export const Stop = styled.button`
  background-color: transparent;
  width: 100%;
  max-width: 170px;
  height: 50px;
  color: #2d80f2;
  border-radius: 37px;
  border: 3px solid #2d80f2;
  transition: all 150ms;

  &:hover {
    background-color: #2d80f2;
    color: #fff;
  }

  ${mqmin(desktopMinSize)} {
    width: 48%;
    font-size: ${rem(14)};
    line-height: ${rem(19)};
  }
`;

export const P = styled.p`
  color: inherit;
  font-family: ${defaultFont};
  font-size: ${rem(11)};
  font-style: normal;
  font-weight: 450;
  letter-spacing: 0;
  line-height: ${rem(16)};
  text-align: left;
  text-decoration: none;

  ${mqmin(desktopMinSize)} {
    font-size: ${rem(12)};
    line-height: ${rem(16)};
  }

  &:not(:empty) {
    margin: ${rem(8)} 0 0;
  }
`;

export const OnActions = styled.div`
  margin-top: 35px;
  display: flex;
  gap: 1rem;
  align-items: center;

  ${mqmax(desktopMinSize - 1)} {
    justify-content: center !important;
    margin-top: ${rem(20)};
  }
`;

export const Footer = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 20px;
  ${mqmax(desktopMinSize - 1)} {
    align-items: center;
  }

  & > strong {
    font-size: ${rem(15)};
    font-weight: 300;
    text-align: center;
    line-height: ${rem(16)};

    a {
      position: relative;
      z-index: ${({ step }) => (step === 2 ? '102' : '1')};
      pointer-events: ${({ step }) => (step === 2 ? 'none' : 'unset')};
      color: #fff;
      display: inline-block;
      transition: color 150ms;
      margin-left: 4px;
      &:hover {
        color: #a00bf6;
      }
    }

    ${mqmax(desktopMinSize - 1)} {
      font-size: ${rem(13)};
      max-width: 44ch;
    }
  }
`;