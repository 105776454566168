import styled from 'styled-components';
import { desktopMinSize, mqmax } from '@Styles/mixins';

export const TutorialBackdrop = styled.div`
  position: fixed;
  inset: 0;
  background: rgba(23, 23, 26, 0.7);
  z-index: 104;
`;

export const MySessionsStepWrapper = styled.div`
  position: fixed;
  inset: 0;
  max-width: 1186px;
  width: 100%;
  z-index: 105;
  line-height: 1.2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  color: #fff;

`;

export const MySessionsStepContentWrapper = styled.div`
  max-width: 334px;
  width: 100%;
  height: 97px;
  background-color: #2d80f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px;
  border-radius: 25px;
  box-shadow: 0px 9px 16px #00000026;

  & > strong {
    font-weight: 300;
    margin: 0;
    font-size: 1rem;

    span {
      font-weight: 600;
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    max-width: 251px;
    height: 118px;
    line-height: 20px;

    & > strong {
      font-size: 0.938rem;
    }
  }
`;

export const MySessionsStepButtonWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  & > button {
    border: 0;
    background-color: transparent;

    &:nth-of-type(1) {
      max-width: 100px;
      width: 100%;
      border-radius: 21px;
      height: 29px;
      outline: 2px solid #fff;
      color: #fff;
      outline-offset: -2px;
      text-transform: uppercase;
      font-weight: 600;
      transition: all 300ms;
      font-size: 0.688rem;
      &:hover {
        background-color: #fff;
        color: #2d80f2;
      }
    }

    &:nth-of-type(2) {
      color: #fff;
      font-weight: 300;
      transition: all 300ms;
      font-size: 0.75rem;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;
      transition: border 300ms;
      width: 100%;
      max-width: fit-content;
      border-radius: 0;

      &:hover {
        border-bottom-color: #fff;
      }
    }
  }
`;
