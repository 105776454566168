import styled from 'styled-components';

export const ToViewTutorialModalContainer = styled.div`
  max-width: 533px;
  width: 100%;
  height: 196px;
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #21222a;
  border-radius: 45px;
  top: 87px;
  left: 50%;
  transform: translate(-50%);
  opacity: ${({ isToViewModalOpen }) => (isToViewModalOpen ? 1 : 0)};
  z-index: ${({ isToViewModalOpen }) => (isToViewModalOpen ? 104 : -1)};
  transition: opacity, z-index, 150ms ease-in-out;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 45px;
    border: 3px solid transparent;
    background: linear-gradient(111deg, #2d7dea 0%, #9b0cee 100%) border-box;
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  strong {
    position: relative;
    font-size: 1.313rem;
    font-weight: 300;
    display: block;
    margin-bottom: 19px;

    span {
      font-weight: 700;
    }
  }

  & > div:has(button) {
    display: flex;
    gap: 0.563rem;
    width: 100%;
    z-index: 100;
    align-items: center;
    justify-content: center;

    button {
      max-width: 192px;
      width: 100%;
      height: 50px;
      border: 3px solid #fff;
      color: #fff;
      transition: color, border, 250ms ease-in-out;
      background: transparent;
      text-transform: uppercase;
      font-size: 0.938rem;
      font-weight: 600;
      border-radius: 100vmax;

      &:hover {
        color: #2d80f2;
        border-color: #2d80f2;
      }
    }
  }
`;

export const ToViewTutorialModalOverlay = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.74);
  z-index: 103;
  opacity: ${({ isToViewModalOpen }) => (isToViewModalOpen ? 1 : 0)};
  z-index: ${({ isToViewModalOpen }) => (isToViewModalOpen ? 103 : -1)};
  transition: opacity, z-index, 150ms ease-in-out;
`;
