import styled from 'styled-components';

import { mqmin, desktopMinSize } from '@Styles/mixins';

export const Header = styled.header`
  display: none;

  ${mqmin(desktopMinSize)} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    padding: 1.75rem calc(7rem + 0.01rem);
    z-index: 1;
    background: #17171a;
  }
  .header {
    &__home-link {
      display: block;
      cursor: pointer;
    }

    &__logo {
      img {
        width: 143px;
        ${mqmin(desktopMinSize)} {
          width: 143px;
        }
      }
    }
  }
`;
