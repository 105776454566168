import { Button } from '@Components/Public';
import Icon from '../Icon';

import Svg from './Svg';

export const Email = () => (
  <OutlinedButton href="mailto:contato@noarcloud.com" startIcon={<EmailIcon />}>
    Contato
  </OutlinedButton>
);

export default Email;

const EmailIcon = Icon(Svg);

function OutlinedButton(props) {
  return <Button variant="outlined" {...props} />;
}
