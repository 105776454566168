import React, { useEffect, useState } from 'react';
import { REDIRECT_TO_LOGIN } from '@Constants';
import { ConfirmationSuccessPageContainer, ConfirmationSuccessPageWrapper } from './styled';
import ConfirmationSuccessImg from '@Assets/confirmation-success-icon.svg';
import NoarLogoImg from '@Assets/logo.svg';
import HeaderNew from '@Containers/Header-new';
import { Link } from 'react-router-dom';
import Body from '../RegisterConfirmationPage/Body';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
const ConfirmationSuccessPage = () => {
  const [next, setNext] = useState();
  const [formattedProducts, setFormattedProducts] = useState({});
  const history = useHistory();

  useEffect(() => {
    const defaultRoute = REDIRECT_TO_LOGIN;
    const { next = defaultRoute, state = {} } = history.location.state || {};
    const { formattedProducts } = state;
    setNext(next);
    setFormattedProducts(formattedProducts);
  }, []);

  return (
    <Body>
      <HeaderNew />
      <ConfirmationSuccessPageContainer>
        <ConfirmationSuccessPageWrapper>
          <img src={ConfirmationSuccessImg} alt="" />
          <Link to="/">
            <img className="logo" src={NoarLogoImg} alt="" />
          </Link>
          <strong>
            sua conta <b>foi criada</b> <span>com sucesso</span>
          </strong>
          <Link   to={{pathname: REDIRECT_TO_LOGIN, state: { next: next, state: {formattedProducts} }}}>
            <a>Ir para o login</a>
          </Link>
        </ConfirmationSuccessPageWrapper>
      </ConfirmationSuccessPageContainer>
    </Body>
  );
};

export default ConfirmationSuccessPage;
