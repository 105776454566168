import React, { useState, useEffect } from 'react';
import * as NoarAPI from '@Services/noar-api';
import Form from './Form';
import Input from './Input';
import Submit from './Submit';
import Footer from './Footer';
import ErrorMessage from './ErrorMessage';
import { useHistory } from 'react-router-dom';
import Overlay from '@Containers/Overlay';
import { REDIRECT_TO_CONFIRMATION_SUCCESS } from '@Constants';
import { IconButton, InputAdornment } from '@material-ui/core';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined'

export const Content = () => {
  const [code, handleCode] = useState('');
  const [tempPassword, handleTempPassword] = useState('');
  const [newPassword, handleNewPassword] = useState('');
  const [confirmNewPassword, handleConfirmNewPassword] = useState('');
  const [email, handleEmailFromLocation] = useState('');
  const [loginChallenge, handleChallengeFromLocation] = useState('');
  const [errorRegisterConfirmationMessage, handleErrorRegisterConfirmationMessage] = useState('');
  const [loginChallengeSession, handleChallengeSessionFromLocation] = useState('');
  const [loading, handleLoading] = useState(false);
  const [showConfirmPassword, handleShowConfirmPassword] = useState(false);
  const [showNewPassword, handleShowNewPassword] = useState(false);
  const history = useHistory();

  // ASYNC FUNCTIONS

  async function registerConfirmation() {
    try {
      handleLoading(true);
      await NoarAPI.sendRegisterConfirmationCode(email, code);
      handleLoading(false);
      handleErrorRegisterConfirmationMessage('');
      localStorage.removeItem('registerConfirmationInfo');
      redirect();
    } catch (error) {
      handleLoading(false);
      handleErrorRegisterConfirmationMessage(error.message);
      resetErrorMessage();
    }
  }

  async function sendNewPasswordChallenger(){
    try {
      handleLoading(true);
      await NoarAPI.sendNewPasswordChallenge(email, loginChallengeSession, newPassword);
      handleLoading(false);
      handleErrorRegisterConfirmationMessage('');
      localStorage.removeItem('registerConfirmationInfo');
      redirect();
    } catch (error) {
      handleLoading(false);
      handleErrorRegisterConfirmationMessage(error.message);
      resetErrorMessage();
    }
  }

  async function resendConfirmationEmail() {
    try {
      handleLoading(true);
      await NoarAPI.resendRegisterConfirmationEmail(email);
      handleLoading(false);
      handleErrorRegisterConfirmationMessage('Email enviado com sucesso!');
      resetErrorMessage();
    } catch (error) {
      handleLoading(false);
      handleErrorRegisterConfirmationMessage(error.message);
      resetErrorMessage();
    }
  }

  // EVENTS

  const redirect = () => {
    const { next=undefined, state = {} } = history.location.state || {};
    const { formattedProducts } = state;
    history.push(REDIRECT_TO_CONFIRMATION_SUCCESS, {next:next, state:{ formattedProducts}});
  }

  function onClickShowConfirmPassword() {
    handleShowConfirmPassword(!showConfirmPassword);
  }

  function onClickShowNewPassword() {
    handleShowNewPassword(!showNewPassword);
  }

  function onChangeCode(event) {
    handleCode(event.target.value);
  }

  function onChangeTempPassword(event) {
    handleTempPassword(event.target.value);
  }

  function onChangeNewPassword(event) {
    handleNewPassword(event.target.value);
  }

  function onChangeConfirmNewPassword(event) {
    handleConfirmNewPassword(event.target.value);
  }

  function onSubmitValidateAccountForm(ev) {
    ev.preventDefault();
    registerConfirmation();
  }

  function onSubmitNewPasswordChallengeForm(ev) {
    ev.preventDefault();
    sendNewPasswordChallenger();
  }

  function resetErrorMessage() {
    setTimeout(function () {
      handleErrorRegisterConfirmationMessage('');
    }, 5000);
  }

  // useEffect(s)

  useEffect(() => {
    let location = history.location;
    if(!(location?.state) || !(location?.challenge) || !(location?.challenge_session) ) {
      location = JSON.parse(localStorage.getItem('registerConfirmationInfo'));
    } 
    else {
      localStorage.setItem('registerConfirmationInfo', JSON.stringify(location));
    }
    handleEmailFromLocation(location?.state);
    handleChallengeFromLocation(location?.challenge)
    handleChallengeSessionFromLocation(location?.challenge_session)
  }, [history.location]);

  return (
    loginChallenge && loginChallenge === 'VALIDATE_ACCOUNT' ?
    <>
      {loading ? <Overlay /> : []}
      <Form onSubmit={onSubmitValidateAccountForm}>
        <Input
          type="text"
          name="code"
          placeholder="Digite o código de validação"
          required
          value={code}
          onChange={onChangeCode}
          maxLength="255"
          autoComplete="nope" 
          variant="outlined"
        />
        {errorRegisterConfirmationMessage ? <ErrorMessage>{errorRegisterConfirmationMessage}</ErrorMessage> : []}
        <Submit />
        <Footer onClickFooter={resendConfirmationEmail} />
      </Form>
    </>
    : 
    <>
      {loading ? <Overlay /> : []}
      <Form onSubmit={onSubmitNewPasswordChallengeForm}>
        <Input
          type="text"
          name="tempPassword"
          placeholder="Digite a senha temporária"
          required
          value={tempPassword}
          onChange={onChangeTempPassword}
          maxLength="255"
          autoComplete="new-password"
          variant="outlined"
        />
        <Input
          type={showNewPassword ? "text" : "password"}
          name="newPassword"
          placeholder="Digite a nova senha"
          required
          value={newPassword}
          onChange={onChangeNewPassword}
          maxLength="255"
          autoComplete="new-password"
          variant="outlined"
          InputProps={{
            endAdornment: (
                <InputAdornment position="end" style={{marginLeft: 0}} >
                    <IconButton style={{padding:0}} aria-label="showPassword" component="a" onClick={onClickShowNewPassword}>
                        {showNewPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                    </IconButton>
                </InputAdornment>
            )
        }}
        />
        <Input
          type={showConfirmPassword ? "text" : "password"}
          name="confirmNewPassword"
          placeholder="Confirme a nova senha"
          required
          value={confirmNewPassword}
          onChange={onChangeConfirmNewPassword}
          maxLength="255"
          autoComplete="new-password"
          variant="outlined"
          InputProps={{
            endAdornment: (
                <InputAdornment position="end" style={{marginLeft: 0}} >
                    <IconButton style={{padding:0}} aria-label="showPassword" component="a" onClick={onClickShowConfirmPassword}>
                        {showConfirmPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                    </IconButton>
                </InputAdornment>
            )
        }}
        />
        {errorRegisterConfirmationMessage ? <ErrorMessage>{errorRegisterConfirmationMessage}</ErrorMessage> : []}
        <Submit />
        <Footer onClickFooter={resendConfirmationEmail} />
      </Form>
    </>
  );
};

export default Content;
