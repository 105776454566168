import styled from "styled-components";
import { desktopMinSize, mq, mqmax, mqmin, overlayZIndex } from "../../../styles/mixins";


export const GeralWrapper = styled.div`
    z-index: ${overlayZIndex + 1};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
`;

export const PopupWrapper = styled.div`
    max-height: 666px;
    background-color: #ffffff;
    z-index: 99999;
    border-radius: 38px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: solid transparent calc(5 / 16 * 1rem);
    padding: 2rem;
    background: #21222A;

    ${mq('1900', '1440')}{
        gap: 0;
        width: 75%;
        height: 85%;
    }

    ${mqmin('1900')}{
        gap: 0;
        width: 60%;
        height: 85%;
    }

    ${mq('1400', '1080')}{
        gap: 0;
        width: 80%;
        height: 70%;
        overflow-y: auto;
        border-radius: 16px;
        ::-webkit-scrollbar {
            background-color: transparent;
            width: 2px;           
        }
        ::-webkit-scrollbar-thumb {
            padding-top: 20px;
            background: transparent linear-gradient(180deg, #2d80f2 0%, #a00bf6 100%) 0% 0% no-repeat padding-box;
            border-radius: 10px;
        }
    }

    ${mqmax(desktopMinSize - 1)}{
        width: 75%;
        height: 85%;
        overflow-y: auto;
        border-radius: 16px;
        ::-webkit-scrollbar {
            background-color: transparent;
            width: 2px;           
        }
        ::-webkit-scrollbar-thumb {
            padding-top: 20px;
            background: transparent linear-gradient(180deg, #2d80f2 0%, #a00bf6 100%) 0% 0% no-repeat padding-box;
            border-radius: 10px;
        }
    }

`;

export const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 0.5;
    z-index: 99998;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const TitleWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    ${mq('1400', '1080')}{
        gap: 0.5rem;
    }
`;

export const Title = styled.h1`
    font-size: 1.5rem;
    color: #ffffff;
    font-weight: 700;
    text-align: center;   
    
    ${mq('1400', '1080')}{
        font-size: 1.2rem;
    }
`;

export const Subtitle = styled.h2`
    font-size: 1rem;
    color: #ffffff;
    font-weight: 400;
    text-align: center;

    ${mq('1400', '1080')}{
        font-size: 0.8rem;
    }
`;