import React from 'react';

import Container from './Container';
import Subtitle from './Subtitle';
import Title from './Title';

export const Header = () => (
  <Container>
    <Title />
    <Subtitle />
  </Container>
);

export default Header;
