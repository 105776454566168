import styled from 'styled-components';

import { defaultFont, desktopMinSize, displayColumn, displayRow, mqmin, rem } from '@Styles/mixins';

export const SessionPresentation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 499px;
  height: auto;
`;

export const Img = styled.img`
  width: ${rem(50)};

  ${mqmin(desktopMinSize)} {
    width: ${rem(499)};
  }
`;

export const TitleColumn = styled.div`
  ${displayColumn()}
  justify-content: flex-start;
  align-items: flex-start;
  width: auto;
  height: auto;
  margin: 0 0 0 ${rem(24)};
`;

export const H1 = styled.h1`
  color: #000000;
  font-family: ${defaultFont};
  font-size: ${rem(20)};
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0;
  line-height: ${rem(20)};
  text-align: left;
  text-decoration: none;

  ${mqmin(desktopMinSize)} {
    font-size: ${rem(28)};
    line-height: ${rem(22)};
  }
`;

export const IpAddressWrapper = styled.div`
  ${displayRow()}
  justify-content: flex-start;
  align-items: center;
  margin: ${rem(5)} 0 0;

  ${mqmin(desktopMinSize)} {
    margin: ${rem(8)} 0 0;
  }
`;

export const P = styled.p`
  font-family: ${defaultFont};
  font-weight: 300;
  font-style: normal;
  font-size: ${rem(16)};
  color: #000000;
  text-decoration: none;
  text-align: left;
  letter-spacing: 0;
  line-height: ${rem(16)};

  ${mqmin(desktopMinSize)} {
    font-size: ${rem(22)};
    line-height: ${rem(22)};
  }
`;
