import styled from 'styled-components';
import { desktopMinSize, rem, mqmax } from '@Styles/mixins';
import { mqmin } from '../../../styles/mixins';

export const CarrouselSectionContainer = styled.div`
  position: relative;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 0.8rem;
  ${mqmax(desktopMinSize - 1)} {
    width: 100%;
    padding: 0;
  }

  h1 {
    font-size: ${rem(47)};
    font-weight: 600;

    ${mqmax(desktopMinSize - 1)} {
      font-size: ${rem(25)};
    }
  }
`;

export const BackToAMIs = styled.button`
  background: transparent;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  color: #fff;
  font-size: ${rem(21)};
  font-weight: 300;
  transition: color 150ms;

  ${mqmax(desktopMinSize - 1)} {
    font-size: ${rem(16)};
  }

  &:hover {
    color: #a00bf6;
  }

  strong {
    font-weight: 300;
    ${mqmax(desktopMinSize - 1)} {
      display: none;
    }
  }
  svg {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
`;

export const AMISection = styled.div`
  width: 100%;
  background-color: #21222a;
  border-radius: 49px;
  max-width: 1179px;
  display: flex;
  gap: 3rem;
  
  ${mqmin(desktopMinSize)} {
    padding: 49px;
  }

  ${mqmax(desktopMinSize - 1)} {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    gap: 1rem;
  }

  img {
    max-width: 499px;
    width: 100%;
    height: 414px;
    border-radius: 38px;
    object-fit: cover;

    ${mqmax(desktopMinSize - 1)} {
      max-width: 329px;
      height: 141px;
      border-radius: 23px;
      width: 109%;
    }
  }
`;

export const AMISectionContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
`;

export const AMISectionContent = styled.div`
  strong {
    z-index: ${({ step }) => (step === 0 ? '102' : '1')};
    position: relative;
    font-size: ${rem(21)};
    font-weight: 300;
    margin-bottom: 12px;
    display: block;

    ${mqmax(desktopMinSize - 1)} {
      font-size: ${rem(15)};
    }
  }

  & > span {
    display: block;
    font-size: ${rem(20)};
    text-transform: uppercase;
    color: #2d80f2;
    font-weight: 600;
    z-index: ${({ step }) => (step === 0 ? '102' : '1')};
    ${mqmax(desktopMinSize - 1)} {
      font-size: ${rem(15)};
    }
  }
`;

export const AMISectionContentFooter = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  row-gap: 1rem;

  & > button {
    max-width: 276px;
    width: 100%;
    height: 50px;
    outline: 3px solid #2d80f2;
    outline-offset: -3px;
    border: 0;
    background: transparent;
    border-radius: 100vmax;
    color: #2d80f2;
    font-size: ${rem(15)};
    transition: all 150ms;
    text-transform: uppercase;
    font-weight: 600;
    z-index: ${({ step }) => (step === 1 ? '102' : '1')};
    pointer-events: ${({ step }) => (step === 1 ? 'none' : 'unset')};

    ${mqmax(desktopMinSize - 1)} {
      max-width: 329px;
      font-size: ${rem(12)};
      width: 109%;
    }

    &:hover {
      color: #fff;
      background-color: #2d80f2;
    }
  }

  & > strong {
    font-size: ${rem(15)};
    font-weight: 300;
    text-align: center;

    a {
      text-decoration: underline;
      position: relative;
      z-index: ${({ step }) => (step === 2 ? '102' : '1')};
      pointer-events: ${({ step }) => (step === 2 ? 'none' : 'unset')};
      color: #fff;
      display: inline-block;
      transition: color 150ms;
      margin-left: 4px;
      cursor: pointer;
      &:hover {
        color: #a00bf6;
      }
    }

    ${mqmax(desktopMinSize - 1)} {
      font-size: ${rem(12)};
      max-width: 44ch;
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    min-height: 20vh;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;

export const AMISectionPrograms = styled.span`
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 3px !important;

  ${mqmax(desktopMinSize - 1)} {
    margin-bottom: 40px;
  }
`;

export const AMISectionWarningContent = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  span {
    font-size: ${rem(15)};
    font-weight: 300;
    line-height: ${rem(20)};
    ${mqmax(desktopMinSize - 1)} {
      font-size: ${rem(12)};
    }
  }
`;
