import styled from 'styled-components';

export const Div = styled.div`
  display: flex;
  width: auto;
  height: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: calc(25 / 16 * 1rem);
`;

export default Div;
