import React from 'react';

import Container from './Container';
import Back from './Back';
import Logout from './Logout';

export const Header = () => (
  <Container>
    <Back />
    <Logout />
  </Container>
);

export default Header;
