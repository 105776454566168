import { useEffect, useRef, useState } from 'react';
import { HowToUseAMI } from '../HowToUseAMI';
import * as S from './styled';
import { ArrowUpward, Add, PlayCircleOutlineOutlined, SaveAlt, HourglassEmptyOutlined, ErrorOutline, CloudDownload, ArrowDownward } from '@material-ui/icons';
import logoPersonal from '../../../../assets/logo_noar_personal.png';
import logoEnterprise from '../../../../assets/logo_noar_enterprise.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ENTERPRISE, ORIGIN_PERSONAL, REDIRECT_TO_LOGIN, messageDisconnect } from '../../../../constants';
import { useUserRole } from '../../../../context/UserRoleContext';
import { getExternalRecurrency, getPersonalActivePlan, getRecurrency } from '../../../../services/noar-api';
import { openConfirmationLightbox, setPersonalPlan } from '../../../../store/ducks/global/actions';
import { SessionExpired } from '../../../../errors';
import { setSelectedPlan } from '../../../../store/ducks/computer/actions';
import { desktopMinSize } from '../../../../styles/mixins';
import AboutPersonal from '../../../ShopPage/ShopPageCard/Personal/AboutPersonalWrapper';

const PersonalUseAmiSectionView = ({ ami, onClickCreateComputer, modalRef, handleOpenModal, recurrency }) => {
  const { name } = useSelector(state => state.global.session.client);
  const userName = name?.split(' ')[0] || '';
  const { personalPlan } = useSelector(state => state.global);
  const history = useHistory();
  const { clientType } = useUserRole();
  const [plan, setPlan] = useState(clientType === 'B2C' ? ORIGIN_PERSONAL : ENTERPRISE);
  const dispatch = useDispatch();
  const knowMoreModalRef = useRef();

  const planInfo = {
    B2C: {
      name: 'NOAR CLOUD',
      description:
        'Acesse seu computador na nuvem de qualquer lugar. Faça o download do nosso software de conexão clicando no botão abaixo e comece a usar todos os seus arquivos e aplicativos na nuvem em poucos minutos.',
    },
    // B2C: {
    //   name: 'Personal',
    //   description:
    //     'O Personal é o seu computador pessoal por assinatura na nuvem. Com ele você pode personalizar a sua área de trabalho como quiser, baixar os seus programas, jogos e salvar todos os arquivos importantes.',
    //   image: logoPersonal,
    // },
    B2B_COLLAB: {
      name: 'Enterprise',
      description:
        'O Enterprise é o seu computador para poder trabalhar como e de onde quiser. Com ele você pode personalizar a sua área de trabalho, baixar os seus programas e salvar todos os arquivos importantes. Maximize seu desempenho!',
      image: logoEnterprise,
    },
  };

  const handleKnowMoreOnClassicOpenModal = () => {
    knowMoreModalRef.current.showModal();
  };

  const handleKnowMoreOnClassicCloseModal = () => {
    const modal = knowMoreModalRef.current;

    modal.setAttribute('closing', '');
    modal.addEventListener(
      'animationend',
      () => {
        modal.removeAttribute('closing');
        modal.close();
      },
      { once: true },
    );
  };

  const personalActions = () => {
    return  {
      disabled: false,
      onWarning: false,
      text: 'DOWNLOAD',
      icon: <ArrowDownward />,
      action: () => {
        history.push('/my/download')
      },
    };

    if(recurrency === undefined){
      return {
        disabled: true,
        onWarning: false,
        text: 'Carregando',
        action: () => {},
      };
    }
    else if (recurrency?.recurrencyStatus === 'PENDING' && !recurrency?.initPoint) {
      return {
        disabled: true,
        onWarning: true,
        text: 'Aguardando pagamento inicial',
        icon: <HourglassEmptyOutlined />,
        action: () => {},
      };
    } else if (recurrency?.recurrencyStatus === 'PENDING' && recurrency?.initPoint) {
      return {
        disabled: false,
        onWarning: true,
        text: 'Continuar último pagamento',
        icon: <HourglassEmptyOutlined />,
        action: () => {
          window.location.href = recurrency.initPoint;
        },
      };
    } else if (recurrency?.recurrencyStatus === 'ACTIVE' && personalPlan.isActive === undefined) {
      return {
        disabled: false,
        onWarning: true,
        text: 'Verificar pagamento',
        icon: <ErrorOutline />,
        action: () => {
          window.location.href = '/my/profile';
        },
      };

    } else if (personalPlan.isActive && personalPlan.imageStatus === 'AVAILABLE') {
      return {
        disabled: false,
        text: 'Ligar',
        icon: <PlayCircleOutlineOutlined />,
        action: () => {
          onClickCreateComputer();
        },
      };
    } else if (personalPlan.isActive && personalPlan.imageStatus === 'UPDATING') {
      return {
        disabled: true,
        text: 'Salvando',
        icon: <SaveAlt />,
        action: () => {},
      };
    } else if (!personalPlan.isActive) {
      return {
        disabled: false,
        text: 'Contratar',
        icon: (
          <S.RotatedIcon>
            <ArrowUpward />
          </S.RotatedIcon>
        ),
        action: () => {
          history.push('/shop/personal');
        },
      };
    }
  };

  async function handleUpdatePersonalInfo() {
    try {
      var personalPlan = await getPersonalActivePlan();
      dispatch(setPersonalPlan(personalPlan[0]?.is_active, personalPlan[0]?.status, null, personalPlan[0]?.signature_date));
    } catch (error) {
      if (error instanceof SessionExpired) {
        sessionStorage.removeItem('@NOARCLOUD-my-home-page-plan-info-modal');
        const turnOnMachine = false;
        const disconnect = true;
        dispatch(
          openConfirmationLightbox(
            () => {
              history.push(REDIRECT_TO_LOGIN);
            },
            messageDisconnect.message,
            messageDisconnect.confirmBtnText,
            messageDisconnect.refuseBtnText,
            turnOnMachine,
            disconnect,
            false,
          ),
        );
      }
    }
  }

  useEffect(() => {
    handleUpdatePersonalInfo();
    dispatch(setSelectedPlan(plan));
  }, []);

  useEffect(() => {
    if (clientType !== 'B2B_ADMIN' && personalPlan) {
      if (ami && personalPlan.image_name) {
        ami.aws_id = personalPlan.image_name;
      }
    }
  }, [personalPlan]);

  return (
    <S.PersonalUseAmiSectionViewWrapper>
      <S.ContentWrapper>
        <S.HelloTextWrapper>
          {window.innerWidth > desktopMinSize ? (
            <>
              <S.HelloText>
                Olá,
                <br />
              </S.HelloText>
              <S.BlueStrong>{userName} :)</S.BlueStrong>
            </>
          ) : (
            <>
              <S.HelloText>
                Olá, <S.BlueStrong>{userName} :)</S.BlueStrong>
              </S.HelloText>
            </>
          )}
        </S.HelloTextWrapper>
        <S.DescriptionTextWrapper>
          <S.DescriptionText>{planInfo[clientType]?.description}</S.DescriptionText>
        </S.DescriptionTextWrapper>
        <S.ButtonsWrapper>
          <S.Button
            variant="contained"
            onClick={() => {
              personalActions().action(ami);
            }}
            disabled={personalActions().disabled}
            onWarning={personalActions().onWarning}
          >
            <S.ButtonContentWrapper>
              {personalActions().text}
              {personalActions().icon}
            </S.ButtonContentWrapper>
          </S.Button>
          {/* {clientType === 'B2C' && (
            <S.Button variant="outlined" onClick={handleKnowMoreOnClassicOpenModal}>
              <S.ButtonContentWrapper>
                <S.ButtonP>Saiba mais</S.ButtonP>
                <Add />
              </S.ButtonContentWrapper>
            </S.Button>
          )} */}
        </S.ButtonsWrapper>
        <HowToUseAMI modalRef={modalRef} />
        {/* <strong>
          Não sabe como conectar seu dispositivo na nuvem?
          <a role="button" onClick={handleOpenModal}>
            Descubra
          </a>
        </strong> */}
      </S.ContentWrapper>
      {planInfo[clientType].image? 
        <S.ImagesWrapper>
          <S.LogoPersonalImg src={planInfo[clientType].image} />
        </S.ImagesWrapper> : []
      }
      <AboutPersonal
        modalRef={knowMoreModalRef}
        handleKnowMoreOnClassicCloseModal={handleKnowMoreOnClassicCloseModal}
      />
    </S.PersonalUseAmiSectionViewWrapper>
  );
};

export default PersonalUseAmiSectionView;
