import React from 'react';
import Box from './Box';
import Container from './Container';
import Close from './Close';
import styled from 'styled-components';
import Header from './Header';
import Back from './Back';
import Copy from './Copy';
import Content from './Content';
import Submit from './Submit';
import QRCode from 'react-qr-code';
import { mqmax, desktopMinSize } from '@Styles/mixins';
import { useState } from 'react';
import { useEffect } from 'react';
import { EXPIRED } from '@Constants';

export const PaymentPix = ({ close, handleViewControl, pixInfo, handleConfirmPaymentReturn }) => {

  const [expirationTimeFullSeconds, handleExpirationTimeFullSeconds] = useState(0);
  const [stopCount, handleStopCount] = useState(false);
  const [firstCheck, handleFirstCheck] = useState(true);
  const fullMinutesAux = Math.floor(expirationTimeFullSeconds/60);
  const FullhoursAux = Math.floor(fullMinutesAux/60);
  const days = Math.floor(FullhoursAux/24);
  const hours = Math.floor(FullhoursAux-(days*24));
  const minutes = Math.floor(fullMinutesAux-(days*24*60)-(hours*60));
  const seconds = Math.floor(expirationTimeFullSeconds-(days*24*60*60)-(hours*60*60)-(minutes*60));

  // EVENTS

  function setExpirationTimeSeconds() {
    if(pixInfo) {
      const initialDate = new Date(new Date().toLocaleString("en-US", {timeZone: "America/Sao_Paulo"}));
      const finalDate = new Date(pixInfo.pix.expiration_date);
      handleExpirationTimeFullSeconds(Math.floor((finalDate.getTime() - initialDate.getTime()) / 1000));
    }
  }

  function countingTimeExpiration() {
    if(expirationTimeFullSeconds !== 0) {
      handleFirstCheck(false);
      setTimeout(() => {
        handleExpirationTimeFullSeconds(expirationTimeFullSeconds - 1);
      }, 1000);
    }else if(!stopCount && !firstCheck) {
      
      handleConfirmPaymentReturn({status: EXPIRED});
      handleStopCount(true);
    }
  }

  function copy() {
    navigator.clipboard.writeText(pixInfo ? pixInfo.pix.text : '');
  }

  useEffect(() => {
    setExpirationTimeSeconds();
  },[pixInfo]);

  useEffect(() => {
    countingTimeExpiration();
  },[expirationTimeFullSeconds]);

  return (
    <Container>
      <Box>
        <ActionsDiv>
          <Back
            goBack={() => {
              handleViewControl(1);
            }}
          ></Back>
          <Close close={close} />
        </ActionsDiv>
        <Header />
        <Content>
          <QrCodeDiv>
            <QrCodeContainer>
              <QRCode
                size={140}
                value={pixInfo ? pixInfo.pix.text : ''}
              />
            </QrCodeContainer>
            <div onClick={copy}>
              <Copy />
            </div>
          </QrCodeDiv>
          <GuideDiv>
            <li>• Abra o aplicativo do seu banco no celular;</li>
            <li>• Selecione a opção de pagamento com PIX / escanear QR Code;</li>
            <li>• Após o pagamento, você receberá um aviso de aprovação e poderá começar a usar as horas;</li>
            {/* <span>Pedido expira em: {`${days.toString().padStart(2, '0')}:${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`}</span> */}
          </GuideDiv>
        </Content>
        <Div>
          <Submit />
        </Div>
      </Box>
    </Container>
  );
};

export default PaymentPix;

const ActionsDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: auto;
  ${mqmax(desktopMinSize - 1)} {
    height: calc(30 / 16 * 1rem);
  }
`;

const QrCodeDiv = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: calc(15 / 16 * 1rem);
  ${mqmax(desktopMinSize - 1)} {
    align-items: center;
  }
`;

const QrCodeContainer = styled.div`
  width: calc(175 / 16 * 1rem);
  height: calc(175 / 16 * 1rem);
  border-radius: calc(23 / 16 * 1rem);
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  ${mqmax(desktopMinSize - 1)} {
    width: calc(175 / 16 * 1rem);
    height: calc(175 / 16 * 1rem);
    border-radius: calc(20 / 16 * 1rem);
  }
`;

export const GuideDiv = styled.ul`
  display: flex;
  color: #fff;
  flex-direction: column;
  row-gap: calc(35 / 16 * 1rem);
  ${mqmax(desktopMinSize - 1)} {
    row-gap: 0;
    margin-top: calc(25 / 16 * 1rem);
  }

  & > li {
    font-size: calc(15 / 16 * 1rem);
    font-weight: 300;
    line-height: 20px;
    ${mqmax(desktopMinSize - 1)} {
      font-size: calc(12 / 16 * 1rem);
      text-align: center;
    }

    & > span {
      font-size: calc(15 / 16 * 1rem);
      ${mqmax(desktopMinSize - 1)} {
        font-size: calc(12 / 16 * 1rem);
        text-align: center;
      }
    }
  }

  & > span {
    font-size: calc(15 / 16 * 1rem);
    font-weight: 600;
    ${mqmax(desktopMinSize - 1)} {
      font-size: calc(12 / 16 * 1rem);
      text-align: center;
      margin-top: calc(11 / 16 * 1rem);
    }
  }
`;

const Div = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  margin-top: calc(22 / 16 * 1rem);
  ${mqmax(desktopMinSize - 1)} {
    margin-top: calc(15 / 16 * 1rem);
  }
`;
