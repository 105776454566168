import styled from 'styled-components';
import { desktopMinSize, mqmax } from '../../../../../../styles/mixins';

export const Container = styled.div`
  display: flex;
  column-gap: calc(10rem / 16);

  ${mqmax(desktopMinSize - 1)} {
    justify-content: center;
    align-items: center;
  }
`;

export default Container;
