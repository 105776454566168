import styled from 'styled-components';

import { desktopMinSize, mqmin } from '@Styles/mixins';

export const Container = styled.address`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  column-gap: calc(17 / 16 * 1rem);

  ${mqmin(desktopMinSize)} {
    justify-content: start;
  }
`;

export default Container;
