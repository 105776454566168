import { Instagram, LinkedIn, Twitter, Youtube } from "../../HomePage/Contact/Header/Navigation/IconButton";
import { BlueStrong, DefaultContentDivWrapper, SectionTitle, StyledPurpleText } from "../styled";
import * as S from './styled';

export function ContactUsBusiness() {
    return (
        <DefaultContentDivWrapper>
            <SectionTitle>Fale com a <StyledPurpleText>NOAR!</StyledPurpleText></SectionTitle>
            <S.ContentWrapper>
                <S.ContentParagraph>
                    Precisa de ajuda?
                </S.ContentParagraph>
                <S.ContentParagraph>
                    Fale conosco agora mesmo pelo <a href="https://wa.me/5511964543593?text=Olá, gostaria de conhecer melhor a Noar B2B!" target="_blank" >WhatsApp</a>. Estamos prontos para ajudar sua empresa a alcançar o <strong>próximo nível</strong>.
                </S.ContentParagraph>
                <S.SocialContainerWrapper>
                    <S.ContentParagraph>
                        Acesse também nossas redes.
                    </S.ContentParagraph>
                    <S.SocialIconsContainer>
                        <Instagram />
                        <LinkedIn />
                        <Youtube />
                        <Twitter />
                    </S.SocialIconsContainer>
                </S.SocialContainerWrapper>
            </S.ContentWrapper>
        </DefaultContentDivWrapper>);
}



export default ContactUsBusiness;