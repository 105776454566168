export const stopMessage = {
  buildConfirmationLightboxText() {
    return (
      <span>
        Tem certeza que deseja <strong>desligar?</strong>
      </span>
    );
  },
  confirmBtnText: 'Sim',
  refuseBtnText: 'Não',
  formAuthenticationCodeSuccess: 'Código de autenticação enviado com sucesso',
};

export const restartMessage = {
  buildConfirmationLightboxText() {
    return (
      <span>
        Tem certeza que deseja <strong>reiniciar?</strong>
      </span>
    );
  },
  confirmBtnText: 'Sim',
  refuseBtnText: 'Não',
};

export const leaveQueueMessage = {
  buildConfirmationLightboxText() {
    return (
      <span>
        Tem certeza que deseja <strong>sair da fila?</strong>
      </span>
    );
  },
  confirmBtnText: 'Sim',
  refuseBtnText: 'Não',
};
