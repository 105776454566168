import React from 'react';
import MobileHeader from '../../components/Header';
import { DownloadPageData } from './DownloadPageData';
import { DownloadMobilePageData } from './DownloadMobilePageData';

import { DownloadPageContainer, DownloadPageWrapper } from './styled';

import { DownloadContent } from './DownloadContent';
import { DownloadMobileContent } from './DownloadMobileContent';

const DownloadPage = () => {
  return (
    <>
      <MobileHeader />
      <DownloadPageContainer>
        <DownloadPageWrapper>
          <h1>Download NOAR Connect</h1>
          <strong>Baixe o aplicativo NOAR Connect no seu pc!</strong>
          <strong>Infelizmente ainda não estamos disponiveis para celular :(</strong>
          {DownloadPageData.map(item => (
            <DownloadContent key={item.name} name={item.name} image={item?.image} link={item?.link} />
          ))}
          {DownloadMobilePageData.map(item => (
            <DownloadMobileContent key={item.name} name={item.name} image={item?.image} link={item?.link} />
          ))}
        </DownloadPageWrapper>
      </DownloadPageContainer>
    </>
  );
};

export default DownloadPage;
