import React from 'react';
import MySessions from '../../components/MySessions';

import { MySessionsAccordion } from '../../components/MySessions/MySessionsAccordion';
import MobileHeader from '../../components/Header';
import { MySessionsTutorial } from '../../components/MySessionsTutorial';

const MySessionsPage = () => {
  return (
    <>
      <MobileHeader />
      <MySessionsTutorial />
      <MySessions />
      <MySessionsAccordion />
    </>
  );
};

export default MySessionsPage;
