import styled from 'styled-components';
import { desktopMinSize, mqmax, mqmin } from '../../styles/mixins';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;

  ${mqmax(desktopMinSize -1)} {
    & > * + * {
      margin-bottom: calc(50em / 16);
    }
  }

  & > * {
    padding-block: 0;
    ${mqmin(desktopMinSize)} {
      padding-block: calc(35em / 16);
    }
  }
`;
