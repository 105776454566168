import React from 'react';
import styled from 'styled-components';

import { desktopMinSize, mqmin, mq } from '@Styles/mixins';

import Form from './Form';
import Header from './Header';

export function Faq() {
  return (
    <Container id="perguntas-frequentes">
      <Header />
      <Form />
    </Container>
  );
}

export default Faq;

const Container = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.8rem;
  max-width: min(calc(304 / 360 * 100%), calc(1331rem / 16));
  margin-inline: auto;
  padding-block: 16rem calc(112rem / 16);

  ${mq(1900, desktopMinSize)} {
    row-gap: 2.7rem;
    padding-block: 0 12.9rem;
  }

  ${mqmin(1900)} {
    row-gap: 2.7rem;
    padding-block: 10rem 12.9rem;
  }
`;
