import styled from 'styled-components';
import { desktopMinSize, mqmax, mqmin } from '../../../../../../styles/mixins';

export const ResultInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;

  & > h4 {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.2rem;
    text-align: center;
    ${mqmax(desktopMinSize - 1)} {
      font-size: 0.751rem;
    }
    ${mqmin(1900)} {
      font-size: 1.2rem;
    }
  }
  ${mqmax(desktopMinSize - 1)} {
    gap: 0.8rem;
  }
`;

export const ResultItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  line-height: 1.2rem;
  & > h3 {
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.5rem;
  }
`;

export const ResultSpecifities = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 1rem;
  width: 100%;
  height: 100%;
  ${mqmax(desktopMinSize - 1)} {
    gap: 0.5rem;
  }
`;

export const SpecifitiesItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  text-align: center;
  font-size: 0.7rem;

  & > h5 {
    font-size: 0.8rem;
    font-weight: 700;
    text-align: center;
    ${mqmin(1900)} {
      font-size: 1rem;
    }
  }

  e {
    font-size: 0.7rem;
    font-weight: 300;
    ${mqmin(1900)} {
      font-size: 1rem;
    }
  }

  span {
    ${mqmin(1900)} {
      font-size: 1rem;
    }
  }

  img {
    width: 0.8rem;
    height: 0.8rem;
  }
`;
