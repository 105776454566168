import styled from 'styled-components';
import { desktopMinSize, mq, mqmax, mqmin } from '../../../styles/mixins';

export const PlansWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: min(calc(304 / 360 * 100%), calc(1331rem / 16));
  gap: calc(32rem / 16);
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: calc(28rem / 16);
  & > p {
    text-align: center;
  }
`;

export const ChooseWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 3rem;

  ${mqmax(desktopMinSize - 1)} {
    flex-direction: column;
  }
`;

export const NichePresentation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 24%;
  min-height: 32rem;
  padding: 0rem 1.5rem;
  transition: all 0.2s ease-in-out;
  background: rgba(23, 23, 26, 0.7);
  border-radius: 1.875rem;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border: 2px solid #333;
    border-radius: 1.875rem;
    pointer-events: none;
    opacity: 0.5;
    background: #000;
    z-index: -1;
    filter: blur(1px);
  }

  &:hover {
    transform: scale(1.05);
  }

  ${mq(1400, desktopMinSize)} {
    width: 23%;
    min-height: 25rem;
  }

  ${mqmin(1900)} {
    width: 30%;
    min-height: 40rem;
  }

  ${mqmax(desktopMinSize - 1)} {
    width: 70%;
    min-height: 26rem;
    padding: 2rem;

    &:hover {
      transform: scale(1);
    }
  }
`;

export const NichePresentationTitle = styled.h3`
  font-size: 1.5rem;
  color: #2d80f2;
  font-weight: 700;
  width: 100%;
  text-align: center;

  ${mq(1400, desktopMinSize)} {
    font-size: 1rem;
  }

  ${mqmin(1900)} {
    font-size: 2rem;
  }

  ${mqmax(desktopMinSize - 1)} {
    font-size: 1.2rem;
  }
`;

export const NichePresentationSubTitle = styled.h4`
  font-size: 0.9rem;
  width: 100%;
  line-height: 1.2;
  font-weight: 400;
  text-align: start;
  min-height: 55px;
  
  ${mq(1400, desktopMinSize)} {
    font-size: 0.7rem;
    min-height: 3rem;
  }

  ${mqmin(1900)} {
    font-size: 1.3rem;
    min-height: 130px;
  }

  ${mqmax(desktopMinSize - 1)} {
    font-size: 0.8rem;
    min-height: 3rem;
  }
`;

export const NichePresentationText = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;

  ${mq(desktopMinSize + 400, desktopMinSize)} {
    font-size: 1.1rem;
  }
`;

export const NicheIconsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`;

export const NicheImage = styled.img`
  width: 20%;
  height: auto;
  object-fit: cover;
  border-radius: 1rem;
  padding: 1rem;

  ${mq(desktopMinSize + 400, desktopMinSize)} {
    width: 25%;
    padding: 0.5rem;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ChecklistWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  row-gap: 0.5rem;
  width: 100%;
  min-height: 156px;

  ${mqmin(1900)} {
    min-height: 200px;
  }
`;

export const ChecklistItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 0.5rem;

  & > p {
    font-size: 0.8rem;
    font-weight: 400;
    color: #fff;
    width: 100%;
    text-align: start;
    line-height: 1.2;

    ${mqmin(1900)} {
      font-size: 1.2rem;
    }

    ${mq(1900, 1401)} {
      font-size: 1rem;
    }
  }
`;

export const ChecklistItemImage = styled.img`
  width: 7%;
  height: auto;
`;
