import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MdOutlineClose } from 'react-icons/md';
import styled from 'styled-components';

import { mqmin, desktopMinSize } from '@Styles/mixins';

import imagem from './promo_desktop.png';
import imagemMobile from './promo_mobile.png';

export const UnitPopup = () => {
  const { closeModal, modalRef } = useUnitPopup();
  const image = useImage();

  return HAS_TO_OPEN ? (
    <Dialog ref={modalRef}>
      <Div>
        <Img alt="" {...image} />
        <Button onClick={closeModal}>
          <Icon />
        </Button>
      </Div>
    </Dialog>
  ) : null;
};

export default UnitPopup;

function useUnitPopup() {
  const [firstOpen, setFirstOpen] = useState(JSON.parse(sessionStorage.getItem(KEY) || 'true'));

  const modalRef = useRef();

  const closeModal = useCallback(
    function () {
      const modal = modalRef.current;

      if (!modal) return;

      function handleAnimationEnd() {
        modal.removeAttribute('closing');
        modal.close();
      }

      modal.setAttribute('closing', '');
      modal.addEventListener('animationend', handleAnimationEnd, { once: true });

      return function () {
        modal.removeEventListener('animationend', handleAnimationEnd);
      };
    },
    [modalRef],
  );

  useEffect(
    function () {
      const modal = modalRef.current;

      if (!(firstOpen && modal)) return;

      modal.showModal();
      setFirstOpen(false);
    },
    [firstOpen, modalRef, setFirstOpen],
  );

  useEffect(
    function () {
      sessionStorage.setItem(KEY, `${firstOpen}`);
    },
    [firstOpen, sessionStorage],
  );

  useEffect(function () {
    localStorage.removeItem('redirectShopPage');
  }, []);

  return { closeModal, modalRef };
}

const KEY = 'home_unit_popup';
const HAS_TO_OPEN = false;

function useImage() {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(
    function () {
      function handleResize() {
        setInnerWidth(window.innerWidth);
      }

      window.addEventListener('resize', handleResize, { passive: true });

      return function () {
        window.removeEventListener('resize', handleResize);
      };
    },
    [setInnerWidth],
  );

  return images[innerWidth < desktopMinSize ? 'mobile' : 'desktop'];
}

const images = {
  desktop: { src: imagem, width: 500, height: 500 },
  mobile: { src: imagemMobile, width: 336, height: 597 },
};

const Img = styled.img`
  display: block;
  width: calc(${images['mobile'].width}rem / 16);
  height: calc(${images['mobile'].height}rem / 16);

  ${mqmin(desktopMinSize)} {
    width: calc(${images['desktop'].width}rem / 16);
    height: calc(${images['desktop'].height}rem / 16);
  }
`;

const Dialog = styled.dialog`
  border: 0;
  border-radius: calc(30rem / 16);
  padding: 0;
  background: transparent;
  box-shadow: 0 0 0 100vw rgba(0, 0, 0, 0.5);

  &[open] {
    animation: slide-up 350ms forwards, fade-in 350ms forwards;
  }

  &[closing] {
    display: block;
    pointer-events: none;
    inset: 0;
    animation: slide-down 350ms forwards, fade-out 350ms forwards;
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes slide-up {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0%);
    }
  }

  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes slide-down {
    0% {
      transform: translateY(0%);
    }
    100% {
      transform: translateY(100%);
    }
  }
`;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: calc(16rem / 16);
  overflow: hidden;
  position: relative;
`;

const Button = styled.button`
  margin-top: calc(10rem / 16);
  position: absolute;
  top: 0;
  right: 2%;
  background: transparent;

  ${mqmin(desktopMinSize)} {
    margin-top: 0;
    top: 2%;
  }
`;

const Icon = styled(MdOutlineClose)`
  width: calc(24rem / 16);
  height: calc(24rem / 16);
  color: #fff;
`;
