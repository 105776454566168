export function calculateHours(used_hours, contracted_hours){
    let {hours, minutes} = used_hours;
    let totalMinUsed = (hours * 60) + minutes;
    let TotalHours = Math.floor(((contracted_hours * 60) - totalMinUsed) / 60);
    let TotalMinutes = ((contracted_hours * 60) - totalMinUsed) % 60;
    return {TotalHours, TotalMinutes}
}

export function calculateUnitHours (unit_minutes){
    let UnitTotalHours = Math.floor(unit_minutes / 60);
    const UnitTotalMinutes = Math.round((unit_minutes / 60 - UnitTotalHours) * 60);
    return {UnitTotalHours, UnitTotalMinutes}
}

export default calculateHours