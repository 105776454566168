import React from 'react';
import styled from 'styled-components';
import { desktopMinSize, mqmax } from '../../../../../../styles/mixins';

export const Image = props => <Img alt="" {...props} />;

export default Image;

const Img = styled.img`
  display: block;
  width: auto;
  height: calc(18 / 16 * 1rem);
  position: absolute;
  top: -20px;
  right: -20px;

  
  ${mqmax(desktopMinSize-1)}{
    top: 5px;
    right: 5px;
  }
`;
