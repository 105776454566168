import { Link } from 'react-router-dom/cjs/react-router-dom';
import { DefaultContentDivWrapper, PresentationButton, SectionTitle } from '../styled';
import * as S from './styled';
import { desktopMinSize } from '../../../styles/mixins';

export function PlansBusiness() {

    const openWhatsapp = () => {
        window.open('https://wa.me/5511964543593?text=Olá, gostaria de conhecer melhor a Noar B2B!','_blank');
      }

    return (
        <DefaultContentDivWrapper>
            <SectionTitle>Planos</SectionTitle>
            <S.PlansBusinessContentWrapper>
                <S.PlansBusinessContent>
                    <S.PlansBusinessItem>• Painel de gerenciamento de usuários;</S.PlansBusinessItem>
                    <S.PlansBusinessItem>• Histórico de sessões dos colaboradores;</S.PlansBusinessItem>
                    <S.PlansBusinessItem>• Todas as máquinas com GPU dedicada;</S.PlansBusinessItem>
                    <S.PlansBusinessItem>• Escolha o armazenamento que se encaixa no seu uso;</S.PlansBusinessItem>
                    <S.PlansBusinessItem>• Seus dados salvos automaticamente, sempre!</S.PlansBusinessItem>
                </S.PlansBusinessContent>
                <S.PriceWrapper>
                    <S.PriceTitle>A partir de</S.PriceTitle>
                    <p><S.PriceContent> 189.40 </S.PriceContent> $USD</p>
                </S.PriceWrapper>
                {window.innerWidth < desktopMinSize
                    ? <PresentationButton  onClick={openWhatsapp} variant='outlined'>Entre em contato</PresentationButton> 
                    : []
                }
            </S.PlansBusinessContentWrapper>
        </DefaultContentDivWrapper>
    )
}

export default PlansBusiness;