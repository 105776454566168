import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin: 0 0 calc(12 / 16 * 1rem) 0;
  width: 90%;
  padding: 0 calc(50 / 1056 * 100%);
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export default Container;
