import React, { useState } from 'react';

import {
  AccordionWrapper,
  AccordionAnswer,
  AdminAccordionAnswer,
  AccordionTitle,
  AdminAccordionTitle,
  AdminAccordionWrapper,
} from './styled';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ProtectedComponent } from '../../ProtectedComponent';

export const MySessionsAccordionContent = ({
  access,
  date,
  section,
  product,
  entryDate,
  entryTime,
  exitDate,
  exitTime,
  duration,
}) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [isAccordionArrowUp, setIsAccordionArrowUp] = useState(false);

  function handleOpenAccordion() {
    setIsAccordionOpen(!isAccordionOpen);
    setIsAccordionArrowUp(!isAccordionArrowUp);
  }

  return (
    <>
      <ProtectedComponent role={['B2B_ADM']}>
        <AdminAccordionWrapper onClick={handleOpenAccordion}>
          <AdminAccordionTitle isAccordionArrowUp={isAccordionArrowUp}>
            <strong>
              {access}{' '}
              <time>
                -{' '}
                {new Date(date).toLocaleString('pt-BR', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                })}
              </time>
            </strong>
            <ExpandMoreIcon />
          </AdminAccordionTitle>

          <AdminAccordionAnswer isAccordionOpen={isAccordionOpen}>
            <strong>
              Nome: <span> {access}</span>
            </strong>
            <strong>
              Email: <span>{product}</span>
            </strong>
            <strong>
              Entrada:{' '}
              <time>
                {new Date(entryDate).toLocaleString('pt-BR', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                })}{' '}
                {entryTime}
              </time>
            </strong>
            <strong>
              Saída:{' '}
              <time>
                {new Date(exitDate).toLocaleString('pt-BR', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                })}{' '}
                {exitTime}
              </time>
            </strong>
            <strong>
              Tempo Utilizado:{' '}
              <time>
                {duration
                  ? Object.entries(duration)
                      .map(([key, value]) => {
                        if (value < 10) {
                          return `0${value}`;
                        } else {
                          return value;
                        }
                      })
                      .join(':')
                  : '-'}
              </time>
            </strong>
          </AdminAccordionAnswer>
        </AdminAccordionWrapper>
      </ProtectedComponent>

      <ProtectedComponent role={['B2C']}>
        <AccordionWrapper onClick={handleOpenAccordion}>
          <AccordionTitle isAccordionArrowUp={isAccordionArrowUp}>
            <strong>
              Acesso {access}{' '}
              <time>
                -{' '}
                {new Date(date).toLocaleString('pt-BR', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                })}
              </time>
            </strong>
            <ExpandMoreIcon />
          </AccordionTitle>

          <AccordionAnswer isAccordionOpen={isAccordionOpen}>
            <strong>
              Sessão: <span>{section}</span>
            </strong>
            <strong>
              Produto: <span>{product}</span>
            </strong>
            <strong>
              Entrada:{' '}
              <time>
                {new Date(entryDate).toLocaleString('pt-BR', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                })}{' '}
                {entryTime}
              </time>
            </strong>
            <strong>
              Saída:{' '}
              <time>
                {new Date(exitDate).toLocaleString('pt-BR', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                })}{' '}
                {exitTime}
              </time>
            </strong>
            <strong>
              Tempo Utilizado:{' '}
              <time>
                {duration
                  ? Object.entries(duration)
                      .map(([key, value]) => {
                        if (value < 10) {
                          return `0${value}`;
                        } else {
                          return value;
                        }
                      })
                      .join(':')
                  : '-'}
              </time>
            </strong>
          </AccordionAnswer>
        </AccordionWrapper>
      </ProtectedComponent>
    </>
  );
};
