import MuiIconButton from '@material-ui/core/IconButton';
import React from 'react';
import styled from 'styled-components';

export const Container = props => <IconButton component="a" target="_blank" rel="noreferrer" {...props} />;

export default Container;

const IconButton = styled(MuiIconButton)`
  &.MuiIconButton-root {
    padding: 0;
  }
`;
