import MuiButton from '@material-ui/core/Button';
import styled from 'styled-components';
import { mqmax, desktopMinSize } from '@Styles/mixins';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export const Register = () => {

  const history = useHistory();

  const handleButtonClick = () => {
    const { next = undefined, state = {} } = history.location.state || {};
    const { formattedProducts } = state;
    history.push("/register", { next: next, state: { formattedProducts: formattedProducts } });
  }
  return (
    <Button onClick={handleButtonClick} variant="outlined">
      OU CADASTRE-SE
    </Button>
  );
};

export default Register;

const Button = styled(MuiButton)`
  &.MuiButton-root {
    max-width: 341px;
    margin: 0 auto;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border-color: #2d80f2;
    border-width: calc(3 / 16 * 1rem);
    border-radius: calc(24 / 16 * 1rem);
    color: #fff;
    font-weight: 600;
    background: transparent;
    transition: ${['border-color'].map(prop => `${prop} calc(1s / 3) ease-in-out`).join(',')};
    &:hover,
    &.active {
      border-color: #fff;
    }
    ${mqmax(desktopMinSize - 1)} {
      padding: 5px;
      font-weight: 500;
    }
  }
`;
