import { FormControl, MenuItem, Select } from "@material-ui/core";
import { Loading } from "../../../Loading";
import { AdminContentWrapper, AdminTable, AdminTableWrapper, CollaboratorActionButton, CollaboratorActionButtonWrapper, TableFooterContent, TableFooterWrapper, Td, TdStatus, Th } from "../styled";
import { Pagination } from "../Pagination";

const DesktopWrapper = ({
    loading,
    collaboratorsData,
    collaboratorsPageInfo,
    itemsPerPage,
    actions,
    handleSuspendCollab,
    handleUnsuspendCollab,
    handleDeleteCollab,
    handleInfoPopupModal,
    handleChange,
    handlePagination,
    classes
}) => {
    return (
    <AdminTableWrapper>
        <AdminContentWrapper>
          {loading ? (
            <Loading />
          ) : (
            <AdminTable>
              <thead>
                <tr>
                  <Th scope="colgroup">Nome</Th>
                  <Th scope="colgroup">Email</Th>
                  <Th scope="colgroup">Cargo</Th>
                  <Th scope="colgroup">Status</Th>
                </tr>
              </thead>

              <tbody>
                {collaboratorsData?.data?.slice(0, itemsPerPage).map((collaborator, index) => {
                  const active = collaborator.active === 'ACTIVE';
                  const action = actions(collaborator)
                  return (
                    <tr key={index + 1}>
                      <Td scope="col" title={collaborator.name}>{collaborator.name}</Td>
                      <Td scope="col" title={collaborator.email}>{collaborator.email}</Td>
                      <Td scope="col" title={collaborator.function}>{collaborator.function}</Td>
                      <TdStatus scope="col" title={active} {...{ active }}>{active ? "Ativo" : "Suspenso"}</TdStatus>
                      <Td scope="col">
                        <CollaboratorActionButtonWrapper>
                          <CollaboratorActionButton onClick={() => handleInfoPopupModal(collaborator)}  title={action.info.title}>{action.info.ico}</CollaboratorActionButton>
                          <CollaboratorActionButton onClick={active ? () => handleSuspendCollab(collaborator) : () => handleUnsuspendCollab(collaborator)}  title={action.block.title}>{action.block.ico}</CollaboratorActionButton>
                          {!active 
                            ? <CollaboratorActionButton onClick={() => handleDeleteCollab(collaborator)} title={action.delete.title}>
                                {action.delete.ico}
                              </CollaboratorActionButton> 
                            : []}
                        </CollaboratorActionButtonWrapper>
                      </Td>
                    </tr>
                  );
                })}
              </tbody>
            </AdminTable>
          )}
        </AdminContentWrapper>
        <TableFooterWrapper>
          <TableFooterContent>
            <FormControl className="formControl">
              <span>Exibindo</span>
              <Select
                value={itemsPerPage}
                onChange={handleChange}
                classes={{
                  root: classes.root,
                  icon: classes.root,
                }}
              >
                <MenuItem value={5}>5 Linhas</MenuItem>
                <MenuItem value={10}>10 Linhas</MenuItem>
                <MenuItem value={15}>15 Linhas</MenuItem>
              </Select>
            </FormControl>

            <Pagination
              pageNumber={collaboratorsPageInfo?.page_number}
              totalPages={collaboratorsPageInfo?.total_pages}
              itemsPerPage={collaboratorsPageInfo?.page_size}
              sessions={collaboratorsPageInfo?.total_elements}
              handlePagination={handlePagination}
            />
          </TableFooterContent>
        </TableFooterWrapper>
      </AdminTableWrapper>
    );
}

export default DesktopWrapper;