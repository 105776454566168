import React from 'react';

import Container from './Container';
import Navigation from './Navigation';
import TitleGroup from './TitleGroup';

export const Header = () => (
  <Container>
    <TitleGroup />
    <Navigation />
  </Container>
);

export default Header;
