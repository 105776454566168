import React, { useEffect, useState } from 'react';
import Form from './Form';
import Wrapper from './Wrapper';
import Box from './Box';
import Container from './Container';
import Header from './Header';
import Overlay from './Overlay';
import Loading from '@Containers/Overlay';
import Close from './Close';
import styled from 'styled-components';
import InputAdornment from '@material-ui/core/InputAdornment';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import IconButton from '@material-ui/core/IconButton';
import ErrorMessage from './ErrorMessage';
import SuccessMessage from './SuccessMessage';
import { Button } from './Button';
import Input from './Input';
import { ConfirmButtonWrapper } from './Button/ConfirmButtonWrapper';
import { SuccessMessageWrapper } from './SuccessMessage/SuccessMessageWrapper';
import { Checkbox } from '@material-ui/core';
import * as S from './styled';

export const PopupFormModal = ({ close, actionFunction, title, action, admPlan, goldImages }) => {
  const [name, handleName] = useState('');
  const [email, handleEmail] = useState('');
  const [password, handlePassword] = useState('');
  const [passwordConfirmation, handlePasswordConfirmation] = useState('');
  const [collabFunction, handleCollabFunction] = useState('');
  const [isHoursLimited, handleIsHoursLimited] = useState(action === 'create' ? false : actionFunction?.data?.maximum_hours_available !== 0);
  const [isNetworkLimited, handleIsNetworkLimited] = useState(action === 'create' ? false : actionFunction?.data?.maximum_upload_available !== 0);
  const [limitedHoursAmount, handleLimitedHoursAmount] = useState(action === 'create' ? 0 : actionFunction?.data?.maximum_hours_available);
  const [limitedNetWorkAmount, handleLimitedNetWorkAmount] = useState(action === 'create' ? 0 : (actionFunction?.data?.maximum_upload_available / 1024 / 1024).toFixed(2));
  const [showPassword, handleShowPassword] = useState(false);
  const [showConfirmPassword, handleShowConfirmPassword] = useState(false);
  const [errorEditUserMessage, handleErrorEditUserMessage] = useState('');
  const [successMessage, handleSuccessMessage] = useState('');
  const [loading, handleLoading] = useState(false);
  const [manualPassword, handleManualPassword] = useState(false);
  const [selectedGoldImage, setSelectedGoldImage] = useState('teste');

  const isCreationPopup = action === 'create';
  const isConfirmPopup = action === 'delete' || action === 'suspend' || action === 'activate';
  const admUploadLimitAmount = admPlan ? (admPlan.upload_limit / 1024 / 1024).toFixed(0) : 0;

  // ASYNC FUNCTIONS
  async function saveEdit() {
    try {
      const limitedHourToSend = isHoursLimited ? limitedHoursAmount : 0;
      const limitedNetWorkToSend = (isNetworkLimited ? limitedNetWorkAmount : 0) * 1024 * 1024;
      var data = {
        name: name,
        id_client: actionFunction.data.id_client,
        function: collabFunction,
        permission: 'B2B_COLLAB',
        maximum_hours_available: limitedHourToSend,
        maximum_upload_available: limitedNetWorkToSend,
      };
      handleLoading(true);
      await actionFunction.actionFunction(data);

      actionFunction.data.name = name;
      actionFunction.data.function = collabFunction;
      actionFunction.data.maximum_hours_available = limitedHourToSend;
      actionFunction.data.maximum_upload_available = limitedNetWorkToSend;

      handleLoading(false);
      handleSuccessMessage('Dados salvos com sucesso!');
      resetErrorSuccessMessage();
    } catch (error) {
      handleErrorEditUserMessage(error.message);
      handleLoading(false);
      resetErrorSuccessMessage();
    }
  }

  async function sendCretion() {
    try {
      const limitedHourToSend = isHoursLimited ? limitedHoursAmount : 0;
      const limitedNetWorkToSend = (isNetworkLimited ? limitedNetWorkAmount : 0) * 1024 * 1024;
      const finalPassword = manualPassword ? password : gerarSenhaAleatoria();

      var data = {
        email: email,
        password: finalPassword,
        name: name,
        function: collabFunction,
        permission: 'B2B_COLLAB',
        maximum_hours_available: limitedHourToSend,
        maximum_upload_available: limitedNetWorkToSend,
        id_image: selectedGoldImage,
      };
      handleLoading(true);
      await actionFunction.actionFunction(data);
      handleLoading(false);
      handleSuccessMessage('Usuário criado com sucesso!');
      resetErrorSuccessMessage();
    } catch (error) {
      handleErrorEditUserMessage(error.message);
      handleLoading(false);
      resetErrorSuccessMessage();
    }
  }

  async function onChangeImageGoldSelection(image) {
    setSelectedGoldImage(image);
  }

  async function onClickConfirmButton() {
    try {
      handleLoading(true);
      await actionFunction.actionFunction(actionFunction.data.id_client);
      handleLoading(false);

      const message =
        action === 'delete'
          ? 'Usuário deletado com sucesso!'
          : action === 'suspend'
            ? 'Usuário suspenso com sucesso!'
            : 'Usuário ativado com sucesso!';

      handleSuccessMessage(message);
      resetErrorSuccessMessage();
    } catch (error) {
      handleErrorEditUserMessage(error.message);
      handleLoading(false);
      resetErrorSuccessMessage();
    }
  }

  // EVENTS

  function gerarSenhaAleatoria() {
    var caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+{}:"<>?';
    var resultado = '';
    for (var i = 0; i < 16; i++) {
      resultado += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
    }
    return resultado;
  }

  function handleIsHoursLimitedLabelClick() {
    handleIsHoursLimited(!isHoursLimited);
  }

  function handleIsNetworkLimitedLabelClick() {
    handleIsNetworkLimited(!isNetworkLimited);
  }

  function handleManualPasswordLabelClick() {
    handleManualPassword(!manualPassword);
  }

  function onChangeIsHoursLimited(event) {
    handleIsHoursLimited(event.target.checked);
  }

  function onChangeIsNetworkLimited(event) {
    handleIsNetworkLimited(event.target.checked);
  }

  function onChangeManualPassword(event) {
    handleManualPassword(event.target.checked);
  }

  function onChangeEmail(event) {
    handleEmail(event.target.value);
  }

  function onChangeName(event) {
    handleName(event.target.value);
  }

  function onChangePassword(event) {
    handlePassword(event.target.value);
  }

  function onChangePasswordConfirmation(event) {
    handlePasswordConfirmation(event.target.value);
  }

  function onClickShowPassword() {
    handleShowPassword(!showPassword);
  }

  function onClickShowConfirmPassword() {
    handleShowConfirmPassword(!showConfirmPassword);
  }

  function onChangeCollabFunction(event) {
    handleCollabFunction(event.target.value);
  }

  function onChangeLimitedHoursAmount(event) {
    handleLimitedHoursAmount(event.target.value);
  }

  function onChangeLimitedNetWorkAmount(event) {
    handleLimitedNetWorkAmount(event.target.value);
  }

  function onSubmitForm(ev) {
    ev.preventDefault();
    if (isCreationPopup) {
      if (isCreationFormValid()) {
        sendCretion();
      }
    } else {
      if (isEditFormValid()) {
        saveEdit();
      }
    }
  }

  function isEditFormValid() {
    if (name.length === 0) {
      handleErrorEditUserMessage('O nome deve ser preenchido.');
      resetErrorSuccessMessage();
      return false;
    } else if (collabFunction.length === 0) {
      handleErrorEditUserMessage('O cargo deve ser preenchido.');
      resetErrorSuccessMessage();
      return false;
    } else if (isNetworkLimited && !limitedNetWorkAmount) {
      handleErrorEditUserMessage('O limite de upload deve ser preenchido.');
      resetErrorSuccessMessage();
      return false;
    } else if (isNetworkLimited && admUploadLimitAmount < limitedNetWorkAmount) {
      handleErrorEditUserMessage('O limite de upload deve ser menor que o limite da conta.');
      resetErrorSuccessMessage();
      return false;
    } else if (isHoursLimited && admPlan?.contracted_hours < limitedHoursAmount) {
      handleErrorEditUserMessage('O limite de horas deve ser menor que o limite da conta.');
      resetErrorSuccessMessage();
      return false;
    }

    return true;
  }

  function isCreationFormValid() {
    if (manualPassword && password !== passwordConfirmation) {
      handleErrorEditUserMessage('As senhas não são iguais.');
      resetErrorSuccessMessage();
      return false;
    } else if (manualPassword && password.length < 8) {
      handleErrorEditUserMessage('A senha deve ter no mínimo 8 caracteres');
      resetErrorSuccessMessage();
      return false;
    } else if (manualPassword && password.length > 64) {
      handleErrorEditUserMessage('A senha deve ter no máximo 64 caracteres');
      resetErrorSuccessMessage();
      return false;
    } else if (manualPassword && password.length === 0) {
      handleErrorEditUserMessage('A senha não pode ser vazia');
      resetErrorSuccessMessage();
      return false;
    } else if (manualPassword && passwordConfirmation.length === 0) {
      handleErrorEditUserMessage('A confirmação de senha não pode ser vazia');
      resetErrorSuccessMessage();
      return false;
    } else if (name.length === 0) {
      handleErrorEditUserMessage('O nome não pode ser vazio');
      resetErrorSuccessMessage();
      return false;
    } else if (email.length === 0) {
      handleErrorEditUserMessage('O email não pode ser vazio');
      resetErrorSuccessMessage();
      return false;
    } else if (email !== '' && !/^[\w+.]+@\w+\.\w{2,}(?:\.\w{2})?$/.test(email)) {
      handleErrorEditUserMessage('O email não é válido');
      resetErrorSuccessMessage();
      return false;
    } else if (collabFunction.length === 0) {
      handleErrorEditUserMessage('O Cargo deve ser preenchido!');
      resetErrorSuccessMessage();
      return false;
    } else if (isNetworkLimited && admUploadLimitAmount < limitedNetWorkAmount) {
      handleErrorEditUserMessage('O limite de upload deve ser menor que o limite da conta.');
      resetErrorSuccessMessage();
      return false;
    } else if (isHoursLimited && limitedHoursAmount > admPlan?.contracted_hours) {
      handleErrorEditUserMessage('O limite de horas deve ser menor que o limite da conta.');
      resetErrorSuccessMessage();
      return false;
    } else if (selectedGoldImage === '') {
      handleErrorEditUserMessage('A imagem Gold deve ser selecionada.');
      resetErrorSuccessMessage();
    }
    return true;
  }

  function resetErrorSuccessMessage() {
    setTimeout(function () {
      handleErrorEditUserMessage('');
    }, 5000);
  }

  useEffect(() => {
    if (actionFunction.data) {
      handleName(actionFunction.data.name);
      handleCollabFunction(actionFunction.data.function);
      setSelectedGoldImage(actionFunction.data.id_image);
    }
  }, [actionFunction]);

  return (
    <Wrapper>
      {loading ? <Loading /> : []}
      <Overlay onClick={() => { close(actionFunction.data) }} />
      <Container>
        <Box>
          <Div>
            <Close close={() => { close(actionFunction.data) }} />
          </Div>
          {successMessage ? (
            <SuccessMessageWrapper>
              <SuccessMessage>{successMessage}</SuccessMessage>
              <Button text="OK" onClick={() => { close(actionFunction.data) }}></Button>
            </SuccessMessageWrapper>
          ) : (
            <>
              <Header title={title} />
              {!isConfirmPopup ? (
                <>
                  <Form>
                    <label htmlFor="name">Nome: *</label>
                    <Input
                      type="text"
                      name="Name"
                      placeholder="Digite o nome"
                      required
                      value={name}
                      onChange={onChangeName}
                      inputProps={{ maxLength: 30, minLength: 8 }}
                      autoComplete="nope"
                      variant="outlined"
                    />
                    <label htmlFor="collabFunction">Cargo: *</label>
                    <Input
                      type="text"
                      name="Function"
                      placeholder="Digite o cargo do colaborador"
                      required
                      value={collabFunction}
                      onChange={onChangeCollabFunction}
                      inputProps={{ maxLength: 30, minLength: 8 }}
                      autoComplete="off"
                      variant="outlined"
                    />
                    {isCreationPopup ? (
                      <>
                        <label htmlFor="email">Email: *</label>
                        <Input
                          type="email"
                          name="Email"
                          placeholder="Digite o email"
                          required
                          value={email}
                          onChange={onChangeEmail}
                          inputProps={{ maxLength: 128, minLength: 8 }}
                          autoComplete="nope"
                          variant="outlined"
                        />
                        <S.PasswordWrapper>
                          <S.CheckboxWrapper >
                            <Checkbox
                              checked={manualPassword}
                              onChange={onChangeManualPassword}
                              name="ManualPassword"
                              color="primary"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                            <label htmlFor="isHoursLimited" onClick={handleManualPasswordLabelClick}>Escolher Senha *</label>
                          </S.CheckboxWrapper>
                          <p>*Caso desmarcado uma senha aleatória será gerada.</p>
                        </S.PasswordWrapper>
                        {
                          manualPassword
                            ?
                            <>
                              <label htmlFor="password">Senha: *</label>
                              <Input
                                type={showPassword ? 'text' : 'password'}
                                name="Password"
                                placeholder="Digite a senha"
                                required
                                value={password}
                                onChange={onChangePassword}
                                inputProps={{ maxLength: 64, minLength: 8 }}
                                autoComplete="new-password"
                                variant="outlined"
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end" style={{ marginLeft: 0 }}>
                                      <IconButton
                                        style={{ padding: 0 }}
                                        aria-label="showPassword"
                                        component="a"
                                        onClick={onClickShowPassword}
                                      >
                                        {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              <label htmlFor="passwordConfirmation">Confirme a senha: *</label>
                              <Input
                                type={showConfirmPassword ? 'text' : 'password'}
                                name="PasswordConfirmation"
                                placeholder="Confirme a senha"
                                onChange={onChangePasswordConfirmation}
                                inputProps={{ maxLength: 64, minLength: 8 }}
                                value={passwordConfirmation}
                                autoComplete="new-password"
                                variant="outlined"
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end" style={{ marginLeft: 0 }}>
                                      <IconButton
                                        style={{ padding: 0 }}
                                        aria-label="showPassword"
                                        component="a"
                                        onClick={onClickShowConfirmPassword}
                                      >
                                        {showConfirmPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </>
                            : []
                        }
                      </>
                    ) : (
                      []
                    )}
                    <>
                      <label htmlFor="goldImages">Escolha a imagem Gold:</label>
                      <S.Select value={selectedGoldImage} name="goldImages" id="goldImages" onChange={(event) => onChangeImageGoldSelection(event.target.value)} disabled={!isCreationPopup}>
                        <S.Option value="">{goldImages?.length === 0 ? "Sem imagens cadastradas" : "Selecione uma imagem"}</S.Option>
                        {goldImages?.map((image, index) => {
                          return <S.Option key={index} value={image.cloud_id}>{image.image_name}</S.Option>
                        }
                        )}
                      </S.Select>
                    </>

                    <S.CheckboxWrapper >
                      <Checkbox
                        checked={isHoursLimited}
                        onChange={onChangeIsHoursLimited}
                        name="IsHoursLimited"
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                      <label htmlFor="isHoursLimited" onClick={handleIsHoursLimitedLabelClick}>Limitar horas mensais</label>
                    </S.CheckboxWrapper>
                    {
                      isHoursLimited ?
                        <>
                          <label htmlFor="limitedHoursAmount">Total de horas mensais (max da conta: {admPlan?.contracted_hours}h)</label>
                          <Input
                            type="text"
                            name="Email"
                            placeholder="Digite o total de horas mensal"
                            required
                            inputProps={{ minLength: 1 }}
                            value={limitedHoursAmount}
                            onChange={onChangeLimitedHoursAmount}
                            autoComplete="nope"
                            variant="outlined"
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                        </>
                        : []
                    }
                    <S.CheckboxWrapper >
                      <Checkbox
                        checked={isNetworkLimited}
                        onChange={onChangeIsNetworkLimited}
                        name="IsNetworkLimited"
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                      <label htmlFor="isHoursLimited" onClick={handleIsNetworkLimitedLabelClick}>Limitar upload mensal </label>
                    </S.CheckboxWrapper>
                    {
                      isNetworkLimited ?
                        <>
                          <label htmlFor="limitedNetWorkAmount">Total de updaload mensal (max da conta: {admUploadLimitAmount}GB)</label>
                          <Input
                            type="text"
                            name="Email"
                            placeholder="Digite o total de upload mensal (GB)"
                            required
                            value={limitedNetWorkAmount}
                            onChange={event => {
                              if (event.target.value === '') {
                                onChangeLimitedNetWorkAmount(event);
                              }
                              else {
                                const regex = /^(\d+\.?\d{0,2}|\.\d{0,2})$/;
                                if (regex.test(event.target.value)) {
                                  onChangeLimitedNetWorkAmount(event);
                                }
                              }
                            }}
                            autoComplete="nope"
                            variant="outlined"
                            onKeyPress={(event) => {
                              if (!/[0-9,.]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                        </>
                        : []
                    }
                  </Form>
                  {errorEditUserMessage ? <ErrorMessage>{errorEditUserMessage}</ErrorMessage> : []}
                  <Button text={isCreationPopup ? 'ADICIONAR' : 'ATUALIZAR'} type="submit" onClick={onSubmitForm} />
                </>
              ) : (
                <>
                  {errorEditUserMessage ? <ErrorMessage>{errorEditUserMessage}</ErrorMessage> : []}
                  <ConfirmButtonWrapper>
                    <Button text="SIM" onClick={onClickConfirmButton}></Button>
                    <Button text="NÃO" onClick={close}></Button>
                  </ConfirmButtonWrapper>
                </>
              )}
            </>
          )}
        </Box>
      </Container>
    </Wrapper>
  );
};

export default PopupFormModal;

const Div = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  height: auto;
`;
