import styled from 'styled-components';

import { desktopMinSize, mqmin } from '@Styles/mixins';

export const Subtitle = styled.p`
  --fontSize: 14;

  font-size: calc(var(--fontSize) * 1rem / 16);
  font-weight: 300;
  line-height: calc(21 / var(--fontSize));
  text-align: center;

  ${mqmin(desktopMinSize)} {
    --fontSize: 14;

    line-height: calc(31 / var(--fontSize));
    text-align: start;
  }

  .uppercase {
    text-transform: uppercase;
  }

  &.align-center {
    text-align: center;
  }

  strong {
    font-weight: 600;
  }
`;

export default Subtitle;
