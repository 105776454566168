import styled from 'styled-components';
import { desktopMinSize, mqmax, rem } from '@Styles/mixins';

export const DownloadPageContainer = styled.div`
  width: 100%;

  ${mqmax(desktopMinSize - 1)} {
    width: 90%;
    margin: ${rem(0)} auto;
    padding: ${rem(28)} ${rem(16)};
  }
`;

export const DownloadPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: #fff;
  gap: 1rem;

  h1 {
    font-size: ${rem(47)};
    font-weight: 600;

    ${mqmax(desktopMinSize - 1)} {
      font-size: ${rem(25)};
      max-width: 16ch;
    }
  }

  & > strong {
    font-size: ${rem(21)};
    font-weight: 300;
    margin-bottom: 13px;

    &:nth-child(3) {
      font-size: ${rem(12)};
      display: none;

      ${mqmax(desktopMinSize - 1)} {
        display: block;
      }
    }

    ${mqmax(desktopMinSize - 1)} {
      display: none;
    }
  }
`;

export const DownloadPageContentWrapper = styled.div`
  max-width: 500px;
  width: 100%;
  display: flex;
  align-items: center;

  & > div {
    display: flex;
    align-items: center;
    gap: 1.625rem;

    strong {
      font-weight: 400;
      font-size: ${rem(21)};

      ${mqmax(desktopMinSize - 1)} {
        font-size: ${rem(12)};
      }
    }

    img {
      width: 84px;
      aspect-ratio: 1;
      max-width: 100%;
      border-radius: 19px;

      ${mqmax(desktopMinSize - 1)} {
        width: 62px;
      }
    }
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 191px;
    width: 100%;
    height: 50px;
    background-color: #2d80f2;
    text-decoration: none;
    color: #fff;
    margin-top: 5px;
    margin-left: auto;
    border-radius: 25px;
    font-size: ${rem(14)};
    text-transform: uppercase;
    font-weight: 600;
    transition: all 300ms;

    &:hover {
      background-color: #a00bf6;
    }

    ${mqmax(desktopMinSize - 1)} {
      max-width: 126px;
      height: 28px;
      font-size: ${rem(10)};
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    display: none;
  }
`;

export const DownloadPageMobileContentWrapper = styled.div`
  max-width: 500px;
  width: 100%;
  display: none;
  align-items: center;

  & > div {
    display: flex;
    align-items: center;
    gap: 1.625rem;

    strong {
      font-weight: 400;
      font-size: ${rem(16)};
    }

    img {
      width: 67px;
      aspect-ratio: 1;
      max-width: 100%;
      border-radius: 19px;
    }
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 121px;
    width: 100%;
    height: 34px;
    background-color: #2d80f2;
    text-decoration: none;
    color: #fff;
    margin-top: 5px;
    margin-left: auto;
    border-radius: 25px;
    font-size: ${rem(12)};
    text-transform: uppercase;
    font-weight: 600;
    transition: all 300ms;

    &:hover {
      background-color: #a00bf6;
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    display: flex;
  }
`;
