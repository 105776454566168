import { Button, TextField } from '@material-ui/core';
import * as S from './styled';
import {
  AssembleInfoRectangleWrapper,
  AssembleInfoWrapper,
  AssemblePhaseTitle,
  InfoP,
  InfoPStrong,
  InfoPriceValue,
  InfoValue,
  InfoValueWrapper,
} from '../../styled';
import { useState } from 'react';
import { KeyboardArrowDownOutlined, Timer } from '@material-ui/icons';
import { desktopMinSize } from '../../../../../../../styles/mixins';
import { Observation } from '../../../../../../../pages/HomePage/Intro/Content';
const HoursAssemblePhase = ({ setHoursPrice, hoursPrice, hoursValue, sethoursValue }) => {
  const [isCustomizeOpen, setIsCustomizeOpen] = useState(false);
  const hourUnitPrice = 6.39;
  const [defaultHoursPlans, setDefaultHoursPlans] = useState([
    {
      value: 5,
      label: '5 horas',
      isSelect: true,
    },
    {
      value: 10,
      label: '10 horas',
      isSelect: false,
    },
    {
      value: 15,
      label: '15 horas',
      isSelect: false,
    },
    {
      value: 30,
      label: '30 horas',
      isSelect: false,
    },
  ]);

  const increment = () => {
    resetDefaultHoursPlans();
    sethoursValue(prevValue => prevValue + 5);
    setHoursPrice((hoursValue + 5) * hourUnitPrice);
  };

  const decrement = () => {
    resetDefaultHoursPlans();
    if (hoursValue > 5) {
      sethoursValue(prevValue => prevValue - 5);
      setHoursPrice((hoursValue - 5) * hourUnitPrice);
    } else {
      sethoursValue(5);
      setHoursPrice(5);
    }
  };

  const handleHoursChange = event => {
    resetDefaultHoursPlans();
    const newValue = parseInt(event.target.value);
    if (!isNaN(newValue) && newValue >= 5) {
      if (newValue < 300) {
        sethoursValue(newValue);
        setHoursPrice(newValue * hourUnitPrice);
      } else {
        sethoursValue(300);
        setHoursPrice(300 * hourUnitPrice);
      }
    } else {
      sethoursValue(null);
      setHoursPrice(0);
    }
  };

  const handleHoursBlur = () => {
    resetDefaultHoursPlans();
    if (hoursValue === null) {
      sethoursValue(5);
      setHoursPrice(5 * hourUnitPrice);
    }
  };

  const handleHourPlanClick = index => {
    sethoursValue(defaultHoursPlans[index].value);
    setHoursPrice(defaultHoursPlans[index].value * hourUnitPrice);

    defaultHoursPlans.forEach((plan, i) => {
      if (i === index) {
        plan.isSelect = true;
      } else {
        plan.isSelect = false;
      }
    });

    setDefaultHoursPlans([...defaultHoursPlans]);
  };

  function resetDefaultHoursPlans() {
    defaultHoursPlans.forEach((plan, i) => {
      plan.isSelect = false;
    });

    setDefaultHoursPlans([...defaultHoursPlans]);
  }

  return (
    <AssembleInfoWrapper>
      <S.DefaultHoursPlansDescription>
        O <strong>Personal</strong> precisa de combustível!
        <br />
        Para isso, selecione um plano ou personalize seu pacote para que suas horas sejam recarregas mensalmente pra
        você!
      </S.DefaultHoursPlansDescription>
      <AssemblePhaseTitle>Horas <strong>(Essas serão suas horas totais no mês)</strong></AssemblePhaseTitle>
      <S.DefaultHoursPlansWrapper isCustomizeOpen={isCustomizeOpen}>
        {defaultHoursPlans.map((plan, index) => (
          <AssembleInfoRectangleWrapper
            onClick={() => {
              handleHourPlanClick(index);
            }}
            phase={'hours'}
            isSelect={plan.isSelect}
          >
            <Timer />
            <S.DefaultHoursPlansText></S.DefaultHoursPlansText>
            <S.DefaultHoursPlansText>{plan.label}</S.DefaultHoursPlansText>
          </AssembleInfoRectangleWrapper>
        ))}
      </S.DefaultHoursPlansWrapper>
      <S.HoursButtonWrapper isCustomizeOpen={isCustomizeOpen}>
        <Button onClick={decrement} disabled={hoursValue === 5}>
          -
        </Button>
        <TextField
          type="number"
          value={hoursValue}
          onChange={handleHoursChange}
          onBlur={handleHoursBlur}
          InputProps={{ inputProps: { step: 5 } }}
        />
        <Button onClick={increment} disabled={hoursValue === 300}>
          +
        </Button>
      </S.HoursButtonWrapper>
      <S.CustomizeButton onClick={() => setIsCustomizeOpen(!isCustomizeOpen)} isCustomizeOpen={isCustomizeOpen}>
        {window.innerWidth >= desktopMinSize ? <KeyboardArrowDownOutlined /> : []}
        {isCustomizeOpen ? 'Planos' : 'Personalizar'}
      </S.CustomizeButton>
      <InfoValueWrapper>
        <InfoValue>{hoursValue}H</InfoValue>
        <InfoPriceValue>R${hoursPrice.toFixed(2)}</InfoPriceValue>
      </InfoValueWrapper>
      <InfoP>Horas promocionais<br/><InfoPStrong>R$6.39/h</InfoPStrong></InfoP>
    </AssembleInfoWrapper>
  );
};

export default HoursAssemblePhase;
