import INITIAL_STATE from './initial-state';

function UPDATE_COMPUTER(oldState, action) {
  if (!action.computer) {
    if (!oldState.instance_id) {
      return {
        ...oldState,
      };
    }

    return {
      ...INITIAL_STATE,
    };
  }
  const computer = {...action.computer, state: action.computer.queue_phase || action.computer.state};
  return {
    ...oldState,
    ...computer,
  };
}
function UPDATE_QUEUE(oldState, action) {
  if (!action.queueInfo?.position) {
    return {
      ...INITIAL_STATE,
      isLoading: false,
    };
  }

  const { position, aws_id, creation_type, start_queue_datetime, queue_phase, waiting_confirmation_datetime_init, state } = action.queueInfo;

  return {
    ...oldState,
    queue_info: { position, aws_id, creation_type },
    start_queue_datetime,
    waiting_confirmation_datetime_init,
    state: (queue_phase || state)
  };
}

function UPDATE_STOPPING_STATE(oldState, action) {
  const state = 'STOPPING';

  return {
    ...oldState,
    state
  };
}

function UPDATE_STATE(oldState, action) {
  if(!action.stateInfo) {
    return {
      ...oldState,
      aws_id: null,
      queue_info: { position: null, aws_id: null, creation_type: null },
      state: null,
      waiting_confirmation_datetime_init: null
    };
  }
  const { queue_phase, position, creation_type, aws_id, waiting_confirmation_datetime_init, start_queue_datetime, state } = action.stateInfo;

  return {
    ...oldState,
    aws_id,
    queue_info: { position: position, aws_id, creation_type },
    state: queue_phase || state,
    waiting_confirmation_datetime_init,
    start_queue_datetime
  };
}

function SET_COMPUTER_LOADING(oldState, action) {
  return {
    ...oldState,
    isLoading: true,
  };
}

function SET_COMPUTER_DOES_NOT_LOADING(oldState, action) {
  return {
    ...oldState,
    isLoading: false,
  };
}

function SET_COMPUTER_WEBSOCKET_ENABLED(oldState, action) {
  return {
    ...oldState,
    computerWebsocketOn: true,
  };
}

function SET_COMPUTER_CREATION_ENABLED(oldState, action) {
  return {
    ...oldState,
    creatingComputer: true,
  };
}

function SET_COMPUTER_CREATION_FINISHED(oldState, action) {
  return {
    ...oldState,
    creatingComputer: false,
  };
}

function SET_SELECTED_PLAN(oldState, action) {
  const selectedPlan = action.plan;
  return {
    ...oldState,
    selectedPlan,
  };
}

function UPDATE_LAUNCH_TIME(oldState, action) {
  return {
    ...oldState,
    launch_time: action.launchTime
  };
}

const actions = {
  UPDATE_COMPUTER,
  UPDATE_QUEUE,
  UPDATE_STATE,
  SET_COMPUTER_LOADING,
  SET_COMPUTER_DOES_NOT_LOADING,
  SET_COMPUTER_WEBSOCKET_ENABLED,
  SET_COMPUTER_CREATION_ENABLED,
  SET_COMPUTER_CREATION_FINISHED,
  UPDATE_STOPPING_STATE,
  SET_SELECTED_PLAN,
  UPDATE_LAUNCH_TIME
};

export default actions;
