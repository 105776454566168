import React, { useState, useEffect, createContext, useContext } from 'react';
import { getAMIs } from '@Services/noar-api';
import { SessionExpired } from '@Errors';
import { useDispatch } from 'react-redux';
import { openConfirmationLightbox } from '@Store/ducks/global/actions';
import { REDIRECT_TO_LOGIN, messageDisconnect } from '@Constants';
import { useHistory } from 'react-router-dom';

const ComputerLoadingContext = createContext();
const ComputerLoadingContextUpdate = createContext();

export function useComputerLoadingContext() {
  return useContext(ComputerLoadingContext);
}

export function useComputerLoadingContextUpdate() {
  return useContext(ComputerLoadingContextUpdate);
}

export function ComputerLoadingContextProvider({
  selectedPlan,
  children,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [AMIs, setAMIs] = useState([]);
  const [activeIndexAMI, setActiveIndexAMI] = useState(null);
  const [isLoadingAMI, setIsLoadingAMI] = useState(false);

  async function handleGetAMIs() {
    try {
      setIsLoadingAMI(true);
      const amis = await getAMIs(selectedPlan);
      setAMIs(amis);
      setIsLoadingAMI(false);
    } catch (error) {
      setIsLoadingAMI(false);
      if (error instanceof SessionExpired) {
        sessionStorage.removeItem('@NOARCLOUD-my-home-page-plan-info-modal');
        const turnOnMachine = false;
        const disconnect = true;
        dispatch(
          openConfirmationLightbox(
            () => {
              history.push(REDIRECT_TO_LOGIN);
            },
            messageDisconnect.message,
            messageDisconnect.confirmBtnText,
            messageDisconnect.refuseBtnText,
            turnOnMachine,
            disconnect,
            false,
          ),
        );
      }
    }
  }

  useEffect(() => {
    if (selectedPlan) {
      handleGetAMIs();
    }
  }, [selectedPlan]);

  return (
    <ComputerLoadingContext.Provider
      value={{
        AMIs,
        setActiveIndexAMI,
        activeIndexAMI,
        isLoadingAMI,
        setAMIs,
      }}
    >
      <ComputerLoadingContextUpdate.Provider value={{ handleGetAMIs }}>
        {children}
      </ComputerLoadingContextUpdate.Provider>
    </ComputerLoadingContext.Provider>
  );
}
