import { Link } from '@material-ui/core';
import { TERMS_PRIVACY_URL, TERMS_USE_URL } from '../../../../../../constants';
import { Observation } from '../../../../../../pages/HomePage/Intro/Content';
import * as S from './styled';
import { COMPUTER_INFOS as infos } from '@Constants';

const SubscriptionSummary = ({ storagePrice, hoursPrice, storageValue, hoursValue }) => {
    return (
        <S.SubscriptionSummaryWrapper>
            <S.SubscriptionSummaryTitle>Resumo da assinatura</S.SubscriptionSummaryTitle>
            <S.SubscriptionSummaryContent>
                {
                    infos.map((info, index) => {
                        return (
                            <S.SubscriptionSummaryContentItem key={index}>
                                <S.SubscriptionSummaryContentItemTitle>{info.title}</S.SubscriptionSummaryContentItemTitle>
                                <S.SubscriptionSummaryContentItemValue>{info.description}</S.SubscriptionSummaryContentItemValue>
                                <S.SubscriptionSummaryContentItemPrice><S.SmallText>R$ {info.price}</S.SmallText></S.SubscriptionSummaryContentItemPrice>
                            </S.SubscriptionSummaryContentItem>
                        )
                    })
                }
                <S.SubscriptionSummaryContentItem>
                    <S.SubscriptionSummaryContentItemTitle>Armazenamento</S.SubscriptionSummaryContentItemTitle>
                    <S.SubscriptionSummaryContentItemValue>{storageValue}GB</S.SubscriptionSummaryContentItemValue>
                    <S.SubscriptionSummaryContentItemPrice>R$ {storagePrice.toFixed(2)}</S.SubscriptionSummaryContentItemPrice>
                </S.SubscriptionSummaryContentItem>
                <S.SubscriptionSummaryContentItem>
                    <S.SubscriptionSummaryContentItemTitle>Horas</S.SubscriptionSummaryContentItemTitle>
                    <S.SubscriptionSummaryContentItemValue>{hoursValue}h</S.SubscriptionSummaryContentItemValue>
                    <S.SubscriptionSummaryContentItemPrice>R$ {hoursPrice.toFixed(2)}</S.SubscriptionSummaryContentItemPrice>
                </S.SubscriptionSummaryContentItem>
            </S.SubscriptionSummaryContent>
            <Observation>*Ao clicar no botão "Assinar” abaixo, você concorda com nossos <Link className='terms-form-box__terms__link' to={TERMS_USE_URL} target="_blank" download >Termos de Serviço</Link> e
                com nossa <Link className='terms-form-box__terms__link' to={TERMS_PRIVACY_URL} target="_blank" download>Políticas de Privacidade</Link>, confirma ter mais de 18 anos e aceita que a Noar
                renove automaticamente sua assinatura e cobre o preço da assinatura
                (atualmente R${(storagePrice + hoursPrice).toFixed(2)}/mês) da sua forma de pagamento até você cancelar.
                Você pode cancelar quando quiser para evitar cobranças futuras.
                Para cancelar, acesse a página “Conta” e clique em “Cancelar Assinatura".</Observation>
        </S.SubscriptionSummaryWrapper>
    );
}

export default SubscriptionSummary;