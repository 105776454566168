import styled from 'styled-components';

import { desktopMinSize, mqmax, rem} from '@Styles/mixins';

export const Hello = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
  margin: ${rem(15)} 0 0 0;
`;

export const P = styled.p`
  width: 100%;
  font-weight: 700;
  font-style: normal;
  font-size: ${rem(35)};
  color: #fff;
  line-height: ${rem(40)};
  text-align: center;

  strong {
    font-size: ${rem(20)};
    font-weight: 300;
    display: block;

    ${mqmax(desktopMinSize - 1)} {
      font-size: ${rem(12)};
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    font-size: ${rem(25)};
    line-height: ${rem(30)};
  }
`;

export const Span = styled.span`
  position: relative;
`;
