import React from 'react';

import Container from '../Container';
import Image from '../Image';

import image from './image.svg';

export const Youtube = () => (
  <Container href="https://www.youtube.com/channel/UCErUnW92FpYXNZQEYikRtVg">
    <Image alt="Youtube" src={image} width={24} height={17} />
  </Container>
);

export default Youtube;
