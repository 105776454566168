import styled from 'styled-components';

import { desktopMinSize, defaultFont, mqmax } from '@Styles/mixins';

export const Container = styled.div`
    font-family: ${defaultFont};
    width: 1179px;
    display: flex;
    align-items: center;
    justify-content: center;
    & > p {
        color: #FFF;
        font-size: calc(15 / 16 * 1rem);
        & > span {
            color: #426FE5;
            font-weight: 600;
        }
    }
    ${mqmax(desktopMinSize - 1)} {
        width: 329px;
    }
`;
