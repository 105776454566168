import MuiButton from '@material-ui/core/Button';
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { mqmax, desktopMinSize } from '@Styles/mixins';
import { REDIRECT_AFTER_LOGIN } from '@Constants';

export const Submit = ({props}) => (
  <Button component={Link} to={REDIRECT_AFTER_LOGIN} variant="outlined" {...props}>
    JÁ PAGUEI
  </Button>
);

export default Submit;

const Button = styled(MuiButton)`
  &.MuiButton-root {
    box-sizing: border-box;
    width: calc(170 / 16 * 1rem);
    border-color: #2d80f2;
    border-width: calc(3 / 16 * 1rem);
    border-radius: calc(32 / 16 * 1rem);
    padding: 10px;
    color: #2d80f2;
    font-weight: 600;
    background: transparent;
    transition: ${['color', 'background'].map(prop => `${prop} calc(1s / 3) ease-in-out`).join(',')};

    &:hover,
    &.active {
      color: #fff;
      background: #2d80f2;
    }

    ${mqmax(desktopMinSize - 1)} {
      padding: 5px;
      font-size: calc(12 / 16 * 1rem);
      width: 100%;
      background: #2d80f2;
      color: #fff;
    }
  }
`;
