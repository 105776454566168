import MuiButton from '@material-ui/core/Button';
import styled from 'styled-components';
import React from 'react';

export const Submit = () => (
  <Button type='submit' variant="outlined">
    ALTERAR SENHA
  </Button>
);

export default Submit;

const Button = styled(MuiButton)`
  &.MuiButton-root {

    padding: 10px;
    box-sizing: border-box;
    width: 100%;
    border-color: #426FE5;
    border-width: calc(3 / 16 * 1rem);
    border-radius: calc(24 / 16 * 1rem);
    color: #fff;
    font-weight: 500;
    background: #426FE5;
  }
`;
