import React, { useState } from 'react';
import {
  CarrouselContainer,
} from '../../styled';
import { AMISectionPage } from '@Components/AMICarrousel/AMISection';
import { useComputerLoadingContext } from '../../Computer/ComputerLoadingContext';
import { Loading } from '@Components/Loading';
import { InitialSteps } from '@Components/AMITutorial/InitialSteps';
import { useTutorialStep } from '../../../../context/TutorialStepContext';
import { desktopMinSize } from '@Styles/mixins';
import { useUserRole } from '../../../../context/UserRoleContext';
import HelloDesktopWrapper from './HelloDesktop';
import HelloMobileWrapper from './HelloMobile';
import FilterInputWrapper from './FilterInput';
import AmiSliderDesktopWrapper from './AmiSliderDesktopWrapper';
import AmiSliderMobileWrapper from './AmiSliderMobileWrapper';

const Carousel = () => {
  const [isOnAMISectionPage, setIsOnAMISectionPage] = useState(false);
  const { setActiveIndexAMI, activeIndexAMI, isLoadingAMI, AMIs } = useComputerLoadingContext();
  const [checked, setChecked] = useState([]);
  const { state } = useTutorialStep();
  
  const [searchedNameValue, setSearchedNameValue] = useState('');

  const { clientType } = useUserRole();

  function handleAMISectionPage() {
    setIsOnAMISectionPage(!isOnAMISectionPage);
  }

  function handleWatchCheckedFilterCheckbox(e) {
    setChecked(currItems => {
      if (currItems.find(item => item === e.target.id) == null) {
        return [...currItems, e.target.id];
      } else {
        return currItems.filter(item => item !== e.target.id);
      }
    });
  }

  function handleGoBack() {
    localStorage.removeItem('selected_plan');
    window.location.reload();
  }

  return (
    <CarrouselContainer>
      <InitialSteps />

      {isOnAMISectionPage 
      ? (<AMISectionPage sectionType='OCCASIONAL' handleAMISectionPage={handleAMISectionPage} ami={AMIs.map(item => item.image_list.find(ami => ami.id === activeIndexAMI)).find(ami => ami !== undefined)}/>) 
      : (
        <>
          {
            (window.innerWidth < desktopMinSize-1 )
              ? (<HelloMobileWrapper handleGoBack={handleGoBack}/>) 
              : (<HelloDesktopWrapper handleGoBack={handleGoBack} clientType={clientType}/>)
          }
          {
            (clientType !== "B2B_COLLAB")
              ? <FilterInputWrapper 
                searchedNameValue={searchedNameValue} 
                setSearchedNameValue={setSearchedNameValue}
                handleWatchCheckedFilterCheckbox={handleWatchCheckedFilterCheckbox}
                checked= {checked}/> 
              : []
          }
          {
            (isLoadingAMI)
              ? (<Loading whichLoading={'generic'} />) 
              : (<AmiSliderDesktopWrapper AMIs= {AMIs} checked= {checked} searchedNameValue= {searchedNameValue} state= {state} handleAMISectionPage= {handleAMISectionPage} setActiveIndexAMI= {setActiveIndexAMI} />)
          }
          <AmiSliderMobileWrapper AMIs={AMIs} checked={checked} searchedNameValue={searchedNameValue} state={state} handleAMISectionPage={handleAMISectionPage} setActiveIndexAMI={setActiveIndexAMI} />
        </>
      )}
    </CarrouselContainer>
  );
};

export default Carousel;