import styled from 'styled-components';
import { desktopMinSize, mqmax } from '@Styles/mixins';

export const TimeExpiredWrapper = styled.dialog`
  position: fixed;
  inset: 0;
  background: #21222a;
  border-radius: 45px;
  border: 0;
  z-index: 998;
  max-width: 475px;
  width: 100%;
  height: 230px;
  box-shadow: 0 0 0 100vw rgba(0, 0, 0, 0.6);

  &[open] {
    animation: slide-up 500ms forwards, fade-in 350ms forwards;
  }

  &[closing] {
    display: block;
    pointer-events: none;
    inset: 0;
    animation: slide-down 500ms forwards, fade-out 350ms forwards;
  }

  &:before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 45px;
    padding: 3px;
    background: linear-gradient(115deg, #2d7dea 0%, #9b0cee 100%);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 220px;
    gap: 1rem;
    text-align: center;

    h2 {
      font-size: 1.313rem;
      font-weight: 700;
      color: #fff;
    }

    strong {
      font-size: 1.125rem;
      font-weight: 300;
      color: #fff;
      max-width: 40ch;
      line-height: 25px;

      span {
        font-weight: 700;
      }
    }

    & > div:has(button) {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      width: 100%;
      z-index: 999;

      & > button {
        background: transparent;
        text-transform: uppercase;
        color: #fff;
        border: 3px solid #fff;
        border-radius: 37px;
        width: 100%;
        max-width: 192px;
        height: 50px;
        transition: border-color 350ms, color 350ms;

        &:hover {
          border-color: #2d80f2;
          color: #2d80f2;
        }
      }
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    border-radius: 30px;
    max-width: 304px;
    height: 194px;

    &:before {
      border-radius: 30px;
    }

    & > div {
      height: 192px;
      h2 {
        font-size: 1.313rem;
      }

      strong {
        font-size: 0.938rem;
        line-height: 19px;
        max-width: 25ch;
      }

      & > div:has(button) {
        button {
          max-width: 127px;
          height: 38px;
          border-width: 1.5px;
          font-size: 13px;
        }
      }
    }
  }

  @keyframes slide-up {
    0% {
      transform: translateY(1000px);
    }
    100% {
      transform: translateY(0%);
    }
  }

  @keyframes slide-down {
    0% {
      transform: translateY(0%);
    }
    100% {
      transform: translateY(1000px);
    }
  }
`;
