import React, { useState, useEffect } from 'react';
import { useTutorialStep } from '../../../context/TutorialStepContext';
import { ThirdStep } from './ThirdStep';
import { FourthStep } from './FourthStep';
import { FifthStep } from './FifthStep';

export const SectionPageSteps = () => {
  const { handleNextStep, handleSkipTutorial, state } = useTutorialStep();
  const [isFirstAccess, setIsFirstAccess] = useState(false);

  useEffect(() => {
    var firstAccess = JSON.parse(localStorage.getItem('firstAccess'));
    if (firstAccess && firstAccess[0]?.sectionPageSteps) {
      setIsFirstAccess(false);
    } else {
      setIsFirstAccess(true);
      localStorage.setItem(
        'firstAccess',
        JSON.stringify([
          {
            initialAMISteps: true,
            sectionPageSteps: true,
          },
        ]),
      );
    }
  }, []);

  if (!isFirstAccess) return null;

  switch (state[1].step) {
    case 0:
      return (
        <ThirdStep
          nextStep={() => handleNextStep('section-page')}
          skipTutorial={() => handleSkipTutorial('section-page')}
        />
      );
    case 1:
      return (
        <FourthStep
          nextStep={() => handleNextStep('section-page')}
          skipTutorial={() => handleSkipTutorial('section-page')}
        />
      );
    case 2:
      return (
        <FifthStep
          nextStep={() => handleNextStep('section-page')}
          skipTutorial={() => handleSkipTutorial('section-page')}
        />
      );

    default:
      return null;
  }
};
