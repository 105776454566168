import React, { useState, useEffect } from 'react';
import * as NoarAPI from '@Services/noar-api';
import Form from './Form';
import Input from './Input';
import Submit from './Submit';
import Footer from './Footer';
import Mobile from '../../Mobile';
import ErrorMessage from './ErrorMessage';
import Overlay from '@Containers/Overlay';
import { useLocation } from 'react-router-dom';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import InputPasswordImg from '../../../../assets/password-input-icon.svg';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import PasswordStrengthChecker from './PasswordCheckbox';

export const Content = ({ onSuccess }) => {
  const [password, handlePassword] = useState('');
  const [showPassword, handleShowPassword] = useState(false);
  const [showConfirmationPassword, handleShowConfirmationPassword] = useState(false);
  const [passwordConfirmation, handlePasswordConfirmation] = useState('');
  const [email, handleEmailFromLocation] = useState('');
  const [code, handleCode] = useState('');
  const [errorCreatePasswordMessage, handleErrorCreatePasswordMessage] = useState('');
  const [loading, handleLoading] = useState(false);
  const location = useLocation();

  // ASYNC FUNCTIONS

  async function createPassword() {
    try {
      handleLoading(true);
      await await NoarAPI.sendPasswordForgot(email, code, password);
      handleLoading(false);
      onSuccess();
    } catch (e) {
      handleLoading(false);
      handleErrorCreatePasswordMessage(e.message);
      resetErrorMessage();
    }
  }
  // EVENTS

  function onChangeCode(event) {
    handleCode(event.target.value);
  }

  function onChangePassword(event) {
    handlePassword(event.target.value);
  }

  function onChangePasswordConfirmation(event) {
    handlePasswordConfirmation(event.target.value);
  }
  function onClickShowPassword() {
    handleShowPassword(!showPassword);
  }
  function onClickShowConfirmationPassword() {
    handleShowConfirmationPassword(curState => !curState);
  }

  function onSubmitCreatePasswordForm(ev) {
    ev.preventDefault();

    if (password !== passwordConfirmation) {
        handleErrorCreatePasswordMessage('As senhas devem ser iguais!');
        resetErrorMessage();
    } else if (!containsLowerCase(password) || !containsUpperCase(password) || !containsNumber(password) || !containsSpecialChar(password)) {
        handleErrorCreatePasswordMessage('A senha não atende os padrões de segurança');
        resetErrorMessage();
    } else {
        createPassword();
    }
}

function containsLowerCase(str) {
    return /[a-z]/.test(str);
}

function containsUpperCase(str) {
  return /[A-Z]/.test(str);
}

function containsNumber(str) {
    return /\d/.test(str);
}

function containsSpecialChar(str){
  return /[!@#$%^&*(),.?":{}|<>]/.test(str);
}

  // FUNCTIONS

  function resetErrorMessage() {
    setTimeout(function () {
      handleErrorCreatePasswordMessage('');
    }, 5000);
  }

  // useEffect(s)

  useEffect(() => {
    handleEmailFromLocation(location?.state);
  }, []);

  return (
    <>
      {loading ? <Overlay /> : []}
      <Form onSubmit={onSubmitCreatePasswordForm}>
        <Input
          type="text"
          name="code"
          placeholder="Digite o código de verificação"
          required
          value={code}
          onChange={onChangeCode}
          autoComplete="off"
          variant="outlined"
        />
        <Input
          type={showPassword ? 'text' : 'password'}
          name="password"
          placeholder="Digite sua nova senha"
          required
          value={password}
          onChange={onChangePassword}
          minLength="14"
          maxLength="64"
          autoComplete="new-password" 
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="showPassword" component="a" onClick={onClickShowPassword}>
                  {showPassword ? (
                    <VisibilityOutlinedIcon />
                  ) : (
                    <img src={InputPasswordImg} alt="" style={{ width: '24px', margin: 0 }} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Input
          type={showConfirmationPassword ? 'text' : 'password'}
          name="passwordConfirmation"
          placeholder="Confirme sua nova senha"
          required
          value={passwordConfirmation}
          onChange={onChangePasswordConfirmation}
          minLength="14"
          maxLength="64"
          autoComplete="new-password" 
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="showPassword" component="a" onClick={onClickShowConfirmationPassword}>
                  {showConfirmationPassword ? (
                    <VisibilityOutlinedIcon />
                  ) : (
                    <img src={InputPasswordImg} alt="" style={{ width: '24px', margin: 0 }} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <PasswordStrengthChecker password={password} direction="column" />
        {errorCreatePasswordMessage ? <ErrorMessage>{errorCreatePasswordMessage}</ErrorMessage> : []}
        <Submit />
        <Mobile>
          <Footer />
        </Mobile>
      </Form>
    </>
  );
};

export default Content;
