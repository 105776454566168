import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { desktopMinSize, mqmin } from '@Styles/mixins';

export const TextField = forwardRef(function ({ EndAdornment: ExternalEndAdornment, label, ...props }, outerRef) {
  const { highlight, ref } = useTextField(outerRef);

  return (
    <Container $highlight={highlight}>
      <Input {...{ ref }} {...props} />
      <Label>{label}</Label>
      <EndAdornment as={ExternalEndAdornment} />
    </Container>
  );
});

export default TextField;

function useTextField(outerRef) {
  const innerRef = useRef(null);
  const ref = outerRef ? mergeRefs([innerRef, outerRef]) : innerRef;
  const [highlight, setHighlight] = useState(false);

  const handleFocus = useCallback(
    function () {
      setHighlight(true);
    },
    [setHighlight],
  );

  const handleBlur = useCallback(
    function ({ target }) {
      setHighlight(!!target.value);
    },
    [setHighlight],
  );

  useEffect(
    function () {
      const node = innerRef.current;

      if (!node) return;

      ['change', 'focus'].forEach(function (type) {
        node.addEventListener(type, handleFocus);
      });

      node.addEventListener('blur', handleBlur);

      return function () {
        ['change', 'focus'].forEach(function (type) {
          node.removeEventListener(type, handleFocus);
        });

        node.removeEventListener('blur', handleBlur);
      };
    },
    [handleBlur, handleFocus, innerRef],
  );

  return { highlight, ref };
}

function mergeRefs(refs) {
  return function (node) {
    refs.forEach(function (ref) {
      ref.current = node;
    });
  };
}

const Input = styled.input`
  box-sizing: border-box;
  width: 100%;
  border: solid var(--color) calc(var(--borderWidth) * 1rem / 16);
  border-radius: calc((var(--borderWidth) * 2 + var(--paddingBlock) * 2 + var(--lineHeight)) / 2 * 1rem / 16);
  padding: calc(var(--paddingBlock) * 1rem / 16) calc(var(--paddingInline) * 1rem / 16);
  outline: none;
  color: inherit;
  font-size: calc(var(--fontSize) * 1rem / 16);
  line-height: calc(var(--lineHeight) / var(--fontSize));
  background: none;
  backdrop-filter: blur(calc(7px));
  transition: border-color calc(1s / 3) ease-in-out;
`;

const Label = styled.span`
  display: block;
  padding-inline: calc(var(--paddingInline) * 1rem / 16);
  font-size: calc(var(--fontSize) * 1rem / 16);
  line-height: calc(var(--lineHeight) / var(--fontSize));
`;

const EndAdornment = styled.div`
  path {
    fill: var(--color);
    transition: fill calc(1s / 3) ease-in-out;
  }
`;

const Container = styled.label`
  --borderWidth: 2;
  --paddingBlock: 16;
  --paddingInline: 23;
  --color: rgba(255, 255, 255, 100%);
  --fontSize: 12;
  --lineHeight: 19;

  position: relative;
  color: var(--color);

  ${({ $highlight }) =>
    $highlight &&
    css`
      --color: #fff;

      ${Label} {
        scale: calc(3 / 4);
        translate: calc(var(--paddingInline) * 1rem / 16)
          calc((var(--borderWidth) + var(--paddingBlock)) * -1rem / 16 - 100%);
      }
    `}

  ${mqmin(desktopMinSize)} {
    --paddingBlock: 26;
    --paddingInline: 38;
    --fontSize: 20;
    --lineHeight: 31;
  }

  ${Label} {
    position: absolute;
    top: calc((var(--borderWidth) + var(--paddingBlock)) * 1rem / 16);
    transition: ${['color', 'scale', 'translate'].map(prop => `${prop} calc(1s / 3) ease-in-out`).join(',')};
    transform-origin: center left;
  }

  ${EndAdornment} {
    position: absolute;
    top: calc((var(--borderWidth) + var(--paddingBlock)) * 1rem / 16);
    right: calc(21rem / 16);
    width: calc(var(--lineHeight) * 1rem / 16);
    height: auto;
  }

  &:has(${EndAdornment}) ${Input} {
    padding-right: calc((var(--paddingBlock) + var(--lineHeight)) * 1rem / 16);
  }
`;
