export function loadSession() {
  return {
    type: 'LOAD_SESSION',
  };
}

export function initLogout() {
  return {
    type: 'INIT_LOGOUT',
  };
}

export function updateClientHours(clientHours) {
  return {
    type: 'UPDATE_CLIENT_HOURS',
    clientHours,
  };
}

export function updateUnitUsedHours(used_minutes) {
  return {
    type: 'UPDATE_UNIT_USED_HOURS',
    used_minutes,
  };
}

export function setClientHoursWebsocketOn() {
  return {
    type: 'SET_CLIENT_HOURS_WEBSOCKET_ON',
  };
}

export function openConfirmationLightbox(callbackFunction, text, confirmBtnText, refuseBtnText, 
  turnOnMachine, disconnect, isUnit, clients_in_queue, average_waiting_time_minutes) {
  return {
    type: 'OPEN_CONFIRMATION_LIGHTBOX',
    confirmationLightbox: {
      callbackFunction,
      text,
      confirmBtnText,
      refuseBtnText,
      turnOnMachine,
      disconnect,
      isUnit,
      clients_in_queue, 
      average_waiting_time_minutes
    },
  };
}

export function closeConfirmationLightbox() {
  return {
    type: 'CLOSE_CONFIRMATION_LIGHTBOX',
  };
}

export function openNpsFormBox(callbackFunction, closeCallBackFunction, product, origin) {
  return {
    type: 'OPEN_NPS_FORM_BOX',
    npsFormBox: {
      callbackFunction,
      closeCallBackFunction,
      product,
      origin,
    },
  };
}

export function closeNpsFormBox() {
  return {
    type: 'CLOSE_NPS_FORM_BOX',
    npsFormBox: {
    },
  };
}

export function openTermsBox() {
  return {
    type: 'OPEN_TERMS_BOX',
    termsBox: {
    },
  };
}

export function closeTermsBox() {
  return {
    type: 'CLOSE_TERMS_BOX',
    termsBox: {
    },
  };
}

export function setPersonalPlan(isActive, status, image_name, signature_date) {
  return {
    type: 'SET_PERSONAL_PLAN',
    personalPlan: {
      isActive: isActive,
      imageStatus: status,
      image_name: image_name,
      signature_date: signature_date,
    }
  };
}

export function setAccountPersonalInfo(accountPersonalInfo, showPopup, showPopupValidationFunction, paymentInfo) {
  return {
    type: 'SET_ACCOUNT_PERSONAL_INFO',
    accountPersonalInfo: {
      accountPersonalInfo: accountPersonalInfo,
      showPopup: showPopup,
      showPopupValidationFunction: showPopupValidationFunction,
      paymentInfo: paymentInfo,
    }
  };
}
