import React from 'react';
import SectionTitle from '../PaymentTitle';

export const Title = () => (
  <>
    <SectionTitle>Endereço de cobrança</SectionTitle>
  </>
);

export default Title;
