import { createTheme, ThemeProvider } from '@material-ui/core';
import styled from 'styled-components';
import { desktopMinSize, mqmax } from '../../../styles/mixins';

export const Body = props => (
  <ThemeProvider theme={theme}>
    <Div {...props} />
  </ThemeProvider>
);

export default Body;

const theme = createTheme({
  typography: {
    fontFamily: '"Poppins", sans-serif',
  },
});

const Div = styled.div`
  background: #17171a;
  height: fit-content;
  min-height: 100vh;

  ${mqmax(desktopMinSize -1)} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
