import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { calculateHours, calculateUnitHours } from '@Utils/calculate-hours';
import { updateClientHours, updateUnitUsedHours } from '@Store/ducks/global/actions';
import { openConfirmationLightbox } from '@Store/ducks/global/actions';
import { REDIRECT_TO_LOGIN, CLIENT_TYPES, messageDisconnect } from '@Constants';
import { getClientHours, getActivatedPlans } from '@Services/noar-api';
import { SessionExpired } from '@Errors';
import clock from '../../../assets/clock.svg';
import * as S from './styled';
import * as sessionManager from '@Utils/session-manager';
import { ProtectedComponent } from '../../../components/ProtectedComponent';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { initLogout } from '../../../store/ducks/global/actions';
import { messages } from '../Nav/messages';
import { ORIGIN_CLASSIC, ORIGIN_PERSONAL, ORIGIN_UNIT } from '../../../constants';

const DivView = ({ classicHours, unitHours, showHours }) => {

  const { name } = useSelector(state => state.global.session.client);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const selectedPlan = getRetrievedPlanName(pathname);
  const isOnProfile = location.pathname === '/my/profile';
  const hasToShowHours = location.pathname === '/use/occasional/unit' || location.pathname === '/use/occasional/classic' || location.pathname === '/my/home' || location.pathname === '/shop/hours' || location.pathname === '/shop/unit';

  function handleProfileOnClick() {
    history.push('/my/profile');
  }

  const handleLogoutIconClick = useCallback(
    function () {
      dispatch(initLogout());
      history.push(REDIRECT_TO_LOGIN);
    },
    [dispatch, history],
  );

  const onClickLogOut = useCallback(
    function () {
      dispatch(
        openConfirmationLightbox(handleLogoutIconClick, messages.message, messages.confirmBtnText, messages.refuseBtnText, null, null, false),
      );
    },
    [handleLogoutIconClick, dispatch],
  );

  function handleClockIconClick() {
    let next = '/shop/hours'
    history.push(next);
  }

  function getPlanAccordingToPage(){
    if(pathname === '/shop/hours'){
      return ORIGIN_CLASSIC;
    }
    else if(pathname === '/shop/unit'){
      return ORIGIN_PERSONAL;
    }
  }

  function getRetrievedPlanName(pathname){
    return pathname.includes('/shop') ? getPlanAccordingToPage() : localStorage.getItem('selected_plan');
  }

  return (
    <S.Div>
      {showHours ? (
        <>
          <ProtectedComponent role={['B2C']}>
            { hasToShowHours 
              ? 
              <>
                <S.HeaderIcon alt={''} src={clock} onClick={handleClockIconClick}/>
                {selectedPlan === ORIGIN_UNIT 
                ? (
                  <S.Time to="/shop/hours">
                    <S.Tooltip>Tempo UNIT Utilizado </S.Tooltip>
                    {unitHours}
                  </S.Time>
                )
                : (
                  <S.Time to="/shop/hours" >
                    <S.Tooltip>Horas restantes </S.Tooltip>
                    {classicHours}
                  </S.Time>
                )}
              </>
              : []
            }
          </ProtectedComponent>
          <S.ProfileButton isOnProfile={isOnProfile} onClick={handleProfileOnClick}>{name?.split(" ")[0]} {name?.split(" ").length > 1 ? name?.split(" ")[1] : ""} </S.ProfileButton>
          <S.LogoutButton onClick={onClickLogOut}>Sair</S.LogoutButton>
        </>
      ) : null}
    </S.Div>
  );
};

function useHours() {
  const dispatch = useDispatch();
  const history = useHistory();
  const showHours = sessionManager.getClientType() !== CLIENT_TYPES.NOAR_PERSONAL;

  const { used_hours, contracted_hours } = useSelector(state => state.global);
  const { TotalHours, TotalMinutes } = calculateHours(used_hours, contracted_hours);

  const classicHours =
    used_hours && contracted_hours >= 0 && TotalHours >= 0 && TotalMinutes >= 0
      ? `${TotalHours.toString().padStart(2, '0')}:${TotalMinutes.toString().padStart(2, '0')} h `
      : '00:00 h';

  
  const { unit_used_minutes } = useSelector(state => state.global);
  const {UnitTotalHours, UnitTotalMinutes} = calculateUnitHours(unit_used_minutes);
  const unitHours = 
    UnitTotalHours >= 0 && UnitTotalMinutes >= 0
      ? `${UnitTotalHours.toString().padStart(2, '0')}:${UnitTotalMinutes.toString().padStart(2, '0')} h `
      : '00:00 h';
  useEffect(
    function () {
      getClientHours()
        .then(function (responseBody) {
          dispatch(updateClientHours(responseBody));
        })
        .catch(function (error) {
          if (error instanceof SessionExpired) {
            const turnOnMachine = false;
            const disconnect = true;
            
            dispatch(
              openConfirmationLightbox(
                () => {
                  history.push(REDIRECT_TO_LOGIN);
                },
                messageDisconnect.message,
                messageDisconnect.confirmBtnText,
                messageDisconnect.refuseBtnText,
                turnOnMachine,
                disconnect,
                false,
              ),
            );
          }
        });
    },
    [dispatch, history],
  );

  useEffect(
    function () {
      getActivatedPlans()
        .then(function (responseBody) {
          if(responseBody.length > 0){
            dispatch(updateUnitUsedHours(responseBody[0]?.used_minutes));
          }
        })
        .catch(function (error) {
          if (error instanceof SessionExpired) {
            const turnOnMachine = false;
            const disconnect = true;
            dispatch(
              openConfirmationLightbox(
                () => {
                  history.push(REDIRECT_TO_LOGIN);
                },
                messageDisconnect.message,
                messageDisconnect.confirmBtnText,
                messageDisconnect.refuseBtnText,
                turnOnMachine,
                disconnect,
              ),
            );
          }
        });
    },
    [dispatch, history],
  );

  return { classicHours, unitHours, showHours };
}

function Div() {
  return <DivView {...useHours()} />;
}

export default Div;
