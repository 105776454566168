import styled from 'styled-components';
import { desktopMinSize, mqmax } from '@Styles/mixins';

export const AlmostExpiringAlertWrapper = styled.dialog`
  position: fixed;
  inset: 0;
  background: #21222a;
  border-radius: 45px;
  border: 0;
  z-index: 998;
  max-width: 401px;
  width: 100%;
  height: 164px;
  box-shadow: 0 0 0 100vw rgba(0, 0, 0, 0.6);

  &[open] {
    animation: slide-up 500ms forwards, fade-in 350ms forwards;
  }

  &[closing] {
    display: block;
    pointer-events: none;
    inset: 0;
    animation: slide-down 500ms forwards, fade-out 350ms forwards;
  }

  &:before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 45px;
    padding: 3px;
    background: linear-gradient(115deg, #2d7dea 0%, #9b0cee 100%);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 164px;
    gap: 1rem;
    text-align: center;

    strong {
      font-size: 1.313rem;
      font-weight: 300;
      color: #fff;
      max-width: 40ch;
      line-height: 25px;

      span {
        font-weight: 700;
      }
    }

    button {
      position: absolute;
      right: 1.5rem;
      top: 2rem;
      background: transparent;
      cursor: pointer;
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    border-radius: 30px;
    max-width: 304px;
    height: 154px;

    &:before {
      border-radius: 30px;
    }

    & > div {
      height: 154px;

      strong {
        font-size: 0.938rem;
        line-height: 19px;
        max-width: 25ch;
      }

      button {
        right: 1rem;
        top: 1.25rem;
      }
    }
  }

  @keyframes slide-up {
    0% {
      transform: translateY(1000px);
    }
    100% {
      transform: translateY(0%);
    }
  }

  @keyframes slide-down {
    0% {
      transform: translateY(0%);
    }
    100% {
      transform: translateY(1000px);
    }
  }
`;
