import styled from 'styled-components';
import { AppBar, Button, Toolbar } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { mqmax } from '../../../styles/mixins';

export const Wrapper = styled(AppBar)`
  max-width: calc(1388 / 16 * 1rem);
  margin: calc(30 / 16 * 1rem) auto;
  border-radius: ${30 / 16}rem;
  padding: ${28 / 16}rem ${57 / 16}rem;
  color: #fff;
  backdrop-filter: blur(5px);

  &.MuiAppBar-positionFixed {
    inset: 0 0 auto;
  }

  &.MuiAppBar-colorPrimary {
    background-color: rgba(23, 23, 26, 0.7);
  }

  &.MuiPaper-elevation4 {
    box-shadow: none;
  }
`;

export const Container = styled(Toolbar)`
  justify-content: space-between;

  &.MuiToolbar-regular {
    min-height: 0;
  }

  &.MuiToolbar-gutters {
    padding: 0;
  }
`;

export const Anchor = styled(Link)`
  display: inline-flex;
`;

export const Logo = styled.img`
  width: ${({ width }) => width / 16}rem;
  height: ${({ height }) => height / 16}rem;
`;

export const List = styled(Toolbar)`
  gap: ${18 / 16}rem;

  &.MuiToolbar-regular {
    min-height: 0;
  }

  &.MuiToolbar-gutters {
    padding: 0;
  }
`;

export const TextButton = styled(Button)`
  &.MuiButton-root {
    color: inherit;
    font-size: ${17 / 16}rem;
    font-weight: normal;
    line-height: ${28 / 17};
    text-transform: none;
    transition: color 300ms ease-out;
    border-bottom: 1px solid #2d80f2;

    ${mqmax(330)}{
      font-size: ${14 / 16}rem;
    }

    &:hover,
    &.active {
      color: #2d80f2;
    }

    &:hover {
      background: none;
    }
  }
`;

export const Cta = styled(Button)`
  &.MuiButton-root {
    border: solid #2d80f2 ${2 / 16}rem;
    border-radius: ${20 / 16}rem;
    padding: ${5 / 16}rem ${38 / 16}rem;
    color: #2d80f2;
    font-size: ${17 / 16}rem;
    font-weight: bold;
    line-height: ${26 / 17};
    transition: ${['color', 'background'].map(prop => `${prop} 300ms ease-out`).join(', ')};

    &:hover {
      color: #fff;
      background: #2d80f2;
    }
  }
`;
