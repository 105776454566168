import styled from "styled-components";

export const Select = styled.select`
    width: 100%;
    padding: 0 10px;
    border: 1px solid #d1d1d1;
    border-radius: calc(24 / 16 * 1rem);
    font-size: 14px;
    color: #333;
    background-color: #fff;
    margin-bottom: 10px;
    font-size: calc(14 / 16 * 1rem);
    line-height: calc(20 / 16);
    text-align: left;
    height: calc(53 / 16 * 1rem)
`;

export const Option = styled.option`
    font-size: 14px;
    color: #333;
    background-color: #fff;
    font-size: calc(14 / 16 * 1rem);
    line-height: calc(20 / 16);
    text-align: left;
    height: calc(53 / 16 * 1rem)
`;