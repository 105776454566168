export const messages = {
  buildConfirmationLightboxText(imageName) {
    return `Deseja iniciar o computador com a imagem ${imageName}?`;
  },
  confirmBtnText: 'Sim',
  refuseBtnText: 'Não',
  turnOnMachine: true
};

export const messageError = {
  message: `Ocorreu um erro ao iniciar o computador. Por favor, tente novamente ou entre em contato com nosso suporte.`,
  confirmBtnText: "OK",
  refuseBtnText: undefined,
};
