export function updateComputer(computer) {
  return {
    type: 'UPDATE_COMPUTER',
    computer,
  };
}

export function updateQueue(queueInfo) {
  return {
    type: 'UPDATE_QUEUE',
    queueInfo,
  };
}

export function updateState(stateInfo) {
  return {
    type: 'UPDATE_STATE',
    stateInfo,
  };
}

export function updateStoppingState() {
  return {
    type: 'UPDATE_STOPPING_STATE',
  };
}

export function setComputerLoading() {
  return {
    type: 'SET_COMPUTER_LOADING',
  };
}

export function setComputerDoesntLoading() {
  return {
    type: 'SET_COMPUTER_DOES_NOT_LOADING',
  };
}

export function setComputerWebsocketOn() {
  return {
    type: 'SET_COMPUTER_WEBSOCKET_ENABLED',
  };
}

export function setComputerCreationEnabled() {
  return {
    type: 'SET_COMPUTER_CREATION_ENABLED',
  };
}

export function setComputerCreationFinished() {
  return {
    type: 'SET_COMPUTER_CREATION_FINISHED',
  };
}

export function setSelectedPlan(plan) {
  return {
    type: 'SET_SELECTED_PLAN',
    plan
  };
}

export function updateLaunchTime(launchTime) {
  return {
    type: 'UPDATE_LAUNCH_TIME',
    launchTime,
  };
}