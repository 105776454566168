import styled from 'styled-components';
import { desktopMinSize, mqmax } from '@Styles/mixins';
export const TutorialBackdrop = styled.div`
  position: fixed;
  inset: 0;
  background: rgba(23, 23, 26, 0.7);
  z-index: 103;
`;

export const ShopPageInitialStepContainer = styled.div`
  max-width: 1440px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 104;
  transform: translate(-50%, -60%);

  ${mqmax(desktopMinSize - 1)} {
    transform: translate(-50%, -86%);
  }
`;

export const ShopPageInitialStepWrapper = styled.div`
  position: relative;
  background-color: #21222a;
  max-width: 745px;
  margin: 0 auto;
  height: 479px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-radius: 49px;

  &:before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 49px;
    padding: 3px;
    background: linear-gradient(133deg, #2d80f2 0%, #9b0cee 100%);
    mask: linear-gradient(133deg, #2d80f2 0%, #9b0cee 100%) content-box,
      linear-gradient(133deg, #2d80f2 0%, #9b0cee 100%);
    -webkit-mask: linear-gradient(133deg, #2d80f2 0%, #9b0cee 100%) content-box,
      linear-gradient(133deg, #2d80f2 0%, #9b0cee 100%);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  & > strong {
    display: block;
    margin-top: 59px;
    font-size: 2.25rem;
    text-align: center;
    line-height: 1.3;
    font-weight: 600;
    span {
      text-transform: uppercase;
    }
  }

  & > p {
    font-size: 1.375rem;
    text-align: center;
    max-width: 34ch;
    line-height: 1.4;
    span {
      font-weight: 600;
    }
  }
  ${mqmax(desktopMinSize - 1)} {
    max-width: 307px;
    height: 285px;
    gap: 1rem;

    & > strong {
      font-size: 1.25rem;
    }

    & > p {
      font-size: 0.75rem;
    }
  }
`;

export const ShopPageInitialStepButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 4;

  & > button {
    border: 0;
    background-color: transparent;

    &:nth-of-type(1) {
      max-width: 170px;
      width: 100%;
      border-radius: 37px;
      height: 50px;
      outline: 3px solid #2d80f2;
      color: #2d80f2;
      outline-offset: -3px;
      text-transform: uppercase;
      font-weight: 600;
      transition: all 300ms;
      font-size: 0.9375rem;

      &:hover {
        background-color: #2d80f2;
        color: #fff;
      }
    }

    &:nth-of-type(2) {
      margin: 15px 0 36px 0;
      border-radius: 37px;
      color: #2d80f2;
      font-weight: 300;
      font-size: 1rem;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;
      transition: border 300ms;
      width: 100%;
      max-width: fit-content;
      border-radius: 0;

      &:hover {
        border-bottom-color: #2d80f2;
      }
    }
  }

  ${mqmax(desktopMinSize - 1)} {
    button {
      max-width: 142px !important;
      font-size: 12px !important;
      &:nth-of-type(1) {
        height: 38px;
      }
    }
  }
`;
