import React, { useState, useEffect } from 'react';
import 'swiper/swiper.min.css';
import Carousel from './Carousel';
import AMIsContext from './context';

export const AMIsView = () => (
  <>
    <Carousel />
  </>
);

export function useAMIs() {
  const [isAmiProgramsClosed, setIsAmiProgramsClosed] = useState(false);

  useEffect(
    function () {
      setIsAmiProgramsClosed(true);
    },
    [setIsAmiProgramsClosed],
  );

  return {
    value: { isAmiProgramsClosed, setIsAmiProgramsClosed },
  };
}

export function AMIs(props) {
  return (
    <AMIsContext.Provider {...useAMIs(props)}>
      <AMIsView />
    </AMIsContext.Provider>
  );
}

export default AMIs;
