import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import MuiButton from '@material-ui/core/Button';
import {
    defaultFont
  } from '@Styles/mixins';


export const Back = () => {
    return (
        <Button component={Link} to="/my/profile">
            {'<'}
        </Button>
    )
}

export default Back;

const Button = styled(MuiButton)`
  &.MuiButton-root {
    padding: 0;
    min-width: auto;
    font-family: ${defaultFont};
    text-transform: none;
    font-style: normal;
    text-align: center;
    line-height: 20px;
    font-weight: 500;
    color: #fff;
    font-size: calc(25 / 16 * 1rem);
  }
`;