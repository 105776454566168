import { Button as MuiButton, IconButton as MuiIconButton } from '@material-ui/core';
import { Close as MuiCloseIcon, Menu as MuiMenuIcon } from '@material-ui/icons';
import React, { useCallback, useRef } from 'react';
import { Link, NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '@Components/Public';

function Div({useScroll, setActualPath}, props) {
  const { handleCloseClick, handleOpenClick, ref } = useDialog();

  return (
    <div {...props}>
      <Button component={Link} variant="all-white" to="/login">
        Acesse
      </Button>
      <MuiIconButton aria-label="Abrir menu" onClick={handleOpenClick}>
        <MenuIcon />
      </MuiIconButton>
      <Menu {...{ ref }}>
        <OptionsWrapper>
          <MuiIconButton aria-label="Fechar menu" onClick={handleCloseClick}>
            <CloseIcon />
          </MuiIconButton>
          <Links>
            {links.map(({ path, text }) =>
              path == '/for-business' || path == '/faq' ? (
                <TextButton
                  component={NavLink}
                  key={path}
                  to={path}
                  onClick={() => {
                    setActualPath("");
                    window.location.hash = "";
                    handleCloseClick();
                  }}
                >
                  {text}
                </TextButton>
              ) : (
                <TextButton onClick={useScroll(path, setActualPath, handleCloseClick)} href={path}>
                  {text}
                </TextButton>
              ),
            )}
          </Links>
          <Links />
        </OptionsWrapper>
        <ButtonWrapper>
          <Button component={Link} variant="all-white" to="/login">
            Acesse
          </Button>
        </ButtonWrapper>
      </Menu>
    </div>
  );
}

function useDialog() {
  const ref = useRef(null);

  const handleOpenClick = useCallback(
    function () {
      const dialog = ref.current;

      if (!dialog) return;

      dialog.showModal();
    },
    [ref],
  );

  const handleCloseClick = useCallback(
    function () {
      const dialog = ref.current;

      if (!dialog) return;

      dialog.close();
    },
    [ref],
  );

  return { handleCloseClick, handleOpenClick, ref };
}

const links = [
  { path: '#planos', text: 'Escolha seu plano' },
  { path: '#como-usar', text: 'Como usar?' },
  { path: '#download', text: 'Baixar' },
  { path: '/for-business', text: 'Para Empresas' },
  { path: '/faq', text: 'FAQ' },
  { path: '#blog', text: 'Blog' },
];

const TextButton = styled(MuiButton)`
  &.MuiButton-root {
    justify-content: end;
    color: #fff;
    font-size: calc(16rem / 16);
    font-weight: normal;
    line-height: ${28 / 17};
    text-transform: none;
    transition: color 300ms ease-out;
    margin-top: calc(20rem / 16);
    margin-right: 10px;

    &:hover,
    &.active {
      color: #2d80f2;
    }

    &:hover {
      background: none;
    }
  }
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  width: 100%;

  ${Links} {
    margin-top: calc(16rem / 16);
  }
`;

const MenuIcon = styled(MuiMenuIcon)`
  color: #fff;
`;

const CloseIcon = styled(MuiCloseIcon)`
  color: #fff;
`;

const Menu = styled.dialog`
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: space-between;
  box-sizing: border-box;
  height: 100%;
  min-width: 250px;
  max-height: none;
  border: 0;
  border-radius: 24px 0px 0px 24px;
  position: fixed;
  inset: 0 0 auto auto;
  z-index: 1;
  overflow: auto;
  background: rgba(23, 23, 26, 0.7);
  box-shadow: 0px 4px 4px #000000;
  backdrop-filter: blur(5px);
  transform: translateX(100%);
  transition: transform calc(1s / 3) ease-in-out;

  &[open] {
    transform: translateX(0);
  }
`;

const ButtonWrapper = styled.div`
  margin-right: 10px;
`;

export const Mobile = styled(Div)``;

export default Mobile;
