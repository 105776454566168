import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closeConfirmationLightbox } from '@Store/ducks/global/actions';
import * as S from './styled';
import { calculateUnitHours } from '../../utils/calculate-hours';

function ConfirmationLightbox() {
  const { isOpen, callbackFunction, text, confirmBtnText, refuseBtnText, turnOnMachine, disconnect, isUnit, clientsInQueue, averageWaitingTimeMinutes } = useSelector(
    state => state.global.confirmationLightbox,
  );
  const dispatch = useDispatch();

  function calculateWaitingTime() {
    const {UnitTotalHours, UnitTotalMinutes} = calculateUnitHours(averageWaitingTimeMinutes)
    return UnitTotalHours >= 0 && UnitTotalMinutes >= 0
    ? `${UnitTotalHours.toString().padStart(2, '0')}:${UnitTotalMinutes.toString().padStart(2, '0')} h `
    : '00:00 h';;
  }

  // FUNCTIONS

  function close() {
    dispatch(closeConfirmationLightbox());
  }

  function confirm() {
    close();
    callbackFunction && callbackFunction();
  }

  function refuse() {
    close();
  }

  // EVENTS

  function onClickClose() {
    close();
    if(disconnect) {
      callbackFunction && callbackFunction();
    }
  }

  function onClickConfirm() {
    confirm();
  }

  function onClickRefuse() {
    refuse();
  }

  function escapeEventListener(event) {
    if (event.key === 'Escape') {
      close();
    }
  }

  // useEffect(s)

  useEffect(() => {
    document.addEventListener('keydown', escapeEventListener);

    return () => {
      document.removeEventListener('keydown', escapeEventListener);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <S.ConfirmationLightbox className={`${isOpen ? 'is--open' : ''}`}>
      <div className="confirmation-lightbox__overlay" onClick={onClickClose}></div>
      <div className={(turnOnMachine && isUnit) ? "confirmation-lightbox__wrapper confirmation-lightbox__wrapper__unit" : "confirmation-lightbox__wrapper"}>
        <div className={(turnOnMachine && isUnit) ? "confirmation-lightbox__box confirmation-lightbox__box__unit" : "confirmation-lightbox__box"}>
          <div className="confirmation-lightbox__content">
            {turnOnMachine ? <p className='confirmation-lightbox__warning'>Lembre-se de desligar a máquina, ou seu tempo continuará correndo!</p> : []}
            <p className="confirmation-lightbox__text">{text}</p>
          </div>
          {turnOnMachine && isUnit ? 
          <div className='confirmation-lightbox__content confirmation-lightbox__content__queue-status'>
            <p className="confirmation-lightbox__text">Fila atual: {clientsInQueue ? clientsInQueue : 0}</p>
            <p className="confirmation-lightbox__text">Tempo estimado: {calculateWaitingTime()}</p>
          </div> : []}
          <div className="confirmation-lightbox__actions">
          {(confirmBtnText || refuseBtnText) ?
            (<>
              { confirmBtnText ?
                <button type="button" id="confirmation-lightbox__btn-confirm" onClick={onClickConfirm}>
                  {confirmBtnText}
                </button>
                : []
              }
              { refuseBtnText ?
                <button type="button" id="confirmation-lightbox__btn-refuse" onClick={onClickRefuse}>
                  {refuseBtnText}
                </button>
                : []
              }
            </>
            ): []}
          </div>
        </div>
      </div>
    </S.ConfirmationLightbox>
  );
}

export default ConfirmationLightbox;
