import styled, { css } from 'styled-components';
import { Button as MuiButton } from '@material-ui/core';
import { desktopMinSize, mq, mqmax, mqmin, rem } from '../../../../styles/mixins';

export const PersonalUseAmiSectionViewWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 85%;
  padding: 0 7%;

  ${mqmax(desktopMinSize - 1)} {
    height: auto;
    flex-direction: column-reverse;
    justify-content: center;
    gap: 3rem;
    padding: 3rem 1rem;
  }

  @media (max-height: 750px) {
    gap: 1.5rem;
    padding: 0 1rem 1rem 1rem;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 3rem;

  ${mqmax(desktopMinSize - 1)} {
    align-items: center;
  }

  @media (max-height: 700px) {
    gap: 1.5rem;
  }
  @media (min-height: 701px, max-width: 1279px) {
    gap: 3rem;
  }


  & > strong {
    font-size: ${rem(15)};
    font-weight: 300;
    color: #fff;
    a {
      text-decoration: underline;
      position: relative;
      z-index: ${({ step }) => (step === 2 ? '102' : '1')};
      pointer-events: ${({ step }) => (step === 2 ? 'none' : 'unset')};
      display: inline-block;
      transition: color 150ms;
      margin-left: 4px;
      cursor: pointer;
      &:hover {
        color: #a00bf6;
      }
    }

    ${mqmax(desktopMinSize - 1)} {
      font-size: ${rem(14)};
      line-height: ${rem(18)};
      text-align: center;
    }
  }
`;

export const ImagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 100%;

  
  ${mqmax(desktopMinSize - 1)} {
    align-items: center;
  }
`;

export const HelloTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 90%;

  ${mqmax(desktopMinSize - 1)} {
    width: 80%;
  }
`;

export const HelloText = styled.p`
  font-size: 3.5rem;
  font-weight: 200;
  color: #fff;

  ${mqmax(desktopMinSize - 1)} {
    font-size: 2.5rem;
    text-align: center;

  }
`;

export const BlueStrong = styled.strong`
  font-size: 3.5rem;
  color: #426fe5;

  ${mqmax(desktopMinSize - 1)} {
    font-size: 2rem;
  }
`;

export const DescriptionText = styled.p`
  font-size: 0.9em;
  font-weight: 200;
  color: #fff;
  line-height: 1.2rem;

  @media (min-width: 1900px) {
    font-size: 1.2em;
    line-height: 1.6rem;
  }

  ${mqmax(desktopMinSize - 1)} {
    line-height: 1.2rem;
    text-align: center;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 1rem;

  ${mqmax(desktopMinSize - 1)} {
    justify-content: center;
    flex-direction: column;
  }
`;

export const DescriptionTextWrapper = styled.div`
  width: 95%;
  @media (min-width: 1900px) {
    width: 80%;
  }
`;

export const Button = styled(MuiButton)`
  &.MuiButton-root {
    font-size: 0.9rem;
    padding: 0.3rem 1.5rem;
    text-transform: none;
    min-width: 160px;

    ${mqmax(desktopMinSize - 1)} {
      font-size: 1rem;
      padding: 0.8rem 5.5rem;
      min-width: 160px;
    }

    ${({ variant }) => {
      if (variant === 'outlined')
        return css`
          border: solid #2d80f2 calc(2rem / 16);
          border-radius: calc(24rem / 16);
          color: #fff;
          opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
          font-weight: light;
          transition: ${['color', 'background'].map(prop => `${prop} 300ms ease-out`).join(', ')};
          &.Mui-disabled {
            cursor: not-allowed;
            color: #2d80f2;
            border: solid #2d80f2 calc(2rem / 16);
            border-radius: calc(24rem / 16);
          }

          &:hover {
            color: #fff;
            background: #2d80f2;
          }
        `;

      if (variant === 'contained')
        return css`
          border-radius: calc(24rem / 16);
          font-weight: light;
          line-height: calc(18 / var(--fontSize));
          &.MuiButton-contained {
            color: #fff;
            opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
            background: ${({ onWarning }) => onWarning ? 'orange' : '#2d80f2'};
            transition: background calc(1s / 3) ease-out;

            &:hover {
              background: ${({ onWarning }) => onWarning ? 'orange' : '#a00bf6'};
            }
          }

          ${mqmin(1400)} {
            line-height: calc(24 / var(--fontSize));
          }

          ${mq(1399, desktopMinSize)} {
            
            ${({ onWarning }) => onWarning ? `font-size: 0.65rem; font-weight: 600;` : ''};
          }
        `;
    }}
  }
`;

export const ButtonContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  margin-right: -0.6rem;
`;

export const RotatedIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transform: rotate(45deg);
`;

export const ButtonP = styled.div`
  margin-bottom: -0.12rem;
`;

export const LogoPersonalImg = styled.img`
  width: 80%;
  height: 80%;
  object-fit: contain;
  z-index: 2;

  @media (min-width: 1900px) {
    width: 70%;
    height: 70%;
  }

  ${mqmax(desktopMinSize - 1)} {
    width: 55%;
    height: 55%;
  }
`;

export const Colored3dLogoImg = styled.img`
  position: absolute;
  z-index: 1;
  width: 90%;
  height: 90%;
  opacity: 0.15;
  transform: translate(25%, 7%);
`;
