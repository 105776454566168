import { ResultInfoWrapper, ResultSpecifities, SpecifitiesItem } from './styled';
import no from '../../../PlanComparison/Table/Boolean/no.svg';
import yes from '../../../PlanComparison/Table/Boolean/yes.svg';
const ResultInfo = ({ result }) => {
  return result === 'CLASSIC' ? (
    <ResultInfoWrapper>
      <h4>Você contrata uma quantidade de horas e usa sem que o computador desligue</h4>
      <ResultSpecifities>
        <SpecifitiesItem>
          <h5>A partir de</h5>
          <span>
            R$6.95 <e>/hora</e>
          </span>
        </SpecifitiesItem>
        <SpecifitiesItem>
          <h5>Máquina sob demanda</h5>
          <img src={yes} alt="yes" />
        </SpecifitiesItem>
        <SpecifitiesItem>
          <h5>Tempo de sessão</h5>
          <span>Gerenciável através de horas</span>
        </SpecifitiesItem>
        <SpecifitiesItem>
          <h5>Armazenamento</h5>
          <img src={no} alt="no" />
        </SpecifitiesItem>
        <SpecifitiesItem>
          <h5>Pagamento</h5>
          <span>Avulso</span>
        </SpecifitiesItem>
        <SpecifitiesItem>
          <h5>Licenças dos programas</h5>
          <img src={no} alt="no" />
        </SpecifitiesItem>
      </ResultSpecifities>
    </ResultInfoWrapper>
  ) : result === 'PERSONAL' ? (
    <ResultInfoWrapper>
      <h4>Você contrata uma quantidade de horas e usa a máquina com as especificações montadas por você</h4>
      <ResultSpecifities>
        <SpecifitiesItem>
          <h5>A partir de</h5>
          <span>
            R$65.76 <e>/mês</e>
          </span>
        </SpecifitiesItem>
        <SpecifitiesItem>
          <h5>Máquina sob demanda</h5>
          <img src={yes} alt="yes" />
        </SpecifitiesItem>
        <SpecifitiesItem>
          <h5>Tempo de sessão</h5>
          <span>Gerenciável através de horas</span>
        </SpecifitiesItem>
        <SpecifitiesItem>
          <h5>Armazenamento</h5>
          <img src={yes} alt="yes" />
        </SpecifitiesItem>
        <SpecifitiesItem>
          <h5>Pagamento</h5>
          <span>Recorrente</span>
        </SpecifitiesItem>
        <SpecifitiesItem>
          <h5>Especificações personalizáveis</h5>
          <img src={yes} alt="yes" />
        </SpecifitiesItem>
      </ResultSpecifities>
    </ResultInfoWrapper>
  ) : (
    []
  );
};

export default ResultInfo;
