import { HelloMobile } from "../../../styled";
import Hello from "../../../Hello";

const HelloMobileWrapper = ({handleGoBack}) => {
    return (
        <HelloMobile>
            <Hello />
        </HelloMobile>
    );
}

export default HelloMobileWrapper;