import React, { useEffect } from 'react';
import { PaginationWrapper } from './styled';

export const Pagination = ({ itemsPerPage, sessions, handlePagination, totalPages, pageNumber }) => {
  function handleChangePage(page) {
    if (page <= 0) {
      return 0;
    } else if (page >= totalPages) {
      return totalPages - 1;
    }
    return page;
  }

  useEffect(() => {
    if (totalPages < pageNumber) {
      handlePagination(handleChangePage(0));
    }
  }, [totalPages]);

  return (
    <PaginationWrapper>
      <button type="button" onClick={() => handlePagination(0)}>
        |&#60;
      </button>
      <button type="button" onClick={() => handlePagination(handleChangePage(pageNumber - 1))}>
        &#60;
      </button>
      {totalPages ? <strong>Página {pageNumber + 1 + ' de ' + totalPages}</strong> : <strong>Página 1 de 1</strong>}

      <button type="button" onClick={() => handlePagination(handleChangePage(pageNumber + 1))}>
        &#62;
      </button>
      <button type="button" onClick={() => handlePagination(totalPages - 1)}>
        &#62;|
      </button>
    </PaginationWrapper>
  );
};
